import {
  AccountTreeEntity,
  FullAccountTreeNode,
  formatGlCode,
  prettyMuchZero,
} from "@joyhub-integration/shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap";

import axios from "../../services/axios";
import { apiUrl, axiosConfig } from "../../utils/api";
import { arrayToDict } from "../../utils/arrayToDict";
import { dateToYMD } from "../../utils/date";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import Loading from "../app/Loading";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { AnnualAccountTotal, AnnualTotalsResponse } from "./types";
import { bookName, formatAmount, formatDeltaPercentage } from "./utils";

const Total = -1;

const getYearlyTotals = (
  tree_id: number,
  system: number,
  book: number,
  propertiesParam: string,
  from: string,
  to: string,
) =>
  axios
    .get(
      apiUrl(
        `/gl/yearly?tree=${tree_id}&system=${system}&book=${book}&properties=${propertiesParam}&from=${from}&to=${to}`,
      ),
      axiosConfig,
    )
    .then((res) => res.data as AnnualTotalsResponse);

interface AnnualAccountTreeViewProps {
  system: number;
  numYears: number;
  accountTree: AccountTreeEntity;
  treeNodes: FullAccountTreeNode[];
  book: number;
  propertiesLabel: string;
  onBreakout: (node: FullAccountTreeNode) => void;
}

const AnnualAccountTreeView: React.FC<
  AnnualAccountTreeViewProps & WithAlertModalProps
> = ({
  system,
  numYears,
  accountTree,
  book,
  treeNodes,
  propertiesLabel,
  onUnexpectedError,
  onBreakout,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [totals, setTotals] = useState<Record<number, Record<string, number>>>(
    {},
  );
  // last year totals over the same period as this year
  const [lytdTotals, setLYTDTotals] = useState<
    Record<number, Record<string, number>>
  >({});
  const [selection] = usePropertiesSelectionQueryParam();

  const thisYear = new Date().getFullYear();

  const years = useMemo(
    () =>
      Array(numYears)
        .fill(thisYear - numYears + 1)
        .map((base, index) => base + index),
    [numYears, thisYear],
  );

  const computeTotals = useCallback(
    (totals: AnnualAccountTotal[]) => {
      const dollarAmounts = arrayToDict(
        totals,
        (t) => `${t.gl_account_id}:${t.year}`,
      );
      const dollars: Record<number, Record<number, number>> = {};
      const computeTotal = (
        node: FullAccountTreeNode,
        year: number,
      ): number | undefined => {
        let total: number | undefined = undefined;
        node.children.forEach((child) => {
          const subtotal = computeTotal(child, year);
          if (subtotal !== undefined) total = (total ?? 0) + subtotal;
        });
        node.accounts.forEach((account) => {
          const subtotal =
            dollarAmounts[`${account.gl_account_id}:${year}`]?.total;
          if (subtotal !== undefined) total = (total ?? 0) + subtotal;
        });
        if (total !== undefined) {
          let yearly = dollars[node.tree_node_id];
          if (yearly === undefined) dollars[node.tree_node_id] = yearly = {};
          yearly[year] = total;
          yearly[Total] = (yearly[Total] ?? 0) + total;
        }
        return total;
      };
      for (const node of treeNodes) {
        for (let year = thisYear - numYears; year <= thisYear; ++year) {
          computeTotal(node, year);
        }
      }
      return dollars;
    },
    [treeNodes, numYears, thisYear],
  );

  useEffect(() => {
    setLoaded(false);
    const propertiesParam = encodeURIComponent(JSON.stringify(selection ?? {}));
    const to = new Date();
    to.setDate(1);
    const from = new Date(to);
    from.setMonth(0);
    from.setFullYear(thisYear - numYears); // go back one extra year so we can show % yoy
    const priorTo = new Date(to);
    priorTo.setFullYear(thisYear - 1);
    const priorFrom = new Date(priorTo);
    priorFrom.setMonth(0);
    Promise.all([
      getYearlyTotals(
        accountTree.tree_id,
        system,
        book,
        propertiesParam,
        dateToYMD(from),
        dateToYMD(to),
      ),
      getYearlyTotals(
        accountTree.tree_id,
        system,
        book,
        propertiesParam,
        dateToYMD(priorFrom),
        dateToYMD(priorTo),
      ),
    ])
      .then(([{ totals }, { totals: lastYearTotals }]) => {
        setTotals(computeTotals(totals));
        setLYTDTotals(computeTotals(lastYearTotals));
        setLoaded(true);
      })
      .catch(onUnexpectedError);
  }, [
    system,
    book,
    accountTree,
    thisYear,
    numYears,
    selection,
    computeTotals,
    onUnexpectedError,
  ]);

  // A tree node usually has one account but sometimes has multiple accounts. The report only
  // displays the tree node, with the tree node code, not the underlying accounts or their codes.
  // Looking at samples this seems reasonable.
  const renderAccountNodes = (
    nodes: FullAccountTreeNode[],
    depth: number,
    parent: number,
  ) => {
    const hasAnything = nodes.some(
      (node) => totals[node.tree_node_id]?.[Total] !== undefined,
    );
    return (
      hasAnything &&
      nodes.map((node, index) => {
        const annually = totals[node.tree_node_id];
        const hasTotal = annually?.[Total] !== undefined;
        const coalesce = hasTotal ? 0 : undefined; // If the row has a total, coalesce values to 0
        // This works because Header/Total rows are associated with just a single account row
        const accountType = node.accounts[0]?.gl_account_type;
        const isTotalRow = accountType === "Total";
        const isHeaderRow = accountType === "Header";
        // Different systems indent differently, this matches a Moss document but not Roscoe...
        const indent = !isTotalRow && !isHeaderRow;
        const deltaCell = (year: number) => {
          const amount = annually?.[year];
          const prior =
            year === thisYear
              ? lytdTotals[node.tree_node_id]?.[year - 1]
              : annually?.[year - 1];
          // if either value is 0 or undefined don't show a % change..
          if (prettyMuchZero(amount) || prettyMuchZero(prior) || !isTotalRow)
            return <td />;
          const pct = formatDeltaPercentage(((amount - prior) * 100) / prior);
          // not colouring the CSS for now because while incoming going up is good, expense going up is bad
          const ve = pct.startsWith("-")
            ? "minus"
            : pct.startsWith("+")
              ? "plus"
              : "zero";
          return <td className={`delta-cell delta-${ve}`}>{pct}</td>;
        };
        const clickable = hasTotal && false; // unsupported in this view
        return (
          <React.Fragment key={node.tree_node_id}>
            {renderAccountNodes(node.children, 1 + depth, node.tree_node_id)}
            {isHeaderRow || hasTotal ? (
              <tr
                className={`${isTotalRow ? "total-row" : ""} ${
                  clickable ? "clickable" : ""
                }`}
                onClick={clickable ? () => onBreakout(node) : undefined}
              >
                <th>
                  {formatGlCode(
                    node.tree_node_code,
                    accountTree.gl_account_format_mask,
                  )}
                </th>
                <th className={`${indent ? "indented" : ""}`}>
                  {node.tree_node_name}
                </th>
                {years.map((year, i) => (
                  <React.Fragment key={i}>
                    <td>{formatAmount(annually?.[year] ?? coalesce)}</td>
                    {deltaCell(year)}
                  </React.Fragment>
                ))}
              </tr>
            ) : null}
          </React.Fragment>
        );
      })
    );
  };

  const varianceMessage = `${thisYear} variance compared with ${
    thisYear - 1
  } over the same period.`;

  return !loaded ? (
    <Loading />
  ) : (
    <Row key={accountTree.tree_id}>
      <Col className="account-tree">
        <div className="print-header">
          <div className="report-properties">
            Properties = {propertiesLabel}
          </div>
          <div className="report-title">
            {accountTree.tree_name} ({numYears - 1} years and year to date)
          </div>
          <div className="report-period">
            Period = {thisYear - numYears + 1} – {thisYear}
          </div>
          <div className="report-period">Book = {bookName(book)}</div>
        </div>
        <div className="finance-table-wrapper jh-max-height  with-footnote">
          <table className="finance-table">
            <thead>
              <tr>
                <th style={{ width: "3%" }} className="br-0" />
                <th style={{ width: "6%" }} className="br-0" />
                {years.map((year, i) => (
                  <React.Fragment key={i}>
                    <th className="br-0">
                      {year} {year === thisYear ? "to date" : null}
                    </th>
                    <th
                      id={year === thisYear ? "dagger" : undefined}
                      className="delta-cell"
                      style={{ width: "3%" }}
                    >
                      {year === thisYear ? (
                        <>
                          <span>&dagger;</span>
                          <UncontrolledPopover
                            target="dagger"
                            trigger="hover"
                            placement="top"
                          >
                            <PopoverBody>{varianceMessage}</PopoverBody>
                          </UncontrolledPopover>
                        </>
                      ) : null}
                    </th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>{renderAccountNodes(treeNodes, 0, -1)}</tbody>
          </table>
        </div>
        <div className="print-finance-footnote">&dagger; {varianceMessage}</div>
      </Col>
    </Row>
  );
};

export default withAlertModal(AnnualAccountTreeView);
