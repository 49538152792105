import {
  faChartLineUp,
  faFrame,
  faImage,
  faMemo,
  faMessage,
  faTableList,
} from "@fortawesome/pro-light-svg-icons";
import { ReportDataColumn } from "@joyhub-integration/shared";
import { isUndefined } from "lodash";

import {
  EmailInsightId,
  EmbeddedReportId,
  HtmlInsightId,
  IframeInsightId,
  IndependentImageInsightId,
  PropertyNoteInsightId,
} from "../../../services/insightLibrary/insightDefinitions";
import { Dimension, Insight } from "../../../services/models";
import { AugmentedGetReportResult } from "../../../services/reportsService";
import { CardMenuItemType, CardType } from "./type";

export const insightToOption = (i: Insight) => ({
  label: i.name,
  value: i.id.toString(),
});

export const dimensionToOption = (d: Dimension) => ({
  label: d.name,
  value: d.id.toString(),
});

export const visualizationTypeToOption = (vizType: string) => ({
  label: vizType,
  value: vizType,
});

export const reportToOption = (report: AugmentedGetReportResult) => ({
  label: report.name,
  value: report.identifier,
});

export const columnToOption = (column: ReportDataColumn) => ({
  label: column.header,
  value: column.index.toString(),
});

export const reduceStuff = (oldStuff: any, newStuff: any) => ({
  ...oldStuff,
  ...newStuff,
});

type ValidationFunction = (
  dashboardInsight: Record<string, any>,
  updateError: (field?: string, message?: string) => void,
) => boolean;

const validateGraph: ValidationFunction = (dashboardInsight, updateError) => {
  if (!dashboardInsight?.linkedReport) {
    updateError("report", "Report is required!");
    return false;
  }

  updateError();
  return true;
};

const validateEmail: ValidationFunction = (dashboardInsight, updateError) => {
  if (!dashboardInsight?.linkedReport) {
    updateError("report", "Report is required!");
    return false;
  }

  updateError();
  return true;
};

const validateTable: ValidationFunction = (dashboardInsight, updateError) => {
  if (!dashboardInsight?.linkedReport) {
    updateError("report", "Report is required!");
    return false;
  }
  if (!dashboardInsight?.name) {
    updateError("name", "Name is required!");
    return false;
  }
  if (!dashboardInsight?.stuff?.tableType?.name) {
    updateError("tableType", "Table type is required!");
    return false;
  }
  if (
    dashboardInsight?.stuff?.tableType?.name === "dynamic" &&
    isUndefined(dashboardInsight?.stuff?.tableType?.sortColumnId)
  ) {
    updateError("sortColumnId", "Sort column is required!");
    return false;
  }
  if (
    dashboardInsight?.stuff?.tableType?.name === "dynamic" &&
    !dashboardInsight?.stuff?.tableType?.sortType
  ) {
    updateError("sortType", "Sort type is required!");
    return false;
  }
  if (
    dashboardInsight?.stuff?.tableType?.name === "dynamic" &&
    !dashboardInsight?.stuff?.tableType?.rowCount
  ) {
    updateError(
      "rowCount",
      "Row count is required and should be bigger than 0!",
    );
    return false;
  }
  updateError();
  return true;
};

const validateImage: ValidationFunction = (dashboardInsight, updateError) => {
  if (!dashboardInsight?.stuff?.isPropertyImage) {
    if (!dashboardInsight?.stuff?.imageGuid) {
      updateError("image", "Image is required!");
      return false;
    }
  }

  updateError();
  return true;
};

const validateIframe: ValidationFunction = (dashboardInsight, updateError) => {
  if (!dashboardInsight?.stuff?.iframe?.url) {
    updateError("iframeurl", "URL is required!");
    return false;
  }

  if (!dashboardInsight?.stuff?.iframe?.height) {
    updateError("iframeheight", "Height is required!");
    return false;
  }

  updateError();
  return true;
};

export const validateForm = (
  cardType: CardType,
  dashboardInsight: Record<string, any>,
  setValidationError: (error: Record<string, string>) => void,
) => {
  const updateError = (field?: string, message?: string) => {
    if (field && message) setValidationError({ message, field });
    else setValidationError({});
  };

  const validationFunctions = {
    menu: () => false,
    graph: () => validateGraph(dashboardInsight, updateError),
    table: () => validateTable(dashboardInsight, updateError),
    email: () => validateEmail(dashboardInsight, updateError),
    html: () => true,
    image: () => validateImage(dashboardInsight, updateError),
    iframe: () => validateIframe(dashboardInsight, updateError),
    propertynote: () => true,
  };

  const validationFunction = validationFunctions[cardType];

  if (validationFunction) return validationFunction();
  else
    throw new Error(`Validation function not found for card type: ${cardType}`);
};

export const cardItems: CardMenuItemType[] = [
  {
    icon: faChartLineUp,
    title: "Choose a graph from a report",
    type: "graph",
    id: EmbeddedReportId,
  },
  {
    icon: faMessage,
    title: "Choose an HTML Report",
    type: "email",
    id: EmailInsightId,
  },
  {
    icon: faTableList,
    title: "Choose a table from a report",
    type: "table",
    id: EmbeddedReportId,
  },
  {
    icon: faMemo,
    title: "Create a text box",
    type: "html",
    id: HtmlInsightId,
  },
  {
    icon: faMemo,
    title: "Create a property note",
    type: "propertynote",
    id: PropertyNoteInsightId,
  },
  {
    icon: faImage,
    title: "Upload an image",
    type: "image",
    id: IndependentImageInsightId,
  },
  {
    icon: faFrame,
    title: "Create an iframe",
    type: "iframe",
    id: IframeInsightId,
  },
];
