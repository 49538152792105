import { of } from "nick-offerman";

const organizationKey = "organization";
const oparseInt = of(parseInt);
export const getSessionOrganization = (): number | null =>
  oparseInt(sessionStorage.getItem(organizationKey), 10);
export const setSessionOrganization = (organization: number | null) =>
  organization == null
    ? sessionStorage.removeItem(organizationKey)
    : sessionStorage.setItem(organizationKey, `${organization}`);
