import { CauseEntity } from "@joyhub-integration/shared";
import { uniq } from "lodash";
import { apiUrl, axiosConfig } from "../utils/api";
import axios from "./axios";

export async function getCauses(
  causeIds: number[],
): Promise<Array<CauseEntity>> {
  if (!causeIds.length) return [];
  const queryParams = uniq(causeIds)
    .map((id) => `causeIds=${encodeURIComponent(id)}`)
    .join("&");

  return axios
    .get(apiUrl(`/causes/get?${queryParams}`), axiosConfig)
    .then((res) => res.data.causes as Array<CauseEntity>);
}
