import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import React from "react";

interface IconWithTextProps extends FontAwesomeIconProps {
  icon: IconProp;
  text: string;
  textClassName?: string;
  iconClassName?: string;
  iconSize?: SizeProp;
  align?: "start" | "center";
  children?: React.ReactNode;
}

const IconWithText: React.FC<IconWithTextProps> = (props) => {
  const {
    textClassName,
    iconClassName,
    iconSize,
    text,
    icon,
    align,
    children,
    ...iconProps
  } = props;
  const textClass = props.textClassName ? props.textClassName : "";
  const iconClass = props.iconClassName
    ? `${props.iconClassName} jh-icon-with-text`
    : "jh-icon-with-text";
  return (
    <div
      className={`flex-row text-nowrap align-items-center justify-content-${
        align ?? "center"
      }`}
    >
      <FontAwesomeIcon
        className={iconClass}
        icon={props.icon}
        size={props.iconSize ? props.iconSize : undefined}
        {...iconProps}
      />
      <div style={{ flex: 0 }} className={textClass}>
        {props.text}
      </div>
      {children}
    </div>
  );
};

export default IconWithText;
