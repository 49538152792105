import axios from "axios";

type RequestHandler = {
  request: any;
  resolver: (value?: any) => void;
};

type InstanceType = {
  queue: RequestHandler[];
  running: RequestHandler[];
  shiftInitial: () => void;
  push: (reqHandler: RequestHandler) => void;
  shift: () => void;
  requestHandler: (req: any) => Promise<any>;
  responseHandler: (res: any) => any;
  responseErrorHandler: (res: any) => Promise<any>;
  interceptors: {
    request: number | null;
    response: number | null;
  };
  detach: () => void;
};

const ConcurrencyManager = (
  axiosInstance: typeof axios,
  MAX_CONCURRENT: number = 10,
): InstanceType => {
  if (MAX_CONCURRENT < 1)
    throw new Error(
      "Concurrency Manager Error: minimum concurrent requests is 1",
    );

  let instance: InstanceType = {
    queue: [],
    running: [],
    shiftInitial: () => {
      setTimeout(() => {
        if (instance.running.length < MAX_CONCURRENT) {
          instance.shift();
        }
      }, 0);
    },
    push: (reqHandler: RequestHandler) => {
      instance.queue.push(reqHandler);
      instance.shiftInitial();
    },
    shift: () => {
      if (instance.queue.length) {
        const queued = instance.queue.shift()!;
        queued.resolver(queued.request);
        instance.running.push(queued);
      }
    },
    requestHandler: (req: any): Promise<any> => {
      return new Promise((resolve) => {
        instance.push({ request: req, resolver: resolve });
      });
    },
    responseHandler: (res: any) => {
      instance.running.shift();
      instance.shift();
      return res;
    },
    responseErrorHandler: (res: any) => {
      return Promise.reject(instance.responseHandler(res));
    },
    interceptors: {
      request: null,
      response: null,
    },
    detach: () => {
      axiosInstance.interceptors.request.eject(instance.interceptors.request!);
      axiosInstance.interceptors.response.eject(
        instance.interceptors.response!,
      );
    },
  };

  instance.interceptors.request = axiosInstance.interceptors.request.use(
    instance.requestHandler,
  );
  instance.interceptors.response = axiosInstance.interceptors.response.use(
    instance.responseHandler,
    instance.responseErrorHandler,
  );

  return instance;
};

export { ConcurrencyManager };
