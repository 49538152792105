import "../craport.css";

import { faPencil, faPlus, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  arrayify,
  GenericReportDefinition,
  isDateColumn,
  ReportSheet,
  SheetRows,
} from "@joyhub-integration/shared";
import React, { useEffect, useState } from "react";
import { NavItem, NavLink } from "reactstrap";

import AddEditSheetModal from "./AddEditSheetModal";
import AddSheetPopover from "./AddSheetPopover";
import DeleteSheetModal from "./DeleteSheetModal";
import {
  BodySheet,
  initialColumn,
  initialSheet,
  SpecialSheet,
  TemplateSheet,
} from "./editReportUtil";

export const preventer = (f: () => void) => (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  f();
};

const GenericReportEditorActionBar: React.FC<{
  id?: number;
  definition: GenericReportDefinition;
  setDefinition: (o: GenericReportDefinition) => void;
  sheetNum?: number | SpecialSheet;
  setSheet: (sheet: string | undefined) => void;
}> = ({ id, definition, setDefinition, sheetNum, setSheet }) => {
  const [editIndex, setEditIndex] = useState<number>();
  const [deleteIndex, setDeleteIndex] = useState<number | SpecialSheet>();
  const [addEditSheet, setAddEditSheet] = useState<ReportSheet>();
  const [addPopper, setAddPopper] = useState(false);
  const sheets = definition.sheets ?? [];

  const doAddEditSheet = () => {
    if (addEditSheet?.name) {
      if (editIndex == null) {
        const sheet: ReportSheet = {
          ...addEditSheet,
          columns: arrayify(initialColumn(addEditSheet.rows)),
        };
        setDefinition({
          ...definition,
          sheets: [...sheets, sheet],
        });
      } else {
        const editedSheets = [...sheets];
        const initialCol = initialColumn(addEditSheet.rows);
        const columns = [...addEditSheet.columns];
        if (!Array.isArray(initialCol) && isDateColumn(initialCol)) {
          // fixes a bug where the initial column's date format wasn't changing upon editing the sheet
          const initialColumnIndex = columns.findIndex((col) =>
            isDateColumn(col),
          );
          columns[initialColumnIndex] = initialCol;
        }
        const sheet: ReportSheet = {
          ...addEditSheet,
          columns,
        };
        editedSheets[editIndex] = sheet;
        setDefinition({
          ...definition,
          sheets: editedSheets,
        });
      }
      setSheet(addEditSheet.name);
      setAddEditSheet(undefined);
    }
  };
  const doDeleteSheet = () => {
    if (deleteIndex === BodySheet) {
      const { body: _, ...rest } = definition;
      setDefinition(rest);
      if (sheetNum === BodySheet) setSheet(undefined);
    } else if (deleteIndex === TemplateSheet) {
      const { templates: _, ...rest } = definition;
      setDefinition(rest);
      if (sheetNum === TemplateSheet) setSheet(undefined);
    } else if (deleteIndex != null) {
      const editedSheets = [...sheets];
      editedSheets.splice(deleteIndex, 1);
      setDeleteIndex(undefined);
      setDefinition({
        ...definition,
        sheets: editedSheets,
      });
      setSheet(
        sheetNum === BodySheet || sheetNum === TemplateSheet
          ? sheetNum
          : sheetNum == null || editedSheets.length === 0
            ? undefined
            : editedSheets[
                sheetNum > deleteIndex || sheetNum >= editedSheets.length
                  ? sheetNum - 1
                  : sheetNum
              ]?.name,
      );
    }
    setDeleteIndex(undefined);
  };
  const onAddSheet = (sheet: string | number[] | SheetRows) => () => {
    setAddPopper(false);
    setEditIndex(undefined);
    if (typeof sheet === "string") {
      setDefinition({ ...definition, body: sheet });
      setSheet(BodySheet);
    } else if (Array.isArray(sheet)) {
      setDefinition({ ...definition, templates: [] });
      setSheet(TemplateSheet);
    } else {
      setAddEditSheet(initialSheet(sheet));
    }
  };
  const onSheetClick = (sheet: string | undefined) =>
    preventer(() => setSheet(sheet));
  const onEditSheetClick = (sheetNum: number) =>
    preventer(() => {
      setEditIndex(sheetNum);
      setAddEditSheet(sheets[sheetNum]);
    });

  useEffect(() => {
    if (
      (sheetNum === BodySheet && definition.body == null) ||
      (sheetNum === TemplateSheet && definition.templates == null)
    ) {
      setSheet(undefined);
    }
  }, [definition, sheetNum, setSheet]);

  return (
    <>
      <ul className="nav nav-tabs editor-action-bar editor-actions jh-crumbar-like">
        <NavItem>
          <NavLink
            active={sheetNum == null}
            href="#sheet"
            onClick={onSheetClick(undefined)}
          >
            Settings
          </NavLink>
        </NavItem>
        {definition.body == null ? null : (
          <NavItem className="ms-2">
            <NavLink
              href="#sheet"
              onClick={onSheetClick(BodySheet)}
              active={sheetNum === BodySheet}
            >
              Email Body
              <span
                className="d-inline-block px-1 ms-1 action-delete"
                onClick={preventer(() => setDeleteIndex(BodySheet))}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </span>
            </NavLink>
          </NavItem>
        )}
        {definition.templates == null ? null : (
          <NavItem className="ms-2">
            <NavLink
              href="#sheet"
              onClick={onSheetClick(TemplateSheet)}
              active={sheetNum === TemplateSheet}
            >
              Excel Template
              <span
                className="d-inline-block px-1 ms-1 action-delete"
                onClick={preventer(() => setDeleteIndex(TemplateSheet))}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </span>
            </NavLink>
          </NavItem>
        )}
        {sheets.map((sheet, index) => (
          <NavItem key={index} className="ms-2">
            <NavLink
              href="#sheet"
              onClick={onSheetClick(sheet.name)}
              active={index === sheetNum}
            >
              {sheet.name}
              <span
                className="d-inline-block px-1 ms-1 action-edit"
                onClick={onEditSheetClick(index)}
              >
                <FontAwesomeIcon icon={faPencil} size="sm" />
              </span>
              {definition.perInsight ? null : (
                <span
                  className="d-inline-block px-1 action-delete"
                  onClick={preventer(() => setDeleteIndex(index))}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </span>
              )}
            </NavLink>
          </NavItem>
        ))}
        {!definition.perInsight && (
          <NavItem>
            <NavLink
              style={{ border: 0, backgroundColor: "transparent" }}
              onClick={preventer(() => setAddPopper(!addPopper))}
              href=""
            >
              <div className="sheet-tab">
                <span className="d-inline-block px-1">
                  <FontAwesomeIcon id="sheet-add" icon={faPlus} />
                </span>
                {"Add Sheet "}
              </div>
            </NavLink>
          </NavItem>
        )}
      </ul>
      {!addPopper ? null : (
        <AddSheetPopover
          definition={definition}
          onAddSheet={onAddSheet}
          toggle={() => setAddPopper(false)}
        />
      )}
      {!addEditSheet ? null : (
        <AddEditSheetModal
          add={editIndex == null}
          sheet={addEditSheet}
          setSheet={setAddEditSheet}
          onSubmit={doAddEditSheet}
        />
      )}
      {deleteIndex == null ? null : (
        <DeleteSheetModal
          sheet={
            typeof deleteIndex === "number" ? sheets[deleteIndex] : deleteIndex
          }
          onClose={() => setDeleteIndex(undefined)}
          onSubmit={doDeleteSheet}
        />
      )}
    </>
  );
};

export default GenericReportEditorActionBar;
