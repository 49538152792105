import { apiUrl, axiosConfig } from "../utils/api";
import axios from "./axios";

export interface UploadUrl {
  url: string;
  prefix: string;
  fields: Record<string, string>;
}

export type UploadInfo = { filename: string; key: string };

export const prepareUpload = async (file: File): Promise<UploadInfo> => {
  const filename = file.name.replace(/.*\//, "");
  const { data: urlData } = await axios.post<UploadUrl>(
    apiUrl("/upload"),
    {},
    axiosConfig,
  );
  const key = urlData.prefix + filename;
  await uploadFile(file, urlData, key);
  return { filename, key };
};

export const uploadFile = (file: File, upload: UploadUrl, key: string) => {
  const formData = new FormData();
  formData.append("key", key);
  formData.append("Content-Type", file.type);
  for (const [key, value] of Object.entries(upload.fields)) {
    formData.append(key, value);
  }
  formData.append("file", file);
  const url = !upload.url.startsWith("/")
    ? upload.url
    : apiUrl(`${upload.url}`);
  return fetch(url, { method: "POST", body: formData }).then((res) => {
    if (res.status !== 204)
      throw Error(`Error uploading ${file.name}: ${res.status}`);
  });
};
