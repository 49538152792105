export type SkelingtonProps<T> = {
  value: T | undefined;
  height: number;
  children: (t: T) => JSX.Element;
};

export const Skelington = <T,>({
  value,
  height,
  children,
}: SkelingtonProps<T>): JSX.Element =>
  value == null ? (
    <div className="skeleton" style={{ minHeight: `${height}rem` }} />
  ) : (
    children(value)
  );
