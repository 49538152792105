import {
  faDownload,
  faEnvelope,
  faKey,
  faPencilAlt,
  faPlus,
  faRunning,
  faTrash,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";
import { ignoreCaseIncludesMatcher } from "@joyhub-integration/shared";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Input } from "reactstrap";

import { sudoAs } from "../../../services/sessionService";
import {
  deleteUser,
  exportUsers,
  getUserById,
  getUsers,
  Role,
  User,
  UserResponse,
} from "../../../services/usersService";
import { dateOf, dateToTimeString } from "../../../utils/date";
import { downloadAttachment } from "../../../utils/download";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import ButtonWithIcon, {
  ButtonWithIconProps,
} from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import DeleteModal from "../common/DeleteModal";
import AddEditUserModal from "./AddEditUserModal";
import ReinviteModal from "./ReinviteModal";
import ResetPasswordModal from "./ResetPasswordModal";
import UsersImportModal from "./UsersImportModal";

const UsersAdminPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [users, setUsers] = useState<Array<User>>();
  const [selected, setSelected] = useState<User>();
  const [userFilter, setUserFilter] = useState("");
  const [addingUser, setAddingUser] = useState<boolean>(false);
  const [reinviting, setReinviting] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [editingUser, setEditingUser] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [importing, setImporting] = useState<boolean>(false);

  const { platform } = useContext(PlatformContext);

  const fetchUsers = useCallback(
    async function () {
      return getUsers()
        .then((usersRes) => {
          usersRes.sort((a, b) => a.name.localeCompare(b.name));
          setUsers(usersRes);
        })
        .catch(onUnexpectedError);
    },
    [onUnexpectedError],
  );

  useEffect(() => {
    fetchUsers();
    setLoaded(true);
  }, [fetchUsers]);

  const filteredUsers = useMemo(() => {
    const match = ignoreCaseIncludesMatcher(userFilter);
    return users?.filter(
      (u: User) =>
        userFilter === "" ||
        match(u.name) ||
        u.roles.some((r: Role) => match(r.name)) ||
        match(u.email),
    );
  }, [users, userFilter]);

  function onAddEditUserModalSubmit(userResponse: UserResponse) {
    fetchUsers().then(() => {
      const user = userResponse.person;

      if (userResponse.error) {
        const message = addingUser
          ? `Adding ${user.email} encountered error: ${userResponse.error}`
          : `Editing ${user.email} encountered error: ${userResponse.error}`;
        setAlert(message, false);
      } else {
        const message = addingUser
          ? `User ${user.email} added successfully.`
          : `User ${user.email} edited successfully.`;
        setAlert(message, true);
      }
      setSelected(undefined);
      onAddEditUserModalClose();
    });
  }

  function onAddEditUserModalClose() {
    setAddingUser(false);
    setEditingUser(false);
  }

  function onDeleteUserModalSubmit(user: User) {
    fetchUsers().then(() => {
      setAlert(`User ${user.email} deleted successfully`, true);
      setSelected(undefined);
      onDeleteUserModalClose();
    });
  }

  function onDeleteUserModalClose() {
    setDeletingUser(false);
  }

  function sudo() {
    if (selected) {
      sudoAs(selected.id).then(() => (document.location.href = "/"));
    }
  }

  const buttonProps = [
    selected && selected.last_login == null
      ? {
          icon: faEnvelope,
          disabled: !selected,

          onClick: () => setReinviting(true),
          tooltip: "Re-send Invite",
        }
      : {
          icon: faKey,
          disabled: !selected,

          onClick: () => setResettingPassword(true),
          tooltip: "Reset Password",
        },
    {
      icon: faPencilAlt,
      disabled: !selected,
      onClick: () => setEditingUser(true),
      tooltip: "Edit User",
    },
    ...(platform?.superAdmin
      ? [
          {
            icon: faRunning,
            disabled: !selected || !selected.active,
            onClick: () => sudo(),
            tooltip: "Login as User",
          },
        ]
      : []),
    {
      icon: faTrash,
      disabled: !selected,
      color: "danger",
      onClick: () => setDeletingUser(true),
      tooltip: "Delete User",
    },
  ];

  function exportClick() {
    exportUsers().then(downloadAttachment("Users"));
  }

  const onImportSubmit = (usersAdded: number, usersEdited: number) => {
    fetchUsers().then(() => {
      setAlert(
        `${usersAdded} users imported, ${usersEdited} users updated.`,
        true,
      );
      setSelected(undefined);
      onImportClose();
    });
  };

  const onImportClose = () => setImporting(false);

  const rightButtonProps: Array<
    ButtonWithIconProps | React.ReactElement<unknown>
  > = [
    {
      icon: faUpload,
      disabled: importing,
      onClick: () => setImporting(true),
      label: "Import",
      tooltip: "Import Users",
    },
    {
      icon: faDownload,
      disabled: false,
      onClick: exportClick,
      label: "Export",
      tooltip: "Export Users",
    },
    <Input
      className="w-auto ms-3 py-1 rounded-pill"
      size={36}
      type="text"
      placeholder="Filter Users"
      value={userFilter}
      onChange={(e) => setUserFilter(e.target.value)}
    />,
  ];

  const tableCols: Array<KeyValue<User>> = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "organization_role",
      title: "System Role",
    },
    {
      key: "roles",
      title: "Property Access",
      toValue: (u) => u.roles.map((r) => r.name).join(", "),
    },
    {
      key: "active",
      title: "Status",
      toValue: (u) => (u.active ? "Active" : "Inactive"),
    },
    {
      key: "last_login",
      title: "Last Login",
      toValue: (u) => dateToTimeString(dateOf(u.last_login)),
      sortValue: (u) => dateOf(u.last_login)?.getTime(),
    },
  ];

  return (
    <>
      <ModernCrumbar primary="Manage Users">
        <ButtonWithIcon
          label="Add User"
          icon={faPlus}
          onClick={() => setAddingUser(true)}
          size="sm"
          color="primary"
          className="ms-auto align-self-center"
        />
      </ModernCrumbar>
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <div className="jh-page-content pt-0 users-admin-page admin-page page-scroll">
          <TableWithSelection<User>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            rows={filteredUsers}
          />
          {(addingUser || editingUser) && (
            <AddEditUserModal
              id={editingUser && selected ? selected.id : undefined}
              onSubmit={onAddEditUserModalSubmit}
              onClose={onAddEditUserModalClose}
            />
          )}
          {reinviting && selected && (
            <ReinviteModal
              user={selected}
              onSubmit={() => setReinviting(false)}
              onClose={() => setReinviting(false)}
            />
          )}
          {resettingPassword && selected && (
            <ResetPasswordModal
              user={selected}
              onSubmit={() => setResettingPassword(false)}
              onClose={() => setResettingPassword(false)}
            />
          )}
          {deletingUser && selected && (
            <DeleteModal<User>
              id={selected.id}
              entityName="User"
              identificationKey="email"
              onSubmit={onDeleteUserModalSubmit}
              onClose={onDeleteUserModalClose}
              getEntity={getUserById}
              deleteEntity={deleteUser}
            />
          )}
          {importing && (
            <UsersImportModal
              onSubmit={onImportSubmit}
              onClose={onImportClose}
            />
          )}
        </div>
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(UsersAdminPage);
