import * as _ from "lodash";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { FunnelChart, ResponsiveContainer } from "recharts";

import {
  SequenceValue,
  calculateInstantSequenceValues,
} from "../../../services/calculateInsightWorker/calculateInsightWorker";
import { InstantInsights } from "../../../services/dataService";
import definitions from "../../../services/insightLibrary/insightDefinitions";
import { KnownInsight as BackendInsight } from "../../../services/insightsService";
import { ComplexInsightIds, colors } from "../../../services/models";
import { avgCharWidth } from "../../../utils/chartLabelFormatter";

/* Next few functions are about constructing funnel plots that correctly
 * represent area and join up as well as possible.
 *
 * First point to note is that the funnel plot is a set of right trapezoids;
 * to make their areas uniformly representative of the input value array,
 * the midpoint of each trapezoid's diagonal edge must always be uniformly
 * proportional to the corresponding input values (for fixed uniform trapezoid
 * base widths, which is the case here).  A trivial base case, implemented by
 * 'steps' below, is to make them all flat, i.e. rectangles, i.e. a box plot.
 *
 * To then achieve the classic funnel plot, observe that knowing *any* single
 * edge's height in the plot must determine all other lines on the plot: that
 * edge will determine the matching edge in its adjacent neighbour, and (via
 * line segment through the trapezoid's already-fixed diagonal midpoint (see
 * above)), the opposite edge of its own trapezoid; these then determine their
 * neighbours, and so on.
 *
 * So all we need then is to pick the height of a single edge, and all else
 * will be determined.  The obvious choice here is a least-squares optimisation
 * against trapezoid slopes.  The quadratic is always positive in the x² term,
 * so we simply look for its centrepoint to minimise.  Another option is to
 * consider the variance of the trapezoid slopes — comparing the two, the
 * variance optimisation looks a bit boring since it wants all slopes to be
 * similar.  So, 'bound_flat' below implements a least-squares optimisation
 * against the slopes in the funnel plot.  Additionally, it determines whether
 * *any* continuous line is possible for the funnel plot, and if the least
 * squares solution is not continuous, will adopt the nearest sub-optimal
 * alternative if that won't result in excessively violent graphs.
 *
 * Very large deltas from one input value to the next will induce corresponding
 * swings in ensuing values, leading to hysteresis — plots that zig-zag wildly,
 * and frequently end up negative.
 *
 * So: if the box plot produced by bound_flat contains negative numbers, then
 * we split the input and re-plot for each partition.  Tried a variety of
 * approaches to finding the splitting point, including exhaustive search
 * looking for the least number of splits needed (produced very spiky graphs),
 * minimising the sum-of-squares of discontinuities (produced a *lot* of
 * splits), minimising the sum-of-absolute-discontinuities (looks quite good).
 * Heuristics instead of exhaustive search: split at the point things went
 * negative (not so good), split at the largest ratio between adjacent input
 * numbers (not bad), or split at the largest delta between adjacent input
 * numbers.  That last seems as good as the best of the exhaustive search
 * approaches, and since it's more lightweight and reasonably intuitive, that's
 * what I've implemented in 'safe_interp_delta'.  You'd think the ratio version
 * would be more robust, but it seems that since the big deltas are the sources
 * of trouble, you want to cut there.
 *
 * The above frequently leaves a few flat segments in the plot, particularly
 * around big step changes.  These look a bit boring, so 'fix_flats' executes
 * a final polishing step, allowing them to tilt within reason.
 *
 * These are then all wrapped up together in 'fixed_safe_bound_flat'.
 */

/* bound_flat won't jump m0 to bounds if it involves a change of greater than
 * Spikiness * maximum edge height.  This stops bound_flat from adopting bounds
 * which will produce overly spiky graphs, instead forcing them to be split.
 */
const Spikiness = 0.25;

/* fix_flats won't allow the graph to grow by more than this factor when it
 * allows flats to tilt: if the maximum edge height is y_max before invoking
 * fix_flats, it'll be at most MaximumGrowth * y_max afterwards.
 */
const MaximumGrowth = 1.8;

const SegmentMargin = 4;

const ValueFontSize = 30;

const ValueCharWidth = ValueFontSize * avgCharWidth;

// Map data values to pairs — the heights of the leading and trailing edges
// of the corresponding funnel plot right trapezoid segment.
type Funneliser = (vals: number[]) => [number, number][];

const steps: Funneliser = (vals) => vals.map((v) => [v, v]);

// for (const vs  of [[], [0], [0, 1]]) {
//   console.log(`${inspect(vs)} -> ${inspect(steps(vs))}`);
// }

// Aiming to minimise the sum of the squares of the m_i (the half step from
// the centre of each column to its left or right edges): this should give
// the least extreme slopes.  The sum of squares forms a quadratic in m_0,
// but since we're not even solving it we don't need the constant term.  The
// x² term will always be n = |vals|..  Check git history for older easier to
// follow version.

const bound_flat: Funneliser = (vals) => {
  if (vals.length <= 1) return steps(vals);
  const n = vals.length;
  const [b, lo, hi, max] = _.range(1, n).reduce(
    ([tot, lo, hi, max], idx: number) => {
      const term = _.range(1, 1 + idx).reduce(
        (ac: number, ci: number) =>
          ac + (1 & ci ? vals[ci - 1] - vals[ci] : vals[ci] - vals[ci - 1]),
        0,
      );
      return [
        tot + term,
        Math.max(lo, -term - vals[idx]),
        Math.min(hi, -term + vals[idx]),
        Math.max(max, vals[idx]),
      ];
    },
    [0, -vals[0], vals[0], vals[0]],
  );
  const m0 = -b / n;
  // Apply [lo,hi] bounds to m0 if they're valid and not too far away.
  const bm0 =
    lo > hi
      ? m0
      : m0 < lo && lo - m0 <= max * Spikiness
        ? lo
        : m0 > hi && m0 - hi <= max * Spikiness
          ? hi
          : m0;
  let y = vals[0] - bm0;
  return vals.map((v) => [y, (y = 2 * v - y)]);
};

// for (const vs of [[1, 3, 2, 1]]) {
//   console.log(`${inspect(vs)} -> ${inspect(flattest(vs))}`);
// }

// Recursively detect negatives and split at the point of greatest change,
// until we have no negatives.
const safe_interp_delta =
  (core: Funneliser): Funneliser =>
  (vals) => {
    const rv = core(vals);
    if (rv.find((v) => v[0] < 0 || v[1] < 0) === undefined) return rv;
    const split = _.range(2, vals.length).reduce(
      (w: number, i: number) =>
        Math.abs(vals[i] - vals[i - 1]) > Math.abs(vals[w] - vals[w - 1])
          ? i
          : w,
      1,
    );
    return [
      ...safe_interp_delta(core)(vals.slice(0, split)),
      ...safe_interp_delta(core)(vals.slice(split)),
    ];
  };

const safe_bound_flat = safe_interp_delta(bound_flat);

// for (const vs of [[1, 3, 2, 1], [183, 2174, 70, 868, 4242, 4369, 2020]]) {
//   console.log(`${inspect(vs)} -> ${inspect(safe_flattest(vs))}`);
// }

// Pick number with smallest absolute magnitude from args.
const minmag = (first: number, ...a: number[]): number =>
  a.reduce(
    (m: number, ai: number) => (Math.abs(ai) < Math.abs(m) ? ai : m),
    first,
  );

// Find flat segments and allow them to tilt as much as is "safe".
const fix_flats =
  (core: Funneliser): Funneliser =>
  (vals) => {
    const rv = core(vals);
    const nm1 = rv.length - 1;
    if (nm1 <= 0) return rv;
    const max_y = MaximumGrowth * Math.max(..._.flatMap(rv));
    rv.forEach((cur: [number, number], i: number) => {
      const [y0, y1] = cur;
      if (y0 !== y1) return;
      const want =
        i <= 0
          ? rv[1][0] - y0
          : i >= nm1
            ? y0 - rv[i - 1][1]
            : // Some question as to what would work best when adjusting a flat with
              // neighbours on both sides: here I go for the least extreme of a slope
              // to join with the left, a slope to join with the right, and a slope
              // that follows the neighbours' direction of travel.
              minmag(
                rv[i + 1][0] - y1,
                y0 - rv[i - 1][1],
                (rv[i + 1][0] - rv[i - 1][1]) / 2.0,
              );
      const bound = Math.min(max_y - y0, y0);
      const wantB = _.clamp(want, -bound, bound);
      cur[0] -= wantB;
      cur[1] += wantB;
    });
    return rv;
  };

export const fixed_safe_bound_flat = fix_flats(safe_bound_flat);

// for (const vs of [[1, 3, 2, 1], [183, 2174, 70, 868, 4242, 4369, 2020]]) {
//   console.log(`${inspect(vs)} -> ${inspect(fixed_safe_flattest(vs))}`);
// }

interface FunnelChartProps {
  insightId: number;
  instantValuesNow: InstantInsights;
  instantValuesAYearAgo: InstantInsights;
  insightsMap: Record<string, BackendInsight>;
  style?: CSSProperties;
}

// Unused??
// interface Datum {
//   value: number;
//   name: string;
//   fill: string;
//   icon: IconProp;
// }

interface FunnelChartLabelsProps {
  data: SequenceValue[];
  perDatumHeight: number;
}

interface FunnelChartPathProps {
  endPoints: [number, number];
  scale: number;
  width: number;
  xOffset: number;
  idx: number;
  perDatumHeight: number;
}

const FunnelChartLabels: React.FC<FunnelChartLabelsProps> = ({
  data,
  perDatumHeight,
}) => {
  // start at the center of the first datum (plus a nudge for the height of the label)
  const topMargin = (-1 * perDatumHeight) / 2 + 12;

  const maxDigits = data.reduce<number>(
    (prev, current) => Math.max(prev, current.value.toString().length),
    0,
  );

  return (
    <g>
      <text
        textAnchor="end"
        y={topMargin}
        fontWeight="300"
        fontSize={ValueFontSize}
      >
        {data.map((datum) => (
          <tspan
            key={`dashboard-funnel-item-value-${datum.label}`}
            x={maxDigits * ValueCharWidth}
            dy={perDatumHeight}
          >
            {datum.value}
          </tspan>
        ))}
      </text>
      {/* -3 to vertically center smaller label text */}
      <text textAnchor="start" y={topMargin - 3}>
        {data.map((datum) => (
          <tspan
            key={`dashboard-funnel-item-label-${datum.label}`}
            x={maxDigits * ValueCharWidth + ValueCharWidth}
            dy={perDatumHeight}
          >
            {datum.label}
          </tspan>
        ))}
      </text>
    </g>
  );
};

const ResponsiveFunnelChart = ({
  insightId,
  values,
  fpVals,
  scale,
  width,
  height,
}: {
  insightId: number;
  values: SequenceValue[];
  fpVals: [number, number][];
  scale: number;
  width?: number;
  height?: number;
}) => {
  if (width && height) {
    const funnelWidth = (width * 2) / 3;
    const perDatumHeight =
      (height - (values.length - 1) * SegmentMargin) / values.length;

    return (
      <FunnelChart width={width} height={height}>
        <g className="recharts-layer">
          <FunnelChartLabels data={values} perDatumHeight={perDatumHeight} />
          <g>
            {fpVals.map((pair, idx) => (
              <FunnelChartPath
                key={`dashboard-funnel-item-insight-${insightId}-index-${idx}`}
                endPoints={pair}
                idx={idx}
                scale={scale}
                width={funnelWidth}
                xOffset={width - funnelWidth - 1} // -1 for border width
                perDatumHeight={perDatumHeight}
              />
            ))}
          </g>
        </g>
      </FunnelChart>
    );
  }

  return null;
};

const FunnelChartPath: React.FC<FunnelChartPathProps> = ({
  endPoints,
  scale,
  width,
  xOffset,
  idx,
  perDatumHeight,
}) => {
  const [startX, endX] = endPoints.map((v) => width * (1 - v / scale));
  const startY = idx * perDatumHeight + SegmentMargin * idx;
  const endY = startY + perDatumHeight;
  return (
    <polygon
      fill={colors[idx]}
      points={`${xOffset + startX},${startY + 1} ${endX + xOffset},${endY} ${
        width + xOffset
      },${endY} ${width + xOffset},${startY + 1}`}
      stroke={colors[idx]}
    />
  );
};

const JhFunnelChart: React.FC<FunnelChartProps> = ({
  insightId,
  instantValuesNow,
  instantValuesAYearAgo,
  insightsMap,
}) => {
  const [values, setValues] = useState<SequenceValue[]>();

  useEffect(() => {
    const definition = definitions.find((d) => d.id === insightId)!;
    const complexInsightIdsSet = (
      definition.insightIds as ComplexInsightIds[]
    ).filter((i) => i.includeInFunnel !== false);
    const promises = complexInsightIdsSet.map((complexInsightIds) => {
      return calculateInstantSequenceValues(
        complexInsightIds,
        instantValuesNow.overall,
        instantValuesAYearAgo.overall,
        insightsMap,
      );
    });
    Promise.all(promises).then(setValues);
  }, [insightId, instantValuesNow, instantValuesAYearAgo, insightsMap]);

  const [scale, fpVals] = useMemo(() => {
    if (!values) return [0, []];
    // values are locale-formatted, so numbers in the thousands need to be
    // stripped of their commas..
    const numVals = values.map(({ value }) =>
      parseFloat(value.replace(/,/g, "")),
    );
    const fpVals = fixed_safe_bound_flat(numVals);
    const scaleMaybeZero = Math.max(..._.flatMap(fpVals));
    const scale = scaleMaybeZero > 0 ? scaleMaybeZero * 1.02 : 1;
    return [scale, fpVals];
  }, [values]); // values are the dependency that triggers recomputation
  if (!values) return null;

  return (
    <ResponsiveContainer height="100%" width="100%">
      <ResponsiveFunnelChart
        insightId={insightId}
        values={values}
        fpVals={fpVals}
        scale={scale}
      />
    </ResponsiveContainer>
  );
};

export default JhFunnelChart;
