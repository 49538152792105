import { of } from "nick-offerman";

export const ignoreCaseIncludesMatcher = (
  match: string,
): ((s: string | null | undefined) => boolean | undefined) => {
  const lc = match.toLowerCase();
  return (s) => s?.toLowerCase().includes(lc);
};

export const strepeat = (c: string, n: number): string =>
  Array(n).fill(c).join("");

export const anyToString = (value: any) =>
  value == null ? null : String(value);

const singularPluralN = (n: number, s: string, pl?: string) =>
  n === 1 ? `${n} ${s}` : pl === undefined ? `${n} ${s}s` : `${n} ${pl}`;

export const singularPlural = (
  q: number | Array<any>,
  s: string,
  pl?: string,
) => singularPluralN(Array.isArray(q) ? q.length : q, s, pl);

export const underscoreSpaces = of((x: string) => x.replace(/ /g, "_"));

/** Splits a string into prefix and suffix around separator, or else returns the whole string and an empty suffix. */
export const split = (s: string, sep: string): [string, string] => {
  const i = s.indexOf(sep);
  return i < 0 ? [s, ""] : [s.substring(0, i), s.substring(sep.length + i)];
};
