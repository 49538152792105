import {
  EffectiveRentNewSignsLast30Days,
  EffectiveRentPerSqftNewSignsLast30Days,
  EffectiveRentPerSqftRenewalsLast30Days,
  EffectiveRentRenewalsLast30Days,
  NewSignsLast30Days,
  RenewalsLast30Days,
  TermsNewSignsLast30Days,
  TermsRenewalsLast30Days,
} from "../../../services/insightLibrary/backendInsightIds";
import { JhTableCol } from "../../common/table/JhTable";
import { RowDefinition } from "../getData";

export const executedLeaseSummaryColumns: JhTableCol[] = [
  {
    key: "leasesSigned",
    display: "Leases Signed",
  },
  {
    key: "averageRent",
    display: "Average Rent",
  },
  {
    key: "rentPSF",
    display: "Rent PSF",
  },
  {
    key: "averageLease",
    display: "Average Lease",
  },
];

export const executedLeaseSummaryRowDefinitions: RowDefinition[] = [
  {
    header: "New Leases",
    insightIds: [
      {
        insightIds: [NewSignsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentNewSignsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentPerSqftNewSignsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [TermsNewSignsLast30Days],
        calculationType: "NUMBER",
      },
    ],
  },
  {
    header: "Renewals",
    insightIds: [
      {
        insightIds: [RenewalsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentRenewalsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentPerSqftRenewalsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [TermsRenewalsLast30Days],
        calculationType: "NUMBER",
      },
    ],
  },
];
