import { ReportInterval } from "@joyhub-integration/shared";

export const getTemporalInfo = (
  interval: ReportInterval = "Day",
  xAxisFormat?: string,
) => {
  const timeConfigs: Record<ReportInterval, { unit: string; format: string }> =
    {
      Year: { unit: "year", format: "%Y" },
      Quarter: { unit: "yearmonth", format: "Q%q %Y" },
      Month: { unit: "yearmonth", format: "%b %Y" },
      Week: { unit: "yearmonthdate", format: "%b %e %Y" },
      Day: { unit: "yearmonthdate", format: "%b %e %Y" },
    };

  let timeUnit = timeConfigs[interval]?.unit;
  let timeFormat = timeConfigs[interval]?.format;

  let xTooltip = "datum.x";

  if (timeFormat) xTooltip = `timeFormat(datum.x, '${timeFormat}')`;
  else if (xAxisFormat) xTooltip = `format(datum.x, '${xAxisFormat}')`;

  return { timeUnit, timeFormat, xTooltip } as const;
};

export const escapeVegaString = (s: string) => s.replace(/'/g, "\\'");
