import {
  ColumnFormat,
  ComparisonValue,
  RowType,
  cellStyle,
  formatCellValue,
} from "@joyhub-integration/shared";

import TrendArrow from "../visualizations/TrendArrow";

const DifferenceCell = ({
  value,
  format,
  rowType,
}: {
  value: ComparisonValue;
  format?: ColumnFormat;
  rowType: RowType;
}) => {
  const formatter = (number: number | null) =>
    formatCellValue(number, format?.numFmtValue);

  return (
    <div className="d-flex justify-content-end">
      <span className="me-2">{formatter(value.value)}</span>
      <TrendArrow
        delta={value.comparison ?? 0}
        percent={false}
        style={cellStyle(value, format?.cellFormats ?? [], rowType)}
        formatter={formatter}
      />
    </div>
  );
};

export default DifferenceCell;
