import { Vega } from "react-vega";

interface ExamplePieChartPropsType {
  colors: string[];
}

export const ExamplePieChart = (props: ExamplePieChartPropsType) => {
  const { colors } = props;

  return (
    <Vega
      className="w-100 h-100 d-flex justify-content-center"
      spec={{
        $schema: "https://vega.github.io/schema/vega-lite/v5.json",
        description: "A simple pie chart with labels.",
        data: {
          values: colors.map((color, index) => ({
            category: index,
            color: color,
            value: 1,
          })),
        },
        mark: { type: "arc", tooltip: true },
        background: "var(--main-bg-color)",
        encoding: {
          theta: { field: "value", type: "quantitative" },
          color: {
            field: "category",
            type: "nominal",
            legend: null,
            scale: {
              domain: colors.map((_, index) => index),
              range: colors,
            },
          },
          tooltip: [{ field: "color", type: "nominal", title: "Color" }],
        },
        view: { stroke: null },
      }}
      actions={false}
      renderer="svg"
    />
  );
};
