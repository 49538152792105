import { clamp } from "lodash";

export const avgCharWidth = 0.6; // rough width of the average char in a variable width font

export function labelConstraints(
  minWidth: number,
  maxWidth: number,
  fontSize: number,
  longestLabelChars: number,
) {
  const longestLabelWidth = longestLabelChars * fontSize * avgCharWidth;

  const widthConstraint = clamp(longestLabelWidth, minWidth, maxWidth);
  const charConstraint = widthConstraint / (fontSize * avgCharWidth);

  return [widthConstraint, charConstraint];
}
