import { Input } from "reactstrap";

interface DashboardHeaderPropsType {
  editMode: boolean;
  dashboardName: string;
  setDashboardName: (value: string) => void;
}

const DashboardHeader = (props: DashboardHeaderPropsType) => {
  const { editMode, dashboardName, setDashboardName } = props;

  return (
    <>
      {editMode && (
        <div className="d-flex align-items-center justify-content-start mx-4 my-3">
          <Input
            type="text"
            id="dashboard-name"
            required
            style={{ width: 300 }}
            value={dashboardName}
            onChange={(e) => setDashboardName(e.target.value)}
          />
        </div>
      )}
    </>
  );
};

export default DashboardHeader;
