import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placement } from "popper.js";
import React, { CSSProperties, ReactElement } from "react";
import {
  Button,
  ButtonProps,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";

interface PopoverProps {
  text: string;
  placement?: Placement;
  container?: string;
}

export interface ButtonWithIconProps extends ButtonProps {
  icon: IconProp;
  iconId?: string;
  iconColor?: string;
  iconSize?: SizeProp;
  iconStyle?: CSSProperties;
  label?: string | ReactElement;
  popoverProps?: PopoverProps;
  tooltip?: string;
  tag?: React.ElementType;
}

export const NonBreakingSpace = "\xa0";

const ButtonWithIcon: React.FC<ButtonWithIconProps> = (props) => {
  const {
    icon,
    iconId,
    iconColor,
    iconSize,
    iconStyle,
    label,
    popoverProps,
    tooltip,
    children,
    tag,
    ...buttonProps
  } = props;
  const popover =
    popoverProps ?? (tooltip && { text: tooltip, placement: "bottom" });
  const popoverId =
    popover &&
    `button-with-icon-popover-${popover.text.replaceAll(/[ /]/g, "")}`;
  const id = buttonProps.id ?? popoverId;
  const Component = tag ?? Button;
  return (
    <>
      <Component {...buttonProps} id={id}>
        <FontAwesomeIcon
          id={iconId}
          icon={icon}
          color={iconColor}
          size={iconSize}
          style={iconStyle}
        />
        {label && NonBreakingSpace}
        {label}
        {children}
      </Component>
      {popover && popoverId ? (
        <UncontrolledPopover
          placement={popover.placement}
          trigger="hover"
          target={id!}
          container={popover.container}
        >
          <PopoverBody>{popover.text}</PopoverBody>
        </UncontrolledPopover>
      ) : null}
    </>
  );
};

export default ButtonWithIcon;
