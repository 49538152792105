import { faFileDownload, faPrint } from "@fortawesome/pro-light-svg-icons";
import {
  AdHocReportRequest,
  DashboardKindEnum,
  GenericReportData,
  InsightDefinition,
  insightIdentifierToString,
  isInsightDimensional,
  PropertiesSelection,
  PureDate,
} from "@joyhub-integration/shared";
import { uniq } from "lodash";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Link, useParams } from "react-router";
import { Card, CardBody, CardFooter, CardHeader, CardText } from "reactstrap";

import {
  downloadAdHocReportData,
  getAdHocReportData,
} from "../../services/exportService";
import { downloadAttachment } from "../../utils/download";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import PlatformContext from "../app/PlatformContext";
import { useOnUnexpectedError } from "../common/alert/withAlertModal";
import { LoadilyFadily } from "../common/allFadily";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import { GenericReportTable } from "../craport/GenericReportTable";
import JhCrumbar from "../navbar/JhCrumbar";
import Unicard from "../unicard/Unicard";
import { siblings } from "./utlis";

const generateReportRequest = (
  insight: InsightDefinition,
  properties?: PropertiesSelection,
  asOf?: Date,
): AdHocReportRequest<false> => ({
  kind: DashboardKindEnum.GenericReport,
  name: insight.name,
  definition: {
    asOf: asOf?.toDateString() ?? new PureDate().toDateString(),
    sheets: [
      {
        name: insight.name,
        rows: {
          rows: "Date",
          count: 12,
          interval: "Month",
        },
        titles: [],
        columns: [
          {
            sort: {
              order: "Descending",
            },
            header: "Date",
            dateFmt: "mmmm yyyy",
            graphAxis: "x",
          },
          {
            header: insight.name,
            insight: insight.id,
            graphAxis: "y",
          },
        ],
        graph: true,
      },
    ],
  },
  configuration: {
    properties: properties ?? {},
    asOf: asOf?.toDateString(),
  },
});

const InsightDetailScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { insightsMap } = useContext(PlatformContext).platform!;
  const [selection] = usePropertiesSelectionQueryParam();
  const onUnexpectedError = useOnUnexpectedError();

  const [insight, setInsight] = useState<InsightDefinition>();
  const [reportRequest, setReportRequest] =
    useState<AdHocReportRequest<false>>();
  const [chartData, setChartData] = useState<GenericReportData<true>>();
  const [date, setDate] = useState<Date>(new Date());
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setInsight(insightsMap[id!]);
  }, [insightsMap, id]);

  useEffect(() => {
    if (insight) {
      setReportRequest(generateReportRequest(insight, selection, date));
    }
  }, [insight, selection, date]);

  useEffect(() => {
    if (reportRequest) {
      setChartData(undefined);
      getAdHocReportData(reportRequest)
        .then((data) => setChartData(data))
        .catch(onUnexpectedError);
    }
  }, [reportRequest, onUnexpectedError]);

  const doDownload = () => {
    if (reportRequest != null) {
      setDownloading(true);
      downloadAdHocReportData(reportRequest)
        .then(downloadAttachment("Insight.xlsx"))
        .catch(onUnexpectedError)
        .finally(() => setDownloading(false));
    }
  };

  const { description, dimensions, identifier, name, unit, goalType, kind } =
    insight ?? {};

  const { short, period, detail, notes, seeAlso } = description ?? {};

  const allSeeAlsos =
    insight &&
    uniq([...(seeAlso ?? []), ...(siblings(insight, insightsMap) ?? [])]);

  return (
    <LoadilyFadily className="jh-page-layout" loaded={insight !== undefined}>
      <JhCrumbar
        back
        primary="Administration"
        primaryPath="/admin"
        secondary={insight?.name}
      />
      <div className="dashboard-filters d-flex w-100 align-items-center mt-3 px-4 justify-content-between">
        <div className="flex-row ps-1">
          <ButtonWithIcon
            icon={faPrint}
            tooltip="Print"
            className="jh-action-icon"
            onClick={() => window.print()}
            id="print-insights"
          />
          <ButtonWithIcon
            icon={faFileDownload}
            tooltip="Download Report"
            className="jh-action-icon"
            onClick={doDownload}
            disabled={downloading}
            id="download-report"
            style={downloading ? { cursor: "wait" } : {}}
          />
        </div>
        <div className="flex-row">
          <DatePicker
            value={date}
            onChange={(value) => {
              if (value instanceof Date) setDate(value);
              else setDate(new Date());
            }}
            clearIcon={null}
            calendarIcon={null}
            className="jh-date-picker btn btn-light"
          />
        </div>
      </div>
      <div className="jh-page-content organization-admin-page admin-page">
        <Card>
          <CardHeader>
            <h2 className="h5 mb-0">
              {name} - {short ?? <em>Undocumented</em>}
            </h2>
          </CardHeader>
          <CardBody>
            {detail && <CardText>{detail}</CardText>}
            <dl
              style={{
                display: "grid",
                gridTemplateRows: "auto auto",
                gridAutoColumns: "1fr",
                gridAutoFlow: "column",
              }}
            >
              <dt>ID</dt>
              <dd>
                {identifier ? (
                  <code>{insightIdentifierToString(identifier)}</code>
                ) : (
                  "–"
                )}
              </dd>
              <dt>Period</dt>
              <dd>{period ?? "–"}</dd>
              <dt>Unit</dt>
              <dd>
                {unit &&
                  (typeof unit === "string"
                    ? unit
                    : Object.entries(unit).map(
                        ([key, value], i) =>
                          `${i !== 0 ? ", " : ""}${key}: ${value}`,
                      ))}
              </dd>
              <dt>Goal type</dt>
              <dd>
                {goalType === "max"
                  ? "Keep value below goal"
                  : goalType === "min"
                    ? "Keep value above goal"
                    : "–"}
              </dd>
              <dt>Kind</dt>
              <dd>{kind ?? "–"}</dd>
              {dimensions && (
                <>
                  <dt>Broken down by</dt>
                  <dd>{Object.values(dimensions).sort().join(", ")}</dd>
                </>
              )}
            </dl>
            {chartData && (
              <>
                <div style={{ height: 400 }} className="mb-4">
                  <Unicard data={chartData.sheets[0]} />
                </div>
                <GenericReportTable data={chartData.sheets[0]} />
              </>
            )}
          </CardBody>
          <CardFooter>
            {notes &&
              notes.length > 0 &&
              (notes.length === 1 ? (
                <p>
                  <strong>Note:</strong> {notes[0]}
                </p>
              ) : (
                <>
                  <p>
                    <strong>Notes:</strong>
                  </p>
                  <ol>
                    {notes.map((n, i) => (
                      <li key={`note-${i}`}>{n}</li>
                    ))}
                  </ol>
                </>
              ))}
            {allSeeAlsos && allSeeAlsos.length > 0 && (
              <p>
                <strong>See also: </strong>
                {allSeeAlsos
                  .flatMap((see) =>
                    isInsightDimensional(see) ? Object.values(see) : [see],
                  )
                  .map((see, i) => (
                    <span key={`insight-link-${i}`}>
                      {i > 0 && ", "}
                      <Link to={`/insights/${see.id}`}>{see.name}</Link>
                    </span>
                  ))}
              </p>
            )}
          </CardFooter>
        </Card>
      </div>
    </LoadilyFadily>
  );
};

export default InsightDetailScreen;
