import {
  AdHocInsightDto,
  AdHocInsightEntity,
  AdHocRule,
  initialRule,
  PartialRecord,
} from "@joyhub-integration/shared";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../../constants";
import {
  postAdHocInsight,
  putAdHocInsight,
} from "../../../services/adHocInsightService";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import { AdHocInsightRuleEditor } from "./AdHocInsightRuleEditor";

type AdHocInsightValidation = PartialRecord<
  keyof AdHocInsightDto,
  string | undefined
>;

type AddEditInsightModalProps = {
  currentSelected?: AdHocInsightEntity;
  onClose: () => void;
  onSubmit: (adHocInsight: AdHocInsightEntity) => void;
};

const AddEditAdHocInsightModal: React.FC<
  AddEditInsightModalProps & WithAlertModalProps
> = (props) => {
  const { currentSelected, onClose, onSubmit, onUnexpectedError } = props;

  const [serverError, setServerError] = useState<string>();
  const [validation, setValidation] = useState<AdHocInsightValidation>({});
  const [name, setName] = useState<string>(currentSelected?.name ?? "");
  const [description, setDescription] = useState<string>(
    currentSelected?.description ?? "",
  );
  const [active, setActive] = useState<boolean>(
    currentSelected?.active ?? true,
  );
  const [rule, setRule] = useState<AdHocRule>(
    currentSelected?.rule ?? initialRule,
  );
  const [ruleErrors, setRuleErrors] = useState<any>();

  const validateForm = () => {
    const validity: AdHocInsightValidation = {};

    if (!name) validity.name = "Field is required.";

    setValidation(validity);
    return Object.keys(validity).length === 0;
  };

  useEffect(() => setValidation({}), [name, description, rule]);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setServerError(undefined);
    if (validateForm() && !ruleErrors.length) {
      const dto: AdHocInsightDto = {
        name,
        description,
        active,
        rule,
      };
      const promise =
        currentSelected == null
          ? postAdHocInsight(dto)
          : putAdHocInsight(currentSelected.id, dto);
      promise
        .then((adHocInsight) => {
          onSubmit(adHocInsight);
        })
        .catch((err) => {
          const response = err.response;
          setServerError(
            response?.status === 400 && response.data.message
              ? response.data.message
              : unexpectedError,
          );
        });
    }
  };

  return (
    <UncontrolledModal onClosed={onClose} onFormSubmit={onFormSubmit}>
      <ModalHeader>
        {currentSelected ? `Edit ${name}` : "Add Ad Hoc Report"}
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup row>
          <Label sm={2}>Name</Label>
          <Col sm={10}>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              invalid={!!validation.name}
            />
            {validation.name && (
              <FormFeedback className="jh-block">
                {validation.name}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Description</Label>
          <Col sm={10}>
            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              invalid={!!validation.description}
            />
            {validation.description && (
              <FormFeedback className="jh-block">
                {validation.description}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup check>
          <Label check sm={{ offset: 2 }}>
            <Input
              type="checkbox"
              checked={active}
              onChange={() => setActive(!active)}
            />
            Rule Active
          </Label>
        </FormGroup>
        <Col sm={12}>
          <h5 className="my-3">Ad Hoc Rule</h5>
          <AdHocInsightRuleEditor
            rule={rule}
            setRule={setRule}
            setRuleErrors={setRuleErrors}
          />
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary" className="ms-2">
          Save
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};
export default withAlertModal(AddEditAdHocInsightModal);
