// import "./jhTable.css";

import {
  faCaretDown,
  faCaretUp,
  faSort,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";

import { SortDirection } from "../../../services/models";

export type TableRow = { [key: string]: string | number };

export interface ColOrRowBucket {
  display: string;
  span: number;
}

export interface JhTableCol {
  display: string;
  key: string;
  className?: string;
}

export interface RowHeader {
  label: string;
  className?: string;
  type?: string | "property";
}

export interface JhTableProps {
  columns: Array<JhTableCol>;
  rows: Array<TableRow>;
  noRowsMessage?: string;
  title?: string;
  rowHeaders: Array<string | RowHeader>;
  cssName?: string;
  columnBuckets?: Array<ColOrRowBucket>;
  rowBuckets?: Array<ColOrRowBucket>;
  defaultSortKey?: string;
  defaultSortDirection?: SortDirection;
  sortFunction?: (
    sortKey: string,
    sortDirection: SortDirection,
  ) => { rows: TableRow[]; rowHeaders: Array<string | RowHeader> };
  onRowClick?: (row: TableRow, rowHeader: string) => void;
  headerTitle?: string;
  id?: string;
}

const JhTable: React.FC<JhTableProps> = (props: JhTableProps) => {
  const {
    rows,
    columns,
    noRowsMessage,
    rowHeaders,
    cssName,
    columnBuckets,
    rowBuckets,
    sortFunction,
    onRowClick,
    headerTitle,
    defaultSortKey,
    defaultSortDirection,
  } = props;
  const columnBucketsRowRef = useRef<HTMLTableRowElement>(null);
  const [sortKey, setSortKey] = useState<string>(defaultSortKey ?? "");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    defaultSortDirection ?? "asc",
  );
  const [filteredRows, setFilteredRows] = useState<TableRow[]>(rows);
  const [filteredRowHeaders, setFilteredRowHeaders] = useState(rowHeaders);
  const tableClassName = cssName ? `jh-table ${cssName}` : "jh-table";
  const areColsBucketed = columnBuckets && columnBuckets.length;
  const areRowsBucketed = rowBuckets && rowBuckets.length;
  const colThStyle = columnBucketsRowRef.current
    ? { top: columnBucketsRowRef.current.offsetHeight }
    : undefined;

  useEffect(() => {
    setFilteredRowHeaders(rowHeaders);
    setFilteredRows(rows);
  }, [rowHeaders, rows]);

  useEffect(() => {
    if (sortFunction && sortDirection && sortKey) {
      const { rows, rowHeaders } = sortFunction(sortKey, sortDirection);
      setFilteredRows(rows);
      setFilteredRowHeaders(rowHeaders);
    }
  }, [sortDirection, sortKey, rows, sortFunction]);

  function onColHeaderClick(e: React.MouseEvent, colKey: string) {
    e.stopPropagation();
    if (sortKey === colKey && sortDirection === "asc") {
      setSortDirection("desc");
    } else if (sortKey === colKey && sortDirection === "desc") {
      setSortDirection("asc");
    } else {
      setSortKey(colKey);
      setSortDirection("desc");
    }
  }

  const allColumns = columns.map((col) => (
    <th key={`col-${col.key}`} style={colThStyle}>
      <div
        className="jh-table-col-header-container"
        onClick={sortFunction ? (e) => onColHeaderClick(e, col.key) : undefined}
      >
        <div className="jh-table-col-header-display">{col.display}</div>
        {sortFunction && sortKey === col.key && sortDirection ? (
          <FontAwesomeIcon
            icon={sortDirection === "asc" ? faCaretUp : faCaretDown}
          />
        ) : null}
        {sortFunction && sortKey !== col.key ? (
          <FontAwesomeIcon icon={faSort} />
        ) : null}
      </div>
    </th>
  ));

  let nextBucketIndex = 0;
  let bucketsShownAlready = 0;

  const allRows = filteredRows.map((row, idx) => {
    const showBucketCell =
      areRowsBucketed && rowBuckets && idx === nextBucketIndex;
    const bucket =
      showBucketCell && (rowBuckets as ColOrRowBucket[])[bucketsShownAlready];
    if (bucket) {
      nextBucketIndex = nextBucketIndex + bucket.span;
      bucketsShownAlready = bucketsShownAlready + 1;
    }
    const rowHeader = filteredRowHeaders[idx];
    const rowHeaderLabel =
      typeof rowHeader === "string" ? rowHeader : rowHeader.label;
    const rowHeaderClass =
      typeof rowHeader === "string" ? "" : rowHeader.className;

    return (
      <tr
        key={`row-${idx}`}
        onClick={() => onTableRowClick(row, rowHeaderLabel)}
        className={onRowClick ? "jh-table-row" : undefined}
      >
        {showBucketCell ? (
          <th
            className="jh-table-row-bucket"
            rowSpan={(bucket as ColOrRowBucket).span}
          >
            {(bucket as ColOrRowBucket).display}
          </th>
        ) : null}
        <td className={`jh-table-row-header ${rowHeaderClass}`}>
          {rowHeaderLabel}
        </td>
        {columns.map((col) => (
          <td
            key={`cell-${col.key}`}
            className={`${rowHeaderClass} ${col?.className}`}
            style={{ width: `calc(100% / ${columns.length})` }}
          >
            {row[col.key]}
          </td>
        ))}
      </tr>
    );
  });

  const noResult = (
    <tr>
      <td className="jh-table-no-results">{noRowsMessage || "No Results"}</td>
    </tr>
  );

  function onTableRowClick(row: TableRow, rowHeader: string) {
    if (onRowClick) {
      onRowClick(row, rowHeader);
    }
  }

  return (
    <Table className={tableClassName} id={props.id}>
      <thead>
        {areColsBucketed ? (
          <tr ref={columnBucketsRowRef}>
            <th className="jh-table-empty-header"></th>
            {(columnBuckets as ColOrRowBucket[]).map((colBucket, idx) => (
              <th key={idx} colSpan={colBucket.span}>
                {colBucket.display}
              </th>
            ))}
          </tr>
        ) : null}
        <tr>
          {headerTitle ? (
            <th className="jh-table-header-tile text-left" style={colThStyle}>
              {headerTitle}
            </th>
          ) : (
            <th className="jh-table-empty-header" style={colThStyle}></th>
          )}
          {areRowsBucketed ? (
            <th className="jh-table-empty-header" style={colThStyle}></th>
          ) : null}
          {allColumns}
        </tr>
      </thead>
      <tbody>{allRows.length ? allRows : noResult}</tbody>
    </Table>
  );
};

export default JhTable;
