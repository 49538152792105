import React from "react";
import { Range, getTrackBackground } from "react-range";

const renderTrack =
  (values: number[], min: number, max: number) =>
  ({ props, children }: { props: any; children: React.ReactNode }) => (
    <div
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{
        ...props.style,
        height: "36px",
        display: "flex",
        width: "100%",
      }}
    >
      <div
        ref={props.ref}
        style={{
          height: "5px",
          width: "100%",
          borderRadius: "4px",
          background: getTrackBackground({
            values,
            colors: ["#ccc", "#548BF4", "#ccc"],
            min,
            max,
          }),
          alignSelf: "center",
        }}
      >
        {children}
      </div>
    </div>
  );

const renderThumb = ({
  props,
  isDragged,
}: {
  props: any;
  isDragged: boolean;
}) => (
  <div
    {...props}
    style={{
      ...props.style,
      height: "42px",
      width: "42px",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 2px 6px #AAA",
    }}
  >
    <div
      style={{
        height: "16px",
        width: "2px",
        backgroundColor: isDragged ? "#548BF4" : "#CCC",
        transition: "background-color 300ms",
      }}
    />
  </div>
);

const JhRange = ({
  values,
  min,
  max,
  step,
  onChange,
}: {
  values: number[];
  min: number;
  max: number;
  step: number;
  onChange: (values: number[]) => void;
}) => (
  <Range
    step={step}
    min={min}
    max={max}
    values={values}
    onChange={onChange}
    draggableTrack
    renderTrack={renderTrack(values, min, max)}
    renderThumb={renderThumb}
  />
);

export default JhRange;
