import "./integrations.css";

import {
  faCogs,
  faDollarSign,
  faFileImport,
  faLambda,
  faPause,
  faPencilAlt,
  faPlay,
  faPlus,
  faSync,
  faTimelineArrow,
  faTransporter,
  faTrash,
  faTree,
} from "@fortawesome/pro-light-svg-icons";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";

import {
  deleteIntegration,
  getIntegrationById,
  getIntegrations,
  getTransferUsers,
  Integration,
  IntegrationVendor,
  setIntegrationStatus,
  TransferUser,
  YardiConfiguration,
} from "../../../services/integrationsService";
import {
  getIntegrationUploadUrl,
  receiveIntegrationUpload,
  syncIntegration,
} from "../../../services/overlordService";
import { uploadFile } from "../../../services/uploadService";
import { dateOf, dateToString } from "../../../utils/date";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import ButtonWithIcon, {
  ButtonWithIconProps,
} from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import DeleteModal from "../common/DeleteModal";
import AddEditIntegrationModal from "./addEditIntegrationModal/AddEditIntegrationModal";
import ComputeIntegrationModal from "./ComputeIntegrationModal";
import FinancialAccountsModal from "./FinancialAccountsModal";
import RunLambdaModal from "./RunLambdaModal";

const lastest = (...ds: (Date | string | null | undefined)[]) =>
  ds.reduce((best: Date, cur) => {
    const cd =
      cur == null ? best : typeof cur === "string" ? new Date(cur) : cur;
    return cd <= best ? best : cd;
  }, new Date(0));

const integrationTimestamp = (i: Integration) =>
  lastest(
    i.last_computation?.updated,
    i.last_computation?.started,
    i.last_sync?.finished,
    i.last_sync?.updated,
    i.last_sync?.started,
  );

const Integrations: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [integrations, setIntegrations] = useState<Array<Integration>>();
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedIntegrationVendor, setSelectedIntegrationVendor] =
    useState<IntegrationVendor>();
  const [modal, setModal] = useState<
    "add" | "edit" | "delete" | "compute" | "lambda" | "financials"
  >();
  const [uploading, setUploading] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const { platform } = useContext(PlatformContext);
  const navigate = useNavigate();
  const selected = integrations?.find((i) => i.id === selectedId);

  const fileTransferUser = useRef<string>();
  const [systemTransferUsers, setSystemTransferUsers] = useState<
    Record<number, TransferUser[]>
  >({});
  const transferUsers = selectedId
    ? (systemTransferUsers[selectedId] ?? [])
    : [];
  const loadTU = (id: number) =>
    getTransferUsers(id).then((tus) =>
      setSystemTransferUsers({ ...systemTransferUsers, [id]: tus }),
    );

  async function fetchIntegrations() {
    return getIntegrations()
      .then((integrations) => {
        // If there are any integrations present, one will be selected.
        // Active is preferred to inactive.
        // Within a cohort, the one with the most recent activity is preferred.
        const best = integrations.reduce(
          (b: Integration | undefined, c) =>
            !b ||
            (!b.active && c.active) ||
            ((!b.active || c.active) &&
              integrationTimestamp(c) > integrationTimestamp(b))
              ? c
              : b,
          undefined,
        );
        integrations.sort((a, b) => a.name.localeCompare(b.name));
        setIntegrations(integrations);
        if (best != null && selectedId == null) setSelectedId(best.id);
      })
      .catch(onUnexpectedError);
  }

  const memoizedFetchIntegrations = useCallback(fetchIntegrations, [
    onUnexpectedError,
    selectedId,
  ]);

  useEffect(() => {
    memoizedFetchIntegrations();
  }, [memoizedFetchIntegrations]);

  function toggleIntegrationStatus() {
    const selected = integrations?.find((i) => i.id === selectedId);
    if (selected) {
      setIntegrationStatus(selected.id, !selected.active)
        .then((active: boolean) => {
          fetchIntegrations().then(() => {
            const action = active ? "activated" : "de-activated";
            const message = `Integration ${selected.name} ${action}.`;
            setAlert(message, true);
            onAddEditModalClose();
          });
        })
        .catch(onUnexpectedError);
    }
  }

  function onAddEditModalSubmit(integration: Integration) {
    fetchIntegrations().then(() => {
      const message =
        modal === "add"
          ? `Integration ${integration.name} created.`
          : `Integration ${integration.name} updated.`;
      setAlert(message, true);
      onAddEditModalClose();
    });
  }

  function onAddEditModalClose() {
    setModal(undefined);
    setSelectedIntegrationVendor(undefined);
  }

  function onDeleteModalSubmit(integration: Integration) {
    fetchIntegrations().then(() => {
      const message = `Integration ${integration.name} deleted`;
      setAlert(message, true);
      setModal(undefined);
    });
  }

  function onSyncIntegration() {
    if (
      selected &&
      selected.active &&
      window.confirm(`Sync ${selected.name} now?`)
    ) {
      syncIntegration(selected.id)
        .then((status) => {
          if (status !== 204) throw Error("Sync failed");
          return setAlert("Synchronization started.", true);
        })
        .catch(onUnexpectedError);
    }
  }

  const onSelectUpload = (kind: string | undefined) => () => {
    if (!selected?.active || uploading || !fileInput.current) return;
    fileTransferUser.current = kind;
    fileInput.current.value = "";
    fileInput.current.click();
  };

  const onFileSelected = () => {
    const files = fileInput.current?.files;
    const transferUser = fileTransferUser.current;
    if (!selected?.active || !files?.length) return;
    setUploading(true);
    getIntegrationUploadUrl(selected.id)
      .then((upload) =>
        Array.from(files).reduce(
          (promise, file) =>
            promise.then(() => {
              const fileName = file.name.replace(/^.*\//, "");
              setAlert(`Uploading ${fileName}`, true);
              const key = `incoming/${transferUser ?? selected.id}/${fileName}`;
              return uploadFile(file, upload, key)
                .then(() => receiveIntegrationUpload(selected.id, key))
                .then(); // .void
            }),
          Promise.resolve(),
        ),
      )
      .then(() => setAlert("Upload succeeded", true))
      .catch(onUnexpectedError)
      .finally(() => {
        setUploading(false);
        if (fileInput.current) fileInput.current.value = "";
      });
  };

  const manageable = selected?.organization_id === platform!.organization.id;

  const overlordButtons: Array<ButtonWithIconProps | React.ReactElement> =
    platform?.superAdmin
      ? [
          {
            icon: faTransporter,
            disabled: !manageable || !selected?.active,
            onClick: () => navigate(`${selected?.id}/transferUsers`),
            tooltip: "Transfer Users",
          },
          <UncontrolledDropdown key="Upload Data">
            <DropdownToggle
              tag={ButtonWithIcon}
              disabled={!manageable || !selected?.active || uploading}
              icon={faFileImport}
              onClick={() => {
                loadTU(selected!.id).then();
              }}
              title="Upload Data"
            ></DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={onSelectUpload(undefined)}>
                Upload PMS Data
              </DropdownItem>
              {transferUsers.map((tu) => (
                <DropdownItem
                  key={tu.username}
                  onClick={onSelectUpload(tu.username)}
                >
                  {tu.username} ({tu.kind ?? "PMS"})
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>,
          {
            icon: faSync,
            disabled:
              !manageable ||
              !selected?.active ||
              (selected.vendor === "Yardi" &&
                Object.keys(selected.configuration).length === 0),
            onClick: onSyncIntegration,
            tooltip: "Synchronize Now",
          },
          {
            icon: faCogs,
            disabled: !manageable || !selected?.active,
            onClick: () => setModal("compute"),
            tooltip: "Compute Insights",
          },
          ...(platform.superAdmin
            ? [
                {
                  icon: faLambda,
                  disabled: !manageable || !selected?.active,
                  onClick: () => setModal("lambda"),
                  tooltip: "Run Scala Lambda",
                },
              ]
            : []),
        ]
      : [];

  const buttonProps: Array<ButtonWithIconProps | React.ReactElement> = [
    {
      icon: faPencilAlt,
      disabled: !manageable,
      onClick: () => setModal("edit"),
      tooltip: "Edit Integration",
    },
    {
      icon: selected?.active === false ? faPlay : faPause,
      disabled: !manageable,
      onClick: () => toggleIntegrationStatus(),
      id: "integration-suspend-reinstate",
      tooltip:
        selected?.active === false
          ? "Activate Integration"
          : "Suspend Integration",
    },
    <UncontrolledDropdown key="History">
      <DropdownToggle
        tag={ButtonWithIcon}
        disabled={!manageable || !selected}
        icon={faTimelineArrow}
        title="History"
      ></DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => navigate(`/admin/integrations/${selected?.id}`)}
        >
          Sync History
        </DropdownItem>
        <DropdownItem
          onClick={() =>
            navigate(`/admin/integrations/${selected?.id}/computeHistory`)
          }
        >
          Compute History
        </DropdownItem>
        {platform?.superAdmin &&
          selected?.vendor === "Yardi" &&
          (selected.configuration as YardiConfiguration).server && (
            <DropdownItem
              onClick={() =>
                navigate(`/admin/integrations/${selected?.id}/ecsSync`)
              }
            >
              Restore History
            </DropdownItem>
          )}
      </DropdownMenu>
    </UncontrolledDropdown>,
    {
      icon: faDollarSign,
      disabled: !manageable,
      onClick: () => setModal("financials"),
      tooltip: "Financial Accounts",
    },
    {
      icon: faTree,
      disabled: !manageable,
      onClick: () => navigate(`/admin/integrations/${selected?.id}/trees`),
      tooltip: "Account Trees",
    },
    ...overlordButtons,
    {
      icon: faTrash,
      disabled: !manageable,
      color: "danger",
      onClick: () => setModal("delete"),
      tooltip: "Delete Integration",
    },
  ];

  const tableCols: Array<KeyValue<Integration>> = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "vendor",
      title: "Vendor",
    },
    {
      key: "last_sync",
      title: "Last Sync",
      toValue: (i) => (
        <span
          className={
            i.last_sync?.success_count === 0 || i.last_sync?.failure_count
              ? "text-danger"
              : ""
          }
        >
          {dateToString(dateOf(i.last_sync?.started))}
        </span>
      ),
    },
    {
      key: "last_computation",
      title: "Last Compute",
      toValue: (i) => (
        <span
          className={i.last_computation?.failure_count ? "text-danger" : ""}
        >
          {dateToString(dateOf(i.last_computation?.started))}
        </span>
      ),
    },
    {
      key: "active",
      title: "Status",
      toValue: (i) => (i.active ? "Active" : "Inactive"),
    },
    {
      key: "organization_name",
      title: "Organization",
    },
  ];

  return (
    <>
      <ModernCrumbar primary="Manage Integrations">
        <ButtonWithIcon
          label="Add Integration"
          icon={faPlus}
          onClick={() => setModal("add")}
          size="sm"
          color="primary"
          className="ms-auto align-self-center"
        />
      </ModernCrumbar>
      <Input
        type="file"
        multiple
        innerRef={fileInput}
        onChange={onFileSelected}
        className="d-none"
      />
      <LoadilyFadily className="jh-page-layout">
        <ActionBar buttonProps={buttonProps} />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<Integration>
            selected={selected}
            onSelectedChange={(s) => setSelectedId(s?.id)}
            columns={tableCols}
            rows={integrations}
          />
        </div>
      </LoadilyFadily>
      {modal === "add" ? (
        <AddEditIntegrationModal
          vendor={selectedIntegrationVendor}
          onSubmit={onAddEditModalSubmit}
          onClose={onAddEditModalClose}
          setSelectedIntegrationVendor={setSelectedIntegrationVendor}
        />
      ) : modal === "edit" && selected ? (
        <AddEditIntegrationModal
          id={selected.id}
          vendor={selected.vendor}
          onSubmit={onAddEditModalSubmit}
          onClose={onAddEditModalClose}
          setSelectedIntegrationVendor={setSelectedIntegrationVendor}
        />
      ) : modal === "delete" && selected ? (
        <DeleteModal<Integration>
          id={selected.id}
          entityName="Integration"
          identificationKey="name"
          getEntity={getIntegrationById}
          deleteEntity={deleteIntegration}
          onSubmit={onDeleteModalSubmit}
          onClose={() => setModal(undefined)}
        />
      ) : modal === "compute" && selected?.active ? (
        <ComputeIntegrationModal
          integration={selected}
          onClose={() => setModal(undefined)}
        />
      ) : modal === "lambda" && selected?.active ? (
        <RunLambdaModal
          integration={selected}
          onClose={() => setModal(undefined)}
        />
      ) : modal === "financials" && selected ? (
        <FinancialAccountsModal
          system={selected}
          onClose={() => setModal(undefined)}
          onSubmit={() => fetchIntegrations().then(() => setModal(undefined))}
        />
      ) : null}
    </>
  );
};

export default withAlertModal(Integrations);
