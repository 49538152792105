import { mapKeys, toLower } from "lodash";

export const commonFinanceColumns = {
  income_market_rent: "Market Rent",
  income_gain_loss_to_lease: "Gain / Loss To Lease",
  gross_potential_rent: "Gross Potential Rent",

  income_vacancy: "Vacancy",
  income_bad_debt: "Bad Debt",
  income_delinquency: "Delinquency",
  income_employee_units: "Employee Units",
  income_non_income_units: "Non-Income Units - Model And Office",
  income_down_units: "Down Units",
  income_concessions: "Concessions",
  rental_income_adjustments: "Total Rental Income Adjustments",

  net_rental_income: "Net Rental Income",

  income_commercial_income: "Commercial Income",
  income_commercial_vacancy: "Commercial Vacancy",
  income_cam_charges: "Cam Charges",
  total_commercial_income: "Total Commercial Income",

  income_laundry_vending: "Laundry And Vending",
  income_parking: "Parking",
  income_late_charges: "Late Charges",
  income_utilities_electricity: "Utilities Income: Electricity",
  income_utilities_gas: "Utilities Income: Gas",
  income_utilities_trash: "Utilities Income: Trash",
  income_utilities_water: "Utilities Income: Water",
  income_utilities_cable: "Utilities Income: Cable",
  income_utilities_pest: "Utilities Income: Pest",
  income_utilities_other: "Utilities Income: Other",
  income_pet: "Pet",
  income_storage: "Storage",
  income_application_fees: "Application Fees",
  income_renters_insurance: "Renter's Insurance",
  income_admin_fees: "Admin Fees",
  income_furniture_rental: "Furniture Rental",
  income_termination_fees: "Termination Fees",
  income_prepaid_rent: "Prepaid Rent",
  income_move_out_income: "Move Out Income",
  income_short_term_premiums: "Short Term Premiums",
  income_community_fees: "Community Fees",
  income_damages_reimbursement: "Damages Reimbursement",
  income_additional_resident_fee: "Additional Resident Fee",
  income_misc_other_income: "Misc. Other Income",
  total_other_income: "Total Other Income",

  total_operating_revenue: "Total Operating Revenue",

  expense_manager_salary: "Manager Salary",
  expense_maintenance_salary: "Maintenance Salary",
  expense_leasing_agent_salary: "Leasing Agent Salary",
  expense_other_salaries: "Other Salaries",
  expense_payroll_taxes_benefits: "Payroll Taxes & Benefits",
  expense_bonuses_commissions: "Bonuses And Commissions",
  total_payroll: "Total Payroll",

  expense_legal_fees: "Legal Fees",
  expense_accounting_audit_fees: "Accounting And Audit Fees",
  expense_hvac_maintenance: "Hvac Maintenance",
  expense_plumbing: "Plumbing",
  expense_elevator_maintenance: "Elevator Maintenance",
  expense_contract_maintenance: "Contract Maintenance",
  expense_other_contract_services: "Other Contract Services",
  total_contract_services: "Total Contract Services",

  expense_advertising: "Advertising",
  expense_broker_commissions: "Broker Commissions",
  expense_seo: "SEO",
  expense_website: "Website",
  expense_marketing: "Marketing",
  total_marketing: "Total Marketing",

  expense_bank_fees: "Bank Fees",
  expense_meals_entertainment: "Meals And Entertainment",
  expense_housekeeping_laundry: "Housekeeping And Laundry",
  expense_corporate_units: "Corporate Units",
  expense_commercial_expenses: "Commercial Expenses",
  expense_office_model_down_units: "Office, Model, Down Units",
  expense_office_expenses: "Office Expenses",
  expense_cable: "Cable",
  expense_security: "Security",
  expense_technology_allocation: "Technology Allocation",
  expense_training: "Training",
  expense_dues_subscriptions: "Dues And Subscriptions",
  expense_miscellaneous_expenses: "Miscellaneous Expenses",
  total_general_administrative: "Total General & Administrative",

  expense_management_fees: "Management Fees",
  total_management_fees: "Total Management Fees",

  expense_repair_costs: "Repair Costs",
  expense_maintenance_fees: "Maintenance Fees",
  expense_cleaning_fees: "Cleaning Fees",
  expense_exterior_maintenance: "Exterior Maintenance",
  expense_interior_maintenance: "Interior Maintenance",
  expense_amenities: "Amenities",
  expense_pool: "Pool",
  expense_parking_maintenance: "Parking Maintenance",
  expense_landscaping: "Landscaping",
  expense_snow_removal: "Snow Removal",
  expense_unit_turnover: "Unit Turnover",
  total_repairs_maintenance: "Total Repairs And Maintenance",

  expense_tenant_improvements: "Tenant Improvements",
  expense_leasing_commissions: "Leasing Commissions",
  total_commercial_expenses: "Total Commercial Expenses",

  controllable_operating_expenses: "Controllable Operating Expenses",

  total_controllable_net_operating_income:
    "Total Controllable Net Operating Income / (Loss)",

  expense_ground_rents: "Ground Rents",
  expense_trash_removal: "Trash Removal",
  expense_gas: "Gas",
  expense_fuel: "Fuel",
  expense_electricity: "Electricity",
  expense_water: "Water",
  expense_sewer: "Sewer",
  expense_pest_control: "Pest Control",
  expense_utilities_other: "Utilities - Other",
  total_utilities: "Total Utilities",

  expense_real_estate_taxes: "Real Estate Taxes",
  expense_other_taxes_assessments_fees: "Other Taxes, Assessments And Fees",
  total_taxes_fees: "Total Taxes & Fees",

  expense_insurance: "Insurance",
  expense_license_permits: "License And Permits",
  total_insurance: "Total Insurance",

  non_controllable_operating_expenses: "Non-Controllable Operating Expenses",

  net_operating_income: "Net Operating Income / (Loss)",

  expense_interest: "Interest",
  expense_principal: "Principal",
  total_debt_service: "Total Debt Service",

  net_operating_income_after_debt_service:
    "Net Operating Income After Debt Service",

  expense_replacement_reserves: "Replacement Reserves",
  expense_depreciation: "Depreciation",
  expense_capital_improvements: "Capital Improvements",
  expense_capital_other: "Other",
  total_capital_improvements: "Total Capital Improvements",

  cash_flow: "Cash Flow",
};

export type CommonFinancialColumn = keyof typeof commonFinanceColumns;

export type CommonAccountTree = SignedCommonAccountTree | Record<string, never>;

// The nonsense with a sign is so that we can store Expenses and Income both as
// positive values in the financials table, and then subtract Expenses when
// calculating NOI. So it indicates whether a positive value in a column
// is in fact a positive or negative value with respect to total income/reality.
export type SignedCommonAccountTree = Partial<{
  [key in CommonFinancialColumn]: CommonAccountTree;
}> & { type: "income" | "expense" };

export const isSignedCommonAccountTree = (
  tree: CommonAccountTree,
): tree is SignedCommonAccountTree => typeof (tree as any).type === "string";

// account codes are prefixed by a sign indicating their global positivity. Plus
// means a positive value is income. Minus means a positive value in expense.
export type CommonAccountMapping = Partial<
  Record<CommonFinancialColumn, string[]>
>;

export const commonAccountTree: SignedCommonAccountTree = {
  type: "income",
  cash_flow: {
    type: "income",

    net_operating_income_after_debt_service: {
      type: "income",

      net_operating_income: {
        type: "income",

        total_controllable_net_operating_income: {
          type: "income",

          total_operating_revenue: {
            type: "income",

            net_rental_income: {
              type: "income",

              gross_potential_rent: {
                type: "income",
                income_market_rent: {},
                income_gain_loss_to_lease: {},
              },

              rental_income_adjustments: {
                type: "income",
                income_vacancy: {},
                income_bad_debt: {},
                income_delinquency: {},
                income_employee_units: {},
                income_non_income_units: {},
                income_down_units: {},
                income_concessions: {},
              },
            },

            total_commercial_income: {
              type: "income",
              income_commercial_income: {},
              income_commercial_vacancy: {},
              income_cam_charges: {},
            },

            total_other_income: {
              type: "income",
              income_laundry_vending: {},
              income_parking: {},
              income_late_charges: {},
              income_utilities_electricity: {},
              income_utilities_gas: {},
              income_utilities_trash: {},
              income_utilities_water: {},
              income_utilities_cable: {},
              income_utilities_pest: {},
              income_utilities_other: {},
              income_pet: {},
              income_storage: {},
              income_application_fees: {},
              income_renters_insurance: {},
              income_admin_fees: {},
              income_furniture_rental: {},
              income_termination_fees: {},
              income_prepaid_rent: {},
              income_move_out_income: {},
              income_short_term_premiums: {},
              income_community_fees: {},
              income_damages_reimbursement: {},
              income_additional_resident_fee: {},
              income_misc_other_income: {},
            },
          },
          controllable_operating_expenses: {
            type: "expense",

            total_payroll: {
              type: "expense",
              expense_manager_salary: {},
              expense_maintenance_salary: {},
              expense_leasing_agent_salary: {},
              expense_other_salaries: {},
              expense_payroll_taxes_benefits: {},
              expense_bonuses_commissions: {},
            },

            total_contract_services: {
              type: "expense",
              expense_legal_fees: {},
              expense_accounting_audit_fees: {},
              expense_hvac_maintenance: {},
              expense_plumbing: {},
              expense_elevator_maintenance: {},
              expense_contract_maintenance: {},
              expense_other_contract_services: {},
            },

            total_marketing: {
              type: "expense",
              expense_advertising: {},
              expense_broker_commissions: {},
              expense_seo: {},
              expense_website: {},
              expense_marketing: {},
            },

            total_general_administrative: {
              type: "expense",
              expense_bank_fees: {},
              expense_meals_entertainment: {},
              expense_housekeeping_laundry: {},
              expense_corporate_units: {},
              expense_commercial_expenses: {},
              expense_office_model_down_units: {},
              expense_office_expenses: {},
              expense_cable: {},
              expense_security: {},
              expense_technology_allocation: {},
              expense_training: {},
              expense_dues_subscriptions: {},
              expense_miscellaneous_expenses: {},
            },

            total_management_fees: {
              type: "expense",
              expense_management_fees: {},
            },

            total_repairs_maintenance: {
              type: "expense",
              expense_repair_costs: {},
              expense_maintenance_fees: {},
              expense_cleaning_fees: {},
              expense_exterior_maintenance: {},
              expense_interior_maintenance: {},
              expense_amenities: {},
              expense_pool: {},
              expense_parking_maintenance: {},
              expense_landscaping: {},
              expense_snow_removal: {},
              expense_unit_turnover: {},
            },

            total_commercial_expenses: {
              type: "expense",
              expense_tenant_improvements: {},
              expense_leasing_commissions: {},
            },
          },
        },

        non_controllable_operating_expenses: {
          type: "expense",

          total_utilities: {
            type: "expense",
            expense_ground_rents: {},
            expense_trash_removal: {},
            expense_gas: {},
            expense_fuel: {},
            expense_electricity: {},
            expense_water: {},
            expense_sewer: {},
            expense_pest_control: {},
            expense_utilities_other: {},
          },

          total_taxes_fees: {
            type: "expense",
            expense_real_estate_taxes: {},
            expense_other_taxes_assessments_fees: {},
          },

          total_insurance: {
            type: "expense",
            expense_insurance: {},
            expense_license_permits: {},
          },
        },
      },
      total_debt_service: {
        type: "expense",
        expense_interest: {},
        expense_principal: {},
      },
    },
    total_capital_improvements: {
      type: "expense",
      expense_replacement_reserves: {},
      expense_depreciation: {},
      expense_capital_improvements: {},
      expense_capital_other: {},
    },
  },
} as const;

const commonAccountTotalType: Partial<
  Record<CommonFinancialColumn, "income" | "expense">
> = {
  cash_flow: "income",
  net_operating_income_after_debt_service: "income",
  net_operating_income: "income",
  total_controllable_net_operating_income: "income",
  total_operating_revenue: "income",
  net_rental_income: "income",
  gross_potential_rent: "income",
  rental_income_adjustments: "income",
  total_commercial_income: "income",
  total_other_income: "income",
  controllable_operating_expenses: "expense",
  total_payroll: "expense",
  total_contract_services: "expense",
  total_marketing: "expense",
  total_general_administrative: "expense",
  total_management_fees: "expense",
  total_repairs_maintenance: "expense",
  total_commercial_expenses: "expense",
  non_controllable_operating_expenses: "expense",
  total_utilities: "expense",
  total_taxes_fees: "expense",
  total_insurance: "expense",
  total_debt_service: "expense",
  total_capital_improvements: "expense",
} as const;

export const commonAccountType = (column: CommonFinancialColumn) =>
  commonAccountTotalType[column] ??
  (column.startsWith("expense") ? "expense" : "income");

export const commonAccountAliases: Record<string, string> = mapKeys(
  {
    "Commercial: Tenant Improvements": "Tenant Improvements",
    "Exterior Decorating": "Exterior Maintenance",
    "Interior Decorating": "Interior Maintenance",
    "Non-Income Units - Models and Office":
      "Non-Income Units - Model And Office",
    "Commercial: Leasing Commissions": "Broker Commissions",
    "Gross Potential Rent": "Market Rent", // <- this is to work around bad Nico mapper names
  },
  (_value, key) => toLower(key),
);
