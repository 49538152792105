import React, { useContext, useEffect, useState } from "react";

import { InsightCustomComponentProps } from "../../services/models";
import PlatformContext from "../app/PlatformContext";
import JhTable, { JhTableCol, TableRow } from "../common/table/JhTable";
import { Skeleton } from "../dashboard/DashboardBoard/MainBoard/BoardRow/SimpleCharts";
import { RowDefinition, getData } from "./getData";

interface GenericTableChartProps {
  columns: JhTableCol[];
  rowDefinitions: RowDefinition[];
}

const GenericTableChart: React.FC<
  InsightCustomComponentProps & GenericTableChartProps
> = ({ columns, rowDefinitions, selection }) => {
  const { insightsMap } = useContext(PlatformContext).platform!;
  const [rows, setRows] = useState<TableRow[]>();
  useEffect(() => {
    getData(columns!, rowDefinitions!, insightsMap, selection).then(setRows);
  }, [insightsMap, selection, columns, rowDefinitions]);
  if (!rows) {
    return <Skeleton />;
  }
  return (
    <div className="jh-chart-content-without-dimension-filter">
      <JhTable
        columns={columns!}
        rows={rows}
        rowHeaders={rowDefinitions!.map((d) => d.header)}
      />
    </div>
  );
};

export default GenericTableChart;
