import {
  ReportColumn,
  ReportDataColumn,
  ReportSheetData,
} from "@joyhub-integration/shared";

import { formatValue } from "../../../../utils/formatValue";
import getDynamicFont from "./getDynamicFont";

interface ChangeCardPropsType {
  data: ReportSheetData;
  xItems: {
    column: ReportDataColumn<any>;
    index: number;
  }[];
  yItems: {
    column: ReportDataColumn<any> | ReportColumn<"Unresolved">;
    index: number;
  }[];
}

export const ChangeCard = (props: ChangeCardPropsType) => {
  const { data, xItems, yItems } = props;

  const { boxContainer, getCustomDynamicFont } = getDynamicFont();

  const xAxisIndex = !isNaN(Number(data.xAxisIndex))
    ? Number(data.xAxisIndex)
    : data.rows.length - 1;

  const xItem = xItems.length > 0 ? xItems[0] : undefined;
  const yItemOne = yItems.length > 0 ? yItems[0] : undefined;
  const yItemTwo = yItems.length > 1 ? yItems[1] : undefined;

  const yValueOne = yItemOne && data.rows[xAxisIndex]?.values[yItemOne.index];
  const yValueTwo = yItemTwo && data.rows[xAxisIndex]?.values[yItemTwo.index];
  const xValue = xItem && data.rows[xAxisIndex]?.values[xItem.index];
  const yHeaderOne = yItemOne && data.columns[yItemOne.index]?.header;
  const yHeaderTwo = yItemTwo && data.columns[yItemTwo.index]?.header;

  const formattedValueTwo =
    yItemTwo && formatValue(yValueTwo, data.columns[yItemTwo.index]);
  const isNegative =
    typeof formattedValueTwo === "string" && formattedValueTwo.startsWith("-");
  const isPositive =
    typeof formattedValueTwo === "string" && formattedValueTwo.startsWith("+");

  return (
    <div
      className="w-100 h-100"
      style={{ overflow: "hidden", whiteSpace: "nowrap" }}
    >
      <div
        ref={boxContainer}
        className="w-100 h-100 d-flex flex-col align-items-center justify-content-between"
      >
        <div style={{ fontSize: getCustomDynamicFont(1), fontWeight: "bold" }}>
          {yHeaderOne || <div style={{ color: "gray" }}>No Title</div>}
        </div>
        <div
          className="w-100 d-flex flex-row justify-content-center align-items-center"
          style={{ gap: getCustomDynamicFont(1.5) }}
        >
          <div
            style={{
              fontSize: getCustomDynamicFont(2.5),
              fontWeight: "bold",
            }}
          >
            {yValueOne ? (
              formatValue(yValueOne, data.columns[yItemOne.index])
            ) : (
              <div style={{ color: "gray" }}>No value</div>
            )}
          </div>
          <div className="d-flex flex-col align-items-center justify-content-center">
            <div
              style={{
                fontSize: getCustomDynamicFont(2),
                fontWeight: "bold",
                color: `${isNegative ? "#b35454" : "#3eb214"}`,
                lineHeight: getCustomDynamicFont(2),
              }}
            >
              {yValueTwo ? (
                isNegative ? (
                  formattedValueTwo
                ) : (
                  `${isPositive ? formattedValueTwo : "+" + formattedValueTwo}`
                )
              ) : (
                <div style={{ color: "gray" }}>No value</div>
              )}
            </div>
            <div
              style={{
                fontSize: getCustomDynamicFont(0.6),
                fontWeight: "bold",
              }}
            >
              {yHeaderTwo || <div style={{ color: "gray" }}>No Title</div>}
            </div>
          </div>
        </div>
        <div style={{ fontSize: getCustomDynamicFont(0.6), color: "#666666" }}>
          {xValue ? (
            formatValue(xValue, data.columns[xItem.index])
          ) : (
            <div style={{ color: "gray" }}>No basis</div>
          )}
        </div>
      </div>
    </div>
  );
};
