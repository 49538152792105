import { CSSProperties } from "react";

import {
  CellErrorValue,
  CellFormulaValue,
  CellHyperlinkValue,
  CellRichTextValue,
  CellSharedFormulaValue,
  CellValue,
} from "exceljs";
import { isISOString, PureDate } from "../util";
import { excelFormatDate, excelFormatNumber } from "./excelReport";
import {
  isComparisonValue,
  isPropertyValue,
  ReportDataValue,
} from "./reportData";
import {
  ReportCellCondition,
  ReportCellFormat,
  ReportCellStyle,
  RowType,
} from "./reportDefinition";

export const cellDisplayValue = (value: ReportDataValue) =>
  isComparisonValue(value) ? value.comparison : value;

export const formatCellValue = (value: ReportDataValue, numFmt?: string) => {
  const displayValue = cellDisplayValue(value);
  return typeof displayValue !== "number" || numFmt == null
    ? (displayValue ?? "").toString()
    : excelFormatNumber(displayValue, numFmt);
};

export const formatStringValue = (value: ReportDataValue) =>
  isPropertyValue(value) ? value.attribute : value;

export const formatCellDate = (value: ReportDataValue, dateFmt?: string) => {
  if (!isISOString(value) || dateFmt == null) return (value ?? "").toString();
  return excelFormatDate(new PureDate(value), dateFmt);
};

export const conditionMatches = (
  value: any,
  condition?: ReportCellCondition,
) => {
  switch (condition?.operator) {
    case "between":
      return (
        typeof value === "number" &&
        value >= condition.value[0] &&
        value <= condition.value[1]
      );
    case "lessThan":
      return typeof value === "number" && value < condition.value;
    case "lessThanOrEqual":
      return typeof value === "number" && value <= condition.value;
    case "greaterThan":
      return typeof value === "number" && value > condition.value;
    case "greaterThanOrEqual":
      return typeof value === "number" && value >= condition.value;
    case "equal":
      return typeof value === "number" && value === condition.value;
    case "notEqual":
      return typeof value === "number" && value !== condition.value;
    default:
      return true;
  }
};

export const typeMatches = (cellType: RowType, cellTypes?: RowType[]) =>
  cellTypes === undefined
    ? cellType === "Normal"
    : cellTypes.includes(cellType);

export const applyStyle = (
  result: CSSProperties,
  {
    bold,
    italic,
    underline,
    borderAfter,
    borderBefore,
    color,
    background,
    size,
    indent,
  }: ReportCellStyle,
) => {
  if (bold && !result.fontWeight) result.fontWeight = 600;
  if (italic && !result.fontStyle) result.fontStyle = "italic";
  if (underline && !result.textDecoration) result.textDecoration = "underline";
  if (borderAfter && !result.borderBottom)
    result.borderBottom = "1px solid black"; // assumes pivot
  if (borderBefore && !result.borderTop) result.borderTop = "1px solid black";
  if (indent && !result.paddingLeft) result.paddingLeft = `${indent * 0.85}em`;
  if (color && !result.color) result.color = color;
  if (background && !result.background) result.backgroundColor = background;
  if (size && !result.fontSize) result.fontSize = size + "px";
};

export const excelCss = (style: ReportCellStyle): CSSProperties => {
  const result: CSSProperties = {};
  applyStyle(result, style);
  return result;
};

export const cellStyle = (
  value: ReportDataValue,
  formats: ReportCellFormat[],
  cellType: RowType,
): CSSProperties | undefined => {
  const displayValue = cellDisplayValue(value);
  const result: CSSProperties = {};
  for (const format of formats) {
    if (
      typeMatches(cellType, format.cellTypes) &&
      conditionMatches(displayValue, format.condition)
    ) {
      applyStyle(result, format);
    }
  }
  return result;
};

// Deplicated from excelUtil.ts and other places

export const boldFont = { bold: true } as const;
export const thinBorder = { style: "thin" } as const;
export const hairBorder = { style: "hair" } as const;
export const thinBottomBorder = { bottom: thinBorder } as const;
export const thinTopBorder = { top: thinBorder } as const;
export const thinRightBorder = { right: thinBorder } as const;
export const thinLeftBorder = { left: thinBorder } as const;

// I'm almost certain the error shape here is a bug in exceljs typing
type ExtendedCellValue = CellValue | { error: CellErrorValue };

export const isFormula = (
  value: ExtendedCellValue,
): value is CellFormulaValue =>
  value != null &&
  typeof value === "object" &&
  typeof (value as any).formula === "string";

export const isSharedFormula = (
  value: ExtendedCellValue,
): value is CellSharedFormulaValue =>
  value != null &&
  typeof value === "object" &&
  typeof (value as any).sharedFormula === "string";

const isHyperlink = (value: ExtendedCellValue): value is CellHyperlinkValue =>
  value != null &&
  typeof value === "object" &&
  typeof (value as any).hyperlink === "string";

const isRichText = (value: ExtendedCellValue): value is CellRichTextValue =>
  value != null &&
  typeof value === "object" &&
  Array.isArray((value as any).richText);

const trimNonEmpty = (s: string) => {
  const t = s.trim();
  return t === "" ? undefined : t;
};

export const excelNonEmptyString = (
  value: ExtendedCellValue,
): string | undefined =>
  typeof value === "number"
    ? value.toString()
    : typeof value === "string"
      ? trimNonEmpty(value)
      : value instanceof Date
        ? new PureDate(value).toISOString()
        : isRichText(value)
          ? trimNonEmpty(value.richText.map((r) => r.text).join(""))
          : isHyperlink(value)
            ? excelNonEmptyString(value.text)
            : isFormula(value)
              ? excelNonEmptyString(value.result)
              : isSharedFormula(value)
                ? excelNonEmptyString(value.result)
                : undefined;

// {"formula":"FALSE()"}
// {"formula":"TRUE()","result":1}
export const excelBoolean = (value: ExtendedCellValue): boolean =>
  value === true ||
  (typeof value === "number" && value !== 0) ||
  (typeof value === "string" && !!value.match(/^\s*[ty]/i)) ||
  (isFormula(value) && excelBoolean(value.result)) ||
  (isSharedFormula(value) && excelBoolean(value.result));

export const formatSimpleExcelValue = (value: ExtendedCellValue): string =>
  excelNonEmptyString(value) ?? "";
