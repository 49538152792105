import { useEffect, useState } from "react";
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";

import { unexpectedError } from "../../../constants";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface DeleteModalProps<T> {
  id: number;
  entityName: string;
  blurb?: string;
  identificationKey: keyof T;
  getEntity: (id: number) => T | Promise<T>;
  deleteEntity: (id: number, entity: T) => Promise<void>;
  onClose: () => void;
  onSubmit: (entity: T) => void;
}

function DeleteModal<T>({
  id,
  getEntity,
  deleteEntity,
  entityName,
  blurb,
  identificationKey,
  onSubmit,
  onClose,
}: DeleteModalProps<T>) {
  const [serverError, setServerError] = useState<string>("");
  const [entity, setEntity] = useState<T>();

  useEffect(() => {
    Promise.resolve(getEntity(id))
      .then((res) => {
        setEntity(res);
      })
      .catch(() => setServerError(unexpectedError));
  }, [id, getEntity]);

  function doSubmit() {
    deleteEntity(id, entity!)
      .then(() => onSubmit(entity!))
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 400 && response.data.message) {
          setServerError(response.data.message);
        } else {
          setServerError(unexpectedError);
        }
      });
  }

  return (
    <UncontrolledModal onClosed={onClose}>
      <ModalHeader>{`Delete ${entityName}`}</ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        {`Are you sure you want to delete the ${entityName.toLowerCase()} '${
          entity?.[identificationKey]
        }'? ${blurb ?? ""}`}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button color="danger" onClick={doSubmit} disabled={entity == null}>
          Delete
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
}

export default DeleteModal;
