import "./properties.scss";

import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import React from "react";

import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import ButtonWithIcon, {
  ButtonWithIconProps,
} from "../../common/button/ButtonWithIcon";

export type PropertyDeleteCellProps = {
  propertyId: number;
  onDeleteProperty: (propertyId: number) => void;
};

const PropertyDeleteCell: React.FC<
  WithAlertModalProps & PropertyDeleteCellProps
> = ({ propertyId, onDeleteProperty }) => {
  const trashButtonProps: ButtonWithIconProps = {
    id: propertyId + "-delete",
    className: "property-delete-button",
    iconStyle: { width: 15, height: 15 },
    icon: faTrashAlt,
    onClick: () => onDeleteProperty(propertyId),
  };

  return (
    <>
      <div style={{ display: "inline" }}>
        <ButtonWithIcon {...trashButtonProps}></ButtonWithIcon>
      </div>
    </>
  );
};

export default withAlertModal(PropertyDeleteCell);
