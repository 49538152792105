import { propertyAttributeIsNumber } from "../property/utils";
import { ReportInsight, Resolution, Unresolved } from "./reportInsight";

export type SortOrder = "Ascending" | "Descending";

export type ColumnSort = { priority?: number; order: SortOrder }; // low priority wins

export type GraphAxis = "x" | "y" | "y2";

export type GraphType = "Bar" | "Line" | "Scatter" | "Pie" | "Snapshot";

export type SnapshotType = "Number" | "Compare" | "Goal";

export type AttachmentType = "excel" | "pdf" | "csv" | "all";

// This used to have a string discriminator attribute as opposed to
// just using structural discrimination to identify column types, but
// it was too hard to get the UI schema editor to behave.
export type ReportColumnBase = {
  header: string;
  bucket?: string;
  subBucket?: string;
  width?: number;
  sort?: ColumnSort;
  graphAxis?: GraphAxis;
  showAverageLine?: boolean;
  updatedAtAxis?: number;
  displaySubColumns?: string[];
};

export type ReportDateColumn = ReportColumnBase & {
  dateFmt: string;
};

export type ReportDimensionColumn = ReportColumnBase & {
  dimension: string;
};

export type ReportInterval = "Year" | "Quarter" | "Month" | "Week" | "Day";
export type ReportDuration = {
  interval: ReportInterval;
  count: number | "YTD";
};

export type Aggregate = "Sum" | "Average";
export type Period = "YTD" | ReportDuration;
export type PriorTimeFrame = {
  days?: number;
  weeks?: number;
  months?: number;
  quarters?: number;
  years?: number;
};

export type TimeFrame =
  | "PriorWeek"
  | "PriorMonth"
  | "PriorQuarter"
  | "PriorYear"
  | PriorTimeFrame;
export type Comparison = "Difference" | "Fraction" | "Delta"; // Delta??

export const isPriorTimeFrame = (
  tf: TimeFrame | undefined,
): tf is PriorTimeFrame => typeof tf === "object";

export const isReportDuration = (a: Period): a is ReportDuration =>
  typeof a === "object" && (a as any).interval != null;

// TODO: Versus should in fact just be part of the main insight algebra
// where timeframe is a part of the simple insight type

export type ReportInsightVersus<Res extends Resolution = Unresolved> = {
  insight: ReportInsight<Res>;
  comparison: Comparison;
  timeFrame?: TimeFrame;
  vsBaseline?: boolean;
};

export type RowType = "Normal" | "Group" | "Overall" | "Header" | "Bucket";

type UnaryCellCondition = {
  operator:
    | "equal"
    | "notEqual"
    | "greaterThan"
    | "lessThan"
    | "greaterThanOrEqual"
    | "lessThanOrEqual";
  value: number;
};

type BinaryCellCondition = {
  operator: "between";
  value: [number, number];
};

export type ReportCellCondition = UnaryCellCondition | BinaryCellCondition;

// Excel also supports icons but we're not going there now.
export type ReportCellStyle = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  color?: string;
  indent?: number;
  size?: number;
  background?: string; // unsupported for new due to exceljs bug
  borderBefore?: boolean;
  borderAfter?: boolean;
};

export type ReportCellFormat = ReportCellStyle & {
  condition?: ReportCellCondition;
  cellTypes?: RowType[]; // If unspecified, only normal cells
};

export type ColumnFormat = {
  numFmt?: string;
  numFmtValue?: string;
  cellFormats?: ReportCellFormat[]; // First matching rule wins
};

export type ReportInsightFields<Res extends Resolution = Unresolved> = {
  insight: ReportInsight<Res>;
  period?: Period;
  aggregate?: Aggregate;
  timeFrame?: TimeFrame;
  versus?: ReportInsightVersus<Res>;
  format?: ColumnFormat;
  filter?: ReportCellCondition;
  dimension?: [string, string]; // to pick a single dimension
};

export type ReportNumberAttributeFields<Res extends Resolution = Unresolved> = {
  attribute: string;
  versus?: ReportInsightVersus<Res>;
  format?: ColumnFormat;
  filter?: ReportCellCondition;
};

export type ReportAttributeColumn<Res extends Resolution = Unresolved> =
  ReportColumnBase & ReportNumberAttributeFields<Res>;

export type ReportInsightColumn<Res extends Resolution = Unresolved> =
  ReportColumnBase & ReportInsightFields<Res>;

export type ReportColumn<Res extends Resolution = Unresolved> =
  | ReportDateColumn
  | ReportDimensionColumn
  | ReportInsightColumn<Res>
  | ReportAttributeColumn<Res>;

export const isAttributeColumn = <Res extends Resolution>(
  a: ReportColumn<Res>,
): a is ReportAttributeColumn<Res> => (a as any).attribute != null;

export const isDateColumn = (
  a: ReportColumn<Resolution>,
): a is ReportDateColumn => (a as any).dateFmt != null;

export const isDimensionColumn = (
  a: ReportColumn<Resolution>,
): a is ReportDimensionColumn => (a as any).dimension != null;

export const isInsightColumn = <Res extends Resolution>(
  a: ReportColumn<Res>,
): a is ReportInsightColumn<Res> => (a as any).insight != null;

export const isNumberAttributeColumn = <Res extends Resolution>(
  a: ReportColumn<Res>,
): a is ReportAttributeColumn<Res> => {
  let attribute = (a as any).attribute;
  if (!attribute) {
    return false;
  }
  return propertyAttributeIsNumber(attribute);
};

export const isBooleanAttributeColumn = <Res extends Resolution>(
  a: ReportColumn<Res>,
): boolean => {
  return (
    (a as any)?.attribute === "is_comparable" ||
    (a as any)?.attribute?.startsWith("user_bool")
  );
};

export type ReportGrouping = {
  category: string; // this belongs as config but the UI does not support that
  sort: "Alphabetic" | "Numeric";
  layout: "Inline" | "Footer" | "Only";
};

export type DateString = string;

export type ReportAsOf = "Yesterday" | "LastMonth" | DateString;

export type RowsByDate = {
  rows: "Date";
} & ReportDuration;

export type RowsByProperty = {
  rows: "Property";
  grouping?: ReportGrouping;
};

export type RowsByDimension = {
  rows: "Dimension";
  dimension: string;
};

export type RowsByLease = {
  rows: "Lease";
  leases: "All" | "New" | "Renewal" | "InPlace"; // 'All' meaning 'New' or 'Renewal', of course
  period: "Week" | "Month" | "Today";
};

// really?
export type RowsByFinance = {
  rows: "Finance";
  tree?: string | undefined;
} & ReportDuration;

export type RowsByUnit = {
  rows: "Unit";
};

export type RowsByMortgage = {
  rows: "Mortgage";
};

export type RowsByInsightData = {
  rows: "InsightData";
};

export type SheetRows =
  | RowsByDate
  | RowsByProperty
  | RowsByDimension
  | RowsByLease
  | RowsByFinance
  | RowsByUnit
  | RowsByMortgage
  | RowsByInsightData;

// TODO: these should select by the "rows" attribute but we need to back-fill the
// rows attribute in stored reports before then.
export const isRowsByDate = (rows: SheetRows): rows is RowsByDate =>
  (rows as any).interval != null && rows.rows !== "Finance";

export const isRowsByDimension = (rows: SheetRows): rows is RowsByDimension =>
  (rows as any).dimension != null;

export const isRowsByLease = (rows: SheetRows): rows is RowsByLease =>
  (rows as any).leases != null;

export const isRowsByFinance = (rows: SheetRows): rows is RowsByFinance =>
  rows.rows === "Finance";

export const isRowsByMortgage = (rows: SheetRows): rows is RowsByMortgage =>
  rows.rows === "Mortgage";

export const isRowsByUnit = (rows: SheetRows): rows is RowsByUnit =>
  rows.rows === "Unit";

export const isRowsByInsightData = (
  rows: SheetRows,
): rows is RowsByInsightData => rows.rows === "InsightData";

export const isRowsByProperty = (rows: SheetRows): rows is RowsByProperty =>
  !isRowsByDate(rows) &&
  !isRowsByDimension(rows) &&
  !isRowsByLease(rows) &&
  !isRowsByFinance(rows) &&
  !isRowsByUnit(rows) &&
  !isRowsByMortgage(rows) &&
  !isRowsByInsightData(rows);

export type ReportTitle = {
  value: string;
  bold?: boolean;
  italic?: boolean;
  color?: string;
  size?: number;
  // TODO: alignment etc.
};

export type ReportSheet<Res extends Resolution = Unresolved> = {
  name: string;
  titles: ReportTitle[];
  columns: ReportColumn<Res>[];
  rows: SheetRows;
  graph?: boolean;
  graphType?: GraphType;
  snapshotType?: SnapshotType;
  xAxisIndex?: any;
  pivot?: boolean;
};

export type GenericReportDefinition<Res extends Resolution = Unresolved> = {
  subject?: string; // this arguably belongs in the scheduled email?
  filename?: string;
  asOf: ReportAsOf;
  perProperty?: boolean;
  body?: string;
  templates?: number[];
  sheets: ReportSheet<Res>[];
  perInsight?: boolean;
};
