import "./reports.css";

import { faPlus } from "@fortawesome/pro-light-svg-icons";
import React from "react";

import ButtonWithIconAndText from "../common/button/ButtonWithIconAndText";

interface NewReportButtonProps {
  onClick: () => void;
}

export const NewReportButton: React.FC<NewReportButtonProps> = (
  props: NewReportButtonProps,
) => {
  return (
    <ButtonWithIconAndText
      onClick={props.onClick}
      type="submit"
      color="primary"
      size="sm"
      icon={faPlus}
      text="New Report"
      className="report-add-button ms-auto align-self-center"
    />
  );
};
