import "./style.css";

import { ignoreCaseIncludesMatcher } from "@joyhub-integration/shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input, Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { dateOf, dateToMY, dateToString } from "../../../utils/date";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import JhSelect, { IDropListItem } from "../../common/JhSelect/JhSelect";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import JhCrumbar from "../../navbar/JhCrumbar";

type FinancialType = {
  id: number;
  name: string;
  dates: Array<Date>;
  type: "T12" | "Budget";
  uncategorized: number;
  received: Date;
  source: "Yardi" | "Entrata" | "RealPage" | "Resman" | "Fortress" | "Manual";
};

async function getFinancialData(): Promise<Array<FinancialType>> {
  return [
    {
      id: 1,
      name: "Test name",
      dates: [new Date("03/08/2024"), new Date("04/08/2024")],
      type: "Budget",
      uncategorized: 10,
      received: new Date("04/08/2024"),
      source: "Manual",
    },
  ];
}

const filterOptions = [
  { label: "Everything", value: "Everything" },
  { label: "Uncategorized Accounts", value: "Uncategorized Accounts" },
];

const ManageFinancials: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [rows, setRows] = useState<Array<FinancialType>>();
  const [selected, setSelected] = useState<FinancialType>();
  const [search, setSearch] = useState("");
  const [loaded, setLoaded] = useState<boolean>(false);
  const [filterOption, setFilterOption] = useState<IDropListItem>();

  const fetchRows = useCallback(
    async function () {
      return getFinancialData()
        .then((dataRes) => {
          dataRes.sort((a, b) => a.name.localeCompare(b.name));
          setRows(dataRes);
        })
        .catch(onUnexpectedError);
    },
    [onUnexpectedError],
  );

  useEffect(() => {
    fetchRows();
    setLoaded(true);
  }, [fetchRows]);

  const filteredData = useMemo(() => {
    const match = ignoreCaseIncludesMatcher(search);
    return rows?.filter(
      (u: FinancialType) =>
        search === "" || match(u.name) || match(u.type) || match(u.source),
    );
  }, [rows, search]);

  const tableCols: Array<KeyValue<FinancialType>> = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "dates",
      title: "Dates",
      toValue: (u) =>
        `${dateToMY(dateOf(u.dates[0]))} - ${dateToMY(dateOf(u.dates[1]))}`,
      sortValue: (u) => dateOf(u.dates[0])?.getTime(),
    },
    {
      key: "type",
      title: "Type",
    },
    {
      key: "uncategorized",
      title: "Uncategorized Accounts",
    },
    {
      key: "received",
      title: "Received",
      toValue: (u) => dateToString(dateOf(u.received)),
      sortValue: (u) => dateOf(u.received)?.getTime(),
    },
    {
      key: "source",
      title: "Source",
    },
  ];

  return (
    <LoadilyFadily loaded={loaded} className="jh-page-layout">
      <JhCrumbar
        primary="Administration"
        primaryPath="/admin"
        secondary="Manage Financials"
      >
        <Input
          className="w-auto ms-auto rounded-pill search-field"
          size={31}
          type="text"
          placeholder="Search by property name or address"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div style={{ width: 304, marginLeft: 10 }}>
          <JhSelect
            id="column"
            isSearchable={true}
            options={filterOptions}
            value={filterOption}
            onValueUpdate={(item) => {
              setFilterOption(item);
            }}
            isClearable
          />
        </div>
      </JhCrumbar>
      <div className="jh-page-content rows-admin-page admin-page">
        <TableWithSelection<FinancialType>
          selected={selected}
          onSelectedChange={(selected) => setSelected(selected)}
          columns={tableCols}
          rows={filteredData}
        />
        <Pagination
          aria-label="Page navigation example"
          size="sm"
          className="d-flex flex-row justify-content-end align-items-center mt-2"
        >
          <PaginationItem>
            <PaginationLink first href="#" />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" previous />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">1</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">2</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">3</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" next />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" last />
          </PaginationItem>
        </Pagination>
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(ManageFinancials);
