import "./columnChart.css";

import React, { ReactText } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

import { SecondaryAxisKeyValue, SortDirection } from "../../../services/models";
import {
  chartNumberFormatter,
  tooltipFormatter,
  tooltipSorter,
} from "../../../utils/chartNumberFormatter";

interface JhColumnChartProps {
  data: Array<Object>;
  xAxisKey: string;
  yAxisKeys: { [key: string]: SecondaryAxisKeyValue };
  yAxisUnit: string;
  tooltipFormatter?: Function;
  stackId?: string;
  tooltipSortDir?: SortDirection;
}

const JhColumnChart: React.FC<JhColumnChartProps> = (props) => {
  const { data, xAxisKey, yAxisKeys, yAxisUnit, stackId, tooltipSortDir } =
    props;

  function tooltipSorterForStacked() {
    const yAxisKeyToIndex = Object.keys(yAxisKeys).reduce(
      (prev, current, index) => {
        prev[current] = index;
        return prev;
      },
      {} as { [key: string]: number },
    );
    return function (a: Payload<number, string>) {
      return yAxisKeyToIndex[a.dataKey as string] * -1;
    };
  }

  const allColumnCharts = Object.entries(yAxisKeys).map(([key, value]) => {
    return (
      <Bar
        key={key}
        dataKey={key}
        unit={yAxisUnit}
        name={key}
        fill={value.colorHex}
        stroke={value.colorHex}
        stackId={stackId}
        yAxisId={value.orientation}
      />
    );
  });

  const leftYAxisUnit = Object.values(yAxisKeys).find(
    (axisKeyInfo) => axisKeyInfo.orientation === "left",
  )?.unit;
  const rightYAxisUnit = Object.values(yAxisKeys).find(
    (axisKeyInfo) => axisKeyInfo.orientation === "right",
  )?.unit;
  const chartClassName = stackId ? "jh-column-chart-stacked" : undefined;

  const tooBig = data.length > 30;

  return (
    <ResponsiveContainer minWidth={tooBig ? `${data.length * 20}px` : "100%"}>
      <BarChart data={data} className={chartClassName}>
        <CartesianGrid vertical={false} stroke="#d6d9da" />
        <XAxis
          dataKey={xAxisKey}
          angle={270}
          interval={0}
          tickFormatter={(value) => (value as string).substring(0, 10)}
          height={100}
          dy={50}
          dx={-7}
        />
        {rightYAxisUnit ? (
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={(v) =>
              chartNumberFormatter(v as number, rightYAxisUnit)
            }
          />
        ) : null}
        {leftYAxisUnit ? (
          <YAxis
            yAxisId="left"
            orientation="left"
            tickFormatter={(v) =>
              chartNumberFormatter(v as number, leftYAxisUnit)
            }
          />
        ) : null}
        <Tooltip
          formatter={(v: string | number | ReactText[], axisKey: string) =>
            tooltipFormatter(v, axisKey, yAxisKeys)
          }
          itemSorter={
            tooltipSortDir
              ? tooltipSorter(tooltipSortDir)
              : tooltipSorterForStacked()
          }
          isAnimationActive={false}
        />
        <Legend />
        {allColumnCharts.map((item) => item)}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default JhColumnChart;
