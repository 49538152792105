import { Of } from "nick-offerman";

// Parses '2020-01-01' into a date in the local timezone, otherwise it is interpreted at 00:00:00 UTC
// and so the locale date is off by one.
const toLocalDateTime = (value: string) => {
  const date = new Date(value);
  return date.getTime() + date.getTimezoneOffset() * 60 * 1000;
};

export const isISOString = (s: any): s is string =>
  typeof s === "string" && !!s.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);

export const isPureDate = (d: any): d is PureDate => d instanceof PureDate;

/** Converts the parameter to a `PureDate` but returns the parameter as-is
 * if it is already a `PureDate`, so not equivalent to a constructor.*/
export const asPureDate = (value: string | number | Date | PureDate) =>
  isPureDate(value) ? value : new PureDate(value);

/** Subclass of Date intended to represent a pure date, just year/month/day. Note that the
 * underlying time part of this will be midnight local time if parsed from a string. That
 * is, in US/East, new PureDate("2021-01-01") will be "2021-01-01T05:00:00.000Z".
 */
export class PureDate extends Date {
  constructor(value: string | number | Date = new Date()) {
    super(isISOString(value) ? toLocalDateTime(value) : value);
    this.setHours(0, 0, 0, 0);
  }

  static of = Of(PureDate);

  /** @param ago the number of months ago. For example, 1 means the last day of last month. If
   * zero, returns the current date. */
  lastDayOfPriorMonth(ago: number) {
    const date = new PureDate(this);
    if (ago > 0) {
      date.setDate(1);
      date.setMonth(date.getMonth() - ago + 1);
      date.setDate(0);
    }
    return date;
  }

  firstDayOfTheMonth() {
    const date = new PureDate(this);
    date.setDate(1);
    return date;
  }

  lastDayOfTheMonth() {
    const date = new PureDate(this);
    date.setDate(1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    return date;
  }

  isSameMonthAndYear(value: string | number | Date | PureDate) {
    const that = asPureDate(value);
    return (
      that.getFullYear() === this.getFullYear() &&
      that.getMonth() === this.getMonth()
    );
  }

  // "with" prefix because this returns a new instance
  withPlusDays(days: number) {
    const ret = new PureDate(this);
    ret.setDate(this.getDate() + days);
    return ret;
  }

  equals(value: string | number | Date | PureDate) {
    return this.toISOString() === asPureDate(value).toISOString();
  }

  lessThan(value: string | number | Date | PureDate) {
    return this < asPureDate(value);
  }

  greaterThan(value: string | number | Date | PureDate) {
    return this > asPureDate(value);
  }

  toString(): string {
    return this.toDateString();
  }

  toISOString(): string {
    return super.toISOString().slice(0, 10);
  }

  toISOMonth(): string {
    return super.toISOString().slice(0, 7); // 2004-11
  }

  toShortMonth(): string {
    return super.toLocaleString("default", { month: "short" }); // 'Jun'
  }

  toISOQuarter(): string {
    const date = new Date(super.valueOf());
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const quarter = Math.ceil(month / 3);

    return `${year}-Q${quarter}`;
  }

  toISOYear(): string {
    return super.toISOString().slice(0, 4); // 2004
  }

  toJSON(): string {
    return this.toISOString();
  }

  dayOfYear(): number {
    return Math.floor(
      (Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()) -
        Date.UTC(this.getFullYear(), 0, 0)) /
        24 /
        60 /
        60 /
        1000,
    );
  }

  weekOfYear(): number {
    return Math.floor(this.dayOfYear() / 7);
  }

  quarterOfYear(): number {
    return 1 + Math.floor(this.getMonth() / 3);
  }
}

/** Where a PureDate is serialized and deserialized to JSON it may have either form. */
export type PureDateIO<Deserialised extends boolean> = Deserialised extends true
  ? string
  : PureDate;

export type DateIO<Deserialised extends boolean> = Deserialised extends true
  ? string
  : Date;

export type PureWindow = [PureDate, PureDate];

export const pureWindowOf = (
  from: null | undefined | string | PureDate,
  to: null | undefined | string | PureDate,
): PureWindow | undefined =>
  from && to ? [PureDate.of(from), PureDate.of(to)] : undefined;
