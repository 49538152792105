import { find } from "lodash";
import { ChangeEvent, useCallback, useEffect } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { IframeInsightId } from "../../../../services/insightLibrary/insightDefinitions";
import insightLibraryService from "../../../../services/insightLibrary/insightsLibraryService";
import { ValidationError } from "../../../admin/integrations/addEditIntegrationModal/common";

interface GraphCardPropsType {
  dashboardInsight?: Record<string, any>;
  validationError: ValidationError;
  onClose: () => void;
  onSubmit: () => void;
  onUnexpectedError: (e?: any) => void;
  updateDashboardInsight: (fieldName: string, newData: any) => void;
}

export const IframeCard = (props: GraphCardPropsType) => {
  const {
    dashboardInsight,
    validationError,
    onUnexpectedError,
    updateDashboardInsight,
  } = props;

  const updateIframeData = useCallback(
    (newData?: Record<string, any>) => {
      const defaultIframe = { ...dashboardInsight?.stuff?.iframe };

      updateDashboardInsight("stuff", {
        iframe: { ...defaultIframe, ...newData },
      });
      updateDashboardInsight("linkedReport", undefined);
      updateDashboardInsight("name", "Iframe card");
    },
    [dashboardInsight?.stuff, updateDashboardInsight],
  );

  const handleHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.valueAsNumber;
    let height = 0;

    if (isNaN(inputValue)) height = 0;
    else height = inputValue;

    updateIframeData({ height });
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateIframeData({ url: e.target.value });
  };

  useEffect(() => {
    const setupInitialData = async () => {
      try {
        const insights = await insightLibraryService.getInsights();
        const iframeInsight = find(insights, { id: IframeInsightId });

        updateDashboardInsight("insight", iframeInsight);
      } catch (error) {
        onUnexpectedError(error);
      }
    };

    setupInitialData();
  }, [onUnexpectedError, updateDashboardInsight]);

  const isInvalidUrl = validationError.field === "iframeurl";
  const isInvalidHeight = validationError.field === "iframeheight";

  return (
    <div className="d-flex flex-col p-4" style={{ minHeight: "375px" }}>
      <FormGroup>
        <Label>URL</Label>
        <Input
          type="text"
          value={dashboardInsight?.stuff?.iframe?.url ?? ""}
          invalid={false}
          onChange={handleUrlChange}
          placeholder="https://example.com"
        />
        {isInvalidUrl && validationError.message && (
          <FormFeedback className="jh-block">
            {validationError.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Height</Label>
        <Input
          type="number"
          value={dashboardInsight?.stuff?.iframe?.height ?? 0}
          onChange={handleHeightChange}
          min={1}
        />
        {isInvalidHeight && validationError.message && (
          <FormFeedback className="jh-block">
            {validationError.message}
          </FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};
