import "./chartWrapper.css";

import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DashboardKindEnum,
  isPropertiesById,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import React, { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link, useLocation } from "react-router";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { OtherDashboardInsight as DashboardInsight } from "../../../services/models";
import PlatformContext from "../../app/PlatformContext";
import ChartInfo from "./ChartInfo";

export interface ChartWrapperProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  dashboardId?: number;
  dashboardInsight: DashboardInsight;
  heading?: string;
  onEditInsight?: () => void;
  onRemoveInsight?: () => void;
  drillable?: boolean /* supports click to drill-in */;
  editMode?: boolean /* editable insight */;
  showInfo?: boolean /* show info widget at the bottom */;
  noContentPadding?: boolean;
  selection: PropertiesSelection;
  dashboardKind:
    | DashboardKindEnum.Dashboard
    | DashboardKindEnum.PropertyDashboard;
  className?: string;
}

const NewLineRE = /(.*?)\n(.*)/;

const ChartWrapper = React.forwardRef<any, ChartWrapperProps>((props, ref) => {
  const {
    heading,
    dashboardId,
    dashboardInsight,
    children,
    onEditInsight,
    onRemoveInsight,
    drillable,
    editMode,
    showInfo,
    noContentPadding,
    selection,
    dashboardKind,
    className,
    ...rest
  } = props;
  const searchParams = useLocation().search;

  const platform = useContext(PlatformContext).platform!;
  const { embedInfo } = platform;

  function onEditInsightClick(e: React.MouseEvent) {
    e.preventDefault();
    onEditInsight && onEditInsight();
  }

  function onRemoveInsightClick(e: React.MouseEvent) {
    e.preventDefault();
    onRemoveInsight && onRemoveInsight();
  }

  const splitHeading = heading?.match(NewLineRE);

  const solePropertyId = isPropertiesById(selection) ? selection : undefined;
  const linkedReport = dashboardInsight.linkedReport;

  const insightUrl =
    linkedReport != null
      ? `/reports/my/${linkedReport}`
      : dashboardKind === DashboardKindEnum.PropertyDashboard
        ? `/properties/${solePropertyId}/dashboards/${dashboardId}/dashboard_insight/${dashboardInsight.id}`
        : `/dashboards/${dashboardId}/dashboard_insight/${dashboardInsight.id}`;
  const clickable = (linkedReport || drillable) && !editMode && !embedInfo;

  const contentClassName = clsx([
    heading || editMode
      ? "jh-chart-content"
      : ["jh-chart-content-without-heading", "d-flex", "align-items-center"],
    { "no-padding": noContentPadding },
  ]);

  const renderBody = () => (
    <div
      key={dashboardInsight.id}
      className={clsx(
        className,
        `jh-chart-wrapper overflow-hidden ${clickable ? "clickable" : ""}`,
      )}
      ref={ref}
      {...rest}
    >
      {heading || editMode ? (
        <div className="jh-chart-header flex-row align-items-center">
          {splitHeading ? (
            <span className="two-line">
              {splitHeading[1]}
              <br />
              {splitHeading[2]}
            </span>
          ) : heading ? (
            <span>{heading}</span>
          ) : null}
          {editMode && (
            <UncontrolledDropdown
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className="pull-right"
            >
              <DropdownToggle
                color="primary"
                outline
                className="py-2 px-2 border-0"
                style={{
                  lineHeight: 1,
                  marginRight: "-.5rem",
                }}
              >
                <FontAwesomeIcon icon={faBars} size="xs" />
              </DropdownToggle>
              <DropdownMenu end>
                {drillable ? (
                  <DropdownItem
                    tag={Link}
                    to={{
                      pathname: insightUrl,
                      search: searchParams,
                    }}
                    toggle={false}
                  >
                    Drill in
                  </DropdownItem>
                ) : null}
                <DropdownItem onClick={onEditInsightClick} toggle={false}>
                  Edit
                </DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={onRemoveInsightClick}
                  toggle={false}
                >
                  Remove
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
      ) : null}
      <ErrorBoundary
        fallback={<span className="px-4 text-danger">An error occurred.</span>}
      >
        <div className={contentClassName}>{children}</div>
      </ErrorBoundary>
      {showInfo && !embedInfo ? (
        <ChartInfo insightId={dashboardInsight.insightId} />
      ) : null}
    </div>
  );

  return clickable ? (
    <Link
      to={{ pathname: insightUrl, search: searchParams }}
      style={{ textDecoration: "none" }}
    >
      {renderBody()}
    </Link>
  ) : (
    renderBody()
  );
});

export default ChartWrapper;
