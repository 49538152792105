import { find } from "lodash";
import { useEffect } from "react";

import { EmbeddedReportId } from "../../../../services/insightLibrary/insightDefinitions";
import insightLibraryService from "../../../../services/insightLibrary/insightsLibraryService";
import { ValidationError } from "../../../admin/integrations/addEditIntegrationModal/common";
import { NameField } from "./Fields/NameField";
import { ReportField } from "./Fields/ReportField";

interface GraphCardPropsType {
  dashboardInsight?: Record<string, any>;
  validationError: ValidationError;
  onClose: () => void;
  onSubmit: () => void;
  onUnexpectedError: (e?: any) => void;
  updateDashboardInsight: (fieldName: string, newData: any) => void;
}

export const GraphCard = (props: GraphCardPropsType) => {
  const {
    dashboardInsight,
    validationError,
    onUnexpectedError,
    updateDashboardInsight,
  } = props;

  const updateReport = (report: string) => {
    updateDashboardInsight("linkedReport", report);
  };

  const updateName = (name: string) => {
    updateDashboardInsight("name", name);
  };

  useEffect(() => {
    const setupInitialData = async () => {
      try {
        const insights = await insightLibraryService.getInsights();
        const embeddedInsight = find(insights, { id: EmbeddedReportId });
        const dimensions =
          await insightLibraryService.getInsightDimensions(EmbeddedReportId);

        updateDashboardInsight("insight", embeddedInsight);
        updateDashboardInsight("stuff", { showGraph: true });

        if (dimensions.length === 1)
          updateDashboardInsight("dimensionId", dimensions[0].id);
        if (embeddedInsight?.visualizationType.length! > 0)
          updateDashboardInsight(
            "visualizationType",
            embeddedInsight?.visualizationType[0],
          );
      } catch (error) {
        onUnexpectedError(error);
      }
    };

    setupInitialData();
  }, [onUnexpectedError, updateDashboardInsight]);

  return (
    <div className="d-flex flex-col p-4" style={{ minHeight: "375px" }}>
      <ReportField
        linkedReport={dashboardInsight?.linkedReport}
        updateReport={updateReport}
        validationError={validationError}
      />
      <NameField
        name={dashboardInsight?.name}
        updateName={updateName}
        validationError={validationError}
      />
    </div>
  );
};
