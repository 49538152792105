import moment from "moment";
import { Of } from "nick-offerman";

export const monthAbbreviations = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function formatNumber(num: number): string {
  return num < 10 ? `0${num}` : num.toString();
}

// Input Format: 2020-04-01 or 4/1/2020
// Output Format: 4/1/2020
export function formatDateStringtoMDY(dateString: string) {
  const date = moment(dateString, ["M/D/YYYY", "YYYY-MM-DD"], true);
  return date.format("M/D/YYYY");
}

export function dateToMY(date: Date | null | undefined) {
  if (date === null || date === undefined) return "–";
  const month = formatNumber(date.getMonth() + 1);
  return `${month}/${date.getFullYear()}`;
}

export function dateToString(date: Date | null | undefined) {
  if (date === null || date === undefined) return "–";
  const day = formatNumber(date.getDate());
  const month = formatNumber(date.getMonth() + 1);
  return `${month}/${day}/${date.getFullYear()}`;
}

export function dateToTimeString(date: Date | null | undefined) {
  if (date === null || date === undefined) return "–";
  const dateStr = dateToString(date);
  const hours = date.getHours() % 12;
  const minutes = formatNumber(date.getMinutes());
  const ampm = date.getHours() > 12 ? "pm" : "am";
  return `${dateStr} ${hours}:${minutes}${ampm}`;
}

// Output Format: 2020-04-01
export function dateToYMD(date: Date): string {
  const day = formatNumber(date.getDate());
  const month = formatNumber(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

// Input Format: 2020-04-01
// Output Format: Apr 20
export function dateStrToMonthAndYear(
  dateStr: string,
  annotateCurrentMonth?: boolean,
): string {
  const now = new Date();
  const date = parseYMD(dateStr);
  const monthName = monthAbbreviations[date.getMonth()];
  const year = date.getFullYear().toString().substring(2);
  const currentMonthAsterisk =
    annotateCurrentMonth && now.getMonth() === date.getMonth() ? "*" : "";
  return `${monthName} '${year}${currentMonthAsterisk}`;
}

export function firstAndLastFromMonthAndYear(monthAndYear: string): {
  first: Date;
  last: Date;
} {
  const split = monthAndYear.split(" '");
  const monthStr = split[0];
  const yearStr = `20${split[1]}`;
  const first = new Date(Date.parse(`${monthStr} 1, ${yearStr}`));
  const last = new Date(first.getFullYear(), first.getMonth() + 1, 0);
  return { first, last };
}

export function toQuarterAndYear(date: Date): string {
  const month = date.getMonth();
  const quarterNumber = Math.floor((month + 3) / 3);
  const year = date.getFullYear().toString().substring(2);
  return `Q${quarterNumber} '${year}`;
}

export function toFullMonth(date: Date): string {
  return monthNames[date.getMonth()];
}

export function toShortMonth(date: Date): string {
  return monthAbbreviations[date.getMonth()];
}

export function toFullMonthAndYear(date: Date): string {
  const month = monthNames[date.getMonth()];
  return `${month} ${date.getFullYear()}`;
}

export function toFullMonthDayAndYear(date: Date): string {
  const month = monthNames[date.getMonth()];
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
}

export function toShortMonthAndYear(date: Date): string {
  const mon = monthAbbreviations[date.getMonth()];
  return `${mon} ${date.getFullYear()}`;
}

export function toShortMonthAndDay(date: Date): string {
  const mon = monthAbbreviations[date.getMonth()];
  return `${mon} ${date.getDate()}`;
}

export function toShortMonthDayAndYear(date: Date): string {
  const mon = monthAbbreviations[date.getMonth()];
  return `${mon} ${date.getDate()}, ${date.getFullYear()}`;
}

// Parses '2020-01-01' into a date in the local timezone, otherwise it is interpreted at 00:00:00 UTC
// and so the locale date is off by one.
export function parseYMD(ymd: string) {
  const date = new Date(ymd);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

export function nowAndAYearAgo(now: Date = new Date()): {
  from: Date;
  to: Date;
} {
  now.setDate(1);
  const from = monthsBack(now, 11);
  return {
    from: from,
    to: now,
  };
}

export function monthsBack(date: Date, months: number): Date {
  const ret = new Date(date);
  ret.setMonth(date.getMonth() - months);
  return ret;
}

export const dateOf = Of(Date);
