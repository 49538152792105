import { ObjectKeyedMap } from "@joyhub-integration/shared";
import { find, isEqual } from "lodash";
import { compare, orderBy } from "natural-orderby";

import { tableValueFormatter } from "../utils/chartNumberFormatter";
import {
  dateStrToMonthAndYear,
  dateToYMD,
  monthsBack,
  parseYMD,
  toQuarterAndYear,
} from "../utils/date";
import {
  CountOfTotal,
  addCountOfTotal,
  compareCountOfTotal,
  percentDifference,
  ratioToNumber,
  safeDivide,
  subtractCountOfTotal,
  toPercentageNum,
} from "../utils/number";
import {
  DimensionedCountOfTotals,
  DimensionedNumbers,
  DimensionedValue,
  InsightRange,
  InsightRangeValue,
  InsightValue,
  InsightValues,
  PropertyInsights,
  RangedInsights,
  TagInsights,
} from "./dataService";
import dimensionBuckets from "./insightLibrary/dimensionBuckets";
import { getOverriddenDimension } from "./insightLibrary/dimensionOverrides";
import {
  KnownInsight as BackendInsight,
  FrontEndUnit,
} from "./insightsService";
import {
  AxisOrientationType,
  ByPropertyCalculationType,
  CategoryChartInformation,
  ChartOrTableDataValue,
  ChartOrTableValues,
  ChartValues,
  ComplexInsightIds,
  Insight,
  NaturalSortOrStringSortFunction,
  Operand,
  RangeValues,
  RangeValuesWithCalculationType,
  RangeValuesWithUnit,
  SecondaryAxisKeyValue,
  ShowTopNCategoriesConfig,
  backupColors,
  colors,
} from "./models";
import { Property } from "./propertiesService";

const naturalSort = compare();

const getInsightValue = (
  insightId: number,
  instantValues: InsightValues,
  dimensionFilter?: Record<string, string>,
) => {
  const value = instantValues[insightId];
  if (Array.isArray(value)) {
    const values = value as any as DimensionedNumbers;
    return (
      values.find((dv) => isEqual(dv.dimensions, dimensionFilter))?.value ?? 0
    );
  } else {
    return value;
  }
};

export function calculateInstantPercentage(
  backendInsightIds: number[],
  instantValues: InsightValues,
  dimensionFilter?: Record<string, string>,
): CountOfTotal {
  if (backendInsightIds.length < 2) {
    const countOfTotal = getInsightValue(
      backendInsightIds[0],
      instantValues,
      dimensionFilter,
    ) as CountOfTotal;
    return countOfTotal;
  } else if (backendInsightIds.length === 2) {
    const count = getInsightValue(
      backendInsightIds[0],
      instantValues,
      dimensionFilter,
    ) as number;
    const total = getInsightValue(
      backendInsightIds[1],
      instantValues,
      dimensionFilter,
    ) as number;
    return { count, total };
  } else {
    return find(
      instantValues,
      (value) => value.hasOwnProperty("count") && value.hasOwnProperty("total"),
    ) as CountOfTotal;
  }
}

export interface InstantNumberCalculation {
  value: number;
  unit: FrontEndUnit;
}

export function calculateInstantNumber(
  backendInsightIds: number[],
  instantValues: InsightValues,
  insightsMap: { [id: string]: BackendInsight },
  operand?: Operand,
  dimensionFilter?: Record<string, string>,
): InstantNumberCalculation {
  const unit = insightsMap[backendInsightIds[0]].primitiveUnit;
  if (backendInsightIds.length === 2) {
    const first = getInsightValue(
      backendInsightIds[0],
      instantValues,
      dimensionFilter,
    ) as number;
    const second = getInsightValue(
      backendInsightIds[1],
      instantValues,
      dimensionFilter,
    ) as number;
    if (operand === "-") {
      return {
        value:
          Number.isFinite(first) && Number.isFinite(second)
            ? first - second
            : NaN,
        unit: unit,
      };
    } else {
      // TODO: this should specify operand / but for another day
      const quotient = safeDivide(first, second) ?? Number.NaN;
      return {
        value: parseFloat(quotient.toFixed(2)),
        unit: unit,
      };
    }
  } else {
    const value = getInsightValue(
      backendInsightIds[0],
      instantValues,
      dimensionFilter,
    );
    if (typeof value === "number") {
      return {
        value: value as number,
        unit: unit,
      };
    } else {
      const countOfTotalValue = value as CountOfTotal;
      const quotient =
        safeDivide(countOfTotalValue?.count, countOfTotalValue?.total) ??
        Number.NaN;
      return {
        value: parseFloat(quotient.toFixed(2)),
        unit: unit,
      };
    }
  }
}

function addResultColToDimensions(
  dimensions: { [dimension: string]: string },
  resultColWithDimensions: string,
) {
  let resultCol = "";
  if (resultColWithDimensions.startsWith("dimensions")) {
    const split = resultColWithDimensions.split(".");
    resultCol = split.length > 1 ? split[1] : split[0];
  } else {
    resultCol = resultColWithDimensions;
  }
  return {
    ...dimensions,
    resultCol: resultCol,
  };
}

function calculateDimensionedRangePercentages(
  rangeValue: InsightValues,
  dimensionedResultCol: string,
  insightIdsArray: BackendInsight[],
): RangeValues[] {
  if (insightIdsArray.length === 2) {
    if (typeof rangeValue[insightIdsArray[1].id] === "number") {
      // TODO Check id this is a valid case
      const countWithDimensions = rangeValue[
        insightIdsArray[0].id
      ] as DimensionedNumbers;
      const total = rangeValue[insightIdsArray[1].id] as number;
      if (countWithDimensions === undefined || total === undefined) return [];
      const values: RangeValues[] = [];
      for (const countWithDimension of countWithDimensions) {
        const countOfTotal = {
          count: countWithDimension.value,
          total: total,
        };
        const withDimensions = {
          value: countOfTotal,
          dimensions: addResultColToDimensions(
            countWithDimension.dimensions,
            dimensionedResultCol,
          ),
        };
        values.push(withDimensions);
      }
      return values;
    } else {
      const countsWithDimensions = rangeValue[
        insightIdsArray[0].id
      ] as DimensionedNumbers;
      const totalArrayWithDimensions = rangeValue[
        insightIdsArray[1].id
      ] as DimensionedNumbers;
      if (
        countsWithDimensions === undefined ||
        totalArrayWithDimensions === undefined
      )
        return [];
      const dimensionsToCountOfTotalMap = ObjectKeyedMap.empty<
        Record<string, string>,
        CountOfTotal
      >();
      for (const countWithDimensions of countsWithDimensions) {
        dimensionsToCountOfTotalMap.update(
          countWithDimensions.dimensions,
          ({ count, total }) => ({
            count: count + countWithDimensions.value,
            total: total,
          }),
          { count: 0, total: 0 },
        );
      }
      for (const totalWithDimensions of totalArrayWithDimensions) {
        dimensionsToCountOfTotalMap.update(
          totalWithDimensions.dimensions,
          ({ count, total }) => ({
            count: count,
            total: total + totalWithDimensions.value,
          }),
          { count: 0, total: 0 },
        );
      }
      const values: RangeValues[] = [];
      for (const [
        dimensions,
        value,
      ] of dimensionsToCountOfTotalMap.entrySet()) {
        values.push({
          value: value,
          dimensions: addResultColToDimensions(
            dimensions,
            dimensionedResultCol,
          ),
        });
      }
      return values;
    }
  } else if (insightIdsArray.length === 1) {
    const dimensionedValue = rangeValue[insightIdsArray[0].id] as
      | DimensionedCountOfTotals
      | DimensionedNumbers;
    if (dimensionedValue === undefined || dimensionedValue.length === 0)
      return [];
    const first = dimensionedValue[0].value;
    const values: RangeValues[] = [];
    if (typeof first === "number") {
      const dimensionedNumbers = dimensionedValue as DimensionedNumbers;
      let total = 0;
      for (const dimensionedNumber of dimensionedNumbers) {
        total = total + dimensionedNumber.value;
      }
      for (const dimensionedNumber of dimensionedNumbers) {
        values.push({
          value: {
            count: dimensionedNumber.value,
            total: total,
          },
          dimensions: addResultColToDimensions(
            dimensionedNumber.dimensions,
            dimensionedResultCol,
          ),
        });
      }
    } else {
      for (const countWithTotal of dimensionedValue) {
        values.push({
          value: countWithTotal.value,
          dimensions: addResultColToDimensions(
            countWithTotal.dimensions,
            dimensionedResultCol,
          ),
        });
      }
    }
    return values;
  } else {
    return [];
  }
}

function calculateWholeRangePercentages(
  rangeValue: InsightValues,
  resultCol: string,
  insightIds: BackendInsight[],
): RangeValues[] {
  if (
    insightIds.length === 2 &&
    typeof rangeValue[insightIds[0].id] === "number"
  ) {
    const countOfTotal = {
      count: rangeValue[insightIds[0].id] as number,
      total: rangeValue[insightIds[1].id] as number,
    };
    return [
      {
        value: countOfTotal,
        dimensions: addResultColToDimensions({}, resultCol),
      },
    ];
  } else if (
    insightIds.length === 2 &&
    typeof rangeValue[insightIds[0].id] === "object"
  ) {
    const countAsCountOfTotal = rangeValue[insightIds[0].id] as CountOfTotal;
    const totalAsCountOfTotal = rangeValue[insightIds[1].id] as CountOfTotal;
    const countOfTotal = {
      count: ratioToNumber(countAsCountOfTotal, "Percent"),
      total: ratioToNumber(totalAsCountOfTotal, "Percent"),
    };
    return [
      {
        value: countOfTotal,
        dimensions: addResultColToDimensions({}, resultCol),
      },
    ];
  } else if (
    insightIds.length === 1 &&
    typeof rangeValue[insightIds[0].id] === "object"
  ) {
    const countWithTotal = rangeValue[insightIds[0].id] as CountOfTotal;
    if (countWithTotal === undefined) return [];
    return [
      {
        value: countWithTotal,
        dimensions: addResultColToDimensions({}, resultCol),
      },
    ];
  } else {
    return [];
  }
}

export function calculateRangePercentages(
  rangeValue: InsightValues,
  resultCol: string,
  insightIds: BackendInsight[],
): RangeValuesWithUnit[] {
  let results: RangeValues[];
  if (resultCol.startsWith("dimensions")) {
    results = calculateDimensionedRangePercentages(
      rangeValue,
      resultCol,
      insightIds,
    );
  } else {
    results = calculateWholeRangePercentages(rangeValue, resultCol, insightIds);
  }
  const resultsWithUnit: RangeValuesWithUnit[] = [];
  for (const res of results) {
    resultsWithUnit.push({
      ...res,
      unit: "Percent",
    });
  }
  return resultsWithUnit;
}

export function calculateRangeRawNumbers(
  rangeValue: InsightValues,
  resultCol: string,
  insightIds: BackendInsight[],
): RangeValuesWithUnit[] {
  const unit = insightIds[0].primitiveUnit;
  if (resultCol.startsWith("dimensions")) {
    const valueWithDimensions = rangeValue[
      insightIds[0].id
    ] as DimensionedNumbers;
    if (valueWithDimensions === undefined) return [];
    const values: RangeValuesWithUnit[] = [];

    for (const valueWithDimension of valueWithDimensions) {
      values.push({
        value: valueWithDimension.value,
        unit: unit,
        dimensions: addResultColToDimensions(
          valueWithDimension.dimensions,
          resultCol,
        ),
      });
    }
    return values;
  } else {
    const value = rangeValue[insightIds[0].id] as number;
    if (value === undefined) return [];
    return [
      {
        value: value,
        unit: unit,
        dimensions: addResultColToDimensions({}, resultCol),
      },
    ];
  }
}

function aggregateRangeValuesByTag(
  byTag: TagInsights<InsightRange>,
  byTagCalculationType: ByPropertyCalculationType,
  complexInsightIds: ComplexInsightIds,
  insightIds: BackendInsight[],
  insightMap: Record<string, BackendInsight>,
) {
  const valuesByTag: {
    tag: string;
    insights: {
      [key: string]: InsightValue;
    };
  }[] = [];
  for (const [tagKey, tagValues] of Object.entries(byTag)) {
    const aggregatedByTag = {
      tag: tagKey,
      insights: {} as { [key: string]: InsightValue },
    };

    for (const current of tagValues) {
      for (const [insightId, insightValue] of Object.entries(
        current.insights,
      )) {
        if (!aggregatedByTag.insights[insightId]) {
          aggregatedByTag.insights[insightId] = insightValue;
        } else {
          if (typeof insightValue === "number") {
            if (byTagCalculationType === "SUM") {
              const previousValue = aggregatedByTag.insights[
                insightId
              ] as number;
              aggregatedByTag.insights[insightId] =
                previousValue + insightValue;
            } else {
              const previousValue = aggregatedByTag.insights[
                insightId
              ] as number;
              aggregatedByTag.insights[insightId] = Math.max(
                previousValue,
                insightValue,
              );
            }
          } else {
            const previousCountOfTotal = aggregatedByTag.insights[
              insightId
            ] as CountOfTotal;
            const currentCountOfTotal = insightValue as CountOfTotal;
            if (byTagCalculationType === "SUM") {
              aggregatedByTag.insights[insightId] = {
                count: previousCountOfTotal.count + currentCountOfTotal.count,
                total: previousCountOfTotal.total + currentCountOfTotal.total,
              };
            } else {
              const max =
                previousCountOfTotal.count / previousCountOfTotal.total >
                currentCountOfTotal.count / currentCountOfTotal.total
                  ? previousCountOfTotal
                  : currentCountOfTotal;
              aggregatedByTag.insights[insightId] = max;
            }
          }
        }
      }
    }
    valuesByTag.push(aggregatedByTag);
  }

  const evaluatedValuesByTag: {
    [key: string]: RangeValuesWithCalculationType[];
  } = {};
  for (const valueForTag of valuesByTag) {
    const valuesWithoutCalculationType =
      complexInsightIds.insightCalculationType === "PERCENTAGE"
        ? calculateRangePercentages(
            valueForTag.insights,
            complexInsightIds.resultCol,
            insightIds,
          )
        : calculateRangeRawNumbers(
            valueForTag.insights,
            complexInsightIds.resultCol,
            insightIds,
          );
    const values: RangeValuesWithCalculationType[] = [];
    const calculationType = complexInsightIds.insightCalculationType;
    const tag = valueForTag.tag;
    for (const value of valuesWithoutCalculationType) {
      values.push({
        ...value,
        calculationType: calculationType,
      });
    }
    evaluatedValuesByTag[tag] = values;
  }
  return evaluatedValuesByTag;
}

function aggregateRangeValuesByPropertyHelper(
  byProperty: PropertyInsights<InsightRange>,
  byPropertyCalculationType: ByPropertyCalculationType,
  complexInsightIds: ComplexInsightIds,
  insightIds: BackendInsight[],
  insightMap: Record<string, BackendInsight>,
) {
  const valuesByProperty: {
    property: string;
    insights: {
      [key: string]: InsightValue;
    };
  }[] = [];

  for (const [propertyKey, propertyValues] of Object.entries(byProperty)) {
    const aggregatedByProperty = {
      property: propertyKey,
      insights: {} as { [key: string]: InsightValue },
    };

    for (const current of propertyValues) {
      for (const [insightId, insightValue] of Object.entries(
        current.insights,
      )) {
        if (!aggregatedByProperty.insights[insightId]) {
          aggregatedByProperty.insights[insightId] = insightValue;
        } else {
          if (typeof insightValue === "number") {
            if (byPropertyCalculationType === "SUM") {
              const previousValue = aggregatedByProperty.insights[
                insightId
              ] as number;
              aggregatedByProperty.insights[insightId] =
                previousValue + insightValue;
            } else {
              const previousValue = aggregatedByProperty.insights[
                insightId
              ] as number;
              aggregatedByProperty.insights[insightId] = Math.max(
                previousValue,
                insightValue,
              );
            }
          } else {
            const previousCountOfTotal = aggregatedByProperty.insights[
              insightId
            ] as CountOfTotal;
            const currentCountOfTotal = insightValue as CountOfTotal;
            if (byPropertyCalculationType === "SUM") {
              aggregatedByProperty.insights[insightId] = {
                count: previousCountOfTotal.count + currentCountOfTotal.count,
                total: previousCountOfTotal.total + currentCountOfTotal.total,
              };
            } else {
              const max =
                previousCountOfTotal.count / previousCountOfTotal.total >
                currentCountOfTotal.count / currentCountOfTotal.total
                  ? previousCountOfTotal
                  : currentCountOfTotal;
              aggregatedByProperty.insights[insightId] = max;
            }
          }
        }
      }
    }
    valuesByProperty.push(aggregatedByProperty);
  }

  const evaluatedValuesByProperty: {
    [key: string]: RangeValuesWithCalculationType[];
  } = {};
  for (const valueForProperty of valuesByProperty) {
    const valuesWithoutCalculationType =
      complexInsightIds.insightCalculationType === "PERCENTAGE"
        ? calculateRangePercentages(
            valueForProperty.insights,
            complexInsightIds.resultCol,
            insightIds,
          )
        : calculateRangeRawNumbers(
            valueForProperty.insights,
            complexInsightIds.resultCol,
            insightIds,
          );
    const values: RangeValuesWithCalculationType[] = [];
    const calculationType = complexInsightIds.insightCalculationType;
    const property = valueForProperty.property;
    for (const value of valuesWithoutCalculationType) {
      values.push({
        ...value,
        calculationType: calculationType,
      });
    }
    evaluatedValuesByProperty[property] = values;
  }

  return evaluatedValuesByProperty;
}

function aggregateRangeValuesByProperty(
  byProperty: PropertyInsights<InsightRange>,
  byPropertyAYearAgo: PropertyInsights<InsightRange>,
  byPropertyCalculationType: ByPropertyCalculationType,
  complexInsightIds: ComplexInsightIds,
  insightIds: BackendInsight[],
  insightMap: Record<string, BackendInsight>,
): { [key: string]: RangeValuesWithCalculationType[] } {
  const valuesByProperty = aggregateRangeValuesByPropertyHelper(
    byProperty,
    byPropertyCalculationType,
    complexInsightIds,
    insightIds,
    insightMap,
  );
  if (complexInsightIds.yoy) {
    const valuesByPropertyAYearAgo = aggregateRangeValuesByPropertyHelper(
      byPropertyAYearAgo,
      byPropertyCalculationType,
      complexInsightIds,
      insightIds,
      insightMap,
    );
    calculateRangeValuesYoyChange(valuesByProperty, valuesByPropertyAYearAgo);
  }
  return valuesByProperty;
}

function aggregateOverallRangeValues(
  overall: InsightRange,
  complexInsightIds: ComplexInsightIds,
  insightIds: BackendInsight[],
  insightMap: Record<string, BackendInsight>,
): { [key: string]: RangeValuesWithCalculationType[] } {
  const valuesWithCalculationType: RangeValuesWithCalculationType[] = [];
  for (const valueForDate of overall) {
    const values =
      complexInsightIds.insightCalculationType === "PERCENTAGE"
        ? calculateRangePercentages(
            valueForDate.insights,
            complexInsightIds.resultCol,
            insightIds,
          )
        : calculateRangeRawNumbers(
            valueForDate.insights,
            complexInsightIds.resultCol,
            insightIds,
          );

    const calculationType = complexInsightIds.insightCalculationType;
    for (const value of values) {
      valuesWithCalculationType.push({
        ...value,
        calculationType: calculationType,
      });
    }
  }

  return {
    Total: valuesWithCalculationType,
  };
}

function aggregateRangeValuesByDateHelper(
  overall: InsightRange,
  complexInsightIds: ComplexInsightIds,
  insightIds: BackendInsight[],
  insightMap: Record<string, BackendInsight>,
): Record<string, RangeValuesWithCalculationType[]> {
  const aggregated: Record<string, RangeValuesWithCalculationType[]> = {};
  for (const valueForDate of overall) {
    const values =
      complexInsightIds.insightCalculationType === "PERCENTAGE"
        ? calculateRangePercentages(
            valueForDate.insights,
            complexInsightIds.resultCol,
            insightIds,
          )
        : calculateRangeRawNumbers(
            valueForDate.insights,
            complexInsightIds.resultCol,
            insightIds,
          );
    const date = valueForDate.date;
    const calculationType = complexInsightIds.insightCalculationType;
    const valuesWithCalculationType: RangeValuesWithCalculationType[] = [];
    for (const value of values) {
      valuesWithCalculationType.push({
        ...value,
        calculationType: calculationType,
      });
    }
    aggregated[date] = valuesWithCalculationType;
  }
  return aggregated;
}

function calculateRangeValuesYoyChange(
  aggregated: Record<string, RangeValuesWithCalculationType[]>,
  aggregatedAYearAgo: Record<string, RangeValuesWithCalculationType[]>,
  getSiblingKey: (key: string) => string = (key: string) => key,
) {
  for (const key of Object.keys(aggregated)) {
    const siblingKey = getSiblingKey(key);
    const valueNow = aggregated[key];
    const valueAYearAgo = aggregatedAYearAgo[siblingKey];
    if (!valueAYearAgo) {
      aggregated[key] = aggregated[key].map((value) => {
        return {
          ...value,
          value: {
            count: 0,
            total: 0,
          },
          calculationType: "PERCENTAGE",
          unit: "Percent",
        };
      });
    } else {
      const valuesNowByDimensionsMap = ObjectKeyedMap.empty<
        Record<string, string>,
        RangeValuesWithCalculationType
      >();
      const valuesAYearAgoByDimensionsMap = ObjectKeyedMap.empty<
        Record<string, string>,
        RangeValuesWithCalculationType
      >();
      for (const dimensionedValue of valueNow) {
        valuesNowByDimensionsMap.put(
          dimensionedValue.dimensions,
          dimensionedValue,
        );
      }
      for (const dimensionedValue of valueAYearAgo) {
        valuesAYearAgoByDimensionsMap.put(
          dimensionedValue.dimensions,
          dimensionedValue,
        );
      }
      aggregated[key] = aggregated[key].map((dimensionedValue) => {
        const now = dimensionedValue.value;
        const aYearAgo = valuesAYearAgoByDimensionsMap.get(
          dimensionedValue.dimensions,
        );
        const yoy: RangeValuesWithCalculationType = {
          ...dimensionedValue,
          value: {
            count: 0,
            total: 0,
          },
          calculationType: "PERCENTAGE",
          unit: "Percent",
        };
        if (aYearAgo) {
          if (dimensionedValue.calculationType === "PERCENTAGE") {
            yoy.value = subtractCountOfTotal(
              now as CountOfTotal,
              aYearAgo.value as CountOfTotal,
            );
          } else {
            yoy.value = percentDifference(now, aYearAgo.value);
          }
        }
        return yoy;
      });
    }
  }
}

function aggregateRangeValuesByDate(
  overall: InsightRange,
  overallAYearAgo: InsightRange,
  complexInsightIds: ComplexInsightIds,
  insightIds: BackendInsight[],
  insightMap: Record<string, BackendInsight>,
  sortByDateDesc?: boolean,
): { [key: string]: RangeValuesWithCalculationType[] } {
  const aggregated = aggregateRangeValuesByDateHelper(
    overall,
    complexInsightIds,
    insightIds,
    insightMap,
  );
  if (complexInsightIds.yoy) {
    const aggregatedAYearAgo = aggregateRangeValuesByDateHelper(
      overallAYearAgo,
      complexInsightIds,
      insightIds,
      insightMap,
    );
    const getSiblingKey = (dateNowStr: string) => {
      const dateNow = parseYMD(dateNowStr);
      const dateAYearAgo = monthsBack(dateNow, 12);
      return dateToYMD(dateAYearAgo);
    };
    calculateRangeValuesYoyChange(
      aggregated,
      aggregatedAYearAgo,
      getSiblingKey,
    );
  }
  if (sortByDateDesc) {
    const sorted: { [key: string]: RangeValuesWithCalculationType[] } = {};
    const sortedKeys = Object.keys(aggregated).sort((a, b) => {
      const aDate = new Date(a);
      const bDate = new Date(b);
      return bDate.getTime() - aDate.getTime();
    });
    for (const key of sortedKeys) {
      sorted[key] = aggregated[key];
    }
    return sorted;
  } else {
    return aggregated;
  }
}

function aggregateRangeValuesByQuarter(
  overall: InsightRange,
  overallAYearAgo: InsightRange,
  complexInsightIds: ComplexInsightIds,
  insightIds: BackendInsight[],
  insightMap: Record<string, BackendInsight>,
): { [key: string]: RangeValuesWithCalculationType[] } {
  const aggregatedByMonth = aggregateRangeValuesByDate(
    overall,
    overallAYearAgo,
    complexInsightIds,
    insightIds,
    insightMap,
  );
  const aggregatedByQuarter: {
    [key: string]: RangeValuesWithCalculationType[];
  } = {};
  for (const [currentDateKey, currentDateValues] of Object.entries(
    aggregatedByMonth,
  )) {
    const quarter = toQuarterAndYear(new Date(currentDateKey));
    aggregatedByQuarter[quarter] = currentDateValues;
  }
  return aggregatedByQuarter;
}

function addTotalToDimensionsHelper(rangedInsight: InsightRangeValue) {
  const insightsWithTotalDimension: InsightValues = {};
  for (const [backendInsightId, insightValue] of Object.entries(
    rangedInsight.insights,
  )) {
    const backendInsightIdNum = parseInt(backendInsightId, 10);
    if (!Array.isArray(insightValue) || insightValue.length === 0) {
      insightsWithTotalDimension[backendInsightIdNum] = insightValue;
    } else {
      const firstDimensionedValue = insightValue[0];
      if (typeof firstDimensionedValue.value === "number") {
        const dimensionedNumbers = insightValue as DimensionedNumbers;
        const totalDimension: DimensionedValue<number> = {
          dimensions: {},
          value: 0,
        };
        for (const dimensionedNumber of dimensionedNumbers) {
          for (const key of Object.keys(dimensionedNumber.dimensions)) {
            totalDimension.dimensions[key] = "Total";
          }
          totalDimension.value = totalDimension.value + dimensionedNumber.value;
        }
        dimensionedNumbers.push(totalDimension);
        insightsWithTotalDimension[backendInsightIdNum] = dimensionedNumbers;
      } else {
        const dimensionedCountOfTotals =
          insightValue as DimensionedCountOfTotals;
        const totalDimension: DimensionedValue<CountOfTotal> = {
          dimensions: {},
          value: { count: 0, total: 0 },
        };
        for (const dimensionedCountOfTotal of dimensionedCountOfTotals) {
          for (const key of Object.keys(dimensionedCountOfTotal.dimensions)) {
            totalDimension.dimensions[key] = "Total";
          }
          totalDimension.value = {
            count:
              totalDimension.value.count + dimensionedCountOfTotal.value.count,
            total:
              totalDimension.value.total + dimensionedCountOfTotal.value.total,
          };
        }
        dimensionedCountOfTotals.push(totalDimension);
        insightsWithTotalDimension[backendInsightIdNum] =
          dimensionedCountOfTotals;
      }
    }
  }
  return {
    date: rangedInsight.date,
    insights: insightsWithTotalDimension,
  };
}

function addTotalToDimensions(rangedInsights: RangedInsights): RangedInsights {
  const overall: InsightRange = [];
  for (const rangedInsight of rangedInsights.overall) {
    overall.push(addTotalToDimensionsHelper(rangedInsight));
  }
  let byProperty: PropertyInsights<InsightRange> | undefined;
  if (rangedInsights.byProperty) {
    byProperty = {};
    for (const [propertyKey, propertyValue] of Object.entries(
      rangedInsights.byProperty,
    )) {
      const propertyKeyNum = parseInt(propertyKey, 10);
      byProperty[propertyKeyNum] = [];
      for (const rangeValue of propertyValue) {
        byProperty[propertyKeyNum].push(addTotalToDimensionsHelper(rangeValue));
      }
    }
  }
  return {
    ...rangedInsights,
    overall: overall,
    byProperty: byProperty,
  };
}

function filterDimensionsHelper(
  rangedInsight: InsightRangeValue,
  filterByDimension: FilterByDimension,
) {
  const insightsFilteredByDimension: InsightValues = {};
  for (const [backendInsightId, insightValue] of Object.entries(
    rangedInsight.insights,
  )) {
    const backendInsightIdNum = parseInt(backendInsightId, 10);
    if (!Array.isArray(insightValue) || insightValue.length === 0) {
      insightsFilteredByDimension[backendInsightIdNum] = insightValue;
    } else {
      const firstDimensionedValue = insightValue[0];
      if (typeof firstDimensionedValue.value === "number") {
        const dimensionedNumbers = insightValue as DimensionedNumbers;
        let filtered: DimensionedNumbers = [];
        if (filterByDimension.selectedValue === "all") {
          filtered = dimensionedNumbers;
        } else {
          for (const dimensionedNumber of dimensionedNumbers) {
            if (
              dimensionedNumber.dimensions[filterByDimension.dimension] ===
              filterByDimension.selectedValue
            ) {
              filtered.push(dimensionedNumber);
            }
          }
        }
        insightsFilteredByDimension[backendInsightIdNum] = filtered;
      } else {
        const dimensionedCountOfTotals =
          insightValue as DimensionedCountOfTotals;
        let filtered: DimensionedCountOfTotals = [];
        if (filterByDimension.selectedValue === "all") {
          filtered = dimensionedCountOfTotals;
        } else {
          for (const dimensionedNumber of dimensionedCountOfTotals) {
            if (
              dimensionedNumber.dimensions[filterByDimension.dimension] ===
              filterByDimension.selectedValue
            ) {
              filtered.push(dimensionedNumber);
            }
          }
        }
        insightsFilteredByDimension[backendInsightIdNum] = filtered;
      }
    }
  }
  return {
    date: rangedInsight.date,
    insights: insightsFilteredByDimension,
  };
}

function filterDimensions(
  rangedInsights: RangedInsights,
  filterByDimension: FilterByDimension,
): RangedInsights {
  const overall: InsightRange = [];
  for (const rangedInsight of rangedInsights.overall) {
    overall.push(filterDimensionsHelper(rangedInsight, filterByDimension));
  }
  let byProperty: PropertyInsights<InsightRange> | undefined = undefined;
  if (rangedInsights.byProperty) {
    byProperty = {};
    for (const [propertyKey, propertyValue] of Object.entries(
      rangedInsights.byProperty,
    )) {
      const propertyKeyNum = parseInt(propertyKey, 10);
      byProperty[propertyKeyNum] = [];
      for (const rangeValue of propertyValue) {
        const byPropertyDatum = filterDimensionsHelper(
          rangeValue,
          filterByDimension,
        );
        byProperty[propertyKeyNum].push(byPropertyDatum);
      }
    }
  }
  return {
    ...rangedInsights,
    overall: overall,
    byProperty: byProperty,
  };
}

function bucketDimensionsHelper(rangedInsight: InsightRangeValue) {
  const insightsFilteredByDimension = {} as InsightValues;
  for (const [backendInsightId, insightValue] of Object.entries(
    rangedInsight.insights,
  )) {
    const backendInsightIdNum = parseInt(backendInsightId, 10);
    if (!Array.isArray(insightValue) || insightValue.length === 0) {
      insightsFilteredByDimension[backendInsightIdNum] = insightValue;
    } else {
      const firstDimensionedValue = insightValue[0];
      if (typeof firstDimensionedValue.value === "number") {
        const dimensionedNumbers = insightValue as DimensionedNumbers;
        const labeledNotBucketed: {
          dimensions: {
            [dimension: string]: string;
          };
          value: number;
        }[] = [];
        for (const dimensionedNumber of dimensionedNumbers) {
          const newDimensions: { [dimension: string]: string } = {};
          const dimensions = Object.entries(dimensionedNumber.dimensions);
          for (const [dimensionKey, dimensionValue] of dimensions) {
            if (
              dimensionBuckets[dimensionKey] &&
              dimensionBuckets[dimensionKey][dimensionValue]
            ) {
              newDimensions[dimensionKey] =
                dimensionBuckets[dimensionKey][dimensionValue];
            } else {
              newDimensions[dimensionKey] = dimensionValue;
            }
          }
          labeledNotBucketed.push({
            dimensions: newDimensions,
            value: dimensionedNumber.value,
          });
        }
        const dimensionValues = ObjectKeyedMap.empty<
          Record<string, string>,
          number
        >();
        for (const { dimensions, value } of labeledNotBucketed) {
          dimensionValues.update(dimensions, (v) => value + v, 0);
        }
        const bucketedDimensions = dimensionValues
          .entrySet()
          .map(([dimensions, value]) => ({ dimensions, value }));
        insightsFilteredByDimension[backendInsightIdNum] = bucketedDimensions;
      } else {
        const dimensionedCountOfTotals =
          insightValue as DimensionedCountOfTotals;
        const labeledNotBucketed: {
          dimensions: {
            [dimension: string]: string;
          };
          value: CountOfTotal;
        }[] = [];
        for (const dimensionedCountOfTotal of dimensionedCountOfTotals) {
          const newDimensions: { [dimension: string]: string } = {};
          const dimensions = Object.entries(dimensionedCountOfTotal.dimensions);
          for (const [dimensionKey, dimensionValue] of dimensions) {
            if (
              dimensionBuckets[dimensionKey] &&
              dimensionBuckets[dimensionKey][dimensionValue]
            ) {
              newDimensions[dimensionKey] =
                dimensionBuckets[dimensionKey][dimensionValue];
            } else {
              newDimensions[dimensionKey] = dimensionValue;
            }
          }
          labeledNotBucketed.push({
            dimensions: newDimensions,
            value: dimensionedCountOfTotal.value,
          });
        }
        const dimensionValues = ObjectKeyedMap.empty<
          Record<string, string>,
          CountOfTotal
        >();
        for (const { dimensions, value } of labeledNotBucketed) {
          const { count, total } = value;
          dimensionValues.update(
            dimensions,
            ({ count: c, total: t }) => ({
              count: count + c,
              total: total + t,
            }),
            { count: 0, total: 0 },
          );
        }
        const bucketedDimensions = dimensionValues
          .entrySet()
          .map(([dimensions, value]) => ({ dimensions, value }));
        insightsFilteredByDimension[backendInsightIdNum] = bucketedDimensions;
      }
    }
  }
  return {
    date: rangedInsight.date,
    insights: insightsFilteredByDimension,
  };
}

function bucketDimensions(rangedInsights: RangedInsights): RangedInsights {
  const overall: { date: string; insights: InsightValues }[] = [];
  for (const rangedInsight of rangedInsights.overall) {
    overall.push(bucketDimensionsHelper(rangedInsight));
  }
  let byProperty: PropertyInsights<InsightRange> | undefined = undefined;
  if (rangedInsights.byProperty) {
    byProperty = {};
    for (const [propertyKey, propertyValue] of Object.entries(
      rangedInsights.byProperty,
    )) {
      const propertyKeyNum = parseInt(propertyKey, 10);
      byProperty[propertyKeyNum] = [];
      for (const rangeValue of propertyValue) {
        byProperty[propertyKeyNum].push(bucketDimensionsHelper(rangeValue));
      }
    }
  }
  return {
    ...rangedInsights,
    overall: overall,
    byProperty: byProperty,
  };
}

function aggregateDimensionOptions(
  overall: InsightRange,
  filterByDimension: FilterByDimension,
): Set<string> {
  const dimensionOptions = new Set<string>();
  for (const rangedInsight of overall) {
    for (const insightValue of Object.values(rangedInsight.insights)) {
      if (Array.isArray(insightValue) && insightValue.length > 0) {
        const firstDimensionedValue = insightValue[0];
        if (typeof firstDimensionedValue.value === "number") {
          const dimensionedNumbers = insightValue as DimensionedNumbers;
          for (const dimensionedNumber of dimensionedNumbers) {
            const dimValue =
              dimensionedNumber.dimensions[filterByDimension.dimension];
            if (dimValue) {
              dimensionOptions.add(dimValue);
            }
          }
        } else {
          const dimensionedCountOfTotals =
            insightValue as DimensionedCountOfTotals;
          for (const dimensionedCountOfTotal of dimensionedCountOfTotals) {
            const dimValue =
              dimensionedCountOfTotal.dimensions[filterByDimension.dimension];
            if (dimValue) {
              dimensionOptions.add(dimValue);
            }
          }
        }
      }
    }
  }
  return dimensionOptions;
}

function aggregateMonthOptions(overall: InsightRange): Set<string> {
  const monthOptions = new Set<string>();
  for (const { date } of Object.values(overall)) {
    const monthKey = dateStrToMonthAndYear(date);
    monthOptions.add(monthKey);
  }
  return monthOptions;
}

function matchesMonthSelected(
  rangedInsight: InsightRangeValue,
  monthsSelected: Set<string>,
): boolean {
  if (monthsSelected.size === 0) return true;
  const monthKey = dateStrToMonthAndYear(rangedInsight.date);
  return monthsSelected.has(monthKey);
}

function filterByMonth(
  rangedInsights: RangedInsights,
  monthsSelected: Set<string>,
): RangedInsights {
  const overall: InsightRange = [];
  for (const rangedInsight of rangedInsights.overall) {
    if (matchesMonthSelected(rangedInsight, monthsSelected)) {
      overall.push(rangedInsight);
    }
  }
  let byProperty: PropertyInsights<InsightRange> | undefined = undefined;
  if (rangedInsights.byProperty) {
    byProperty = {};
    for (const [propertyKey, propertyValue] of Object.entries(
      rangedInsights.byProperty,
    )) {
      const propertyKeyNum = parseInt(propertyKey, 10);
      byProperty[propertyKeyNum] = [];
      for (const rangeValue of propertyValue) {
        if (matchesMonthSelected(rangeValue, monthsSelected)) {
          byProperty[propertyKeyNum].push(rangeValue);
        }
      }
    }
  }
  return {
    ...rangedInsights,
    overall: overall,
    byProperty: byProperty,
  };
}

export interface RangeCalculation {
  values: { [key: string]: RangeValuesWithCalculationType[] };
  dimensionOptions: Set<string>;
  monthOptions: Set<string>;
}

function bucketAndFilter(
  rangeData: RangedInsights,
  aggregateDimensionAndMonthOptions: boolean,
  monthsSelected?: Set<string>,
  filterByDimension?: FilterByDimension,
  addTotalDimension?: boolean,
) {
  let dimensionOptions = new Set<string>();
  let monthOptions = new Set<string>();

  const filteringByMonth = monthsSelected && monthsSelected.size !== 0;
  const withDimensionsBucketed = bucketDimensions(rangeData);

  if (aggregateDimensionAndMonthOptions) {
    dimensionOptions = filterByDimension
      ? aggregateDimensionOptions(
          withDimensionsBucketed.overall,
          filterByDimension,
        )
      : new Set<string>();
    monthOptions = aggregateMonthOptions(rangeData.overall);
  }

  const filteredByMonth = filteringByMonth
    ? filterByMonth(withDimensionsBucketed, monthsSelected!)
    : withDimensionsBucketed;
  const filteredByDimension = filterByDimension
    ? filterDimensions(filteredByMonth, filterByDimension)
    : filteredByMonth;
  const withTotalDimension = addTotalDimension
    ? addTotalToDimensions(filteredByDimension)
    : filteredByDimension;
  return {
    dimensionOptions: dimensionOptions,
    monthOptions: monthOptions,
    bucketedAndFiltered: withTotalDimension,
  };
}

// TODO: Get rid of this by making all insight definitions use ComplexInsightIds[] instead of number[]
function complexifyInsights(
  insight: Insight,
  yoy?: boolean,
  drillIn?: boolean,
): ComplexInsightIds[] {
  let insights = drillIn
    ? (insight.drillInInsights ?? insight.insightIds)
    : insight.insightIds;
  let insightIdSets: ComplexInsightIds[] = [];
  const complexInsight = find(
    insights,
    (insight) => typeof insight !== "number",
  );
  if (complexInsight) {
    insightIdSets = insights as ComplexInsightIds[];
  } else {
    insightIdSets = [
      {
        insightIds: insights as number[],
        insightCalculationType: insight.visualizationType.includes("NUMBER")
          ? "NUMBER"
          : "PERCENTAGE",
        resultCol: insight.name,
        delta: insight.delta,
        yoy: yoy,
      },
    ];
  }
  return insightIdSets;
}

export function calculateRangeValues(
  insight: Insight,
  rangeData: RangedInsights,
  rangeDataAYearAgo: RangedInsights,
  insightsMap: { [id: string]: BackendInsight },
  filterByDimension?: FilterByDimension,
  monthsSelected?: Set<string>,
  sortByDateDesc?: boolean,
  yoy?: boolean,
  drillIn?: boolean,
): RangeCalculation {
  let insightIdSets = complexifyInsights(insight, yoy, drillIn);

  const rangeCalculation: RangeCalculation = {
    values: {},
    dimensionOptions: new Set<string>(),
    monthOptions: new Set<string>(),
  };

  for (const complexInsightIds of insightIdSets) {
    const insightIds = complexInsightIds.insightIds;
    const {
      dimensionOptions,
      monthOptions,
      bucketedAndFiltered: datum,
    } = bucketAndFilter(
      rangeData,
      true,
      monthsSelected,
      filterByDimension,
      insight.addTotalDimension,
    );
    const { bucketedAndFiltered: datumAYearAgo } = bucketAndFilter(
      rangeDataAYearAgo,
      false,
      monthsSelected,
      filterByDimension,
      insight.addTotalDimension,
    );
    const insights: BackendInsight[] = [];
    for (const id of insightIds) {
      insights.push(insightsMap[id as number]);
    }

    const aggregated = insight.byPropertyCalculationType
      ? aggregateRangeValuesByProperty(
          datum.byProperty!,
          datumAYearAgo.byProperty!,
          insight.byPropertyCalculationType,
          complexInsightIds,
          insights,
          insightsMap,
        )
      : insight.quarterly
        ? aggregateRangeValuesByQuarter(
            datum.overall,
            datumAYearAgo.overall,
            complexInsightIds,
            insights,
            insightsMap,
          )
        : aggregateRangeValuesByDate(
            datum.overall,
            datumAYearAgo.overall,
            complexInsightIds,
            insights,
            insightsMap,
            sortByDateDesc,
          );

    const aggregatedByTag =
      insight.byTagCalculationType && datum.byTag
        ? aggregateRangeValuesByTag(
            datum.byTag as PropertyInsights<InsightRange>,
            insight.byTagCalculationType,
            complexInsightIds,
            insights,
            insightsMap,
          )
        : {};

    for (const key of Object.keys(aggregated)) {
      if (rangeCalculation.values[key]) {
        rangeCalculation.values[key] = rangeCalculation.values[key].concat(
          aggregated[key],
        );
      } else {
        rangeCalculation.values[key] = aggregated[key];
      }
    }

    for (const key of Object.keys(aggregatedByTag)) {
      if (rangeCalculation.values[key]) {
        rangeCalculation.values[key] = rangeCalculation.values[key].concat(
          aggregatedByTag[key],
        );
      } else {
        rangeCalculation.values[key] = aggregatedByTag[key];
      }
    }
    if (insight.addTotal) {
      const valuesByOverall = aggregateOverallRangeValues(
        datum.overall,
        complexInsightIds,
        insights,
        insightsMap,
      );

      if (complexInsightIds.yoy) {
        const overallAYearAgo = aggregateOverallRangeValues(
          datum.overall,
          complexInsightIds,
          insights,
          insightsMap,
        );
        calculateRangeValuesYoyChange(valuesByOverall, overallAYearAgo);
      }

      let overallSumValues: RangeValuesWithCalculationType =
        {} as RangeValuesWithCalculationType;
      for (const key of Object.keys(valuesByOverall)) {
        for (const item of valuesByOverall[key]) {
          if (Object.keys(overallSumValues).length === 0) {
            overallSumValues = {
              ...item,
            };
          } else {
            if (typeof item.value === "number") {
              overallSumValues.value = ((overallSumValues.value as number) +
                item.value) as number;
            } else {
              const currentValue = overallSumValues.value as CountOfTotal;
              overallSumValues.value = {
                count: currentValue.count + item.value.count,
                total: currentValue.total + item.value.total,
              };
            }
          }
        }
      }

      if (rangeCalculation.values["Total"]) {
        rangeCalculation.values["Total"] =
          rangeCalculation.values["Total"].concat(overallSumValues);
      } else {
        rangeCalculation.values["Total"] = [overallSumValues];
      }
    }

    for (const dimensionOption of Array.from(dimensionOptions)) {
      rangeCalculation.dimensionOptions.add(dimensionOption);
    }
    for (const monthOption of Array.from(monthOptions)) {
      rangeCalculation.monthOptions.add(monthOption);
    }
  }

  return rangeCalculation;
}

export interface TableValues {
  rowHeaders: string[];
  colHeaders: string[];
  rows: { [key: string]: number | string }[];
  dimensionOptions: string[];
  dataUnavailable: boolean;
  monthOptions: string[];
}

function getDimensionForTableValue(value: RangeValues): {
  key: string;
  value: string;
} {
  const dimObject = value.dimensions as { [key: string]: string };
  const firstKey = Object.keys(dimObject)[0];
  return {
    key: firstKey,
    value: dimObject[firstKey],
  };
}

function sortDimensionKeys(
  dimensionKeys: string[],
  primaryAxisKeyDimension: string,
  sortFunction?: (a: string, b: string) => number,
): string[] {
  return dimensionKeys.sort((a, b) => {
    if (a === "Total") {
      return 1;
    } else if (b === "Total") {
      return -1;
    } else if (a === primaryAxisKeyDimension) {
      return -1;
    } else if (b === primaryAxisKeyDimension) {
      return 1;
    } else {
      return sortFunction ? sortFunction(a, b) : naturalSort(a, b);
    }
  });
}

// TODO: convert all of the optional params to an object
function calculateNumberChartValues(
  rangeCalculation: RangeCalculation,
  propertiesHash: { [key: string]: Property },
  resultColToOrientation: { [key: string]: AxisOrientationType },
  byPropertyCalculationType?: ByPropertyCalculationType,
  quarterly?: boolean,
  addTotalDimension?: boolean,
  sortDataKeys?: NaturalSortOrStringSortFunction,
  sortDimensionOptions?: boolean,
  sortDataByPrimaryAxisKey?: NaturalSortOrStringSortFunction,
  showTopN?: number,
): ChartOrTableValues {
  const rangeValues = rangeCalculation.values;
  const primaryAxisKey = byPropertyCalculationType ? "property" : "month";
  const dimensionDictionary: { [key: string]: FrontEndUnit } = {};
  const data: {
    [key: string]: string | ChartOrTableDataValue;
  }[] = [];
  for (const monthPropertyOrQuarterKey of Object.keys(rangeValues)) {
    const property = propertiesHash[monthPropertyOrQuarterKey];
    const monthAndYearPropertyOrQuarterName = byPropertyCalculationType
      ? property?.property_code
        ? `${property.property_code} – ${property.property_name}`
        : monthPropertyOrQuarterKey // a Property Tag
      : quarterly
        ? monthPropertyOrQuarterKey
        : dateStrToMonthAndYear(monthPropertyOrQuarterKey, true);
    let valueArray = rangeValues[monthPropertyOrQuarterKey];
    const datum: { [key: string]: string | ChartOrTableDataValue } = {
      [primaryAxisKey]: monthAndYearPropertyOrQuarterName,
    };
    if (showTopN) {
      valueArray = valueArray
        .sort((a, b) => {
          if (typeof a.value === "number" && typeof b.value === "number") {
            return (b.value as number) - a.value;
          } else {
            return compareCountOfTotal(
              a.value as CountOfTotal,
              b.value as CountOfTotal,
              -1,
            );
          }
        })
        .slice(0, showTopN);
    }

    for (const current of valueArray) {
      if (Object.keys(current).length > 0) {
        const dimensionWithKey = getDimensionForTableValue(current);
        const dimension = getOverriddenDimension(
          dimensionWithKey.key,
          dimensionWithKey.value,
        );
        dimensionDictionary[dimension] = current.unit;
        datum[dimension] = {
          calculationType: current.calculationType,
          value: current.value,
          unit: current.unit,
        };
      }
    }
    data.push(datum);
  }
  const sortedData = sortDataByPrimaryAxisKey
    ? typeof sortDataByPrimaryAxisKey === "boolean"
      ? orderBy(data, (datum) => datum[primaryAxisKey], "asc")
      : data.sort((a, b) =>
          sortDataByPrimaryAxisKey(
            a[primaryAxisKey] as string,
            b[primaryAxisKey] as string,
          ),
        )
    : data;

  const chartColors =
    Object.keys(dimensionDictionary).length > colors.length
      ? backupColors
      : colors;
  // hack to make sure total is last column
  if (addTotalDimension && dimensionDictionary["Total"]) {
    const totalDimension = dimensionDictionary["Total"];
    delete dimensionDictionary["Total"];
    dimensionDictionary["Total"] = totalDimension;
  }
  const dimensionKeys =
    sortDataKeys == null || typeof sortDataKeys === "boolean"
      ? sortDimensionKeys(Object.keys(dimensionDictionary), primaryAxisKey)
      : sortDimensionKeys(
          Object.keys(dimensionDictionary),
          primaryAxisKey,
          sortDataKeys,
        );
  const secondaryAxisKeys: { [key: string]: SecondaryAxisKeyValue } = {};
  for (let i = 0; i < dimensionKeys.length; i++) {
    const current = dimensionKeys[i];
    secondaryAxisKeys[current] = {
      colorHex: chartColors[i % chartColors.length],
      unit: dimensionDictionary[current],
      orientation: resultColToOrientation[current] || "left",
    };
  }
  const dataUnavailable =
    Object.keys(secondaryAxisKeys).length === 0 || sortedData.length === 0;
  const dimensionOptions = Array.from(rangeCalculation.dimensionOptions);
  if (sortDimensionOptions) {
    dimensionOptions.sort(naturalSort);
  }
  return {
    primaryAxisKey: primaryAxisKey,
    secondaryAxisKeys: secondaryAxisKeys,
    data: sortedData,
    dimensionOptions: dimensionOptions,
    dataUnavailable,
    monthOptions: Array.from(rangeCalculation.monthOptions),
  };
}

function compareChartOrTableDataValue(
  a: ChartOrTableDataValue | undefined,
  b: ChartOrTableDataValue | undefined,
): number {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  if (a.calculationType === "NUMBER") {
    const aValue = a.value as number;
    const bValue = b.value as number;
    return bValue - aValue;
  } else {
    return compareCountOfTotal(
      a.value as CountOfTotal,
      b.value as CountOfTotal,
      -1,
    );
  }
}

function compareDimensionedValues(
  a: ChartOrTableDataValue[],
  b: ChartOrTableDataValue[],
): number {
  if (a.length === 0 && b.length === 0) return 0;
  if (a.length === 0) return 1;
  if (b.length === 0) return -1;
  if (a[0].calculationType === "NUMBER") {
    let aSum = 0;
    let bSum = 0;
    for (const val of a) {
      aSum = aSum + (val.value as number);
    }
    for (const val of b) {
      bSum = bSum + (val.value as number);
    }
    return bSum - aSum;
  } else {
    let aCountOfTotal = { count: 0, total: 0 };
    let bCountOfTotal = { count: 0, total: 0 };
    for (const val of a) {
      aCountOfTotal = addCountOfTotal(aCountOfTotal, val.value as CountOfTotal);
    }
    for (const val of b) {
      bCountOfTotal = addCountOfTotal(bCountOfTotal, val.value as CountOfTotal);
    }
    return compareCountOfTotal(aCountOfTotal, bCountOfTotal, -1);
  }
}

export function calculateCategoryChartValues(
  rangeCalculation: RangeCalculation,
  categoryInformation: CategoryChartInformation,
  resultColToOrientation: { [key: string]: AxisOrientationType },
  propertiesHash: { [key: string]: Property },
  addTotalDimension?: boolean,
  byPropertyCalculationType?: string,
  sortDataKeys?: NaturalSortOrStringSortFunction,
  sortDimensionOptions?: boolean,
  sortDataByPrimaryAxisKey?: NaturalSortOrStringSortFunction,
  showTopNCategories?: ShowTopNCategoriesConfig,
): ChartOrTableValues {
  const rangeValueSets = rangeCalculation.values;
  const dimensionKey = categoryInformation.dimensionKey;
  const categoryKey = categoryInformation.categoryKey;
  let dataKeyedByDimensionKey: {
    [key: string]: { [key1: string]: ChartOrTableDataValue };
  } = {};
  const categoriesDictionary: { [key: string]: FrontEndUnit } = {};
  const primaryAxisKey = byPropertyCalculationType ? "property" : "month";
  for (const monthKey of Object.keys(rangeValueSets)) {
    const rangeValues = rangeValueSets[monthKey];
    for (const value of rangeValues) {
      const dimensions = value.dimensions as { [key: string]: string };
      const property = propertiesHash[monthKey];
      const dimensionValue = byPropertyCalculationType
        ? `${property.property_code} – ${property.property_name}`
        : dateStrToMonthAndYear(monthKey, true);
      dimensions[primaryAxisKey] = dimensionValue;
      const rawDimensionMasterKey = dimensions[dimensionKey];
      const dimensionMasterKey = getOverriddenDimension(
        dimensionKey,
        rawDimensionMasterKey,
      );
      const overriddenCategoryKey = getOverriddenDimension(
        categoryKey,
        dimensions[categoryKey],
      );
      if (!dataKeyedByDimensionKey[dimensionMasterKey]) {
        dataKeyedByDimensionKey[dimensionMasterKey] = {};
      }
      if (dataKeyedByDimensionKey[dimensionMasterKey][overriddenCategoryKey]) {
        if (typeof value.value === "number") {
          const currentValue = dataKeyedByDimensionKey[dimensionMasterKey][
            overriddenCategoryKey
          ].value as number;
          dataKeyedByDimensionKey[dimensionMasterKey][
            overriddenCategoryKey
          ].value = currentValue + value.value;
        } else {
          const newCountOTotal = value.value as CountOfTotal;
          const currentValue = dataKeyedByDimensionKey[dimensionMasterKey][
            overriddenCategoryKey
          ].value as CountOfTotal;
          const currentCount = currentValue.count;
          const currentTotal = currentValue.total;
          dataKeyedByDimensionKey[dimensionMasterKey][
            overriddenCategoryKey
          ].value = {
            count: currentCount + newCountOTotal.count,
            total: currentTotal + newCountOTotal.total,
          };
        }
      } else {
        dataKeyedByDimensionKey[dimensionMasterKey][overriddenCategoryKey] =
          value;
      }
      categoriesDictionary[overriddenCategoryKey] = value.unit;
    }
  }
  let dataKeys = Object.keys(dataKeyedByDimensionKey);
  if (showTopNCategories) {
    dataKeys = dataKeys
      .sort((a, b) => {
        if (showTopNCategories.sortKey === "dimensions") {
          const aDimensionedValues = Object.values(dataKeyedByDimensionKey[a]);
          const bDimensionedValues = Object.values(dataKeyedByDimensionKey[b]);
          return compareDimensionedValues(
            aDimensionedValues,
            bDimensionedValues,
          );
        } else {
          const aDatum = dataKeyedByDimensionKey[a][showTopNCategories.sortKey];
          const bDatum = dataKeyedByDimensionKey[b][showTopNCategories.sortKey];
          return compareChartOrTableDataValue(aDatum, bDatum);
        }
      })
      .slice(0, showTopNCategories.topN);
  }
  if (sortDataByPrimaryAxisKey) {
    const sortFunction =
      typeof sortDataByPrimaryAxisKey === "boolean"
        ? naturalSort
        : sortDataByPrimaryAxisKey;
    dataKeys.sort(sortFunction);
  }
  const data: { [x: string]: string | ChartOrTableDataValue }[] = [];
  for (const key1 of dataKeys) {
    const dataWithoutKey = dataKeyedByDimensionKey[key1];
    data.push({
      [dimensionKey]: key1,
      ...dataWithoutKey,
    });
  }
  const chartColors =
    Object.keys(categoriesDictionary).length > 10 ? backupColors : colors;
  // hack to make sure total is last column
  if (addTotalDimension && categoriesDictionary["Total"]) {
    const totalDimension = categoriesDictionary["Total"];
    delete categoriesDictionary["Total"];
    categoriesDictionary["Total"] = totalDimension;
  }
  const dimensionKeys =
    sortDataKeys == null || typeof sortDataKeys === "boolean"
      ? sortDimensionKeys(Object.keys(categoriesDictionary), primaryAxisKey)
      : sortDimensionKeys(
          Object.keys(categoriesDictionary),
          primaryAxisKey,
          sortDataKeys,
        );
  const secondaryAxisKeys: { [key: string]: SecondaryAxisKeyValue } = {};
  for (let idx = 0; idx < dimensionKeys.length; idx++) {
    const current = dimensionKeys[idx];
    secondaryAxisKeys[current] = {
      colorHex: chartColors[idx % chartColors.length],
      unit: categoriesDictionary[current],
      orientation: resultColToOrientation[current] || "left",
    };
  }
  const dataUnavailable =
    Object.keys(secondaryAxisKeys).length === 0 || data.length === 0;
  const dimensionOptions = Array.from(rangeCalculation.dimensionOptions);
  if (sortDimensionOptions) {
    dimensionOptions.sort(naturalSort);
  }
  return {
    primaryAxisKey: dimensionKey,
    secondaryAxisKeys: secondaryAxisKeys,
    data: data,
    dimensionOptions: dimensionOptions,
    dataUnavailable: dataUnavailable,
    monthOptions: Array.from(rangeCalculation.monthOptions),
  };
}

export interface FilterByDimension {
  dimension: string;
  selectedValue: string;
}

// Maybe move the optional params to an options object as this is becoming unwieldy
export function calculateChartOrTableValues(
  insight: Insight,
  rangeData: RangedInsights,
  rangeDataAYearAgo: RangedInsights,
  insightsMap: { [id: string]: BackendInsight },
  propertiesHash: { [key: string]: Property },
  filterByDimension?: FilterByDimension,
  monthsSelected?: Set<string>,
  sortByDateDesc?: boolean,
  showTopNCategoriesEligible?: boolean,
  yoy?: boolean,
  drillIn?: boolean,
): ChartOrTableValues {
  const rangeValues = calculateRangeValues(
    insight,
    rangeData,
    rangeDataAYearAgo,
    insightsMap,
    filterByDimension,
    monthsSelected,
    sortByDateDesc,
    yoy,
    drillIn,
  );

  const complexInsightIds = insight.insightIds as ComplexInsightIds[];
  const resultColToOrientation: { [key: string]: AxisOrientationType } = {};

  for (const current of complexInsightIds) {
    if (current.yAxisOrientation && current.resultCol) {
      resultColToOrientation[current.resultCol] = current.yAxisOrientation;
    }
  }
  if (insight.categoryInformation) {
    return calculateCategoryChartValues(
      rangeValues,
      insight.categoryInformation,
      resultColToOrientation,
      propertiesHash,
      insight.addTotalDimension,
      insight.byPropertyCalculationType,
      insight.generalChartOptions?.sortDataKeys,
      insight.generalChartOptions?.sortDimensionOptions,
      insight.generalChartOptions?.sortDataByPrimaryAxisKey === false
        ? false
        : insight.generalChartOptions?.sortDataByPrimaryAxisKey ||
            !!insight.byPropertyCalculationType,
      showTopNCategoriesEligible
        ? insight.categoryInformation.showTopNCategories
        : undefined,
    );
  } else {
    return calculateNumberChartValues(
      rangeValues,
      propertiesHash,
      resultColToOrientation,
      insight.byPropertyCalculationType,
      insight.quarterly,
      insight.addTotalDimension,
      insight.generalChartOptions?.sortDataKeys,
      insight.generalChartOptions?.sortDimensionOptions,
      insight.generalChartOptions?.sortDataByPrimaryAxisKey === false
        ? false
        : insight.generalChartOptions?.sortDataByPrimaryAxisKey ||
            !!insight.byPropertyCalculationType,
      showTopNCategoriesEligible ? insight.showTopN : undefined,
    );
  }
}

export function calculateChartValues(
  insight: Insight,
  rangeData: RangedInsights,
  rangeDataAYearAgo: RangedInsights,
  insightsMap: { [id: string]: BackendInsight },
  propertiesHash: { [key: string]: Property },
  filterByDimension?: FilterByDimension,
  monthsSelected?: Set<string>,
  drillIn?: boolean,
): ChartValues {
  const chartOrTableValues = calculateChartOrTableValues(
    insight,
    rangeData,
    rangeDataAYearAgo,
    insightsMap,
    propertiesHash,
    filterByDimension,
    monthsSelected,
    false,
    true,
    drillIn,
  );
  const data: { [key: string]: string | number }[] = [];
  for (const dataObject of chartOrTableValues.data) {
    const newDataObject: { [key: string]: string | number } = {};
    for (const key of Object.keys(dataObject)) {
      const value = dataObject[key];
      if (typeof value === "string") {
        newDataObject[key] = value;
      } else if (typeof value.value === "number") {
        newDataObject[key] = value.value;
      } else if (value.calculationType === "PERCENTAGE") {
        newDataObject[key] = toPercentageNum(value.value as CountOfTotal);
      } else {
        newDataObject[key] = ratioToNumber(
          value.value as CountOfTotal,
          value.unit,
        );
      }
    }
    data.push(newDataObject);
  }

  const yAxisIds: { [key: string]: AxisOrientationType } = {};
  for (const item of insight.insightIds as ComplexInsightIds[]) {
    const colName = item.resultCol;
    if (item?.yAxisOrientation) {
      yAxisIds[colName] = item.yAxisOrientation;
    }
  }

  return {
    primaryAxisKey: chartOrTableValues.primaryAxisKey,
    secondaryAxisKeys: chartOrTableValues.secondaryAxisKeys,
    data: data,
    dimensionOptions: chartOrTableValues.dimensionOptions,
    dataUnavailable: chartOrTableValues.dataUnavailable,
    monthOptions: chartOrTableValues.monthOptions,
  };
}

export function calculateTableValues(
  insight: Insight,
  rangeData: RangedInsights,
  rangeDataAYearAgo: RangedInsights,
  insightsMap: { [id: string]: BackendInsight },
  propertiesHash: { [key: string]: Property },
  filterByDimension?: FilterByDimension,
  monthsSelected?: Set<string>,
  yoy?: boolean,
  drillIn?: boolean,
): TableValues {
  const chartValues = calculateChartOrTableValues(
    insight,
    rangeData,
    rangeDataAYearAgo,
    insightsMap,
    propertiesHash,
    filterByDimension,
    monthsSelected,
    !insight.byPropertyCalculationType && !insight.quarterly,
    false,
    yoy,
    drillIn,
  );

  if (insight?.tableChartOptions?.inverted) {
    const rowHeaders = Object.keys(chartValues.secondaryAxisKeys);
    const colHeaders: string[] = [];
    for (const datum of chartValues.data) {
      colHeaders.push(datum[chartValues.primaryAxisKey] as string);
    }
    const groupedByRow: {
      [key: string]: { [key: string]: ChartOrTableDataValue };
    } = {};
    for (let idx = 0; idx < chartValues.data.length; idx++) {
      const colKey = colHeaders[idx];
      const current = chartValues.data[idx];
      for (const [key, value] of Object.entries(current)) {
        if (typeof value !== "string") {
          if (!groupedByRow[key]) groupedByRow[key] = {};
          groupedByRow[key][colKey] = value;
        }
      }
    }
    const rows: { [key: string]: string }[] = [];
    for (const rowHeader of rowHeaders) {
      const val = groupedByRow[rowHeader];
      const row: { [key: string]: string } = {};
      for (const key of Object.keys(val)) {
        const value = val[key];
        const unit = chartValues.secondaryAxisKeys[rowHeader].unit;
        row[key] = tableValueFormatter(value, unit);
      }
      rows.push(row);
    }
    return {
      rowHeaders,
      colHeaders,
      rows,
      dimensionOptions: chartValues.dimensionOptions,
      dataUnavailable: chartValues.dataUnavailable,
      monthOptions: chartValues.monthOptions,
    };
  } else {
    const colHeaders = Object.keys(chartValues.secondaryAxisKeys);
    const rowHeaders: string[] = [];
    for (const datum of chartValues.data) {
      rowHeaders.push(datum[chartValues.primaryAxisKey] as string);
    }
    const rows: { [key: string]: string }[] = [];
    for (const val of chartValues.data) {
      delete val[chartValues.primaryAxisKey];
      const row: { [key: string]: string } = {};
      for (const key of Object.keys(val)) {
        const value = val[key];
        const unit = chartValues.secondaryAxisKeys[key].unit;
        row[key] = tableValueFormatter(value, unit);
      }
      rows.push(row);
    }

    return {
      rowHeaders,
      colHeaders,
      rows,
      dimensionOptions: chartValues.dimensionOptions,
      dataUnavailable: chartValues.dataUnavailable,
      monthOptions: chartValues.monthOptions,
    };
  }
}
