import {
  faCloudRainbow,
  faDownload,
  faEye,
  faEyeSlash,
  faLocationArrow,
  faLockAlt,
  faPlus,
  faShare,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";
import {
  CustomColumn,
  CustomColumnType,
  getColumnDataType,
  getCustomColumnName,
  NewPropertyDto,
  PropertyColumnDefaultTitle,
  PropertyColumnKey,
} from "@joyhub-integration/shared";
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ButtonProps, Input } from "reactstrap";
import { getInstantData } from "../../../services/dataService";
import { AllUnitCount } from "../../../services/insightLibrary/backendInsightIds";
import {
  addProperty,
  exportProperties,
  getPMSProperties,
  getProperties,
  hideUnHideProperty,
  importProperties,
  Property,
  PropertyWithUnitCount,
  PropertyWithVendor,
  updateGeocode,
} from "../../../services/propertiesService";
import { MSAList, statesList } from "../../../services/selection";
import {
  getCustomFieldValuesFromProperty,
  PropertyColumnCellType,
} from "../../../utils/customColumns";
import { downloadAttachment } from "../../../utils/download";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import ButtonWithIcon, {
  ButtonWithIconProps,
} from "../../common/button/ButtonWithIcon";
import TableWithSelection from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import DeletePropertyModal from "./DeletePropertyModal";
import EditColumnsModal from "./EditColumnsModal";
import ImagePreview from "./ImagePreview";
import { NewPropertyModal } from "./NewPropertyModal";
import "./properties.css";
import PropertyColumnCell from "./PropertyColumnCell";
import PropertyCustomColumnCell from "./PropertyCustomColumnCell";
import PropertyDeleteCell from "./PropertyDeleteCell";
import SharePropertyModal from "./SharePropertyModal";

const Properties: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [isAddingNewProperty, setIsAddingNewProperty] = useState(false);
  const [sortBy, setSortBy] = useState<string>("property_name");
  const [sortDirection, setSortDirection] = useState<string>("asc");
  const [tableKey, setTableKey] = useState(0);
  const [pmsProperties, setPMSProperties] = useState<PropertyWithVendor[]>();
  const [properties, setProperties] = useState<Array<PropertyWithUnitCount>>();
  const [textColumnOptions, setTextColumnOptions] =
    useState<Record<string, string[]>>();
  const [selected, setSelected] = useState<PropertyWithUnitCount>();
  const [sharing, setSharing] = useState<PropertyWithUnitCount>();
  const [editing, setEditing] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<PropertyWithUnitCount>();
  const [editingMetadata, setEditingMetadata] = useState<boolean>(false);
  const [importing, setImporting] = useState(false);
  const importInput = useRef<HTMLInputElement>(null);
  const pageNumber = useRef<number>(1);
  const isPaginating = useRef<boolean>(false);
  const didReachEnd = useRef<boolean>(false);
  const { platform, setProperties: setPropertiesInContext } =
    React.useContext(PlatformContext);

  const [propertyFilter, setPropertyFilter] = useState("");
  const debouncedSetPropertyFilter = debounce(
    (value) => setPropertyFilter(value),
    300,
  );

  const {
    superAdmin,
    organization: { configuration },
  } = platform!;
  const isAdmin = platform!.admin;
  const isEditor = platform!.organization_role === "Editor";
  const [showHidden, setShowHidden] = useState(false);
  const [customColumns, setColumnColumns] = useState(
    configuration.customColumns ?? [],
  );

  const loadProperties = useCallback(
    async (
      refresh: boolean = false,
      search: string = propertyFilter,
      hidden: boolean = showHidden,
      paginate: boolean = false,
    ) => {
      if (isPaginating.current && !paginate) return;
      if (refresh) {
        didReachEnd.current = false;
        pageNumber.current = 1;
      }
      const [propertiesRes, hiddenPropertiesRes] = await Promise.all([
        getProperties(false, sortBy, sortDirection, pageNumber.current, search),
        getProperties(true, sortBy, sortDirection, pageNumber.current, search),
      ]);

      const hiddenPropertyIds = hidden
        ? hiddenPropertiesRes.map((property: Property) => property.id)
        : [];
      const instantData = await getInstantData(
        [AllUnitCount],
        hidden ? { ids: hiddenPropertyIds } : {},
        undefined,
        "property",
      );
      const propertiesWithUnitCount = (
        hidden ? hiddenPropertiesRes : propertiesRes
      ).map((p: Property) => {
        const unitCount = instantData.byProperty[p.id]?.[AllUnitCount] as
          | number
          | undefined;
        return {
          ...p,
          unit_count:
            !p.pms_override_columns.includes("unit_count") && unitCount
              ? unitCount
              : p.unit_count,
          insightUnitCount: unitCount,
        };
      });
      let systemIds = (hidden ? hiddenPropertiesRes : propertiesRes).map(
        (p: Property) => p.system_id,
      );
      const pms = await getPMSProperties(systemIds);
      setPMSProperties(pms);
      if (pageNumber.current === 1) {
        setProperties(propertiesWithUnitCount);
      } else {
        setProperties((prevProperties = []) =>
          prevProperties.concat(
            propertiesWithUnitCount.filter(
              (p: PropertyWithUnitCount) =>
                !prevProperties.some((existing) => existing.id === p.id),
            ),
          ),
        );
      }
      if (propertiesWithUnitCount.length === 0) didReachEnd.current = true;
    },
    [propertyFilter, showHidden, sortBy, sortDirection],
  );

  useEffect(() => {
    loadProperties(true, propertyFilter);
  }, [loadProperties, propertyFilter]);

  const reloadPlatformProperties = useCallback(() => {
    getProperties(false, sortBy, sortDirection).then(setPropertiesInContext);
  }, [setPropertiesInContext, sortBy, sortDirection]);

  useEffect(() => {
    const sel = properties?.find((p) => p.id === selected?.id);
    if (sel !== selected) setSelected(sel);
  }, [properties, selected]);

  useEffect(() => {
    const record: Record<string, string[]> = {};

    (properties ?? []).forEach((p) =>
      Object.keys(p)
        .filter((key) => getColumnDataType(key as keyof PropertyWithUnitCount))
        .forEach((key) => {
          const option = p[key as keyof PropertyWithUnitCount]?.toString();
          if (option) {
            if (!record[key]) {
              record[key] = [];
            }
            record[key].push(option);
          }
        }),
    );

    record["state"] = Object.values(statesList);
    record["msa"] = [...MSAList, "Other", "N/A"];

    setTextColumnOptions(record);
  }, [properties]);

  async function handleAddProperty(p: NewPropertyDto) {
    setIsAddingNewProperty(false);
    try {
      const addPropertyResponse = await addProperty(p);
      const propertyId: number | null = addPropertyResponse?.data.id ?? null;
      if (propertyId) {
        try {
          await updateGeocode(propertyId);
        } catch {
          console.error("Geocode failed while adding a new property");
        }
      }
      setAlert(`${p.property_name} has successfully been added.`, true);
    } catch (e) {
      onUnexpectedError(e);
    }
  }

  function toggleProperty(
    selected: PropertyWithUnitCount | undefined,
    hidden: boolean,
  ) {
    if (selected != null) {
      hideUnHideProperty(selected.id, hidden)
        .then(() => {
          // after hiding/restoring it disappears from view
          setProperties(
            properties?.filter((property) => property.id !== selected.id),
          );
          reloadPlatformProperties();
          setAlert(
            `Property ${selected.property_name} ${
              hidden ? "hidden" : "restored"
            }.`,
            true,
          );
        })
        .catch(onUnexpectedError);
    }
  }

  function exportProperty() {
    exportProperties().then(downloadAttachment("Properties"));
  }

  const onImportClicked = () => {
    if (importing || !importInput.current) return;
    importInput.current.value = "";
    importInput.current.click();
  };

  async function updateGeoCoordinates(id: number) {
    if (selected) {
      await updateGeocode(selected.id)
        .then(() => {
          setAlert(`Coordinates updated for ${selected.property_name}.`, true);
        })
        .catch(() => {
          setAlert(
            `Coordinates could not be updated.  Please check the address, city, state, and zip code and try again.`,
            false,
          );
        });
    }
  }

  const onImportFileSelected = () => {
    const file = importInput.current?.files?.[0];
    if (!file) return;
    setImporting(true);
    importProperties(file)
      .then(() => loadProperties(true))
      .then(() => {
        reloadPlatformProperties();
        setAlert(`Property data imported`, true);
        new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          //reloads the table after a successful import
          setTableKey((prevKey) => prevKey + 1);
        });
      })
      .catch(onUnexpectedError)
      .finally(() => {
        setImporting(false);
        if (importInput.current) importInput.current.value = "";
      });
  };

  const buttonProps: (ButtonWithIconProps | React.ReactElement)[] = [
    {
      id: "button-HideRestore",
      icon: showHidden ? faEye : faEyeSlash,
      disabled: !selected,
      onClick: () => toggleProperty(selected, !showHidden),
      tooltip: showHidden ? "Restore Property" : "Hide Property",
    },
    ...(superAdmin
      ? [
          {
            icon: faShare,
            disabled: !selected?.front_end,
            onClick: () => setSharing(selected),
            tooltip: "Share Property",
          },
        ]
      : []),
  ];

  const middleButtonProps: ButtonProps[] = [
    {
      onClick: () => {
        setShowHidden(false);
        loadProperties(true, propertyFilter, false);
      },
      label: "Active",
      tooltip: "Active Properties",
      outline: showHidden,
    },
    {
      onClick: () => {
        setShowHidden(true);
        loadProperties(true, propertyFilter, true);
      },
      label: "Hidden",
      tooltip: "Hidden Properties",
      outline: !showHidden,
    },
  ];
  const rightButtonProps: (ButtonWithIconProps | React.ReactElement)[] = [];
  if (isAdmin) {
    rightButtonProps.push({
      icon: faCloudRainbow,
      disabled: false,
      onClick: () => setEditingMetadata(true),
      label: "Edit Columns",
      className: "btn btn-secondary",
    });
    rightButtonProps.push({
      icon: faUpload,
      disabled: importing,
      onClick: onImportClicked,
      tooltip: "Import",
    });
  }
  rightButtonProps.push(
    ...[
      {
        icon: faDownload,
        disabled: false,
        onClick: exportProperty,
        tooltip: "Export",
      },
      {
        icon: faLocationArrow,
        disabled: !selected?.address,
        onClick: () => selected && updateGeoCoordinates(selected?.id),
        tooltip: "Update Coordinates",
      },
      <Input
        className="w-auto ms-3 py-1 rounded-pill"
        size={36}
        type="text"
        placeholder="Filter Properties"
        onChange={(e) => debouncedSetPropertyFilter(e.target.value)}
      />,
    ],
  );

  const lockButtonProps: ButtonWithIconProps = {
    className: "property-icon-button",
    iconStyle: { width: 11, height: 11 },
    icon: faLockAlt,
  };

  const isColumnLocked = useCallback(
    (columnKey: string, p: PropertyWithUnitCount) => {
      if (columnKey === "property_code") {
        return true;
      }

      if (columnKey === "state" || columnKey === "city") {
        //sometimes pms data returns the incorrect data for city/state which is why we always allow these columns to be editable
        return false;
      } else if (columnKey === "unit_count") {
        return (
          p.front_end &&
          (p.insightUnitCount ?? 0) !== 0 &&
          !p.pms_override_columns.includes("unit_count")
        );
      } else if (
        columnKey === "latitude" ||
        columnKey === "longitude" ||
        columnKey === "property_code"
      ) {
        return true;
      } else {
        return (pmsProperties ?? []).flat().some((pms) => {
          if (
            pms.vendor === "RealPage" &&
            (columnKey === "address" ||
              columnKey === "city" ||
              columnKey === "state" ||
              columnKey === "zip_code" ||
              columnKey === "country")
          ) {
            return false; //always allow user to edit these fields if they are from realpage
          } else {
            return (
              pms.system_id === p.system_id &&
              pms.source_property_id === p.source_property_id &&
              (pms[columnKey as keyof Property]?.toString() ?? "").trim() !== "" //only locks if the pms data is not empty
            );
          }
        });
      }
    },
    [pmsProperties],
  );

  const textColumnOptionsForKey = useCallback(
    (columnKey: string) => {
      return textColumnOptions && textColumnOptions[columnKey]
        ? (textColumnOptions[columnKey]
            .filter((value, index, self) => {
              const lowerCaseVal = value.toLowerCase();
              return (
                self.findIndex((v) => v.toLowerCase() === lowerCaseVal) ===
                index
              );
            })
            .sort() ?? [])
        : [];
    },
    [textColumnOptions],
  );

  const updateTextColumnOptionsForKey = useCallback(
    (key: string, old: string, value?: string) => {
      setTextColumnOptions((prevOptions) => {
        const updatedOptions = { ...prevOptions };
        const myarr = updatedOptions[key] ? [...updatedOptions[key]] : [];

        const index = myarr.findIndex((item) => item === old);
        if (index !== -1) {
          myarr.splice(index, 1);
        }
        if (value) {
          myarr.push(value);
        }
        myarr.sort();
        updatedOptions[key] = myarr;

        return updatedOptions;
      });
    },
    [],
  );

  const getPropertyColumnCell = useCallback(
    (columnKey: string, dataType: (typeof CustomColumnType)[number]) =>
      (p: PropertyWithUnitCount, colIdx: number) => {
        let isLocked = isColumnLocked(columnKey, p);
        return (
          <PropertyColumnCell
            id={`p-${p.id}-${colIdx}`}
            propertyId={p.id}
            columnKey={columnKey}
            dataType={dataType}
            isLocked={isLocked}
            originalValue={
              p[columnKey as keyof PropertyWithUnitCount]?.toString() ?? ""
            }
            textColumnOptions={textColumnOptionsForKey(columnKey)}
            onSavedValue={(old, newValue) =>
              updateTextColumnOptionsForKey(columnKey, old, newValue)
            }
            onBeginEdit={() => {
              setSelected(undefined);
              setEditing(true);
            }}
            onFinishEdit={() => {
              setEditing(false);
            }}
          />
        );
      },
    [isColumnLocked, textColumnOptionsForKey, updateTextColumnOptionsForKey],
  );

  const getPropertyDeleteCell =
    () => (p: PropertyWithUnitCount, colIdx: number) => {
      return (
        <PropertyDeleteCell
          key={`p-${p.id}-${colIdx}`}
          propertyId={p.id}
          onDeleteProperty={() => {
            setDeleting(p);
            setSelected(undefined);
            setEditing(false);
          }}
        ></PropertyDeleteCell>
      );
    };

  const getPropertyCustomColumnCell = useCallback(
    (column: CustomColumn) => (p: PropertyWithUnitCount, colIdx: number) => {
      let isLocked = isColumnLocked(column.columnKey, p);
      const propertyColumnsWithValue = getCustomFieldValuesFromProperty(
        p,
        customColumns ?? [],
      );
      let customColumnValue = propertyColumnsWithValue.find(
        (col) => col.propertyId === p.id && col.columnKey === column.columnKey,
      );
      if (customColumnValue)
        return (
          <PropertyCustomColumnCell
            id={`p-${p.id}-${colIdx}`}
            propertyId={p.id}
            propertyColumns={propertyColumnsWithValue}
            customColumn={customColumnValue}
            isLocked={isLocked}
            originalValue={customColumnValue.value?.toString() ?? ""}
            textColumnOptions={textColumnOptionsForKey(column.columnKey)}
            onBeginEdit={() => {
              setSelected(undefined);
              setEditing(true);
            }}
            onFinishEdit={() => setEditing(false)}
            onSavedValue={(old, newValue) =>
              updateTextColumnOptionsForKey(column.columnKey, old, newValue)
            }
          />
        );
    },
    [
      customColumns,
      isColumnLocked,
      textColumnOptionsForKey,
      updateTextColumnOptionsForKey,
    ],
  );

  const onCloseMetadataModal = (changesMade: boolean) => {
    if (changesMade) {
      reloadPlatformProperties();
    }
    setEditingMetadata(false);
  };

  const onCloseDeleteModal = async (
    changesMade: boolean,
    propertyName: string,
  ) => {
    if (changesMade) {
      setAlert(`${propertyName} was successfully deleted.`, true);
      await loadProperties(showHidden);
      reloadPlatformProperties();
    }
    setDeleting(undefined);
  };

  const createColumnCell = useCallback(
    (key: keyof typeof PropertyColumnKey): PropertyColumnCellType => {
      return {
        key: key,
        title:
          customColumns?.find((col) => col.columnKey === key)?.name ??
          PropertyColumnDefaultTitle[
            key as keyof typeof PropertyColumnDefaultTitle
          ],
        toValue: getPropertyColumnCell(
          key,
          getColumnDataType(key as PropertyColumnKey),
        ),
      };
    },
    [getPropertyColumnCell, customColumns],
  );

  const tableCols = useMemo(() => {
    let defaultColumns: PropertyColumnCellType[] = [
      createColumnCell("loan_amount"),
      createColumnCell("origination_date"),
      createColumnCell("interest_rate"),
      createColumnCell("term"),
      createColumnCell("interest_only_period"),
      createColumnCell("amortization"),
      createColumnCell("lender_name"),
      createColumnCell("lender_contact"),
      createColumnCell("lender_email"),
      createColumnCell("property_manager_name"),
      createColumnCell("property_manager_email"),
      createColumnCell("regional_manager_name"),
      createColumnCell("regional_manager_email"),
      createColumnCell("property_code"),
      createColumnCell("property_name"),
      createColumnCell("property_alias"),
      createColumnCell("is_comparable"),
      createColumnCell("address"),
      createColumnCell("city"),
      createColumnCell("state"),
      createColumnCell("zip_code"),
      createColumnCell("latitude"),
      createColumnCell("longitude"),
      createColumnCell("pms_name"),
      createColumnCell("region"),
      createColumnCell("msa"),
      createColumnCell("apn"),
      createColumnCell("fips"),
      createColumnCell("unit_count"),
      createColumnCell("total_sqft"),
      createColumnCell("rentable_sqft"),
      createColumnCell("parcel_size"),
      createColumnCell("owner_name"),
      createColumnCell("contact_person_name"),
      createColumnCell("contact_person_email"),
      createColumnCell("year_built"),
      createColumnCell("year_updated"),
      createColumnCell("property_class"),
      createColumnCell("capitalization_rate"),
      createColumnCell("property_type"),
      createColumnCell("floor_count"),
      createColumnCell("building_count"),
      createColumnCell("elevator_count"),
      createColumnCell("parking_count"),
      createColumnCell("commercial_unit_count"),
      createColumnCell("purchase_date"),
      createColumnCell("purchase_price"),
      createColumnCell("market"),
      createColumnCell("website"),
      createColumnCell("phone"),
    ].filter(
      (cell) =>
        !(customColumns ?? []).some((col) => col.columnKey === cell.key),
    );

    return [
      {
        key: "upload_btn",
        title: "",
        toValue: (p: PropertyWithUnitCount) => <ImagePreview property={p} />,
      },
      ...(defaultColumns ?? []).map((c) => ({
        key: c.key,
        title:
          c.title ??
          PropertyColumnDefaultTitle[
            c.key as keyof typeof PropertyColumnDefaultTitle
          ],
        toValue: c.toValue,
      })),
      ...(customColumns ?? [])
        .filter((col) => !col.hidden)
        .map((c) => ({
          key: c.columnKey,
          title: getCustomColumnName(c),
          toValue: getPropertyCustomColumnCell(c),
        })),
      { key: "system_name", title: PropertyColumnDefaultTitle.system_name },
      { key: "property-delete", title: "", toValue: getPropertyDeleteCell() },
    ];
  }, [customColumns, createColumnCell, getPropertyCustomColumnCell]);

  const handleSelectedChange = useCallback(
    (selected: any) => {
      if (!editing) {
        setSelected(properties?.find((p) => p.id === selected?.id));
      }
    },
    [editing, properties, setSelected],
  );

  const handleOnScrollEnd = async () => {
    if (isPaginating.current || didReachEnd.current) return;
    isPaginating.current = true;
    await loadProperties(false, propertyFilter, showHidden, true);
    isPaginating.current = false;
    pageNumber.current += 1;
  };

  const handleOnSortChanged = (by: string, dir: string) => {
    setSortBy(by);
    setSortDirection(dir);
    loadProperties(true);
  };

  const handleOnColumnsSaved = useCallback((columns: CustomColumn[]) => {
    setColumnColumns(columns);
    setTableKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <>
      <ModernCrumbar primary="Manage Properties">
        <ButtonWithIcon
          label="Add Property"
          icon={faPlus}
          onClick={() => setIsAddingNewProperty(true)}
          size="sm"
          color="primary"
          className="ms-auto align-self-center"
        />
      </ModernCrumbar>
      <Input
        type="file"
        accept=".xlsx"
        innerRef={importInput}
        onChange={onImportFileSelected}
        className="d-none"
      />
      <LoadilyFadily className="jh-page-layout">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
          middleButtonProps={middleButtonProps}
        />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<PropertyWithUnitCount>
            key={tableKey}
            selected={selected}
            onSelectedChange={handleSelectedChange}
            columns={tableCols}
            rows={properties}
            sortColumn={sortBy}
            singleLineRows={true}
            onScrollEnd={handleOnScrollEnd}
            onSortChanged={handleOnSortChanged}
            garbageMemoization={true}
            rowStyle={{ cursor: "default" }}
          />
          <div className="mt-3">
            <ButtonWithIcon {...lockButtonProps} />
            <span className="lock-message-text">
              This data cannot be edited as it is from your PMS. Please make the
              update within your property management system.
            </span>
          </div>
        </div>
      </LoadilyFadily>
      {editingMetadata ? (
        <EditColumnsModal
          onSave={handleOnColumnsSaved}
          onClose={onCloseMetadataModal}
        />
      ) : null}
      {sharing ? (
        <SharePropertyModal
          property={sharing}
          onClose={() => setSharing(undefined)}
        />
      ) : null}
      {deleting ? (
        <DeletePropertyModal
          property={deleting}
          onDeleted={(propertyName) => {
            onCloseDeleteModal(true, propertyName);
          }}
          onClose={() => onCloseDeleteModal(false, deleting.property_name)}
        />
      ) : null}
      {isAddingNewProperty ? (
        <NewPropertyModal
          onClose={() => setIsAddingNewProperty(false)}
          onSave={handleAddProperty}
        />
      ) : null}
    </>
  );
};

export default withAlertModal(Properties);
