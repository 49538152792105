/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useRef, useState } from "react";

const getDynamicFont = () => {
  const boxContainer = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState("1em");

  useEffect(() => {
    function updateSize() {
      setFontSize(
        Math.max((boxContainer.current?.offsetHeight ?? 0) / 10, 5) + "px",
      );
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const getCustomDynamicFont = useCallback(
    (custom: number, prefix?: string) => {
      let customFont = parseInt(fontSize) * custom;
      if (customFont < 16) customFont = 16;
      return prefix ?? "" + customFont + "px";
    },
    [fontSize],
  );

  return {
    boxContainer,
    fontSize,
    getCustomDynamicFont,
  };
};

export default getDynamicFont;
