import {
  faCode,
  faCopy,
  faDoorClosed,
  faDoorOpen,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  DropdownMenu,
  DropdownToggle,
  PopoverBody,
  Spinner,
  UncontrolledPopover,
} from "reactstrap";

import { ReportSheet } from "@joyhub-integration/shared";
import {
  createEmbedLink,
  deleteEmbedLink,
  EmbedLinkType,
  getEmbedLinks,
  putEmbedLinkRestricted,
} from "../../services/embedLinkService";
import { SingleReportResult } from "../../services/reportsService";
import { apiUrl } from "../../utils/api";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import JhSelect, { IDropListItem } from "../common/JhSelect/JhSelect";
import TableWithoutSelection, {
  KeyValue,
} from "../common/table/TableWithoutSelection";

interface EmbedLinkItem {
  id: number;
  embedLink: string;
  restricted?: boolean;
  sheet?: number;
}

interface EmbedLinkProps {
  kind: string;
  identifier: string;
  propertyId?: number;
  sheetNames?: ReportSheet<"Unresolved">[];
  report?: SingleReportResult;
}

const EmbedLink: React.FC<EmbedLinkProps & WithAlertModalProps> = (props) => {
  const {
    kind,
    identifier,
    propertyId,
    sheetNames,
    report,
    setAlert,
    onUnexpectedError,
  } = props;

  const [isCreating, setIsCreating] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [embedLinks, setEmbedLinks] = useState<Array<EmbedLinkType>>([]);

  const [linkType, setLinkType] = useState<"Download" | "Online">("Online");
  const [sheetOptions, setSheetOptions] = useState<IDropListItem[]>();
  const [sheetOption, setSheetOption] = useState<IDropListItem>();

  const [selection] = usePropertiesSelectionQueryParam();

  const isOnline = useMemo(() => linkType === "Online", [linkType]);

  const isPropertyReport = report && report.definition.perProperty;

  useEffect(() => {
    if (sheetNames?.length) {
      const options = sheetNames.map((sn, index) => ({
        label: sn.name,
        value: index.toString(),
      }));
      setSheetOptions(options);

      if (options.length) setSheetOption(options[0]);
    }
  }, [sheetNames]);

  const uiUrl = window.location.protocol + "//" + window.location.host;

  const handleSheetChange = (value: IDropListItem) => {
    setSheetOption(value);
  };

  const getEmbedLinkActionCell =
    () =>
    ({ embedLink, id, restricted }: EmbedLinkItem) => (
      <div className="d-flex justify-content-end align-items-center">
        <CopyToClipboard
          text={embedLink}
          onCopy={() => setAlert("Embed Link is copied!", true)}
        >
          <Button
            outline
            className="d-flex border-0"
            size="sm"
            title="Copy Link to Clipboard"
          >
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </CopyToClipboard>
        <Button
          outline
          color="secondary"
          className="d-flex border-0"
          size="sm"
          title={restricted ? "Unrestrict Properties" : "Restrict Properties"}
          onClick={() => handleRestrict(id, !restricted)}
        >
          <FontAwesomeIcon icon={restricted ? faDoorClosed : faDoorOpen} />
        </Button>
        <Button
          outline
          color="danger"
          className="d-flex border-0"
          size="sm"
          title="Delete Link"
          onClick={() => handleDelete(id)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
    );

  const getEmbedDownloadLinkSheetCell =
    () =>
    ({ sheet }: EmbedLinkItem) => {
      if (sheetOptions && sheetOptions.length > 0) {
        let filterOptions = sheetOptions.filter(
          (option) => parseInt(option.value ?? "0") === sheet,
        );
        const curSheet =
          filterOptions.length > 0 ? filterOptions[0] : undefined;

        return (
          <div>
            {!curSheet?.label.length
              ? "No title"
              : `${curSheet.label.slice(0, 6)}${curSheet.label && curSheet.label.length > 6 ? "..." : ""}`}
          </div>
        );
      } else return <div>No title</div>;
    };

  const getShortEmbedLink =
    (isDownload: boolean) =>
    ({ embedLink }: EmbedLinkItem): string => {
      const startLength = isDownload ? 7 : 15;
      const endLength = isDownload ? 6 : 8;

      return `${embedLink.slice(0, startLength)} .... ${embedLink.slice(-endLength)}`;
    };

  const getTableCols = (isDownload: boolean) => {
    const tableCols: Array<KeyValue<any>> = [];

    tableCols.push({
      key: "id",
      title: "ID",
    });
    tableCols.push({
      key: "shortEmbedLink",
      title: "Link",
      toValue: getShortEmbedLink(isDownload),
    });
    if (isDownload)
      tableCols.push({
        key: "sheet",
        title: "Sheet",
        toValue: getEmbedDownloadLinkSheetCell(),
      });
    tableCols.push({
      key: "action",
      title: "",
      toValue: getEmbedLinkActionCell(),
    });
    return tableCols;
  };

  const handleCreate = async () => {
    setIsCreating(true);
    try {
      const links = await createEmbedLink(
        identifier,
        isOnline ? kind : "csv",
        { selection },
        parseInt(sheetOption?.value ?? "0"),
        propertyId,
        isPropertyReport,
      );
      setEmbedLinks(links);
      setIsCreating(false);
    } catch (e) {
      onUnexpectedError(e);
      setIsCreating(false);
    }
  };

  const handleRestrict = async (id: number, restricted: boolean) => {
    try {
      await putEmbedLinkRestricted(id, restricted);
      updateEmbedLinks();
    } catch (e) {
      onUnexpectedError(e);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteEmbedLink(id);

      updateEmbedLinks();
    } catch (e) {
      onUnexpectedError(e);
    }
  };

  const updateEmbedLinks = useCallback(
    () =>
      getEmbedLinks(identifier, propertyId)
        .then((links) => setEmbedLinks(links))
        .catch(onUnexpectedError),
    [identifier, onUnexpectedError, propertyId, setEmbedLinks],
  );

  useEffect(() => {
    if (dropdownOpen && identifier) updateEmbedLinks().then(() => {});
  }, [dropdownOpen, identifier, linkType, updateEmbedLinks]);

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle
        id="embed-link"
        className="jh-action-icon dropdown-toggle"
      >
        <FontAwesomeIcon icon={faCode} />
      </DropdownToggle>
      {!dropdownOpen ? (
        <UncontrolledPopover
          placement="bottom"
          trigger="hover"
          target="embed-link"
        >
          <PopoverBody>{`Embed ${kind}`}</PopoverBody>
        </UncontrolledPopover>
      ) : null}
      <DropdownMenu style={{ width: "28rem" }}>
        <div className="px-3">
          <div className="mb-3">
            <strong>{`Embed ${kind}`}</strong>
          </div>
          {kind === "report" && (
            <div className="mt-3">
              <ButtonGroup className="w-100">
                <Button
                  color="primary"
                  outline={linkType !== "Online"}
                  onClick={() => setLinkType("Online")}
                  className="w-50"
                >
                  Online
                </Button>
                <Button
                  color="primary"
                  outline={linkType !== "Download"}
                  onClick={() => setLinkType("Download")}
                  className="w-50"
                >
                  Embedded CSV
                </Button>
              </ButtonGroup>
            </div>
          )}
          <div className="jh-table-wrapper td-middle mt-2">
            <TableWithoutSelection<EmbedLinkItem>
              columns={getTableCols(!isOnline)}
              rows={embedLinks
                .filter((link) =>
                  isOnline ? link.kind !== "csv" : link.kind === "csv",
                )
                .map((link) => {
                  const embedLink = isOnline
                    ? `${uiUrl}/embed/${link.uuid}`
                    : `${apiUrl(`/embed/csv/${link.uuid}`)}`;

                  return {
                    id: link.id,
                    embedLink,
                    restricted: link.restricted,
                    ...(isOnline
                      ? undefined
                      : { sheet: link.configuration?.sheet ?? "" }),
                  };
                })}
              sortColumn="id"
            />
          </div>
          {!isOnline && (
            <>
              <div
                className="mt-4"
                style={{
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                Please select the sheet to embed
              </div>
              <JhSelect
                className="mt-1"
                value={sheetOption}
                options={sheetOptions}
                onValueUpdate={handleSheetChange}
                isMulti={false}
                pointy
                placeholder="Select sheet name"
              />
            </>
          )}
          <div className="mt-3 w-100">
            <Button
              color="primary"
              className="w-100 mb-2 position-relative"
              onClick={handleCreate}
              disabled={isCreating}
              style={isCreating ? { cursor: "wait" } : {}}
            >
              Create a new link
              {isCreating ? (
                <Spinner
                  color="light"
                  size="sm"
                  style={{
                    position: "absolute",
                    right: ".625rem",
                    top: ".625rem",
                  }}
                />
              ) : null}
            </Button>
          </div>
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default withAlertModal(EmbedLink);
