import {
  ColumnFormat,
  ComparisonValue,
  RowType,
  cellStyle,
  formatCellValue,
} from "@joyhub-integration/shared";
import { max } from "lodash";

import Meter from "../visualizations/Meter";

const FractionCell = ({
  value,
  format,
  rowType,
}: {
  value: ComparisonValue;
  format?: ColumnFormat;
  rowType: RowType;
}) => {
  const actual = value.value ?? 0;
  const target = value.versus ?? 0;

  return (
    <Meter
      low={0}
      high={max([actual, target]) ?? 0}
      actual={actual}
      target={target}
      formatter={(value) => formatCellValue(value, format?.numFmtValue)}
      style={cellStyle(value, format?.cellFormats ?? [], rowType)}
      small
    />
  );
};

export default FractionCell;
