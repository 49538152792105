import "./session.css";

import React, { useEffect } from "react";
import { Button } from "reactstrap";

import { ReactComponent as Logo } from "../../images/logo-h.svg";
import { setSessionOrganization } from "../../services/sessionOrganization";
import { loginUrl, setSessionReturnUrl } from "../../services/sessionService";

// uses document.location.replace to avoid the "login" page being in your history.

const Login: React.FC<{ loggedOut?: boolean }> = ({ loggedOut }) => {
  /**
   * Regardless of how it happened, any logged out user will always end up at the login page,
   * making it a logical place to make sure we are logged out of Zendesk
   *
   * We also put it inside an effect to make sure it only runs once, on initial load
   */
  useEffect(() => {
    // if you log with zendesk still open, it will prompt you with more silly forms. close it first
    window.zE?.("messenger", "close");
    window.zE?.("messenger", "logoutUser");
  }, []);

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { pathname, search } = document.location;
    setSessionOrganization(null);
    setSessionReturnUrl(`${pathname}${search}`);
    document.location.replace(loginUrl());
  };
  return (
    <a className="session-container" href={loginUrl()} onClick={onClick}>
      <div className="session-messages">
        <Logo id="session-logo" title="Revolution RE" />
        <div className="session-stuff">
          {loggedOut && (
            <div className="session-message">
              You have successfully logged out.
            </div>
          )}
          <Button color="primary" className="p-2 w-50 login-button">
            {loggedOut ? "Log in again" : "Log in"}
          </Button>
        </div>
      </div>
    </a>
  );
};

export default Login;
