import { PureDate } from "@joyhub-integration/shared";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Modal, ModalHeader } from "reactstrap";
import {
  getInstantData,
  getRangeDataByIds,
  InstantInsights,
  RangedInsights,
} from "../../services/dataService";
import {
  AllUnitCount,
  AvailableUnitCount,
  AverageDaysVacant,
  AverageNotReadyDuration,
  BudgetedTotalOperatingExpenses,
  BudgetedTotalOperatingRevenue,
  EffectiveRentNewSignsLast30Days,
  EffectiveRentPerSqftNewSignsLast30Days,
  EffectiveRentPerSqftRenewalsLast30Days,
  EffectiveRentRenewalsLast30Days,
  Leased,
  LeasedUnitCount,
  LeaseExpiryCount30Days,
  LeaseExpiryCount60Days,
  LeaseExpiryCount90Days,
  LeaseNoticeCount30Days,
  LeaseNoticeCount60Days,
  LeaseNoticeCount90Days,
  LeasePendingCount30Days,
  LeasePendingCount60Days,
  LeasePendingCount90Days,
  LeaseRenewalCount30Days,
  LeaseRenewalCount60Days,
  LeaseRenewalCount90Days,
  MoveIns,
  MoveOuts,
  NewSignsLast30Days,
  NoticeUnrentedUnitCount,
  Occupancy as OccupancyId,
  OccupiedUnitCount,
  PercentTradeOutNewSignsLast30Days,
  PercentTradeOutRenewalsLast30Days,
  RenewalRateId,
  RenewalsLast30Days,
  SiteVisitCount,
  TermsNewSignsLast30Days,
  TermsRenewalsLast30Days,
  TotalInPlaceRent,
  TotalMarketRent,
  TotalOperatingExpenses,
  TotalOperatingRevenue,
  VacantUnrentedUnitCount,
} from "../../services/insightLibrary/backendInsightIds";
import { Property } from "../../services/propertiesService";
import { toShortMonthDayAndYear } from "../../utils/date";
import PlatformContext from "../app/PlatformContext";
import { useOnUnexpectedError } from "../common/alert/withAlertModal";
import Availability from "./cards/Availability";
import DaysVacant from "./cards/DaysVacant";
import Expenses from "./cards/Expenses";
import Exposure from "./cards/Exposure";
import MakeReady from "./cards/MakeReady";
import NewTradeOut from "./cards/NewTradeOut";
import Occupancy from "./cards/Occupancy";
import PropertySnapshot from "./cards/PropertySnapshot";
import RenewalRate from "./cards/RenewalRate";
import RenewalTradeOut from "./cards/RenewalTradeOut";
import Revenue from "./cards/Revenue";
import TradeOutDetail from "./cards/TradeOutDetail";
import DrillIn from "./DrillIn";

// AverageDaysVacant is expected to be last 30 days..
// AverageNotReadyDuration ditto
// RenewalRate ditto (it based on LeaseRenewal and LeaseExpiry)

type CommunityDirectorDashboardParams = {
  propertyId: string;
};

const CommunityDirectorDashboard = () => {
  const { propertyId } = useParams<CommunityDirectorDashboardParams>();
  const [drillIn, setDrillIn] = useState<{
    name: string;
    id: string;
    error?: string;
  }>();
  const [instantInsights, setInstantInsights] = useState<InstantInsights>();
  const [rangedInsights, setRangedInsights] = useState<RangedInsights>();
  const { platform } = useContext(PlatformContext);
  const onUnexpectedError = useOnUnexpectedError();

  const property = useMemo<Property | undefined>(
    () => platform?.propertiesMap[propertyId!],
    [platform, propertyId],
  );

  useEffect(() => {
    if (property != null)
      getInstantData(
        [
          AllUnitCount,
          OccupiedUnitCount,
          OccupancyId,
          LeasedUnitCount,
          Leased,
          MoveIns,
          MoveOuts,
          SiteVisitCount,
          AvailableUnitCount,
          NewSignsLast30Days,
          EffectiveRentNewSignsLast30Days,
          EffectiveRentPerSqftNewSignsLast30Days,
          PercentTradeOutNewSignsLast30Days,
          TermsNewSignsLast30Days,
          RenewalsLast30Days,
          EffectiveRentRenewalsLast30Days,
          EffectiveRentPerSqftRenewalsLast30Days,
          PercentTradeOutRenewalsLast30Days,
          TermsRenewalsLast30Days,
          AverageDaysVacant,
          AverageNotReadyDuration,
          RenewalRateId,
          LeaseExpiryCount30Days,
          LeaseRenewalCount30Days,
          LeasePendingCount30Days,
          LeaseNoticeCount30Days,
          LeaseExpiryCount60Days,
          LeaseRenewalCount60Days,
          LeasePendingCount60Days,
          LeaseNoticeCount60Days,
          LeaseExpiryCount90Days,
          LeaseRenewalCount90Days,
          LeasePendingCount90Days,
          LeaseNoticeCount90Days,
          VacantUnrentedUnitCount,
          NoticeUnrentedUnitCount,
          TotalOperatingExpenses,
          TotalOperatingRevenue,
          BudgetedTotalOperatingExpenses,
          BudgetedTotalOperatingRevenue,
        ],
        property.id,
      )
        .then((insights) => setInstantInsights(insights))
        .catch(onUnexpectedError);
  }, [property, onUnexpectedError]);

  useEffect(() => {
    if (property != null && instantInsights != null) {
      const to = new PureDate(instantInsights.date);
      const from = new PureDate(to);
      from.setDate(1);
      from.setMonth(from.getMonth() - 16); // -17 months to now
      from.setDate(0);
      getRangeDataByIds(
        [
          AllUnitCount,
          OccupiedUnitCount,
          OccupancyId,
          MoveIns,
          MoveOuts,
          SiteVisitCount,
          AvailableUnitCount,
          TotalInPlaceRent,
          TotalMarketRent,
        ],
        from,
        to,
        property.id,
      )
        .then(setRangedInsights)
        .catch(onUnexpectedError);
    }
  }, [property, instantInsights, onUnexpectedError]);

  const date = useMemo(
    () => new PureDate(instantInsights?.date),
    [instantInsights],
  );

  return (
    <>
      <div className="px-4 pt-3">
        <div className="d-sm-flex justify-content-start mb-md-2 align-items-baseline">
          <h2 className="d-inline d-md-block mb-0">
            {property?.property_name}
          </h2>
          {!instantInsights?.date ? null : (
            <span className="text-muted ms-2">
              as of {toShortMonthDayAndYear(date)}
            </span>
          )}
        </div>
        <PropertySnapshot property={property} insights={instantInsights} />
        <Occupancy
          insights={rangedInsights}
          date={date}
          onClick={() =>
            setDrillIn({ name: "Occupancy", id: "occupancyDrillIn" })
          }
        />

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(20em, 1fr))",
            columnGap: "1em",
          }}
        >
          <RenewalRate
            insights={instantInsights}
            onClick={() =>
              setDrillIn({ name: "Renewal Rate", id: "renewalRateDrillIn" })
            }
          />
          <NewTradeOut
            insights={instantInsights}
            onClick={() =>
              setDrillIn({ name: "Trade Out (New)", id: "newTradeOutDrillIn" })
            }
          />
          <RenewalTradeOut
            insights={instantInsights}
            onClick={() =>
              setDrillIn({
                name: "Trade Out (Renewal)",
                id: "renewalTradeOutDrillIn",
              })
            }
          />
          <DaysVacant
            insights={instantInsights}
            onClick={() =>
              setDrillIn({ name: "Days Vacant", id: "daysVacantDrillIn" })
            }
          />
          <MakeReady
            insights={instantInsights}
            onClick={() =>
              setDrillIn({ name: "Make Ready", id: "makeReadyDrillIn" })
            }
          />
          <Revenue
            insights={instantInsights}
            onClick={() =>
              setDrillIn({ name: "Revenue", id: "revenueDrillIn" })
            }
          />
          <Expenses
            insights={instantInsights}
            onClick={() =>
              setDrillIn({ name: "Expenses", id: "expensesDrillIn" })
            }
          />
        </div>
        <Availability
          insights={rangedInsights}
          date={date}
          onClick={() =>
            setDrillIn({ name: "Availability", id: "availabilityDrillin" })
          }
        />
        <TradeOutDetail
          insights={instantInsights}
          onClick={() =>
            setDrillIn({ name: "Trade Out", id: "tradeOutDrillin" })
          }
        />
        <Exposure
          insights={instantInsights}
          onClick={() =>
            setDrillIn({ name: "Exposure", id: "exposureDrillIn" })
          }
        />
      </div>
      <Modal
        isOpen={drillIn !== undefined}
        size="xl"
        toggle={() => setDrillIn(undefined)}
      >
        <ModalHeader className="mb-0" toggle={() => setDrillIn(undefined)}>
          {drillIn?.name} Detail
        </ModalHeader>
        {drillIn && property && (
          <DrillIn propertyId={property.id} reportId={drillIn.id} />
        )}
      </Modal>
    </>
  );
};

export default CommunityDirectorDashboard;
