import {
  InsightDto,
  InsightEntity,
  InsightIdentifierRE,
  ManualInsightEntityDefinition,
  PartialRecord,
  Unit,
} from "@joyhub-integration/shared";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";

import { unexpectedError } from "../../../constants";
import {
  postCustomInsight,
  putCustomInsight,
} from "../../../services/insightsService";
import { equalsIgnoreCase } from "../../../utils/string";
import PlatformContext from "../../app/PlatformContext";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

type InsightValidation = PartialRecord<keyof InsightDto, string | undefined>;

type AddEditInsightModalProps = {
  insights?: Array<InsightEntity>;
  insight?: InsightEntity;
  onClose: () => void;
  onSubmit: (insight: InsightEntity) => void;
};

const frac = (s: String): ManualInsightEntityDefinition["unit"] => {
  const index = s.indexOf("/");
  return index < 0
    ? (s as Unit)
    : {
        count: s.substring(0, index) as Unit,
        total: s.substring(1 + index) as Unit,
      };
};

const defrac = (u: ManualInsightEntityDefinition["unit"]): string =>
  typeof u === "string" ? u : u.count + "/" + u.total;

const AddEditManualInsightModal: React.FC<AddEditInsightModalProps> = ({
  insights,
  insight,
  onClose,
  onSubmit,
}) => {
  const { superAdmin, organization, overlordDomain } =
    useContext(PlatformContext).platform!;
  const canShare = superAdmin && overlordDomain;
  const [serverError, setServerError] = useState<string>();
  const [validation, setValidation] = useState<InsightValidation>({});
  const [identifier, setIdentifier] = useState<string>(
    insight?.identifier ?? "",
  );
  const [name, setName] = useState<string>(insight?.name ?? "");
  const [description, setDescription] = useState<string>(
    insight?.description ?? "",
  );
  const [shared, setShared] = useState<boolean>(
    (canShare && insight?.shared) || false,
  );
  const [definition, setDefinition] = useState<ManualInsightEntityDefinition>(
    (insight?.definition as ManualInsightEntityDefinition) ?? {
      unit: "Number",
      grain: "Month",
    },
  );

  const validateForm = () => {
    const validity: InsightValidation = {};
    if (!identifier) {
      validity.identifier = "Field is required.";
    } else if (!identifier.match(InsightIdentifierRE)) {
      validity.identifier = "This is not a valid identifier.";
    } else if (
      insights?.find(
        (i) =>
          i.id !== insight?.id && equalsIgnoreCase(i.identifier, identifier),
      )
    ) {
      validity.identifier = "This identifier is already in use.";
    }
    if (!name) {
      validity.name = "Field is required.";
    }
    setValidation(validity);
    return Object.keys(validity).length === 0;
  };

  useEffect(
    () => setValidation({}),
    [identifier, name, description, shared, definition],
  );

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setServerError(undefined);
    if (validateForm()) {
      const dto: InsightDto = {
        identifier,
        name,
        description,
        definition,
        manual: true,
        shared,
      };
      const promise =
        insight == null || insight.organization_id !== organization.id
          ? postCustomInsight(dto)
          : putCustomInsight(insight.id, dto);
      promise
        .then((insight) => {
          onSubmit(insight);
        })
        .catch((err) => {
          const response = err.response;
          setServerError(
            response?.status === 400 && response.data.message
              ? response.data.message
              : unexpectedError,
          );
        });
    }
  };

  return (
    <UncontrolledModal onClosed={onClose} onFormSubmit={onFormSubmit}>
      <ModalHeader>
        {insight ? `Edit ${insight.name}` : "Add Manual Insight"}
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup row>
          <Label sm={2}>Identifier</Label>
          <Col sm={10}>
            <Input
              type="text"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              invalid={!!validation.identifier}
            />
            {validation.identifier && (
              <FormFeedback>{validation.identifier}</FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Name</Label>
          <Col sm={10}>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              invalid={!!validation.name}
            />
            {validation.name && <FormFeedback>{validation.name}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Description</Label>
          <Col sm={10}>
            <Input
              type="textarea"
              height={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              invalid={!!validation.description}
            />
            {validation.description && (
              <FormFeedback>{validation.description}</FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Value</Label>
          <Col sm={10}>
            <Input
              type="select"
              className="custom-select"
              value={defrac(definition.unit)}
              onChange={(e) =>
                setDefinition({ ...definition, unit: frac(e.target.value) })
              }
            >
              <option value="Number">Number</option>
              <option value="Dollar">Dollars</option>
              <option value="Dollar-Dollar">Dollars Difference</option>
              <option value="Day">Days</option>
              <option value="Month">Months</option>
              <option value="Sqft">Square Feet</option>
              <option value="Number/Number">Average Number</option>
              <option value="Dollar/Number">Average Dollars</option>
              <option value="Dollar/Sqft">Dollars per Square Foot</option>
              <option value="Dollar-Dollar/Number">
                Average Dollars Difference
              </option>
              <option value="Dollar-Dollar/Dollar">
                Relative Dollars Difference
              </option>
              <option value="Day/Number">Average Days</option>
              <option value="Month/Number">Average Months</option>
              <option value="Sqft/Number">Average Square Feet</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Dimension</Label>
          <Col sm={10}>
            <Input
              type="select"
              className="custom-select"
              value={definition.dimension ?? ""}
              onChange={(e) =>
                setDefinition({
                  ...definition,
                  dimension: (e.target.value as any) || undefined,
                })
              }
            >
              <option value="">None</option>
              <option value="bedrooms">By Bedrooms</option>
              <option value="unitType">By Unit Type</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Grain</Label>
          <Col sm={10}>
            <Input
              type="select"
              className="custom-select"
              value={definition.grain}
              onChange={(e) =>
                setDefinition({ ...definition, grain: e.target.value as any })
              }
            >
              <option value="Day">Daily</option>
              <option value="Month">Monthly</option>
            </Input>
          </Col>
        </FormGroup>
        {canShare ? (
          <FormGroup row>
            <Col sm={{ offset: 2, size: 10 }}>
              <div className="form-check">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={shared}
                    onChange={() => setShared(!shared)}
                  />
                  Shared with all organizations
                </Label>
              </div>
            </Col>
          </FormGroup>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary" className="ms-2">
          Save
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};
export default AddEditManualInsightModal;
