import JSON5 from "json5";
import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../../constants";
import { Integration } from "../../../services/integrationsService";
import { runLambda } from "../../../services/overlordService";
import { useSetAlert } from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import "./computeIntegrationModal.css";

interface SyncIntegrationModalProps {
  integration: Integration;
  onClose: () => void;
}

//Runs Scala Lambdas
//TODO: Could run other lambdas too, but right now this was needed to run Scala ones, which are run differently
const RunLambdaModal: React.FC<SyncIntegrationModalProps> = ({
  integration,
  onClose,
}) => {
  function formatJson(json: string): string {
    try {
      return JSON5.stringify(JSON5.parse(json), undefined, 2);
    } catch (e) {
      return json;
    }
  }

  const defaultDebugPath = "ingest/debug";
  const defaultDebugContent = formatJson(`{ systemId: ${integration.id} }`);

  const [serverError, setServerError] = useState<string>("");
  const [path, setPath] = useState(defaultDebugPath);
  const [content, setContent] = useState(defaultDebugContent);
  const [submitting, setSubmitting] = useState(false);
  const setAlert = useSetAlert();

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setSubmitting(true);
    runLambda(integration.id, { path, content: JSON5.parse(content) })
      .then((status) => {
        if (status !== 204) throw Error("Lambda run failed");
        setAlert("Running lambda.", true);
        onClose();
      })
      .catch((err) => {
        setSubmitting(false);
        const response = err.response;
        if (response && response.status === 400 && response.data.message) {
          setServerError(response.data.message);
        } else {
          setServerError(unexpectedError);
        }
      });
  }

  return (
    <UncontrolledModal size="lg" onClosed={onClose} onFormSubmit={onSubmit}>
      <ModalHeader toggle={onClose}>
        <div
          className="flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          Run Scala Lambda
        </div>
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup>
          <Label>Path:</Label>
          <Label style={{ float: "right", fontStyle: "italic" }}>
            ingest/debug, ingest/resman/property, etc
          </Label>
          <Input
            className="placeholder-right"
            type="text"
            value={path}
            onChange={(e) => setPath(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content:</Label>
          <Label style={{ float: "right", fontStyle: "italic" }}>
            i.e. DebugMsg = {"{ systemId: 123 }"}
          </Label>
          <br />
          <Label style={{ float: "right", fontStyle: "italic" }}>
            IngestFileMsg = {"{ fileId: 123, historyId: 456 }"}
          </Label>
          <Input
            className="placeholder-right"
            type="textarea"
            value={content}
            height={6}
            style={{
              fontFamily: "monospace",
              height: "120px",
              fontSize: "14px",
            }}
            onChange={(e) => setContent(e.target.value)}
            onBlur={(e) => setContent(formatJson(e.target.value))}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary" disabled={submitting}>
          Submit
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default RunLambdaModal;
