import { Config } from "vega-lite";

const labelFontSize = 14;

const vegaConfig: Config = {
  autosize: {
    type: "fit",
    contains: "padding",
    resize: true,
  },

  mark: { tooltip: true },

  line: {
    strokeWidth: 5,
  },

  legend: {
    columns: { expr: "floor(width / 300)" },
    labelFontSize,
    labelLimit: 300,
    title: null,
    orient: "bottom",
    // @ts-ignore
    layout: {
      bottom: {
        anchor: "middle",
      },
    },
  },

  view: {
    stroke: "transparent",
  },

  axis: {
    labelFont: "Open Sans",
    labelFontSize,
    labelPadding: 15,
    gridColor: "#f5f5f5",
    domainColor: "#ccc",
    ticks: false,
  },
};

export default vegaConfig;
