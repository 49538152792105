import { CustomColumn } from "@joyhub-integration/shared";
import React from "react";

import EditColumnListItem from "./EditColumnListItem";

type ListItemProps = {
  column: CustomColumn;
  originalDisplayTitle: string;
  defaultDisplayTitle: string;
  index: number;
  handleEdit: (index: number, c: CustomColumn) => void;
  handleMove: (to: number, from: number) => void;
  setEditing: (index: number | null) => void;
  editingIndex: number | null;
};

const FixedColumnListItem: React.FC<ListItemProps> = ({
  column,
  originalDisplayTitle,
  defaultDisplayTitle,
  index,
  handleEdit,
  handleMove,
  setEditing,
  editingIndex,
}) => {
  const columnName =
    (column.name ?? "") !== "" ? column.name : defaultDisplayTitle;
  const autoCompleteOptionsArr = [originalDisplayTitle];
  if (defaultDisplayTitle !== "") {
    autoCompleteOptionsArr.push(defaultDisplayTitle);
  }
  const autoCompleteOptions = Array.from(new Set(autoCompleteOptionsArr))?.map(
    (v) => ({
      label: v,
      value: v,
    }),
  );

  return (
    <EditColumnListItem
      column={column}
      dataType={column.dataType}
      columnKey={column.columnKey}
      columnName={columnName}
      index={index}
      handleEdit={handleEdit}
      handleMove={handleMove}
      handleRemove={() => {}}
      setEditing={setEditing}
      editingIndex={editingIndex}
      textDisabled={true}
      numberDisabled={true}
      percentageDisabled={true}
      dateDisabled={true}
      yearDisabled={true}
      dollarDisabled={true}
      handleDataTypeSelected={() => {}}
      autoCompleteOptions={autoCompleteOptions}
    ></EditColumnListItem>
  );
};

export default FixedColumnListItem;
