import React, { useState } from "react";
import { Form, Modal, ModalProps } from "reactstrap";

import { useOnUnexpectedError } from "../alert/withAlertModal";

interface UncontrolledModalProps extends ModalProps {
  onFormSubmit?: (e: React.FormEvent<HTMLFormElement>) => void | Promise<any>;
}

const UncontrolledModal: React.FC<UncontrolledModalProps> = (props) => {
  const onUnexpectedError = useOnUnexpectedError();
  const { onFormSubmit, children, ...modalProps } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const toggle = () => setModalOpen(!modalOpen);
  return (
    <Modal
      {...modalProps}
      isOpen={modalOpen}
      toggle={toggle}
      size={props.size ?? "lg"}
      backdrop="static"
    >
      {onFormSubmit ? (
        <Form
          className="d-flex flex-column"
          style={{ minHeight: 0 }}
          onSubmit={(e) => {
            e.preventDefault();
            const promise = onFormSubmit(e);
            if (
              promise != null &&
              typeof (promise as any)["catch"] === "function"
            ) {
              promise.catch(onUnexpectedError);
            }
          }}
          autoComplete="off"
        >
          {children}
        </Form>
      ) : (
        children
      )}
    </Modal>
  );
};

export default UncontrolledModal;
