import React from "react";
import { Input } from "reactstrap";

interface ThirtySixtyNinetyDayFilterProps {
  monthsSelected: string[];
  monthOptions: string[];
  onMonthSelectedChange: (months: string[]) => void;
}

const ThirtySixtyNinetyDayFilter: React.FC<ThirtySixtyNinetyDayFilterProps> = ({
  monthsSelected,
  monthOptions,
  onMonthSelectedChange,
}) => {
  const options = [
    {
      display: "1 year",
      value: "",
    },
    {
      display: "30 days",
      value: monthOptions[monthOptions.length - 1],
    },
    {
      display: "60 days",
      value: monthOptions.slice(monthOptions.length - 2).join(","),
    },
    {
      display: "90 days",
      value: monthOptions.slice(monthOptions.length - 3).join(","),
    },
  ];
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    onMonthSelectedChange(e.target.value.split(","));
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "0.5rem",
        zIndex: 10,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Input
        type="select"
        className="custom-select"
        value={monthsSelected.join(",")}
        style={{ width: "fit-content" }}
        onChange={onChange}
        onClick={(e) => e.preventDefault()}
      >
        {options.map(({ value, display }) => (
          <option key={value} value={value}>
            {display}
          </option>
        ))}
      </Input>
    </div>
  );
};

export default ThirtySixtyNinetyDayFilter;
