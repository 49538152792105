import { UnitMetadata } from "../../services/unitMap/unitMetadata";
import { formattedDollar } from "../../utils/number";

const UnitPopover = ({ unitDatum }: { unitDatum?: UnitMetadata }) => {
  return (
    <div style={{ background: "#fff" }} className="p-3">
      {unitDatum ? (
        <dl>
          <dt>Status</dt>
          <dd>{unitDatum.status}</dd>

          <dt>Open work orders</dt>
          <dd>{unitDatum.openWorkOrders}</dd>

          {unitDatum.currentTenant ? (
            <>
              <dt>Current tenant</dt>
              <dd>{unitDatum.currentTenant}</dd>

              <dt>Rent</dt>
              <dd>
                {unitDatum.rent ? formattedDollar(unitDatum.rent) : "N/A"}
              </dd>

              <dt>Renewal</dt>
              <dd>{unitDatum.renewal ?? "N/A"}</dd>

              <dt>Current balance</dt>
              <dd>
                {unitDatum.currentBalance
                  ? formattedDollar(unitDatum.currentBalance)
                  : "N/A"}
              </dd>
            </>
          ) : (
            <>
              <dt>Future tenant / applicant</dt>
              <dd>{unitDatum.futureTenantOrApplicant ?? "N/A"}</dd>

              <dt>Move in</dt>
              <dd>{unitDatum.moveIn ?? "N/A"}</dd>
            </>
          )}

          <dt>Market rent</dt>
          <dd>{formattedDollar(unitDatum.marketRent)}</dd>

          <dt>Square feet</dt>
          <dd>{unitDatum.sqft ?? "N/A"}</dd>

          <dt>Floorplan</dt>
          <dd>{unitDatum.floorplan}</dd>

          <dt>Bedrooms</dt>
          <dd>{unitDatum.bedrooms}</dd>

          <dt>Bathrooms</dt>
          <dd>{unitDatum.bathrooms}</dd>

          <dt>Average turnover rate</dt>
          <dd>{unitDatum.turnover}</dd>
        </dl>
      ) : (
        "No unit data available"
      )}
    </div>
  );
};

export default UnitPopover;
