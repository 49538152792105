import {
  DashboardKindEnum,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import { useContext, useEffect, useState } from "react";
import { useDrag } from "react-dnd";

import { Button } from "reactstrap";
import { useBreakpoint } from "../../../../../hooks/useBreakpoint";
import {
  DashboardBoardRestPropsType,
  Dashboard as DashboardType,
} from "../../../../../services/dashboardService";
import { RangedInsights } from "../../../../../services/dataService";
import { insightComponents } from "../../../../../services/insightLibrary/insightComponents";
import insightsLibraryService from "../../../../../services/insightLibrary/insightsLibraryService";
import { OtherDashboardInsight } from "../../../../../services/models";
import PlatformContext from "../../../../app/PlatformContext";
import ChartWrapper from "../../../../common/charts/ChartWrapper";
import JhFunnelChart from "../../../../common/charts/FunnelChart";
import NumberChart from "../../../../common/charts/NumberChart";
import SequenceChart from "../../../../common/charts/SequenceChart";
import InstantTableChart from "../../../tableChart/InstantTableChart";
import RangeTableChart from "../../../tableChart/RangeTableChart";
import {
  InstantPieChart,
  SimpleJhBarChart,
  SimpleJhColumnChart,
  SimpleLineChart,
  SimplePieChart,
  SimpleScatterChart,
  Skeleton,
} from "./SimpleCharts";

import {
  faArrowLeft,
  faArrowRight,
  faMinus,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

interface RowItemPropsType {
  data: Record<string, any>;
  last: boolean;
  dashboard: DashboardType;
  selection: PropertiesSelection;
  dashboardKind:
    | DashboardKindEnum.Dashboard
    | DashboardKindEnum.PropertyDashboard;
  onUpdateLayout: (
    itemRowIndex: number,
    itemNo: number,
    update: number | "left" | "right",
  ) => void;
  onUpdateWidth: (itemRowIndex: number, itemNo: number, width: number) => void;
  setDashboardInsightToEdit: (di: OtherDashboardInsight) => void;
  setDashboardInsightToRemove: (di: OtherDashboardInsight) => void;
}

interface DropResult {
  rowIndex: number;
}

export const RowItem = (
  props: RowItemPropsType & DashboardBoardRestPropsType,
) => {
  const {
    smCharts,
    mdCharts,
    customComponentCharts,
    data,
    last,
    loaded,
    dashboard,
    selection,
    editMode,
    dashboardKind,
    rangeDataBreakout,
    rangeDataNoBreakout,
    rangeDataBreakoutAYearAgo,
    rangeDataNoBreakoutAYearAgo,
    instantDataNow,
    instantDataAYearAgo,
    onUpdateLayout,
    onUpdateWidth,
    setDashboardInsightToEdit,
    setDashboardInsightToRemove,
  } = props;

  const platform = useContext(PlatformContext).platform!;
  const { insightsMap, propertiesMap: propertiesHash } = platform;

  const [chartType, setChartType] = useState<"sm" | "md" | "custom">();
  const [chart, setChart] =
    useState<OtherDashboardInsight<Record<string, any>>>();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "card",
      item: data,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DropResult>();
        if (dropResult && item)
          onUpdateLayout(item.y, item.x, dropResult.rowIndex);
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [data, onUpdateLayout],
  );
  const breakpoint = useBreakpoint();

  useEffect(() => {
    const smInsights = smCharts.filter(
      (chart) => chart.id === parseInt(data.i),
    );
    if (smInsights.length === 1) {
      setChartType("sm");
      setChart(smInsights[0]);
    }

    const mdInsights = mdCharts.filter(
      (chart) => chart.id === parseInt(data.i),
    );
    if (mdInsights.length === 1) {
      setChartType("md");
      setChart(mdInsights[0]);
    }

    const customInsights = customComponentCharts.filter(
      (chart) => chart.id === parseInt(data.i),
    );
    if (customInsights.length === 1) {
      setChartType("custom");
      setChart(customInsights[0]);
    }
  }, [customComponentCharts, data, mdCharts, smCharts]);

  if (
    smCharts.length === 0 &&
    mdCharts.length === 0 &&
    customComponentCharts.length === 0
  )
    return null;

  if (!chartType || !chart) return <></>;

  // md insight
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === chart.insightId,
  );
  const rangeData = insight?.byPropertyCalculationType
    ? rangeDataBreakout
    : rangeDataNoBreakout;
  const rangeDataAYearAgo = insight?.byPropertyCalculationType
    ? rangeDataBreakoutAYearAgo
    : rangeDataNoBreakoutAYearAgo;
  const chartOrTableProps = {
    insightId: chart.insightId,
    dashboardInsightId: chart.id,
    rangeData: rangeData as RangedInsights,
    rangeDataAYearAgo: rangeDataAYearAgo as RangedInsights,
    insightsMap: insightsMap,
    propertiesHash: propertiesHash,
  };

  // custom insight
  const defaultProps = insight?.customComponent?.defaultProps || {};
  const CustomComponent = insightComponents[insight!.id];
  const heading = insight?.customComponent?.showHeading
    ? chart.name
    : undefined;
  const drillable = insight?.customComponent?.drillable;

  return (
    <div
      ref={editMode ? drag : undefined}
      style={{
        flexGrow: data.width ?? 1,
        flexBasis: 0,
        cursor: editMode ? "move" : "auto",
        opacity: isDragging ? 0.5 : 1,
        overflowX: breakpoint === "lg" ? "auto" : undefined,
      }}
      className="d-flex flex-column align-items-stretch"
    >
      {editMode && (
        <div className="d-flex justify-content-between px-2">
          <Button
            size="sm"
            color="primary"
            outline
            className="d-flex align-items-center border-0 p-1"
            disabled={data.x < 1}
            onClick={() => onUpdateLayout(data.y, data.x, "left")}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              color="primary"
              outline
              className="d-flex align-items-center border-0 p-1"
              disabled={data.width <= 1}
              onClick={() =>
                onUpdateWidth(data.y, data.x, Math.floor(data.width - 1))
              }
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <span className="mx-1 text-muted">{data.width}</span>
            <Button
              size="sm"
              color="primary"
              outline
              className="d-flex align-items-center border-0 p-1"
              onClick={() =>
                onUpdateWidth(data.y, data.x, Math.floor(data.width + 1))
              }
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Button
            size="sm"
            color="primary"
            outline
            className="d-flex align-items-center border-0 p-1"
            onClick={() => onUpdateLayout(data.y, data.x, "right")}
            disabled={last}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      )}
      <div className="flex-grow-1">
        {chartType === "sm" && (
          <ChartWrapper
            key={chart.id.toString()}
            dashboardId={dashboard.id}
            dashboardInsight={chart}
            heading={chart.name}
            onEditInsight={() => setDashboardInsightToEdit(chart)}
            onRemoveInsight={() => setDashboardInsightToRemove(chart)}
            editMode={editMode}
            showInfo={!editMode}
            drillable
            selection={selection}
            dashboardKind={dashboardKind}
          >
            {!instantDataNow || !instantDataAYearAgo || !loaded ? (
              <Skeleton />
            ) : chart.visualizationType === "TABLE" ? (
              <InstantTableChart
                insightId={chart.insightId}
                dashboardInsightId={chart.id}
                instantDataNow={instantDataNow}
                instantDataAYearAgo={instantDataAYearAgo}
              />
            ) : chart.visualizationType === "SEQUENCE" ? (
              <SequenceChart
                insightId={chart.insightId}
                instantValuesNow={instantDataNow}
                instantValuesAYearAgo={instantDataAYearAgo}
                insightsMap={insightsMap}
              />
            ) : chart.visualizationType === "FUNNEL" ? (
              <JhFunnelChart
                insightId={chart.insightId}
                instantValuesNow={instantDataNow}
                instantValuesAYearAgo={instantDataAYearAgo}
                insightsMap={insightsMap}
              />
            ) : chart.visualizationType === "PIE" ? (
              <InstantPieChart
                insightId={chart.insightId}
                dashboardInsightId={0}
                instantValuesNow={instantDataNow}
                instantValuesAYearAgo={instantDataAYearAgo}
                insightsMap={insightsMap}
              />
            ) : (
              <NumberChart
                insightId={chart.insightId}
                vizType={chart.visualizationType}
                instantValuesNow={instantDataNow}
                instantValuesAYearAgo={instantDataAYearAgo}
                insightsMap={insightsMap}
              />
            )}
          </ChartWrapper>
        )}
        {chartType === "md" && (
          <ChartWrapper
            key={chart.id.toString()}
            dashboardId={dashboard.id}
            dashboardInsight={chart}
            heading={chart.name}
            onEditInsight={() => setDashboardInsightToEdit(chart)}
            onRemoveInsight={() => setDashboardInsightToRemove(chart)}
            drillable
            editMode={editMode}
            showInfo={!editMode}
            selection={selection}
            dashboardKind={dashboardKind}
          >
            {!loaded || !rangeData ? (
              <Skeleton />
            ) : chart.visualizationType === "COLUMN" ? (
              <SimpleJhColumnChart {...chartOrTableProps} />
            ) : chart.visualizationType === "BAR" ? (
              <SimpleJhBarChart {...chartOrTableProps} />
            ) : chart.visualizationType === "LINE" ? (
              <SimpleLineChart {...chartOrTableProps} />
            ) : chart.visualizationType === "SCATTER" ? (
              <SimpleScatterChart {...chartOrTableProps} />
            ) : chart.visualizationType === "PIE" ? (
              <SimplePieChart {...chartOrTableProps} />
            ) : chart.visualizationType === "TABLE" &&
              insight?.calculationType === "RANGE" ? (
              <RangeTableChart {...chartOrTableProps} />
            ) : null}
          </ChartWrapper>
        )}
        {chartType === "custom" && (
          <ChartWrapper
            key={chart.id.toString()}
            dashboardId={dashboard.id}
            dashboardInsight={chart}
            onEditInsight={() => setDashboardInsightToEdit(chart)}
            onRemoveInsight={() => setDashboardInsightToRemove(chart)}
            editMode={editMode}
            showInfo={!editMode}
            heading={heading}
            noContentPadding={insight?.customComponent?.noContentPadding}
            drillable={drillable}
            selection={selection}
            dashboardKind={dashboardKind}
          >
            <CustomComponent
              {...defaultProps}
              dashboardInsight={chart}
              selection={selection}
            />
          </ChartWrapper>
        )}
      </div>
    </div>
  );
};
