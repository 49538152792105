import {
  faLockAlt,
  faPencil,
  faPencilAlt,
} from "@fortawesome/pro-light-svg-icons";
import {
  CustomColumnType,
  PropertyClass,
  PropertyRegion,
  PropertyType,
} from "@joyhub-integration/shared";
import React, { useRef } from "react";
import { useClickAway } from "react-use";
import { Popover, PopoverBody } from "reactstrap";
import { PropertyDropDownType } from "../../../services/propertiesService";
import { formatCurrency } from "../../../utils/currency";
import { formatDateStringtoMDY } from "../../../utils/date";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import ButtonWithIcon, {
  ButtonWithIconProps,
} from "../../common/button/ButtonWithIcon";
import PropertyComparableCell from "../../properties/PropertyComparableCell";
import CellEditor from "./CellEditor";
import "./properties.scss";
import PropertyCheckboxCell from "./PropertyCheckboxCell";

const parseForEditor = (
  value: string,
  dataType: (typeof CustomColumnType)[number],
) =>
  dataType === "percentage" && value !== ""
    ? (parseFloat(value) * 100).toString()
    : value;

const saveFromEditor = (
  value: string | number,
  dataType: (typeof CustomColumnType)[number],
  setCellValue: (value: string) => void,
) =>
  dataType === "percentage" && typeof value === "string" && value !== ""
    ? setCellValue((parseFloat(value) / 100).toString())
    : setCellValue(value.toString());

export type PropertyCellProps = {
  id: string;
  columnKey: string;
  dataType: (typeof CustomColumnType)[number];
  isLocked: boolean;
  editMode: boolean;
  originalValue: string;
  cellValue: string;
  textColumnOptions: string[];
  onBeginEdit: () => void;
  onFinishEdit: () => void;
  onSendValue: (value: any) => void;
  onSavedValue: (old: any, value: any) => void;
  setEditMode: (value: boolean) => void;
  setCellValue: (value: string) => void;
};

export const getDropDownValue = (
  key: string,
  type: PropertyDropDownType,
): string => {
  return type[key as keyof PropertyDropDownType];
};

const PropertyCell: React.FC<WithAlertModalProps & PropertyCellProps> = ({
  id,
  columnKey,
  dataType,
  isLocked,
  editMode,
  originalValue,
  cellValue,
  textColumnOptions,
  onBeginEdit,
  onFinishEdit,
  onSendValue,
  setEditMode,
  setCellValue,
}) => {
  const lockButtonProps: ButtonWithIconProps = {
    id: id + "-lock",
    className: "property-icon-button",
    iconStyle: { width: 11, height: 11 },
    style: { cursor: "default" },
    icon: faLockAlt,
  };

  const peniclButtonProps: ButtonWithIconProps = {
    id: id + "-edit",
    className: "property-icon-button",
    iconStyle: { width: 11, height: 11 },
    iconSize: "sm",
    icon: faPencil,
    onClick: () => beginEditing(),
  };

  const addDataButtonProps: ButtonWithIconProps = {
    id: id + "-add",
    className: "property-cell-add-button",
    icon: faPencilAlt,
    iconStyle: { width: 15, height: 15 },
    onClick: () => beginEditing(),
  };

  const beginEditing = () => {
    if (isLocked) {
      return;
    }
    setEditMode(true);
    onBeginEdit();
  };

  const finishEditing = () => {
    setEditMode(false);
    onFinishEdit();
  };

  const sendValue = (value?: string) => {
    finishEditing();
    onSendValue(value ?? cellValue);
  };

  const ref = useRef(null);
  useClickAway(ref, (evt: KeyboardEvent) => {
    setEditMode(false);
    sendValue();
  });

  const showPopover = editMode && !columnKey.startsWith("user_bool");

  const dropDownType: PropertyDropDownType | null =
    columnKey === "region"
      ? PropertyRegion
      : columnKey === "property_class"
        ? PropertyClass
        : columnKey === "property_type"
          ? PropertyType
          : null;

  const displayValue = () => {
    switch (dataType) {
      case "number":
      case "text":
        return dropDownType
          ? getDropDownValue(cellValue, dropDownType)
          : cellValue;
      case "date":
        return formatDateStringtoMDY(cellValue);
      case "year":
      case "bool":
        return cellValue;
      case "bool":
        return cellValue;
      case "percentage":
        return (parseFloat(cellValue) * 100).toFixed(2) + "%";
      case "dollar":
        return formatCurrency(cellValue);
    }
  };

  const isCheckbox = columnKey.startsWith("user_bool");

  return (
    <>
      <div id={id} style={{ display: "inline" }}>
        {columnKey === "is_comparable" ? (
          <PropertyComparableCell is_comparable={cellValue} />
        ) : isCheckbox ? (
          <PropertyCheckboxCell
            cellValue={cellValue}
            onChange={(value: boolean) => {
              setCellValue(value.toString());
              onSendValue(value.toString());
            }}
          />
        ) : (
          <div
            onDoubleClick={() => beginEditing()}
            className={editMode ? "text-muted" : ""}
            style={{ display: "inline" }}
          >
            {cellValue ? displayValue() : editMode ? "-" : ""}
          </div>
        )}
        {!isLocked && cellValue === "" && !editMode && !isCheckbox ? (
          <ButtonWithIcon style={{ maxHeight: 30 }} {...addDataButtonProps} />
        ) : showPopover ? (
          <Popover
            placement="bottom"
            isOpen={true}
            target={id}
            delay={0}
            innerRef={ref}
          >
            <PopoverBody>
              <CellEditor
                colKey={columnKey}
                dataType={dataType}
                cellValue={parseForEditor(cellValue, dataType)}
                textColumnOptions={textColumnOptions}
                dropDownType={dropDownType}
                onChange={(value) =>
                  saveFromEditor(value, dataType, setCellValue)
                }
                originalValue={parseForEditor(originalValue, dataType)}
                sendValue={sendValue}
              />
            </PopoverBody>
          </Popover>
        ) : null}
        {isLocked ? (
          <ButtonWithIcon {...lockButtonProps} />
        ) : cellValue.trim() === "" || isCheckbox ? null : (
          <ButtonWithIcon {...peniclButtonProps} />
        )}
      </div>
    </>
  );
};

export default withAlertModal(PropertyCell);
