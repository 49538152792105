import {
  formatted,
  formattedPercentage,
  safeDivide,
  toPercentage,
} from "../../utils/number";
import {
  FilterByDimension,
  TableValues,
  calculateChartValues,
  calculateInstantNumber,
  calculateInstantPercentage,
  calculateTableValues,
} from "../calculateInsight";
import {
  InsightValues,
  PropertyInsights,
  RangedInsights,
} from "../dataService";
import insightDefinitions from "../insightLibrary/insightDefinitions";
import { KnownInsight as BackendInsight } from "../insightsService";
import {
  ChartValues,
  ComplexInsightIds,
  Insight,
  InsightVisualizationType,
  Operand,
  ScatterChartValues,
  ScatterData,
} from "../models";
import { Property } from "../propertiesService";

const colors = [
  "#2A459A",
  "#7F93D7",
  "#52B4D2",
  "#C39A2A",
  "#89A7D4",
  "#1A243C",
  "#0d44fa",
  "#233537",
  "#396E74",
  "#398956",
  "#4BBC8E",
  "#533974",
  "#9B477E",
  "#94639C",
  "#D55F77",
];

const backupColors = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#3B3EAC",
];

export interface SequenceValue {
  label: string;
  value: string;
}

export function calculateInstantSequenceValues(
  complexInsightIds: ComplexInsightIds,
  instantValues: InsightValues,
  instantValuesAYearAgo: InsightValues,
  insightsMap: { [id: string]: BackendInsight },
  hideLabel: boolean = false,
): SequenceValue {
  const {
    insightCalculationType,
    yoy,
    insightIds,
    delta,
    resultCol,
    operand,
    dimensionFilter,
  } = complexInsightIds;
  const vizType = insightCalculationType === "NUMBER" ? "NUMBER" : "PERCENTAGE";
  const value = calculateInstantInsightValue(
    insightIds as number[],
    vizType,
    instantValues,
    instantValuesAYearAgo,
    insightsMap,
    delta,
    yoy,
    operand,
    dimensionFilter,
  );
  return {
    value: value,
    label: hideLabel && complexInsightIds.hideLabel ? "" : resultCol,
  };
}

export interface PieValue {
  label: string;
  value: number;
}

export function calculateInstantPieValues(
  complexInsightIds: ComplexInsightIds,
  instantValues: InsightValues,
  instantValuesAYearAgo: InsightValues,
  insightsMap: { [id: string]: BackendInsight },
): PieValue {
  const {
    insightCalculationType,
    yoy,
    insightIds,
    delta,
    resultCol,
    operand,
    dimensionFilter,
  } = complexInsightIds;
  const vizType = insightCalculationType === "NUMBER" ? "NUMBER" : "PERCENTAGE";
  const value = calculateInstantInsightValue(
    insightIds as number[],
    vizType,
    instantValues,
    instantValuesAYearAgo,
    insightsMap,
    delta,
    yoy,
    operand,
    dimensionFilter,
  );
  const number = parseFloat(value.replace(/,/g, ""));
  return {
    value: isNaN(number) ? 0 : number,
    label: resultCol,
  };
}

export function calculateTableInstantInsightValuesByProperty(
  insightId: number,
  instantValues: PropertyInsights<InsightValues>,
  instantValuesAYearAgo: PropertyInsights<InsightValues>,
  insightsMap: { [id: string]: BackendInsight },
  propertiesHash: { [key: string]: Property },
): TableValues {
  const insight = insightDefinitions.find((i) => i.id === insightId);
  if (!insight) {
    return {
      rowHeaders: [],
      colHeaders: [],
      rows: [],
      dimensionOptions: [],
      dataUnavailable: true,
      monthOptions: [],
    };
  }
  const complexInsightIds = insight.insightIds as ComplexInsightIds[];
  const rows: { [key: string]: number | string }[] = [];
  const rowHeaders: string[] = [];
  const colHeaders = complexInsightIds.map((ci) => ci.resultCol);
  for (const propertyId of Object.keys(instantValues)) {
    const row: { [key: string]: number | string } = {};
    for (const {
      insightCalculationType,
      yoy,
      insightIds,
      delta,
      resultCol,
      operand,
      dimensionFilter,
    } of complexInsightIds) {
      const vizType =
        insightCalculationType === "NUMBER" ? "NUMBER" : "PERCENTAGE";
      row[resultCol] = calculateInstantInsightValue(
        insightIds as number[],
        vizType,
        instantValues[parseInt(propertyId, 10)],
        instantValuesAYearAgo[parseInt(propertyId, 10)],
        insightsMap,
        delta,
        yoy,
        operand,
        dimensionFilter,
      );
    }
    rows.push(row);
    const propertyCode = propertiesHash[propertyId].property_code;
    const propertyName = propertiesHash[propertyId].property_name;
    rowHeaders.push(`${propertyCode} - ${propertyName}`);
  }
  return {
    rowHeaders,
    colHeaders,
    rows,
    dimensionOptions: [],
    dataUnavailable: false,
    monthOptions: [],
  };
}

export function calculateInstantInsightValue(
  backendInsightIds: number[],
  vizType: "NUMBER" | "PERCENTAGE",
  instantValuesNow: InsightValues,
  instantValuesAYearAgo: InsightValues,
  insightsMap: { [id: string]: BackendInsight },
  delta?: boolean,
  yoy?: boolean,
  operand?: Operand,
  dimensionFilter?: Record<string, string>,
): string {
  if (!yoy && vizType === "PERCENTAGE") {
    const value = calculateInstantPercentage(
      backendInsightIds,
      instantValuesNow,
      dimensionFilter,
    );
    return toPercentage(value, delta);
  } else if (yoy && vizType === "PERCENTAGE") {
    const valueLastYear = calculateInstantPercentage(
      backendInsightIds,
      instantValuesAYearAgo,
    );
    const valueNow = calculateInstantPercentage(
      backendInsightIds,
      instantValuesNow,
    );
    const nowQuotient =
      safeDivide(valueNow.count, valueNow.total) ?? Number.NaN;
    const lastYearQuotient =
      safeDivide(valueLastYear.count, valueLastYear.total) ?? Number.NaN;
    const change = nowQuotient - lastYearQuotient;
    return formattedPercentage(change, true);
  } else if (!yoy && vizType === "NUMBER") {
    const res = calculateInstantNumber(
      backendInsightIds,
      instantValuesNow,
      insightsMap,
      operand,
      dimensionFilter,
    );
    return formatted(res.value, res.unit, delta);
  } else {
    const valueLastYear = calculateInstantNumber(
      backendInsightIds,
      instantValuesAYearAgo,
      insightsMap,
      operand,
      dimensionFilter,
    );
    const valueNow = calculateInstantNumber(
      backendInsightIds,
      instantValuesNow,
      insightsMap,
      operand,
      dimensionFilter,
    );
    const countOfTotal = {
      count: valueNow.value - valueLastYear.value,
      total: valueLastYear.value,
    };
    return toPercentage(countOfTotal, true);
  }
}

export function calculateRangeInsightValue(
  insightId: number,
  rangeData: RangedInsights,
  rangeDataAYearAgo: RangedInsights,
  insightsMap: { [id: string]: BackendInsight },
  propertiesHash: { [key: string]: Property },
  vizType: InsightVisualizationType,
  filterByDimension?: FilterByDimension,
  monthsSelected?: Set<string>,
  yoy?: boolean,
  drillIn?: boolean,
): TableValues | ChartValues {
  const insight = insightDefinitions.find((i) => i.id === insightId);
  if (insight && vizType === "TABLE") {
    const tableValues = calculateTableValues(
      insight,
      rangeData,
      rangeDataAYearAgo,
      insightsMap,
      propertiesHash,
      filterByDimension,
      monthsSelected,
      yoy,
      drillIn,
    );
    return tableValues;
  } else {
    const chartValues = calculateChartValues(
      insight as Insight,
      rangeData,
      rangeDataAYearAgo,
      insightsMap,
      propertiesHash,
      filterByDimension,
      monthsSelected,
      drillIn,
    );
    return chartValues;
  }
}

export function calculateRangeInsightValueByMonthAndYear(
  insightId: number,
  rangeData: RangedInsights,
  rangeDataAYearAgo: RangedInsights,
  insightsMap: { [id: string]: BackendInsight },
  propertiesHash: { [key: string]: Property },
  filterByDimension?: FilterByDimension,
  monthsSelected?: Set<string>,
  yoy?: boolean,
  drillIn?: boolean,
): TableValues {
  const insight = insightDefinitions.find((i) => i.id === insightId) as Insight;
  const insightAggregatedByProperty: Insight = insight.categoryInformation
    ? {
        ...insight,
        categoryInformation: {
          ...insight.categoryInformation,
          dimensionKey: "property",
        },
        byPropertyCalculationType: "SUM",
      }
    : {
        ...insight,
        byPropertyCalculationType: "SUM",
      };
  return calculateTableValues(
    insightAggregatedByProperty,
    rangeData,
    rangeDataAYearAgo,
    insightsMap,
    propertiesHash,
    filterByDimension,
    monthsSelected,
    yoy,
    drillIn,
  );
}

export function calculateRangeScatterValue(
  insightId: number,
  rangeData: RangedInsights,
  rangeDataAYearAgo: RangedInsights,
  insightsMap: { [id: string]: BackendInsight },
  propertiesHash: { [key: string]: Property },
  vizType: InsightVisualizationType,
  filterByDimension?: FilterByDimension,
  monthsSelected?: Set<string>,
): ScatterChartValues {
  const insight = insightDefinitions.find((i) => i.id === insightId);
  const values = calculateRangeInsightValue(
    insightId,
    rangeData,
    rangeDataAYearAgo,
    insightsMap,
    propertiesHash,
    vizType,
    filterByDimension,
    monthsSelected,
  );
  const chartValues = values as ChartValues;
  const data = chartValues.data.reduce(
    (prev, current) => {
      if (prev[current[chartValues.primaryAxisKey]]) {
        prev[current[chartValues.primaryAxisKey]].data.push(current);
      } else {
        prev[current[chartValues.primaryAxisKey]] = {
          data: [current],
          color: "",
        };
      }
      return prev;
    },
    {} as { [key: string]: ScatterData },
  );
  const chartColors =
    Object.keys(data).length > colors.length ? backupColors : colors;
  Object.keys(data).forEach((key, idx) => {
    data[key].color = chartColors[idx % chartColors.length];
  });
  const xAxisKey = Object.keys(chartValues.secondaryAxisKeys)[0];
  const xAxisKeyUnit = Object.values(chartValues.secondaryAxisKeys)[0].unit;
  const yAxisKey = Object.keys(chartValues.secondaryAxisKeys)[1];
  const yAxisKeyUnit = Object.values(chartValues.secondaryAxisKeys)[1].unit;
  return {
    data: data,
    dataUnavailable: chartValues.dataUnavailable,
    monthOptions: chartValues.monthOptions,
    xAxisKey: xAxisKey,
    xAxisKeyUnit: xAxisKeyUnit,
    yAxisKey: yAxisKey,
    yAxisKeyUnit: yAxisKeyUnit,
    zAxisKey: insight?.categoryInformation?.dimensionKey,
  };
}
