import {
  ColumnFormat,
  Comparison,
  ComparisonValue,
  ReportDataValue,
  formatCellValue,
  isComparisonValue,
} from "@joyhub-integration/shared";
import { UncontrolledTooltip } from "reactstrap";

const body = (
  value: ComparisonValue,
  comparison: Comparison,
  format?: ColumnFormat,
) => {
  switch (comparison) {
    case "Delta":
      return (
        <>
          <div>Value: {formatCellValue(value.value, format?.numFmtValue)}</div>
          <div>Change: {formatCellValue(value, format?.numFmt)}</div>
        </>
      );
    case "Difference":
      return (
        <>
          <div>Value: {formatCellValue(value.value, format?.numFmtValue)}</div>
          <div>Change: {formatCellValue(value, format?.numFmtValue)}</div>
        </>
      );
    case "Fraction":
      return (
        <>
          <div>Value: {formatCellValue(value.value, format?.numFmtValue)}</div>
          <div>Goal: {formatCellValue(value.versus, format?.numFmtValue)}</div>
        </>
      );
  }
};

const Tooltip = ({
  target,
  value,
  comparison,
  format,
}: {
  target: string;
  value: ReportDataValue;
  comparison?: Comparison;
  format?: ColumnFormat;
}) =>
  isComparisonValue(value) && comparison ? (
    <UncontrolledTooltip target={target}>
      {body(value, comparison, format)}
    </UncontrolledTooltip>
  ) : null;

export default Tooltip;
