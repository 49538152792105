export function handleIntInputKeyPress(
  e: React.KeyboardEvent<HTMLInputElement>,
) {
  let allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "Delete",
  ];
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
}
