export type PropertyCheckboxCellProps = {
  cellValue: string;
  onChange: (value: boolean) => void;
};

const PropertyCheckboxCell: React.FC<PropertyCheckboxCellProps> = ({
  cellValue,
  onChange,
}) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <label>
        <input
          style={{
            cursor: "pointer",
          }}
          type="checkbox"
          checked={cellValue === "true"}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
      </label>
    </div>
  );
};

export default PropertyCheckboxCell;
