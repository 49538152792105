export function arrayToDict<T>(
  array: Array<T>,
  getId: (obj: T) => string | number,
) {
  const dict: { [key: string]: T } = {};
  array.forEach((item) => {
    const id = getId(item);
    dict[id] = item;
  });
  return dict;
}
