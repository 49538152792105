import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import React, { FormEvent, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";

import { checkForHubSpotId } from "../../../services/overlordService";
import {
  ApplicationType,
  BaseOrganization,
  NavbarTheme,
} from "../../../services/usersService";
import ButtonWithIconAndText from "../../common/button/ButtonWithIconAndText";

function buttonChooser<T extends string>(
  id: string,
  labels: Record<T, string>,
  value: T,
  setValue: (t: T) => void,
) {
  return (
    <ButtonGroup id={id} className="d-block d-flex w-100">
      {Object.keys(labels).map((v) => (
        <Button
          key={v}
          className="w-100"
          color={value === v ? "secondary" : "light"}
          onClick={() => setValue(v as T)}
        >
          {labels[v as T]}
        </Button>
      ))}
    </ButtonGroup>
  );
}

interface HubSpotIdProps {
  success: boolean;
  message: string;
}

const OrganizationForm: React.FC<{
  label: string;
  icon: IconDefinition;
  organization: BaseOrganization;
  onSubmit: (
    name: string,
    domains: string[],
    application: ApplicationType,
    navbarTheme: NavbarTheme,
    file: File | undefined,
    commonInsights: boolean | undefined,
    sesTls: boolean | undefined,
    slackEnabled: boolean,
    slackChannel: string,
    hsRecordId: string,
  ) => undefined | Promise<any>;
  isCreate?: boolean;
}> = ({ label, icon, organization, isCreate, onSubmit }) => {
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState(organization.name);
  const [domains, setDomains] = useState(organization.domains);
  const [application, setApplication] = useState(organization.application);
  const [navbarTheme, setNavBarTheme] = useState(
    organization.configuration.navbarTheme ?? "dark",
  );
  const [sesTls, setSesTls] = useState(organization.configuration.sesTls);
  const [slackChannel, setSlackChannel] = useState(
    organization.slack_channel ? organization.slack_channel : "",
  );
  const [slackEnabled, setSlackEnabled] = useState(organization.slack_enabled);
  const [file, setFile] = useState<File>();
  const [hsRecordId, setHsRecordId] = useState(organization.hs_record_id);
  const [checkResult, setCheckResult] = useState<HubSpotIdProps>();
  const [commonInsights, setCommonInsights] = useState(
    !!organization.configuration.commonInsights,
  );

  const checkHsRecordId = async () => {
    await checkForHubSpotId(name).then((res) => {
      console.log(res);
      if (res.result) {
        setCheckResult({
          success: true,
          message: `Found HubSpot ID: ${res.result.id} for ${name}`,
        });
      } else {
        setCheckResult({
          success: false,
          message: `No HubSpot ID found for ${name}`,
        });
      }
    });
  };

  const doSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    console.log(e);
    const promise = onSubmit(
      name,
      domains,
      application,
      navbarTheme,
      file,
      commonInsights,
      sesTls,
      slackEnabled,
      slackChannel,
      hsRecordId,
    );
    (promise ?? Promise.resolve()).finally(() => setSubmitting(false));
  };

  return (
    <Form onSubmit={doSubmit}>
      <FormGroup>
        <Label for="org-name">Name</Label>
        <Input
          type="text"
          id="org-name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="org-domain">Domain</Label>
        <Input
          type="text"
          id="org-domain"
          value={domains.join(",")}
          onChange={(e) => setDomains(e.target.value.split(/[,\s]+/))}
        />
        <FormText>Separate multiple domains with commas.</FormText>
      </FormGroup>
      <FormGroup check>
        <Input
          id="common-insights"
          type="checkbox"
          checked={commonInsights}
          onChange={(e) => setCommonInsights(e.target.checked)}
        />
        <Label check for="common-insights">
          Use common model insights
        </Label>
      </FormGroup>
      <FormGroup check>
        <Input
          id="ses-tls"
          type="checkbox"
          checked={!!sesTls}
          onChange={(e) => setSesTls(e.target.checked)}
        />
        <Label check for="ses-tls">
          Require transport level security (TLS) for email delivery
        </Label>
      </FormGroup>
      {isCreate && (
        <>
          <FormGroup>
            <Label for="org-logo">Logo</Label>
            <Input
              className="upload-file-input"
              type="file"
              id="org-logo"
              accept="image/*"
              onChange={(e) => setFile(e.target.files![0])}
            />
          </FormGroup>
          <FormGroup>
            <Label for="org-navbar-theme">Navbar Theme</Label>
            {buttonChooser(
              "org-navbar-theme",
              { dark: "Dark", light: "Light" },
              navbarTheme!,
              setNavBarTheme,
            )}
          </FormGroup>
        </>
      )}
      <FormGroup className="mt-2">
        <Label for="org-application">Application</Label>
        {buttonChooser(
          "org-application",
          {
            BI: "Business Intelligence",
            PC: "Property Connect",
            DV: "Deal Vault",
          },
          application,
          setApplication,
        )}
      </FormGroup>
      <FormGroup check>
        <Input
          id="org-slack-enabled"
          type="checkbox"
          checked={slackEnabled}
          onChange={(e) => setSlackEnabled(e.target.checked)}
        />
        <Label check for="org-slack-enabled">
          Enable Slack Notifications
        </Label>
      </FormGroup>
      <FormGroup className="mt-2">
        <Label for="org-slack-channel">Slack Channel</Label>
        <Input
          type="text"
          id="org-slack-channel"
          value={slackChannel}
          disabled={!slackEnabled}
          onChange={(e) => setSlackChannel(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="org-hs-record-id">HubSpot Record ID</Label>
        <Row className="row-cols-lg-auto g-3 align-items-center">
          <Col>
            <Input
              id="org-hs-record-id"
              type="text"
              value={hsRecordId}
              onChange={(e) => setHsRecordId(e.target.value)}
            />
          </Col>
          <Col>
            <Button onClick={checkHsRecordId}>Find HubSpot Record ID</Button>
          </Col>
          <Col>
            {checkResult && (
              <Alert color={checkResult.success ? "primary" : "warning"}>
                {checkResult.message}
              </Alert>
            )}
          </Col>
        </Row>
      </FormGroup>
      <hr />
      <FormGroup className="pull-right mt-4">
        <ButtonWithIconAndText
          type="submit"
          color="primary"
          icon={icon}
          text={label}
          className="w-25"
          disabled={submitting}
        />
      </FormGroup>
    </Form>
  );
};

export default OrganizationForm;
