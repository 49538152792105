import { Buffer } from "buffer";

import {
  CommonAccountMapping,
  CommonAccountTree,
  CommonFinancialColumn,
  commonAccountTree,
  commonFinanceColumns,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Col, Row } from "reactstrap";

import {
  Integration,
  parseCommonMapping,
} from "../../../services/integrationsService";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";

type AccountsSectionProps<T extends string> = {
  system: Integration;
  config: CommonAccountMapping;
  updateConfig: (config: CommonAccountMapping) => void;
};

const CommonAccountsSection = <T extends string>({
  config,
  updateConfig,
}: AccountsSectionProps<T>) => {
  const onUnexpectedError = useOnUnexpectedError();
  const renderAccount = (
    column: CommonFinancialColumn,
    tree: CommonAccountTree,
    depth: number,
  ) => {
    const hasChildren = !!Object.keys(tree).length;
    return (
      <div key={column} className={depth ? "account-entry" : undefined}>
        <div
          className={clsx("account-label d-flex align-items-baseline", {
            "account-header": hasChildren,
          })}
        >
          <span className="account-label-name">
            {commonFinanceColumns[column]}
          </span>
          <span className="account-line flex-grow-1" />
          <span className="account-codes">
            {config[column]?.join(", ") ?? ""}
          </span>
        </div>
        {Object.entries(tree).map(([k, v]) =>
          typeof v === "string"
            ? null
            : renderAccount(k as CommonFinancialColumn, v, 1 + depth),
        )}
      </div>
    );
  };

  const [structure, setStructure] = useState<string>();

  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      function parseFile(file: File) {
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
          try {
            const data = event.target?.result as ArrayBuffer;
            setStructure(Buffer.from(data).toString("base64"));
          } catch (e) {
            setStructure(undefined);
            onUnexpectedError(e);
          }
        });
        reader.readAsArrayBuffer(file);
      }

      parseFile(acceptedFiles[0]);
    },
    [onUnexpectedError],
  );

  useEffect(() => {
    if (structure) {
      parseCommonMapping(structure)
        .then(({ mapping }) => {
          updateConfig(mapping);
        })
        .catch((e) => {
          setStructure(undefined);
          onUnexpectedError(e);
        });
    }
    // react-hooks/exhaustive-deps warns "If 'updateConfig' changes too often,
    // find the parent component that defines it and wrap that definition in
    // useCallback"
  }, [onUnexpectedError, structure, updateConfig]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    disabled: uploading,
    maxFiles: 1,
    multiple: false,
  });

  return (
    <div
      {...getRootProps({
        className: clsx("generic-drop", {
          isDragWaiting: !isDragActive,
          isDragActive,
          isDragAccept,
          isDragReject,
        }),
      })}
    >
      <input {...getInputProps()} />
      <Row>
        <Col>
          <div className="d-flex finance-modal-account-tree-header">
            <strong>Account</strong>
            <strong className="ms-auto">Account Codes</strong>
          </div>
          <div className="finance-modal-account-tree">
            {Object.entries(commonAccountTree).map(([k, v]) =>
              typeof v === "string"
                ? null
                : renderAccount(k as CommonFinancialColumn, v, 0),
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CommonAccountsSection;
