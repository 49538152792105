import React, { ReactText } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { SecondaryAxisKeyValue, SortDirection } from "../../../services/models";
import {
  chartNumberFormatter,
  tooltipFormatter,
  tooltipSorter,
} from "../../../utils/chartNumberFormatter";

interface LineChartProps {
  data: Array<Object>;
  xAxisKey: string;
  yAxisKeys: { [key: string]: SecondaryAxisKeyValue };
  yAxisUnit: string;
  contentStyle?: Function;
  stackId?: string;
  tickFormatter?: Function;
  type?: any | "linear";
  tooltipSortDir?: SortDirection;
}

const JhLineChart: React.FC<LineChartProps> = (props) => {
  const { data, xAxisKey, yAxisKeys, stackId, type, tooltipSortDir } = props;

  const allLineCharts = Object.entries(yAxisKeys).map(([key, value]) => {
    return (
      <Area
        key={key}
        type={type}
        dataKey={key}
        name={key}
        fill={value.colorHex}
        fillOpacity={0}
        stroke={value.colorHex}
        strokeWidth={5}
        yAxisId={value.orientation}
        stackId={stackId}
        connectNulls
      />
    );
  });
  const leftYAxisUnit = Object.values(yAxisKeys).find(
    (axisKeyInfo) => axisKeyInfo.orientation === "left",
  )?.unit;
  const rightYAxisUnit = Object.values(yAxisKeys).find(
    (axisKeyInfo) => axisKeyInfo.orientation === "right",
  )?.unit;

  function calculateYAxisMin(dataMin: number | string): number | string {
    if (typeof dataMin === "number" && Number.isFinite(dataMin)) {
      if (Math.abs(dataMin) < 5) return dataMin;

      const floored = Math.floor(dataMin);
      if (floored % 5 === 0) return floored;
      return calculateYAxisMin(floored - 1);
    } else {
      return dataMin;
    }
  }

  function calculateYAxisMax(dataMax: number | string): number | string {
    if (typeof dataMax === "number" && Number.isFinite(dataMax)) {
      if (Math.abs(dataMax) < 5) return dataMax;

      const ceiling = Math.ceil(dataMax);
      if (ceiling % 5 === 0) return ceiling;
      return calculateYAxisMax(ceiling + 1);
    } else {
      return dataMax;
    }
  }

  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{ left: 10, right: 10, top: 0, bottom: 0 }}
      >
        <CartesianGrid vertical={false} stroke="#d6d9da" />
        <XAxis
          dataKey={xAxisKey}
          angle={270}
          interval={0}
          tickFormatter={(value) => (value as string).substring(0, 10)}
          height={100}
          dy={50}
          dx={-7}
        />
        {rightYAxisUnit ? (
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={(v) =>
              chartNumberFormatter(v as number, rightYAxisUnit)
            }
            domain={[calculateYAxisMin, calculateYAxisMax]}
          />
        ) : null}
        {leftYAxisUnit ? (
          <YAxis
            tickFormatter={(v) =>
              chartNumberFormatter(v as number, leftYAxisUnit)
            }
            yAxisId="left"
            domain={[calculateYAxisMin, calculateYAxisMax]}
          />
        ) : null}

        <Tooltip
          formatter={(v: string | number | ReactText[], axisKey: string) =>
            tooltipFormatter(v, axisKey, yAxisKeys)
          }
          itemSorter={
            tooltipSortDir ? tooltipSorter(tooltipSortDir) : undefined
          }
          isAnimationActive={false}
        />
        <Legend />
        {allLineCharts.map((item) => item)}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default JhLineChart;
