import { apiUrl, axiosConfig, axiosJsonConfig } from "../utils/api";
import axios, { lastRequestTime } from "./axios";

export const loginUrl = () => apiUrl("/session/login");

export const logoutUrl = () => apiUrl("/session/logout");

export async function zendeskLogin(): Promise<string> {
  return axios.get(apiUrl(`/session/zendeskLogin`), axiosConfig).then((res) => {
    return res.data as string;
  });
}

export async function setOrganization(organization: number): Promise<void> {
  return axios.put(
    apiUrl("/session/organization"),
    organization,
    axiosJsonConfig,
  );
}

export async function pingSession(): Promise<void> {
  return axios.put(apiUrl("/session/ping"), undefined, axiosConfig);
}

export async function sudoAs(person: number): Promise<void> {
  return axios.post(
    apiUrl(`/session/sudo/${person}`),
    undefined,
    axiosJsonConfig,
  );
}

/** If we've made no request in 5 minutes then ping the server to keep the session alive. */
export const keepSessionAlive = () => {
  if (new Date().getTime() - lastRequestTime >= 5 * 60 * 1000)
    pingSession().then(() => {});
};

const returnUrlKey = "login-return-url";

export const getSessionReturnUrl = (): string | null =>
  sessionStorage.getItem(returnUrlKey);

export const setSessionReturnUrl = (url: string | null) =>
  url == null
    ? sessionStorage.removeItem(returnUrlKey)
    : sessionStorage.setItem(returnUrlKey, `${url}`);
