import * as ExcelJS from "exceljs";
import { CellValue } from "exceljs";
import Papa from "papaparse";
import {
  boldFont,
  formatSimpleExcelValue,
  thinBottomBorder,
  thinRightBorder,
} from "../report";
import {
  exhaustiveCheck,
  PureDate,
  usCentricYesterdayUnlessItIsTheEvening,
} from "../util";
import {
  AdHocDataSet,
  AdHocDataSetColumn,
  AdHocInputConfig,
  AdHocRule,
  ColumnMatch,
  isByPropertyRule,
  isColumnMatchFixed,
  isColumnMatchHeader,
  isPropertyAttribute,
  isPropertyInsight,
  isRowMatchContaining,
  isRowMatchFixed,
  isTransformerRule,
  RowMatchContaining,
} from "./adHocRule";

const isValid = (regex: string | undefined, value: string) =>
  regex === undefined ? true : new RegExp(regex).test(value);

export const isValidFileName = (ruleObject: AdHocRule, fileName: string) =>
  isValid(ruleObject?.match?.fileName, fileName);

export const isValidSheetName = (
  ruleObject: AdHocRule,
  workbook: ExcelJS.Workbook,
) => {
  if (!ruleObject?.match?.sheetName) {
    return workbook.worksheets[0];
  }

  return workbook.worksheets.find((ws) =>
    isValid(ruleObject.match.sheetName, ws.name),
  );
};

export const isValidCells = (
  ruleObject: AdHocRule,
  sheet: ExcelJS.Worksheet,
) => {
  if (!ruleObject?.match?.cells?.length) return true;

  for (const cellRule of ruleObject.match.cells) {
    const { row, col, value } = cellRule;

    if (!row || !col || value === undefined) return false;

    const cell = sheet.findCell(row, col);

    if (!cell) return false;

    if (!isValid(value, formatSimpleExcelValue(cell.value))) return false;
  }

  return true;
};

export const getSheetData = (ws: ExcelJS.Worksheet) => {
  let sheetData: any[] = [];

  ws.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    let record: Record<string, any> = {};

    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      const rowCol = cell.fullAddress.address.match(/[A-Z]+/);
      const key = rowCol ? rowCol[0] : "AAA";
      const value = cell.value || "";
      record[key] = value;
    });

    sheetData.push(record);
  });
  return sheetData;
};

export const makeWorkbook = (rule: AdHocRule, outData: Array<any[]>) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Revolution RE";
  workbook.created = new Date();
  const worksheet = workbook.addWorksheet("Insights");

  if (isTransformerRule(rule)) {
    if (rule.dataSetConfigs.length > 0) {
      const dataSetConfig = rule.dataSetConfigs[0];
      const columnFormats = dataSetConfig.outputColumns.map(
        (col) => col.displayFormat,
      );
      outData.forEach((rowData, rowIndex) => {
        rowData.forEach((cellData, colIndex: number) => {
          const cell = worksheet.getRow(rowIndex + 1).getCell(colIndex + 1);
          cell.value = cellData;
          const format = columnFormats[colIndex]?.format;
          if (format) cell.numFmt = format;
        });
      });
    }
    return workbook;
  } else if (isByPropertyRule(rule)) {
    worksheet.views = [{ state: "frozen", xSplit: 3, ySplit: 1 }];
    worksheet.addRow(["Date", "Property", "Insight", "Value 1"]);
    const headerRow = worksheet.getRow(1);
    headerRow.border = thinBottomBorder;
    headerRow.font = boldFont;
    const lastHeaderCol = worksheet.getColumn(3);
    lastHeaderCol.border = thinRightBorder;
    worksheet.addRows(outData);
    return workbook;
  } else {
    return exhaustiveCheck(rule);
  }
};

const findValidConfiguration = (
  configurations: AdHocInputConfig[],
  rowIndex: number,
) =>
  configurations.find(({ startRow, endRow }) => {
    const isValidStart = rowIndex >= startRow - 1;
    const isValidEnd = endRow === null || rowIndex <= endRow - 1;
    return isValidStart && isValidEnd;
  });

const shouldIncludeHeader = (config: AdHocInputConfig, rowIndex: number) =>
  config.includeHeaderInOutput && rowIndex === config.startRow - 1;

const generateHeaderRow = (dataSetConfig: { outputColumns: any[] }) =>
  dataSetConfig.outputColumns.map((column) => column.outputColumnName);

const generateDataRow = (
  inputRow: Record<string, string | number>,
  dataSetConfig: AdHocDataSet,
) =>
  dataSetConfig.outputColumns.map((column) => {
    if (!column.calculationFunction)
      throw new Error("Output column calculationFunction is not yet defined!");

    if (!column.arguments)
      throw new Error("Output column arguments are not yet defined!");

    return calculateColumnValue(inputRow, column);
  });

const calculateColumnValue = (
  inputRow: Record<string, string | number>,
  { calculationFunction, arguments: args }: AdHocDataSetColumn,
) => {
  let formula = calculationFunction;
  args.forEach(
    (arg: { inputColumn: string | number; alias: string | RegExp }) => {
      const value = inputRow[arg.inputColumn] || "0";
      formula = formula.replace(new RegExp(arg.alias, "g"), value.toString());
    },
  );

  try {
    return eval(formula);
  } catch (e) {
    console.error("Error evaluating formula: ", formula);
    return null;
  }
};

export const excelParseProcess = (
  rule: AdHocRule,
  sheet: ExcelJS.Worksheet,
  fileName: string,
): [Array<any[]>, Array<any[]> | undefined] => {
  if (isTransformerRule(rule)) {
    if (!rule.inputExcelConfigurations)
      throw new Error("Input Excel Configurations are not yet defined!");

    if (!rule.dataSetConfigs)
      throw new Error("Data Set Configs are not yet defined!");

    const sheetData = getSheetData(sheet);
    const results = sheetData.reduce((acc, row, rowIndex) => {
      const config = findValidConfiguration(
        rule.inputExcelConfigurations,
        rowIndex,
      );
      if (!config) return acc; // Skip rows that don't match any configuration

      const dataSetConfig = rule.dataSetConfigs.find(
        (c) => c.name === config.outputDataSetName,
      );
      if (!dataSetConfig) return acc; // Skip if no matching data set config is found

      if (shouldIncludeHeader(config, rowIndex))
        acc.push(generateHeaderRow(dataSetConfig));

      const outputRow = generateDataRow(row, dataSetConfig);
      if (outputRow) acc.push(outputRow);

      return acc;
    }, new Array<any[]>());
    return [results, undefined];
  } else if (isByPropertyRule(rule)) {
    const { dataDate, headerRow, propertyColumn, dataColumns } = rule;
    let headerRowNum = 0;
    const headerColumns: Record<string, string> = {};
    if (headerRow) {
      if (isRowMatchFixed(headerRow)) {
        headerRowNum = headerRow.row;
      } else if (isRowMatchContaining(headerRow)) {
        do {
          ++headerRowNum;
        } while (
          headerRowNum <= sheet.rowCount &&
          !rowContains(sheet.getRow(headerRowNum), headerRow)
        );
        if (headerRowNum > sheet.rowCount)
          throw Error("Failed to match header row");
      } else {
        exhaustiveCheck(headerRow);
      }
      sheet.getRow(headerRowNum).eachCell((cell) => {
        // WTF, cell.col is "1", "2" etc where address is "A1", "B1" etc
        headerColumns[formatSimpleExcelValue(cell.value)] =
          cell.address.replace(/\d+/, "");
      });
    }

    let date = usCentricYesterdayUnlessItIsTheEvening().toISOString();
    if (dataDate?.fileNameRegex) {
      const match = fileName.match(new RegExp(dataDate.fileNameRegex));
      if (match?.length) date = new PureDate(match[1]).toISOString();
    }

    const propertyCol = matchCol(headerColumns, propertyColumn);
    // Date, Property, Insight, Value
    const insightResults = new Array<any[]>();
    const attributeResults = new Array<any[]>();

    for (let rowNum = 1 + headerRowNum; rowNum <= sheet.rowCount; ++rowNum) {
      const propertyName = formatSimpleExcelValue(
        sheet.getCell(`${propertyCol}${rowNum}`).value,
      );
      if (!propertyName) continue;
      for (const dataColumn of dataColumns) {
        const dataCol = matchCol(headerColumns, dataColumn.column);
        const dataValue = sheet.getCell(`${dataCol}${rowNum}`).value;
        const { dataType } = dataColumn;
        const dataName = isPropertyAttribute(dataType)
          ? dataType.attribute
          : isPropertyInsight(dataType)
            ? dataType.insight
            : null;

        if (dataName && dataValue != null && dataValue !== "") {
          if (isPropertyAttribute(dataType)) {
            attributeResults.push([null, propertyName, dataName, dataValue]);
          } else if (isPropertyInsight(dataType)) {
            insightResults.push([
              date,
              propertyName,
              dataName,
              cleanNumber(dataValue),
            ]);
          }
        }
      }
    }
    return [insightResults, attributeResults];
  } else {
    return exhaustiveCheck(rule);
  }
};

// CSVs come through with numbers formatted as strings with commas and percents and dollars...
const DollarsRE = /^\$[0-9.,]+$/;
const PercentRE = /^[0-9.,]+%$/;
const NonDigitRE = /[^0-9.]/g;

const cleanNumber = (value: CellValue): CellValue =>
  typeof value !== "string"
    ? value
    : value.match(DollarsRE)
      ? parseFloat(value.replace(NonDigitRE, "")) // same as number but shrug
      : value.match(PercentRE)
        ? parseFloat(value.replace(NonDigitRE, "")) / 100
        : parseFloat(value.replace(NonDigitRE, ""));

const matchCol = (
  headerColumns: Record<string, string>,
  columnMatch: ColumnMatch,
): string => {
  if (isColumnMatchFixed(columnMatch)) {
    return columnMatch.col;
  } else if (isColumnMatchHeader(columnMatch)) {
    const propertyCol = headerColumns[columnMatch.header];
    if (!propertyCol)
      throw Error(`Unknown column header ${columnMatch.header}`);
    return propertyCol;
  } else {
    return exhaustiveCheck(columnMatch);
  }
};

const rowContains = (row: ExcelJS.Row, header: RowMatchContaining): boolean => {
  return false;
};

export const parseCSV = (buffer: Buffer | ArrayBuffer) => {
  const textDecoder = new TextDecoder("utf-8");
  const csvString = textDecoder.decode(buffer);

  const { data: csvData } = Papa.parse(csvString);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1");

  csvData.forEach((row) => {
    worksheet.addRow(row);
  });

  return workbook;
};
