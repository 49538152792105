import {
  DateExpression,
  DatePeriod,
  PeriodExpression,
  PureDate,
  isDateExpression,
  isPeriodExpression,
} from "@joyhub-integration/shared";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Input, Label } from "reactstrap";

type DateBasedGroupFilterProps = {
  columnValues: any[];
  existingSelection?: DateExpression | PeriodExpression;
  updateSelection: (update?: DateExpression | PeriodExpression) => void;
};

const DateBasedGroupFilter: React.FC<DateBasedGroupFilterProps> = ({
  columnValues,
  existingSelection,
  updateSelection,
}) => {
  const [mode, setMode] = useState<"date" | "period">(
    isDateExpression(existingSelection!) ? "date" : "period",
  );
  const [period, setPeriod] = useState<DatePeriod | undefined>(
    isPeriodExpression(existingSelection!)
      ? existingSelection.period
      : undefined,
  );
  const [after, setAfter] = useState<string | undefined>(
    isDateExpression(existingSelection!) ? existingSelection.after : undefined,
  );
  const [before, setBefore] = useState<string | undefined>(
    isDateExpression(existingSelection!)
      ? existingSelection?.before
      : undefined,
  );

  const [rangeMode, setRangeMode] = useState<"before" | "after" | "between">(
    () => {
      if (before != null && after != null) {
        return "between";
      } else if (before != null) {
        return "before";
      } else {
        return "after";
      }
    },
  );

  const validRange =
    (after && before && new PureDate(before).greaterThan(after)) ||
    (after && !before) ||
    (!after && before);

  useEffect(() => {
    if (existingSelection == null) {
      setPeriod(undefined);
      setBefore(undefined);
      setAfter(undefined);
    }
  }, [existingSelection]);

  useEffect(() => {
    if (mode === "date" && validRange) {
      const update: DateExpression = {
        type: "expression",
      };
      if (after && rangeMode !== "before") {
        update.after = after;
      }
      if (before && rangeMode !== "after") {
        update.before = before;
      }
      updateSelection(update);
      setPeriod(undefined);
    } else if (period != null) {
      const periodUpdate: PeriodExpression = {
        type: "expression",
        period,
      };
      updateSelection(periodUpdate);
      setBefore(undefined);
      setAfter(undefined);
    }
  }, [after, before, mode, period, rangeMode, updateSelection, validRange]);

  return (
    <div>
      <ButtonGroup className="p-2">
        <Button
          size="sm"
          outline={mode !== "date"}
          onClick={() => {
            setMode("date");
            // submitOnChange();
          }}
        >
          Range
        </Button>
        <Button
          size="sm"
          outline={mode !== "period"}
          onClick={() => {
            setMode("period");
            // submitOnChange();
          }}
        >
          Preceding Period
        </Button>
      </ButtonGroup>
      {mode === "date" ? (
        <>
          <ButtonGroup className="p-2">
            <Button
              size="sm"
              outline={rangeMode !== "after"}
              onClick={() => {
                setRangeMode("after");
              }}
              style={{ width: "120px" }}
            >
              After
            </Button>
            <Button
              size="sm"
              outline={rangeMode !== "before"}
              onClick={() => {
                setRangeMode("before");
              }}
              style={{ width: "120px" }}
            >
              Before
            </Button>
            <Button
              size="sm"
              outline={rangeMode !== "between"}
              onClick={() => {
                setRangeMode("between");
              }}
              style={{ width: "120px" }}
            >
              Between
            </Button>
          </ButtonGroup>
          <div>
            <Label className="p-2">
              Date After
              <Input
                type="date"
                value={after}
                onChange={(evt) => {
                  setAfter(evt.target.value);
                  // submitOnChange();
                }}
                disabled={rangeMode === "before"}
                style={{ opacity: rangeMode === "before" ? 0.25 : 1 }}
              />
            </Label>
            <Label className="p-2">
              Date Before
              <Input
                type="date"
                value={before}
                onChange={(evt) => {
                  setBefore(evt.target.value);
                  // submitOnChange();
                }}
                disabled={rangeMode === "after"}
                style={{ opacity: rangeMode === "after" ? 0.25 : 1 }}
              />
            </Label>
            {!validRange && (before != null || after != null) ? (
              <div className="text-danger ps-2 mt-0 mb-2">
                The range isn't valid.
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <div className="d-flex align-items-baseline p-2">
          <Label>In the preceding...</Label>
          <ButtonGroup className="ps-2">
            <Button
              size="sm"
              outline={period !== "1mo"}
              onClick={() => {
                setPeriod("1mo");
                // submitOnChange();
              }}
            >
              1mo
            </Button>
            <Button
              size="sm"
              outline={period !== "3mo"}
              onClick={() => {
                setPeriod("3mo");
                // submitOnChange();
              }}
            >
              3mo
            </Button>
            <Button
              size="sm"
              outline={period !== "6mo"}
              onClick={() => {
                setPeriod("6mo");
                // submitOnChange();
              }}
            >
              6mo
            </Button>
            <Button
              size="sm"
              outline={period !== "1yr"}
              onClick={() => {
                setPeriod("1yr");
                // submitOnChange();
              }}
            >
              1yr
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default DateBasedGroupFilter;
