import {
  PropertiesByColumn,
  PropertiesByIds,
} from "@joyhub-integration/shared";
import React, { useState } from "react";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
} from "reactstrap";

import { Property } from "../../../services/propertiesService";
import FilteringPicker from "../../common/FilteringPicker";
import PropertiesColumnPicker from "./PropertiesColumnPicker";

// a value that should just be on the PropertiesSelection union members
export type PropertiesSelectionType = "all" | "none" | "ids" | "columns";

interface ViewPropertiesPermissionsFormGroupProps {
  allProperties: Property[];
  propertiesSelectionType: PropertiesSelectionType;
  setPropertiesSelectionType: (type: PropertiesSelectionType) => void;
  byIdsSelection: PropertiesByIds;
  setByIdsSelection: (selection: PropertiesByIds) => void;
  byColumnSelection: PropertiesByColumn;
  setByColumnSelection: (selection: PropertiesByColumn) => void;
}

const ViewPropertiesPermissionsFormGroup: React.FC<
  ViewPropertiesPermissionsFormGroupProps
> = ({
  allProperties,
  propertiesSelectionType,
  setPropertiesSelectionType,
  byIdsSelection,
  setByIdsSelection,
  byColumnSelection,
  setByColumnSelection,
}) => {
  const [choiceDropdownOpen, setChoiceDropdownOpen] = useState(false);

  const toggleChoiceDropdown = () => {
    setChoiceDropdownOpen((prev) => !prev);
  };

  const dropdownToggleText = (sel: PropertiesSelectionType) => {
    if (sel === "ids") {
      return "Choose from list";
    } else if (sel === "columns") {
      return "Filter by attribute";
    } else {
      return "Choose";
    }
  };

  return (
    <>
      <FormGroup>
        <Label className="me-2">Properties allowed:</Label>
        <ButtonGroup size="sm">
          <Button
            size="sm"
            outline
            active={propertiesSelectionType === "none"}
            onClick={() => setPropertiesSelectionType("none")}
          >
            None
          </Button>
          <Button
            size="sm"
            outline
            active={propertiesSelectionType === "all"}
            onClick={() => setPropertiesSelectionType("all")}
          >
            All
          </Button>
          <ButtonDropdown
            size="sm"
            isOpen={choiceDropdownOpen}
            toggle={toggleChoiceDropdown}
          >
            <DropdownToggle
              caret
              outline
              size="sm"
              active={
                propertiesSelectionType === "ids" ||
                propertiesSelectionType === "columns"
              }
            >
              {dropdownToggleText(propertiesSelectionType)}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setPropertiesSelectionType("ids")}>
                {dropdownToggleText("ids")}
              </DropdownItem>
              <DropdownItem
                onClick={() => setPropertiesSelectionType("columns")}
              >
                {dropdownToggleText("columns")}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </ButtonGroup>
      </FormGroup>
      {propertiesSelectionType === "ids" && (
        <FilteringPicker
          options={allProperties}
          label="property_name"
          filterOn={["property_code", "property_name"]}
          selection={byIdsSelection.ids}
          setSelection={(ids) => setByIdsSelection({ ids })}
        />
      )}
      {propertiesSelectionType === "columns" && (
        <PropertiesColumnPicker
          allProperties={allProperties}
          byColumnSelection={byColumnSelection}
          setByColumnSelection={setByColumnSelection}
        />
      )}
    </>
  );
};

export default ViewPropertiesPermissionsFormGroup;
