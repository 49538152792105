import {
  ReportColumn,
  ReportDataColumn,
  ReportSheetData,
  cellDisplayValue,
} from "@joyhub-integration/shared";
import { Progress } from "reactstrap";

import { formatValue } from "../../../../utils/formatValue";
import getDynamicFont from "./getDynamicFont";

interface RelValueCardPropsType {
  data: ReportSheetData;
  xItems: {
    column: ReportDataColumn<any>;
    index: number;
  }[];
  yItems: {
    column: ReportDataColumn<any> | ReportColumn<"Unresolved">;
    index: number;
  }[];
}

export const RelValueCard = (props: RelValueCardPropsType) => {
  const { data, xItems, yItems } = props;

  const { boxContainer, getCustomDynamicFont } = getDynamicFont();

  const xAxisIndex = !isNaN(Number(data.xAxisIndex))
    ? Number(data.xAxisIndex)
    : data.rows.length - 1;

  const xItem = xItems.length > 0 ? xItems[0] : undefined;
  const yItemOne = yItems.length > 0 ? yItems[0] : undefined;
  const yItemTwo = yItems.length > 1 ? yItems[1] : undefined;
  const yItemThree = yItems.length > 2 ? yItems[2] : undefined;

  const yValueOne = yItemOne && data.rows[xAxisIndex]?.values[yItemOne.index];
  const yValueTwo = yItemTwo && data.rows[xAxisIndex]?.values[yItemTwo.index];
  const yValueThree =
    yItemThree && data.rows[xAxisIndex]?.values[yItemThree.index];
  const xValue = xItem && data.rows[xAxisIndex]?.values[xItem.index];
  const yHeaderOne = yItemOne && data.columns[yItemOne.index]?.header;
  const yHeaderThree = yItemThree && data.columns[yItemThree.index]?.header;

  const progressValue = cellDisplayValue(yValueTwo ?? "");
  const convertedValue =
    typeof progressValue === "number"
      ? parseFloat((progressValue * 100).toFixed(2))
      : 0;
  let progressColor = "green";
  let progressTextColor = "white";
  if (convertedValue >= 50 && convertedValue < 80) {
    progressColor = "yellow";
    progressTextColor = "black";
  } else if (convertedValue < 50) progressColor = "#b35454";

  return (
    <div
      className="w-100 h-100 position-relative"
      style={{ overflow: "hidden", whiteSpace: "nowrap" }}
    >
      <div
        className="position-absolute"
        style={{
          bottom: 0,
          left: "10px",
          fontSize: getCustomDynamicFont(0.6),
          color: "#666666",
        }}
      >
        <div>
          {xValue ? (
            formatValue(xValue, data.columns[xItem.index])
          ) : (
            <div style={{ color: "gray" }}>No basis</div>
          )}
        </div>
      </div>
      <div
        ref={boxContainer}
        className="w-100 h-100 flex-col align-items-center justify-content-around"
      >
        <div className="w-100 h-100 flex-col align-items-center justify-content-start">
          <div
            style={{ fontSize: getCustomDynamicFont(1), fontWeight: "bold" }}
          >
            {yHeaderOne || <div style={{ color: "gray" }}>No Title</div>}
          </div>
          <div
            style={{ fontSize: getCustomDynamicFont(1.5), fontWeight: "bold" }}
          >
            {yValueOne ? (
              formatValue(yValueOne, data.columns[yItemOne.index])
            ) : (
              <div style={{ color: "gray" }}>No value</div>
            )}
          </div>
        </div>
        <div className="w-100 flex-col align-items-center justify-content-around">
          <Progress
            value={convertedValue}
            style={{
              height: getCustomDynamicFont(0.7),
              width: "70%",
              borderRadius: "6px",
            }}
            barStyle={{
              background: progressColor,
              color: progressTextColor,
              fontSize: getCustomDynamicFont(0.5),
            }}
          >
            {`${convertedValue} %`}
          </Progress>
        </div>
        <div className="w-100 h-100 flex-col align-items-center justify-content-end">
          <div
            style={{
              fontSize: getCustomDynamicFont(0.7),
            }}
          >
            {yHeaderThree || <div style={{ color: "gray" }}>No Title</div>}
          </div>
          <div style={{ fontSize: getCustomDynamicFont(1) }}>
            {yValueThree ? (
              formatValue(yValueThree, data.columns[yItemThree.index])
            ) : (
              <div style={{ color: "gray" }}>No value</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
