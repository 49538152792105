import { PureDate } from "@joyhub-integration/shared";
import { values } from "lodash";
import { useMemo } from "react";
import { Table } from "reactstrap";

import { RangedInsights } from "../../../services/dataService";
import {
  AllUnitCount,
  AvailableUnitCount,
  MoveIns,
  MoveOuts,
  OccupiedUnitCount,
  SiteVisitCount,
} from "../../../services/insightLibrary/backendInsightIds";
import { toFullMonth, toShortMonthAndDay } from "../../../utils/date";
import { safeSubtract } from "../../../utils/number";
import { insightValue, previousInsights } from "../cdUtil";
import AnimatedNumber from "../visualizations/AnimatedNumber";
import DashboardCard from "./DashboardCard";
import { Skelington } from "./Skelington";

type CDAvailability = {
  date: string;
  totalUnits: number | undefined;
  occupied: number | undefined;
  moveIns: number | undefined;
  moveOuts: number | undefined;
  net: number | undefined;
  tours: number | undefined;
  available: number | undefined;
};

type CDAvailabilityData = CDAvailability[];

const availabilityData = (
  rangedInsights: RangedInsights | undefined,
  date: PureDate,
): CDAvailabilityData | undefined => {
  if (rangedInsights == null) return undefined;
  const data: CDAvailabilityData = [];
  for (let month = 0; month < 3; ++month) {
    const insights = previousInsights(rangedInsights, date, month);
    data.push({
      date: date.lastDayOfPriorMonth(month).toJSON(),
      totalUnits: insightValue(insights?.insights, AllUnitCount),
      occupied: insightValue(insights?.insights, OccupiedUnitCount),
      moveIns: insightValue(insights?.insights, MoveIns),
      moveOuts: insightValue(insights?.insights, MoveOuts),
      net: safeSubtract(
        insightValue(insights?.insights, MoveIns),
        insightValue(insights?.insights, MoveOuts),
      ),
      tours: insightValue(insights?.insights, SiteVisitCount),
      available: insightValue(insights?.insights, AvailableUnitCount),
    });
  }
  return data;
};

const fields = [
  "totalUnits",
  "occupied",
  "moveIns",
  "moveOuts",
  "net",
  "tours",
  "available",
] as const;

const cell = (number: number | undefined, key: number) => (
  <td key={key}>
    {number == null ? "–" : <AnimatedNumber>{number}</AnimatedNumber>}
  </td>
);

const Availability = ({
  insights,
  date,
  onClick,
}: {
  insights?: RangedInsights;
  date: PureDate;
  onClick?: () => void;
}) => {
  const availability = useMemo(
    () => availabilityData(insights, date),
    [insights, date],
  );
  const formatDate = (dateStr: string): string => {
    const date = new PureDate(dateStr);
    const eom = new PureDate(date);
    eom.setDate(1);
    eom.setMonth(1 + eom.getMonth());
    eom.setDate(0);
    return date.getDate() === eom.getDate()
      ? toFullMonth(date)
      : toShortMonthAndDay(date);
  };
  return (
    <DashboardCard
      title={`Availability by Month`}
      onClick={onClick}
      background="inner"
    >
      <Skelington value={availability} height={4}>
        {(periods) => (
          <Table className="mb-0 bg-white rounded" size="sm" responsive>
            <thead>
              <tr>
                <th />
                <th>Total Units</th>
                <th>Occupied</th>
                <th>Move Ins</th>
                <th>Move Outs</th>
                <th>Net</th>
                <th>Tours</th>
                <th>Available</th>
              </tr>
            </thead>
            <tbody>
              {periods.map((period) => (
                <tr key={period.date}>
                  <td>{formatDate(period.date)}</td>
                  {values(fields).map((field, idx) => cell(period[field], idx))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Skelington>
    </DashboardCard>
  );
};

export default Availability;
