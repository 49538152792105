import {
  ReportDataColumn,
  ReportDataRow,
  ReportDataValue,
  RowType,
  cellStyle,
  formatCellDate,
  formatCellValue,
  formatStringValue,
  isComparisonValue,
  isPropertyValue,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { Link } from "react-router";

import DeltaCell from "./DeltaCell";
import DifferenceCell from "./DifferenceCell";
import FractionCell from "./FractionCell";
import PropertyCell from "./PropertyCell";
import Tooltip from "./Tooltip";

const Cell = ({
  rowType,
  value,
  row,
  column,
  colSpan,
  to,
  selected,
}: {
  rowType: RowType;
  value: ReportDataValue;
  row: ReportDataRow;
  column: ReportDataColumn;
  selected?: boolean;
  colSpan?: number;
  to?: string;
}) => {
  let formatted: any;
  if (column.type === "Date") {
    formatted = formatCellDate(value, column.format?.numFmt);
  } else if (row.type !== "Group" && isPropertyValue(value)) {
    formatted = <PropertyCell value={value} />;
  } else if (column.type === "String") {
    formatted = formatStringValue(value);
  } else if (isComparisonValue(value)) {
    switch (column.versus?.comparison) {
      case "Delta":
        formatted = (
          <DeltaCell value={value} format={column.format} rowType={rowType} />
        );
        break;
      case "Difference":
        formatted = (
          <DifferenceCell
            value={value}
            format={column.format}
            rowType={rowType}
          />
        );
        break;
      case "Fraction":
        formatted = (
          <FractionCell
            value={value}
            format={column.format}
            rowType={rowType}
          />
        );
        break;
    }
  } else {
    formatted = (
      <span style={cellStyle(value, column.format?.cellFormats ?? [], rowType)}>
        {formatCellValue(value, column.format?.numFmt)}
      </span>
    );
  }
  const cellClass = clsx("py-3 pe-3", rowType, column.type, {
    Selected: selected,
    "text-right px-3": typeof value !== "string" && !isPropertyValue(value),
  });
  return (
    <td
      id={`cell-${column.index}-${row.key}`}
      className={clsx("align-middle", cellClass)}
      colSpan={colSpan}
    >
      {to == null ? (
        formatted
      ) : (
        <Link className="cell-link" to={to}>
          {formatted}
        </Link>
      )}
      <Tooltip
        target={`cell-${column.index}-${row.key}`}
        value={value}
        format={column.format}
        comparison={column.versus?.comparison}
      />
    </td>
  );
};

export default Cell;
