import { CustomColumn, CustomColumnType } from "./organization";
import { PropertyColumnDefaultTitle, PropertyColumnKey } from "./types";

export const propertyAttributeIsNumber = (attribute: string) => {
  return (
    attribute.includes("user_decimal") ||
    attribute.includes("user_money") ||
    attribute.includes("user_numeric") ||
    attribute.includes("user_int") ||
    attribute.includes("count") ||
    attribute.includes("year") ||
    attribute.includes("price") ||
    attribute.includes("rate") ||
    attribute.includes("sqft")
  );
};

export function defaultSortedPropertyColumns(
  customColumns: CustomColumn[] | undefined,
): CustomColumn[] {
  let columns = customColumns?.filter((col) => col.table === "property") ?? [];

  Object.values(PropertyColumnKey)
    .filter((key) => !(key == "id" || key === "image_guid"))
    .reverse()
    .forEach((key) => {
      if (
        !key.includes("user_") &&
        !customColumns?.some((col) => col.columnKey === key)
      ) {
        //if the fixed fields don't exist in the `customColumns` array; then we need to add them
        columns.unshift({
          columnKey: key,
          dataType: getColumnDataType(key),
          name: null,
          table: "property",
          hidden: false,
        });
      }
    });
  return columns;
}

export const getCustomColumnName = (col: CustomColumn): string =>
  col.name ??
  PropertyColumnDefaultTitle[
    col.columnKey as keyof typeof PropertyColumnDefaultTitle
  ];

export const getColumnDataType = (key: string): CustomColumnType => {
  if (key.includes("user_date")) {
    return "date";
  } else if (key.includes("user_int")) {
    return "year";
  } else if (key.includes("user_money")) {
    return "dollar";
  } else if (key.includes("user_text")) {
    return "text";
  } else if (key.includes("user_decimal")) {
    return "percentage";
  } else if (key.includes("user_numeric")) {
    return "number";
  } else if (key.includes("user_bool")) {
    return "bool";
  }

  switch (key) {
    case "is_comparable":
      return "bool";
    case "unit_count":
    case "total_sqft":
    case "rentable_sqft":
    case "parcel_size":
    case "floor_count":
    case "building_count":
    case "elevator_count":
    case "parking_count":
    case "commercial_unit_count":
    case "latitude":
    case "longitude":
      return "number";
    case "year_built":
    case "year_updated":
    case "term":
    case "interest_only_period":
    case "amortization":
      return "year";
    case "capitalization_rate":
    case "interest_rate":
      return "percentage";
    case "purchase_date":
    case "origination_date":
      return "date";
    case "purchase_price":
    case "loan_amount":
      return "dollar";
    default:
      return "text";
  }
};

const NonDigitRE = /[^0-9.]/g;

export const convertColumnValue = (
  value: string | number | boolean | Date | undefined,
  type: CustomColumnType,
): string | number | boolean | null => {
  if (value == null) return null;

  switch (type) {
    case "date":
      if (value instanceof Date) return value.toISOString();
      if (typeof value === "string") {
        try {
          return new Date(value).toISOString();
        } catch (e) {
          return null;
        }
      } else return null;

    case "year":
      return typeof value === "string"
        ? parseInt(value.replace(NonDigitRE, ""), 10)
        : typeof value === "bigint" || typeof value === "number"
          ? value
          : null;

    case "dollar":
    case "number":
      return typeof value === "string"
        ? parseFloat(value.replace(NonDigitRE, ""))
        : typeof value === "bigint" || typeof value === "number"
          ? value
          : null;

    case "percentage":
      return typeof value === "string"
        ? parseFloat(value.replace(NonDigitRE, "")) / 100
        : typeof value === "bigint" || typeof value === "number"
          ? value
          : null;

    case "bool":
      return typeof value === "string"
        ? value.toLowerCase() === "true"
        : typeof value === "boolean"
          ? value
          : null;

    case "text":
      return typeof value === "string" ? value : null;

    default:
      return null;
  }
};
