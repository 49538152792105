import React, { useMemo } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";

import { CountOfTotal } from "../../../services/dataService";
import { GoalType, KnownInsight } from "../../../services/insightsService";
import { yetAnotherRatioToNumber } from "../../../utils/number";

interface GaugeProps {
  insight: KnownInsight;
  goalType: GoalType;
  value: CountOfTotal;
  target: CountOfTotal;
}

const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;
  const isPercentage = payload.payload.primitiveUnit === "Percent";
  const goodBound = payload.payload.goodBound + (isPercentage ? `%` : "");
  const comparator = payload.payload.goalType === "min" ? ">" : "<";
  const goal = `Goal: ${comparator} ${goodBound}`;

  const displayValue = payload.payload.currentValue + (isPercentage ? `%` : "");

  return (
    <g>
      <text
        style={{ fontSize: "25px" }}
        className="text-value"
        x={cx}
        y={cy}
        dy={-5}
        textAnchor="middle"
        fill={fill}
      >
        {displayValue}
      </text>
      <text
        className="text-name"
        x={cx}
        y={cy}
        dy={30}
        textAnchor="middle"
        fill="#333"
      >
        {goal}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cornerRadius={50}
        stroke="none"
      />
      <line
        x1={cx}
        y1={cy - innerRadius}
        x2={cx}
        y2={cy - outerRadius}
        stroke="#00000030"
        strokeWidth={4}
      />
    </g>
  );
};

const Gauge: React.FC<GaugeProps> = ({ insight, goalType, value, target }) => {
  const currentValue = useMemo(
    () => yetAnotherRatioToNumber(value, insight.primitiveUnit),
    [value, insight.primitiveUnit],
  );
  const goodBound = useMemo(
    () => yetAnotherRatioToNumber(target, insight.primitiveUnit),
    [target, insight.primitiveUnit],
  );

  const statusColor = useMemo(() => {
    const goodColor = "#1BC5BD";
    const badColor = "#F64E60";
    const noDataColor = "#333";
    if (
      currentValue == null ||
      goodBound == null ||
      Number.isNaN(currentValue)
    ) {
      return noDataColor;
    }
    if (goalType === "min") {
      if (currentValue >= goodBound) {
        return goodColor;
      } else {
        return badColor;
      }
    } else {
      if (currentValue < goodBound) {
        return goodColor;
      } else {
        return badColor;
      }
    }
  }, [currentValue, goodBound, goalType]);

  const data = useMemo(() => {
    const base = {
      goalType,
      goodBound,
      currentValue,
      primitiveUnit: insight.primitiveUnit,
    };
    if (
      currentValue == null ||
      goodBound == null ||
      Number.isNaN(currentValue)
    ) {
      return [
        { ...base, name: "Active", value: 0 },
        { ...base, name: "Total", value: 100 },
      ];
    }
    if (goalType === "min") {
      if (currentValue > goodBound * 2) {
        return [
          { ...base, name: "Active", value: 100 },
          { ...base, name: "Total", value: 0 },
        ];
      } else {
        const total = goodBound * 2 - Math.max(0, currentValue);
        return [
          { ...base, name: "Active", value: Math.max(0, currentValue) },
          { ...base, name: "Total", value: total },
        ];
      }
    } else {
      if (currentValue > goodBound * 2) {
        return [
          { ...base, name: "Active", value: 100 },
          { ...base, name: "Total", value: 0 },
        ];
      } else {
        const total = goodBound * 2 - Math.max(0, currentValue);
        return [
          { ...base, name: "Active", value: Math.max(0, currentValue) },
          { ...base, name: "Total", value: total },
        ];
      }
    }
  }, [goalType, currentValue, goodBound, insight.primitiveUnit]);

  const dataDummy = useMemo(() => {
    const value = data.reduce((prev, current) => {
      prev = prev + current.value;
      return prev;
    }, 0);
    return [{ name: "", value: value }];
  }, [data]);

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={dataDummy}
          dataKey="value"
          startAngle={225}
          endAngle={-45}
          innerRadius="90%"
          outerRadius="100%"
          fill="#EBEDF3"
          stroke="#EBEDF3"
          cornerRadius={50}
        />
        <Pie
          activeIndex={0}
          activeShape={renderActiveShape}
          data={data}
          startAngle={225}
          endAngle={-45}
          innerRadius="90%"
          outerRadius="100%"
          fill="none"
          cornerRadius={50}
          stroke="none"
          dataKey="value"
        >
          <Cell fill={statusColor} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Gauge;
