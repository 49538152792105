import { FinanceBook, PkEntity } from "../dao";

export type AccountTreeEntity = PkEntity & {
  tree_id: number;
  system_id: number;
  tree_code: string | null;
  tree_name: string | null;
  gl_account_format_mask: string | null; //  ????-????, ?, ???????, ????.???? etc
};

export type NewAccountTree = Omit<AccountTreeEntity, "id" | "tree_id">;

export type AccountTreeDto = NewAccountTree & {
  structure: string; // base-64 encoded CSV | XLS
};

export type AccountTreeNodeEntity = PkEntity & {
  system_id: number;
  tree_id: number;
  tree_code: string | null; // redundant copy
  tree_node_id: number;
  tree_node_code: string | null;
  tree_node_name: string | null;
  tree_node_total_into_id: number | null; // Yardi tends to use 0 as the apex tree_node_total_into_id
  reverse_value: boolean | null; // may mean actually consider values to be negative
  negate: boolean | null; // "Print format: flip the sign"
};

export type NewAccountTreeNode = Omit<
  AccountTreeNodeEntity,
  "id" | "tree_node_id"
>;

export type AccountTreeNodeAccountEntity = PkEntity & {
  system_id: number;
  tree_id: number;
  tree_code: string | null; // redundant copy
  tree_node_id: number;
  tree_node_code: string | null; // redundant copy
  tree_node_name: string | null; // redundant copy
  tree_node_total_into_id: number | null; // redundant copy
  tree_node_account_id: number;
  gl_account_id: number;
  gl_account_code: string | null; // redundant copy
};

export type NewAccountTreeNodeAccount = Omit<
  AccountTreeNodeAccountEntity,
  "id" | "tree_node_account_id"
>;

export type GlAccountEntity = PkEntity & {
  system_id: number;
  gl_account_id: number;
  gl_account_code: string | null;
  gl_account_name: string | null;
  normal_balance: "Debit" | "Credit" | null; // Debit | Credit
  gl_account_type: "Regular" | "Total" | "Header" | null; // Regular | Total | Header
  report_type: "IncomeStatement" | "BalanceSheet" | null; // IncomeStatement | BalanceSheet
};

export type NewGlAccount = Omit<GlAccountEntity, "id" | "gl_account_id">;

export type FullAccountTreeAccount = AccountTreeNodeAccountEntity &
  GlAccountEntity;

export type FullAccountTreeNode = AccountTreeNodeEntity & {
  children: FullAccountTreeNode[];
  accounts: FullAccountTreeAccount[];
};

export const FinanceInsightId_Accrual = FinanceBook.Accrual;
export const FinanceInsightId_Budget = FinanceBook.Budget;
export const FinanceInsightId_ProForma = FinanceBook.ProForma;
export const FinanceInsightId_Cash = FinanceBook.Cash;
