import { isPropertiesById } from "@joyhub-integration/shared";
import React, { useContext } from "react";

import { InsightCustomComponentProps } from "../services/models";
import { Property } from "../services/propertiesService";
import PlatformContext from "./app/PlatformContext";

const flump = (...as: string[]) => as.filter((a) => !!a);

const addressOf = (property: Property) =>
  flump(
    property.address ?? "",
    property.city ?? "",
    flump(property.state ?? "", property.zip_code ?? "").join(" "),
  ).join(", ");

const Row: React.FC<{ label: string; value?: string }> = ({ label, value }) =>
  !value ? null : (
    <div>
      <span style={{ fontWeight: 500 }}>{label}:</span>{" "}
      <span style={{ fontWeight: 300, color: "#333" }}>{value}</span>
    </div>
  );

const PropertyInformation: React.FC<InsightCustomComponentProps> = ({
  selection,
}) => {
  const solePropertyId = isPropertiesById(selection) ? selection : undefined;
  const { platform } = useContext(PlatformContext);
  const property =
    solePropertyId == null
      ? undefined
      : platform?.propertiesMap[solePropertyId.toString()];
  return property == null ? null : (
    <div className="overflow-auto w-100 h-100">
      <Row label="Property Code" value={property.property_code} />
      <Row label="Name" value={property.property_name} />
      <Row label="Address" value={addressOf(property)} />
      <Row label="Tags" value={property.tags.join(", ")} />
      {Object.keys(property.metadata)
        .sort()
        .map((key) => (
          <Row
            key={key}
            label={key}
            value={property.metadata[key].toString()}
          />
        ))}
      <Row label="Integration" value={property.system_name} />
    </div>
  );
};

export default PropertyInformation;
