export type PartialRecord<K extends string, T> = Partial<Record<K, T>>;

export const groupById = <A extends { id: number }>(
  as: A[],
): Record<number, A> => {
  const aMap: Record<number, A> = {};
  for (const a of as) {
    aMap[a.id] = a;
  }
  return aMap;
};
