export type AllProperties = Record<string, never>;

export type PropertyId = number;

export type PropertiesByIds = {
  ids: PropertyId[];
};

// `group` is a database id of a stored property group
export type PropertiesByGroup = {
  group: number;
};

type ColumnFilter = Record<string, ColumnValue[]>;

export type PropertiesByColumn = {
  columns: ColumnFilter;
};

// unsupported but legacy data exist in property groups, reports and roles
export type PropertiesByMetadata = {
  metadata: {};
};

export type ColumnValue =
  | string
  | boolean
  | NameExpression
  | RangeExpression
  | DateExpression
  | PeriodExpression;

export type NameExpression = ColumnExpression & {
  name: "user.name" | "user.email";
};

export function isNameExpression(m: ColumnValue): m is NameExpression {
  return typeof m !== "string" && typeof m !== "boolean" && "name" in m;
}

export type RangeExpression = ColumnExpression & {
  min?: number;
  max?: number;
};

export function isRangeExpression(m: ColumnValue): m is RangeExpression {
  return (
    typeof m !== "string" &&
    typeof m !== "boolean" &&
    ("min" in m || "max" in m)
  );
}

export type DateExpression = ColumnExpression & {
  before?: string;
  after?: string;
};

export function isDateExpression(m?: ColumnValue): m is DateExpression {
  return (
    !!m &&
    typeof m !== "string" &&
    typeof m !== "boolean" &&
    ("before" in m || "after" in m)
  );
}

export type DatePeriod = "1mo" | "3mo" | "6mo" | "1yr";

export type PeriodExpression = ColumnExpression & {
  period: DatePeriod;
};

export function isPeriodExpression(m?: ColumnValue): m is PeriodExpression {
  return !!m && typeof m != "string" && typeof m !== "boolean" && "period" in m;
}

export type ColumnExpression = {
  type: "expression";
};

export type NonGroupPropertiesSelection =
  | PropertyId
  | AllProperties
  | PropertiesByIds
  | PropertiesByColumn
  | PropertiesByMetadata;

export type PropertiesCmp = AllProperties | PropertyId | PropertiesByGroup;

export type PropertiesComparison = {
  comparison: Array<PropertiesCmp>;
  vs?: PropertiesCmp;
};

export type PropertiesSelection =
  | NonGroupPropertiesSelection
  | PropertiesByGroup
  | PropertiesComparison;

export const isAllProperties = (
  selection: PropertiesSelection | ResolvedPropertiesSelection | undefined,
): selection is AllProperties =>
  selection != null &&
  typeof selection === "object" &&
  !Object.getOwnPropertyNames(selection).length;

export const isPropertiesById = (
  selection: PropertiesSelection | ResolvedPropertiesSelection | undefined,
): selection is PropertyId => typeof selection === "number";

export const isPropertiesByIds = (
  selection: PropertiesSelection | ResolvedPropertiesSelection | undefined,
): selection is PropertiesByIds => (selection as any)?.ids != null;

export const isPropertiesByIdOrIds = (
  selection: PropertiesSelection | ResolvedPropertiesSelection | undefined,
): selection is PropertyId | PropertiesByIds =>
  isPropertiesById(selection) || isPropertiesByIds(selection);

export const asPropertyIds = (
  selection: PropertyId | PropertiesByIds,
): PropertyId[] => (isPropertiesById(selection) ? [selection] : selection.ids);

export const isPropertiesByGroup = (
  selection: PropertiesSelection | undefined,
): selection is PropertiesByGroup => (selection as any)?.group != null;

export const isPropertiesByColumns = (
  selection: PropertiesSelection | ResolvedPropertiesSelection | undefined,
): selection is PropertiesByColumn => (selection as any)?.columns != null;

export const isPropertiesByMetadata = (
  selection: PropertiesSelection | ResolvedPropertiesSelection | undefined,
): selection is PropertiesByMetadata => (selection as any)?.metadata != null;

export const isPropertiesComparison = (
  selection: PropertiesSelection | undefined,
): selection is PropertiesComparison => (selection as any)?.comparison != null;

export type ResolvedPropertiesByGroup = {
  group: { id: number; name: string; selection: NonGroupPropertiesSelection };
};

export type ResolvedPropertiesCmp =
  | AllProperties
  | PropertyId
  | ResolvedPropertiesByGroup;

export type ResolvedPropertiesComparison = {
  comparison: Array<ResolvedPropertiesCmp>;
  vs?: ResolvedPropertiesCmp;
};

export type ResolvedPropertiesSelection =
  | NonGroupPropertiesSelection
  | ResolvedPropertiesByGroup
  | ResolvedPropertiesComparison;

export const isResolvedPropertiesByGroup = (
  selection: ResolvedPropertiesSelection | undefined,
): selection is ResolvedPropertiesByGroup => (selection as any)?.group != null;

export const isResolvedPropertiesComparison = (
  selection: ResolvedPropertiesSelection | undefined,
): selection is ResolvedPropertiesComparison =>
  (selection as any)?.comparison != null;
