export type AllInsights = Record<string, never>;

export type InsightsByIds = {
  ids: number[];
};

export type InsightsSelection = AllInsights | InsightsByIds;

export const isAllInsights = (
  selection: InsightsSelection,
): selection is AllInsights => !Object.getOwnPropertyNames(selection).length;

export const isInsightsByIds = (
  selection: InsightsSelection,
): selection is InsightsByIds => (selection as any).ids != null;
