export function apostrophize(str: string) {
  if (str.endsWith("s")) {
    return str + "'";
  } else {
    return str + "'s";
  }
}

function capitalizeWord(word: string): string {
  if (!word) return "";
  if (word.length === 0) return word.toUpperCase();
  const lowercase = word.toLocaleLowerCase();
  const first = lowercase.charAt(0).toUpperCase();
  const rest = lowercase.substring(1);
  return first + rest;
}

export function capitalize(str: string): string {
  if (!str) return "";
  return str.split(" ").map(capitalizeWord).join(" ");
}

export const equalsIgnoreCase = (a: string, b: string) =>
  a.toLowerCase() === b.toLowerCase();

export const isString = (a: any): a is string => typeof a === "string";
