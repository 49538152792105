import { find } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Input } from "reactstrap";

import { usePropertyNote } from "../../../../hooks/usePropertyNote";
import { PropertyNoteInsightId } from "../../../../services/insightLibrary/insightDefinitions";
import insightLibraryService from "../../../../services/insightLibrary/insightsLibraryService";
import { ValidationError } from "../../../admin/integrations/addEditIntegrationModal/common";

interface PropertyNoteCardPropsType {
  dashboardInsight?: Record<string, any>;
  validationError: ValidationError;
  onClose: () => void;
  onSubmit: () => void;
  onUnexpectedError: (e?: any) => void;
  updateDashboardInsight: (fieldName: string, newData: any) => void;
}

export const PropertyNoteCard = (props: PropertyNoteCardPropsType) => {
  const { dashboardInsight, onUnexpectedError, updateDashboardInsight } = props;

  const { propertyNote } = usePropertyNote();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");

  const updateInsightPropertyNote = useCallback(
    (note: string) => {
      updateDashboardInsight("stuff", {
        propertyNote: note,
      });
    },
    [updateDashboardInsight],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNote(e.target.value);
      updateInsightPropertyNote(e.target.value);
    },
    [setNote, updateInsightPropertyNote],
  );

  useEffect(() => {
    if (dashboardInsight && !isLoaded) {
      updateDashboardInsight("linkedReport", undefined);
      updateDashboardInsight("name", "Property Note");
      setIsLoaded(true);
    }
  }, [dashboardInsight, isLoaded, updateDashboardInsight]);

  useEffect(() => {
    const setupInitialData = async () => {
      try {
        const insights = await insightLibraryService.getInsights();
        const propertyNoteInsight = find(insights, {
          id: PropertyNoteInsightId,
        });

        updateDashboardInsight("insight", propertyNoteInsight);
      } catch (error) {
        onUnexpectedError(error);
      }
    };

    setupInitialData();
  }, [onUnexpectedError, updateDashboardInsight]);

  useEffect(() => {
    setNote(propertyNote);
    updateInsightPropertyNote(propertyNote);
  }, [propertyNote, updateInsightPropertyNote]);

  return (
    <div style={{ padding: 10 }}>
      <div style={{ width: "100%", minHeight: 200 }}>
        <Input
          name="text"
          type="textarea"
          value={note}
          style={{ width: "100%", minHeight: 200 }}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
