import { AxiosResponse } from "axios";
import fileDownload from "js-file-download";

const ContentDispositionHeader = "content-disposition";
const DispositionRE = /^attachment; filename="(.*)"$/; // pretty crude

export const downloadAttachment =
  (defaultFilename: string) => (res: AxiosResponse) => {
    const disposition = res.headers[ContentDispositionHeader] as
      | string
      | undefined;
    const match = disposition?.match(DispositionRE);
    const blob = res.data as Blob;
    const filename = match?.[1] ?? defaultFilename;
    return fileDownload(blob, filename);
  };
