export type DimensionBuckets = {
  [key: string]: {
    [key1: string]: string;
  };
};

const dimensionBuckets: DimensionBuckets = {
  source: {
    "ApartmentData.com": "SEO / Paid Online",
    "ApartmentGuide.com": "SEO / Paid Online",
    "Apartmentguide.com": "SEO / Paid Online",
    "Apartment Guide": "SEO / Paid Online",
    "ApartmentList.com": "ApartmentList.com",
    "Apartmentlist.com": "ApartmentList.com",
    Apartmentlist: "ApartmentList.com",
    ApartmentList: "ApartmentList.com",
    "Apartment List": "ApartmentList.com",
    "ApartmentRatings.com": "SEO / Paid Online",
    Apartmentratings: "SEO / Paid Online",
    ApartmentRatings: "SEO / Paid Online",
    "Apartment Ratings": "SEO / Paid Online",
    "Apartments.com": "Apartments.com / CoStar",
    Billboard: "Drive By / Walk In / Signage",
    "Bing Search": "SEO / Paid Online",
    CheckpointID: "CheckpointID",
    Craigslist: "Craigslist",
    "Craigslist.org": "Craigslist",
    "CoStar (Apts.com, AptFinder.com, etc.)": "Apartments.com / CoStar",
    "Costar Group": "Apartments.com / CoStar",
    "CoStar Group": "Apartments.com / CoStar",
    CoStar: "Apartments.com / CoStar",
    Costar: "Apartments.com / CoStar",
    "Digital Display Ads": "SEO / Paid Online",
    "Direct Mailer/Postcard": "Direct Mail",
    "Display Ads": "Drive By / Walk In / Signage",
    "Drive By/Walk In/Signage": "Drive By / Walk In / Signage",
    "Drive By": "Drive By / Walk In / Signage",
    "Drive by": "Drive By / Walk In / Signage",
    "Drive-By": "Drive By / Walk In / Signage",
    "Drive By/signage": "Drive By / Walk In / Signage",
    "Drive By/Signage": "Drive By / Walk In / Signage",
    "Walk In/drive By": "Drive By / Walk In / Signage",
    "Walk In/Drive By": "Drive By / Walk In / Signage",
    Employee: "Internal Referral",
    Facebook: "Social Media",
    "Facebook.com": "Social Media",
    "Facebook Marketplace Advertisement": "Facebook Paid",
    "Facebook Paid Advertisement": "Facebook Paid",
    "Facebook Property Page": "Social Media",
    "Former Resident": "Resident Referral",
    "Google My Business": "SEO / Paid Online",
    "Google My Business/Maps": "SEO / Paid Online",
    "Google Paid Search": "SEO / Paid Online",
    "Google Paid Search/PPC": "SEO / Paid Online",
    "Google Pay Per Click (ppc)": "SEO / Paid Online",
    "Google Pay Per Click (PPC)": "SEO / Paid Online",
    "Google Search": "Google Search",
    "Google.com": "Google Search",
    Google: "Google Search",
    "Housing Fair": "Local Event",
    "ILoveLeasing.com": "Property Website",
    "Instagram Profile": "Social Media",
    Instagram: "Social Media",
    "Lease Guarantor": "Resident Referral",
    "Local Business": "Paid Referral",
    "Local Business/Outreach/Business referral": "Paid Referral",
    "Local Referral": "Paid Referral",
    "Merchant Referral": "Paid Referral",
    "Live/work In The Area": "Live In Area",
    "Lives/Works in Area": "Live In Area",
    "Live/work in the area": "Live In Area",
    "Locator/Realtor referral": "Paid Referral",
    "Locator/realtor": "Paid Referral",
    "Locator/Realtor": "Paid Referral",
    Locator: "Paid Referral",
    "Military.com": "SEO / Paid Online",
    "Other Site": "Other Property",
    "Paid Social": "SEO / Paid Online",
    Ppc: "SEO / Paid Online",
    PPC: "SEO / Paid Online",
    "Preferred Employer": "Paid Referral",
    "Previous Resident": "Prior Resident",
    "Prior Takeover": "Prior Takeover",
    "Newspaper/Magazine": "Print Advertising",
    "Property Flyer/Brochure": "Print Marketing",
    "Property Website": "Property Website",
    "Rent.com": "SEO / Paid Online",
    "RENTCafe Reach Google Paid": "SEO / Paid Online",
    "RENTCafe.com ILS": "Property Website",
    "Corporate Website": "Property Website",
    Realtor: "Paid Referral",
    Rentgrata: "Resident Referral",
    RentGrata: "Resident Referral",
    "Rent Grata": "Resident Referral",
    "Referral-resident": "Resident Referral",
    "Referral-Resident": "Resident Referral",
    "Referral Company": "Paid Referral",
    "Resident Referral": "Resident Referral",
    "RPM Corporate Website": "Property Website",
    "RPM Employee Referral": "Internal Referral",
    "RangeWater Employee": "Internal Referral",
    "Shopping Nearby": "Drive By / Walk In / Signage",
    Signage: "Drive By / Walk In / Signage",
    "Sister Roscoe Property": "Internal Referral",
    "Transfer Unit": "Transfer on Site",
    Twitter: "Social Media",
    Walkscore: "SEO / Paid Online",
    Yelp: "Yelp",
    "Yelp.com": "Yelp",
    "Zillow Network": "Zillow Network",
    Zillow: "Zillow Network",
    "Zillow.com": "Zillow Network",
    "Zillow Boost": "Zillow Network",
    "Zumper and Padmapper": "SEO / Paid Online",
    Zumper: "SEO / Paid Online",
  },
  priority: {
    "(none)": "Unspecified",
    "": "Unspecified",
  },
};

export default dimensionBuckets;
