import "./properties.css";

import React, { useMemo } from "react";
import { Badge } from "reactstrap";

export type PropertyComparableCellProps = {
  is_comparable: any | undefined;
};

enum CompType {
  comp = "Comp",
  own = "Own",
}

const PropertyComparableCell: React.FC<PropertyComparableCellProps> = ({
  is_comparable,
}) => {
  const compType: CompType | null = useMemo(() => {
    if (!is_comparable) return null;
    return is_comparable === "true" ? CompType.comp : CompType.own;
  }, [is_comparable]);

  const className = useMemo(() => {
    return compType?.toString()?.toLowerCase() ?? "";
  }, [compType]);

  const cellLabel = useMemo(() => {
    if (!compType) return "";
    switch (compType) {
      case CompType.comp:
        return "Comp";
      case CompType.own:
        return "Own";
    }
  }, [compType]);

  return (compType === is_comparable) === undefined ||
    is_comparable === null ||
    is_comparable === "" ? null : (
    <div className="property-badge-container">
      <Badge className={`property-${className}-badge`}>{cellLabel}</Badge>
    </div>
  );
};

export default PropertyComparableCell;
