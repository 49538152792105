import { ReportSheetData } from "@joyhub-integration/shared";
import { Card, CardBody } from "reactstrap";

import { useEffect, useState } from "react";
import {
  insightUrl,
  propertyDashboardUrl,
} from "../../services/dashboardService";
import {
  useInsightsSelectionQueryParam,
  usePropertiesSelectionQueryParam,
} from "../../utils/useQueryParams";
import Unicard from "../unicard/Unicard";
import { GenericReportTable } from "./GenericReportTable";

interface ReportContentProps {
  sheetData: ReportSheetData;
}

export const ReportContent = (props: ReportContentProps) => {
  const [selection] = usePropertiesSelectionQueryParam();
  const [iSelection] = useInsightsSelectionQueryParam();

  const [sheetData, setSheetData] = useState<ReportSheetData>(props.sheetData);

  useEffect(() => {
    if (props.sheetData.type === "InsightData" && iSelection && iSelection.ids)
      setSheetData({
        ...props.sheetData,
        rows: props.sheetData.rows.filter(
          (row) =>
            typeof row.key === "number" && iSelection?.ids.includes(row.key),
        ),
      });
    else setSheetData(props.sheetData);
  }, [props.sheetData, iSelection]);

  return (
    <>
      {sheetData.graph && (
        <Card
          className="jh-chart-wrapper mb-4 border-0 position-sticky"
          style={{
            left: "1.5rem",
            width: "calc(100vw - 3rem - var(--scrollbar-width))",
          }}
        >
          <CardBody style={{ height: 400 }}>
            <Unicard data={sheetData} actions style={{ overflow: "hidden" }} />
          </CardBody>
        </Card>
      )}
      <GenericReportTable
        data={sheetData}
        drillIn={(key) =>
          typeof key !== "number"
            ? undefined
            : props.sheetData.type === "InsightData"
              ? insightUrl(key, selection)
              : propertyDashboardUrl(key)
        }
      />
    </>
  );
};
