import {
  faCloudDownload,
  faCopy,
  faFileDownload,
} from "@fortawesome/pro-light-svg-icons";
import { useContext, useEffect, useMemo, useState } from "react";
import dashboardService, { Dashboard } from "../../services/dashboardService";
import {
  deleteScheduledEmail,
  getScheduledEmail,
  getScheduledEmails,
  ScheduledEmail,
} from "../../services/scheduledEmailsService";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import DeleteModal from "../admin/common/DeleteModal";
import PlatformContext from "../app/PlatformContext";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import EmbedLink from "../craport/EmbedLink";
import ScheduledEmails from "../craport/ScheduledEmails";
import SendScheduleEmail from "../craport/SendScheduleEmail";

interface DashboardButtonsPropsType {
  propertyId?: number;
  dashboard: Dashboard;
  pdfDownloading: boolean;
  onDownloadDefinition: () => void;
  onDownloadPDF: () => void;
  onCopy: () => void;
  onUnexpectedError: (e?: any) => void;
  setAlert: (message: string, success: boolean) => void;
}

export const DashboardButtons = (props: DashboardButtonsPropsType) => {
  const {
    propertyId: basePropertyId,
    onCopy,
    onDownloadDefinition,
    onDownloadPDF,
    pdfDownloading,
    dashboard,
    onUnexpectedError,
    setAlert,
  } = props;

  const platform = useContext(PlatformContext).platform!;
  const { propertiesMap, admin, superAdmin, organization_role, embedInfo } =
    platform;

  const properties = useMemo(
    () => Object.values(propertiesMap).filter((p) => p.front_end),
    [propertiesMap],
  );

  const propertyId =
    embedInfo?.restricted && properties.length === 1
      ? properties[0].id
      : basePropertyId;

  const [selection] = usePropertiesSelectionQueryParam();

  const [viewScheduledEmailsModalOpen, setViewScheduledEmailsModalOpen] =
    useState(false);
  const [scheduledEmails, setScheduledEmails] = useState<ScheduledEmail[]>([]);
  const [scheduledEmailToBeRemoved, setScheduledEmailToBeRemoved] =
    useState<ScheduledEmail>();

  useEffect(() => {
    if (admin && dashboard) {
      getScheduledEmails(dashboard.id, propertyId)
        .then((scheduledEmails) => setScheduledEmails(scheduledEmails))
        .catch(onUnexpectedError);
    }
  }, [admin, dashboard, propertyId, onUnexpectedError]);

  function onScheduledEmailsChange() {
    getScheduledEmails(dashboard.id, propertyId)
      .then(setScheduledEmails)
      .catch(onUnexpectedError);
  }

  function onScheduledEmailRemoveClick(scheduledEmail: ScheduledEmail) {
    setScheduledEmailToBeRemoved(scheduledEmail);
  }

  function onScheduledEmailDeleteSubmit() {
    setAlert("Scheduled email deleted successfully.", true);
    setScheduledEmailToBeRemoved(undefined);
    onScheduledEmailsChange();
  }

  return (
    <>
      {(organization_role == "Administrator" ||
        organization_role == "Editor") && (
        <ButtonWithIcon
          icon={faFileDownload}
          tooltip="Download Dashboard PDF"
          className="jh-action-icon"
          onClick={onDownloadPDF}
          disabled={pdfDownloading}
          id="download-dashboard-pdf"
          style={pdfDownloading ? { cursor: "wait" } : {}}
        />
      )}
      {organization_role == "Administrator" || organization_role == "Editor" ? (
        <SendScheduleEmail
          id={dashboard.id}
          sendEmail={(recipients) =>
            dashboardService.sendDashboard(dashboard.id, {
              properties: selection!,
              recipients,
              propertyId,
            })
          }
          isDashboard
          propertyId={propertyId}
          scheduledEmails={scheduledEmails}
          onScheduledEmailsChange={onScheduledEmailsChange}
          onViewScheduledEmailsClick={() =>
            setViewScheduledEmailsModalOpen(true)
          }
        />
      ) : null}
      {organization_role == "Administrator" ? (
        <EmbedLink
          kind="dashboard"
          identifier={dashboard.identifier}
          propertyId={propertyId}
        />
      ) : null}
      {viewScheduledEmailsModalOpen ? (
        <ScheduledEmails
          onClose={() => setViewScheduledEmailsModalOpen(false)}
          onScheduledEmailsChange={onScheduledEmailsChange}
          scheduledEmails={scheduledEmails}
          onRemoveClick={onScheduledEmailRemoveClick}
        />
      ) : null}
      {superAdmin && (
        <ButtonWithIcon
          icon={faCloudDownload}
          tooltip="Download Definition"
          className="jh-action-icon"
          id="download-definition"
          onClick={onDownloadDefinition}
        />
      )}
      {(organization_role == "Administrator" ||
        organization_role == "Editor") && (
        <ButtonWithIcon
          icon={faCopy}
          tooltip="Copy Dashboard"
          className="jh-action-icon"
          onClick={onCopy}
          id="copy-dashboard"
        />
      )}
      {scheduledEmailToBeRemoved ? (
        <DeleteModal<ScheduledEmail>
          id={scheduledEmailToBeRemoved.id}
          entityName="Scheduled Email"
          identificationKey="id"
          getEntity={getScheduledEmail}
          deleteEntity={deleteScheduledEmail}
          onClose={() => setScheduledEmailToBeRemoved(undefined)}
          onSubmit={onScheduledEmailDeleteSubmit}
        />
      ) : null}
    </>
  );
};
