// Shamefully stolen from the back-end

// is a string nice; prefers "Los Angeles" to "los angeles" or "LOS ANGELES"

const hasUpperAndLowerCase = (a: string) =>
  a.match(/[A-Z]/) && a.match(/[a-z]/);

/**
 * Returns a map from lower-case form to preferred capitalized form. Prefers
 * Los to los or LOS, TX to tx.
 */
export const mapInsensitively = (as: string[]): Record<string, string> => {
  const ma: Record<string, string> = {};
  for (const a of as) {
    const la = a.toLowerCase();
    const existing = ma[la];
    if (existing == null || existing === la || hasUpperAndLowerCase(a))
      ma[la] = a;
  }
  return ma;
};

/**
 * Remove any duplicate values ignoring case. Properly capitalized
 * values are preferred.
 */
export const deduplicateInsensitively = (as: string[]): string[] =>
  Object.values(mapInsensitively(as));
