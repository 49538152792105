import {
  ColumnFormat,
  PropertyClass,
  PropertyRegion,
  PropertyType,
  ReportDataColumn,
  ReportDataValue,
  formatCellDate,
  formatCellValue,
  formatStringValue,
  isPropertyValue,
} from "@joyhub-integration/shared";

import { formatCurrency } from "./currency";

export const formatValue = (
  value: ReportDataValue,
  column: ReportDataColumn,
  format?: ColumnFormat,
) => {
  const numFmt = format?.numFmt ?? column.format?.numFmt;
  let formatted: any;
  if (column.type === "Date") {
    formatted = formatCellDate(
      isPropertyValue(value) ? value.attribute : value,
      numFmt,
    );
  } else if (column.type === "String") {
    let formatted = formatStringValue(value);
    if (column.attribute && formatted && formatted !== "") {
      if (
        column.attribute === "capitalization_rate" ||
        column.attribute === "interest_rate" ||
        column.attribute.includes("user_decimal")
      ) {
        formatted =
          parseFloat(formatStringValue(value) as string).toFixed(2) + "%";
      } else if (
        column.attribute === "purchase_price" ||
        column.attribute === "loan_amount" ||
        column.attribute.includes("user_money")
      ) {
        formatted = formatCurrency(formatStringValue(value) as string);
      }
    }

    if (formatted) {
      switch (column.attribute) {
        case "property_type":
          return PropertyType[formatted as keyof typeof PropertyType] ?? "";
        case "region":
          return PropertyRegion[formatted as keyof typeof PropertyRegion] ?? "";
        case "property_class":
          return PropertyClass[formatted as keyof typeof PropertyClass] ?? "";
        default:
          return formatted;
      }
    }

    return formatted;
  } else {
    formatted = formatCellValue(
      isPropertyValue(value) ? value.attribute : value,
      numFmt,
    );
  }
  return formatted;
};
