import {
  AccountTreeEntity,
  FullAccountTreeNode,
  PureDate,
  formatGlCode,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";

import axios from "../../services/axios";
import { apiUrl, axiosConfig } from "../../utils/api";
import { dateToYMD, toShortMonthAndYear } from "../../utils/date";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import Loading from "../app/Loading";
import { useOnUnexpectedError } from "../common/alert/withAlertModal";
import { BudgetTotalsResponse } from "./types";
import { formatAmount } from "./utils";

interface BudgetTreeViewProps {
  system: number;
  accountTree: AccountTreeEntity;
  treeNodes: FullAccountTreeNode[];
  propertiesLabel: string;
}

export const getMonths = () =>
  Array(12)
    .fill(undefined)
    .map((_, index) => {
      const date = new Date();
      date.setDate(1);
      date.setMonth(index);
      return dateToYMD(date);
    });

const BudgetTreeView: React.FC<BudgetTreeViewProps> = ({
  system,
  accountTree,
  treeNodes,
  propertiesLabel,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [totals, setTotals] = useState<Record<number, Record<string, number>>>(
    {},
  );
  const [selection] = usePropertiesSelectionQueryParam();
  const onUnexpectedError = useOnUnexpectedError();

  const months = useMemo(() => getMonths(), []);
  const year = new Date().getFullYear();

  useEffect(() => {
    setLoaded(false);
    const propertiesParam = encodeURIComponent(JSON.stringify(selection ?? {}));
    axios
      .get<BudgetTotalsResponse>(
        apiUrl(
          `/gl/budget?tree=${accountTree.tree_code}&system=${system}&properties=${propertiesParam}&year=${year}`,
        ),
        axiosConfig,
      )
      .then((res) => res.data.budgets)
      .then((budgets) => {
        const treeBudget: Record<string, number> = {}; // `{tree_id}:{ymd}` -> number
        for (const budget of budgets) {
          const { tree_node_id, budget_month, budget_amount } = budget;
          treeBudget[`${tree_node_id}:${budget_month}`] = budget_amount;
        }
        const dollars: Record<number, Record<string, number>> = {}; // tree_node-id -> (ymd|"Total") -> number
        const computeTotal = (
          node: FullAccountTreeNode,
          month: string,
        ): number | undefined => {
          let total: number | undefined = undefined;
          node.children.forEach((child) => {
            const subtotal = computeTotal(child, month);
            if (subtotal != null) total = (total ?? 0) + subtotal;
          });
          const subtotal = treeBudget[`${node.tree_node_id}:${month}`];
          if (subtotal != null) total = (total ?? 0) + subtotal;
          if (total != null) {
            const monthly =
              dollars[node.tree_node_id] ?? (dollars[node.tree_node_id] = {});
            monthly[month] = total;
            monthly["Total"] = (monthly["Total"] ?? 0) + total;
          }
          return total;
        };
        treeNodes.forEach((node) => {
          months.forEach((month) => {
            computeTotal(node, month);
          });
        });
        setTotals(dollars);
        setLoaded(true);
      })
      .catch(onUnexpectedError);
  }, [
    system,
    months,
    accountTree,
    treeNodes,
    year,
    onUnexpectedError,
    selection,
  ]);

  // A tree node usually has one account but sometimes has multiple accounts. The report only
  // displays the tree node, with the tree node code, not the underlying accounts or their codes.
  // Looking at samples this seems reasonable.
  const renderAccountNodes = (
    nodes: FullAccountTreeNode[],
    depth: number,
    parent: number,
  ) => {
    const hasAnything = nodes.some(
      (node) => totals[node.tree_node_id]?.Total !== undefined,
    );
    return (
      hasAnything &&
      nodes.map((node, index) => {
        const monthly = totals[node.tree_node_id];
        const hasTotal = monthly?.Total !== undefined;
        const coalesce = hasTotal ? 0 : undefined; // If the row has a total, coalesce values to 0
        // This works because Header/Total rows are associated with just a single account row
        const accountType = node.accounts[0]?.gl_account_type;
        const isTotalRow = accountType === "Total";
        const isHeaderRow = accountType === "Header";
        // Different systems indent differently, this matches a Moss document but not Roscoe...
        const indent = !isTotalRow && !isHeaderRow;
        return (
          <React.Fragment key={node.tree_node_id}>
            {renderAccountNodes(node.children, 1 + depth, node.tree_node_id)}
            {isHeaderRow || hasTotal ? (
              <tr className={clsx({ "total-row": isTotalRow })}>
                <th>
                  {formatGlCode(
                    node.tree_node_code,
                    accountTree.gl_account_format_mask,
                  )}
                </th>
                <th className={`${indent ? "indented" : ""}`}>
                  {node.tree_node_name}
                </th>
                {months.map((month, i) => (
                  <td key={i}>{formatAmount(monthly?.[month] ?? coalesce)}</td>
                ))}
                <td>{formatAmount(monthly?.["Total"])}</td>
              </tr>
            ) : null}
          </React.Fragment>
        );
      })
    );
  };

  return !loaded ? (
    <Loading />
  ) : (
    <Row key={accountTree.tree_id}>
      <Col className="account-tree">
        <div className="print-header">
          <div className="print-properties">Properties = {propertiesLabel}</div>
          <div className="print-title">{accountTree.tree_name}</div>
          <div className="print-period">Year = {year}</div>
        </div>
        <div className="finance-table-wrapper jh-max-height">
          <table className="finance-table">
            <thead>
              <tr>
                <th style={{ width: "3%" }} className="br-0" />
                <th style={{ width: "6%" }} className="br-0" />
                {months.map((month, i) => (
                  <th style={{ width: "7%" }} key={i}>
                    {toShortMonthAndYear(new PureDate(month))}
                  </th>
                ))}
                <th style={{ width: "7%" }}>Total</th>
              </tr>
            </thead>
            <tbody>{renderAccountNodes(treeNodes, 0, -1)}</tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
};

export default BudgetTreeView;
