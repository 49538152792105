import { apiUrl, axiosJsonConfig } from "../utils/api";
import axios from "./axios";
import { BaseOrganization } from "./usersService";

export type EmailInfo = {
  emailAddress: {
    email: string;
    opt_out: boolean;
  };
  organization: BaseOrganization;
  report: boolean;
  subscribed?: boolean;
};

export type EmailAddresses = {
  emailAddresses: string[];
};

export async function getEmailAutocomplete(
  prefix: string,
): Promise<EmailAddresses> {
  return axios
    .get(
      apiUrl(`/emails/autocomplete?email=${encodeURIComponent(prefix)}`),
      axiosJsonConfig,
    )
    .then((res) => res.data as EmailAddresses);
}

export async function getEmailInfo(guid: string): Promise<EmailInfo> {
  return axios
    .get(apiUrl(`/emails/${guid}`), axiosJsonConfig)
    .then((res) => res.data as EmailInfo);
}

/** Unsubscribe from all emails. */
export async function setEmailOptOut(
  guid: string,
  optOut: boolean,
): Promise<void> {
  return axios.put(apiUrl(`/emails/${guid}/opt_out`), optOut, axiosJsonConfig);
}

/** Unsubscribe from this report. */
export async function setEmailUnsubscribed(
  guid: string,
  unsubscribe: boolean,
): Promise<void> {
  return axios.put(
    apiUrl(`/emails/${guid}/unsubscribe`),
    unsubscribe,
    axiosJsonConfig,
  );
}
