import {
  AverageTradeOutNewAndRenewedLeasesLast30Days,
  AverageTradeOutNewSignsLast30Days,
  AverageTradeOutRenewalsLast30Days,
  EffectiveRentNewAndRenewedLeasesLast30Days,
  EffectiveRentNewSignsLast30Days,
  EffectiveRentPerSqftNewAndRenewedLeasesLast30Days,
  EffectiveRentPerSqftNewSignsLast30Days,
  EffectiveRentPerSqftRenewalsLast30Days,
  EffectiveRentRenewalsLast30Days,
  NewAndRenewedLeasesLast30Days,
  NewSignsLast30Days,
  PercentTradeOutNewAndRenewedLeasesLast30Days,
  PercentTradeOutNewSignsLast30Days,
  PercentTradeOutRenewalsLast30Days,
  RenewalsLast30Days,
  TermsNewAndRenewedLeasesLast30Days,
  TermsNewSignsLast30Days,
  TermsRenewalsLast30Days,
} from "../../../services/insightLibrary/backendInsightIds";
import { JhTableCol } from "../../common/table/JhTable";
import { RowDefinition } from "../getData";

export const newLeasesColumns: JhTableCol[] = [
  {
    key: "totalLeasesInPlace",
    display: "Total Leases in Place",
  },
  {
    key: "averageEffectiveRent",
    display: "Average Effective Rent",
  },
  {
    key: "averageEffectiveRentPSF",
    display: "Average Effective Rent PSF",
  },
  {
    key: "averageLeaseTerm",
    display: "Average Lease Term",
  },
  {
    key: "tradeOutDollar",
    display: "Trade Out +$",
  },
  {
    key: "tradeOutPercent",
    display: "Trade Out +%",
  },
];

export const newLeasesRowDefinitions: RowDefinition[] = [
  {
    header: "Total Trade Outs",
    insightIds: [
      {
        insightIds: [NewAndRenewedLeasesLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentNewAndRenewedLeasesLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentPerSqftNewAndRenewedLeasesLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [TermsNewAndRenewedLeasesLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [AverageTradeOutNewAndRenewedLeasesLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [PercentTradeOutNewAndRenewedLeasesLast30Days],
        calculationType: "PERCENTAGE",
      },
    ],
  },
  {
    header: "New",
    insightIds: [
      {
        insightIds: [NewSignsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentNewSignsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentPerSqftNewSignsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [TermsNewSignsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [AverageTradeOutNewSignsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [PercentTradeOutNewSignsLast30Days],
        calculationType: "PERCENTAGE",
      },
    ],
  },
  {
    header: "Renewals",
    insightIds: [
      {
        insightIds: [RenewalsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentRenewalsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [EffectiveRentPerSqftRenewalsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [TermsRenewalsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [AverageTradeOutRenewalsLast30Days],
        calculationType: "NUMBER",
      },
      {
        insightIds: [PercentTradeOutRenewalsLast30Days],
        calculationType: "PERCENTAGE",
      },
    ],
  },
];
