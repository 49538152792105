import { PureDate } from "@joyhub-integration/shared";
import { useEffect, useMemo, useState } from "react";
import { InsightCustomComponentProps } from "../../services/models";
import { RenderedResult, renderReport } from "../craport/Craport";

const EmailInsightCard: React.FC<InsightCustomComponentProps> = (props) => {
  const { dashboardInsight, selection } = props;

  const [rendered, setRendered] = useState<RenderedResult>();

  const reportId = dashboardInsight?.linkedReport;

  const viewConfiguration = useMemo(() => {
    const asOf = PureDate.of(new Date() ?? undefined);
    return selection == null ? undefined : { properties: selection, asOf };
  }, [selection]);

  useEffect(() => {
    setRendered(undefined);
    if (reportId != null && viewConfiguration != null)
      renderReport(reportId, viewConfiguration).then(setRendered).catch();
  }, [viewConfiguration, reportId]);

  return (
    <div className="w-100 h-100 d-flex flex-col">
      <div
        className="w-100 h-100"
        dangerouslySetInnerHTML={{
          __html: rendered ? rendered.body : `<div></div>`,
        }}
      />
    </div>
  );
};

export default EmailInsightCard;
