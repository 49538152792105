import { PickType } from "@joyhub-integration/shared";
import { orderBy } from "natural-orderby";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

interface FilteringPickerProps<A> {
  options: A[];
  label: keyof PickType<A, string>;
  selection: number[];
  setSelection: (selection: number[]) => void;
  filterOn?: (keyof PickType<A, string>)[];
  maxHeight?: string;
}

function FilteringPicker<A extends { id: number }>(
  props: FilteringPickerProps<A>,
) {
  const [filterText, setFilterText] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(new Set<number>());

  useEffect(
    () => setSelectedOptions(new Set(props.selection)),
    [props.selection],
  );

  const sortedOptions = useMemo(
    () => orderBy(props.options, (o) => o[props.label], "asc"),
    [props.options, props.label],
  );

  const filterMatches = useCallback(
    (s: string) => s.toLowerCase().includes(filterText.toLowerCase()),
    [filterText],
  );

  const filteredOptions = useMemo(
    () =>
      sortedOptions.filter((option) => {
        const filterOn = props.filterOn || [props.label];
        return filterOn.some((keyOfA) =>
          filterMatches(
            option[keyOfA] as unknown as string /* too clever by half */,
          ),
        );
      }),
    [sortedOptions, filterMatches, props.filterOn, props.label],
  );

  const allSelected = useMemo(
    () =>
      filteredOptions.length !== 0 &&
      selectedOptions.size === filteredOptions.length,
    [selectedOptions, filteredOptions],
  );

  const handleAllSelectedChange = () => {
    saveSelection(
      new Set(!allSelected ? filteredOptions.map((o) => o.id) : []),
    );
  };

  const handleOptionCheckChange = (id: number) => {
    const newSet = new Set(selectedOptions);
    if (!newSet.delete(id)) {
      newSet.add(id);
    }
    saveSelection(newSet);
  };

  const saveSelection = (set: Set<number>) => {
    setSelectedOptions(set);
    props.setSelection(Array.from(set));
  };

  return (
    <>
      <FormGroup>
        <Input
          type="text"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          placeholder="Filter"
        />
      </FormGroup>
      <div className="d-flex justify-content-between">
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checkex={allSelected}
              onChange={handleAllSelectedChange}
            />
            Select all
          </Label>
        </FormGroup>
        <div>{selectedOptions.size} selected</div>
      </div>
      <hr />
      <div
        style={{ maxHeight: props.maxHeight || "10vh", overflowY: "scroll" }}
      >
        {!filteredOptions.length && (
          <div>{sortedOptions.length ? "No matches" : "No options"}</div>
        )}

        {filteredOptions.map((o) => (
          <div key={o.id}>
            <FormGroup check>
              <Label check>
                <>
                  <Input
                    type="checkbox"
                    checked={selectedOptions.has(o.id)}
                    onChange={() => handleOptionCheckChange(o.id)}
                  />
                  {o[props.label]}
                </>
              </Label>
            </FormGroup>
          </div>
        ))}
      </div>
    </>
  );
}

export default FilteringPicker;
