import React, { useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
  Integration,
  TransferUser,
  resetTransferUserPassword,
} from "../../../services/integrationsService";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface EditTransferUserModalProps {
  integration: Integration;
  transferUser: TransferUser;
  onClose: () => void;
}

const EditTransferUserModal: React.FC<EditTransferUserModalProps> = ({
  integration,
  transferUser: otu,
  onClose,
}) => {
  const [reset, setReset] = useState(false);
  const onUnexpectedError = useOnUnexpectedError();
  const [transferUser, setTransferUser] = useState(otu);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetTransferUserPassword(integration.id, transferUser.id)
      .then((tu) => {
        setTransferUser(tu);
        setReset(true);
      })
      .catch(onUnexpectedError);
  };

  return (
    <UncontrolledModal size="lg" onClosed={onClose} onFormSubmit={onFormSubmit}>
      <ModalHeader toggle={onClose}>
        <div
          className="flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span>
            {reset ? "Password Has Been Reset" : "Reset Transfer User Password"}
          </span>
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <strong>Username:</strong>
          <span style={{ fontFamily: "monospace" }}>
            {" "}
            {transferUser.username}
          </span>
        </div>
        <div>
          <strong>Email:</strong>
          <span style={{ fontFamily: "monospace" }}> {transferUser.email}</span>
        </div>
        {reset ? (
          <>
            <div>
              <strong>Password:</strong>
              <span style={{ fontFamily: "monospace" }}>
                {" "}
                {transferUser.password}
              </span>
            </div>
            <div className="mt-3">
              Please record this information safely, it cannot subsequently be
              retrieved.
            </div>
          </>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          {reset ? "Close" : "Cancel"}
        </Button>
        {!reset ? (
          <Button type="submit" color="primary">
            Reset Password
          </Button>
        ) : null}
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default EditTransferUserModal;
