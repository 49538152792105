import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";

import { TableValues } from "../../../services/calculateInsight";
import CalculateInsightWorker from "../../../services/calculateInsightWorker";
import { InstantInsights } from "../../../services/dataService";
import { Platform } from "../../../services/platformService";
import PlatformContext from "../../app/PlatformContext";
import { BaseTableChartProps, TableChart } from "./TableChart";

interface InstantTableChartProps extends BaseTableChartProps {
  instantDataNow: InstantInsights;
  instantDataAYearAgo: InstantInsights;
}

const InstantTableChart: React.FC<InstantTableChartProps> = ({
  insightId,
  dashboardInsightId,
  instantDataNow,
  instantDataAYearAgo,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const [resolvedTableValues, setResolvedTableValues] = useState<TableValues>();
  const { insightsMap, propertiesMap } = useContext(PlatformContext)
    .platform as Platform;

  useEffect(() => {
    setLoaded(false);
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateTableInstantInsightValuesByProperty(
        insightId,
        instantDataNow.byProperty,
        instantDataAYearAgo.byProperty,
        insightsMap,
        propertiesMap,
      )
      .then((tableValues) => {
        setResolvedTableValues(tableValues);
        setLoaded(true);
      });
  }, [
    insightId,
    instantDataNow,
    instantDataAYearAgo,
    insightsMap,
    propertiesMap,
  ]);

  if (!loaded) {
    return (
      <div className="dashboard-grid-item-loading">
        <Spinner />
      </div>
    );
  }
  return (
    <TableChart
      insightId={insightId}
      dashboardInsightId={dashboardInsightId}
      tableValues={resolvedTableValues!}
      {...rest}
    />
  );
};

export default InstantTableChart;
