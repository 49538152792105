// picking a single value from a dimensioned insight
import { AdHocRule } from "../adHocInsight/adHocRule";
import { InsightId, Unit } from "../insight";

/** A resolved report type contains no references to named insights,
 * they have all been resolved to the target insight definition. */
export type Resolved = "Resolved";

/** A resolved report type may contain references to named insights. */
export type Unresolved = "Unresolved";

/** Resolution status of a report type denoting whether it contains named insights. */
export type Resolution = Resolved | Unresolved; // NoNames|WithNames??

export type InsightName = string;

export type GoalInsight = {
  goal: number;
};

export type DimensionalInsightDefinition = {
  insight: InsightId;
  dimension: string;
  value: string;
};

export type AdditiveInsightDefinition<Res extends Resolution = Unresolved> = {
  addends: readonly ReportInsight<Res>[];
};

export type DifferentialInsightDefinition<Res extends Resolution = Unresolved> =
  {
    minuend: ReportInsight<Res>;
    subtrahends: readonly ReportInsight<Res>[];
  };

export type FractionalInsightDefinition<Res extends Resolution = Unresolved> = {
  numerator: ReportInsight<Res>;
  denominator: ReportInsight<Res>;
};

export type ProductInsightDefinition<Res extends Resolution = Unresolved> = {
  multiplicand: ReportInsight<Res>;
  multipliers: readonly ReportInsight<Res>[];
};

export type DeltaInsightDefinition<Res extends Resolution = Unresolved> = {
  delta: ReportInsight<Res>;
  versus: ReportInsight<Res>;
};

// TODO: kill this, make it a function
// for aggregating a dimensioned insight
export type SummedInsightDefinition = {
  sum: InsightId;
};

export type InsightFunctionName =
  | "numerator"
  | "denominator"
  | "absolute"
  | "negate";

export type FunctionInsightDefinition<Res extends Resolution = Unresolved> = {
  fn: InsightFunctionName;
  parameters: readonly ReportInsight<Res>[];
};

export type ConstantInsightDefinition = {
  constant: number;
};

export type LeaseAttributeDefinition = {
  insight: InsightId;
  attribute: string;
};

export type ReportInsight<Res extends Resolution = Unresolved> =
  | null
  | InsightId
  | (Res extends Resolved ? never : InsightName)
  | (Res extends Resolved ? ManualInsight : never)
  | LeaseAttributeDefinition
  | GoalInsight
  | DimensionalInsightDefinition
  | SummedInsightDefinition
  | FractionalInsightDefinition<Res>
  | DeltaInsightDefinition<Res>
  | DifferentialInsightDefinition<Res>
  | AdditiveInsightDefinition<Res>
  | ProductInsightDefinition<Res>
  | FunctionInsightDefinition<Res>
  | ConstantInsightDefinition;

export const isInsightId = (a: ReportInsight<Resolution>): a is InsightId =>
  typeof a === "number";

export const isInsightName = (a: ReportInsight<Resolution>): a is InsightName =>
  typeof a === "string";

export const isGoalInsight = (a: ReportInsight<Resolution>): a is GoalInsight =>
  a != null && typeof a === "object" && (a as any).goal != null;

export const isManualReportInsight = (
  a: ReportInsight<Resolved>,
): a is ManualInsight =>
  a != null && typeof a === "object" && (a as any).manual;

export const isLeaseAttributeDefinition = (
  a: ReportInsight<Resolution>,
): a is LeaseAttributeDefinition =>
  a != null && typeof a === "object" && (a as any).attribute != null;

export const isDimensionalInsightDefinition = (
  a: ReportInsight<Resolution>,
): a is DimensionalInsightDefinition =>
  a != null &&
  typeof a === "object" &&
  (a as any).dimension != null &&
  (a as any).value != null;

export const isFractionalInsightDefinition = <Res extends Resolution>(
  a: ReportInsight<Res>,
): a is FractionalInsightDefinition<Res> =>
  a != null &&
  typeof a === "object" &&
  (a as any).numerator != null &&
  (a as any).denominator != null;

export const isProductInsightDefinition = <Res extends Resolution>(
  a: ReportInsight<Res>,
): a is ProductInsightDefinition<Res> =>
  a != null &&
  typeof a === "object" &&
  (a as any).multiplicand != null &&
  (a as any).multipliers != null;

export const isDifferentialInsightDefinition = <Res extends Resolution>(
  a: ReportInsight<Res>,
): a is DifferentialInsightDefinition<Res> =>
  a != null &&
  typeof a === "object" &&
  (a as any).minuend != null &&
  (a as any).subtrahends != null;

export const isDeltaInsightDefinition = <Res extends Resolution>(
  a: ReportInsight<Res>,
): a is DeltaInsightDefinition<Res> =>
  a != null &&
  typeof a === "object" &&
  (a as any).delta != null &&
  (a as any).versus != null;

export const isAdditiveInsightDefinition = <Res extends Resolution>(
  a: ReportInsight<Res>,
): a is AdditiveInsightDefinition<Res> =>
  a != null && typeof a === "object" && Array.isArray((a as any).addends);

/** A summed insight is the sum of its dimensioned values. */
export const isSummedInsightDefinition = (
  a: ReportInsight<Resolution>,
): a is SummedInsightDefinition =>
  a != null && typeof a === "object" && (a as any).sum != null;

export const isFunctionInsightDefinition = <Res extends Resolution>(
  a: ReportInsight<Res>,
): a is FunctionInsightDefinition<Res> =>
  a != null &&
  typeof a === "object" &&
  (a as any).fn != null &&
  Array.isArray((a as any).parameters);

export const isConstantInsightDefinition = (
  a: ReportInsight<Resolution>,
): a is ConstantInsightDefinition =>
  a != null && typeof a === "object" && typeof (a as any).constant === "number";

export type DerivedInsightEntityDefinition = {
  insight: ReportInsight<Resolved>;
  numFmt?: string;
};

export type ManualInsightEntityDefinition = {
  dimension?: "unitType" | "bedrooms";
  unit: Unit | { count: Unit; total: Unit };
  grain: "Day" | "Month";
};

export type InsightEntityDefinition =
  | DerivedInsightEntityDefinition
  | ManualInsightEntityDefinition;

export const isDerivedInsightEntityDefinition = (
  d: InsightEntityDefinition,
): d is DerivedInsightEntityDefinition => (d as any).insight != null;

export const isManualInsightEntityDefinition = (
  d: InsightEntityDefinition,
): d is ManualInsightEntityDefinition => (d as any).unit != null;

export type InsightEntity<
  T extends InsightEntityDefinition = InsightEntityDefinition,
> = {
  id: number;
  organization_id: number;
  identifier: string;
  name: string;
  description: string;
  definition: T;
  manual: boolean;

  shared: boolean;
  editor_id: number;
  edited: string | Date;
};

export type AdHocInsightEntity = {
  id: number;
  organization_id: number;
  name: string;
  active: boolean;
  rule: AdHocRule;
  description?: string;
};

export type AdHocInsightHistoryEntity = {
  id: number;
  organization_id: number;
  ad_hoc_insight_id: number;
  import_file_id: number;
  status: boolean;
  output_file_path?: string;
  log?: string;
  created: Date;
};

export type ManualInsight = Pick<
  InsightEntity<ManualInsightEntityDefinition>,
  "id" | "identifier" | "definition" | "manual"
>;

export type InsightDefinitionMap = Record<
  string,
  Pick<InsightEntity, "id" | "identifier" | "definition" | "manual">
>;
