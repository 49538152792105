import "./dashboardInsight.css";

import {
  faCloudDownload,
  faFileDownload,
  faPrint,
} from "@fortawesome/pro-light-svg-icons";
import {
  DashboardKindEnum,
  GenericReportData,
  GenericReportDefinition,
  isPropertiesById,
  PureDate,
} from "@joyhub-integration/shared";
import fileDownload from "js-file-download";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { StringParam, useQueryParam } from "use-query-params";

import dashboardService, {
  Dashboard,
  dashboardInsightUrl,
  propertyDashboardUrl,
} from "../../../services/dashboardService";
import {
  downloadAdHocReportData,
  getAdHocReportData,
  insightReportRequest,
  insightSheet,
  sendAdHocReport,
} from "../../../services/exportService";
import { insightComponents } from "../../../services/insightLibrary/insightComponents";
import definitions from "../../../services/insightLibrary/insightDefinitions";
import {
  OtherDashboardInsight as DashboardInsight,
  Insight,
} from "../../../services/models";
import { toFullMonthAndYear } from "../../../utils/date";
import { downloadAttachment } from "../../../utils/download";
import { usePropertiesSelectionQueryParam } from "../../../utils/useQueryParams";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { useLoadilyFadily } from "../../common/allFadily";
import ButtonWithIcon from "../../common/button/ButtonWithIcon";
import PrintHeader from "../../common/PrintHeader";
import { GenericReportTable } from "../../craport/GenericReportTable";
import SendScheduleEmail from "../../craport/SendScheduleEmail";
import JhCrumbar from "../../navbar/JhCrumbar";

export type DashboardInsightParams = {
  id: string;
  dashboardInsightId: string;
};

const DashboardInsightScreen: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const { id, dashboardInsightId } = useParams<DashboardInsightParams>();
  const [dashboard, setDashboard] = useState<Dashboard>();
  const [dashboardInsight, setDashboardInsight] = useState<DashboardInsight>();
  const [insight, setInsight] = useState<Insight>();
  const [downloading, setDownloading] = useState(false);
  const [chartData, setChartData] = useState<GenericReportData<true>>();
  const [selection] = usePropertiesSelectionQueryParam();
  const [insightDate] = useQueryParam("insightDate", StringParam);
  const platform = useContext(PlatformContext).platform!;
  const { propertiesMap, propertyGroups, admin, superAdmin, organization } =
    platform;

  const insightId = parseInt(id!, 10);
  useEffect(() => {
    if (!dashboardInsight) {
      if (dashboardInsightId == null) {
        const insight = definitions.find((def) => def.id === insightId)!;
        setDashboard({ name: insight.name } as any);
        setDashboardInsight({
          id: 0,
          insightId,
          name: insight.name,
          visualizationType: insight.visualizationType[0],
        });
        setInsight(insight);
      } else {
        dashboardService
          .getDashboardById(insightId)
          .then((d) => {
            setDashboard(d);
            const dashboardInsightIdInt = parseInt(dashboardInsightId, 10);
            const dashboardInsightFound = d.definition.insights.find(
              (di) => di.id === dashboardInsightIdInt,
            );
            if (dashboardInsightFound) {
              setDashboardInsight(dashboardInsightFound);
              setInsight(
                definitions.find(
                  (def) => def.id === dashboardInsightFound.insightId,
                ),
              );
            }
          })
          .catch(onUnexpectedError);
      }
    }
  }, [insightId, dashboardInsightId, onUnexpectedError, dashboardInsight]);

  useEffect(() => {
    if (insight) {
      try {
        window.analytics.track("Dashboard Insight Expanded", {
          insightId: insight.id,
          insightName: insight.name,
          userId: platform.person.id,
        });
      } catch (e) {
        console.error("Unable to fire segment track event", e);
      }
    }
  }, [platform, insight]);

  const reportRequest = useMemo(
    () =>
      insight == null ||
      (insight.customComponent && !insight.customComponent.drillable)
        ? undefined
        : insightReportRequest(
            insight,
            selection ?? {},
            Object.values(propertiesMap),
            propertyGroups,
            PureDate.of(insightDate ?? undefined),
            insightDate != null,
            organization,
          ),
    [
      insight,
      insightDate,
      organization,
      propertiesMap,
      propertyGroups,
      selection,
    ],
  );

  useEffect(() => {
    if (reportRequest != null) {
      setChartData(undefined);
      getAdHocReportData(reportRequest)
        .then((data) => setChartData(data))
        .catch(onUnexpectedError);
    }
  }, [reportRequest, onUnexpectedError]);

  const doDownload = () => {
    if (reportRequest != null) {
      setDownloading(true);
      downloadAdHocReportData(reportRequest)
        .then(downloadAttachment("Insight.xlsx"))
        .catch(onUnexpectedError)
        .finally(() => setDownloading(false));
    }
  };

  const doDownloadDefinition = () => {
    if (insight != null) {
      const definition: GenericReportDefinition = {
        subject: `{organization,name} ${dashboardInsight?.name} {date,MMMM y}`,
        asOf: "Yesterday",
        sheets: [insightSheet(insight, insightDate != null)],
      };
      const blob = new Blob([JSON.stringify(definition, undefined, 2)], {
        type: "application/json ",
      });
      const filename = `${dashboardInsight?.name ?? "Report"}.json`;
      fileDownload(blob, filename);
    }
  };

  const doSend = (recipients: string[]) =>
    reportRequest != null
      ? sendAdHocReport({ ...reportRequest, recipients })
      : Promise.reject();

  const property = useMemo(
    () =>
      selection != null && isPropertiesById(selection)
        ? propertiesMap[selection]
        : undefined,
    [selection, propertiesMap],
  );

  const subtitle = useMemo(() => {
    return dashboard?.kind === DashboardKindEnum.PropertyDashboard
      ? `${property?.property_name} – ${dashboardInsight?.name}`
      : insightDate != null
        ? `${toFullMonthAndYear(PureDate.of(insightDate))} – ${
            dashboardInsight?.name
          }`
        : dashboardInsight?.name;
  }, [dashboard, dashboardInsight, insightDate, property]);

  const drillIn = useMemo(() => {
    const column = chartData?.sheets[0].columns[0];
    if (column != null && dashboard?.kind === DashboardKindEnum.Dashboard) {
      if (column.type === "Date") {
        return (key: any) =>
          key == null
            ? undefined
            : `${dashboardInsightUrl(
                dashboard,
                dashboardInsightId,
                selection,
              )}&insightDate=${key}`;
      } else if (column.type === "String") {
        // bah, dimension column also looks like a property column
        return (key: any) =>
          typeof key !== "number" ? undefined : propertyDashboardUrl(key);
      }
    }
    return undefined;
  }, [dashboard, chartData, selection, dashboardInsightId]);

  const CustomComponent = insight ? insightComponents[insight.id] : undefined;

  const fadily = useLoadilyFadily(!!insight);

  return (
    <div className="jh-page-layout">
      <JhCrumbar
        primary={
          dashboardInsightId == null ? "Insights" : (dashboard?.name ?? "")
        }
        primaryPath={`/dashboards/${dashboard?.identifier}`}
        secondary={subtitle}
        back
      />

      <div className="dashboard-filters">
        <div />
        <div className="flex-row ps-1">
          <ButtonWithIcon
            icon={faPrint}
            tooltip="Print"
            className="jh-action-icon"
            onClick={() => window.print()}
            id="print-insight"
          />
          {!reportRequest ? null : (
            <>
              <ButtonWithIcon
                icon={faFileDownload}
                tooltip="Download"
                className="jh-action-icon"
                onClick={doDownload}
                disabled={downloading}
                id="download-insight"
              />
              {admin && (
                <SendScheduleEmail
                  sendEmail={doSend}
                  scheduledEmails={[]}
                  onScheduledEmailsChange={() => {}}
                  onViewScheduledEmailsClick={() => {}}
                />
              )}
              {superAdmin && (
                <ButtonWithIcon
                  icon={faCloudDownload}
                  tooltip="Download Definition"
                  className="jh-action-icon"
                  onClick={doDownloadDefinition}
                  id="download-definition"
                />
              )}
            </>
          )}
        </div>
      </div>

      <div className="jh-page-content dashboard-insight-main" style={fadily}>
        {dashboardInsight ? (
          <PrintHeader title={dashboardInsight.name} fullDate />
        ) : null}
        {CustomComponent && !insight?.drillInInsights ? (
          <CustomComponent dashboardInsight={dashboardInsight} />
        ) : chartData ? (
          <GenericReportTable data={chartData.sheets[0]} drillIn={drillIn} />
        ) : (
          <div>
            <div
              style={{
                borderBottom: "1px solid black",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
                textShadow: "0 0 10px rgba(0,0,0,0.5)",
                color: "rgba(0, 0, 0, 0)",
              }}
            >
              <div>Lorem Ipsum</div>
              <div>Dolor Sit Amet</div>
              <div>Consectetur Adipiscing Elit</div>
            </div>
            <div
              className="skeleton-main"
              style={{
                height: "600px",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withAlertModal(DashboardInsightScreen);
