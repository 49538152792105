import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { TableValues } from "../../../services/calculateInsight";
import CalculateInsightWorker from "../../../services/calculateInsightWorker";
import { RangedInsights } from "../../../services/dataService";
import definitions from "../../../services/insightLibrary/insightDefinitions";
import { KnownInsight as BackendInsight } from "../../../services/insightsService";
import { Insight } from "../../../services/models";
import { Property } from "../../../services/propertiesService";
import { DelimitedStringArrayParamDeepEqual } from "../../../utils/useQueryParams";
import { BaseTableChartProps, TableChart } from "./TableChart";

interface RangeTableChartProps extends BaseTableChartProps {
  insightsMap: Record<string, BackendInsight>;
  propertiesHash: Record<string, Property>;
  rangeData: RangedInsights;
  rangeDataAYearAgo: RangedInsights;
  yoy?: boolean;
  drillIn?: boolean;
}

const RangeTableChart: React.FC<RangeTableChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  dimensionFilterSelectedProp,
  monthSelectedProp,
  yoy,
  drillIn,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const [resolvedTableValues, setResolvedTableValues] = useState<TableValues>();
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = definitions.find((i) => i.id === insightId) as Insight;
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (dimensionFilterSelectedProp) {
      setDimensionFilterSelected(dimensionFilterSelectedProp, "replaceIn");
    }
  }, [dimensionFilterSelectedProp, setDimensionFilterSelected]);

  useEffect(() => {
    if (monthSelectedProp) {
      setMonthSelected([monthSelectedProp], "replaceIn");
    }
  }, [monthSelectedProp, setMonthSelected]);

  useEffect(() => {
    setLoaded(false);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "TABLE",
        filterByDimension,
        new Set(monthsSelected),
        yoy,
        drillIn,
      )
      .then((tableValuesRes) => {
        setResolvedTableValues(tableValuesRes as TableValues);
        setLoaded(true);
      });
  }, [
    dimensionFilterSelected,
    filterDimension,
    insightId,
    insightsMap,
    monthsSelected,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
    yoy,
    drillIn,
  ]);
  /*useEffect(() => {
    if (resolvedTableValues && showLastNMonths) {
      setMonthSelected(
        resolvedTableValues.monthOptions.slice(
          resolvedTableValues.monthOptions.length - showLastNMonths
        ),
        "replaceIn"
      );
    }
  }, [resolvedTableValues, showLastNMonths, setMonthSelected]);*/

  if (!loaded) {
    return (
      <div className="dashboard-grid-item-loading">
        <Spinner />
      </div>
    );
  }
  return (
    <TableChart
      insightId={insightId}
      dashboardInsightId={dashboardInsightId}
      tableValues={resolvedTableValues!}
      dimensionFilterSelectedProp={dimensionFilterSelectedProp}
      monthSelectedProp={monthSelectedProp}
      {...rest}
    />
  );
};

export default RangeTableChart;
