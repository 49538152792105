import { Line } from "rc-progress";
import { CSSProperties } from "react";

import AnimatedNumber from "./AnimatedNumber";

const colors = {
  danger: "var(--danger-color)",
  success: "var(--success-color)",
  warning: "var(--warning-color)",
  dark: "var(--dark-color)",
};

const renderValue = (
  number: number,
  formatter?: (number: number) => string,
  animated?: boolean,
) =>
  animated ? (
    <AnimatedNumber formatter={formatter}>{number}</AnimatedNumber>
  ) : formatter ? (
    formatter(number)
  ) : (
    number
  );

const Meter = ({
  high,
  target,
  actual,
  positiveGood = true,
  animated = true,
  small = false,
  formatter,
  targetLabel = "Goal",
  style,
}: {
  low: number;
  high: number;
  target?: number;
  actual?: number;
  positiveGood?: boolean;
  animated?: boolean;
  small?: boolean;
  formatter?: (value: number) => string;
  targetLabel?: string;
  style?: CSSProperties;
}) => {
  if (actual == null) return null;
  let color = style?.color;
  if (color === undefined && target != null) {
    color = colors.success;
    const fractionOfTarget = actual / target;
    if (positiveGood) {
      if (fractionOfTarget < 0.5) {
        color = colors.danger;
      } else if (fractionOfTarget < 0.8) {
        color = colors.warning;
      }
    } else {
      if (fractionOfTarget > 1.2) {
        color = colors.danger;
      } else if (fractionOfTarget > 1) {
        color = colors.warning;
      }
    }
  }
  return (
    <>
      <div
        style={{
          fontSize: small ? "1em" : "1.75em",
          fontWeight: small ? undefined : 600,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        className="d-flex justify-content-center mb-2"
      >
        {renderValue(actual, formatter, animated)}
      </div>
      <Line
        percent={(actual * 100) / high}
        strokeWidth={3}
        trailWidth={3}
        strokeColor={color ?? colors.dark}
        className="p-2"
      />
      {!small && (
        <div className="text-center mt-3 text-muted">
          <div style={{ fontWeight: 600 }}>{targetLabel}</div>
          {target == null ? (
            <div className="text-muted">–</div>
          ) : (
            renderValue(target, formatter, animated)
          )}
        </div>
      )}
    </>
  );
};

export default Meter;
