import { PropertyColumnKey } from "./types";

export const CustomColumnType = [
  "bool",
  "date",
  "dollar",
  "number",
  "percentage",
  "text",
  "year",
] as const;

export type CustomColumnType = (typeof CustomColumnType)[number];

export const CustomColumnTableTypes = ["property"] as const;

export type CustomColumn = {
  name: string | null;
  table: (typeof CustomColumnTableTypes)[number];
  dataType: CustomColumnType;
  columnKey: PropertyColumnKey;
  hidden: boolean;
};

export interface CustomColumnWithValue extends CustomColumn {
  propertyId: number;
  value: any | null;
}
