import { InsightCustomComponentProps } from "../../services/models";

type IframeInsightCardConfig = {
  iframe?: {
    url?: string;
    height?: number;
  };
};

const IframeInsightCard: React.FC<
  InsightCustomComponentProps<IframeInsightCardConfig>
> = ({ dashboardInsight }) => {
  const { url, height } = dashboardInsight?.stuff?.iframe!;

  return (
    <div className="w-100 h-100 iframe-card">
      <iframe
        title="iframe"
        src={url ?? ""}
        style={height ? { height: `${height}rem` } : undefined}
        className="w-100"
      />
    </div>
  );
};

export default IframeInsightCard;
