import {
  GoalFetchOptions,
  GoalYear,
  InsightsSelection,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import axios, { AxiosResponse } from "axios";

import {
  apiUrl,
  axiosBlobConfig,
  axiosConfig,
  axiosJsonConfig,
} from "../utils/api";
import { downloadAttachment } from "../utils/download";
import { convertBase64 } from "../utils/file";
import { CountOfTotal, getInstantData, isCountOfTotal } from "./dataService";
import {
  AverageDaysVacant,
  AverageNotReadyDuration,
  ExpenseFactor,
  Occupancy,
  PercentTradeOutNewSignsLast30Days,
  PercentTradeOutRenewalsLast30Days,
  RenewalRateId,
} from "./insightLibrary/backendInsightIds";
import { GoalType, KnownInsight } from "./insightsService";

interface GaugeGoal {
  insightId: number;
  name?: string; //optional override of the InsightDefinition name
}

const goals: GaugeGoal[] = [
  {
    insightId: ExpenseFactor,
  },
  {
    insightId: PercentTradeOutNewSignsLast30Days,
    name: "Trade Out (New Leases)",
  },
  {
    insightId: PercentTradeOutRenewalsLast30Days,
    name: "Trade Out (Renewals)",
  },
  {
    insightId: AverageDaysVacant,
    name: "Days Vacant (Avg)",
  },
  {
    insightId: AverageNotReadyDuration,
    name: "Days to Make Ready (Avg)",
  },
  {
    insightId: Occupancy,
  },
  {
    insightId: RenewalRateId,
  },
];

export interface GoalWithCurrentValue {
  name: string;
  insight: KnownInsight;
  goalType: GoalType;
  target: CountOfTotal;
  currentValue: CountOfTotal;
}

export async function getCurrentValuesForGoals(
  insightsMap: Record<string, KnownInsight>,
  selection: PropertiesSelection,
): Promise<GoalWithCurrentValue[]> {
  return getInstantData(
    goals.map((goal) => goal.insightId),
    selection,
  ).then((instantInsights) => {
    const goalsWithCurrentValue = new Array<GoalWithCurrentValue>();
    goals.forEach((goal) => {
      const insightDef = insightsMap[goal.insightId]!;
      const insightValue = instantInsights.overall[goal.insightId];
      const goalValue = instantInsights.goals.overall[goal.insightId];
      if (
        isCountOfTotal(insightValue) &&
        isCountOfTotal(goalValue) &&
        insightDef.goalType != null
      ) {
        goalsWithCurrentValue.push({
          name: goal.name || insightDef.name,
          insight: insightDef,
          goalType: insightDef.goalType,
          target: goalValue,
          currentValue: insightValue,
        });
      }
    });

    return goalsWithCurrentValue;
  });
}

export async function loadGoals(
  year: number,
  propertySelection: PropertiesSelection,
  insightSelection: InsightsSelection,
  offset: number,
  limit: number,
): Promise<GoalYear[]> {
  const propertiesQueryParam = `&properties=${encodeURIComponent(
    JSON.stringify(propertySelection),
  )}`;
  const insightQueryParam = `&insights=${encodeURIComponent(
    JSON.stringify(insightSelection),
  )}`;
  const queryString = `year=${year}${propertiesQueryParam}${insightQueryParam}&offset=${offset}&limit=${limit}`;
  return axios.get(apiUrl(`/goals?${queryString}`), axiosConfig).then((res) => {
    return res.data as GoalYear[];
  });
}

export async function loadGoalFetchOptions(
  year: number,
): Promise<GoalFetchOptions> {
  return axios
    .get(apiUrl(`/goals/fetchOptions?year=${year}`), axiosConfig)
    .then((res) => res.data as GoalFetchOptions);
}

export async function generateTemplate(
  year: number,
  propertyIds: number[],
  insightIds: number[],
  fileName?: string,
) {
  const data = {
    year,
    propertyIds,
    insightIds,
  };

  return axios
    .post(apiUrl("/goals/template"), data, axiosBlobConfig)
    .then(downloadAttachment(fileName || "Goal Template"));
}

export async function importGoals(file: File): Promise<AxiosResponse> {
  const body = await convertBase64(file);
  return axios.put(apiUrl("/goals"), { body }, axiosJsonConfig);
}
