import { InsightEntity, SystemRole } from "@joyhub-integration/shared";

import propertyGroupService, {
  PropertyGroup,
} from "../components/properties/propertyGroupService";
import { apiUrl, axiosConfig, prId } from "../utils/api";
import { containsIgnoreCase } from "../utils/array";
import { arrayToDict } from "../utils/arrayToDict";
import axios from "./axios";
import {
  KnownInsight,
  getCustomInsights,
  getKnownInsights,
} from "./insightsService";
import { getPlatformUrl } from "./platformUrl";
import { Property, getProperties } from "./propertiesService";
import {
  getSessionOrganization,
  setSessionOrganization,
} from "./sessionOrganization";
import { ApplicationType, Organization, Role, User } from "./usersService";

interface BasePlatform {
  person: User;
  organization: Organization;
  roles: Array<Role>;
  organizations: Array<Organization>;
  admin: boolean;
  superAdmin: boolean;
  overlordDomain: boolean;
  buildDate: string;
  organization_role: SystemRole;
  embedInfo?: {
    kind: string;
    identifier: string;
    propertyId: number | null;
    restricted: boolean | null;
    configuration: Record<string, any> | null;
  };
}

export interface Platform extends BasePlatform {
  propertiesMap: Record<string, Property>;
  insightsMap: Record<string, KnownInsight>;
  customInsights: InsightEntity[];
  propertyGroups: PropertyGroup[];
}

export async function warmDatabase(): Promise<boolean> {
  return axios
    .get(apiUrl("/db/warm"), axiosConfig)
    .then((res) => res.data !== false);
}

async function getBasePlatform() {
  return axios
    .get<BasePlatform>(getPlatformUrl(), axiosConfig)
    .then((res) => res.data);
}

export async function getPlatform(): Promise<Platform> {
  return getBasePlatform().then((basePlatform) => {
    const sessionOrg = getSessionOrganization();
    if (sessionOrg != null && sessionOrg !== basePlatform.organization.id) {
      // On organization mismatch don't try to fetch properties, just return a stub platform
      // for use by the organization change error page.
      return {
        ...basePlatform,
        propertiesMap: {},
        insightsMap: {},
        propertyGroups: [],
        customInsights: [],
      };
    } else {
      setSessionOrganization(basePlatform.organization.id);
      return Promise.all([
        getProperties(),
        getKnownInsights(),
        propertyGroupService.getPropertyGroups(),
        getCustomInsights(),
      ]).then(([properties, insights, propertyGroups, customInsights]) => {
        const propertiesMap = arrayToDict(properties, (p) => p.id);
        const insightsMap = arrayToDict(insights, (i) => i.id);
        const admin =
          basePlatform.organization_role === SystemRole.byName("Administrator");
        const superAdmin =
          admin &&
          !!basePlatform.person.email.match(
            /@(joyhub\.io|lo\.ventures|revolutionre\.com)$/,
          );
        const overlordDomain = containsIgnoreCase(
          basePlatform.organization.domains,
          "joyhub.io",
        );
        return {
          ...basePlatform,
          propertiesMap,
          insightsMap,
          customInsights,
          propertyGroups,
          admin,
          superAdmin,
          overlordDomain,
        };
      });
    }
  });
}

const appEnv: "Local" | "Dev" | "Prod" = process.env
  .REACT_APP_ENVIRONMENT as any;
const isPR = appEnv === "Dev" && prId != null;

export const environment: "Local" | "Dev" | "PR" | "Prod" = isPR
  ? "PR"
  : appEnv;

export const environmentName = isPR ? `#${prId}` : appEnv;

export const applicationName = (application?: ApplicationType) =>
  appEnv !== "Prod"
    ? `${application ?? "Revolution "} ${isPR ? `PR #${prId}` : appEnv}`
    : application === "BI"
      ? "Revolution RE"
      : application === "PC"
        ? "Property Connect"
        : "Revolution RE";
