import { sortBy } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import PlatformContext from "../app/PlatformContext";

const PropertyPicker: React.FC<{
  className?: string;
  right?: boolean;
  theme?: string;
}> = ({ right, className, theme: overrideTheme }) => {
  const { organization, propertiesMap } = useContext(PlatformContext).platform!;
  const [selection] = usePropertiesSelectionQueryParam();
  const [propertyMenuOpen, setPropertyMenuOpen] = useState(false);

  const sortedProperties = useMemo(
    () => sortBy(Object.values(propertiesMap), "property_name"),
    [propertiesMap],
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="flex-row flex-shrink-0 align-items-center">
      <Dropdown
        isOpen={propertyMenuOpen}
        toggle={() => setPropertyMenuOpen(!propertyMenuOpen)}
      >
        <DropdownToggle
          id="property-picker"
          color={
            overrideTheme ?? organization.configuration.navbarTheme ?? "dark"
          }
          className={className}
          caret
          active={propertyMenuOpen}
        >
          {propertiesMap[selection as any]?.property_name ?? "Unknown"}
        </DropdownToggle>
        <DropdownMenu end={right}>
          {sortedProperties.map((property) => (
            <DropdownItem
              key={property.id}
              onClick={() =>
                navigate(
                  `/properties/${property.id}${pathname.replace(/^\/properties\/\d+/, "")}?p=${property.id}`,
                  { replace: true },
                )
              }
            >
              {property.property_name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default PropertyPicker;
