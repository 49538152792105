import clsx from "clsx";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

import TrendArrow from "../visualizations/TrendArrow";

const DashboardCard = ({
  title,
  background = "outer",
  centered = false,
  delta,
  positiveGood = true,
  children,
  controls,
  onClick,
}: {
  title?: string;
  background?: "none" | "outer" | "inner";
  centered?: boolean;
  delta?: number;
  positiveGood?: boolean;
  children: React.ReactNode;
  controls?: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Card
      className="w-100 d-flex mb-4"
      style={{
        background: background === "outer" ? "#fff" : "none",
        border: "none",
        cursor: onClick ? "pointer" : undefined,
      }}
      onClick={onClick}
    >
      <CardBody className={clsx("flex-col", { "p-0": background !== "outer" })}>
        <CardTitle
          tag="h2"
          style={{ fontSize: "1.25em" }}
          className={clsx({ "mb-3": background === "inner" })}
        >
          <div className="d-flex justify-content-between">
            <div
              className={clsx("d-flex w-100", {
                "justify-content-center": centered,
                "justify-content-between": !centered,
              })}
              title={title}
            >
              <div
                className="text-nowrap overflow-hidden"
                style={{ textOverflow: "ellipsis" }}
              >
                {title}
              </div>
              {delta && (
                <TrendArrow delta={delta} positiveGood={positiveGood} />
              )}
            </div>
            {controls}
          </div>
        </CardTitle>
        <div
          className={clsx("flex-col w-100 h-100 justify-content-center", {
            "bg-white p-4 rounded": background === "inner",
          })}
        >
          {children}
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardCard;
