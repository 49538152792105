import React, { useEffect } from "react";
import { Navigate } from "react-router";

// Vestigial component to set the logged out state and redirect to the homepage without
// leaving the loggedOut route in the browser history.

type LoggedOutProps = {
  setLoggedOut: (loggedOut: boolean) => void;
};

const LoggedOut: React.FC<LoggedOutProps> = ({ setLoggedOut }) => {
  useEffect(() => setLoggedOut(true), [setLoggedOut]);
  return <Navigate to="/" replace={true} />;
};

export default LoggedOut;
