import { TagValue } from "../../services/unitMap/unitMapApi";

// TODO: There are also "building" level tags that can be shown and hidden. This
// currently only handles "floor" level tags.
const LevelPicker = ({
  floors,
  resultsPerFloor,
  value,
  onChange,
}: {
  floors?: TagValue[];
  resultsPerFloor?: { [floorId: string]: number };
  value?: string;
  onChange: (floor: number) => void;
}) => (
  <label
    className="py-3 px-4 h-100"
    style={{ width: "10em", backgroundColor: "#F6F8F9" }}
  >
    <div className="mb-2" style={{ fontSize: "1.25em", fontWeight: 600 }}>
      Floor
    </div>
    <select
      onChange={(event) => onChange(Number.parseInt(event.target.value))}
      defaultValue={value}
      size={floors?.length}
      className="w-100"
      style={{
        textAlign: "center",
        border: "none",
        maxHeight: "75%",
      }}
    >
      {floors?.map((floor) => {
        const resultCount = resultsPerFloor?.[floor.value];
        return (
          <option key={floor.value} value={floor.value} className="p-2">
            {floor.name}
            {resultCount && ` (${resultCount})`}
          </option>
        );
      })}
    </select>
  </label>
);

export default LevelPicker;
