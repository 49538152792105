import { SystemRole } from "./systemRole";

type PermissionDefinition = {
  create: SystemRole[];
  read: SystemRole[];
  update: SystemRole[];
  delete: SystemRole[];
  share?: SystemRole[];
};

const dashboards: PermissionDefinition = {
  create: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  read: [
    SystemRole.byName("Administrator"),
    SystemRole.byName("Editor"),
    SystemRole.byName("Viewer"),
  ],
  update: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  delete: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
};

const properties: PermissionDefinition = {
  create: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  delete: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  update: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  read: [
    SystemRole.byName("Administrator"),
    SystemRole.byName("Editor"),
    SystemRole.byName("Viewer"),
  ],
};

const financialImports: PermissionDefinition = {
  create: [SystemRole.byName("Administrator")],
  delete: [SystemRole.byName("Administrator")],
  update: [SystemRole.byName("Administrator")],
  read: [SystemRole.byName("Administrator")],
};

const reports: PermissionDefinition = {
  create: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  delete: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  share: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  update: [SystemRole.byName("Administrator"), SystemRole.byName("Editor")],
  read: [
    SystemRole.byName("Administrator"),
    SystemRole.byName("Editor"),
    SystemRole.byName("Viewer"),
  ],
};

export const permissions = {
  dashboards,
  properties,
  reports,
};

export function checkPermission(
  permission: string,
  action: keyof PermissionDefinition,
  role: SystemRole,
) {
  const permissionDefinition =
    permissions[permission as keyof typeof permissions];
  if (permissionDefinition) {
    const roles = permissionDefinition[action];
    if (roles) {
      return roles.includes(role);
    }
  }
  return false;
}

export function isAdministrator(role: SystemRole) {
  return role === "Administrator";
}
