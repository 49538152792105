import {
  DerivedInsightEntityDefinition,
  ExcelNumberFormatRE,
  InsightDto,
  InsightEntity,
  InsightIdentifierRE,
  PartialRecord,
  ReportInsight,
  Resolved,
} from "@joyhub-integration/shared";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";

import { unexpectedError } from "../../../constants";
import { AllUnitCount } from "../../../services/insightLibrary/backendInsightIds";
import {
  postCustomInsight,
  putCustomInsight,
} from "../../../services/insightsService";
import { equalsIgnoreCase } from "../../../utils/string";
import PlatformContext from "../../app/PlatformContext";
import JhSelect from "../../common/JhSelect/JhSelect";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import { baseNumberFormatOptions, findOption } from "../../craport/editUtil";
import InsightDefinitionEditor from "./InsightDefinitionEditor";

type InsightValidation = PartialRecord<keyof InsightDto, string | undefined>;

type AddEditInsightModalProps = {
  insights?: Array<InsightEntity>;
  insight?: InsightEntity;
  onClose: () => void;
  onSubmit: (insight: InsightEntity) => void;
};

const AddEditDerivedInsightModal: React.FC<AddEditInsightModalProps> = ({
  insights,
  insight,
  onClose,
  onSubmit,
}) => {
  const { superAdmin, organization, overlordDomain } =
    useContext(PlatformContext).platform!;
  const canShare = superAdmin && overlordDomain;
  const [serverError, setServerError] = useState<string>();
  const [validation, setValidation] = useState<InsightValidation>({});
  const [identifier, setIdentifier] = useState<string>(
    insight?.identifier ?? "",
  );
  const [name, setName] = useState<string>(insight?.name ?? "");
  const [description, setDescription] = useState<string>(
    insight?.description ?? "",
  );
  const [shared, setShared] = useState<boolean>(
    (canShare && insight?.shared) || false,
  );
  const [definition, setDefinition] = useState<DerivedInsightEntityDefinition>(
    (insight?.definition as DerivedInsightEntityDefinition) ?? {
      insight: AllUnitCount,
    },
  );
  const [definitionValid, setDefinitionValid] = useState(true);
  const setInsight = useCallback(
    (insight: ReportInsight<Resolved>) =>
      setDefinition((def) => ({
        ...def,
        insight,
      })),
    [setDefinition],
  );

  const validateForm = () => {
    const validity: InsightValidation = {};
    if (!identifier) {
      validity.identifier = "Field is required.";
    } else if (!identifier.match(InsightIdentifierRE)) {
      validity.identifier = "This is not a valid identifier.";
    } else if (
      insights?.find(
        (i) =>
          i.id !== insight?.id && equalsIgnoreCase(i.identifier, identifier),
      )
    ) {
      validity.identifier = "This identifier is already in use.";
    }
    if (!name) {
      validity.name = "Field is required.";
    }
    if (!definitionValid) {
      validity.definition = "This is not a valid definition.";
    }
    setValidation(validity);
    return Object.keys(validity).length === 0;
  };

  useEffect(
    () => setValidation({}),
    [identifier, name, description, shared, definition],
  );

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setServerError(undefined);
    if (validateForm()) {
      const dto: InsightDto = {
        identifier,
        name,
        description,
        definition,
        manual: false,
        shared,
      };
      const promise =
        insight == null || insight.organization_id !== organization.id
          ? postCustomInsight(dto)
          : putCustomInsight(insight.id, dto);
      promise
        .then((insight) => {
          onSubmit(insight);
        })
        .catch((err) => {
          const response = err.response;
          setServerError(
            response?.status === 400 && response.data.message
              ? response.data.message
              : unexpectedError,
          );
        });
    }
  };

  return (
    <UncontrolledModal onClosed={onClose} onFormSubmit={onFormSubmit}>
      <ModalHeader>
        {insight ? `Edit ${insight.name}` : "Add Derived Insight"}
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup row>
          <Label sm={2}>Identifier</Label>
          <Col sm={10}>
            <Input
              type="text"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              invalid={!!validation.identifier}
            />
            {validation.identifier && (
              <FormFeedback>{validation.identifier}</FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Name</Label>
          <Col sm={10}>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              invalid={!!validation.name}
            />
            {validation.name && <FormFeedback>{validation.name}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Description</Label>
          <Col sm={10}>
            <Input
              type="textarea"
              height={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              invalid={!!validation.description}
            />
            {validation.description && (
              <FormFeedback>{validation.description}</FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="number-format">
            Format
          </Label>
          <Col sm={10}>
            <JhSelect
              id="number-format"
              isSearchable={true}
              isClearable={true}
              placeholder="Automatic..."
              isCreatable={true}
              menuPosition="fixed"
              options={baseNumberFormatOptions}
              value={findOption(baseNumberFormatOptions, definition.numFmt)}
              onValueUpdate={(item) =>
                setDefinition({
                  ...definition,
                  numFmt: item?.value || undefined,
                })
              }
              isValidNewOption={(value) => !!value.match(ExcelNumberFormatRE)}
              pointy
            />
          </Col>
        </FormGroup>
        {canShare ? (
          <FormGroup row>
            <Col sm={{ offset: 2, size: 10 }}>
              <div className="form-check">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={shared}
                    onChange={() => setShared(!shared)}
                  />
                  Shared with all organizations
                </Label>
              </div>
            </Col>
          </FormGroup>
        ) : null}
        <FormGroup>
          <InsightDefinitionEditor
            insight={definition.insight}
            setInsight={setInsight}
            setValid={setDefinitionValid}
          />
          <Input type="hidden" invalid={!!validation.definition} />
          {validation.definition && (
            <FormFeedback>{validation.definition}</FormFeedback>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary" className="ms-2">
          Save
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};
export default AddEditDerivedInsightModal;
