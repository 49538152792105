import {
  faArrowDown,
  faArrowUp,
  faMinus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useContext, useEffect, useState } from "react";

import { InsightCustomComponentProps } from "../../services/models";
import { Platform } from "../../services/platformService";
import {
  WeeklyInsightWithValue,
  calculateWeeklySummaryValues,
} from "../../services/weeklySummaryServices";
import { chunk } from "../../utils/array";
import PlatformContext from "../app/PlatformContext";

const styles = {
  card: {
    height: "100%",
    width: "50%",
    border: "1px solid var(--border-color)",
  },
  topCard: {
    borderTop: "none",
  },
  bottomCard: {
    borderBottom: "none",
  },
  leftCard: {
    borderLeft: "none",
  },
  rightCard: {
    borderRight: "none",
  },
};

const WeeklySummary: React.FC<InsightCustomComponentProps> = ({
  selection,
}) => {
  const [weeklyInsights, setWeeklyInsights] = useState<
    WeeklyInsightWithValue[]
  >([]);
  const { insightsMap } = useContext(PlatformContext).platform as Platform;
  useEffect(() => {
    calculateWeeklySummaryValues(insightsMap, selection).then(
      setWeeklyInsights,
    );
  }, [insightsMap, selection]);
  if (!weeklyInsights.length) return null;
  const chunks = chunk(weeklyInsights, 2);

  function renderWeeklySummaryInsight(
    style: CSSProperties,
    weeklyInsightWithValue?: WeeklyInsightWithValue,
  ) {
    const [icon, color] =
      weeklyInsightWithValue?.typeOfChange === "positive"
        ? [faArrowUp, "#1BC5BD"]
        : weeklyInsightWithValue?.typeOfChange === "negative"
          ? [faArrowDown, "#F64E60"]
          : [faMinus, undefined];
    return (
      <div
        className="d-flex flex-col align-items-center justify-content-center"
        style={style}
      >
        {weeklyInsightWithValue ? (
          <>
            <div className="mb-1" style={{ fontSize: "40px", fontWeight: 300 }}>
              {weeklyInsightWithValue.value}
            </div>
            <div
              className="flex-col justify-content-center mb-1 w-75 h-25 text-center text-capitalize"
              style={{ fontSize: "20px", fontWeight: 300 }}
            >
              {weeklyInsightWithValue.name}
            </div>
            <div className="flex-row align-items-center justify-content-center">
              <FontAwesomeIcon className="me-2" icon={icon} color={color} />
              <div style={{ fontSize: "20px", fontWeight: 600 }}>
                {weeklyInsightWithValue.change}
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }

  return (
    <div className="w-100 h-100 d-flex flex-col justify-content-center">
      {chunks.map((chunk, idx) => {
        const mergeStyle = (
          style: CSSProperties,
          toMerge: CSSProperties,
        ): CSSProperties => {
          return {
            ...style,
            ...toMerge,
          };
        };
        const first = chunk[0];
        const second = chunk.length === 2 ? chunk[1] : undefined;
        let style: CSSProperties = styles.card;
        if (idx === 0) {
          style = mergeStyle(style, styles.topCard);
        }
        if (idx === chunks.length - 1) {
          style = mergeStyle(style, styles.bottomCard);
        }
        const leftStyle = mergeStyle(style, styles.leftCard);
        const rightStyle = mergeStyle(style, styles.rightCard);
        return (
          <div key={idx} className="d-flex flex-row" style={{ flex: 1 }}>
            {renderWeeklySummaryInsight(leftStyle, first)}
            {renderWeeklySummaryInsight(rightStyle, second)}
          </div>
        );
      })}
    </div>
  );
};

export default WeeklySummary;
