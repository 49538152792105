import "react-bootstrap-typeahead/css/Typeahead.css";

import { faBolt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnValue,
  PropertiesByColumn,
  defaultSortedPropertyColumns,
  exhaustiveCheck,
  getCustomColumnName,
  isNameExpression,
} from "@joyhub-integration/shared";
import _, { sortBy } from "lodash";
import { orderBy } from "natural-orderby";
import React, { useContext, useMemo, useState } from "react";
import {
  Highlighter,
  Menu,
  MenuItem,
  RenderMenuProps,
  Token,
  Typeahead,
} from "react-bootstrap-typeahead";
import type {
  Option,
  RenderTokenProps,
  TypeaheadManagerChildProps,
  TypeaheadPropsAndState,
} from "react-bootstrap-typeahead/types/types";
import { Button, InputGroup, InputGroupText } from "reactstrap";

import { Property } from "../../../services/propertiesService";
import { containsIgnoreCase } from "../../../utils/array";
import {
  preventBrowserAutoComplete,
  sendEmptyDocumentClick,
} from "../../../utils/misc";
import { isString } from "../../../utils/string";
import PlatformContext from "../../app/PlatformContext";

interface PropertiesColumnPickerProps {
  allProperties: Property[];
  byColumnSelection: PropertiesByColumn;
  setByColumnSelection: (a: PropertiesByColumn) => void;
}

const UserNameExpressionOption = {
  label: "User name",
  expression: true,
};

const UserEmailExpressionOption = {
  label: "User email",
  expression: true,
};

const defaultTypeaheadValueOptions = [
  UserNameExpressionOption,
  UserEmailExpressionOption,
];

const PropertiesColumnPicker: React.FC<PropertiesColumnPickerProps> = ({
  allProperties,
  byColumnSelection,
  setByColumnSelection,
}) => {
  const [newField, setNewField] = useState(new Array());
  const { organization } = useContext(PlatformContext).platform!;
  const { configuration } = organization;

  // All the non-hidden text attributes
  const attributes = useMemo(() => {
    return defaultSortedPropertyColumns(configuration.customColumns).filter(
      (col) => col.dataType === "text" && !col.hidden,
    );
  }, [configuration]);

  // Attribute name by column key
  const attributeNames = useMemo(
    () =>
      Object.fromEntries(
        attributes.map((col) => [col.columnKey, getCustomColumnName(col)]),
      ),
    [attributes],
  );

  // What column attributes have not yet been added
  const typeaheadNewFieldOptions = useMemo(() => {
    return sortBy(
      attributes
        .filter((a) => !byColumnSelection.columns[a.columnKey])
        .map((a) => ({
          ...a,
          label: a.name ?? attributeNames[a.columnKey],
        })),
      (a) => a.label,
    );
  }, [attributes, byColumnSelection, attributeNames]);

  // What values can be added for each column
  const typeaheadValueOptions: Record<string, Option[]> = useMemo(() => {
    const valueOptions: Record<string, Set<string>> = {};
    allProperties.forEach((p) =>
      attributes.forEach(({ columnKey }) => {
        const value = p[columnKey as keyof Property];
        if (value) {
          valueOptions[columnKey] = (
            valueOptions[columnKey] || new Set<string>()
          ).add(value.toString());
        }
      }),
    );

    return Object.entries(valueOptions).reduce((acc, [key, valuesSet]) => {
      const typeaheadOptions = [
        ...orderBy(Array.from(valuesSet)).map((label) => {
          return { label, expression: false };
        }),
        ...defaultTypeaheadValueOptions,
      ];
      return { ...acc, [key]: typeaheadOptions };
    }, {});
  }, [allProperties, attributes]);

  // What value have been selected for each column
  const typeheadValues: Record<string, Option[]> = Object.entries(
    byColumnSelection.columns,
  ).reduce((acc, [field, values]) => {
    return {
      ...acc,
      [field]: values.map((v) => {
        if (typeof v === "string") {
          return {
            expression: false,
            label: v,
          };
        } else if (isNameExpression(v)) {
          if (v.name === "user.name") {
            return UserNameExpressionOption;
          } else if (v.name === "user.email") {
            return UserEmailExpressionOption;
          } else {
            exhaustiveCheck(v.name);
          }
        } else {
          return {
            expression: false,
            label: v?.toString() ?? "",
          };
        }
      }),
    };
  }, {});

  const handleColumnValueChange = (field: string, values: Option[]) => {
    setByColumnSelection({
      columns: {
        ...byColumnSelection.columns,
        [field]: values.map<ColumnValue>((v) => {
          if (typeof v === "string") return v;

          if (v.expression) {
            if (v.label === UserNameExpressionOption.label) {
              return {
                type: "expression",
                name: "user.name",
              };
            } else if (v.label === UserEmailExpressionOption.label) {
              return {
                type: "expression",
                name: "user.email",
              };
            } else {
              return v.label;
            }
          } else {
            return v.label;
          }
        }),
      },
    });
  };

  const addColumnField = () => {
    if (newField.length > 0) {
      handleColumnValueChange(newField[0].columnKey, []);
      setNewField([]);
    }
  };

  const removeColumnField = (field: string) => {
    const { [field]: _, ...rest } = byColumnSelection.columns;
    setByColumnSelection({ columns: rest });
  };

  const allowNewColumnValue =
    (field: string) => (values: Option[], props: TypeaheadPropsAndState) => {
      return !containsIgnoreCase(
        byColumnSelection.columns[field].filter(isString),
        props.text.trim(),
      );
    };

  // i don't need a const, but if this is inlined at usage site prettier removes the trailing space /shrug
  const customOptionPrefix = "New selection: ";

  // unfortunately, to get our own Menu.Dvider and Menu.Header we have to
  // take over rendering the entire menu. This function is a subset and a duplication
  // of TypeaheadMenu's renderMenuItem.
  const renderMenuItem =
    (state: TypeaheadManagerChildProps) => (result: Option, index: number) => (
      <MenuItem key={index} option={result} position={index}>
        {typeof result !== "string" ? (
          <>
            {result.customOption && customOptionPrefix}
            <Highlighter search={state.text}>{result.label}</Highlighter>
          </>
        ) : (
          <Highlighter search={state.text}>{result}</Highlighter>
        )}
      </MenuItem>
    );

  const renderMenu = (
    results: Option[],
    menuProps: RenderMenuProps,
    state: TypeaheadManagerChildProps,
  ) => {
    const [expressiveResults, valueResults] = _.partition(results, (r) =>
      typeof r !== "string" ? r.expression : false,
    );

    const valueMenuItems = valueResults.map(renderMenuItem(state));
    const divider = expressiveResults.length
      ? [
          <React.Fragment key={valueResults.length || 0}>
            <Menu.Divider />
            <Menu.Header>Expressions</Menu.Header>
          </React.Fragment>,
        ]
      : [];
    const expressiveMenuItems = expressiveResults.map((r, i) =>
      renderMenuItem(state)(r, valueMenuItems.length + 1 + i),
    );

    const items = [...valueMenuItems, ...divider, ...expressiveMenuItems];
    return <Menu {...menuProps}>{items}</Menu>;
  };

  const renderToken = (
    selectedItem: Option,
    { onRemove }: RenderTokenProps,
    index: number,
  ) => (
    <Token key={index} onRemove={onRemove} option={selectedItem}>
      {typeof selectedItem !== "string" ? (
        <>
          {selectedItem.expression && (
            <FontAwesomeIcon icon={faBolt} className="me-1" />
          )}
          {selectedItem.label}
        </>
      ) : (
        selectedItem
      )}
    </Token>
  );

  return (
    <div>
      {Object.entries(typeheadValues).map(([field, values]) => (
        <div key={field}>
          <InputGroup size="sm" className="mb-2">
            <InputGroupText>{attributeNames[field]}</InputGroupText>
            <Typeahead
              id={`column-${field}`}
              options={
                typeaheadValueOptions[field] || defaultTypeaheadValueOptions
              }
              size="sm"
              multiple
              allowNew={allowNewColumnValue(field)}
              selected={values}
              onChange={(selected) => handleColumnValueChange(field, selected)}
              onFocus={preventBrowserAutoComplete}
              onBlur={sendEmptyDocumentClick}
              renderToken={renderToken}
              renderMenu={renderMenu}
            />
            <Button onClick={() => removeColumnField(field)}>Delete</Button>
          </InputGroup>
        </div>
      ))}

      <div>
        <InputGroup size="sm">
          <InputGroupText>Attribute</InputGroupText>
          <Typeahead
            id="column-add-field"
            options={typeaheadNewFieldOptions}
            size="sm"
            allowNew={false}
            selected={newField}
            onChange={(selected) => setNewField(selected)}
            onFocus={preventBrowserAutoComplete}
            onBlur={sendEmptyDocumentClick}
          />
          <Button onClick={addColumnField} disabled={newField.length === 0}>
            Add
          </Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default PropertiesColumnPicker;
