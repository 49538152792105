import React, { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";

type BoolPickerProps = {
  columnKey: string;
  existingSelection: boolean | undefined;
  updateSelection: (update: boolean | undefined) => void;
};

const BoolPicker: React.FC<BoolPickerProps> = ({
  columnKey,
  existingSelection,
  updateSelection,
}) => {
  const [mode, setMode] = useState<"checked" | "unchecked" | undefined>(
    existingSelection === true
      ? "checked"
      : existingSelection === false
        ? "unchecked"
        : undefined,
  );

  return (
    <div className="mb-2 px-4">
      <ButtonGroup className="p-2">
        <Button
          size="sm"
          outline={mode !== "checked"}
          onClick={() => {
            const newMode = mode === "checked" ? undefined : "checked";
            updateSelection(newMode === "checked" ? true : undefined);
            setMode(newMode);
          }}
          style={{ width: "125px" }}
        >
          {columnKey === "is_comparable" ? "Comp" : "Checked ☑"}
        </Button>
        <Button
          size="sm"
          outline={mode !== "unchecked"}
          onClick={() => {
            const newMode = mode === "unchecked" ? undefined : "unchecked";
            updateSelection(newMode === "unchecked" ? false : undefined);
            setMode(newMode);
          }}
          style={{ width: "125px" }}
        >
          {columnKey === "is_comparable" ? "Own" : " Not Checked"}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default BoolPicker;
