import React from "react";
import { Input } from "reactstrap";

interface MonthFilterProps {
  monthSelected: string;
  monthOptions: string[];
  onMonthSelectedChange: (month: string) => void;
}

const MonthFilter: React.FC<MonthFilterProps> = ({
  monthSelected,
  monthOptions,
  onMonthSelectedChange,
}) => {
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    onMonthSelectedChange(e.target.value);
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "0.5rem",
        zIndex: 10,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Input
        type="select"
        className="custom-select"
        value={monthSelected}
        style={{ width: "fit-content" }}
        onClick={(e) => e.preventDefault()}
        onChange={onChange}
      >
        <option value="">All Months</option>
        {monthOptions.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </Input>
    </div>
  );
};

export default MonthFilter;
