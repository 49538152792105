import axios from "axios";
import React, { useRef, useState } from "react";
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { unexpectedError } from "../../../constants";
import { importUsers } from "../../../services/usersService";
import { apiUrl, axiosBlobConfig } from "../../../utils/api";
import { downloadAttachment } from "../../../utils/download";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface UsersImportModalProps {
  onSubmit: (usersAdded: number, usersEdited: number) => void;
  onClose: () => void;
}

const UsersImportModal: React.FC<UsersImportModalProps> = (props) => {
  const [emailInvites, setEmailInvites] = useState(true);
  const [ignoreWarnings, setIgnoreWarnings] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [importWarnings, setImportWarnings] = useState(new Array<string>());

  const fileInput = useRef<HTMLInputElement>(null);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setServerError("");
    setImportWarnings([]);
    const file = fileInput.current?.files?.item(0);
    if (!file) return;
    setUploading(true);
    importUsers(emailInvites, ignoreWarnings, file)
      .then((res) => props.onSubmit(res.data.usersAdded, res.data.usersEdited))
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 400) {
          if (response.data.message) {
            setServerError(response.data.message);
          } else if (response.data.errors?.length) {
            setImportWarnings(response.data.errors as string[]);
          } else {
            setServerError(unexpectedError);
          }
        } else {
          setServerError(unexpectedError);
        }
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const downloadTemplate = () => {
    axios
      .get(apiUrl("/people/template"), axiosBlobConfig)
      .then(downloadAttachment("Users Template"));
  };

  return (
    <UncontrolledModal onClosed={props.onClose} onFormSubmit={onSubmit}>
      <ModalHeader className="modal-gadget-header">
        <span>Import or Edit Users</span>
        <Button onClick={downloadTemplate} color="link" className="p-0">
          Download template
        </Button>
      </ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!serverError}>
          {serverError}
        </Alert>
        <Alert color="warning" isOpen={!!importWarnings.length}>
          <p>The following lines have errors and are unable to be imported</p>
          <ul>
            {importWarnings.map((importError, i) => (
              <li key={i}>{importError}</li>
            ))}
          </ul>
        </Alert>
        <FormGroup>
          <Input
            className="upload-file-input"
            type="file"
            id="user-upload"
            accept=".xlsx"
            innerRef={fileInput}
          />
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={emailInvites}
              onChange={() => setEmailInvites(!emailInvites)}
            />{" "}
            Email invitations on create
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {!!importWarnings.length && (
          <Label check className="me-3">
            <Input
              type="checkbox"
              checked={ignoreWarnings}
              onChange={() => setIgnoreWarnings(!ignoreWarnings)}
            />{" "}
            Proceed with warnings
          </Label>
        )}
        <Button color="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary" disabled={uploading}>
          Import
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default UsersImportModal;
