/** Singleton set. */
import { arrayify } from "@joyhub-integration/shared";

export const setOf = <A>(a: A | A[]): Set<A> => new Set(arrayify(a));

/** Immutable set add. Returns a new set with [a] added. */
export const setAdd = <A>(as: Set<A>, a: A | A[]): Set<A> => {
  const newAs = new Set(as);
  if (Array.isArray(a)) {
    for (const aa of a) {
      newAs.add(aa);
    }
  } else {
    newAs.add(a);
  }
  return newAs;
};

/** Immutable set remove. Returns a new set with [a] removed. */
export const setRemove = <A>(as: Set<A>, a: A | A[]): Set<A> => {
  const newAs = new Set(as);
  if (Array.isArray(a)) {
    for (const aa of a) {
      newAs.delete(aa);
    }
  } else {
    newAs.delete(a);
  }
  return newAs;
};

/** Immutable set toggle. Returns a new set with [a] differently present. */
export const setToggle = <A>(as: Set<A>, a: A | A[]): Set<A> => {
  if (Array.isArray(a)) {
    return a.some((aa) => !as.has(aa)) ? setAdd(as, a) : setRemove(as, a);
  } else {
    return as.has(a) ? setRemove(as, a) : setAdd(as, a);
  }
};

/** Map the values of a set. */
export const mapSet = <A>(as: Set<A>, fa: (a: A) => A) =>
  new Set(Array.from(as).map(fa));
