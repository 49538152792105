import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DashboardKindEnum,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import { useCallback, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Button } from "reactstrap";
import {
  DashboardBoardRestPropsType,
  Dashboard as DashboardType,
} from "../../../../services/dashboardService";
import { OtherDashboardInsight } from "../../../../services/models";
import withAlertModal, {
  WithAlertModalProps,
} from "../../../common/alert/withAlertModal";
import { BoardRow } from "./BoardRow";

interface MainBoardPropsType {
  basicLayout: any[] | undefined;
  dashboard: DashboardType;
  selection: PropertiesSelection;
  dashboardKind:
    | DashboardKindEnum.Dashboard
    | DashboardKindEnum.PropertyDashboard;
  setDashboardInsightToEdit: (di: OtherDashboardInsight) => void;
  setDashboardInsightToRemove: (di: OtherDashboardInsight) => void;
  toggleAddEditInsight: (xy: [number, number]) => void;
  layout: any[];
  setLayout: (layout: any[]) => void;
}

// type disappointment throughout
const reindex = (row: any[]): any[] =>
  row.map((item: any, x) => ({
    ...item,
    x,
  }));

const MainBoard = (
  props: MainBoardPropsType & DashboardBoardRestPropsType & WithAlertModalProps,
) => {
  const {
    basicLayout,
    dashboard,
    editMode,
    onUnexpectedError,
    toggleAddEditInsight,
    layout,
    setLayout,
    ...rest
  } = props;

  useEffect(() => {
    if (basicLayout === undefined || basicLayout.length === 0) setLayout([[]]);
    else setLayout(basicLayout);
  }, [basicLayout, setLayout]);

  const handleAddNewRow = () => {
    const newLayout = [...layout];
    newLayout.push([]);
    setLayout(newLayout);
  };

  const handleUpdateLayout = useCallback(
    (
      itemRowIndex: number,
      itemNo: number,
      update: number | "left" | "right",
    ) => {
      const newLayout = [...layout];
      if (typeof update === "number") {
        const from = newLayout[itemRowIndex],
          to = newLayout[update];
        if (!from || !to || from === to) return;
        const index = from.findIndex((item: any) => item.x === itemNo);
        if (index < 0) return;
        const oldRow = [...from];
        const [item] = oldRow.splice(index, 1);
        const newItem = { ...item, y: update };
        const newRow = [...to, newItem];
        newLayout[itemRowIndex] = reindex(oldRow);
        newLayout[update] = reindex(newRow);
      } else {
        const row = newLayout[itemRowIndex];
        if (!row) return;
        const index = row.findIndex((item: any) => item.x === itemNo);
        if (
          index < 0 ||
          (index === 0 && update === "left") ||
          (index === row.length - 1 && update === "right")
        )
          return;
        const newRow = [...row];
        const [item] = newRow.splice(index, 1);
        newRow.splice(index + (update === "left" ? -1 : 1), 0, item);
        newLayout[itemRowIndex] = reindex(newRow);
      }
      setLayout(newLayout);
    },
    [layout, setLayout],
  );

  const handleUpdateWidth = useCallback(
    (itemRowIndex: number, itemNo: number, width: number) => {
      if (layout.length <= itemRowIndex) return;
      if (itemNo >= layout[itemRowIndex].length) return;

      const newLayout = [...layout];
      newLayout[itemRowIndex][itemNo].width = width;
      setLayout(newLayout);
    },
    [layout, setLayout],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {layout &&
        layout.map((item, index) => (
          <BoardRow
            key={index}
            data={item}
            index={index}
            dashboard={dashboard}
            editMode={editMode}
            onUpdateLayout={handleUpdateLayout}
            onUpdateWidth={handleUpdateWidth}
            toggleAddEditInsight={toggleAddEditInsight}
            {...rest}
          />
        ))}
      {editMode && (
        <div
          style={{
            marginTop: 10,
            marginBottom: 20,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <Button
            block
            color="primary"
            outline
            className="border-0"
            onClick={handleAddNewRow}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-1">Add Row</span>
          </Button>
        </div>
      )}
    </DndProvider>
  );
};

export default withAlertModal(MainBoard);
