import {
  faBuilding,
  faHomeHeart,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import React, { useContext, useEffect, useState } from "react";

import { InsightCustomComponentProps } from "../../../services/models";
import { Platform } from "../../../services/platformService";
import {
  PortfolioOverview,
  getPortfolioOverview,
} from "../../../services/portfolioOverviewService";
import { dateToString } from "../../../utils/date";
import { formatted, toPercentage } from "../../../utils/number";
import PlatformContext from "../../app/PlatformContext";
import { Skeleton } from "../../dashboard/DashboardBoard/MainBoard/BoardRow/SimpleCharts";
import { ReactComponent as occupied } from "./Occupied.svg";
import PortfolioOverviewItem from "./PortfolioOverviewItem";

interface PortfolioOverviewCardProps extends InsightCustomComponentProps {
  spinWhileLoading?: boolean;
  showHeader?: boolean;
}

const PortfolioOverviewCard: React.FC<PortfolioOverviewCardProps> = ({
  selection,
  showHeader,
}) => {
  const [portfolioOverview, setPortfolioOverview] =
    useState<PortfolioOverview>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const platform = useContext(PlatformContext).platform as Platform;
  const isAdmin = platform.admin;
  useEffect(() => {
    getPortfolioOverview(selection)
      .then(setPortfolioOverview)
      .then(() => setLoaded(true));
  }, [selection]);

  if (!loaded) return <Skeleton />;

  const po = portfolioOverview as PortfolioOverview;
  const properties = formatted(po.properties);
  const units = formatted(po.units);
  const residents = formatted(po.residents);
  const occupancy = toPercentage(po.occupancy);
  const asOf = dateToString(po.asOf);
  return (
    <div className="home-overview w-100">
      {showHeader ? (
        <div className="home-overview-label home-overview-label-mobile">
          <strong>Portfolio Overview</strong>
          <div className="home-overview-date mt-0 ms-1">{asOf}</div>
        </div>
      ) : null}
      <div className="flex-row flex-wrap align-items-center">
        {showHeader ? (
          <div className="flex-col home-overview-label">
            <strong>Portfolio Overview</strong>
            <div className="home-overview-date">{asOf}</div>
          </div>
        ) : null}
        <PortfolioOverviewItem
          value={properties}
          icon={faBuilding}
          label={po.properties === 1 ? "Property" : "Properties"}
          to="/properties"
          isAdmin={isAdmin}
        />
        <PortfolioOverviewItem
          value={units}
          icon={faHomeHeart}
          label={po.units === 1 ? "Unit" : "Units"}
        />
        <PortfolioOverviewItem
          value={occupancy}
          svg={occupied}
          label="Occupancy"
          className="occupied-overview"
        />
        <PortfolioOverviewItem
          value={residents}
          icon={faUsers}
          label={po.residents === 1 ? "Resident" : "Residents"}
        />
      </div>
    </div>
  );
};

export default PortfolioOverviewCard;
