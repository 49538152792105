import * as _ from "lodash";
import * as stackTrace from "stack-trace";
import { inspect } from "util";

export const exhaustiveCheck: (a: never) => never = (a) =>
  allCasesCovered(a, () =>
    Error(`Unreachable code triggered by ${inspect(a, true, null, true)}`),
  );

export const randomStr = (chars: string, length: number): string => {
  return Array(length)
    .fill(undefined)
    .map(() => chars[Math.floor(Math.random() * chars.length)])
    .join("");
};

export const randomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

/**
 * randomly generate a cognito compliant password
 *
 * specifically:
 *  - minimum 8 letters
 *  - an uppercase
 *  - a lowercase
 *  - a number
 *  - a symbol (we use _ because it is easy to double-click and select, an important benefit)
 */
export const generateCognitoPassword = () => {
  const nums = "1234567890";
  const uppers = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowers = "abcdefghijklmnopqrstuvwxyz";
  const chars = uppers + lowers + nums;

  //random insert somewhere
  const rInsert = (str: string, chr: string): string => {
    const i = Math.random() * (str.length - 1);
    return str.substring(0, i) + chr + str.substring(i);
  };

  const basePassword = randomStr(chars, 6);
  const randNumber = nums[randomInt(nums.length)];
  const randUpper = uppers[randomInt(uppers.length)];
  const randLower = lowers[randomInt(lowers.length)];
  return rInsert(
    rInsert(rInsert(rInsert(basePassword, "_"), randNumber), randUpper),
    randLower,
  );
};

// confirm cognito characters - mostly a poor man's sanitization tool
export const validateCognitoPassword = (password: string) => {
  return password.length >= 8 && password.match(/^\w+$/) != null; // _ is included as part of \w
};

/** Extract the value from possibly undefined expression or else throw. */
export const getOrThrow = <A, E>(
  a: A | undefined,
  ...args: [() => E] | [string] | [string, Record<string, any>]
): A => {
  if (a === undefined)
    throw typeof args[0] === "function"
      ? args[0]()
      : Error(args[0], ...(args.length > 1 ? [{ cause: args[1] }] : []));
  else return a;
};

/** Want an array with exactly one entry; return it or throw otherwise. */
export const getOneOrThrow = <A, E>(
  as: A[],
  eNone: () => E,
  eAmbiguous: (n: number, a: A, b: A) => E,
): A => {
  if (as.length === 0) throw eNone();
  else if (as.length > 1) throw eAmbiguous(as.length, as[0], as[1]);
  else return as[0];
};

// Result of string.match where groups & input are non-null.
export type SuccessfulMatch = RegExpMatchArray & {
  groups: NonNullable<RegExpMatchArray["groups"]>;
  input: NonNullable<RegExpMatchArray["input"]>;
};
export const isSuccessfulMatch = (
  m: RegExpMatchArray | null,
): m is SuccessfulMatch => m != null && m.groups != null && m.input != null;

export const arrayify1 = <T>(a: undefined | T): T[] =>
  a === undefined ? [] : [a];

export const arrayify = <T>(a: undefined | T | T[]): T[] =>
  Array.isArray(a) ? a : a === undefined ? [] : [a];

// See https://github.com/microsoft/TypeScript/issues/17002 (bug report) and
// https://stackoverflow.com/a/58880876 (explanation and some solutions).
// When writing arrayifyRO, we hit a slight problem because isArray() loses
// typing information, so fails to act as a useful typeguard on readonly arrays.
// Solution mainly involve either adding prototypes to isArray, or rolling your
// own.
declare global {
  interface ArrayConstructor {
    // Note that some "solutions" offer a version of this with (RoA<any> | any),
    // which would let non-readonly arrays through.
    isArray(arg: ReadonlyArray<any> | Readonly<any>): arg is ReadonlyArray<any>;
  }
}

export const arrayifyRO = <T>(
  a: undefined | Readonly<T> | readonly T[],
): readonly T[] => (Array.isArray(a) ? a : a === undefined ? [] : [a]);

/** Sequence from [0..n) or [n..m] */
export const sequence = (n: number, m?: number): Array<number> => {
  const zero = m === undefined ? 0 : n;
  const count = m === undefined ? n : 1 + m - n;
  return Array(count)
    .fill(undefined)
    .map((_value, index) => zero + index);
};

export type SimpleRow = string[];
export type SimpleSheet = Array<SimpleRow> & {
  locator: string;
  name?: string;
  sheetNumber: number;
  startRow?: number;
};
export const newSimpleSheet = (
  locator: string,
  sheetNumber: number,
): SimpleSheet =>
  Object.assign(new Array<SimpleRow>(), { locator, sheetNumber });

const renameKeys = (o: any, mapping: Record<string, string>) =>
  _.mapKeys(o, (v, k) => mapping[k] ?? k);

// Pull out key sheet info for exceptions and log messages.
export const sheetContext = (sheet: SimpleSheet) =>
  _.pick(
    renameKeys(sheet, { name: "sheetName" }),
    "locator",
    "sheetName",
    "sheetNumber",
    "startRow",
  );

// Throw this into your switch's `default:` case in order to force a type error
// if you haven't covered everything in a discriminated union.
//
// It is much easier to declare this as a function: you need to really go
// overboard with annotations on a `const foo = ...` version of this if you
// want control flow analysis to recognise that it never returns:
//
//   https://stackoverflow.com/a/59094502
export function allCasesCovered<E>(c: never, e: () => E): never {
  throw e();
}

// This is intended to support a localeCompare-based alternative to LoDash's
// sortBy, which doesn't permit choice of comparison function; didn't use it
// in the end though.  Use would be as a parameter to Array.sort(), called
// with an array of key getters as with sortBy.  Could modify to take
// strcasecmp or whatever as a param too.
// export const strcasecmpMulti =
//   <T, KT extends string>(
//     keyGetters: Array<(v: T) => KT>,
//   ): ((a: T, b: T) => number) =>
//   (a, b) => {
//     for (const kg of keyGetters) {
//       const cmp = strcasecmp(kg(a), kg(b));
//       if (cmp != 0) return cmp;
//     }
//     return 0;
//   };
export const strcasecmp = (a: string, b: string): number =>
  a.localeCompare(b, undefined, { sensitivity: "accent" });
export const iEquals = (a: string, b: string): boolean =>
  strcasecmp(a, b) === 0;

export const groupBy = <C, A, B extends string | number>(
  as: readonly A[],
  f: (a: A) => [B, C],
): Record<B, C[]> => {
  const bcs = {} as Record<B, C[]>;
  for (const a of as) {
    const [b, c] = f(a);
    const cs = bcs[b];
    if (cs) {
      cs.push(c);
    } else {
      bcs[b] = [c];
    }
  }
  return bcs;
};

export const grouped = <C, A, B extends string | number>(
  as: readonly A[],
  f: (a: A) => [B, C],
): [B, C[]][] => Object.entries(groupBy(as, f)) as [B, C[]][];

/** Get a required environment variable or throw an error. */
export const requireEnv = (name: string): string =>
  getOrThrow(process.env[name], () =>
    Error(`Missing environment variable: ${name}`),
  );

/** Lazily get a required environment variable or throw an error. */
export const lazyEnv =
  (name: string): (() => string) =>
  () =>
    requireEnv(name);

/** Get an environment variable or a default. */
export const optionalEnv = (name: string, def: string = ""): string =>
  process.env[name] || def;

export const queryString = (params: { [key: string]: string | number }) =>
  Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

export const uriEncode = (
  base: string,
  params: {
    [key: string]: string | number;
  },
) =>
  Object.keys(params).length === 0 ? base : `${base}?${queryString(params)}`;

export const uriEncodeWhereDefined = (
  base: string,
  params: {
    [key: string]: string | number | undefined | null;
  },
) =>
  uriEncode(
    base,
    _.pickBy(params, (v) => v != null) as {
      [key: string]: string | number;
    },
  );

export const callerID = () => {
  const stack = _.tail(stackTrace.get()); // Ignore this frame
  const here = stack[0].getFileName();
  // Ignore caller's frame too, and skip further frames that are either in the
  // same file as the caller or don't have filenames or are Node specials.
  const rest = _.dropWhile(_.tail(stack), (f) => {
    const fn = f.getFileName();
    return fn == null || fn === here || fn.startsWith("node:");
  });
  const fn = rest[0]?.getFileName() ?? "<unknown>";
  // Just report parent directory and filename.
  const nicefn = (fn.match(/[^\/]+\/[^\/]+$/)?.[0] ?? fn).replace(":", "/");
  return `${nicefn}:${rest[0]?.getLineNumber() ?? "???"}`;
  // Full stack trace version:
  // let prev = "";
  // const pretty = rest.reduce((acc, frame) => {
  //   const cfn = frame.getFileName() ?? "<unknown>";
  //   // Just report parent directory and filename.
  //   const nicefn = (cfn.match(/[^\/]+\/[^\/]+$/)?.[0] ?? cfn).replace(":", "/");
  //   const ln = frame.getLineNumber() ?? "???";
  //   const sameFile = nicefn === prev;
  //   prev = nicefn;
  //   return acc === ""
  //     ? `${nicefn}:${ln}`
  //     : sameFile
  //       ? `${acc}-${ln}`
  //       : `${acc}, ${nicefn}:${ln}`;
  // }, "");
  // return pretty ?? "<unknown>";
};

// Test-and-add an element to a set: mainly for checking for repeats of things.
export const seen = <E, S extends Set<E>>(s: S, e: E) => {
  const rv = s.has(e);
  s.add(e);
  return rv;
};
