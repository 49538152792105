import React from "react";
import { Button, ButtonProps, Spinner } from "reactstrap";

export interface SubmitButtonProps extends ButtonProps {
  busy: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const { busy, children, disabled, ...buttonProps } = props;
  return (
    <Button {...buttonProps} disabled={busy || disabled} type="submit">
      {children}
      {busy && <Spinner size="sm" style={{ marginLeft: ".25rem" }} />}
    </Button>
  );
};

export default SubmitButton;
