import preval from "preval.macro";
import React from "react";
import { ReactComponent as RevolutionRELogo } from "../../images/logo-h.svg";
import { Platform } from "../../services/platformService";
import { prId } from "../../utils/api";

const buildDate = preval`module.exports = new Date().toISOString().substring(0, 10)`;
const commitId = (
  process.env.REACT_APP_AWS_COMMIT_ID || "0123456789ABCDEF"
).substring(0, 16);
const branchName = process.env.REACT_APP_AWS_BRANCH;

export const ModernFooter: React.FC<{ platform?: Platform }> = ({
  platform,
}) => (
  <div className="d-print-none modern-footer">
    <div className="footer-inner">
      <div className="footer-inner-inner">
        <a
          href="https://revolutionre.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <RevolutionRELogo className="logo-full" style={{ height: "2rem" }} />
        </a>
        <div className="build-info">
          {buildDate}
          {prId != null ? ` | ${branchName}` : null}
          {` | ${commitId}`}
          {platform && platform.buildDate !== buildDate
            ? ` | ${platform.buildDate}`
            : null}
        </div>
      </div>
    </div>
  </div>
);
