import "./error.css";

import {
  faBug,
  faEnvelope,
  faHome,
  faRedo,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import ButtonWithIcon from "../common/button/ButtonWithIcon";

type ErrorProps = {
  goHome: () => void;
  cause: string;
};

const Error: React.FC<ErrorProps> = ({ goHome, cause }) => {
  return (
    <>
      <div className="hero-error-wrapper">
        <FontAwesomeIcon icon={faBug} size="8x" className="hero-error" />
      </div>
      <div className="error-page-main">
        <h1>Oops!</h1>
        <p>{cause} Please try again or contact support.</p>
        <div className="flex-row error-page-actions">
          <ButtonWithIcon
            icon={faRedo}
            color="transparent"
            onClick={() => document.location.reload()}
          />
          <ButtonWithIcon icon={faHome} color="transparent" onClick={goHome} />
          <ButtonWithIcon
            icon={faEnvelope}
            color="transparent"
            tag="a"
            href="mailto:support@revolutionre.com"
          />
        </div>
      </div>
    </>
  );
};

export default Error;
