import { toLower } from "lodash";

export function chunk<T>(array: T[], size: number) {
  const chunked: T[][] = [];
  for (let i = 0; i < array.length; i++) {
    const chunkedIndex = Math.floor(i / size);
    if (chunked[chunkedIndex] && chunked[chunkedIndex].length) {
      chunked[chunkedIndex].push(array[i]);
    } else {
      chunked[chunkedIndex] = [array[i]];
    }
  }
  return chunked;
}

export const countWhile = <A>(
  as: readonly A[],
  pred: (a: A, i: number) => boolean,
): number => {
  let i = 0;
  for (const a of as) {
    if (!pred(a, i)) break;
    ++i;
  }
  return i;
};

export const findMap = <A, B>(
  as: readonly A[],
  f: (a: A, i: number) => B | undefined,
): B | undefined => {
  let i = 0;
  for (const a of as) {
    const b = f(a, i++);
    if (b != null) return b;
  }
  return undefined;
};

export const isNumberArray = (a: any[]): a is number[] =>
  a.every((i) => typeof i === "number");

export const lowerCaseSet = (as: string[]) => new Set(as.map(toLower));

export const containsIgnoreCase = (as: string[], a: string) => {
  const lowerAs = lowerCaseSet(as);
  return lowerAs.has(a.toLowerCase() as Lowercase<string>);
};

export const containsAnyIgnoreCase = (as: string[], bs: string[]) => {
  const lowerAs = lowerCaseSet(as);
  return bs.some((b) => lowerAs.has(b.toLowerCase() as Lowercase<string>));
};
