import { CoOptional, Perhaps, of } from "nick-offerman";

import { getOrThrow } from "./misc";

/** deprecated, use typescript enums instead... */
export type Enum<A> = {
  values: readonly A[];
  byName: <B extends Perhaps<string>>(name: B) => CoOptional<A, B>;
  byNameIgnoreCase: <B extends Perhaps<string>>(name: B) => CoOptional<A, B>;
  includesValue: (name: unknown) => name is A;
  type: A;
};

export function getEnumValues<T extends Record<string, string | number>>(
  enumObject: T,
): T[keyof T][] {
  return Object.keys(enumObject).map((key) => enumObject[key as keyof T]);
}

// TS numeric enums have both names *and* numbers as keys in their object:
// enum { A } will be { "0": "A", "A": 0 }..  One could filter them with a type
// guard to separate keys & values, but at the moment we can rely on the
// implementation detail that the names form the first half of the entries,
// while the numbers form the second.  Yeuch!
export const numericEnumNames = <T extends Record<string, string | number>>(
  e: T,
) => {
  const vals = Object.values(e);
  return vals.slice(0, vals.length / 2) as (keyof T)[];
};
export const numericEnumValues = <T extends Record<string, string | number>>(
  e: T,
) => {
  const vals = Object.values(e);
  return vals.slice(vals.length / 2, vals.length) as T[keyof T][];
};

export const isEnum = (a: any): a is Enum<any> =>
  a != null &&
  typeof a === "object" &&
  Array.isArray(a.values) &&
  typeof a.byName === "function";

export const enumify = <A extends string>(...values: readonly A[]): Enum<A> => {
  Object.freeze(values);
  const valueMap = Object.freeze(Object.fromEntries(values.map((v) => [v, v])));
  const lcValueMap = Object.freeze(
    Object.fromEntries(values.map((v) => [v.toLowerCase(), v])),
  );

  const rv = {
    values,
    byName: of((value: string) =>
      getOrThrow(valueMap[value], () =>
        Error(`Value ${value} is not a member of [${values.join(", ")}]`),
      ),
    ),
    byNameIgnoreCase: of((value: string) =>
      getOrThrow(lcValueMap[value.toLowerCase()], () =>
        Error(`Value ${value} is not a member of [${values.join(", ")}]`),
      ),
    ),
    includesValue: (value: unknown): value is A =>
      typeof value === "string" && value in valueMap,
  };
  return Object.freeze(rv as Enum<A>);
};

export function getEnumKeyByValue(object: any, value: string) {
  return Object.keys(object).find((key) => object[key] === value);
}
