import {
  InsightsSelection,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import { isEqual } from "lodash";
import {
  decodeDelimitedArray,
  decodeDelimitedNumericArray,
  encodeDelimitedArray,
  encodeDelimitedNumericArray,
} from "serialize-query-params";
import {
  QueryParamConfig,
  decodeDate,
  decodeJson,
  encodeDate,
  encodeJson,
  useQueryParam,
} from "use-query-params";

function setEq<A extends string | number>(as0: Set<A>, as1: Set<A>) {
  if (as0.size !== as1.size) return false;
  const iter = as0.values();
  let a = iter.next();
  while (!a.done) {
    if (!as1.has(a.value)) return false;
    a = iter.next();
  }
  return true;
}

export const DelimitedNumericArrayParamDeepEqual: QueryParamConfig<
  (number | null)[] | null | undefined,
  number[] | null | undefined
> = {
  encode: encodeDelimitedNumericArray,
  decode: (arrayStr) => {
    const decodedWithNull = decodeDelimitedNumericArray(arrayStr);
    if (!decodedWithNull) return decodedWithNull;
    const withOutNull: number[] = [];
    for (const num of decodedWithNull) {
      if (num !== null) {
        withOutNull.push(num);
      }
    }
    return withOutNull;
  },
  equals: (numArrA, numArrB) => {
    if (!numArrA || !numArrB) return false;
    const setA = new Set<number>();
    const setB = new Set<number>();
    for (const num of numArrA) {
      if (num && !Number.isNaN(num)) {
        setA.add(num);
      }
    }
    for (const num of numArrB) {
      if (num && !Number.isNaN(num)) {
        setB.add(num);
      }
    }
    return setEq(setA, setB);
  },
};

export const DelimitedStringArrayParamDeepEqual: QueryParamConfig<
  (string | null)[] | null | undefined,
  string[] | null | undefined
> = {
  encode: encodeDelimitedArray,
  decode: (arrayStr) => {
    const decodedWithNull = decodeDelimitedArray(arrayStr);
    if (!decodedWithNull) return decodedWithNull;
    const withOutNull: string[] = [];
    for (const num of decodedWithNull) {
      if (num) {
        withOutNull.push(num);
      }
    }
    return withOutNull;
  },
  equals: (strArrA, strArrB) => {
    if (!strArrA || !strArrB) return false;
    const setA = new Set<string>();
    const setB = new Set<string>();
    for (const str of strArrA) {
      if (str) {
        setA.add(str);
      }
    }
    for (const str of strArrB) {
      if (str) {
        setB.add(str);
      }
    }
    return setEq(setA, setB);
  },
};

export const PropertiesSelectionParameterName = "p";

const jsonQueryParam = <A>(): QueryParamConfig<
  A | undefined,
  A | undefined
> => ({
  encode: encodeJson,
  decode: (s) => decodeJson(s) ?? undefined,
  equals: isEqual,
});

const insightsSelectionParamConfig = jsonQueryParam<InsightsSelection>();
const propertiesSelectionParamConfig = jsonQueryParam<PropertiesSelection>();

export const usePropertiesSelectionQueryParam = () =>
  useQueryParam(
    PropertiesSelectionParameterName,
    propertiesSelectionParamConfig,
  );

export const useInsightsSelectionQueryParam = () =>
  useQueryParam("i", insightsSelectionParamConfig);

export const DateRangeFromParameterName = "from";

export const DateRangeToParameterName = "to";

export const DateRangeParam: QueryParamConfig<
  Date | undefined,
  Date | undefined
> = {
  encode: encodeDate,
  decode: (s) => decodeDate(s) ?? undefined,
  equals: isEqual,
};

export const useDateRangeFromQueryParam = () =>
  useQueryParam(DateRangeFromParameterName, DateRangeParam);

export const useDateRangeToQueryParam = () =>
  useQueryParam(DateRangeToParameterName, DateRangeParam);

export const asPropertiesQuery = (selection: PropertiesSelection | undefined) =>
  `${PropertiesSelectionParameterName}=${encodeURIComponent(
    JSON.stringify(selection ?? {}),
  )}`;
