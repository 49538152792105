/**
 * If `key` exists in `map`, returns its association. Otherwise, sets the association `key` -> `orElse`
 * in `map` and returns `orElse`
 */
export const getOrElseUpdate = <A, B>(
  map: Map<A, B>,
  key: A,
  orElse: () => B,
): B => {
  let b = map.get(key);
  if (b == null) {
    b = orElse();
    map.set(key, b);
  }
  return b;
};

/**
 * like map.values, but for a map of maps.
 */
export const flatValues = <A, B, C>(map: Map<A, Map<B, C>>): C[] => {
  const cs = new Array<C>();
  map.forEach((innerMap) => {
    innerMap.forEach((value) => cs.push(value));
  });

  return cs;
};

/**
 * Creates a map from the elements `as`. Maps elements into keys and values with
 * `keyFn` and `valueFn`.
 */
// named like Array.from, which takes an iterable and a mapping function.
export const mapFrom = <A, K, V>(
  as: Iterable<A>,
  keyFn: (a: A) => K,
  valueFn: (a: A) => V,
): Map<K, V> => {
  const map = new Map<K, V>();
  for (const a of as) {
    map.set(keyFn(a), valueFn(a));
  }
  return map;
};
