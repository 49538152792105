import { max } from "lodash";
import { useMemo } from "react";

import { InstantInsights } from "../../../services/dataService";
import { PercentTradeOutRenewalsLast30Days } from "../../../services/insightLibrary/backendInsightIds";
import { goaledData } from "../cdUtil";
import Meter from "../visualizations/Meter";
import DashboardCard from "./DashboardCard";
import { Skelington } from "./Skelington";

const RenewalTradeOut = ({
  insights,
  onClick,
}: {
  insights?: InstantInsights;
  onClick?: () => void;
}) => {
  const data = useMemo(
    () => goaledData(insights, PercentTradeOutRenewalsLast30Days),
    [insights],
  );
  return insights && data?.actual == null ? null : (
    <DashboardCard title="Trade Out (Renewals)" centered onClick={onClick}>
      <Skelington value={data} height={3}>
        {({ target, actual }) => (
          <Meter
            low={0}
            high={max([actual, target]) ?? 0}
            actual={actual}
            target={target}
            formatter={(number) =>
              number.toLocaleString("en-US", {
                style: "percent",
                maximumFractionDigits: 2,
              })
            }
          />
        )}
      </Skelington>
    </DashboardCard>
  );
};

export default RenewalTradeOut;
