import { List, filter, find, forEach, inRange, includes, keys } from "lodash";

// Prototype unit metadata structure
export type UnitMetadata = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  type: string;
  status: "Occupied" | "Vacant";
  floorplan: string;
  bedrooms: number;
  bathrooms?: number;
  sqft?: number;
  rent?: number;
  marketRent?: number;
  renewal: string;
  moveIn: string;
  turnover: number;
  currentTenant: string;
  currentBalance: number;
  futureTenantOrApplicant: string;
  openWorkOrders: number;
  [key: string]: string | number | undefined;
};

export type FilterType = "multi" | "range" | "discreteRange" | "dateRange";

export type UnitFilters = {
  [key: string]: {
    name: "string";
    type: FilterType;
    unit: "usd" | string;
    options?: { label: string; value: string }[];
    min?: number | `${number}/${number}/${number}`;
    max?: number | `${number}/${number}/${number}`;
  };
};

export type UnitsApiResponse = {
  units: UnitMetadata[];
  filters: UnitFilters;
};

export type UnitFilterValues = {
  [key: string]:
    | {
        type: FilterType;
        values: (string | undefined)[] | number[] | undefined | DateRange;
      }
    | undefined;
};

export type DateRange = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export const getUnitData = async (
  unitId: string,
  unitData: UnitMetadata[],
): Promise<UnitMetadata | undefined> => {
  return new Promise((resolve) =>
    resolve(find(unitData, (unit) => unit.id === unitId) as UnitMetadata),
  );
};

export const getMatchingUnits = async (
  mapId: number,
  filters: UnitFilterValues,
  unitData: UnitMetadata[],
): Promise<string[]> => {
  const nonEmptyFilters = filter(
    keys(filters) as List<keyof UnitFilters>,
    (key) => filters[key]?.values !== undefined,
  );

  if (nonEmptyFilters.length === 0) {
    return [];
  }

  return new Promise((resolve) =>
    resolve(
      filter(unitData, (unit) => {
        let match = true;
        forEach(nonEmptyFilters, (filterKey: any) => {
          const filter = filters[filterKey]!;
          const unitAttributeValue = unit[filterKey] as number | string;

          switch (filter.type) {
            case "multi":
              match =
                match &&
                includes(
                  filter.values as string[],
                  unitAttributeValue.toString(),
                );
              break;
            case "range":
            case "discreteRange":
              const min = (filter.values as number[])![0] as number;
              const max = (filter.values as number[])![1] as number;
              match =
                match && inRange(unitAttributeValue as number, min, max + 1);
              break;
            case "dateRange":
              const unitDate: Date = new Date(unitAttributeValue as string);
              const startDate = (filter?.values as DateRange)?.startDate;
              const endDate = (filter?.values as DateRange)?.endDate;
              if (!unitDate || !startDate) {
                return false;
              }

              match =
                match &&
                unitDate >= startDate &&
                (endDate === undefined || unitDate <= endDate);
              break;
          }

          return match;
        });
        return match;
      }).map((unit) => unit.id),
    ),
  );
};
