import { compare } from "natural-orderby";

const naturally = compare();

const orderedPriorities = [
  "low",
  "routine",
  "medium",
  "preventative maintenance",
  "standard",
  "high",
  "critical",
  "emergency",
  "make ready",
];

const unspecified = ["unspecified", "(none)", "blank"];

const numericRE = /^[0-9]/;

export const comparePriority = (a: string, b: string): number => {
  const nA = numericRE.test(a),
    nB = numericRE.test(b);
  if (unspecified.indexOf(a.toLowerCase()) !== -1) {
    return 1;
  } else if (unspecified.indexOf(b.toLowerCase()) !== -1) {
    return -1;
  } else if (nA && !nB) {
    return -1;
  } else if (nB && !nA) {
    return 1;
  } else {
    const iA = orderedPriorities.indexOf(a.toLowerCase()),
      iB = orderedPriorities.indexOf(b.toLowerCase());
    if (iA >= 0 && iB >= 0) {
      return iA - iB;
    } else if (iA >= 0) {
      return -1;
    } else if (iB >= 0) {
      return 1;
    }
  }
  return naturally(a, b);
};
