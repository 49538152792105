import {
  faChartScatter,
  faCity,
  faCloudUpload,
  faFileCertificate,
  faGrinTears,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashboardKindEnum } from "@joyhub-integration/shared";
import clsx from "clsx";
import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate } from "react-router";
import { useMedia } from "react-use";
import { Button, Card, ModalBody } from "reactstrap";

import { ReportSection } from "../../services/reportsService";
import PlatformContext from "../app/PlatformContext";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import UncontrolledModal from "../common/modal/UncontrolledModal";

const normalReports = [
  {
    kind: DashboardKindEnum.GenericReport,
    qs: "",
    title: "Standard Report",
    description: "Property or portfolio report.",
    icon: faCity,
  },
  {
    kind: DashboardKindEnum.Scorecard,
    qs: "",
    title: "Scorecard Email",
    description: "Standard portfolio health email.",
    icon: faFileCertificate,
  },
];

const joyhubStats = {
  kind: DashboardKindEnum.JoyhubStats,
  qs: "",
  title: "Revolution RE Statistics",
  description: "Revolution RE statistics email.",
  icon: faGrinTears,
};

const insightDataReport = {
  kind: DashboardKindEnum.GenericReport,
  qs: "&subkind=insight",
  title: "Insight Data Report",
  description: "All insight data report.",
  icon: faChartScatter,
};

export const UploadStorageKey = "_upload";

const AddReportModal: React.FC<{
  toggle: () => void;
  section: ReportSection;
}> = ({ toggle, section }) => {
  const { superAdmin, overlordDomain } = useContext(PlatformContext).platform!;
  const navigate = useNavigate();

  const isWide = useMedia("(min-width: 1440px)");

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return;
      const str = await acceptedFiles[0].text();
      const defn = JSON.parse(str);
      if (
        (!defn?.perInsight && typeof defn?.asOf !== "string") ||
        !Array.isArray(defn?.sheets)
      ) {
        alert("Bad bad bad");
      } else {
        const qs = defn.perInsight ? "&subkind=insight" : "";
        sessionStorage.setItem(UploadStorageKey, str);
        navigate(
          `/reports/${section}/_new?kind=${DashboardKindEnum.GenericReport}&upload=1&${qs}`,
        );
      }
    },
    [navigate, section],
  );

  const {
    getRootProps,
    getInputProps,
    inputRef,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: { "application/json": [".json"] },
    noClick: true,
  });

  const reports = [
    ...normalReports,
    ...(superAdmin && overlordDomain ? [joyhubStats] : []),
    ...(superAdmin ? [insightDataReport] : []),
  ];

  return (
    <UncontrolledModal size="lg" isOpen={true} onClosed={toggle}>
      <ModalBody>
        <div
          {...getRootProps()}
          className={clsx(
            "flex-row flex-wrap p-5 justify-content-center generic-drop",
            {
              isDragActive,
              isDragAccept,
              isDragReject,
            },
          )}
        >
          <ButtonWithIcon
            color="transparent"
            icon={faTimes}
            iconSize="2x"
            style={{
              position: "absolute",
              right: "-.5rem",
              top: "-.5rem",
              zIndex: 100,
              padding: ".75rem 1rem",
            }}
            onClick={toggle}
          />
          {reports.map(({ kind, title, description, icon, qs }, index) => (
            <Card
              key={index}
              tag={Link}
              to={`/reports/${section}/_new?kind=${kind}${qs}`}
              className="jh-card flex-column align-items-center p-4 add-report-card text-decoration-none"
            >
              <FontAwesomeIcon
                icon={icon}
                size={isWide ? "6x" : "4x"}
                className="color-pop"
              />
              <h5 className="mt-2 text-nowrap">{title}</h5>
              <div className="small text-muted text-center">{description}</div>
            </Card>
          ))}
          {!superAdmin ? null : (
            <div
              className="position-absolute"
              style={{ right: "1rem", bottom: "1rem" }}
            >
              <input {...getInputProps()} />
              <Button
                outline
                className="border-0 px-1"
                onClick={() => inputRef.current?.click()}
                color="dark"
              >
                <FontAwesomeIcon icon={faCloudUpload} size="lg" />
              </Button>
            </div>
          )}
        </div>
      </ModalBody>
    </UncontrolledModal>
  );
};

export default AddReportModal;
