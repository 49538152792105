import { SystemRole } from "@joyhub-integration/shared";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

import { unexpectedError } from "../../../constants";
import {
  AddEditUserDto,
  Role,
  User,
  UserResponse,
  createUser,
  editUser,
  getRoles,
  getUserById,
} from "../../../services/usersService";
import { arrayToDict } from "../../../utils/arrayToDict";
import PlatformContext from "../../app/PlatformContext";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface ValidationError {
  message?: string;
  field?: string;
}

interface AddEditUserModalProps {
  id?: number;
  onClose: () => void;
  onSubmit: (user: UserResponse) => void;
}

const AddEditUserModal: React.FC<AddEditUserModalProps> = (props) => {
  const {
    organization: { application },
  } = useContext(PlatformContext).platform!;
  const [serverError, setServerError] = useState<string>("");
  const [validationError, setValidationError] = useState<ValidationError>({});
  const [rolesDict, setRolesDict] = useState<{ [key: string]: Role }>({});
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [emailInvite, setEmailInvite] = useState<boolean>(true);
  const [systemRole, setSystemRole] = useState<SystemRole>();
  const [roleIds, setRoleIds] = useState<Set<number>>(new Set<number>());
  const [submitting, setSubmitting] = useState<boolean>(false);

  const isNewUser = !props.id;

  useEffect(() => {
    getRoles()
      .then((roles) => {
        setRolesDict(arrayToDict(roles, (r) => r.id));
        const allPropertiesRole = roles.find(
          (r) => r.name === "All Properties",
        );
        if (allPropertiesRole) {
          setRoleIds(new Set([allPropertiesRole.id]));
        }
      })
      .catch(() => setServerError(unexpectedError));
  }, []);

  useEffect(() => {
    if (props.id) {
      getUserById(props.id)
        .then((res: User) => {
          setName(res.name);
          setEmail(res.email);
          setActive(res.active);
          setSystemRole(res.organization_role);
          setRoleIds(new Set(res.roles.map((r) => r.id)));
        })
        .catch(() => setServerError(unexpectedError));
    }
  }, [props.id]);

  function toggleRole(roleId: number) {
    const newRoleIds = new Set(roleIds);
    if (newRoleIds.has(roleId)) {
      newRoleIds.delete(roleId);
    } else {
      newRoleIds.add(roleId);
    }
    setRoleIds(newRoleIds);
  }

  function emailValid() {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    );
  }

  function validateForm(): ValidationError {
    if (!name) {
      return { message: "Name is required.", field: "name" };
    } else if (!email || !emailValid()) {
      return { message: "Email is invalid.", field: "email" };
    } else if (roleIds.size === 0) {
      return { message: "At least one role must be selected.", field: "roles" };
    } else if (!systemRole) {
      return {
        message: "A system role must be selected.",
        field: "system_role",
      };
    }
    return {};
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const validationError = validateForm();
    if (Object.keys(validationError).length) {
      setValidationError(validationError);
    } else {
      setValidationError(validationError);
      const userDto: AddEditUserDto = {
        name: name,
        email: email,
        active: active,
        organization_role: systemRole!,
        roles: Array.from(roleIds),
        emailInvite: emailInvite,
      };
      setSubmitting(true);
      const promise = props.id
        ? editUser(props.id, userDto)
        : createUser(userDto);
      promise
        .then((user) => {
          props.onSubmit(user);
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 400 && response.data.message) {
            setServerError(response.data.message);
          } else {
            setServerError(unexpectedError);
          }
          setSubmitting(false);
        });
    }
  }

  const biSystemRoles = SystemRole.values;
  const pcSystemRoles = [SystemRole.byName("Administrator")];

  const systemRoles = application === "BI" ? biSystemRoles : pcSystemRoles;

  const systemRoleRadioButtons = systemRoles.map((r: SystemRole, index) => {
    return (
      <FormGroup check key={`systemRole-${index}`}>
        <Label check>
          <Input
            type="radio"
            name="systemRole"
            checked={r === systemRole}
            onChange={() => setSystemRole(r)}
          />{" "}
          {r}
        </Label>
      </FormGroup>
    );
  });

  const roleCheckboxes = Object.values(rolesDict).map((role) => (
    <FormGroup check key={role.id}>
      <Label check>
        <Input
          type="checkbox"
          checked={roleIds.has(role.id)}
          onChange={() => toggleRole(role.id)}
        />{" "}
        {role.name}
      </Label>
    </FormGroup>
  ));

  return (
    <UncontrolledModal onClosed={props.onClose} onFormSubmit={onSubmit}>
      <ModalHeader>
        {props.id ? `Edit User '${props.id}'` : "Add User"}
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            invalid={!!(validationError.field === "name")}
          />
          {validationError.field === "name" && validationError.message ? (
            <FormFeedback>{validationError.message}</FormFeedback>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            invalid={!!(validationError.field === "email")}
          />
          {validationError.field === "email" && validationError.message ? (
            <FormFeedback>{validationError.message}</FormFeedback>
          ) : null}
        </FormGroup>
        {isNewUser && (
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={emailInvite}
                onChange={() => setEmailInvite(!emailInvite)}
              />{" "}
              Email an invitation
            </Label>
          </FormGroup>
        )}
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={active}
              onChange={() => setActive(!active)}
            />{" "}
            User Active
          </Label>
        </FormGroup>

        <div className="flex-row">
          <div className="flex-col col-6">
            <FormGroup className="mt-3">
              <Label style={{ fontWeight: "bold" }}>System Role</Label>
              {systemRoleRadioButtons}
              {validationError.field === "systemRole" &&
              validationError.message ? (
                <FormFeedback className="jh-block">
                  {validationError.message}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </div>
          <div className="flex-col col-6">
            <FormGroup className="mt-3">
              <Label style={{ fontWeight: "bold" }}>Property Access</Label>
              {roleCheckboxes}
              {validationError.field === "roles" && validationError.message ? (
                <FormFeedback className="jh-block">
                  {validationError.message}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          className="ms-2"
          disabled={submitting}
        >
          {submitting ? (
            <>
              <Spinner color="light" size="sm" />{" "}
            </>
          ) : null}
          Save
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default AddEditUserModal;
