import { useEffect } from "react";

const useZendesk = (zendeskKey?: string) => {
  useEffect(() => {
    if (!zendeskKey) return;

    const script = document.createElement("script");
    script.async = !0;
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [zendeskKey]);
};

export default useZendesk;
