import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { CSSProperties, ReactElement, useState } from "react";
import { Collapse, FormGroup } from "reactstrap";

export const Revealer: React.FC<{
  label: string;
  labelStyle?: CSSProperties;
  open?: boolean;
  addOn?: ReactElement;
  children?: React.ReactNode;
}> = ({ label, labelStyle, open: defaultOpen, addOn, children }) => {
  const [open, setOpen] = useState(defaultOpen ?? false);

  return (
    <FormGroup>
      <div className="jh-revealer-row">
        <span
          onClick={() => setOpen(!open)}
          className="jh-revealer-label"
          style={labelStyle}
        >
          <span className={clsx(["me-2", "jh-revealer"], { open })}>
            <FontAwesomeIcon icon={faChevronRight} size="xs" />
          </span>
          {label}
        </span>
        {addOn}
      </div>
      <Collapse className="px-2" isOpen={open} children={children} />
    </FormGroup>
  );
};
