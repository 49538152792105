import {
  faEraser,
  faFileDownload,
  faLink,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import React, { MouseEvent, useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import axios from "../../../services/axios";
import { getOrganizationsForPropertyConnect } from "../../../services/organizationService";
import {
  linkOrganization,
  purgeCache,
  unlinkOrganization,
  updateOrganization,
} from "../../../services/overlordService";
import { getPlatform } from "../../../services/platformService";
import { setSessionOrganization } from "../../../services/sessionOrganization";
import { setOrganization } from "../../../services/sessionService";
import {
  ApplicationType,
  NavbarTheme,
  Organization,
} from "../../../services/usersService";
import { apiUrl, axiosBlobConfig } from "../../../utils/api";
import { downloadAttachment } from "../../../utils/download";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ButtonWithIconAndText from "../../common/button/ButtonWithIconAndText";
import IconWithText from "../../common/icon/IconWithText";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import DisableOrganizationModal from "./DisableOrganizationModal";
import OrganizationForm from "./OrganizationForm";

const OrganizationSettingsSection: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const { platform, setPlatform } = useContext(PlatformContext);
  const { organization } = platform!;

  const onUpdateDomain = (
    name: string,
    domains: string[],
    application: ApplicationType,
    navbarTheme: NavbarTheme,
    file: File | undefined,
    commonInsights: boolean | undefined,
    sesTls: boolean | undefined,
    slackEnabled: boolean | undefined,
    slackChannel: string | undefined,
    hsRecordId: string | undefined,
  ) => {
    return updateOrganization(
      organization.id,
      name,
      domains,
      application,
      navbarTheme,
      organization.configuration.logo,
      commonInsights,
      sesTls,
      slackEnabled,
      slackChannel,
      hsRecordId,
    )
      .then(getPlatform)
      .then(setPlatform)
      .then(() => setAlert("Organization settings updated.", true))
      .catch(onUnexpectedError);
  };

  return (
    <div className="admin-section">
      <OrganizationForm
        label="Update Settings"
        icon={faWrench}
        organization={organization}
        onSubmit={onUpdateDomain}
      />
    </div>
  );
};

const LinkedOrganizationsSection: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const { platform } = useContext(PlatformContext);
  const { organization, organizations } = platform!;
  const [linking, setLinking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState<Record<number, boolean>>({});

  useEffect(() => {
    getOrganizationsForPropertyConnect()
      .then((orgs) => {
        setConnected(orgs.reduce((a, o) => ({ ...a, [o.id]: true }), {}));
        setLoading(false);
      })
      .catch(onUnexpectedError);
  }, [onUnexpectedError]);

  const onLinkOrganization = (id: number) => () => {
    const { [id]: current, ...rest } = connected;
    (current
      ? unlinkOrganization(organization.id, id).then(() => setConnected(rest))
      : linkOrganization(organization.id, id).then(() =>
          setConnected({ ...rest, [id]: true }),
        )
    ).catch(onUnexpectedError);
  };

  return (
    <div className="admin-section">
      <h4>Linked Organizations</h4>
      <p>
        {loading
          ? "Loading..."
          : !Object.keys(connected).length
            ? "Linked to no organizations"
            : `Linked to ${organizations
                .filter((o) => connected[o.id])
                .map((o) => o.name)
                .join(", ")}.`}
      </p>
      <FormGroup className="d-flex flex-row-reverse">
        <Dropdown
          isOpen={linking}
          toggle={() => setLinking((l) => !l)}
          className="w-25"
        >
          <DropdownToggle className="w-100" color="primary">
            <IconWithText icon={faLink} text="Link Organizations" />
          </DropdownToggle>
          <DropdownMenu>
            {organizations.map(({ id, name }) => (
              <DropdownItem
                key={id}
                toggle={false}
                className="d-flex"
                onClick={onLinkOrganization(id)}
              >
                <Input
                  id="checkbox2"
                  type="checkbox"
                  checked={connected[id] === true}
                  onChange={() => {}}
                  inline
                />
                <Label check>{name}</Label>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </FormGroup>
    </div>
  );
};

const RedisSection: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const { platform } = useContext(PlatformContext);
  const { organization } = platform!;
  const [submitting, setSubmitting] = useState(false);

  const onPurgeCache = (e: MouseEvent) => {
    e.preventDefault();
    setSubmitting(true);
    purgeCache(organization.id)
      .then(() => setAlert("Cache purged.", true))
      .catch(onUnexpectedError)
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="admin-section">
      <h4>Redis Cache</h4>
      <p>
        Things fall apart; the centre cannot hold;
        <br />
        Mere anarchy is loosed upon the world,
        <br />
        The blood-dimmed tide is loosed, and everywhere
        <br />
        The ceremony of innocence is drowned.
      </p>
      <FormGroup className="pull-right">
        <ButtonWithIconAndText
          onClick={onPurgeCache}
          type="submit"
          color="primary"
          icon={faEraser}
          text="Purge Cache"
          className="w-25"
          disabled={submitting}
        />
      </FormGroup>
    </div>
  );
};

const olExportDashboard = async () =>
  axios.post(apiUrl("/overlord/dashboards"), null, axiosBlobConfig);

const DashboardsSection: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const [downloading, setDownloading] = useState(false);

  const doExportDashboards = (e: MouseEvent) => {
    e.preventDefault();
    setDownloading(true);
    olExportDashboard()
      .then(downloadAttachment("Dashboards.zip"))
      .catch(onUnexpectedError)
      .finally(() => setDownloading(false));
  };

  return (
    <div className="admin-section">
      <h4>Dashboards and Reports</h4>
      <p>
        Export current organization's dashboards and reports to a .zip of .json
        files.
      </p>
      <FormGroup className="pull-right">
        <ButtonWithIconAndText
          onClick={doExportDashboards}
          type="submit"
          color="primary"
          icon={faFileDownload}
          text="Export Dashboards"
          className="w-25"
          disabled={downloading}
        />
      </FormGroup>
    </div>
  );
};

interface DisableOrganizationModalProps extends WithAlertModalProps {
  organization: Organization;
}

const DisableOrganizationSection: React.FC<DisableOrganizationModalProps> = ({
  onUnexpectedError,
  organization,
}) => {
  const [serverError, setServerError] = useState<string>("");
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="admin-section">
      <h4>Disable and Hide Organization</h4>
      <p>Disable and hide this organization from the platform.</p>
      <FormGroup className="pull-right">
        <ButtonWithIconAndText
          style={{ color: "white" }}
          onClick={() => setShowModal(true)}
          type="submit"
          color="danger"
          icon={faEraser}
          text="Disable Organization"
          className="w-25"
        />
      </FormGroup>
      {showModal && (
        <DisableOrganizationModal
          organization={organization}
          onClose={() => setShowModal(false)}
          onSubmit={() => {
            setShowModal(false);
            setSessionOrganization(null);
            setOrganization(1).then(() => (document.location.href = "/"));
          }}
        />
      )}
    </div>
  );
};

const ManageOrganizationPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const { platform } = useContext(PlatformContext);
  const {
    organization: { application },
  } = platform!;

  return (
    <>
      <ModernCrumbar primary="Manage Organization" />
      <LoadilyFadily className="jh-page-layout">
        <div className="jh-page-content organization-admin-page admin-page">
          <OrganizationSettingsSection
            setAlert={setAlert}
            onUnexpectedError={onUnexpectedError}
          />

          {application === "PC" && (
            <LinkedOrganizationsSection
              setAlert={setAlert}
              onUnexpectedError={onUnexpectedError}
            />
          )}
          <RedisSection
            setAlert={setAlert}
            onUnexpectedError={onUnexpectedError}
          />
          <DashboardsSection
            setAlert={setAlert}
            onUnexpectedError={onUnexpectedError}
          />
          {platform?.superAdmin && platform.organization.id != 1 && (
            <DisableOrganizationSection
              setAlert={setAlert}
              onUnexpectedError={onUnexpectedError}
              organization={platform.organization}
            />
          )}
        </div>
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(ManageOrganizationPage);
