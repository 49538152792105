import { FilterByDimension } from "../calculateInsight";
import {
  InsightValues,
  PropertyInsights,
  RangedInsights,
} from "../dataService";
import { KnownInsight as BackendInsight } from "../insightsService";
import {
  ComplexInsightIds,
  InsightVisualizationType,
  Operand,
} from "../models";
import { Property } from "../propertiesService";
import {
  calculateInstantInsightValue,
  calculateInstantPieValues,
  calculateInstantSequenceValues,
  calculateRangeInsightValue,
  calculateRangeScatterValue,
  calculateTableInstantInsightValuesByProperty,
} from "./calculateInsightWorker";

// fake webworker to avoid rewriting everything
class CalculateInsightWorker {
  async calculateInstantSequenceValues(
    complexInsightIds: ComplexInsightIds,
    instantValues: InsightValues,
    instantValuesAYearAgo: InsightValues,
    insightsMap: { [id: string]: BackendInsight },
    hideLabel: boolean = false,
  ) {
    return calculateInstantSequenceValues(
      complexInsightIds,
      instantValues,
      instantValuesAYearAgo,
      insightsMap,
      hideLabel,
    );
  }

  async calculateInstantPieValues(
    complexInsightIds: ComplexInsightIds,
    instantValues: InsightValues,
    instantValuesAYearAgo: InsightValues,
    insightsMap: { [id: string]: BackendInsight },
  ) {
    return calculateInstantPieValues(
      complexInsightIds,
      instantValues,
      instantValuesAYearAgo,
      insightsMap,
    );
  }

  async calculateTableInstantInsightValuesByProperty(
    insightId: number,
    instantValues: PropertyInsights<InsightValues>,
    instantValuesAYearAgo: PropertyInsights<InsightValues>,
    insightsMap: { [id: string]: BackendInsight },
    propertiesHash: { [key: string]: Property },
  ) {
    return calculateTableInstantInsightValuesByProperty(
      insightId,
      instantValues,
      instantValuesAYearAgo,
      insightsMap,
      propertiesHash,
    );
  }

  async calculateInstantInsightValue(
    backendInsightIds: number[],
    vizType: "NUMBER" | "PERCENTAGE",
    instantValuesNow: InsightValues,
    instantValuesAYearAgo: InsightValues,
    insightsMap: { [id: string]: BackendInsight },
    delta?: boolean,
    yoy?: boolean,
    operand?: Operand,
    dimensionFilter?: Record<string, string>,
  ) {
    return calculateInstantInsightValue(
      backendInsightIds,
      vizType,
      instantValuesNow,
      instantValuesAYearAgo,
      insightsMap,
      delta,
      yoy,
      operand,
      dimensionFilter,
    );
  }

  async calculateRangeInsightValue(
    insightId: number,
    rangeData: RangedInsights,
    rangeDataAYearAgo: RangedInsights,
    insightsMap: { [id: string]: BackendInsight },
    propertiesHash: { [key: string]: Property },
    vizType: InsightVisualizationType,
    filterByDimension?: FilterByDimension,
    monthsSelected?: Set<string>,
    yoy?: boolean,
    drillIn?: boolean,
  ) {
    return calculateRangeInsightValue(
      insightId,
      rangeData,
      rangeDataAYearAgo,
      insightsMap,
      propertiesHash,
      vizType,
      filterByDimension,
      monthsSelected,
      yoy,
      drillIn,
    );
  }

  async calculateRangeScatterValue(
    insightId: number,
    rangeData: RangedInsights,
    rangeDataAYearAgo: RangedInsights,
    insightsMap: { [id: string]: BackendInsight },
    propertiesHash: { [key: string]: Property },
    vizType: InsightVisualizationType,
    filterByDimension?: FilterByDimension,
    monthsSelected?: Set<string>,
  ) {
    return calculateRangeScatterValue(
      insightId,
      rangeData,
      rangeDataAYearAgo,
      insightsMap,
      propertiesHash,
      vizType,
      filterByDimension,
      monthsSelected,
    );
  }
}

export default CalculateInsightWorker;
