import { ReportDataColumn, ReportDataRow } from "@joyhub-integration/shared";

import Cell from "./Cell";

const Row = ({
  className,
  row,
  columns,
  to,
}: {
  className?: string;
  row: ReportDataRow;
  columns: ReportDataColumn[];
  to?: string;
}) => (
  <tr className={className}>
    {row.values.map((value, index) => (
      <Cell
        key={index}
        value={value}
        rowType={row.type}
        row={row}
        column={columns[index]}
        to={index === 0 ? to : undefined}
      />
    ))}
  </tr>
);

export default Row;
