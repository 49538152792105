import "./genericReportTable.css";

import {
  baseSortOrder,
  cellStyle,
  ColumnFormat,
  excelCss,
  naturallyCompareRows,
  newSortOrder,
  ReportDataColumn,
  ReportDataRow,
  ReportDataValue,
  ReportSheetData,
  ReportSheetType,
  ReportSorting,
  rowComparer,
  RowType,
  sortReportRows,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { concat, isEqual, isUndefined, range, sumBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router";
import { Alert } from "reactstrap";

import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { countWhile } from "../../utils/array";
import { formatValue } from "../../utils/formatValue";
import { setRemove, setToggle } from "../../utils/set";
import { Bucket, headerBuckets, isBucketStart } from "./bucketUtil";

type DrillInUrl = (key: any) => string | undefined;

const TableCell: React.FC<{
  rowType: RowType;
  value: ReportDataValue;
  column: ReportDataColumn;
  className?: string;
  selected?: boolean;
  colSpan?: number;
  to?: string;
  rowFormat?: ColumnFormat; // pivot tables...
}> = ({
  rowType,
  value,
  column,
  className,
  selected,
  colSpan,
  to,
  rowFormat,
}) => {
  const formatted = formatValue(value, column, rowFormat);
  const cellClass = clsx(className, rowType, column.type, {
    Selected: selected,
  });
  const cellFormats = concat(
    rowFormat?.cellFormats ?? [],
    column.format?.cellFormats ?? [],
  );
  return (
    <td
      className={cellClass}
      style={cellStyle(value, cellFormats, rowType)}
      colSpan={colSpan}
    >
      {to == null ? (
        formatted
      ) : (
        <Link className="cell-link" to={to}>
          {formatted}
        </Link>
      )}
    </td>
  );
};

// A normal row, displays one row. Renders a <tr> using the values of one 'row' object
const TableRow: React.FC<{
  row: ReportDataRow;
  collapse?: number;
  className?: string;
  columns: ReportDataColumn[];
  selectedColumns?: Set<number>;
  sheetType?: ReportSheetType;
  drillIn?: DrillInUrl;
}> = ({
  row: { key, type, values, format },
  className,
  collapse,
  columns,
  selectedColumns,
  sheetType,
  drillIn,
}) => (
  <tr className={clsx(className)}>
    {values.map((value, index) => {
      const to = index === 0 ? drillIn?.(key) : undefined;
      return (index > 0 && index < (collapse ?? 0)) ||
        columns[index]?.display === false ? null : (
        <TableCell
          key={index}
          value={value}
          to={to}
          rowType={type}
          column={columns[index]}
          selected={selectedColumns?.has(columns[index].index)}
          colSpan={index === 0 ? collapse : undefined}
          rowFormat={sheetType === "InsightData" ? format : undefined}
        />
      );
    })}
  </tr>
);

// A pivoted row, displays one *column*. Renders a <tr> using the valueIndex'st element of every row
const PivotedTableRow: React.FC<{
  rows: ReportDataRow[];
  column: ReportDataColumn;
  emptyColumn?: boolean;
  valueIndex: number;
  selectedColumns?: Set<number>;
  drillIn?: DrillInUrl;
  bucket?: Bucket;
  subBucket?: Bucket;
}> = ({
  rows,
  column,
  emptyColumn,
  bucket,
  subBucket,
  valueIndex,
  selectedColumns,
  drillIn,
}) => {
  // TODO: group the rows by type per vertical arrangement
  const [hiddenOverride, setHiddenOverride] = useState(false);
  const hiddenColumn = hiddenOverride ? false : emptyColumn;
  const bucketFormat = column.format?.cellFormats?.find((fmt) =>
    fmt.cellTypes?.includes("Bucket"),
  );
  const headerFormat = column.format?.cellFormats?.find((fmt) =>
    fmt.cellTypes?.includes("Header"),
  );
  return (
    <tr>
      {[bucket, subBucket].map(
        (b, index) =>
          b && (
            <td
              key={index}
              className="Bucket"
              rowSpan={b.width}
              style={bucketFormat ? excelCss(bucketFormat) : undefined}
            >
              {b.label}
            </td>
          ),
      )}
      <td
        className="Header String"
        onClick={() => setHiddenOverride(true)}
        style={{
          height: hiddenColumn ? "10px" : undefined,
          backgroundColor: hiddenColumn ? "#D7D7D7" : undefined,
          cursor: hiddenColumn ? "pointer" : undefined,
          ...(headerFormat ? excelCss(headerFormat) : {}),
        }}
      >
        {hiddenColumn ? "" : column.header}
      </td>
      {rows.flatMap(({ key, type, values, format }, index) => {
        const bucketStart =
          index === 0 || values[0] !== rows[index - 1].values[0];
        const to = valueIndex === 0 ? drillIn?.(key) : undefined;
        return (
          <TableCell
            key={index}
            value={values[valueIndex]}
            to={to}
            rowType={type}
            rowFormat={format}
            column={column}
            selected={selectedColumns?.has(column.index)}
            className={bucketStart ? "BucketStart" : undefined}
          />
        );
      })}
    </tr>
  );
};

// A normal header, displays the 'header' value for each column primarily, plus grouping
export const TableHeader: React.FC<{
  columns: ReportDataColumn[];
  emptyColumns?: Set<number>;
  selectedColumns?: Set<number>;
  sortOrder: ReportSorting[];
  onHeaderClick: (
    index: number,
    width: number,
    event: React.MouseEvent,
  ) => void;
}> = ({ columns, emptyColumns, selectedColumns, sortOrder, onHeaderClick }) => {
  const totalWidth = sumBy(columns, (h) => h.width);
  const { buckets, subBuckets } = headerBuckets(columns);

  const [hiddenOverride, setHiddenOverride] = useState(new Set<number>());

  const hiddenColumns = useMemo(() => {
    return setRemove(emptyColumns ?? new Set(), Array.from(hiddenOverride));
  }, [emptyColumns, hiddenOverride]);

  const emptyColumnClick = (index: number) => {
    setHiddenOverride(setToggle(hiddenOverride, index));
  };

  const headerClass = (index: number) =>
    clsx(columns[index].type, {
      BucketStart: buckets.length > 0 && isBucketStart(columns, index),
      Sorted: index === sortOrder[0]?.[0],
      Ascending: sortOrder[0]?.[1] === 1,
      Descending: sortOrder[0]?.[1] === -1,
      Selected: selectedColumns?.has(columns[index].index),
    });

  const allSelected = (column: number, width: number) =>
    range(column, column + width).every((index) =>
      selectedColumns?.has(
        columns.filter((col) => col?.display !== false)[index].index,
      ),
    );

  return (
    <thead
      className={clsx({ Bucketed: buckets.length + subBuckets.length > 0 })}
    >
      {buckets.length > 0 ? (
        <tr className="Buckets">
          {buckets.map(
            ({ label, width, column, index: bucketIndex }, index) => (
              <th
                key={index}
                className={clsx("Bucket", {
                  Selected: allSelected(column, width),
                })}
                colSpan={width}
                onClick={(e) => onHeaderClick(bucketIndex ?? 0, width, e)}
              >
                {label}
              </th>
            ),
          )}
        </tr>
      ) : null}
      {subBuckets.length > 0 ? (
        <tr className="Buckets">
          {subBuckets.map(
            ({ label, width, column, index: subBucketIndex }, index) => (
              <th
                key={index}
                className={clsx("Bucket", {
                  Selected: allSelected(column, width),
                })}
                colSpan={width}
                onClick={(e) => onHeaderClick(subBucketIndex ?? 0, width, e)}
              >
                {label}
              </th>
            ),
          )}
        </tr>
      ) : null}
      <tr>
        {columns.map(({ width, header, display, filter }, index) => {
          const hiddenColumn = hiddenColumns.has(index);
          return display === false ? null : (
            <th
              key={index}
              id={`header-${index}`}
              style={{
                width:
                  (hiddenColumn ? 0 : Math.floor((100 * width) / totalWidth)) +
                  "%",
                backgroundColor: hiddenColumn ? "#D7D7D7" : "",
              }}
              className={clsx("Header", headerClass(index))}
              onClick={(e) =>
                hiddenColumn
                  ? emptyColumnClick(index)
                  : onHeaderClick(index, 1, e)
              }
            >
              {hiddenColumn ? "" : header}
              {filter ? (
                <FontAwesomeIcon icon={faFilter} size="xs" className="ms-1" />
              ) : null}
              {index === sortOrder[0]?.[0] ? (
                <>
                  <span>{"   "}</span>
                  <span className="Sorter">▾</span>
                </>
              ) : null}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const rowBuckets = (rdc: ReportDataColumn, rows: ReportDataRow[]): Bucket[] => {
  const buckets = new Array<Bucket>();
  let column = 0;
  for (const { type, values } of rows) {
    const label = formatValue(values[0], rdc);
    if (label == null || label !== buckets[buckets.length - 1]?.label) {
      buckets.push({ label, width: 1, column, type });
    } else {
      ++buckets[buckets.length - 1].width;
    }
    ++column;
  }
  return buckets;
};

// A pivoted header, displays the values[0] element of each row, does not yet support grouping features
export const PivotedTableHeader: React.FC<{
  rows: ReportDataRow[];
  columns: ReportDataColumn[];
  drillIn?: DrillInUrl;
}> = ({ rows, columns, drillIn }) => {
  const bucketCount =
    (columns.some((col) => col.bucket != null) ? 1 : 0) +
    (columns.some((col) => col.subBucket != null) ? 1 : 0);

  let rowWidth = 12; // header
  for (const { width } of rows) {
    rowWidth += width ?? 12;
  }
  const width = 100 / (rows.length + 1) + "%";

  const headerColumns = columns.filter((col) => col.index === 0);
  const bucketColumn = headerColumns[headerColumns.length - 2];
  const headerColumn = headerColumns[headerColumns.length - 1];
  const buckets = bucketColumn ? rowBuckets(bucketColumn, rows) : [];

  // for now we only support one level of pivot header buckets
  return (
    <thead className={clsx({ Bucketed: headerColumns.length > 1 })}>
      {bucketColumn && (
        <tr className="Buckets">
          <th className="Header String" colSpan={1 + bucketCount}>
            {headerColumn.header}
          </th>
          {buckets.map(({ label, width, type }) => (
            <th className={clsx("Bucket", type)} colSpan={width}>
              {label}
            </th>
          ))}
        </tr>
      )}
      <tr>
        <th
          className="Header String"
          style={{ width: (100 * 12) / rowWidth + "%" }}
          colSpan={1 + bucketCount}
        >
          {headerColumns.length === 1 ? headerColumn.header : ""}
        </th>

        {rows.map(({ type, key, values, group, width }, index) => {
          const to = type === "Normal" ? drillIn?.(key) : undefined;
          const formatted = formatValue(
            values[headerColumns.length - 1],
            headerColumn,
          );
          return (
            <th
              key={index}
              className={clsx("Header", type, {
                Grouped: group != null,
                BucketStart: buckets.some((b) => b.column === index),
              })}
              style={{ width: (100 * (width ?? 12)) / rowWidth + "%" }}
            >
              {to == null ? (
                formatted
              ) : (
                <Link className="cell-link" to={to}>
                  {formatted}
                </Link>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export const TableBody: React.FC<{
  rows: ReportDataRow[];
  columns: ReportDataColumn[];
  selectedColumns?: Set<number>;
  sheetType?: ReportSheetType;
  drillIn?: DrillInUrl;
}> = ({ columns, selectedColumns, rows, sheetType, drillIn }) => {
  const propertyCells = countWhile(columns, (h) => h.type === "String");
  const isEmpty = !rows.length;
  return (
    <tbody>
      {!isEmpty ? null : (
        <tr className="NoData text-muted">
          <td colSpan={columns.length}>No results in this report</td>
        </tr>
      )}
      {rows.map((row, index) => {
        return (
          <TableRow
            key={index}
            className={clsx({ Grouped: row.group != null })}
            row={row}
            collapse={row.type === "Group" ? propertyCells : 0}
            columns={columns}
            selectedColumns={selectedColumns}
            drillIn={drillIn}
            sheetType={sheetType}
          />
        );
      })}
    </tbody>
  );
};

const toBucketMap = (buckets: Bucket[]): Record<number, Bucket> => {
  const bucketMap: Record<number, Bucket> = {};
  let bucketIndex = 0;
  for (const bucket of buckets) {
    bucketMap[bucketIndex] = bucket;
    bucketIndex += bucket.width;
  }
  return bucketMap;
};

export const PivotedTableBody: React.FC<{
  rows: ReportDataRow[];
  columns: ReportDataColumn[];
  emptyColumns?: Set<number>;
  selectedColumns?: Set<number>; //not used currently
}> = ({ columns, emptyColumns, selectedColumns, rows }) => {
  // TODO: actually do selections on pivots, a tricky matter
  const { buckets, subBuckets } = headerBuckets(columns);
  const headerColumns = columns.filter((col) => col.index === 0);
  // buckets will probably fail if the first column shares a bucket with the second column
  const bucketMap = toBucketMap(buckets);
  const subBucketMap = toBucketMap(subBuckets);

  const isEmpty = !rows.length;
  return (
    <tbody className={clsx({ Bucketed: headerColumns.length > 1 })}>
      {columns
        .map((col, index) => ({ ...col, idx: index }))
        .filter((col) => col.display !== false)
        .map((column, index) =>
          //skip the first, values[0] was already rendered as the header row
          column.index === 0 ? null : (
            <PivotedTableRow
              key={index}
              bucket={bucketMap[index]}
              subBucket={subBucketMap[index]}
              rows={rows}
              column={column}
              emptyColumn={emptyColumns?.has(column.idx)}
              valueIndex={column.idx}
            />
          ),
        )}
      {!isEmpty ? null : (
        <tr className="NoData text-muted">
          <td colSpan={columns.length}>No results in this report</td>
        </tr>
      )}
    </tbody>
  );
};

// TODO: should the print header be revealable in desktop view?
// TODO: if you have resorted the view then reflect that in the excel download
// TODO: reSorting by a leading dimension column should preserve original order
//   in particular with respect to annual income which otherwise sorts badly

export type GenericReportTableProps = {
  data: ReportSheetData;
  drillIn?: DrillInUrl;
  property?: {
    name?: string;
    sortColumnId?: number;
    sortType?: string;
    rowCount?: number;
  };
};

export const GenericReportTable: React.FC<GenericReportTableProps> = ({
  data,
  drillIn,
  property,
}) => {
  const {
    titles,
    columns,
    rows,
    grouping,
    pivot,
    compact,
    error,
    type: sheetType,
  } = data;

  const [sortOrder, setSortOrder] = useState(() => baseSortOrder(columns));

  const isDynamic = useMemo(
    () =>
      isEqual(property?.name, "dynamic") &&
      !isUndefined(property?.rowCount) &&
      !isUndefined(property?.sortColumnId) &&
      !isUndefined(property?.sortType),
    [
      property?.name,
      property?.rowCount,
      property?.sortColumnId,
      property?.sortType,
    ],
  );

  const onResort = (index: number) => {
    if (columns[index].type !== "NoValue") {
      setSortOrder(newSortOrder(columns, sortOrder, index));
    }
  };

  // exclude columns with all null values (if they are intended to have values)
  const emptyColumns = useMemo(
    () =>
      new Set(
        columns.flatMap((c, index) => {
          const emptyValues = rows.every((r) => r.values[index] == null);
          return emptyValues && !c.visible ? [index] : [];
        }) || [],
      ),
    [columns, rows],
  );

  const sortedRows = useMemo(() => {
    const compareRows = rowComparer(sortOrder);
    const compareGroups =
      grouping?.sort === "Alphabetic" ? naturallyCompareRows : compareRows;
    const sortedNewRows = sortReportRows(
      rows,
      compareRows,
      compareGroups,
      grouping,
    );

    if (!property?.rowCount) return sortedNewRows;
    else return sortedNewRows.slice(0, property?.rowCount);
  }, [sortOrder, grouping, rows, property?.rowCount]);

  useEffect(() => {
    if (
      isDynamic &&
      !isUndefined(property?.sortColumnId) &&
      property?.sortType
    ) {
      const newOrder = isEqual(property?.sortType, "asc") ? 1 : -1;
      setSortOrder([
        [property?.sortColumnId, newOrder],
        [0, -1],
      ]);
    }
  }, [isDynamic, property?.sortColumnId, property?.sortType]);

  return (
    <div
      className={clsx(
        "generic-report-table",
        `grouping-${grouping?.layout ?? "None"}`,
        {
          pivot,
          compact,
        },
      )}
    >
      <div className="generic-report-table-header">
        {titles.map(({ value, ...styles }, index) => (
          <div
            key={index}
            className="chart-table-title"
            style={excelCss(styles)}
          >
            {value}
          </div>
        ))}
      </div>
      {error == null ? null : <Alert color="danger">{error}</Alert>}
      <table className="generic-report-table-root">
        {!pivot ? (
          <>
            <TableHeader
              columns={columns}
              emptyColumns={emptyColumns}
              sortOrder={sortOrder}
              onHeaderClick={onResort}
            />
            <TableBody
              rows={sortedRows}
              columns={columns}
              drillIn={drillIn}
              sheetType={sheetType}
            />
          </>
        ) : (
          <>
            <PivotedTableHeader
              rows={sortedRows}
              columns={columns}
              drillIn={drillIn} // table header renders value[0], the only value used for drillIn
            />
            <PivotedTableBody
              rows={sortedRows}
              columns={columns}
              emptyColumns={emptyColumns}
            />
          </>
        )}
      </table>
    </div>
  );
};

/* TODO: Bring me back

const periodStr = (a: Aggregate | undefined, p: Period) =>
  (a ?? "Average") + (isMonthsPeriod(p) ? `${p.months}Months` : p);

const columnDescription = (
  { insight, timeFrame, versus, period, aggregate }: ReportInsightColumn,
  insightsMap: Record<string, Insight>
): string => {
  const insightDescription = (insight: ReportInsight): string =>
    isFractionalInsightDefinition(insight)
      ? `\\frac{${insightDescription(insight.numerator)}}{${insightDescription(
          insight.denominator
        )}}`
      : insightsMap[insight.toString()]?.name ?? "Unknown";
  const timeFrameInsightDescription = (insight: ReportInsight, timeFrame?: TimeFrame): string =>
    insightDescription(insight) +
    (timeFrame == null ? "" : `_{${timeFrame}}`) +
    (period == null ? "" : `^{${periodStr(aggregate, period)}}`);
  const primary = timeFrameInsightDescription(insight, timeFrame);
  if (versus == null) {
    return primary;
  } else {
    const secondary = timeFrameInsightDescription(versus.insight, versus.timeFrame);
    switch (versus.comparison) {
      case "Difference":
        return `${primary}-${secondary}`;
      case "Fraction":
        return `\\frac{${primary}}{${secondary}}`;
      case "Delta":
        return `\\frac{${primary}-${secondary}}{${secondary}}`;
    }
  }
};

  columns.map((column, index) =>
  isExportInsightColumn(column) ? (
    <UncontrolledTooltip
      key={index}
      target={`header-${index}`}
      placement="bottom-end"
      innerClassName="chart-table-tooltip"
      delay={{ show: 300, hide: 0 }}
    >
      <MathJax.Provider>
        <MathJax.Node formula={columnDescription(column, insightsMap)} />
      </MathJax.Provider>
    </UncontrolledTooltip>
  ) : null
)*/
