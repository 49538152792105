// Missing Dimensional and Summed
// TODO: add RelativeChange

export const insightSchema = {
  $id: "https://example.org/schemas/insight.json",
  $schema: "http://json-schema.org/draft-07/schema#",
  $ref: "#/definitions/ReportInsight",
  title: "Definition",
  options: {
    disable_collapse: true,
    disable_edit_json: false,
  },
  definitions: {
    ReportInsight: {
      anyOf: [
        {
          title: "Insight",
          $ref: "#/definitions/Insight",
        },
        {
          title: "Goal",
          type: "object",
          additionalProperties: false,
          properties: {
            goal: {
              title: "Goal Insight",
              $ref: "#/definitions/Insight",
            },
          },
          required: ["goal"],
        },
        {
          title: "Add",
          type: "object",
          additionalProperties: false,
          properties: {
            addends: {
              title: "Addends",
              type: "array",
              format: "table",
              items: {
                $ref: "#/definitions/ReportInsight",
              },
              minItems: 1,
              options: {
                disable_array_reorder: true,
                disable_array_delete_all_rows: true,
                disable_array_delete_last_row: true,
              },
            },
          },
          required: ["addends"],
        },
        {
          title: "Subtract",
          type: "object",
          additionalProperties: false,
          properties: {
            minuend: {
              title: "Minuend",
              $ref: "#/definitions/ReportInsight",
            },
            subtrahends: {
              title: "Subtrahends",
              type: "array",
              format: "table",
              items: {
                $ref: "#/definitions/ReportInsight",
              },
              minItems: 1,
              options: {
                disable_array_reorder: true,
                disable_array_delete_all_rows: true,
                disable_array_delete_last_row: true,
              },
            },
          },
          required: ["minuend", "subtrahends"],
        },
        {
          title: "Multiply",
          type: "object",
          additionalProperties: false,
          properties: {
            multiplicand: {
              title: "Multiplicand",
              $ref: "#/definitions/ReportInsight",
            },
            multipliers: {
              title: "Multipliers",
              type: "array",
              format: "table",
              items: {
                $ref: "#/definitions/ReportInsight",
              },
              minItems: 1,
              options: {
                disable_array_reorder: true,
                disable_array_delete_all_rows: true,
                disable_array_delete_last_row: true,
              },
            },
          },
          required: ["multiplicand", "multipliers"],
        },
        {
          title: "Divide",
          type: "object",
          additionalProperties: false,
          properties: {
            numerator: {
              title: "Numerator",
              $ref: "#/definitions/ReportInsight",
            },
            denominator: {
              title: "Denominator",
              $ref: "#/definitions/ReportInsight",
            },
          },
          required: ["numerator", "denominator"],
        },
        {
          title: "Delta",
          type: "object",
          additionalProperties: false,
          properties: {
            delta: {
              title: "Value",
              $ref: "#/definitions/ReportInsight",
            },
            versus: {
              title: "Versus",
              $ref: "#/definitions/ReportInsight",
            },
          },
          required: ["delta", "versus"],
        },
        {
          title: "Function",
          type: "object",
          additionalProperties: false,
          properties: {
            fn: {
              title: "Function",
              type: "string",
              enum: ["numerator", "denominator", "absolute", "negate"],
            },
            parameters: {
              title: "Parameters",
              type: "array",
              format: "table",
              items: {
                $ref: "#/definitions/Insight",
              },
              minItems: 1,
              options: {
                disable_array_reorder: true,
                disable_array_delete_all_rows: true,
                disable_array_delete_last_row: true,
              },
            },
          },
          required: ["fn", "parameters"],
        },
        {
          title: "Constant",
          type: "object",
          additionalProperties: false,
          properties: {
            constant: {
              title: "Value",
              type: "number",
            },
          },
          required: ["constant"],
        },
      ],
    },
    Insight: {
      type: "number",
    },
  },
};
