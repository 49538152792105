import "./alertModal.css";

import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";

interface AlertModalProps {
  success: boolean;
  message: string;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const AlertModal: React.FC<AlertModalProps> = ({
  message,
  success,
  isOpen,
  setIsOpen,
}) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (isOpen) {
      const timeout = success ? 3000 : 10000;
      const newTimeoutId = setTimeout(() => setIsOpen(false), timeout);
      setTimeoutId(newTimeoutId);
    }
  }, [setIsOpen, isOpen, success]);

  function toggle() {
    if (timeoutId && isOpen) {
      clearTimeout(timeoutId);
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody className="flex-col jh-alert-modal-container">
        <FontAwesomeIcon
          icon={success ? faCheckCircle : faExclamationCircle}
          color={success ? "#629922" : "#E14C35"}
          size="2x"
        />
        <p className="text-center">{message}</p>
      </ModalBody>
    </Modal>
  );
};

export default AlertModal;
