import React, { useState } from "react";
import { Input } from "reactstrap";

const EditIdentifier: React.FC<{
  identifier: string;
  setIdentifier: (id: string) => void;
}> = ({ identifier, setIdentifier }) => {
  const [editing, setEditing] = useState(false);

  return (
    <div
      style={{
        position: "absolute",
        top: ".75rem",
        right: "1.25rem",
        fontWeight: "normal",
        fontSize: "1rem",
        zIndex: 1,
      }}
      className="text-muted text-small w-auto"
    >
      {editing ? (
        <Input
          type="text"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          onBlur={() => setEditing(false)}
          autoFocus
        />
      ) : (
        <span
          style={{ lineHeight: "2.5rem" }}
          onDoubleClick={() => setEditing(true)}
        >
          {identifier}
        </span>
      )}
    </div>
  );
};

export default EditIdentifier;
