import { isNumberArray } from "../../utils/array";
import { Dimension, Insight, InsightVisualizationType } from "../models";
import insightDefinitions from "./insightDefinitions";

export class InsightLibraryService {
  insights: Array<Insight> = insightDefinitions;
  dimensions: Dimension[] = [];

  // get list of data sources
  getInsights(id?: number): Promise<Array<Insight>> {
    const insights = id
      ? this.insights.filter((item) => item.id === id)
      : this.insights;
    return Promise.resolve(insights);
  }

  getInsightDimensions(insightId: number): Promise<Array<Dimension>> {
    return Promise.resolve(
      this.dimensions.filter((d) => d.insightId === insightId),
    );
  }

  getAllChildInsightIds(
    insightId: number,
    yoy: boolean = false,
    selectedVizType?: InsightVisualizationType,
    drillIn: boolean = false,
  ): number[] {
    const ret = new Set<number>();
    const insight = this.insights.find((i) => i.id === insightId);

    const childInsightIds = drillIn
      ? (insight?.drillInInsights ?? insight?.insightIds)
      : insight?.insightIds;

    if (insight && childInsightIds != null) {
      if (isNumberArray(childInsightIds)) {
        if (!yoy || selectedVizType === "YOY CHANGE") {
          childInsightIds.forEach((id) => ret.add(id));
        }
      } else {
        for (const complexInsightIds of childInsightIds) {
          for (const insightId of complexInsightIds.insightIds) {
            if (!yoy || complexInsightIds.yoy) {
              ret.add(insightId);
            }
          }
        }
      }
    }
    return Array.from(ret);
  }
}

const libraryService = new InsightLibraryService();

export default libraryService;
