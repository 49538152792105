import { IntegrationFormProps } from "./common";
import EntrataForm from "./EntrataForm";
import FortressForm from "./FortressForm";
import JoyHubForm from "./JoyHubForm";
import ManualForm from "./ManualForm";
import RealPageForm from "./RealPageForm";
import ResManForm from "./ResManForm";
import YardiForm from "./YardiForm";

export const vendorToFormComponentMap: {
  [key: string]: React.FC<IntegrationFormProps>;
} = {
  Entrata: EntrataForm,
  Fortress: FortressForm,
  JoyHub: JoyHubForm,
  RealPage: RealPageForm,
  ResMan: ResManForm,
  Yardi: YardiForm,
  Manual: ManualForm,
};
