import React, { useCallback, useEffect, useState } from "react";

import {
  CloudFormationStack,
  getYardiRestoreStacks,
} from "../../../services/cloudService";
import { dateOf, dateToTimeString } from "../../../utils/date";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import TableWithoutSelection from "../../common/table/TableWithoutSelection";
import { KeyValue } from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";

const CloudFormationStacks: React.FC<{ stacks: CloudFormationStack[] }> = ({
  stacks,
}) => {
  const tableCols: Array<KeyValue<any>> = [
    {
      key: "StackName",
      title: "Stack Name",
    },
    {
      key: "StackStatus",
      title: "Status",
    },
    {
      key: "CreationTime",
      title: "Created Time",
      toValue: (s: CloudFormationStack) =>
        dateToTimeString(dateOf(s.CreationTime)),
    },
  ];

  return (
    <div className="jh-table-wrapper">
      <TableWithoutSelection
        columns={tableCols}
        rows={stacks}
        sortColumn="Created Time"
      />
    </div>
  );
};

const CloudHealthPage: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const [stacks, setStacks] = useState<Array<CloudFormationStack>>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchYardiStacks = useCallback(
    function () {
      return getYardiRestoreStacks()
        .then((cfnRes) => {
          cfnRes.sort((a, b) => a.StackName.localeCompare(b.StackName));
          setStacks(cfnRes);
        })
        .catch(onUnexpectedError);
    },
    [onUnexpectedError],
  );

  useEffect(() => {
    fetchYardiStacks().then(() => setLoaded(true));
  }, [fetchYardiStacks]);

  return (
    <>
      <ModernCrumbar primary="Cloud Health" />
      <div className="action-bar-padding" />
      <LoadilyFadily className="jh-page-layout" loaded={loaded}>
        <div className="jh-page-content pt-0 admin-page page-scroll no-action-bar">
          <div className="admin-section">
            {stacks && <CloudFormationStacks stacks={stacks} />}
          </div>
        </div>
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(CloudHealthPage);
