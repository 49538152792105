import { useEffect, useRef } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

import { ValidationError } from "../../../../admin/integrations/addEditIntegrationModal/common";

interface NameFieldPropsType {
  name?: string;
  validationError: ValidationError;
  updateName: (name: string) => void;
}

export const NameField = (props: NameFieldPropsType) => {
  const { name = "", validationError, updateName } = props;

  const nameField = useRef<HTMLInputElement>();

  const updateRows = () => {
    if (nameField.current)
      nameField.current.setAttribute("rows", name.includes("\n") ? "2" : "1");
  };

  const setNameField = (el: HTMLInputElement) => {
    nameField.current = el;
    updateRows();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateName(e.target.value);
  };

  useEffect(updateRows, [name]);

  const isInvalid = validationError.field === "name";

  return (
    <FormGroup>
      <Label>Title of the card</Label>
      <Input
        type="textarea"
        value={name}
        invalid={isInvalid}
        innerRef={setNameField}
        onChange={handleChange}
      />
      {isInvalid && validationError.message && (
        <FormFeedback>{validationError.message}</FormFeedback>
      )}
    </FormGroup>
  );
};
