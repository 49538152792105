/**
 * Date.getMonth return type
 */
import { PureDate } from "./pureDate";

export type MonthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

// value can be year-month-date like '2020-01-01'
export const parsePureDate = (
  value: string | undefined,
): PureDate | undefined => {
  if (value == undefined) return undefined;

  const date = new Date(value);
  if (date.toString() === "Invalid Date") {
    return undefined;
  } else {
    // Parses '2020-01-01' into a date in the local timezone, otherwise it is interpreted at 00:00:00 UTC
    // and so the locale date is off by one.
    const localDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000,
    );
    return new PureDate(localDate);
  }
};

export const maxDate = (
  d1: PureDate | null,
  d2: PureDate | null,
): PureDate | null => {
  if (d1 != null && d2 != null) {
    const maxTime = Math.max(d1.getTime(), d2.getTime());
    return maxTime === d1.getTime() ? d1 : d2;
  } else if (d1 == null) {
    return d2 == null ? null : d2;
  } else {
    return d1;
  }
};

export const durationStr = (ms: number) =>
  ms < 1000
    ? `${ms}ms`
    : ms < 60000
      ? `${Number(ms / 1000).toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}s`
      : `${Number(ms / 60000).toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}m ${Number((ms % 60000) / 1000).toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}s`;

export const durationOf = (
  start: string | undefined | null,
  stop: string | undefined | null,
) =>
  start == null || stop == null
    ? "–"
    : durationStr(new Date(stop).getTime() - new Date(start).getTime());

export const dateStr = (s: string | undefined | null) =>
  s == null ? "–" : new Date(s).toLocaleString();

export const yesterday = () => new PureDate().withPlusDays(-1);

// On 2024-09-09T00:00:00Z it is 8pm ET, 5pm PT on 9/8/2024, so consider data to be for 2024-09-07
// On 2024-09-09T01:00:00Z it is 9pm ET, 6pm PT on 9/8/2024, so consider data to be for 2024-09-08
export const usCentricYesterdayUnlessItIsTheEvening = (
  date: Date = new Date(),
) => {
  date.setHours(date.getHours() - 25); // 21 hours for the 9pm ET plus 4 hours for the (current) offset from ET to Z
  // Then I have to do this weird thing into PureDate because it maliciously adjusts the date again for local TZ
  return new PureDate(date.toISOString().substring(0, 10));
};
