import {
  AttachmentType,
  PropertiesSelection,
} from "@joyhub-integration/shared";

import { apiUrl, axiosConfig, axiosJsonConfig } from "../utils/api";
import axios from "./axios";

interface ScheduledEmailConfig {
  properties: PropertiesSelection;
  notifyWhenGoal?: "met" | "failed" | "both";
  schedule?: string;
  propertyId?: number;
  attachmentType?: AttachmentType;
}

interface CreateScheduledEmailDto {
  definition_id: number;
  schedule: string;
  active: boolean;
  configuration: ScheduledEmailConfig;
  emailAddresses: string[];
}

export interface ScheduledEmail extends CreateScheduledEmailDto {
  id: number;
  organization_id: number;
}

interface GetScheduledEmailsResponse {
  scheduledEmails: ScheduledEmail[];
}

export async function getScheduledEmails(
  definition: number,
  propertyId?: number,
): Promise<ScheduledEmail[]> {
  const propertyIdParam = propertyId ? `&propertyId=${propertyId}` : "";
  return axios
    .get<GetScheduledEmailsResponse>(
      apiUrl(`/scheduledEmails?definition=${definition}${propertyIdParam}`),
      axiosConfig,
    )
    .then((res) => res.data.scheduledEmails);
}

export async function createScheduledEmail(
  dto: CreateScheduledEmailDto,
): Promise<ScheduledEmail> {
  return axios
    .post<ScheduledEmail>(apiUrl("/scheduledEmails"), dto, axiosJsonConfig)
    .then((res) => res.data);
}

export async function fetchRenderedEmail(
  id: number,
): Promise<{ subject: string; body: string }> {
  return axios
    .get(apiUrl(`/scheduledEmails/${id}/render`), axiosConfig)
    .then((res) => res.data);
}

export async function updateScheduledEmailStatus(
  id: number,
  active: boolean,
): Promise<boolean> {
  return axios
    .put<boolean>(
      apiUrl(`/scheduledEmails/${id}/active`),
      active.toString(),
      axiosJsonConfig,
    )
    .then((res) => res.data);
}

export async function getScheduledEmail(id: number): Promise<ScheduledEmail> {
  return axios
    .get<ScheduledEmail>(apiUrl(`/scheduledEmails/${id}`), axiosConfig)
    .then((res) => res.data);
}

export async function deleteScheduledEmail(id: number): Promise<void> {
  return axios.delete(apiUrl(`/scheduledEmails/${id}`), axiosConfig);
}

export type SendEmailResult = {
  delivered: string[];
  optedOut: string[];
  bounced: string[];
  failed: string[];
};
