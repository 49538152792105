import React, { useState } from "react";
import { editColumn } from "../../../services/propertiesService";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import "./properties.scss";
import PropertyCell, { PropertyCellProps } from "./PropertyCell";

type PropertyColumnCellProps = {
  propertyId: number;
};

type OmittedPropertyCellProps = Omit<
  PropertyCellProps,
  "editMode" | "setEditMode" | "setCellValue" | "cellValue" | "onSendValue"
>;

const PropertyColumnCell: React.FC<
  WithAlertModalProps & PropertyColumnCellProps & OmittedPropertyCellProps
> = ({
  id,
  propertyId,
  dataType,
  isLocked,
  columnKey,
  originalValue,
  textColumnOptions,
  onUnexpectedError,
  onBeginEdit,
  onFinishEdit,
  onSavedValue,
}) => {
  const [cellValue, setCellValue] = useState<string>(originalValue);
  const [editMode, setEditMode] = useState(false);

  const sendValue = (value: string) => {
    if (value === originalValue) {
      return;
    }
    editColumn(propertyId, columnKey, dataType, value)
      .then(() => {
        if (dataType === "text") {
          onSavedValue(originalValue, value);
        }
      })
      .catch((e) => {
        setCellValue(originalValue);
        onUnexpectedError(e);
      });
  };

  return (
    <PropertyCell
      key={id}
      id={id}
      columnKey={columnKey}
      dataType={dataType}
      isLocked={isLocked}
      originalValue={originalValue}
      textColumnOptions={textColumnOptions}
      cellValue={cellValue}
      setCellValue={setCellValue}
      onSendValue={sendValue}
      onSavedValue={onSavedValue}
      onBeginEdit={onBeginEdit}
      onFinishEdit={onFinishEdit}
      setEditMode={setEditMode}
      editMode={editMode}
    />
  );
};

export default withAlertModal(PropertyColumnCell);
