// Adapted from hash-prospector (https://nullprogram.com/blog/2018/07/31/).
// Want to thoroughly mix a field-specific seed and the (integer) primary
// key to produce a seed for faker.*.  hash-prospector seems to have found
// one of the best functions for doing this with 32-bit numbers.  JS doesn't
// really do 64-bit, so eh..
const hashInt = (h: number, v: number): number => {
  const v32 = v >>> 0; // JS for "convert to unsigned 32-bit", apparently.
  h ^= v32;
  h *= 0xed5ad4bb;
  h ^= h >> 11;
  h *= 0xac4c1b51;
  h ^= h >> 15;
  h *= 0x31848bab;
  h ^= h >> 14;
  return h;
};

const hashCore = (h: number, v: number | string): number => {
  if (typeof v === "string") {
    const len = v.length;
    for (let i = 0; i < len; ++i) {
      h = hashInt(h, v.charCodeAt(i));
    }
    return h;
  }
  return hashInt(h, v);
};

/** Hash a string or number. */
export const hashValue = (a: string | number) => hashCore(0x31848bab, a);

/** Hash an array of strings or numbers. */
export const hashKeys = (a: (string | number)[]) =>
  a.reduce(hashCore, 0x31848bab);

/** Hash an object with string or number keys and values. */
export const hashObject = <
  A extends string | number,
  B extends string | number,
>(
  ab: Record<A, B>,
): number => {
  let hash = 0x31848bab;
  for (const a of Object.keys(ab).sort()) {
    hash = hashCore(hash, a);
    hash = hashCore(hash, ab[a as A]); // this type is a lie because a is always a string but.. javascript
  }
  return hash;
};

/** Test for equality of objects with string or number keys and values. */
// see also nodejs util.isDeepStrictEqual, but we are in shared and
// front-end has no nodejs util package.
export const equalObject = <
  A extends string | number,
  B extends string | number,
>(
  ab0: Record<A, B>,
  ab1: Record<A, B>,
): boolean => {
  const as = Object.keys(ab0);
  if (as.length !== Object.keys(ab1).length) return false;
  for (const a of as) {
    if (ab0[a as A] !== ab1[a as A]) return false;
  }
  return true;
};

/** A map where the keys are objects. This does not maintain insertion order. */
export class ObjectKeyedMap<A extends object, B> implements Iterable<[A, B]> {
  private map = {} as Record<number, [A, B][]>;

  constructor(
    private hash: (a: A) => number,
    private equal: (a0: A, a1: A) => boolean,
  ) {}

  put(key: A, value: B): void {
    const digest = this.hash(key);
    const entries = this.map[digest];
    if (entries == null) {
      this.map[digest] = [[key, value]];
    } else {
      const entry = entries.find(([k]) => this.equal(k, key));
      if (entry === undefined) {
        entries.push([key, value]);
      } else {
        entry[1] = value;
      }
    }
  }

  update(key: A, f: (existing: B) => B, b: B): void {
    const digest = this.hash(key);
    const entries = this.map[digest];
    if (entries == null) {
      this.map[digest] = [[key, f(b)]];
    } else {
      const entry = entries.find(([k]) => this.equal(k, key));
      if (entry === undefined) {
        entries.push([key, f(b)]);
      } else {
        entry[1] = f(entry[1]);
      }
    }
  }

  get(key: A): B | undefined {
    const digest = this.hash(key);
    const entries = this.map[digest];
    if (entries == null) {
      return undefined;
    } else {
      const entry = entries.find(([k]) => this.equal(k, key));
      return entry?.[1];
    }
  }

  has(key: A): boolean {
    const digest = this.hash(key);
    const entries = this.map[digest];
    if (entries == null) {
      return false;
    } else {
      const entry = entries.find(([k]) => this.equal(k, key));
      return entry !== undefined;
    }
  }

  keySet() {
    const keys: A[] = [];
    for (const entries of Object.values(this.map)) {
      for (const entry of entries) {
        keys.push(entry[0]);
      }
    }
    return keys;
  }

  values() {
    const values: B[] = [];
    for (const entries of Object.values(this.map)) {
      for (const entry of entries) {
        values.push(entry[1]);
      }
    }
    return values;
  }

  entrySet() {
    const entrySet: [A, B][] = [];
    for (const entries of Object.values(this.map)) {
      entrySet.push(...entries);
    }
    return entrySet;
  }

  [Symbol.iterator]() {
    return this.entrySet()[Symbol.iterator]();
  }

  /** A constructor where the types conform to `hashObject` and `equalObject`. */
  // The type argument `A` will usually need to be a `type` not an `interface`
  // because of declaration merging. `type` types do not merge, so it is
  // always known if the keys/values of `type` conform to the `Record`
  // constraint. The same `interface` type might conform at one callsite and not
  // another.
  static empty = <
    A extends Record<string | number, string | number>,
    B,
  >(): ObjectKeyedMap<A, B> =>
    new ObjectKeyedMap<A, B>(hashObject, equalObject);
}

/** A set where the values are objects. */
export class ObjectKeyedSet<A extends object> {
  private map: ObjectKeyedMap<A, boolean>;

  constructor(
    private hash: (a: A) => number,
    private equal: (a0: A, a1: A) => boolean,
  ) {
    this.map = new ObjectKeyedMap(hash, equal);
  }

  add(value: A) {
    this.map.put(value, true);
  }

  has(value: A) {
    return this.map.get(value) === true;
  }

  values() {
    return this.map.keySet();
  }

  static empty = <A extends Record<string | number, string | number>>() =>
    new ObjectKeyedSet<A>(hashObject, equalObject);

  static from = <A extends Record<string | number, string | number>>(
    as: Iterable<A>,
  ) => {
    const set = ObjectKeyedSet.empty<A>();
    for (const a of as) {
      set.add(a);
    }
    return set;
  };
}
