export const formatCurrency = (value: string): string => {
  let num = Number(value);
  let str;

  if (Math.round(num) === num) {
    str = num.toLocaleString();
  } else {
    str = num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return "$" + str;
};
