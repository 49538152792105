import {
  CustomInsightOffset,
  ReportInsight,
  Resolved,
} from "@joyhub-integration/shared";
import { JSONEditor } from "@json-editor/json-editor";
import { sortBy } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { NoValueId } from "../../../services/insightLibrary/backendInsightIds";
import PlatformContext from "../../app/PlatformContext";
import { insightSchema } from "./insightSchema";

const InsightDefinitionEditor: React.FC<{
  insight: ReportInsight<Resolved>;
  setInsight: (o: ReportInsight<Resolved>) => void;
  setValid: (valid: boolean) => void;
}> = ({ insight, setInsight, setValid }) => {
  const { insightsMap, customInsights } = useContext(PlatformContext).platform!;
  const [, setEditor] = useState<any>();
  const [initialValue] = useState(insight); // memoize original definition
  const schema = useMemo(() => {
    const insights = sortBy(
      [
        ...Object.values(insightsMap)
          .filter((i) => i.id !== NoValueId && !i.dimensions)
          .map((i) =>
            i.common
              ? {
                  ...i,
                  name: i.name + " (Common)",
                }
              : i,
          ),
        ...customInsights
          .filter((i) => i.manual)
          .map((r) => ({
            id: r.id + CustomInsightOffset,
            name: r.name + " (Custom)",
          })),
      ],
      "name",
    );
    return {
      ...insightSchema,
      definitions: {
        ...insightSchema.definitions,
        Insight: {
          type: "number",
          enum: insights.map((i) => i.id),
          options: {
            enum_titles: insights.map((i) => i.name),
          },
        },
      },
    };
  }, [customInsights, insightsMap]);
  const onRef = useCallback(
    (el: HTMLDivElement | null) => {
      if (el) {
        const editor = new JSONEditor(el, {
          theme: "bootstrap4",
          schema,
          startval: initialValue,
          prompt_before_delete: false,
          show_opt_in: true,
          show_errors: "never",
          disable_properties: true,
          disable_edit_json: true,
        });
        editor.on("change", () => {
          setInsight(editor.getValue());
          setValid(!editor.validate().length);
        });
        setEditor(editor);
      } else if (!el) {
        setEditor((e: any) => {
          e?.destroy(); // sus...
        });
      }
    },
    [setEditor, initialValue, setInsight, schema, setValid],
  );
  return <div className="insight-definition-editor" ref={onRef} />;
};

export default InsightDefinitionEditor;
