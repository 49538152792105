import {
  GraphAxis,
  ReportColumn,
  ReportSheetData,
} from "@joyhub-integration/shared";
import { isEqual, isUndefined } from "lodash";

import { ChangeCard } from "./ChangeCard";
import { RelValueCard } from "./RelValueCard";
import { TodayInsight } from "./TodayInsight";

interface SnapshotPropsType {
  data: ReportSheetData;
  yAxisItems?: {
    column: ReportColumn<"Unresolved">;
    index: number;
  }[];
}

export const Snapshot = (props: SnapshotPropsType) => {
  const { data, yAxisItems } = props;

  const getAxisItems = (axis?: GraphAxis) =>
    data.columns
      .map((column, index) => ({ column, index }))
      .filter(({ column }) => isEqual(column.graphAxis, axis))
      .sort((prev, next) => {
        const prevUpdatedTime = prev.column?.updatedAtAxis ?? 0;
        const nextUpdatedTime = next.column?.updatedAtAxis ?? 0;
        return prevUpdatedTime - nextUpdatedTime;
      });

  const yItems = getAxisItems("y");
  const xItems = getAxisItems("x");

  return (
    <div className="w-100 h-100 d-flex flex-col align-items-center">
      <div className="rounded bg-white w-100 h-100">
        {(isEqual(data.snapshotType, "Number") ||
          isUndefined(data.snapshotType)) && (
          <TodayInsight
            data={data}
            yItems={yAxisItems ?? yItems}
            xItems={xItems}
          />
        )}
        {isEqual(data.snapshotType, "Compare") && (
          <ChangeCard
            data={data}
            yItems={yAxisItems ?? yItems}
            xItems={xItems}
          />
        )}
        {isEqual(data.snapshotType, "Goal") && (
          <RelValueCard
            data={data}
            yItems={yAxisItems ?? yItems}
            xItems={xItems}
          />
        )}
      </div>
    </div>
  );
};
