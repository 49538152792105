import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { SecondaryAxisKeyValue, colors } from "../../../services/models";

interface PieChartProps {
  data: Array<Object>;
  xAxisKey: string;
  yAxisKeys: { [key: string]: SecondaryAxisKeyValue };
}

interface PieLabelProps {
  fill: string;
  cx: number;
  cy: number;
  midAngle: number;
  outerRadius: number;
  value: string;
}

const renderLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  value,
  fill,
}: PieLabelProps) => {
  const radius = outerRadius + 20 + 4; // 4 to not touch lines
  const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
  const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);
  const dy = Math.floor((7 * y) / cy); // vertical alignment

  return (
    <text x={x} y={y} fill={fill} textAnchor={x > cx ? "start" : "end"} dy={dy}>
      {value}
    </text>
  );
};

const ResponsivePieChart = ({
  width,
  children,
  ...rest
}: {
  width?: number;
  children: JSX.Element[];
  rest?: any;
}) => {
  return (
    <PieChart width={width} {...rest}>
      <Tooltip isAnimationActive={false} />
      {children}
      {width && width > 500 ? (
        <Legend layout="vertical" verticalAlign="middle" align="right" />
      ) : (
        <Legend />
      )}
    </PieChart>
  );
};

const JhPieChart: React.FC<PieChartProps> = (props) => {
  const { data, yAxisKeys, xAxisKey } = props;

  const pies = Object.keys(yAxisKeys).map((key) => {
    return (
      <Pie
        key={key}
        dataKey={key}
        nameKey={xAxisKey}
        data={data}
        label={renderLabel}
        innerRadius="45%"
        outerRadius="75%"
        paddingAngle={2}
      >
        {data.map((_, idx) => (
          <Cell
            key={idx}
            fill={colors[idx]}
            fillOpacity={0}
            stroke={colors[idx]}
          />
        ))}
      </Pie>
    );
  });

  return (
    <ResponsiveContainer>
      <ResponsivePieChart>{pies}</ResponsivePieChart>
    </ResponsiveContainer>
  );
};

export default JhPieChart;
