import {
  ReportColumn,
  ReportDataColumn,
  ReportSheetData,
} from "@joyhub-integration/shared";

import { formatValue } from "../../../../utils/formatValue";
import getDynamicFont from "./getDynamicFont";

interface TodayInsightPropsType {
  data: ReportSheetData;
  xItems: {
    column: ReportDataColumn<any>;
    index: number;
  }[];
  yItems: {
    column: ReportDataColumn<any> | ReportColumn<"Unresolved">;
    index: number;
  }[];
}

export const TodayInsight = (props: TodayInsightPropsType) => {
  const { data, xItems, yItems } = props;

  const { boxContainer, getCustomDynamicFont } = getDynamicFont();

  const xAxisIndex = !isNaN(Number(data.xAxisIndex))
    ? Number(data.xAxisIndex)
    : data.rows.length - 1;

  const yItem = yItems.length > 0 ? yItems[0] : undefined;
  const xItem = xItems.length > 0 ? xItems[0] : undefined;

  const yValue = yItem && data.rows[xAxisIndex]?.values[yItem.index];
  const xValue = xItem && data.rows[xAxisIndex]?.values[xItem.index];
  const yHeader = yItem && data.columns[yItem.index]?.header;

  return (
    <div
      className="w-100 h-100"
      style={{ overflow: "hidden", whiteSpace: "nowrap" }}
    >
      <div
        ref={boxContainer}
        className="w-100 h-100 d-flex flex-col align-items-center justify-content-between"
      >
        <div style={{ fontSize: getCustomDynamicFont(1), fontWeight: "bold" }}>
          {yHeader || <div style={{ color: "gray" }}>No Title</div>}
        </div>
        <div
          style={{
            fontSize: getCustomDynamicFont(3.5),
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {yValue ? (
            formatValue(yValue, data.columns[yItem.index])
          ) : (
            <div style={{ color: "gray" }}>No value</div>
          )}
        </div>
        <div style={{ fontSize: getCustomDynamicFont(0.6), color: "#666666" }}>
          {xValue ? (
            formatValue(xValue, data.columns[xItem.index])
          ) : (
            <div style={{ color: "gray" }}>No basis</div>
          )}
        </div>
      </div>
    </div>
  );
};
