import {
  faCheck,
  faChevronRight,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnValue,
  DateExpression,
  PeriodExpression,
  PropertiesByColumn,
  PropertiesSelection,
  PropertyColumnDefaultTitle,
  PropertyColumnKey,
  RangeExpression,
  getColumnDataType,
  isPropertiesByColumns,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { mapValues, orderBy } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Button, Collapse, DropdownItem } from "reactstrap";

import {
  Property,
  PropertyFilter,
  getColumnName,
  getFilterLabel,
} from "../../services/propertiesService";
import { containsIgnoreCase } from "../../utils/array";
import { equalsIgnoreCase, isString } from "../../utils/string";
import { deduplicateInsensitively } from "../../utils/tagUtils";
import PlatformContext from "../app/PlatformContext";
import BoolPicker from "./BoolPicker";
import DateBasedGroupFilter from "./DateBasedGroupFilter";
import NumericRangePicker from "./NumericRangePicker";

// One day these shall be configurable..

// Tag groups that should not be rendered as filters in the UI

const getColumnValues = (
  properties: Array<Property>,
): Record<string, Array<string>> => {
  const values: Record<string, Array<string>> = {};
  const propertyFilters: PropertyFilter[] =
    properties.map(
      ({
        id,
        system_id,
        foreign_id,
        hidden,
        front_end,
        back_end,
        back_end_property_id,
        pms_override_columns,
        source_property_id,
        tags,
        metadata,
        image_guid,
        contact_person_name,
        contact_person_email,
        year_built,
        system_name,
        ...rest
      }) => rest,
    ) ?? [];
  for (const property of propertyFilters) {
    for (const [k, v] of Object.entries(property)) {
      if (v != null)
        if (values[k] == null) values[k] = [v?.toString() ?? ""];
        else values[k].push(v?.toString() ?? "");
    }
  }
  return mapValues(values, (o) => orderBy(deduplicateInsensitively(o)));
};

const getColumnSelection = (
  selection: PropertiesSelection | undefined,
): PropertiesByColumn | undefined =>
  selection != null && isPropertiesByColumns(selection) ? selection : undefined;

type UpdateSelectionsHandler = <A extends ColumnValue[]>(
  key: string,
  update?: A,
) => void;

type CategoryValueClickHandler = (
  e: React.MouseEvent<HTMLElement>,
  category: string,
  value: string | undefined,
) => void;

const ActualSelector: React.FC<{
  category: [PropertyColumnKey, string];
  categoryValues: Record<string, Array<string>>;
  currentSelection: ColumnValue[] | undefined;
  updateSelections: UpdateSelectionsHandler;
  onCategoryValueClick: CategoryValueClickHandler;
}> = ({
  category,
  categoryValues,
  currentSelection,
  updateSelections,
  onCategoryValueClick,
}) => {
  const columnKey = category[0];
  const dataType = getColumnDataType(columnKey);
  const columnValues = useMemo(
    () => categoryValues[columnKey] ?? [],
    [categoryValues, columnKey],
  );
  const updateSelection = useCallback(
    <A extends ColumnValue>(update?: A) => {
      if (update != null) {
        updateSelections(columnKey, [update]);
      } else {
        updateSelections(columnKey, undefined);
      }
    },
    [updateSelections, columnKey],
  );
  switch (dataType) {
    case "text":
      return (
        <>
          {categoryValues[columnKey]?.map((value) => {
            const checked = containsIgnoreCase(
              currentSelection?.filter(isString) ?? [],
              value,
            );
            return (
              <DropdownItem
                key={value}
                className={clsx("dashboard-property-dropdown-item")}
                style={{ paddingLeft: "2rem" }}
                onClick={(e) => {
                  onCategoryValueClick(e, columnKey, value);
                }}
                toggle={false}
              >
                <div style={{ display: "inline-block", width: "1rem" }}>
                  {!checked ? null : (
                    <FontAwesomeIcon icon={faCheck} size="xs" />
                  )}
                </div>
                {value}
              </DropdownItem>
            );
          })}
        </>
      );
    case "date":
      const dateEx: DateExpression | PeriodExpression =
        currentSelection?.[0] as DateExpression | PeriodExpression;
      return (
        <DateBasedGroupFilter
          columnValues={columnValues}
          existingSelection={dateEx}
          updateSelection={updateSelection}
        />
      );
    case "year":
    case "number":
    case "dollar":
    case "percentage":
      const rangEx: RangeExpression = currentSelection?.[0] as RangeExpression;
      return (
        <NumericRangePicker
          columnValues={columnValues}
          existingSelection={rangEx}
          updateSelection={updateSelection}
          dataType={dataType}
        />
      );
    case "bool":
      const boolEx = currentSelection?.[0] as boolean | undefined;
      return (
        <BoolPicker
          columnKey={columnKey}
          existingSelection={boolEx}
          updateSelection={updateSelection}
        />
      );
  }
};

interface ColumnSelectorProps {
  allProperties: Property[];
  selection: PropertiesSelection;
  setSelection: (
    update: (
      iso: PropertiesSelection | undefined,
    ) => PropertiesSelection | undefined,
  ) => void;
}

const ColumnSelector: React.FC<ColumnSelectorProps> = ({
  allProperties,
  selection,
  setSelection,
}) => {
  const [openCategory, setOpenCategory] = useState<string>();
  const {
    organization: { configuration },
  } = useContext(PlatformContext).platform!;

  const categoryValues = useMemo(
    () => getColumnValues(allProperties),
    [allProperties],
  );
  const foundCategories = Object.keys(categoryValues);
  const categories: [PropertyColumnKey, string][] = foundCategories.map(
    (cat) => {
      const name =
        (configuration.customColumns ?? []).find((col) => col.columnKey === cat)
          ?.name ??
        PropertyColumnDefaultTitle[
          cat as keyof typeof PropertyColumnDefaultTitle
        ] ??
        "";
      return [cat as PropertyColumnKey, name];
    },
  );

  const updateSelections = useCallback<UpdateSelectionsHandler>(
    (key, update) => {
      const stateUpdate = (
        selection: PropertiesSelection | undefined,
      ): PropertiesSelection | undefined => {
        const columnsSelection = getColumnSelection(selection);
        const updateColumns = {
          ...columnsSelection?.columns,
        };
        if (update && update.length) {
          updateColumns[key] = update;
        } else {
          delete updateColumns[key];
        }
        return Object.keys(updateColumns).length
          ? { columns: updateColumns }
          : {};
      };
      setSelection(stateUpdate);
    },
    [setSelection],
  );

  const onCategoryValueClick = useCallback<CategoryValueClickHandler>(
    (
      e: React.MouseEvent<HTMLElement>,
      category: string,
      value: string | undefined,
    ) => {
      e.stopPropagation();
      const metadataSelection = getColumnSelection(selection);
      const { [category]: values, ...metadata } =
        metadataSelection?.columns ?? {};
      const stringValues = values?.filter(isString) ?? [];
      if (value == null) {
        // delete
      } else if (!containsIgnoreCase(stringValues, value)) {
        metadata[category] = [value, ...stringValues];
      } else {
        const remainder = stringValues.filter(
          (v) => !equalsIgnoreCase(value, v),
        );
        if (remainder.length > 0) metadata[category] = remainder;
      }
      updateSelections(category, metadata[category]);
    },
    [updateSelections, selection],
  );

  return (
    <div
      className="pt-3 pb-2 px-3"
      style={{ minHeight: "6rem", maxHeight: "50vh", overflowY: "scroll" }}
    >
      {categories
        .sort((a, b) => a[1].localeCompare(b[1]))
        .map((category) => {
          const columnKey = category[0];
          const open = columnKey === openCategory;
          const columnSelection = getColumnSelection(selection);
          const currentSelection = columnSelection?.columns[columnKey];
          const active = currentSelection?.length;
          const colName = category[1];
          if (colName === "") {
            return;
          }
          const name = configuration.customColumns
            ? getColumnName(columnKey, configuration.customColumns)
            : columnKey;
          return (
            <React.Fragment key={columnKey}>
              <div
                className={clsx(
                  "dropdown-item clickable dashboard-property-dropdown-item bordered d-flex align-items-baseline",
                  { active },
                )}
                tabIndex={0}
                style={{
                  paddingLeft: "1rem",
                  paddingRight: active ? ".5rem" : undefined,
                }}
                onClick={() => setOpenCategory(open ? undefined : columnKey)}
              >
                <div className={clsx(["me-2", "jh-revealer"], { open })}>
                  <FontAwesomeIcon icon={faChevronRight} size="xs" />
                </div>
                <div
                  className="flex-grow-1 overflow-hidden"
                  style={{ minWidth: 0, textOverflow: "ellipsis" }}
                >
                  {active
                    ? getFilterLabel(columnKey, name, currentSelection ?? [])
                    : colName}
                </div>
                {!active ? null : (
                  <Button
                    size="sm"
                    className="ms-2 p-1"
                    color="transparent"
                    onClick={(e) =>
                      onCategoryValueClick(e, columnKey, undefined)
                    }
                    title="Clear category"
                  >
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                  </Button>
                )}
              </div>
              <Collapse
                isOpen={open}
                className="dashboard-property-dropdown-bordered"
              >
                <ActualSelector
                  category={category}
                  categoryValues={categoryValues}
                  currentSelection={currentSelection}
                  updateSelections={updateSelections}
                  onCategoryValueClick={onCategoryValueClick}
                />
              </Collapse>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default ColumnSelector;
