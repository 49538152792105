import { NonGroupPropertiesSelection } from "@joyhub-integration/shared";
import axios from "axios";

import { apiUrl, axiosConfig } from "../utils/api";
import { Role } from "./usersService";

export interface RoleDto {
  name: string;
  administrator: boolean;
  propertiesSelection: NonGroupPropertiesSelection | null;
}

export const createRole = async (roleDto: RoleDto): Promise<Role> => {
  return axios
    .post(apiUrl("/roles"), roleDto, axiosConfig)
    .then((res) => res.data as Role);
};

export const getRoleById = (id: number): Promise<Role> => {
  return axios
    .get(apiUrl(`/roles/${id}`), axiosConfig)
    .then((res) => res.data as Role);
};

export const editRole = async (id: number, roleDto: RoleDto): Promise<Role> => {
  return axios
    .put(apiUrl(`/roles/${id}`), roleDto, axiosConfig)
    .then((res) => res.data as Role);
};

export const deleteRole = (id: number): Promise<void> => {
  return axios.delete(apiUrl(`/roles/${id}`), axiosConfig);
};
