import { PropertiesSelection, ReportColumn } from "@joyhub-integration/shared";

import { CountOfTotal } from "../utils/number";
import { FrontEndUnit } from "./insightsService";

export interface User {
  id: number;
  accountId: number;
  email: string;
  firstName: string;
  lastName: string;
}

export interface DataSource {
  id: number;
  name: string;
}

export interface Account {
  id: number;
  name: string;
}

export interface Option {
  label: string;
  value: string;
}

export const InsightVisualizationTypes = [
  "LINE",
  "COLUMN",
  "BAR",
  "SCATTER",
  "NUMBER",
  "PERCENTAGE",
  "PIE",
  "TABLE",
  "YOY CHANGE",
  "SEQUENCE",
  "FUNNEL",
  "CUSTOM",
];
export type InsightVisualizationType =
  (typeof InsightVisualizationTypes)[number];

export type AxisOrientationType = "left" | "right";

export type SortDirection = "asc" | "desc";

export interface RangeValues {
  value: number | CountOfTotal;
  dimensions: { [key: string]: any };
}

export interface RangeValuesWithUnit extends RangeValues {
  unit: FrontEndUnit;
}

export type CalculationType = "NUMBER" | "PERCENTAGE";

export interface RangeValuesWithCalculationType extends RangeValuesWithUnit {
  calculationType: CalculationType;
}

export interface ChartOrTableDataValue {
  calculationType: CalculationType;
  value: string | number | CountOfTotal;
  unit: FrontEndUnit;
}

export interface SecondaryAxisKeyValue {
  colorHex: string;
  unit: FrontEndUnit;
  orientation: AxisOrientationType;
}

export interface ChartOrTableValues {
  data: { [key: string]: string | ChartOrTableDataValue }[];
  primaryAxisKey: string;
  secondaryAxisKeys: { [key: string]: SecondaryAxisKeyValue };
  dimensionOptions: string[];
  dataUnavailable: boolean;
  monthOptions: string[];
}

export interface ChartValues {
  data: { [key: string]: string | number }[];
  primaryAxisKey: string;
  secondaryAxisKeys: { [key: string]: SecondaryAxisKeyValue };
  dimensionOptions: string[];
  dataUnavailable: boolean;
  monthOptions: string[];
}

export interface ScatterChartValues {
  data: { [key: string]: ScatterData };
  xAxisKey: string;
  xAxisKeyUnit: FrontEndUnit;
  yAxisKey: string;
  yAxisKeyUnit: FrontEndUnit;
  zAxisKey?: string;
  dataUnavailable: boolean;
  monthOptions: string[];
}

export interface ScatterData {
  data: object[];
  color: string;
}

export const colors = [
  "#0d1553",
  "#0cc0aa",
  "#007961",
  "#a1cfcf",
  "#214a65",
  "#7aed7b",
  "#1c9820",
  "#c2df7d",
  "#658114",
  "#e3ae8c",
  "#6e3901",
  "#d95e13",
  "#fd6ca0",
  "#b90b59",
  "#e9af2a",
  "#5a88ae",
];

export const backupColors = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#3B3EAC",
];

export type Operand = "-"; // one day more than just subtraction

export interface ComplexInsightIds {
  insightIds: number[];
  dimensionFilter?: Record<string, string>;
  insightCalculationType: CalculationType;
  resultCol: string;
  yAxisOrientation?: AxisOrientationType;
  tableColOrRowBucket?: string;
  subBucket?: string;
  delta?: boolean;
  yoy?: boolean;
  positive?: boolean;
  className?: string;
  includeInFunnel?: boolean;
  hideLabel?: boolean;
  numFmt?: string;
  operand?: Operand;
}

export interface ShowTopNCategoriesConfig {
  sortKey: string;
  topN: number;
}

export interface CategoryChartInformation {
  dimensionKey: string;
  categoryKey: string;
  showTopNCategories?: ShowTopNCategoriesConfig;
}

export type ByPropertyCalculationType = "SUM" | "MAX";

export interface ColumnChartOptions {
  stacked?: boolean;
}

export interface BarChartOptions {
  stacked?: boolean;
}

export interface TableChartOptions {
  inverted?: boolean;
  bucketCols?: boolean;
  bucketRows?: boolean;
  defaultSort?: {
    key: string;
    dir: SortDirection;
  };
  removeZeroRows?: boolean;
}

export interface LineChartOptions {
  stacked?: boolean;
}

export type StringSortFunction = (a: string, b: string) => number;

export type NaturalSortOrStringSortFunction = boolean | StringSortFunction;

export interface GeneralChartOptions {
  sortValuesInTooltip?: {
    sortDirection?: SortDirection;
  };
  filterDimension?: string;
  filterByMonth?: boolean;
  filterByThirtySixtyNinetyDays?: boolean;
  sortDataKeys?: NaturalSortOrStringSortFunction;
  sortDimensionOptions?: boolean;
  sortDataByPrimaryAxisKey?: NaturalSortOrStringSortFunction;
  showLastNMonths?: number;
}

export interface Insight {
  id: number;
  calculationType: "RANGE" | "INSTANT";
  dataSourceId: number;
  name: string;
  visualizationType: Array<InsightVisualizationType>;
  description: string;
  calculations: string[];
  tags: string[];
  insightIds: number[] | ComplexInsightIds[];
  drillInInsights?: number[] | ComplexInsightIds[];
  categoryInformation?: CategoryChartInformation;
  isMultiY?: boolean;
  byPropertyCalculationType?: ByPropertyCalculationType;
  quarterly?: boolean;
  addTotalDimension?: boolean;
  columnChartOptions?: ColumnChartOptions;
  barChartOptions?: BarChartOptions;
  tableChartOptions?: TableChartOptions;
  lineChartOptions?: LineChartOptions;
  generalChartOptions?: GeneralChartOptions;
  byPropertyGroupCalculation?: boolean;
  addTotal?: boolean;
  byTagCalculationType?: ByPropertyCalculationType;
  showTopN?: number;
  delta?: boolean; // Does this insight represent a delta change
  positive?: boolean; // Does this insight represent a positive value for which a positive change is good
  className?: string;
  customComponent?: {
    // declare the component in insightComponents.ts
    width: number;
    height: number;
    defaultProps?: object;
    showHeading?: boolean;
    noContentPadding?: boolean;
    drillable?: boolean;
  };
  export?: {
    columns: ReportColumn[];
  };
  propertyOnly?: boolean;
}

export interface Report {
  id: number;
  identifier: string;
  kind: string;
  immutable: boolean; // extra-organizational
  shared: boolean;
  name: string;
  description?: string;
  scheduledEmailCount?: number;
  category: string[];
  reportUrl?: string;
  editor_id: number;
  editor_name?: string;
  edited: string;
  created: string;
  viewed?: string;
  hidden?: boolean;
}

export interface Dimension {
  id: number;
  insightId?: number;
  name: string;
}

// The following were in dashboardService.ts .. I don't have clarity on
// the difference between SomeDashboardInsight and OtherDashboardInsight

export const DashboardInsightIntervalOptions = [
  "DAY",
  "WEEK",
  "MONTH",
  "QUARTER",
  "YEAR",
  "CUSTOM",
] as const;

export type DashboardInsightIntervalOptionsType =
  (typeof DashboardInsightIntervalOptions)[number];

export interface OtherDashboardInsight<A = Record<string, any>> {
  id: number;
  name: string;
  insightId: number;
  dateFrom?: Date | string;
  dateTo?: Date | string;
  interval?: DashboardInsightIntervalOptionsType;
  visualizationType: InsightVisualizationType;
  dimensionId?: number;
  linkedReport?: string;
  stuff?: A;
}

export interface InsightCustomComponentProps<A = object> {
  dashboardInsight?: OtherDashboardInsight<A>;
  selection: PropertiesSelection;
  isOldDashboard?: boolean;
}

export interface InsightCustomComponentSettingsProps<A> {
  stuff: A | undefined;
  setStuff: (stuff: Partial<A>) => void;
}
