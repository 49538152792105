import { apiUrl, axiosConfig } from "../utils/api";
import axios from "./axios";

export interface CloudFormationStack {
  id: number;
  StackId: string;
  StackName: string;
  CreationTime: Date;
  StackStatus: string;
}

export async function getYardiRestoreStacks(): Promise<
  Array<CloudFormationStack>
> {
  return axios
    .get(apiUrl("/overlord/cloud"), axiosConfig)
    .then((res) => res.data.stacks as Array<CloudFormationStack>);
}
