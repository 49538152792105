import { FullAccountTreeNode } from "./glTypes";

const Epsilon = 0.005; // below half a cent is noise

export const prettyMuchZero = (value: number | null | undefined) =>
  value == null || Math.abs(value) < Epsilon;

export const epsilonToZero = (value: number) =>
  prettyMuchZero(value) ? 0 : value;

export const formatGlCode = (
  value: string | null | undefined,
  format: string | null | undefined,
) => {
  // TODO: generalize this
  const match = format?.match(/^(\?+)([^?])(\?+)$/); // TODO: do all the regexy stuff when account tree selected
  if (!match || !value) return value;
  const [_, prefix, infix] = match; // eslint-disable-line @typescript-eslint/no-unused-vars
  return (
    value.substring(0, prefix.length) + infix + value.substring(prefix.length)
  );
};

export const isFakeGlCode = (code?: string | null) => code?.endsWith(":index");

// Fake account codes are not stable and so cannot be persisted; persist
// instead the capitalized account name.
export const persistentGlCode = (node: FullAccountTreeNode): string | null =>
  node.tree_node_name != null && isFakeGlCode(node.tree_node_code)
    ? node.tree_node_name.toUpperCase()
    : node.tree_node_code;
