import BirdeyeImg from "./Birdeye.png";
import EntrataImg from "./Entrata.png";
import FortressImg from "./Fortress.png";
import JoyHubImg from "./JoyHub.png";
import ManualImg from "./Manual.png";
import MetadataImg from "./Metadata.png";
import RealPageImg from "./RealPage.png";
import ResManImg from "./ResMan.png";
import S3ReplicationImg from "./S3Replication.png";
import YardiImg from "./Yardi.png";

export const Birdeye = BirdeyeImg;
export const Entrata = EntrataImg;
export const ResMan = ResManImg;
export const Fortress = FortressImg;
export const JoyHub = JoyHubImg;
export const Metadata = MetadataImg;
export const RealPage = RealPageImg;
export const S3Replication = S3ReplicationImg;
export const Yardi = YardiImg;
export const Manual = ManualImg;

export default {
  Birdeye,
  Entrata,
  ResMan,
  Fortress,
  JoyHub,
  Metadata,
  RealPage,
  S3Replication,
  Yardi,
  Manual,
} as Record<string, string>;
