import { forwardRef, memo, ReactNode } from "react";

interface RowProps<
  R extends { id: number | string },
  C extends { key: string },
> {
  row: R;
  columns: Array<C>;
  isSelected?: boolean;
  singleLineRows?: boolean;
  onRowClick: (row: R) => void;
  cellValue: (row: R, col: C, idx: number) => ReactNode | undefined;
  style: React.CSSProperties;
}

const RowWithSelection = forwardRef<HTMLTableRowElement, RowProps<any, any>>(
  (
    { row, columns, isSelected, singleLineRows, onRowClick, cellValue, style },
    ref,
  ) => {
    return (
      <tr
        ref={ref}
        className={
          isSelected
            ? "jh-table-with-selection-row-selected"
            : "jh-table-with-selection-row"
        }
        style={{ whiteSpace: singleLineRows ? "nowrap" : "normal", ...style }}
        key={`row-${row.id}`}
        onClick={() => onRowClick(row)}
      >
        {columns.map((col, idx) => (
          <td key={`cell-${col.key}`}>{cellValue(row, col, idx)}</td>
        ))}
      </tr>
    );
  },
);

export const GarbageMemoizedRowWithSelection = memo(
  RowWithSelection,
  (prevProps, nextProps) => prevProps.isSelected === nextProps.isSelected,
);

export default RowWithSelection;
