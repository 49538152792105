import { underscoreSpaces } from "@joyhub-integration/shared";
import { useContext } from "react";

import { apiUrl } from "../../utils/api";
import PlatformContext from "../app/PlatformContext";

const OrgLogo: React.FC<{ modern?: boolean }> = ({ modern }) => {
  const { platform } = useContext(PlatformContext);
  const { organization } = platform!;

  const logoUrl = underscoreSpaces(organization.configuration.logo);
  const theme = platform?.organization.configuration.navbarTheme ?? "dark";

  return logoUrl ? (
    <img
      src={apiUrl(`/organizations/${organization.id}/logo/${logoUrl}`)}
      alt={organization.name}
      className={modern ? "org-logo" : "mw-100 p-lg-2 p-0 org-logo"}
      style={{
        maxHeight: modern ? "30px" : "60px",
        maxWidth: modern ? "12rem" : undefined,
      }}
    />
  ) : (
    <div
      className={
        modern
          ? `org-title-logo ${theme}`
          : `org-title-logo mw-100 p-lg-2 p-0 ${theme}`
      }
    >
      {organization.name}
    </div>
  );
};

export default OrgLogo;
