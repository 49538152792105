import { ReportDataColumn, ReportInterval } from "@joyhub-integration/shared";
import { each, isEmpty, reduce } from "lodash";
import { VisualizationSpec } from "react-vega";

import { escapeVegaString, getTemporalInfo } from "../../../vegaSpecs/util";

const y2LineLayer = (
  header: string,
  interval: ReportInterval | undefined,
  xAxisFormat?: string,
  y2AxisFormat?: string,
  isPropertyOrDimension?: boolean,
) => {
  const { timeUnit, timeFormat } = getTemporalInfo(interval);
  return {
    mark: { type: "line", interpolate: "basis" },
    encoding: {
      x: {
        field: "x",
        title: null,
        type: "nominal",
        timeUnit: isPropertyOrDimension ? undefined : timeUnit,
        axis: isPropertyOrDimension
          ? undefined
          : {
              format: timeFormat ?? xAxisFormat,
              formatType: timeFormat ? "time" : undefined,
              labelAngle: -90,
            },
      },
      y: {
        field: "y2",
        title: null,
        type: "quantitative",
        axis: { format: y2AxisFormat },
      },
      tooltip: { field: `y2Tooltip`, type: "nominal" },
      color: {
        field: "column",
      },
      size: { value: 4 },
    },
    transform: [{ filter: `datum.column === '${escapeVegaString(header)}'` }],
  };
};

const barLayer = (
  field: string,
  interval: ReportInterval | undefined,
  xAxisFormat?: string,
  yAxisFormat?: string,
  isPropertyOrDimension?: boolean,
  colors?: string[],
) => {
  const { timeUnit, timeFormat } = getTemporalInfo(interval);
  return {
    mark: "bar",
    params: [
      {
        name: "column",
        select: { type: "point", fields: ["column"] },
        bind: "legend",
      },
      {
        name: "selectedColumn",
        select: {
          type: "point",
          fields: ["column"],
          on: "mouseover",
          clear: "mouseout",
        },
      },
    ],
    encoding: {
      x: {
        field: "xOrder",
        title: null,
        type: "nominal", // I don't know when this is nominal vs temporal but temporal breaks bar charts
        timeUnit: isPropertyOrDimension ? undefined : timeUnit,
        axis: isPropertyOrDimension
          ? undefined
          : {
              format: timeFormat ?? xAxisFormat,
              formatType: timeFormat ? "time" : undefined,
              labelAngle: -90,
            },
        sort: {
          field: "xOrder",
          order: "ascending",
        },
      },
      xOffset: {
        field: "column",
        sort: { op: "min", field: "x", order: "ascending" },
      },
      y: {
        field,
        title: null,
        type: "quantitative",
        axis: { format: yAxisFormat },
      },
      color: {
        field: "column",
        sort: { field: "*", order: "ascending", op: "count" },
        scale: {
          range: colors,
        },
      },
      tooltip: { field: `${field}Tooltip`, type: "nominal" },
      opacity: {
        condition: { param: "column", value: 1 },
        value: 0.2,
      },
    },
  };
};

const ruleLayer = (format?: string) => {
  return {
    params: [
      {
        name: "vs",
        select: { type: "point", fields: ["column"] },
        bind: "legend",
      },
    ],
    mark: {
      type: "rule",
    },
    encoding: {
      y: {
        field: "vs",
        title: null,
        type: "quantitative",
        axis: { format },
      },
      size: { value: 4 },
      color: { field: "column" },
      tooltip: { field: `vsTooltip`, type: "nominal" },
    },
  };
};

const rollingLayer = (
  columnName: string,
  interval: ReportInterval | undefined,
  isPropertyOrDimension?: boolean,
) => {
  const { timeUnit } = getTemporalInfo(interval);

  return {
    transform: [
      { filter: `datum.column === '${escapeVegaString(columnName)}'` },
    ],
    mark: "line",
    encoding: {
      x: {
        field: "x",
        type: "nominal",
        timeUnit: isPropertyOrDimension ? undefined : timeUnit,
      },
      y: { field: "rollAvg", type: "quantitative" },
      color: {
        condition: { param: "selectedColumn", empty: false, value: "red" },
        field: "column",
        type: "nominal",
        sort: { field: "*", order: "ascending", op: "count" },
      },
      size: { value: 4 },
    },
  };
};

const tooltip = (
  y1Columns: ReportDataColumn<any>[],
  xAxisFormat: string | undefined,
  yAxisFormat: string | undefined,
  vsAxisFormat: string | undefined,
  y2AxisFormat: string | undefined,
  vs: boolean | undefined,
  y2: boolean | undefined,
  interval: ReportInterval | undefined,
  isPropertyOrDimension: boolean | undefined,
) => {
  const { xTooltip } = getTemporalInfo(interval, xAxisFormat);
  let calculateConfig =
    reduce(
      y1Columns,
      (result, y1Column) => {
        if (y1Column.showAverageLine)
          return (
            result +
            `datum.column === '${y1Column.header.replace(
              /'/g,
              "\\'",
            )}' ? datum.column + ': ' + format(datum.y, '${yAxisFormat}') + ' (Rolling 3 Month Average: ' + format(datum.rollAvg, '.1f') + ')' : `
          );

        return result;
      },
      "",
    ) + `datum.column + ': ' + format(datum.y, '${yAxisFormat ?? ",d"}')`;

  return [
    {
      window: [
        {
          op: "mean",
          field: "y",
          as: "rollAvg",
        },
      ],
      frame: [-2, 0],
      groupby: ["column"],
    },
    {
      calculate: calculateConfig,
      as: "yTooltip",
    },
    vs
      ? {
          calculate: `datum.vsName + ' - ' + datum.column  + ': ' + format(datum.vs, '${vsAxisFormat}')`,
          as: "vsTooltip",
        }
      : {},
    y2
      ? {
          calculate: `${
            isPropertyOrDimension ? "" : `${xTooltip} + ' - ' + `
          }datum.column ${
            y2AxisFormat ? ` + ': ' + format(datum.y2, '${y2AxisFormat}')` : ""
          }`,
          as: "y2Tooltip",
        }
      : {},
  ];
};

const bar = ({
  xAxisFormat,
  yAxisFormat,
  vsAxisFormat,
  y2AxisFormat,
  vs,
  y2,
  interval,
  y1Columns,
  y2Columns,
  isPropertyOrDimension,
  colors,
}: {
  xAxisFormat?: string;
  yAxisFormat?: string;
  vsAxisFormat?: string;
  y2AxisFormat?: string;
  vs?: boolean;
  y2?: boolean;
  interval: ReportInterval | undefined;
  y1Columns: ReportDataColumn<any>[];
  y2Columns: ReportDataColumn<any>[];
  isPropertyOrDimension?: boolean;
  colors?: string[];
}) => {
  const layers: any[] = [
    barLayer(
      "y",
      interval,
      xAxisFormat,
      yAxisFormat,
      isPropertyOrDimension,
      colors,
    ),
  ];

  if (vs) layers.push(ruleLayer(vsAxisFormat));

  each(y1Columns, (y1Column) => {
    if (y1Column.showAverageLine)
      layers.push(
        rollingLayer(y1Column.header, interval, isPropertyOrDimension),
      );
  });

  if (!isEmpty(y1Columns))
    each(y2Columns, (y2Column) => {
      layers.push(
        y2LineLayer(
          y2Column.header,
          interval,
          xAxisFormat,
          y2AxisFormat,
          isPropertyOrDimension,
        ),
      );
    });

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    height: "container",
    width: "container",
    data: { name: "data" },
    transform: [
      ...tooltip(
        y1Columns,
        xAxisFormat,
        yAxisFormat,
        vsAxisFormat,
        y2AxisFormat,
        vs,
        y2,
        interval,
        isPropertyOrDimension,
      ),
      {
        calculate: "datum.x === 'Studio' ? ' Studio' : datum.x",
        as: "xOrder",
      },
    ],
    layer: layers,
  } as VisualizationSpec;
};

export default bar;
