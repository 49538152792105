import { max } from "lodash";
import { useMemo } from "react";

import { InstantInsights } from "../../../services/dataService";
import {
  BudgetedTotalOperatingRevenue,
  TotalOperatingRevenue,
} from "../../../services/insightLibrary/backendInsightIds";
import { formattedDollar } from "../../../utils/number";
import { financialData } from "../cdUtil";
import Meter from "../visualizations/Meter";
import DashboardCard from "./DashboardCard";
import { Skelington } from "./Skelington";

const Revenue = ({
  insights,
  onClick,
}: {
  insights?: InstantInsights;
  onClick?: () => void;
}) => {
  const data = useMemo(
    () =>
      financialData(
        insights,
        TotalOperatingRevenue,
        BudgetedTotalOperatingRevenue,
      ),
    [insights],
  );
  return insights && data?.actual == null ? null : (
    <DashboardCard
      title="Revenue"
      centered
      delta={data?.delta}
      onClick={onClick}
    >
      <Skelington value={data} height={3}>
        {({ target, actual }) => (
          <Meter
            low={0}
            high={max([actual, target]) ?? 0}
            actual={actual}
            target={target}
            formatter={formattedDollar}
            targetLabel="Budget"
          />
        )}
      </Skelington>
    </DashboardCard>
  );
};

export default Revenue;
