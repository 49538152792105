import React, { CSSProperties, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import Loading from "../app/Loading";

export const useLoadilyFadily = (loaded: boolean): CSSProperties => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    if (loaded && !opacity) setTimeout(() => setOpacity(1), 10);
  }, [loaded, opacity, setOpacity]);
  return { opacity, transition: "opacity .5s ease-out" };
};

export const LoadilyFadily: React.FC<
  React.HTMLAttributes<any> & { loaded?: boolean }
> = ({ loaded, children, ...rest }) => {
  const fadily = useLoadilyFadily(loaded !== false);
  return loaded === false ? (
    <Loading />
  ) : (
    <div style={fadily} {...rest}>
      <ErrorBoundary
        fallback={<span className="px-4 text-danger">An error occurred.</span>}
      >
        {children}
      </ErrorBoundary>
    </div>
  );
};

function allFadily<T extends {}>(Component: React.FC<T>) {
  return (props: T) => (
    <LoadilyFadily>
      <Component {...props} />
    </LoadilyFadily>
  );
}

export default allFadily;
