import React, { useCallback, useContext, useState } from "react";

import { unexpectedError } from "../../../constants";
import AlertModal from "./AlertModal";

export interface WithAlertModalProps {
  setAlert: (message: string, success: boolean) => void;
  onUnexpectedError: (e?: any) => void;
}

const WithAlertContext = React.createContext<WithAlertModalProps>({
  setAlert: () => {},
  onUnexpectedError: () => {},
});

export const useSetAlert = (): WithAlertModalProps["setAlert"] =>
  useContext(WithAlertContext).setAlert;

export const useOnUnexpectedError =
  (): WithAlertModalProps["onUnexpectedError"] =>
    useContext(WithAlertContext).onUnexpectedError;

function withAlertModal<T>(Component: React.FC<T & WithAlertModalProps>) {
  return function (props: T) {
    const [message, setMessage] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const setAlert = useCallback(function (message: string, success: boolean) {
      setMessage(message);
      setSuccess(success);
      setIsOpen(true);
    }, []);

    const onUnexpectedError = useCallback(
      (e: any) => {
        if (e) console.warn(e);
        setAlert(unexpectedError, false);
      },
      [setAlert],
    );

    return (
      <WithAlertContext.Provider value={{ setAlert, onUnexpectedError }}>
        <AlertModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          message={message}
          success={success}
        />
        <Component
          {...props}
          setAlert={setAlert}
          onUnexpectedError={onUnexpectedError}
        />
      </WithAlertContext.Provider>
    );
  };
}

export default withAlertModal;
