import {
  executedLeaseSummaryColumns,
  executedLeaseSummaryRowDefinitions,
} from "../../components/genericTableChart/definitions/executedLeaseSummary";
import {
  newLeasesColumns,
  newLeasesRowDefinitions,
} from "../../components/genericTableChart/definitions/newLeases";
import { comparePriority } from "../../utils/priority";
import { comparePeriods } from "../../utils/time";
import { Insight } from "../models";
import {
  AllUnitCount,
  AllUnitCountByBedrooms,
  AllUnitCountByUnitType,
  Applications,
  Applications30Day,
  ApplicationsApproved,
  ApplicationsByAdSource,
  ApplicationsDenied,
  ApplicationsOverridden,
  ApplicationsScreened,
  ApplicationsScreenedByAdSource,
  AvailableUnitCount,
  AvailableUnitCountByBedrooms,
  AvailableUnitCountByUnitType,
  AverageDaysFromMoveOutToReady,
  AverageDaysOnMarket,
  AverageDaysVacant,
  AverageMonthlyIncome,
  AverageMoveInRentByAdSource,
  AverageTradeOutNewAndRenewedLeasesLast30Days,
  EffectiveRentNewAndRenewedLeasesLast30Days,
  EffectiveRentNewSignsLast30Days,
  EffectiveRentPerSqftNewAndRenewedLeasesLast30Days,
  EffectiveRentPerSqftNewSignsLast30Days,
  EffectiveRentPerSqftRenewalsLast30Days,
  EffectiveRentRenewalsLast30Days,
  ExpiringLeaseMoveOutCount,
  ExpiringLeaseMoveOutCountByBedrooms,
  ExpiringLeaseNoticeCountByBedrooms,
  ExpiringLeasePendingCount,
  ExpiringLeasePendingCountByBedrooms,
  ExpiringLeaseRenewalCount,
  ExpiringLeaseRenewalCountByBedrooms,
  InPlaceRent,
  InPlaceRentPerSqft,
  LeadCount,
  Leads30Day,
  LeadsByAdSource,
  Leased,
  LeasedByBedrooms,
  LeasedUnitCount,
  LeaseExpiryCount,
  LeaseExpiryCount30Days,
  LeaseExpiryCount60Days,
  LeasePendingCount30Days,
  LeaseRenewalCount30Days,
  LeaseRenewalCount60Days,
  LeaseRenewalCountByBedrooms60Days,
  LeasingVelocityByBedrooms,
  MarketRent,
  MarketRentPerSqft,
  MaximumDaysVacant,
  MoveInRent,
  MoveInRentPerSqft,
  MoveIns,
  MoveIns30Day,
  MoveInsByAdSource,
  MoveInTradeOutPercent,
  MoveOutReason,
  MoveOuts30Day,
  NewAndRenewedLeasesLast30Days,
  NewSignsLast30Days,
  NoticeUnrentedUnitCountByLeaseEnd,
  Occupancy,
  OccupancyByBedrooms,
  OccupiedUnitCount,
  OccupiedUnitCountByBedroomsAndAdSource,
  OfflineUnitCount,
  OnlineUnitCount,
  OnlineUnitCountByBedrooms,
  OpenWorkOrderCompletions,
  PercentTradeOutNewAndRenewedLeasesLast30Days,
  RenewalRent,
  RenewalRentPerSqft,
  RenewalsLast30Days,
  RenewalTradeOutPercent,
  SiteVisits30Day,
  TenantCountByIncomeBracket,
  TermsNewAndRenewedLeasesLast30Days,
  TermsNewSignsLast30Days,
  TermsRenewalsLast30Days,
  TotalRentByAdSource,
  TotalTradeOutPercent,
  VacantNotReadyUnitCount,
  VacantReadyUnitCount,
  VacantUnitCount,
  VacantUnrentedUnitCount,
  WorkOrderAgeDistribution,
  WorkOrderAverageAgeToCompletion,
} from "./backendInsightIds";

export const ImageInsightId = 61;

export const UnicardInsightId = 68;

export const PropertyInformationInsightId = 69;

export const EmbeddedReportId = 70;

export const HtmlInsightId = 71;

export const IndependentImageInsightId = 72;

export const IframeInsightId = 73;

export const EmailInsightId = 74;

export const PropertyNoteInsightId = 75;

const definitions: Array<Insight> = [
  {
    id: 1,
    dataSourceId: 1,
    name: "Occupancy",
    visualizationType: ["PERCENTAGE"],
    description: "Percentage of online units that are currently occupied",
    calculations: ["Total occupied units / total Online Units"],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [AllUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Units",
      },
      {
        insightIds: [OfflineUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Admin, Model, Down",
      },
      {
        insightIds: [OccupiedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Occupied Units",
      },
      {
        insightIds: [VacantUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Units",
      },
      {
        insightIds: [VacantReadyUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Ready Units",
      },
      {
        insightIds: [VacantNotReadyUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Unready Units",
      },
      {
        insightIds: [VacantUnrentedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Unrented Units",
      },
      {
        insightIds: [Occupancy],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Occupancy",
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 2,
    dataSourceId: 1,
    name: "Vacant Ready",
    visualizationType: ["PERCENTAGE"],
    description: "Percentage of vacant units that are ready to lease",
    calculations: [
      "Total units that are vacant and ready to lease / Total vacant units",
    ],
    tags: ["Leasing"],
    insightIds: [VacantReadyUnitCount, VacantUnitCount],
    drillInInsights: [
      {
        insightIds: [OnlineUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Online Units",
      },
      {
        insightIds: [AvailableUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Available Units",
      },
      {
        insightIds: [VacantUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Units",
      },
      {
        insightIds: [VacantUnitCount, AvailableUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% of Available Units Vacant",
      },
      {
        insightIds: [VacantNotReadyUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Not Ready Units",
      },
      {
        insightIds: [VacantReadyUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Ready Units",
      },
      {
        insightIds: [VacantReadyUnitCount, VacantUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Vacant Ready",
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 3,
    dataSourceId: 1,
    name: "Leads (30 day)",
    visualizationType: ["NUMBER"],
    description: "Number of prospective resident leads over 30 days",
    calculations: ["Total number of leads over 30 days"],
    tags: ["Leasing"],
    insightIds: [Leads30Day],
    calculationType: "INSTANT",
  },
  {
    id: 4,
    dataSourceId: 1,
    name: "Site Visits (30 day)",
    visualizationType: ["NUMBER"],
    description: "Number of site visits over 30 days",
    calculations: ["Total number of site visits over 30 days"],
    tags: ["Leasing"],
    insightIds: [SiteVisits30Day],
    calculationType: "INSTANT",
  },
  {
    id: 5,
    dataSourceId: 1,
    name: "Applications (30 day)",
    visualizationType: ["NUMBER"],
    description: "Number of prospective resident applications over 30 days",
    calculations: ["Total number of applications over 30 days"],
    tags: ["Leasing"],
    insightIds: [Applications30Day],
    calculationType: "INSTANT",
  },
  {
    id: 6,
    dataSourceId: 1,
    name: "Move Ins (30 day)",
    visualizationType: ["NUMBER"],
    description: "Number of prospective move-ins over 30 days",
    calculations: ["Total number of move-ins over 30 days"],
    tags: ["Leasing"],
    insightIds: [MoveIns30Day],
    calculationType: "INSTANT",
  },
  {
    id: 7,
    dataSourceId: 1,
    name: "Lead to Application Conversion",
    visualizationType: ["PERCENTAGE"],
    description:
      "Number of applications received relative to the total number of leads",
    calculations: ["Total number of applications / total number of leads"],
    tags: ["Leasing"],
    insightIds: [Applications30Day, Leads30Day],
    calculationType: "INSTANT",
  },
  {
    id: 8,
    dataSourceId: 1,
    name: "Lead to Move In Conversion",
    visualizationType: ["PERCENTAGE"],
    description:
      "Number of move-ins received relative to the total number of leads",
    calculations: ["Total number of move-ins / total number of leads"],
    tags: ["Leasing"],
    insightIds: [MoveIns30Day, Leads30Day],
    calculationType: "INSTANT",
  },
  {
    id: 10,
    dataSourceId: 1,
    name: "Leasing Availability by Bedroom",
    visualizationType: ["LINE", "TABLE"],
    description: "Percentage of available units per month by bedroom type",
    calculations: [
      "Each month:",
      "total available 0 bedroom units / total units (as a %)",
      "total available 1 bedroom units / total units (as a %)",
      "total available 2 bedroom units / total units (as a %)",
      "total available 3 bedroom units / total units (as a %)",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [AvailableUnitCountByBedrooms, OnlineUnitCountByBedrooms],
        insightCalculationType: "PERCENTAGE",
        resultCol: "dimensions.Bedrooms",
      },
    ],
    generalChartOptions: {
      sortDataKeys: true,
      sortDimensionOptions: true,
    },
    calculationType: "RANGE",
  } as Insight,
  {
    id: 11,
    dataSourceId: 1,
    name: "Occupancy and Leased",
    visualizationType: ["LINE", "COLUMN"],
    description: "Occupancy and leased percentages",
    calculations: [
      "Occupied + Admin/Model/Down units / total units",
      "- plotted every day over X period of time",
      "Leased + Admin/Model/Down units / Total units",
      "- plotted every day over X period of time",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [OccupancyByBedrooms],
        insightCalculationType: "PERCENTAGE",
        resultCol: "dimensions.Occupancy",
      },
      {
        insightIds: [LeasedByBedrooms],
        insightCalculationType: "PERCENTAGE",
        resultCol: "dimensions.Leased",
      },
    ],
    categoryInformation: {
      categoryKey: "resultCol",
      dimensionKey: "month",
    },
    generalChartOptions: {
      filterDimension: "bedrooms",
      sortDimensionOptions: true,
    },
    calculationType: "RANGE",
  },
  {
    id: 12,
    dataSourceId: 1,
    name: "Bedroom Type by Ad Source",
    visualizationType: ["BAR"],
    description: "Number of units for each bedroom type by ad source",
    calculations: [
      "Total number of 1 bedrooms, 2 bedrooms, 3 bedrooms for each ad source (website, etc.) over a give period of time",
    ],
    tags: ["Marketing"],
    insightIds: [
      {
        insightIds: [OccupiedUnitCountByBedroomsAndAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Bedrooms and Ad Source",
      },
    ],
    categoryInformation: {
      categoryKey: "bedrooms",
      dimensionKey: "source",
      showTopNCategories: {
        sortKey: "dimensions",
        topN: 10,
      },
    },
    generalChartOptions: {
      sortDataKeys: true,
    },
    calculationType: "RANGE",
  } as Insight,
  {
    id: 13,
    dataSourceId: 1,
    name: "Leads by Top Ad Source",
    visualizationType: ["LINE"],
    description: "Number of leads per top 10 ad sources by month",
    calculations: [
      "Total number of leads for each ad source (website, facebook, etc.) over a given period of time",
    ],
    tags: ["Marketing"],
    generalChartOptions: {
      sortValuesInTooltip: {
        sortDirection: "desc",
      },
    },
    insightIds: [
      {
        insightIds: [LeadsByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Ad Source",
      },
    ],
    showTopN: 10,
    lineChartOptions: {
      stacked: true,
    },
    calculationType: "RANGE",
  },
  {
    id: 14,
    dataSourceId: 1,
    name: "Traffic Conversion",
    visualizationType: ["TABLE"],
    description: "Leads, move-ins, and conversion % by ad source",
    calculations: [
      "Total number of traffic by ad source, total number of move ins by ad source, move ins/traffic (as a %) by ad source",
    ],
    tags: ["Marketing"],
    insightIds: [
      {
        insightIds: [LeadsByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Leads",
      },
      {
        insightIds: [MoveInsByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Move Ins",
      },
      {
        insightIds: [MoveInsByAdSource, LeadsByAdSource],
        insightCalculationType: "PERCENTAGE",
        resultCol: "dimensions.Conversion",
      },
    ],
    categoryInformation: {
      categoryKey: "resultCol",
      dimensionKey: "source",
    },
    generalChartOptions: {
      filterByMonth: true,
    },
    tableChartOptions: {
      defaultSort: {
        dir: "desc",
        key: "Move Ins",
      },
    },
    calculationType: "RANGE",
  },
  {
    id: 15,
    dataSourceId: 1,
    name: "Activity Trend",
    visualizationType: ["LINE"],
    description: "Number of leads, applications and move-ins by month",
    calculations: [
      "Total Traffic in a month",
      "Total Applications in a month",
      "Total Move-ins in a month",
    ],
    tags: ["Marketing"],
    insightIds: [
      {
        insightIds: [LeadCount],
        insightCalculationType: "NUMBER",
        resultCol: "Leads",
      },
      {
        insightIds: [Applications],
        insightCalculationType: "NUMBER",
        resultCol: "Applications",
      },
      {
        insightIds: [MoveIns],
        insightCalculationType: "NUMBER",
        resultCol: "Move Ins",
      },
    ],
    lineChartOptions: {
      stacked: true,
    },
    calculationType: "RANGE",
  },
  {
    id: 16,
    dataSourceId: 1,
    name: "Activity by Ad Source",
    visualizationType: ["BAR"],
    description:
      "Number of leads, applications and move-ins by top 10 ad sources over the past 30 days",
    calculations: [
      "Ad Source:",
      "Total Traffic in a selected time period",
      "Total Applications in a selected time period",
      "Total Move-ins in a selected time period",
    ],
    tags: ["Marketing"],
    insightIds: [
      {
        insightIds: [LeadsByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Leads",
      },
      {
        insightIds: [ApplicationsByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Applications",
      },
      {
        insightIds: [MoveInsByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Move Ins",
      },
    ],
    categoryInformation: {
      categoryKey: "resultCol",
      dimensionKey: "source",
      showTopNCategories: {
        topN: 10,
        sortKey: "Move Ins",
      },
    },
    generalChartOptions: {
      filterByMonth: true,
    },
    barChartOptions: {
      stacked: true,
    },
    calculationType: "RANGE",
  },
  {
    id: 17,
    dataSourceId: 1,
    name: "Resident Value by Ad Source",
    visualizationType: ["BAR"],
    description: "Average rent by ad source",
    calculations: ["Average lease value by ad source over a given period"],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [TotalRentByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Total Lease Rent",
      },
    ],
    categoryInformation: {
      categoryKey: "resultCol",
      dimensionKey: "source",
      showTopNCategories: {
        topN: 10,
        sortKey: "Total Lease Rent",
      },
    },
    calculationType: "RANGE",
  },
  {
    id: 18,
    dataSourceId: 1,
    name: "Annual Income",
    visualizationType: ["BAR"],
    description: "Percentage of residents reporting income within each bracket",
    calculations: [
      "$0-20k - number of residents in this income bracket / total number of residents",
      "$20-40k -- number of residents in this income bracket / total number of residents",
      "$40-60k - number of residents in this income bracket / total number of residents",
      "$60-80k - - number of residents in this income bracket / total number of residents",
      "$80-100k-- number of residents in this income bracket / total number of residents ",
      "$100-120k -- number of residents in this income bracket / total number of residents",
      "$120-140k -- number of residents in this income bracket / total number of residents",
      "$140-160k-- number of residents in this income bracket / total number of residents",
      "$160k+ -- number of residents in this income bracket / total number of residents",
    ],
    tags: ["Resident"],
    insightIds: [
      {
        insightIds: [TenantCountByIncomeBracket],
        insightCalculationType: "PERCENTAGE",
        resultCol: "dimensions.Percentage of Tenants in Income Bracket",
      },
    ],
    categoryInformation: { categoryKey: "resultCol", dimensionKey: "income" },
    generalChartOptions: {
      sortDataByPrimaryAxisKey: true,
    },
    calculationType: "RANGE",
    export: {
      columns: [
        {
          header: "Percentage of Tenants in Income Bracket",
          insight: {
            numerator: TenantCountByIncomeBracket,
            denominator: { sum: TenantCountByIncomeBracket },
          },
        },
      ],
    },
  },
  {
    id: 19,
    dataSourceId: 1,
    name: "In Place Rent Per Square Foot",
    visualizationType: ["NUMBER", "YOY CHANGE"],
    description: "Average in-place rent per square foot for all units",
    calculations: [
      "Total square feet of all rented units / Total rental revenue",
    ],
    tags: ["Leasing"],
    insightIds: [InPlaceRentPerSqft],
    positive: true,
    calculationType: "INSTANT",
  },
  {
    id: 20,
    dataSourceId: 1,
    name: "New Rent Per Square Foot",
    visualizationType: ["NUMBER", "YOY CHANGE"],
    description:
      "Average in-place rents for all newly signed lease units per square foot",
    calculations: [
      "Total square feet of all newly rented units / Total rental revenue from newly signed leases",
    ],
    tags: ["Leasing"],
    insightIds: [MoveInRentPerSqft],
    positive: true,
    calculationType: "INSTANT",
  },
  {
    id: 21,
    dataSourceId: 1,
    name: "Renewal Rent Per Square Foot",
    visualizationType: ["NUMBER", "YOY CHANGE"],
    description: "Average in-place rent for all renewed units per square foot",
    calculations: [
      "Total square feet of all renewal rented units / Total rental revenue from renewal units",
    ],
    tags: ["Leasing"],
    insightIds: [RenewalRentPerSqft],
    positive: true,
    calculationType: "INSTANT",
  },
  {
    id: 22,
    dataSourceId: 1,
    name: "Trade Out - New Lease",
    visualizationType: ["PERCENTAGE"],
    description:
      "Ratio of average rent collected from new in-place leases to prior in-place leases",
    calculations: [
      "Total average rent of newly placed leases / Total average rent of leases that have recently moved out",
    ],
    tags: ["Leasing"],
    insightIds: [MoveInTradeOutPercent],
    delta: true,
    positive: true,
    calculationType: "INSTANT",
  },
  {
    id: 23,
    dataSourceId: 1,
    name: "Trade Out - Renewal",
    visualizationType: ["PERCENTAGE"],
    description:
      "Ratio of average rent collected from renewed leases on a trade-out to prior in-place leases",
    calculations: [
      "100%- ([Total square feet of all renewed leases on a trade out / Total rental revenue of all renewed leases on a trade out] / [Total square feet of all rented units / Total rental revenue])",
    ],
    tags: ["Leasing"],
    insightIds: [RenewalTradeOutPercent],
    delta: true,
    positive: true,
    calculationType: "INSTANT",
  },
  {
    id: 24,
    dataSourceId: 1,
    name: "Renewal Performance",
    visualizationType: ["COLUMN"],
    description:
      "Leases expiring in the next 30 days by status (renewal, move out, notice given, or pending) and bedroom type",
    calculations: [
      "Total Renewals",
      "Total Move Outs",
      "Total Notices",
      "Total Pending",
      "[Pending may be all lease expirations that are not designated a Renewal, Move Out or Notice",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [ExpiringLeaseRenewalCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Renewals",
      },
      {
        insightIds: [ExpiringLeaseMoveOutCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Move Outs",
      },
      {
        insightIds: [ExpiringLeaseNoticeCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Notices",
      },
      {
        insightIds: [ExpiringLeasePendingCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Pending",
      },
    ],
    drillInInsights: [
      {
        insightIds: [AllUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Units",
      },
      {
        insightIds: [ExpiringLeaseRenewalCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Renewals",
      },
      {
        insightIds: [ExpiringLeaseMoveOutCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Move Outs",
      },
      {
        insightIds: [ExpiringLeaseNoticeCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Notices",
      },
      {
        insightIds: [ExpiringLeasePendingCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Pending",
      },
    ],
    columnChartOptions: {
      stacked: true,
    },
    generalChartOptions: {
      filterDimension: "bedrooms",
      sortDimensionOptions: true,
    },
    categoryInformation: {
      categoryKey: "resultCol",
      dimensionKey: "month",
    },
    calculationType: "RANGE",
  },
  {
    id: 25,
    dataSourceId: 1,
    name: "Leasing Velocity by Bedroom",
    visualizationType: ["LINE", "TABLE"],
    description: "Number of leases signed per month by bedroom type",
    calculations: [
      "Each month:",
      "total 0 unit bedrooms leased",
      "total 1 bedroom units leased",
      "total 2 bedroom units leased",
      "total 3 bedroom units leased",
      "total count of all bedroom units leased",
    ],
    tags: ["Leasing"],
    addTotalDimension: true,
    insightIds: [
      {
        insightIds: [LeasingVelocityByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Bedrooms",
      },
    ],
    generalChartOptions: {
      sortDataKeys: true,
      sortDimensionOptions: true,
    },
    calculationType: "RANGE",
  },
  {
    id: 26,
    dataSourceId: 1,
    name: "Availability",
    visualizationType: ["PERCENTAGE"],
    description: "Percentage of units that are currently available to rent",
    calculations: ["100% (of units) -less X % (Vacancy)"],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [AvailableUnitCount, OnlineUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Available",
      },
    ],
    drillInInsights: [
      {
        insightIds: [AllUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Units",
      },
      {
        insightIds: [OnlineUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Online Units",
      },
      {
        insightIds: [LeasedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Leased Units",
      },
      {
        insightIds: [AvailableUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Available Units",
      },
      {
        insightIds: [VacantUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Units",
      },
      {
        insightIds: [VacantUnrentedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Unrented Units",
      },
      {
        insightIds: [AvailableUnitCount, OnlineUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Available",
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 27,
    dataSourceId: 1,
    name: "Expiring Leases - 30 Days",
    visualizationType: ["NUMBER"],
    description: "Number of leases expiring in the next 30 days",
    calculations: ["Number of leases expiring in next 30 days"],
    tags: ["Leasing"],
    insightIds: [LeaseExpiryCount30Days],
    drillInInsights: [
      {
        insightIds: [OnlineUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Online Units",
      },
      {
        insightIds: [LeasedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Leases",
      },
      {
        insightIds: [LeaseExpiryCount30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Expiring Leases",
      },
      {
        insightIds: [LeasePendingCount30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Pending Leases",
      },
      {
        insightIds: [LeaseRenewalCount30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Renewed Leases",
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 28,
    dataSourceId: 1,
    name: "Pending Leases - 30 Days",
    visualizationType: ["PERCENTAGE"],
    description:
      "Percentage of leases expiring in the next 30 days that are currently pending",
    calculations: [
      "[Total number of occupied Units in 30 days less renewal, Move out, Notices, that have a lease expiring in 60/90 days] / all units",
    ],
    tags: ["Leasing"],
    insightIds: [LeasePendingCount30Days, LeaseExpiryCount30Days],
    drillInInsights: [
      {
        insightIds: [OnlineUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Online Units",
      },
      {
        insightIds: [LeasedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Leases",
      },
      {
        insightIds: [LeaseExpiryCount30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Expiring Leases",
      },
      {
        insightIds: [LeasePendingCount30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Pending Leases",
      },
      {
        insightIds: [LeaseExpiryCount30Days, LeasedUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Expiring",
      },
      {
        insightIds: [LeasePendingCount30Days, LeaseExpiryCount30Days],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Pending",
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 29,
    dataSourceId: 1,
    name: "Renewal Retention - 60 Days",
    visualizationType: ["PERCENTAGE"],
    description:
      "Percentage of leases expiring in the next 60 days that will currently renew",
    calculations: [
      "[Total number of occupied Units in 60 days less renewal, Move out, Notices, that have a lease expiring in 60/90 days] / all units",
    ],
    tags: ["Leasing"],
    insightIds: [LeaseRenewalCount60Days, LeaseExpiryCount60Days],
    drillInInsights: [
      {
        insightIds: [LeasedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Leases",
      },
      {
        insightIds: [LeaseExpiryCount60Days],
        insightCalculationType: "NUMBER",
        resultCol: "Expired Leases",
      },
      {
        insightIds: [LeaseRenewalCount60Days],
        insightCalculationType: "NUMBER",
        resultCol: "Renewed Leases",
      },
      {
        insightIds: [LeaseRenewalCountByBedrooms60Days],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Bedrooms",
      },
      {
        insightIds: [LeaseRenewalCount60Days, LeaseExpiryCount60Days],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Renewal Retention",
      },
    ],
    calculationType: "INSTANT",
    generalChartOptions: {
      sortDimensionOptions: true,
      filterDimension: "bedrooms",
    },
  },
  {
    id: 30,
    dataSourceId: 1,
    name: "In Place Rent Per Unit",
    visualizationType: ["NUMBER", "YOY CHANGE"],
    description: "Average in-place rent per unit",
    calculations: [
      "Total of all in-place monthly rents / Total # of rented units",
    ],
    tags: ["Leasing"],
    insightIds: [InPlaceRent],
    positive: true,
    calculationType: "INSTANT",
  },
  {
    id: 31,
    dataSourceId: 1,
    name: "Lease Trade Out",
    visualizationType: ["LINE"],
    description:
      "Difference in average rent between in-place leases and prior leases by month",
    calculations: [
      "Total new leases / total trade out units",
      "Total renewal leases in trade out units / total trade out units",
      "Total trade out units / Total units [available units or total units]",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [MoveInTradeOutPercent],
        insightCalculationType: "PERCENTAGE",
        resultCol: "New Lease",
      },
      {
        insightIds: [RenewalTradeOutPercent],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Renewal",
      },
      {
        insightIds: [TotalTradeOutPercent],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Total",
      },
    ],
    drillInInsights: [
      {
        insightIds: [AllUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Units",
      },
      {
        insightIds: [AvailableUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Available Units",
      },
      {
        insightIds: [ExpiringLeaseRenewalCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total # of Renewals",
      },
      {
        insightIds: [RenewalRent, ExpiringLeaseRenewalCount],
        insightCalculationType: "NUMBER",
        resultCol: "Average Renewal Rent",
        numFmt: "$0.00",
      },
      {
        insightIds: [RenewalTradeOutPercent],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Renewal Trade Out %",
      },
      {
        insightIds: [MoveIns],
        insightCalculationType: "NUMBER",
        resultCol: "Total # of New Leases",
      },
      {
        insightIds: [MoveInRent, MoveIns],
        insightCalculationType: "NUMBER",
        resultCol: "Average New Lease Rent",
        numFmt: "$0.00",
      },
      {
        insightIds: [MoveInTradeOutPercent],
        insightCalculationType: "PERCENTAGE",
        resultCol: "New Lease Trade Out %",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 32,
    dataSourceId: 1,
    name: "Rent PSF",
    visualizationType: ["LINE"],
    description:
      "Average rent per square foot for new leases, in-place leases, and renewal leases",
    calculations: [
      "Average In Place rents per square foot for a given time period",
      "Average New rents per square foot for a given time period",
      "Average Renewal rents per square foot for a given time period",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [MarketRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "Market Rent PSF",
      },
      {
        insightIds: [InPlaceRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "In Place PSF",
      },
      {
        insightIds: [MoveInRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "New Leases PSF",
      },
      {
        insightIds: [RenewalRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "Renewals PSF",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 33,
    dataSourceId: 1,
    name: "Renewal Retention - Expiring Leases",
    visualizationType: ["TABLE", "COLUMN"],
    description:
      "Percentage of leases expiring in the next 30 days that are renewing",
    calculations: [
      "Each Month",
      "Number of Expiring Leases [#]",
      "Number of Expiring Lease Renewals [#]",
      "100% - [Number of leases renewed / Total Number of expiring leases] %",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [LeaseExpiryCount],
        insightCalculationType: "NUMBER",
        resultCol: "Expiring Leases",
      },
      {
        insightIds: [ExpiringLeasePendingCount],
        insightCalculationType: "NUMBER",
        resultCol: "Pending Leases",
      },
      {
        insightIds: [ExpiringLeaseRenewalCount],
        insightCalculationType: "NUMBER",
        resultCol: "Renewing Leases",
      },
      {
        insightIds: [ExpiringLeaseRenewalCount, LeaseExpiryCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Renewing",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 34,
    dataSourceId: 1,
    name: "Availability by Month",
    visualizationType: ["TABLE", "COLUMN", "LINE"],
    description:
      "Current available, vacant, and notice unrented unit count and percentage",
    calculations: [
      "total available number of units. total available units / total units (as a %)",
      "number of vacant units. total vacant units / total units (as a %)",
      "current number of vacant units. current vacant units / total units (as a %)",
      "next month total number of vacant units. next month total number of vacant units / total units (as a %)",
      "future months vacant units. future months vacant units/total units (as a %)",
    ],
    tags: ["Leasing"],
    tableChartOptions: {
      bucketCols: true,
    },
    insightIds: [
      {
        insightIds: [AllUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total - #",
        tableColOrRowBucket: "Total",
      },
      {
        insightIds: [AvailableUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Available - #",
        tableColOrRowBucket: "Available",
      },
      {
        insightIds: [AvailableUnitCount, OnlineUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Available - %",
        tableColOrRowBucket: "Available",
      },
      {
        insightIds: [VacantUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant - #",
        tableColOrRowBucket: "Vacant",
      },
      {
        insightIds: [VacantUnrentedUnitCount, OnlineUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Vacant - %",
        tableColOrRowBucket: "Vacant",
      },
      {
        insightIds: [NoticeUnrentedUnitCountByLeaseEnd],
        dimensionFilter: { leaseEnd: "This Month" },
        insightCalculationType: "NUMBER",
        resultCol: "Current Month - #",
        tableColOrRowBucket: "Notice Given, Unrented",
        subBucket: "Current Month",
      },
      {
        insightIds: [NoticeUnrentedUnitCountByLeaseEnd, OnlineUnitCount],
        dimensionFilter: { leaseEnd: "This Month" },
        insightCalculationType: "PERCENTAGE",
        resultCol: "Current Month - %",
        tableColOrRowBucket: "Notice Given, Unrented",
        subBucket: "Current Month",
      },
      {
        insightIds: [NoticeUnrentedUnitCountByLeaseEnd],
        dimensionFilter: { leaseEnd: "Next Month" },
        insightCalculationType: "NUMBER",
        resultCol: "Next Month - #",
        tableColOrRowBucket: "Notice Given, Unrented",
        subBucket: "Next Month",
      },
      {
        insightIds: [NoticeUnrentedUnitCountByLeaseEnd, OnlineUnitCount],
        dimensionFilter: { leaseEnd: "Next Month" },
        insightCalculationType: "PERCENTAGE",
        resultCol: "Next Month - %",
        tableColOrRowBucket: "Notice Given, Unrented",
        subBucket: "Next Month",
      },
      {
        insightIds: [NoticeUnrentedUnitCountByLeaseEnd],
        dimensionFilter: { leaseEnd: "In 2+ Months" },
        insightCalculationType: "NUMBER",
        resultCol: "Future Months - #",
        tableColOrRowBucket: "Notice Given, Unrented",
        subBucket: "Future Months",
      },
      {
        insightIds: [NoticeUnrentedUnitCountByLeaseEnd, OnlineUnitCount],
        dimensionFilter: { leaseEnd: "In 2+ Months" },
        insightCalculationType: "PERCENTAGE",
        resultCol: "Future Months - %",
        tableColOrRowBucket: "Notice Given, Unrented",
        subBucket: "Future Months",
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 35,
    dataSourceId: 1,
    name: "Make Ready Performance",
    visualizationType: ["LINE"],
    description:
      "Average days to make a unit ready, average days a unit is on the market, average days a unit is vacant",
    calculations: [
      "Average Days for all units to move from Move Out date to Make Ready status in a month",
      "Average Days for all units to move from Make Ready status to Lease signed status in a month",
      "Average days for all units to move from Move out status to Move In status in a month",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [AverageDaysFromMoveOutToReady],
        insightCalculationType: "NUMBER",
        resultCol: "Average Days from Move Out to Ready",
      },
      {
        insightIds: [AverageDaysOnMarket],
        insightCalculationType: "NUMBER",
        resultCol: "Average Days on Market",
      },
      {
        insightIds: [AverageDaysVacant],
        insightCalculationType: "NUMBER",
        resultCol: "Average Days Vacant",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 36,
    dataSourceId: 1,
    name: "Make Ready Status",
    visualizationType: ["TABLE"],
    description:
      "Total number of vacant units, units that are vacant but ready to rent, percentage completed",
    calculations: [
      "Total Vacant Units",
      "Total Made Ready Units",
      "Total made ready units / total vacant units (shown as a %)",
      "Average Days to go from Move Out to Made Ready Unit",
      "Average Days from Move Out to Move In",
      "Total Units Vacant Not Ready",
      "Total Units Past Due on Rent",
    ],
    tags: ["Leasing"],
    tableChartOptions: {
      bucketCols: true,
    },
    insightIds: [
      {
        insightIds: [AvailableUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Available Units - Total",
        tableColOrRowBucket: "Available Units",
      },
      {
        insightIds: [AvailableUnitCount, OnlineUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Available Units - % Available",
        tableColOrRowBucket: "Available Units",
      },
      {
        insightIds: [VacantNotReadyUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Not Ready Status - Total",
        tableColOrRowBucket: "Not Ready Status",
      },
      {
        insightIds: [VacantNotReadyUnitCount, VacantUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Not Ready Status - %",
        tableColOrRowBucket: "Not Ready Status",
      },
      {
        insightIds: [AverageDaysFromMoveOutToReady],
        insightCalculationType: "NUMBER",
        resultCol: "Not Ready Status - Average Days to Make Ready",
        tableColOrRowBucket: "Not Ready Status",
      },
      {
        insightIds: [VacantReadyUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Made Ready Status - Total",
        tableColOrRowBucket: "Made Ready Status",
      },
      {
        insightIds: [VacantReadyUnitCount, VacantUnitCount],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Made Ready Status - %",
        tableColOrRowBucket: "Made Ready Status",
      },
      {
        insightIds: [AverageDaysVacant],
        insightCalculationType: "NUMBER",
        resultCol: "Made Ready Status - Average Days Vacant",
        tableColOrRowBucket: "Made Ready Status",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 37,
    dataSourceId: 1,
    name: "Leased",
    visualizationType: ["PERCENTAGE"],
    description:
      "Percentage of online units that currently have leases in-place",
    calculations: ["Total leased units / Total units"],
    tags: ["Leasing"],
    insightIds: [Leased],
    drillInInsights: [
      {
        insightIds: [AllUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Units",
      },
      {
        insightIds: [OfflineUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Admin/Model/Down Units",
      },
      {
        insightIds: [LeasedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Leased Units",
      },
      {
        insightIds: [AvailableUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Available Units",
      },
      {
        insightIds: [VacantUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Units",
      },
      {
        insightIds: [VacantUnrentedUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Vacant Unrented Units",
      },
      {
        insightIds: [Leased],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Leased",
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 38,
    dataSourceId: 1,
    name: "Screening Result Trend",
    visualizationType: ["LINE"],
    description: "Percentage of Screening Result passes, fails, and overrides",
    calculations: [
      "Total Passes / Total Screens Completed",
      "Total Fails / Total Screens Completed",
      "Total Results Overridden / Total Fails",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [ApplicationsApproved, ApplicationsScreened],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Passes",
      },
      {
        insightIds: [ApplicationsDenied, ApplicationsScreened],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Fails",
      },
      {
        insightIds: [ApplicationsOverridden, ApplicationsDenied],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Result Overrides",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 39,
    dataSourceId: 1,
    name: "Screens by Ad Source",
    visualizationType: ["PIE"],
    description: "Total number of resident screens completed by ad source",
    calculations: [
      "number of resident screens completed by ad source #1",
      "number of resident screens completed by ad source #2",
      "number of resident screens completed by ad source #3",
      "...",
    ],
    tags: ["Leasing"],
    generalChartOptions: {
      sortValuesInTooltip: {
        sortDirection: "desc",
      },
      filterByMonth: true,
    },
    tableChartOptions: {
      defaultSort: {
        dir: "desc",
        key: "Applications Screened",
      },
    },
    categoryInformation: {
      categoryKey: "resultCol",
      dimensionKey: "source",
      showTopNCategories: {
        sortKey: "Applications Screened",
        topN: 10,
      },
    },
    insightIds: [
      {
        insightIds: [ApplicationsScreenedByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Applications Screened",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 40,
    dataSourceId: 1,
    name: "Vacant Days",
    visualizationType: ["COLUMN"],
    description:
      "Average days units are vacant and the maximum number of vacant days",
    calculations: [
      "At a given point in time, for all vacant units - Add all days vacant / number of vacant units.",
      "For all vacant units, maximum days any given unit is vacant.",
    ],
    tags: ["Leasing"],
    byPropertyCalculationType: "MAX",
    insightIds: [
      {
        insightIds: [AverageDaysVacant],
        insightCalculationType: "NUMBER",
        resultCol: "Average Days Vacant",
      },
      {
        insightIds: [MaximumDaysVacant],
        insightCalculationType: "NUMBER",
        resultCol: "Maximum Days Vacant",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 41,
    dataSourceId: 1,
    name: "Move In Analysis by Ad Source",
    visualizationType: ["SCATTER"],
    description: "Number and rental rate of units generated by ad source",
    calculations: [
      "Number of Move-Ins by Ad Source, Average Rental Rate for the Move Ins by Ad Source - over a given period",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [MoveInsByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Move Ins",
      },
      {
        insightIds: [AverageMoveInRentByAdSource],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Average Move In Rent",
      },
    ],
    categoryInformation: {
      categoryKey: "resultCol",
      dimensionKey: "source",
      showTopNCategories: {
        topN: 10,
        sortKey: "Move Ins",
      },
    },
    generalChartOptions: {
      filterByMonth: true,
    },
    calculationType: "RANGE",
  },
  {
    id: 42,
    dataSourceId: 1,
    name: "Rent per Unit",
    visualizationType: ["LINE", "COLUMN"],
    description:
      "Average monthly rent per unit for market rent, in-place rent, new leases, and renewal leases",
    calculations: [
      "Average Market rents per unit for a given time period",
      "Average In Place rents per unit for a given time period",
      "Average New Lease rents per unit for a given time period",
      "Average Renewal Lease rents per unit for a given time period",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [MarketRent],
        insightCalculationType: "NUMBER",
        resultCol: "Market Rent",
      },
      {
        insightIds: [InPlaceRent],
        insightCalculationType: "NUMBER",
        resultCol: "In Place",
      },
      {
        insightIds: [MoveInRent],
        insightCalculationType: "NUMBER",
        resultCol: "New Leases",
      },
      {
        insightIds: [RenewalRent],
        insightCalculationType: "NUMBER",
        resultCol: "Renewals",
      },
    ],
    calculationType: "RANGE",
  },
  {
    id: 43,
    dataSourceId: 1,
    name: "Recent Leases - Last 30 days",
    visualizationType: ["TABLE"],
    description:
      "Effective rent PSF, lease term, trade out cost / percentage for leases signed in the last 30 days",
    calculations: [
      "# of new leases signed in last X days",
      "Effective rent = add all base rents less all concessions / number of new leases",
      "Effective Rent Per Square Foot = all base rents less all concessions / (add all rentable square feet for each rented unit)",
      "Lease Term: add all contract lease terms / number of leases",
      "Lease Trade Out:   current effective rents for newly leased units -less prior effective rent for same units",
      "% Trade Out: 100% - [Total effective rent for new signed leases/prior effective rent for signed leases]",
    ],
    tags: ["Leasing"],
    byPropertyCalculationType: "SUM",
    insightIds: [
      {
        insightIds: [NewAndRenewedLeasesLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "New Leases",
      },
      {
        insightIds: [EffectiveRentNewAndRenewedLeasesLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Avg Effective Rent",
      },
      {
        insightIds: [EffectiveRentPerSqftNewAndRenewedLeasesLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Effective Rent PSF",
      },
      {
        insightIds: [TermsNewAndRenewedLeasesLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Avg Lease Term",
      },
      {
        insightIds: [AverageTradeOutNewAndRenewedLeasesLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Lease Trade Out",
        delta: true,
      },
      {
        insightIds: [PercentTradeOutNewAndRenewedLeasesLast30Days],
        insightCalculationType: "PERCENTAGE",
        resultCol: "% Trade Out",
        delta: true,
      },
    ],
    tableChartOptions: {
      defaultSort: {
        key: "New Leases",
        dir: "desc",
      },
      removeZeroRows: true,
    },
    calculationType: "INSTANT",
  },
  {
    id: 44,
    dataSourceId: 1,
    name: "Executed Lease Summary",
    visualizationType: ["CUSTOM"],
    description:
      "Average rent, rent PSF, and lease term for new leases and renewals signed in the last 30 days",
    calculations: [],
    tags: ["Leasing"],
    insightIds: [],
    drillInInsights: [
      {
        insightIds: [NewSignsLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "# Signed",
        tableColOrRowBucket: "New Leases",
      },
      {
        insightIds: [EffectiveRentNewSignsLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Avg Rent",
        tableColOrRowBucket: "New Leases",
      },
      {
        insightIds: [EffectiveRentPerSqftNewSignsLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Rent PSF",
        tableColOrRowBucket: "New Leases",
      },
      {
        insightIds: [TermsNewSignsLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Avg Lease Term",
        tableColOrRowBucket: "New Leases",
      },
      {
        insightIds: [RenewalsLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "# Signed",
        tableColOrRowBucket: "Renewals",
      },
      {
        insightIds: [EffectiveRentRenewalsLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Avg Rent",
        tableColOrRowBucket: "Renewals",
      },
      {
        insightIds: [EffectiveRentPerSqftRenewalsLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Rent PSF",
        tableColOrRowBucket: "Renewals",
      },
      {
        insightIds: [TermsRenewalsLast30Days],
        insightCalculationType: "NUMBER",
        resultCol: "Avg Lease Term",
        tableColOrRowBucket: "Renewals",
      },
    ],
    calculationType: "INSTANT",
    customComponent: {
      width: 4,
      height: 2,
      defaultProps: {
        columns: executedLeaseSummaryColumns,
        rowDefinitions: executedLeaseSummaryRowDefinitions,
      },
      showHeading: true,
      drillable: true,
    },
  },
  {
    id: 45,
    dataSourceId: 1,
    name: "Rent to Income",
    visualizationType: ["LINE"],
    description: "Average monthly income, rent, and the rent to income ratio",
    calculations: [
      "Average Monthly Rent - $",
      "Average Monthly Income - $",
      "Rent to Income is Average Rent / Average Monthly Income (shown as a %)",
    ],
    tags: ["Resident"],
    insightIds: [
      {
        insightIds: [AverageMonthlyIncome],
        insightCalculationType: "NUMBER",
        resultCol: "Average Monthly Income",
        yAxisOrientation: "right",
      },
      {
        insightIds: [InPlaceRent],
        insightCalculationType: "NUMBER",
        resultCol: "Total Rent",
        yAxisOrientation: "right",
      },
      {
        insightIds: [InPlaceRent, AverageMonthlyIncome],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Rent to Income",
        yAxisOrientation: "left",
      },
    ],
    isMultiY: true,
    calculationType: "RANGE",
  },
  {
    id: 46,
    dataSourceId: 1,
    name: "Move Out Reason",
    visualizationType: ["PIE"],
    description: "Top 10 reasons for move-outs in the past 30 days",
    calculations: [],
    tags: ["Resident"],
    insightIds: [
      {
        insightIds: [MoveOutReason],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Move Out Reason",
      },
    ],
    categoryInformation: {
      categoryKey: "resultCol",
      dimensionKey: "reason",
      showTopNCategories: {
        topN: 10,
        sortKey: "Move Out Reason",
      },
    },
    tableChartOptions: {
      defaultSort: {
        key: "Move Out Reason",
        dir: "desc",
      },
    },
    generalChartOptions: {
      filterByThirtySixtyNinetyDays: true,
    },
    calculationType: "RANGE",
    export: {
      columns: [
        {
          header: "Move Out Reason",
          insight: MoveOutReason,
          period: {
            interval: "Month",
            count: 12,
          } as const,
          aggregate: "Sum",
          sort: { order: "Descending" },
        },
      ],
    },
  },
  {
    id: 47,
    dataSourceId: 1,
    name: "Work Order Aging",
    visualizationType: ["BAR", "COLUMN"],
    description:
      "Number of incomplete work orders by age and priority within the past 30 days",
    calculations: [
      "For everything not complete/canceled in period, bucketed age & total vs priority.",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [WorkOrderAgeDistribution],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Work Order Age",
      },
    ],
    generalChartOptions: {
      sortDataByPrimaryAxisKey: comparePeriods,
      sortDataKeys: comparePriority,
    },
    barChartOptions: {
      stacked: true,
    },
    columnChartOptions: {
      stacked: true,
    },
    categoryInformation: {
      categoryKey: "priority",
      dimensionKey: "age",
    },
    calculationType: "RANGE",
    export: {
      columns: [
        {
          header: "Work Order Age",
          insight: WorkOrderAgeDistribution,
        },
      ],
    },
  },
  {
    id: 48,
    dataSourceId: 1,
    name: "Service Requests Average Days to Completion",
    visualizationType: ["LINE"],
    description:
      "Average age of service requests completed within the past 30 days",
    calculations: [
      "For everything that hits completion in period, average age vs priority.",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [WorkOrderAverageAgeToCompletion],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Priority",
      },
    ],
    calculationType: "RANGE",
    generalChartOptions: {
      sortDataKeys: comparePriority,
    },
  },
  {
    id: 49,
    dataSourceId: 1,
    name: "Service Request Completions",
    visualizationType: ["TABLE"],
    description: "Service request completions by priority",
    calculations: [
      "For everything open at start of period or opened during, count of open, count of new, % complete by end of period, avg days to complete.",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [OpenWorkOrderCompletions],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Priority",
      },
    ],
    calculationType: "RANGE",
    generalChartOptions: {
      sortDataKeys: comparePriority,
    },
  },
  {
    id: 50,
    dataSourceId: 1,
    name: "Leads / Site Visits / Applications / Move Ins (30 Day)",
    visualizationType: ["SEQUENCE", "FUNNEL"],
    description:
      "Number of leads, site visits, applications, and move ins over the past 30 days",
    calculations: [
      "Total number of leads over 30 days",
      "Total number of site visits over 30 days",
      "Total number of applications over 30 days",
      "Total number of move ins over 30 days",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [Leads30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Leads",
      },
      {
        insightIds: [SiteVisits30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Site Visits",
      },
      {
        insightIds: [SiteVisits30Day, Leads30Day],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Lead to Site Visits",
        includeInFunnel: false,
      },
      {
        insightIds: [Applications30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Applications",
      },
      {
        insightIds: [Applications30Day, Leads30Day],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Lead to Application",
        includeInFunnel: false,
      },
      {
        insightIds: [MoveIns30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Move Ins",
      },
      {
        insightIds: [MoveIns30Day, Leads30Day],
        insightCalculationType: "PERCENTAGE",
        resultCol: "Lead to Move In",
        includeInFunnel: false,
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 51,
    dataSourceId: 1,
    name: "In Place Rent PSF - YoY",
    visualizationType: ["SEQUENCE"],
    description:
      "Average in-place rent per square foot for all units + YoY change",
    calculations: [
      "Total rental revenue / Total square feet of all rented units",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [InPlaceRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "In Place Rent PSF",
        className: "value",
        hideLabel: true,
      },
      {
        insightIds: [InPlaceRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "YoY",
        yoy: true,
        positive: true,
        className: "percentage",
      },
    ],
    calculationType: "INSTANT",
    className: "number-with-percentage",
  },
  {
    id: 52,
    dataSourceId: 1,
    name: "Move In Rent PSF - YoY",
    visualizationType: ["SEQUENCE"],
    description:
      "Average move-in rent per square foot for all units + YoY change",
    calculations: [
      "Total move-in rental revenue / Total square feet of move-in units",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [MoveInRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "Move In Rent PSF",
        className: "value",
        hideLabel: true,
      },
      {
        insightIds: [MoveInRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "YoY",
        yoy: true,
        positive: true,
        className: "percentage",
      },
    ],
    calculationType: "INSTANT",
    className: "number-with-percentage",
  },
  {
    id: 53,
    dataSourceId: 1,
    name: "Renewal Rent PSF - YoY",
    visualizationType: ["SEQUENCE"],
    description:
      "Average renewal rent per square foot for all units + YoY change",
    calculations: [
      "Total renewal rental revenue / Total square feet of renewal units",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [RenewalRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "Renewal Rent PSF",
        className: "value",
        hideLabel: true,
      },
      {
        insightIds: [RenewalRentPerSqft],
        insightCalculationType: "NUMBER",
        resultCol: "YoY",
        yoy: true,
        positive: true,
        className: "percentage",
      },
    ],
    calculationType: "INSTANT",
    className: "number-with-percentage",
  },
  {
    id: 54,
    dataSourceId: 1,
    name: "In Place Rent Per Unit - YoY",
    visualizationType: ["SEQUENCE"],
    description: "Average in-place rent per unit + YoY Change",
    calculations: [
      "Total of all in-place monthly rents / Total # of rented units",
    ],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [InPlaceRent],
        insightCalculationType: "NUMBER",
        resultCol: "In Place Rent Per Unit",
        className: "value",
        hideLabel: true,
      },
      {
        insightIds: [InPlaceRent],
        insightCalculationType: "NUMBER",
        resultCol: "YoY",
        yoy: true,
        positive: true,
        className: "percentage",
      },
    ],
    calculationType: "INSTANT",
    className: "number-with-percentage",
  },
  {
    id: 55,
    dataSourceId: 1,
    name: "Portfolio Overview",
    visualizationType: ["CUSTOM"],
    description: "# Properties, Units, residents, and % Occupancy",
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
    customComponent: {
      width: 8,
      height: 1,
      defaultProps: {
        spinWhileLoading: true,
      },
    },
  },
  {
    id: 56,
    dataSourceId: 1,
    name: "Performance v goals",
    visualizationType: ["CUSTOM"],
    description: "How my properties are doing compared to the goals I have set",
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
    customComponent: {
      width: 2,
      height: 6,
      showHeading: true,
    },
  },
  {
    id: 57,
    dataSourceId: 1,
    name: "Weekly summary",
    visualizationType: ["CUSTOM"],
    description: "How my properties are doing compared to last week",
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
    customComponent: {
      width: 2,
      height: 6,
      showHeading: true,
    },
  },
  {
    id: 58,
    dataSourceId: 1,
    name: "Property Overview",
    visualizationType: ["CUSTOM"],
    description: "Overview of a single property.",
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
    customComponent: {
      width: 6,
      height: 1,
      showHeading: false,
      noContentPadding: true,
    },
    propertyOnly: true,
  },
  {
    id: 59,
    dataSourceId: 1,
    name: "Bedroom Summary",
    visualizationType: ["TABLE"],
    description:
      "Unit count, available unit count, and average rent by bedroom count.",
    calculations: [],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [AllUnitCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Count",
      },
      {
        insightIds: [AvailableUnitCountByBedrooms],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Available",
      },
    ],
    generalChartOptions: {
      sortDataKeys: true,
      showLastNMonths: 1,
    },
    tableChartOptions: {
      inverted: true,
    },
    categoryInformation: {
      categoryKey: "bedrooms",
      dimensionKey: "resultCol",
    },
    calculationType: "RANGE",
  },
  {
    id: 60,
    dataSourceId: 1,
    name: "Coming Up",
    visualizationType: ["PIE"],
    description: "Breakdown of expiring leases in the next 30 days.",
    calculations: [],
    tags: ["Resident"],
    insightIds: [
      {
        insightIds: [ExpiringLeaseRenewalCount],
        insightCalculationType: "NUMBER",
        resultCol: "Renewing",
      },
      {
        insightIds: [ExpiringLeasePendingCount],
        insightCalculationType: "NUMBER",
        resultCol: "Pending",
      },
      {
        insightIds: [ExpiringLeaseMoveOutCount],
        insightCalculationType: "NUMBER",
        resultCol: "Not renewing",
      },
    ],
    categoryInformation: {
      categoryKey: "",
      dimensionKey: "resultCol",
    },
    calculationType: "INSTANT",
    export: {
      columns: [
        {
          header: "Renewing",
          insight: ExpiringLeaseRenewalCount,
          aggregate: "Sum",
          sort: { order: "Descending" },
        },
        {
          header: "Pending",
          insight: ExpiringLeasePendingCount,
          aggregate: "Sum",
          sort: { order: "Descending" },
        },
        {
          header: "Not renewing",
          insight: ExpiringLeaseMoveOutCount,
          aggregate: "Sum",
          sort: { order: "Descending" },
        },
      ],
    },
  },
  {
    id: ImageInsightId, // 61
    dataSourceId: 1,
    name: "Image",
    visualizationType: ["CUSTOM"],
    description: "Embedded image",
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
    customComponent: {
      width: 1,
      height: 1,
      defaultProps: {},
      showHeading: true,
    },
  },
  {
    id: 62,
    dataSourceId: 1,
    name: "New Leases - Last 30 Days",
    visualizationType: ["CUSTOM"],
    description: "New leases / Trade out information for the last 30 days",
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
    customComponent: {
      width: 8,
      height: 2,
      defaultProps: {
        columns: newLeasesColumns,
        rowDefinitions: newLeasesRowDefinitions,
      },
      showHeading: true,
    },
  },
  {
    id: 63,
    dataSourceId: 1,
    name: "Net New Residents (30 Day)",
    visualizationType: ["SEQUENCE"],
    description:
      "Number of Move Ins, Move Outs, Net New Residents, and Available units for the last 30 days.",
    calculations: [],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [MoveIns30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Move Ins",
      },
      {
        insightIds: [MoveOuts30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Move Outs",
      },
      {
        insightIds: [MoveIns30Day, MoveOuts30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Net New",
        operand: "-",
      },
      {
        insightIds: [AvailableUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Available",
      },
    ],
    drillInInsights: [
      {
        insightIds: [OnlineUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Total Leasable Units",
      },
      {
        insightIds: [MoveIns30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Move Ins",
      },
      {
        insightIds: [MoveOuts30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Move Outs",
      },
      {
        insightIds: [MoveIns30Day, MoveOuts30Day],
        insightCalculationType: "NUMBER",
        resultCol: "Net New",
        operand: "-",
      },
      {
        insightIds: [AvailableUnitCount],
        insightCalculationType: "NUMBER",
        resultCol: "Available",
      },
    ],
    calculationType: "INSTANT",
  },
  {
    id: 64,
    dataSourceId: 1,
    name: "Unit Map",
    description: "UnitMap Prototype",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 6,
      height: 3,
      showHeading: false,
      noContentPadding: true,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
  {
    id: 65,
    dataSourceId: 1,
    name: "Unit Type Summary",
    visualizationType: ["TABLE"],
    description:
      "Unit count, available unit count, and average rent by unit type.",
    calculations: [],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [AllUnitCountByUnitType],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Count",
      },
      {
        insightIds: [AvailableUnitCountByUnitType],
        insightCalculationType: "NUMBER",
        resultCol: "dimensions.Available",
      },
    ],
    generalChartOptions: {
      sortDataKeys: true,
      showLastNMonths: 1,
    },
    categoryInformation: {
      categoryKey: "unitType",
      dimensionKey: "resultCol",
    },
    calculationType: "RANGE",
  },
  {
    id: 66,
    dataSourceId: 1,
    name: "Move In Rent Per Unit - YoY",
    visualizationType: ["SEQUENCE"],
    description: "Move In rent per unit + YoY Change",
    calculations: ["Total of move in monthly rents / Total # of move ins"],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [MoveInRent],
        insightCalculationType: "NUMBER",
        resultCol: "Move In Rent Per Unit",
        className: "value",
        hideLabel: true,
      },
      {
        insightIds: [MoveInRent],
        insightCalculationType: "NUMBER",
        resultCol: "YoY",
        yoy: true,
        positive: true,
        className: "percentage",
      },
    ],
    calculationType: "INSTANT",
    className: "number-with-percentage",
  },
  {
    id: 67,
    dataSourceId: 1,
    name: "Renewal Rent Per Unit - YoY",
    visualizationType: ["SEQUENCE"],
    description: "Renewal rent per unit + YoY Change",
    calculations: ["Total of renewal monthly rents / Total # of renewals"],
    tags: ["Leasing"],
    insightIds: [
      {
        insightIds: [RenewalRent],
        insightCalculationType: "NUMBER",
        resultCol: "Renewal Rent Per Unit",
        className: "value",
        hideLabel: true,
      },
      {
        insightIds: [RenewalRent],
        insightCalculationType: "NUMBER",
        resultCol: "YoY",
        yoy: true,
        positive: true,
        className: "percentage",
      },
    ],
    calculationType: "INSTANT",
    className: "number-with-percentage",
  },
  {
    id: UnicardInsightId,
    dataSourceId: 1,
    name: "Unicard (unsupported)",
    description: "",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 3,
      height: 3,
      showHeading: false,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
  {
    id: PropertyInformationInsightId,
    dataSourceId: 1,
    name: "Property Information",
    description: "Property information",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 2,
      height: 2,
      showHeading: false,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
  {
    id: EmbeddedReportId,
    dataSourceId: 1,
    name: "Embedded Report",
    description: "Embeds a report in a dashboard",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 4,
      height: 2,
      showHeading: true,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
  {
    id: HtmlInsightId,
    dataSourceId: 1,
    name: "HTML Card",
    description: "HTML Card",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 4,
      height: 2,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
  {
    id: IndependentImageInsightId,
    dataSourceId: 1,
    name: "Image Card",
    description: "Image Card",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 4,
      height: 2,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
  {
    id: IframeInsightId,
    dataSourceId: 1,
    name: "Iframe Card",
    description: "Iframe Card",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 4,
      height: 2,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
  {
    id: EmailInsightId,
    dataSourceId: 1,
    name: "Email Card",
    description: "Email Card",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 4,
      height: 2,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
  {
    id: PropertyNoteInsightId,
    dataSourceId: 1,
    name: "Property Note",
    description: "Property Note",
    visualizationType: ["CUSTOM"],
    customComponent: {
      width: 4,
      height: 2,
    },
    calculations: [],
    tags: [],
    insightIds: [],
    calculationType: "INSTANT",
  },
];

export default definitions;
