function equalsSingularOrPlural(str: string, plural: string) {
  const lowercase = str.toLowerCase();
  return lowercase === plural || `${lowercase}s` === plural;
}

export function timePeriodToSeconds(timePeriod: string): number {
  const [periodValue, period] = timePeriod.split(" ");
  const lowerNum = periodValue.includes("-")
    ? periodValue.split("-")[0]
    : periodValue;
  const periodValueNum = parseInt(lowerNum);
  if (equalsSingularOrPlural(period, "seconds")) {
    return periodValueNum;
  } else if (equalsSingularOrPlural(period, "minutes")) {
    return periodValueNum * 60;
  } else if (equalsSingularOrPlural(period, "hours")) {
    return periodValueNum * 60 * 60;
  } else if (equalsSingularOrPlural(period, "days")) {
    return periodValueNum * 60 * 60 * 24;
  } else if (equalsSingularOrPlural(period, "weeks")) {
    return periodValueNum * 60 * 60 * 24 * 7;
  } else if (equalsSingularOrPlural(period, "months")) {
    return periodValueNum * 60 * 60 * 24 * 30;
  } else {
    return -1;
  }
}

export function comparePeriods(periodA: string, periodB: string) {
  const secondsA = timePeriodToSeconds(periodA);
  const secondsB = timePeriodToSeconds(periodB);
  return secondsA - secondsB;
}
