import "./testIntegrationModal.css";

import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

import { unexpectedError } from "../../../../constants";
import {
  TestIntegrationDto,
  testIntegration,
} from "../../../../services/integrationsService";
import UncontrolledModal from "../../../common/modal/UncontrolledModal";

interface TestIntegrationModalProps {
  integrationDto: TestIntegrationDto;
  onClose: () => void;
}

type TestStatus = "in progress" | "failed" | "passed";

const TestIntegrationModal: React.FC<TestIntegrationModalProps> = ({
  integrationDto,
  onClose,
}) => {
  const [serverError, setServerError] = useState<string>("");
  const [status, setStatus] = useState<TestStatus>("in progress");
  const [detail, setDetail] = useState<string[]>();
  const messagesByStatus: Record<TestStatus, string> = {
    "in progress": "We are currently testing your configuration, please wait.",
    passed: "All tests passed, you may proceed.",
    failed: "Errors were encountered during the test.",
  };

  const [immutableDto] = useState(integrationDto); // just to be extra sure..
  useEffect(() => {
    testIntegration(immutableDto)
      .then((testResponse) => {
        setStatus(testResponse.success ? "passed" : "failed");
        setDetail(testResponse.detail);
      })
      .catch(() => {
        setServerError(unexpectedError);
      });
  }, [immutableDto]);

  return (
    <UncontrolledModal onClosed={onClose} className="test-integration-modal">
      <ModalHeader>
        <div className="flex-row d-flex align-items-center">
          {status === "in progress" ? (
            <Spinner color="secondary" />
          ) : (
            <FontAwesomeIcon
              icon={status === "failed" ? faTimes : faCheck}
              color={status === "failed" ? "#E14C35" : "#629922"}
              size="lg"
            />
          )}
          <div className="test-integration-modal-status">Tests {status}</div>
        </div>
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <p>{messagesByStatus[status]}</p>
        {detail && detail.length ? (
          <ul className="test-integration-modal-detail">
            {detail.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default TestIntegrationModal;
