import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { TableValues } from "../../../services/calculateInsight";
import definitions from "../../../services/insightLibrary/insightDefinitions";
import {
  ComplexInsightIds,
  Insight,
  SortDirection,
} from "../../../services/models";
import { fromFormattedNumber } from "../../../utils/number";
import { DelimitedStringArrayParamDeepEqual } from "../../../utils/useQueryParams";
import JhTable, {
  ColOrRowBucket,
  JhTableCol,
  RowHeader,
  TableRow,
} from "../../common/table/JhTable";
import DimensionFilter from "../DimensionFilter";
import MonthFilter from "../MonthFilter";
import ThirtySixtyNinetyDayFilter from "../ThirtySixtyNinetyDayFilter";

export interface BaseTableChartProps {
  insightId: number;
  dashboardInsightId: number;
  cssName?: string;
  showDimensionFilter?: boolean;
  showMonthFilter?: boolean;
  dimensionFilterSelectedProp?: string;
  monthSelectedProp?: string;
  onRowClick?: (row: TableRow, rowHeader: string) => void;
  formatColumnHeaders?: (colHeaders: string[]) => JhTableCol[];
  formatRowHeaders?: (rowHeaders: string[]) => RowHeader[];
}

interface TableChartProps extends BaseTableChartProps {
  tableValues: TableValues;
}

export const TableChart: React.FC<TableChartProps> = ({
  insightId,
  dashboardInsightId,
  cssName,
  tableValues,
  showDimensionFilter,
  showMonthFilter,
  onRowClick,
  formatColumnHeaders,
  formatRowHeaders,
}) => {
  const [columns, setColumns] = useState<JhTableCol[]>([]);
  const [rows, setRows] = useState<Array<TableRow>>([]);
  const [rowHeaders, setRowHeaders] = useState<(string | RowHeader)[]>([]);
  const [colBuckets, setColBuckets] = useState<Array<ColOrRowBucket>>([]);
  const [rowBuckets, setRowBuckets] = useState<Array<ColOrRowBucket>>([]);
  const [dataUnavailable, setDataUnavailable] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const [dimensionOptions, setDimensionOptions] = useState<string[]>([]);
  const [monthOptions, setMonthOptions] = useState<string[]>([]);
  const insight = definitions.find((i) => i.id === insightId) as Insight;
  const childInsights = insight.insightIds as ComplexInsightIds[];
  const shouldBucketCols = insight.tableChartOptions?.bucketCols;
  const shouldBucketRows = insight.tableChartOptions?.bucketRows;

  useEffect(() => {
    const insight = definitions.find((i) => i.id === insightId) as Insight;
    if (insight.tableChartOptions?.removeZeroRows) {
      const newRows: Array<Record<string, string | number>> = [];
      const newRowHeaders: string[] = [];
      for (let idx = 0; idx < tableValues.rows.length; idx++) {
        const row = tableValues.rows[idx];
        const rowHeader = tableValues.rowHeaders[idx];
        const shouldAddRow = Object.values(row).some(
          (val) =>
            val !== "–" &&
            (typeof val === "number" ? val !== 0 : parseFloat(val) !== 0),
        );
        if (shouldAddRow) {
          newRows.push(row);
          newRowHeaders.push(rowHeader);
        }
      }
      tableValues.rows = newRows;
      tableValues.rowHeaders = newRowHeaders;
    }
    let currentBucketIndex = 0;
    const colBucketsWithCountAndIndex = childInsights.reduce(
      (prev, current) => {
        if (current.tableColOrRowBucket) {
          if (prev[current.tableColOrRowBucket]) {
            prev[current.tableColOrRowBucket].span =
              prev[current.tableColOrRowBucket].span + 1;
          } else {
            prev[current.tableColOrRowBucket] = {
              span: 1,
              index: currentBucketIndex,
            };
            currentBucketIndex = currentBucketIndex + 1;
          }
        }
        return prev;
      },
      {} as { [key: string]: { span: number; index: number } },
    );
    const colOrRowBuckets = Object.entries(colBucketsWithCountAndIndex)
      .map(([key, value]) => {
        return {
          ...value,
          display: key,
        };
      })
      .sort((a, b) => a.index - b.index);
    const formattedColHeaders = formatColumnHeaders
      ? formatColumnHeaders(tableValues.colHeaders)
      : tableValues.colHeaders.map((colHeader) => {
          return {
            key: colHeader,
            display: colHeader,
          };
        });
    const overriddenColHeaders =
      colOrRowBuckets.length && shouldBucketCols
        ? formattedColHeaders.map((colHeader) => {
            return {
              ...colHeader,
              key: colHeader.key,
              display: colHeader.display.split("-")[1],
            };
          })
        : formattedColHeaders;
    const overriddenRowHeaders =
      colOrRowBuckets.length && shouldBucketRows
        ? tableValues.rowHeaders.map((rowHeader) => rowHeader.split("-")[1])
        : tableValues.rowHeaders;
    const formattedRowHeaders = formatRowHeaders
      ? formatRowHeaders(overriddenRowHeaders)
      : overriddenRowHeaders;
    if (shouldBucketCols) {
      setColBuckets(colOrRowBuckets);
    } else if (shouldBucketRows) {
      setRowBuckets(colOrRowBuckets);
    }
    setColumns(overriddenColHeaders);
    setRowHeaders(formattedRowHeaders);
    setRows(tableValues.rows);
    setDimensionOptions(tableValues.dimensionOptions);
    setDataUnavailable(tableValues.dataUnavailable);
    setMonthOptions(tableValues.monthOptions);
  }, [
    childInsights,
    shouldBucketCols,
    shouldBucketRows,
    tableValues,
    insightId,
    formatColumnHeaders,
    formatRowHeaders,
  ]);

  const chartContentClassName =
    (insight?.generalChartOptions?.filterDimension &&
      showDimensionFilter !== false) ||
    (insight?.generalChartOptions?.filterByMonth && showMonthFilter !== false)
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";

  function sort(
    sortKey: string,
    sortDirection: SortDirection,
  ): { rows: TableRow[]; rowHeaders: Array<string | RowHeader> } {
    const rowsWithHeaders = rows.map((row, idx) => {
      return {
        tableRow: row,
        header: rowHeaders[idx],
      };
    });
    rowsWithHeaders.sort((a, b) => {
      const aToNumber = fromFormattedNumber(
        a.tableRow[sortKey] ? a.tableRow[sortKey].toString() : "0",
      );
      const bToNumber = fromFormattedNumber(
        b.tableRow[sortKey] ? b.tableRow[sortKey].toString() : "0",
      );
      if (sortDirection === "asc") {
        return aToNumber - bToNumber;
      } else {
        return bToNumber - aToNumber;
      }
    });
    const rowsWithoutHeaders = rowsWithHeaders.map(
      (rowWithHeader) => rowWithHeader.tableRow,
    );
    const rowHeadersWithoutRows = rowsWithHeaders.map(
      (rowWithHeader1) => rowWithHeader1.header,
    );
    return {
      rows: rowsWithoutHeaders,
      rowHeaders: rowHeadersWithoutRows,
    };
  }

  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth &&
        showMonthFilter !== false ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension &&
        showDimensionFilter !== false ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {dataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {dataUnavailable ? null : (
        <div
          className={chartContentClassName}
          style={{ cursor: "default", minHeight: 300 }}
          onClick={(e) => e.stopPropagation()}
        >
          <JhTable
            cssName={cssName}
            columns={columns}
            rows={rows}
            columnBuckets={colBuckets}
            rowBuckets={rowBuckets}
            title="Leasing Velocity"
            rowHeaders={rowHeaders}
            onRowClick={onRowClick}
            sortFunction={
              !insight.tableChartOptions?.inverted ? sort : undefined
            }
            defaultSortDirection={insight.tableChartOptions?.defaultSort?.dir}
            defaultSortKey={insight.tableChartOptions?.defaultSort?.key}
          />
        </div>
      )}
    </>
  );
};
