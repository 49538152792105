import { numerically, RangeExpression } from "@joyhub-integration/shared";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonGroup, FormGroup, Input } from "reactstrap";

type NumericRangePickerProps = {
  columnValues: string[]; // strings from the db
  existingSelection?: RangeExpression;
  updateSelection: (update?: RangeExpression) => void;
  dataType: "number" | "percentage" | "year" | "dollar";
};

const NumericRangePicker: React.FC<NumericRangePickerProps> = ({
  columnValues,
  existingSelection,
  updateSelection,
  dataType,
}) => {
  const [mode, setMode] = useState<"before" | "after" | "between">(() => {
    const { min, max } = existingSelection ?? {};
    if (typeof max === "number" && typeof min === "number") {
      return "between";
    } else if (typeof max === "number") {
      return "before";
    } else {
      return "after";
    }
  });

  const minRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const maxRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (mode === "before") {
      maxRef.current?.focus();
    } else {
      minRef.current?.focus();
    }
  }, [mode]);

  const numericValues = useMemo(() => {
    return columnValues
      .map((n) => Number(n))
      .filter((n) => !isNaN(n))
      .sort(numerically);
  }, [columnValues]);
  const len = numericValues.length;
  const vMin = len > 0 ? numericValues[0] : 0;
  const vMax = len > 1 ? numericValues[len - 1] : vMin + 1;
  const step = vMax - vMin > 10 ? 1 : 0.1;

  const [min, setMin] = useState(existingSelection?.min);
  const [max, setMax] = useState(existingSelection?.max);

  useEffect(() => {
    if (existingSelection == null) {
      setMin(undefined);
      setMax(undefined);
    }
  }, [existingSelection]);

  useEffect(() => {
    const validRange = Number(min) <= Number(max);
    if (validRange && mode === "between") {
      const re: RangeExpression = {
        type: "expression",
        min,
        max,
      };
      updateSelection(re);
    } else if (typeof min === "number" && mode === "after") {
      const re: RangeExpression = {
        type: "expression",
        min,
      };
      updateSelection(re);
    } else if (typeof max === "number" && mode === "before") {
      const re: RangeExpression = {
        type: "expression",
        max,
      };
      updateSelection(re);
    }
  }, [min, max, mode, updateSelection]);

  return (
    <div className="mb-2 px-4">
      <ButtonGroup className="p-2">
        <Button
          size="sm"
          outline={mode !== "after"}
          onClick={() => {
            setMode("after");
          }}
          style={{ width: "120px" }}
        >
          {dataType === "year" ? "After" : "Minimum"}
        </Button>
        <Button
          size="sm"
          outline={mode !== "before"}
          onClick={() => {
            setMode("before");
          }}
          style={{ width: "120px" }}
        >
          {dataType === "year" ? "Before" : "Maximum"}
        </Button>
        <Button
          size="sm"
          outline={mode !== "between"}
          onClick={() => {
            setMode("between");
          }}
          style={{ width: "120px" }}
        >
          Between
        </Button>
      </ButtonGroup>

      <FormGroup className="ps-2 mt-2">
        <Input
          innerRef={minRef}
          name="min"
          type="number"
          value={mode === "before" ? "" : (min ?? "")}
          min={vMin}
          max={vMax - step}
          step={step}
          onChange={(evt) => setMin(evt.target.valueAsNumber)}
          className="d-inline w-auto"
          disabled={mode === "before"}
          autoFocus={true}
          style={{ opacity: mode === "before" ? 0.25 : 1 }}
        />
        <span
          className="px-2"
          style={{ opacity: mode === "between" ? 1 : 0.25 }}
        >
          to
        </span>
        <Input
          innerRef={maxRef}
          name="max"
          type="number"
          value={mode === "after" ? "" : (max ?? "")}
          min={vMin + step}
          max={vMax}
          step={step}
          onChange={(evt) => setMax(evt.target.valueAsNumber)}
          className="d-inline w-auto"
          disabled={mode === "after"}
          autoFocus={true}
          style={{ opacity: mode === "after" ? 0.25 : 1 }}
        />
      </FormGroup>
    </div>
  );
};

export default NumericRangePicker;
