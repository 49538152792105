import { max, mean, pick, sortBy, sum, sumBy } from "lodash";
import { compare } from "natural-orderby";

import {
  arrayify,
  callerID,
  equalObject,
  getOrThrow,
  ObjectKeyedMap,
  PureDate,
  sequence,
} from "../util";
import {
  CountOfTotal,
  DateRange,
  DateToRange,
  DerivedCountOfTotalInsightDefinition,
  DerivedNumeratorInsightDefinition,
  Dimensioned,
  DimensionedValue,
  Dimensions,
  GoalType,
  InsightComputation,
  InsightDefinition,
  InsightDescription,
  InsightIdentifier,
  InsightImplementation,
  InsightResult,
  InsightValues,
} from "./insightTypes";

const naturallyCompare = compare();

export const sumNumbers = (values: number[]) => sum(values);

export const maxNumbers = (values: number[]) => max(values);

export const meanNumbers = (values: number[]) => mean(values);

export const sumCountOfTotal = (values: CountOfTotal[]): CountOfTotal => {
  let count = 0,
    total = 0;
  for (const { count: c, total: t } of values) {
    count += c;
    total += t;
  }
  return { count, total };
};

export const sumDimensionedNumbers = <A extends string>(
  values: Dimensioned<number, A>[],
): Dimensioned<number, A> => {
  const dimensionValues = ObjectKeyedMap.empty<Dimensions<A>, number>();
  for (const list of values) {
    for (const { dimensions, value } of list) {
      dimensionValues.update(dimensions, (v) => value + v, 0);
    }
  }
  return dimensionValues
    .entrySet()
    .map(([dimensions, value]) => ({ dimensions, value }));
};

export const implId = (impl: InsightImplementation): number =>
  typeof impl.insight === "number" ? impl.insight : impl.insight.id;

export const implName = (impl: InsightImplementation): string =>
  typeof impl.insight === "number" ? `${impl.insight}` : impl.insight.name;

export const getInsights = <A>(
  values: InsightValues,
  insight: InsightImplementation<A>,
): A =>
  getOrThrow(values[implId(insight)] as unknown as A, () =>
    Error(`Missing insight: ${implName(insight)}`),
  );

export const sumDimensionedNumbersInsights =
  <A extends string>(
    ...insights: InsightImplementation<Dimensioned<number, A>>[]
  ) =>
  (_id: number, _date: Date, values: InsightValues): Dimensioned<number, A> =>
    sumDimensionedNumbers(
      insights.map((insight) => getInsights(values, insight)),
    );

export const sumNumberInsights =
  (...insights: InsightImplementation<number>[]) =>
  (_id: number, _date: Date, values: InsightValues): number => {
    let total = 0;
    for (const insight of insights) {
      total += getInsights(values, insight);
    }
    return total;
  };

export const sumCountOfTotalInsights =
  (...insights: InsightImplementation<CountOfTotal>[]) =>
  (_id: number, _date: Date, values: InsightValues): CountOfTotal => {
    let count = 0,
      total = 0;
    for (const insight of insights) {
      const { count: c, total: t } = getInsights(values, insight);
      count += c;
      total += t;
    }
    return { count, total };
  };

export const sumDimensionedCountOfTotal = <A extends string>(
  values: Dimensioned<CountOfTotal, A>[],
): Dimensioned<CountOfTotal, A> => {
  const dimensionValues = ObjectKeyedMap.empty<Dimensions<A>, CountOfTotal>();
  for (const list of values) {
    for (const {
      dimensions,
      value: { count, total },
    } of list) {
      dimensionValues.update(
        dimensions,
        ({ count: c, total: t }) => ({ count: count + c, total: total + t }),
        { count: 0, total: 0 },
      );
    }
  }
  return dimensionValues
    .entrySet()
    .map(([dimensions, value]) => ({ dimensions, value }));
};

/** Sums all the numbers of a dimensioned value. */
export const sumDimensionedNumber = (
  values: Dimensioned<number, any>,
): number => sumBy(values, (v) => v.value);

export const sumDimensionedInsight =
  (insight: InsightImplementation<Dimensioned<number, any>>) =>
  (_id: number, _date: Date, values: InsightValues): number =>
    sumDimensionedNumber(getInsights(values, insight));

export const subtractInsights =
  (
    minuendInsight: InsightImplementation<number>,
    subtrahendInsight: InsightImplementation<number>,
  ) =>
  (_id: number, _date: Date, values: InsightValues): number | undefined => {
    const minuend = getInsights(values, minuendInsight);
    const subtrahend = getInsights(values, subtrahendInsight);
    return minuend === undefined || subtrahend === undefined
      ? undefined
      : minuend - subtrahend;
  };

export const countOfTotalInsight =
  (
    countInsight: InsightImplementation<number>,
    totalInsight: InsightImplementation<number>,
  ) =>
  (
    _id: number,
    _date: Date,
    values: InsightValues,
  ): CountOfTotal | undefined => {
    const count = getInsights(values, countInsight);
    const total = getInsights(values, totalInsight);
    return count === undefined || total === undefined
      ? undefined
      : { count, total };
  };

export const dimensionedCountOfTotalInsight =
  <A extends string>(
    countInsight: InsightImplementation<Dimensioned<number, A>>,
    totalInsight: InsightImplementation<Dimensioned<number, A>>,
  ) =>
  (
    _id: number,
    _date: Date,
    values: InsightValues,
  ): Dimensioned<CountOfTotal, A> | undefined => {
    const counts = getInsights(values, countInsight);
    const totals = getInsights(values, totalInsight);
    return (
      counts &&
      totals &&
      totals.map(({ dimensions, value: total }) => ({
        dimensions,
        value: {
          count:
            counts.find((o) => equalObject(dimensions, o.dimensions))?.value ??
            0,
          total,
        },
      }))
    );
  };

/** For month-month computations, true if date is from the current month, and
 * anything other than the last day.  Idea being that if we're doing a monthly
 * insight for the current month and it would have only partial data, then we
 * switch to last 30 days instead.
 */
export const onlyPartWayThroughThisMonth = (date: Date) => {
  const vs = new PureDate();
  vs.setDate(1); // First day of this month
  if (vs.greaterThan(date))
    // date is from a previous month
    return false;
  vs.setMonth(1 + vs.getMonth());
  vs.setDate(0); // Last day of this month
  return vs.greaterThan(date);
};

const monthOfOr =
  (thisMonth?: (date: Date) => DateRange) =>
  (date: Date): DateRange => {
    if (thisMonth !== undefined && onlyPartWayThroughThisMonth(date))
      return thisMonth(date);
    const from = new PureDate(date);
    from.setDate(1);
    const to = new PureDate(from);
    to.setMonth(1 + to.getMonth());
    to.setDate(0);
    return { from, to };
  };

/** Returns from/to dates corresponding to the start and end of the given
 * month.  Dates should thus be within the inclusive-inclusive range
 * `[from, to]`: `to` will be the first day of the month and `from` the last.
 *
 * Two variants: ..OrLast30Days will return the last 30 days if date is in the
 * current month; ..IncludingCurrentMonth will always return the first to last
 * days of the month containing date.
 */
export const monthOfOrLast30Days = monthOfOr((date: Date): DateRange => {
  const { from, to } = last30Days.expand(date);
  to.setDate(to.getDate() - 1); // TODO: delete this and merge lines above and below!
  return { from, to };
});

export const monthOfIncludingCurrentMonth = monthOfOr();

export const mapInsight =
  <A, B>(
    insight: InsightImplementation<A>,
    fab: (a: A) => B,
  ): InsightComputation<B> =>
  (id, date, values) =>
    fab(getInsights(values, insight));

export const mapDimensionedInsight =
  <A extends string, AV, B extends string, BV>(
    insight: InsightImplementation<DimensionedValue<AV, A>[]>,
    fab: (a: DimensionedValue<AV, A>) => DimensionedValue<BV, B>,
  ): InsightComputation<DimensionedValue<BV, B>[]> =>
  (id, date, values) => {
    const ivs = getInsights(values, insight).map(fab);
    if (ivs.length === 0) return ivs;
    const keys = Object.keys(ivs[0].dimensions as Dimensions<B>).sort() as B[];
    return sortBy(
      ivs,
      keys.map((k) => (i: DimensionedValue<BV, B>) => i.dimensions[k]),
    );
  };

/** Aggregate a multi-dimensional insight by a single dimension. */
export const aggregateByDimension =
  <A extends string, B extends A>(dimension: B) =>
  (values: Dimensioned<number, A>): Dimensioned<number, B> => {
    // TODO: figure out the typed result
    const countByDimension = {} as Record<string, number>;
    for (const { dimensions, value } of values) {
      const key = dimensions[dimension];
      countByDimension[key] = value + (countByDimension[key] ?? 0);
    }
    return Object.keys(countByDimension)
      .sort()
      .map((dim) => {
        const dimensions = {
          [dimension]: dim,
        } as Record<B, string>;
        return { dimensions, value: countByDimension[dim] };
      });
  };

/** Filter a multi-dimensional insight by a single dimension. */
export const filterByDimension =
  <A extends string, B extends A>(dimension: B, dimensionValue: string) =>
  (values: Dimensioned<number, A>): Dimensioned<number, Exclude<A, B>> => {
    const as: Dimensioned<number, Exclude<A, B>> = [];
    for (const { dimensions, value } of values) {
      const { [dimension]: dim, ...rest } = dimensions;
      if (dim === dimensionValue) as.push({ dimensions: rest, value });
    }
    return as;
  };

export const mapComputation =
  <A, B, Context>(
    f: InsightComputation<A, Context>,
    g: (a: A) => B,
  ): InsightComputation<B, Context> =>
  (id, date, insights) =>
    g(f(id, date, insights));

export const mapPromisedComputation = <A, B, Context>(
  f: InsightComputation<Promise<A>, Context>,
  g: (a: A) => B,
): InsightComputation<Promise<B>, Context> =>
  mapComputation(f, (pa) => pa.then(g));

export const mapPromisedArray = <A, B, Context>(
  f: InsightComputation<Promise<A[]>, Context>,
  g: (a: A) => B,
): InsightComputation<Promise<B[]>, Context> =>
  mapComputation(f, (pa) => pa.then((as) => as.map(g)));

// Expand a range forwards and backwards to form a search window for stuff that
// probably got logged before/after it actually happened.
export const widen = (
  { from, to }: DateRange,
  daysBefore = 0,
  daysAfter = 0,
) => {
  const earlier = new PureDate(from);
  if (daysBefore) earlier.setDate(earlier.getDate() - daysBefore);
  const later = new PureDate(to);
  if (daysAfter) later.setDate(later.getDate() + daysAfter);
  return { from: earlier, to: later };
};

export const Infinite_Future = PureDate.of("2099-12-31");
export const Infinite_Past = PureDate.of("1971-01-01");

export const onOrAfter: DateToRange = {
  desc: `on or after`,
  period: "onOrAfter",
  expand: (to: Date) => ({ from: new PureDate(to), to: Infinite_Future }),
};

export const onOrBefore: DateToRange = {
  desc: `on or before`,
  period: "onOrBefore",
  expand: (to: Date) => ({ from: Infinite_Past, to: new PureDate(to) }),
};

export const lastNDays = (days: number): DateToRange => ({
  desc: `last ${days} days`,
  period: `${days}d`,
  expand: (to: Date) => {
    const from = new PureDate(to);
    from.setDate(from.getDate() - (days - 1));
    return { from, to: new PureDate(to) };
  },
});

export const today = lastNDays(1);
export const last7Days = lastNDays(7); // "lastWeek" too ambiguous
export const last30Days = lastNDays(30);

export const nextNDays = (days: number): DateToRange => ({
  desc: `next ${days} days`,
  period: `f${days}d`,
  expand: (from: Date) => {
    const to = new PureDate(from);
    to.setDate(to.getDate() + (days - 1));
    return { from: new PureDate(from), to };
  },
});

export const quarterly: DateToRange = {
  desc: "quarterly",
  period: "q",
  expand: (d: Date) => {
    const from = new PureDate(d);
    const mth = from.getMonth();
    const qtr = mth - (mth % 3);
    from.setDate(1);
    from.setMonth(qtr);
    const to = new PureDate(from);
    to.setMonth(qtr + 3);
    to.setDate(0);
    return { from, to };
  },
};

// 'monthly', except current month uses last 30 days to make graphs look better
export const monthlyOrLast30Days: DateToRange = {
  desc: "monthly or prior 30 days",
  period: "m30",
  expand: (d: Date) => monthOfOrLast30Days(d),
};

// 'monthly', but extra specific that the current month is still 'monthly'
export const monthlyIncludingCurrentMonth: DateToRange = {
  desc: "monthly",
  period: "m",
  expand: (d: Date) => monthOfIncludingCurrentMonth(d),
};

const englishOrdinalRules = new Intl.PluralRules("en", { type: "ordinal" });

// I *think* there should be some kind of proper type stuff based off
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/resolvedOptions
// to register that English ordinal rules don't need entries for zero or many,
// but no.
const englishOrdinalSuffixes = {
  zero: "th", // Shouldn't be needed for en
  one: "st",
  two: "nd",
  few: "rd",
  many: "th", // Shouldn't be needed for en
  other: "th",
};

export const ordinal = (n: number): string => {
  const suffix = englishOrdinalSuffixes[englishOrdinalRules.select(n)];
  return n + suffix;
};

/** Overlap with aggregateByDimension. */
const dimensionedAggregate =
  <RN, N, DT extends string, IFDT extends DT, CDT extends DT>(
    source: InsightImplementation<Dimensioned<N, DT>>,
    aggregator: (a: RN, s: N) => RN,
    initialValue: RN,
    dimensions: CDT | CDT[],
    inclusionFilter?: (v: DimensionedValue<N, IFDT>) => boolean,
  ) =>
  (
    _id: number,
    _date: PureDate,
    insights: InsightValues,
  ): Dimensioned<RN, CDT> => {
    const data = getInsights(insights, source) ?? [];
    const keys =
      dimensions instanceof Array ? [...dimensions].sort() : [dimensions];
    const totals: Record<string, DimensionedValue<RN, CDT>> = {};
    data.forEach((dv) => {
      if (inclusionFilter === undefined || inclusionFilter(dv)) {
        const keyVal = keys.map((k) => dv.dimensions[k]).join("|");
        const cur = totals[keyVal];
        if (cur === undefined) {
          totals[keyVal] = {
            dimensions: pick(dv.dimensions, keys),
            value: aggregator(initialValue, dv.value),
          };
        } else {
          cur.value = aggregator(cur.value, dv.value);
        }
      }
    });
    return Object.keys(totals)
      .sort()
      .map((k) => totals[k]);
  };

/** Overlap with sumDimensionedInsight. */
const dimensionedNumericAggregate = <
  DT extends string,
  IFDT extends DT,
  CDT extends DT,
>(
  source: InsightImplementation<Dimensioned<number, DT>>,
  dimensions: CDT | CDT[],
  inclusionFilter?: (v: DimensionedValue<number, IFDT>) => boolean,
) =>
  dimensionedAggregate(
    source,
    (a: number, s: number) => a + s,
    0,
    dimensions,
    inclusionFilter,
  );

/** Filter a dimensioned insight and aggregate it to a dimensional subset. */
export const implementDimensionedNumericAggregateInsight = <
  DT extends string,
  IFDT extends DT,
  CDT extends DT,
>(
  source: InsightImplementation<Dimensioned<number, DT>>,
  dimensions: CDT | CDT[],
  inclusionFilter?: (v: DimensionedValue<number, IFDT>) => boolean,
  insight?: InsightDefinition<Dimensioned<number, CDT>>,
) =>
  insight === undefined
    ? internalInsight(
        dimensionedNumericAggregate(source, dimensions, inclusionFilter),
        source,
      )
    : implementInsight(
        insight,
        dimensionedNumericAggregate(source, dimensions, inclusionFilter),
        source,
      );

const dimensionedCountOfTotalAggregate = <
  DT extends string,
  IFDT extends DT,
  CDT extends DT,
>(
  source: InsightImplementation<Dimensioned<CountOfTotal, DT>>,
  dimensions: CDT | CDT[],
  inclusionFilter?: (v: DimensionedValue<CountOfTotal, IFDT>) => boolean,
) =>
  dimensionedAggregate(
    source,
    (
      { count: ac, total: at }: CountOfTotal,
      { count: sc, total: st }: CountOfTotal,
    ) => ({
      count: ac + sc,
      total: at + st,
    }),
    { count: 0, total: 0 },
    dimensions,
    inclusionFilter,
  );

/** Filter a dimensioned insight and aggregate it to a dimensional subset. */
export const implementDimensionedCountOfTotalAggregateInsight = <
  DT extends string,
  IFDT extends DT,
  CDT extends DT,
>(
  source: InsightImplementation<Dimensioned<CountOfTotal, DT>>,
  dimensions: CDT | CDT[],
  inclusionFilter?: (v: DimensionedValue<CountOfTotal, IFDT>) => boolean,
  insight?: InsightDefinition<Dimensioned<CountOfTotal, CDT>>,
) =>
  insight === undefined
    ? internalInsight(
        dimensionedCountOfTotalAggregate(source, dimensions, inclusionFilter),
        source,
      )
    : implementInsight(
        insight,
        dimensionedCountOfTotalAggregate(source, dimensions, inclusionFilter),
        source,
      );

const aggregate =
  <RN, N, DT extends string, IFDT extends DT, CDT extends DT>(
    source: InsightImplementation<Dimensioned<N, DT>>,
    aggregator: (a: RN, s: N) => RN,
    initialValue: RN,
    inclusionFilter?: (v: DimensionedValue<N, IFDT>) => boolean,
  ) =>
  (_id: number, _date: PureDate, insights: InsightValues): RN => {
    const data = getInsights(insights, source) ?? [];
    return (
      inclusionFilter === undefined ? data : data.filter(inclusionFilter)
    ).reduce((a: RN, o) => aggregator(a, o.value), initialValue);
  };

const numericAggregate = <DT extends string, IFDT extends DT, CDT extends DT>(
  source: InsightImplementation<Dimensioned<number, DT>>,
  inclusionFilter?: (v: DimensionedValue<number, IFDT>) => boolean,
) => aggregate(source, (a: number, s: number) => s + a, 0, inclusionFilter);

const countOfTotalAggregate = <
  DT extends string,
  IFDT extends DT,
  CDT extends DT,
>(
  source: InsightImplementation<Dimensioned<CountOfTotal, DT>>,
  inclusionFilter?: (v: DimensionedValue<CountOfTotal, IFDT>) => boolean,
) =>
  aggregate(
    source,
    (
      { count: ac, total: at }: CountOfTotal,
      { count: sc, total: st }: CountOfTotal,
    ) => ({
      count: ac + sc,
      total: at + st,
    }),
    { count: 0, total: 0 },
    inclusionFilter,
  );

/** Filter a numeric insight and sum it.
 */
export const implementNumericAggregateInsight = <
  DT extends string,
  IFDT extends DT,
  CDT extends DT,
>(
  source: InsightImplementation<Dimensioned<number, DT>>,
  inclusionFilter?: (v: DimensionedValue<number, IFDT>) => boolean,
  insight?: InsightDefinition<number>,
) =>
  insight === undefined
    ? internalInsight(numericAggregate(source, inclusionFilter), source)
    : implementInsight(
        insight,
        numericAggregate(source, inclusionFilter),
        source,
      );

/** Filter a numeric insight and sum it.
 */
export const implementCountOfTotalAggregateInsight = <
  DT extends string,
  IFDT extends DT,
  CDT extends DT,
>(
  source: InsightImplementation<Dimensioned<CountOfTotal, DT>>,
  inclusionFilter?: (v: DimensionedValue<CountOfTotal, IFDT>) => boolean,
  insight?: InsightDefinition<CountOfTotal>,
) =>
  insight === undefined
    ? internalInsight(countOfTotalAggregate(source, inclusionFilter), source)
    : implementInsight(
        insight,
        countOfTotalAggregate(source, inclusionFilter),
        source,
      );

/***
 * @param insight
 * @param compute
 * @param dependencies
 * @param instant This insight is computed from a snapshot of data and only
 * valid at the current instant
 * @param noCache This insight won't be cached: it's recomputed every time.
 * Intended for insights with unwieldy return values.
 */
export const implementInsight = <A>(
  insight: InsightDefinition<A>,
  compute: InsightComputation<InsightResult<A>>,
  dependencies: InsightImplementation | InsightImplementation[],
  instant: boolean = false,
  noCache: boolean = false,
): InsightImplementation<A> => {
  const deps = arrayify(dependencies);
  return {
    insight,
    compute,
    dependencies: deps,
    instant: instant || deps.some((i) => i.instant),
    noCache,
  };
};

export const implementDuplicateInsight = <A>(
  insight: InsightDefinition<A>,
  dependency: InsightImplementation<A>,
): InsightImplementation<A> => ({
  insight,
  compute: (id, date, insights) => getInsights(insights, dependency),
  dependencies: [dependency],
  instant: dependency.instant,
  noCache: dependency.noCache,
});

// figure out how to generalize this to N dependencies and then
// this can be used for most insights
export const implementDerivedInsight = <A, Context>(
  insight: InsightDefinition<A>,
  compute: InsightComputation<InsightResult<A>, Context>,
  context: InsightImplementation<Context>,
  instant: boolean = false,
  noCache: boolean = false,
): InsightImplementation<A> => ({
  insight,
  compute: (id, date, insights) =>
    compute(id, date, getInsights(insights, context)),
  dependencies: [context],
  instant: instant || context.instant,
  noCache,
});

// TODO: Rework this so internal insight implementations
// just have an undefined insight and either store their
// data internally or in a separate block of the raw insights
let _internalId = 10000;

export const internalInsight = <A>(
  compute: InsightComputation<InsightResult<A>>,
  dependencies: InsightImplementation | InsightImplementation[] = [],
  instant: boolean = false,
  noCache: boolean = false,
): InsightImplementation<A> => {
  const deps = arrayify(dependencies);
  return {
    insight: _internalId++,
    source: callerID(),
    compute,
    dependencies: deps,
    instant: instant || deps.some((d) => d.instant),
    noCache,
  };
};

export const internalDerivedInsight = <A, Context>(
  compute: (id: number, date: PureDate, context: Context) => InsightResult<A>,
  context: InsightImplementation<Context>,
  instant: boolean = false,
  noCache: boolean = false,
): InsightImplementation<A> => ({
  insight: _internalId++,
  source: callerID(),
  compute: (id, date, insights) =>
    compute(id, date, getInsights(insights, context)),
  dependencies: [context],
  instant: instant || context.instant,
  noCache,
});

export const implementCountOfTotalInsight = (
  insight: InsightDefinition<CountOfTotal>,
  countInsight: InsightImplementation<number>,
  totalInsight: InsightImplementation<number>,
) =>
  implementInsight(insight, countOfTotalInsight(countInsight, totalInsight), [
    countInsight,
    totalInsight,
  ]);

export const implementDimensionedCountOfTotalInsight = <A extends string>(
  insight: InsightDefinition<Dimensioned<CountOfTotal, A>>,
  countInsight: InsightImplementation<Dimensioned<number, A>>,
  totalInsight: InsightImplementation<Dimensioned<number, A>>,
) =>
  implementInsight(
    insight,
    dimensionedCountOfTotalInsight(countInsight, totalInsight),
    [countInsight, totalInsight],
  );

// TODO: the rest of these?

export const allWorkOrderAges = [
  "0-2 days",
  "3-7 days",
  "2 weeks",
  "30 days",
  "60 days",
  "90 days",
  "91+ days",
];

// bracket is 0-9, income / 20,000
export const incomeBracket = (bracket: number) =>
  bracket === 0
    ? "$0"
    : bracket === 9
      ? "$160K+"
      : `\$${(bracket - 1) * 20}–${bracket * 20}K`;

export const allIncomeBrackets = sequence(10).map(incomeBracket);

// these are mostly what we have seen from yardi in a reasonable order
const orderedPriorities = [
  "Low",
  "Routine",
  "Medium",
  "Preventative Maintenance",
  "Standard",
  "High",
  "Critical",
  "Emergency",
  "Make Ready",
];

const numericRE = /^[0-9]/;

export const comparePriority = (a: string, b: string): number => {
  const nA = numericRE.test(a),
    nB = numericRE.test(b);
  // Legacy: we used to store (unset), now we store "".
  if (a === "(unset)" || !a) {
    return 1;
  } else if (b === "(unset)" || !b) {
    return -1;
  } else if (nA && !nB) {
    return -1;
  } else if (nB && !nA) {
    return 1;
  } else {
    const iA = orderedPriorities.indexOf(a),
      iB = orderedPriorities.indexOf(b);
    if (iA >= 0 && iB >= 0) {
      return iA - iB;
    } else if (iA >= 0) {
      return -1;
    } else if (iB >= 0) {
      return 1;
    }
  }
  return naturallyCompare(a, b);
};

/** Excludes `"value"` from the dimensions you can pick. */
type NotValue<A> = A extends "value" ? never : A;

/** Transforms `{value: X, dimensions: {dimension: Y}}[]` to
 * `{value: X, dimension: Y}[]`. Does not aggregate duplicate
 * values (i.e. if there are other dimensions).
 */
export const pickDimension = <A extends string, B>(
  dimension: NotValue<A>,
  value: Dimensioned<B, A>,
): (Record<A, string> & { value: B })[] =>
  value.map(
    ({ value, dimensions }) =>
      ({
        [dimension]: dimensions[dimension],
        value: value,
      }) as any, // as any because of impossible "value" overlap
  );

/** When a generically-implemented insight is needed in another source file,
 * can locate it with this (see e.g. yardiRentInsights's InPlaceRent, which
 * needs OccupiedUnitCount, which is implemented by
 * genericUnitInsightImplementations).
 */
export const findInsight = <T>(
  insights: readonly InsightImplementation[],
  target: InsightDefinition<T>,
): InsightImplementation<T> => {
  const result = insights.find(
    (i) => i.insight === target,
  ) as InsightImplementation<T>;
  if (result === undefined)
    throw Error(
      `Surprise: couldn't find insight ${target.id} in implementation list!`,
    );
  return result;
};

export const deriveCountOfTotalInsightDefinition = (
  id: number,
  name: string,
  description: InsightDescription,
  count: InsightDefinition<number>,
  total: InsightDefinition<number>,
  identifier: InsightIdentifier,
  goalType?: GoalType,
): DerivedCountOfTotalInsightDefinition => {
  return {
    id,
    name,
    description: {
      ...description,
      seeAlso: [...(description.seeAlso ?? []), count, total],
    },
    unit: { count: count.unit, total: total.unit },
    aggregate: sumCountOfTotal,
    identifier,
    goalType,
    count,
    total,
  };
};

export const deriveNumeratorInsightDefinition = (
  id: number,
  name: string,
  description: InsightDescription,
  identifier: string,
  numerator: InsightDefinition<CountOfTotal>,
  goalType?: GoalType,
): DerivedNumeratorInsightDefinition => {
  return {
    id,
    name,
    description,
    unit: numerator.unit.count,
    aggregate: sumNumbers,
    identifier: { name: identifier },
    goalType,
    numerator,
  };
};

export const insightIdentifierToString = (i: InsightIdentifier): string =>
  `${i.name}${i.aspect == null ? "" : "#" + i.aspect}${
    i.period == null ? "" : "/" + i.period
  }${i.dimension == null ? "" : "*" + i.dimension}`;

/** A magic number added to a custom insight PK to form a fake known insight Id. */
export const CustomInsightOffset = 100000;

/** A magic number added to an insight id used to store the common
 * model insight computation in the insight data blob.
 */
export const CommonInsightOffset = 200000;
