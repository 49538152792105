import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";

import { EntrataConfiguration } from "../../../../services/integrationsService";
import { getOrganizationsForPropertyConnect } from "../../../../services/organizationService";
import { Platform } from "../../../../services/platformService";
import { Organization } from "../../../../services/usersService";
import PlatformContext from "../../../app/PlatformContext";
import { IntegrationFormProps, ValidationError } from "./common";

const EntrataForm: React.FC<IntegrationFormProps> = (props) => {
  const [entrataConfig, setEntrataConfig] = useState<EntrataConfiguration>({
    domain: "",
    username: "",
    ignorePmsFinancials: false,
  });
  const [propertyConnectOrganizations, setPropertyConnectOrganizations] =
    useState<Organization[]>();
  const { isCreate, validationError, setConfigurationValidation } = props;
  const platform = useContext(PlatformContext).platform as Platform;

  const validate = useCallback(
    (newConfig: EntrataConfiguration, isCreate: boolean): ValidationError => {
      const isPcApp = platform.organization.application === "PC";
      if (!newConfig.domain) {
        return { message: "Entrata hostname is required.", field: "domain" };
      } else if (!newConfig.username) {
        return { message: "Username is required.", field: "username" };
      } else if (isCreate && !newConfig.secret) {
        return { message: "Password is required.", field: "secret" };
      } else if (isPcApp && !newConfig.client) {
        return { message: "Client is required.", field: "client" };
      }
      return {};
    },
    [platform.organization.application],
  );

  useEffect(() => {
    const validation = validate(entrataConfig, isCreate);
    setConfigurationValidation(validation);
  }, [entrataConfig, setConfigurationValidation, isCreate, validate]);

  useEffect(() => {
    if (props.configuration && Object.keys(props.configuration).length) {
      const config = props.configuration as EntrataConfiguration;
      setEntrataConfig(config);
    }
  }, [props.configuration]);

  useEffect(() => {
    const isPcApp = platform.organization.application === "PC";
    if (isPcApp) {
      getOrganizationsForPropertyConnect().then((organizations) => {
        setPropertyConnectOrganizations(organizations);
      });
    }
  }, [platform.organization.application]);

  function onConfigChange(
    e: ChangeEvent<HTMLInputElement>,
    key: keyof EntrataConfiguration,
  ) {
    const newConfig = {
      ...entrataConfig,
      [key]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    };
    setEntrataConfig(newConfig);
    props.setConfiguration(newConfig);
  }

  return (
    <>
      <FormGroup>
        <Label>Entrata hostname</Label>
        <Input
          type="text"
          value={entrataConfig.domain}
          onChange={(e) => onConfigChange(e, "domain")}
          invalid={validationError.field === "domain"}
          autoComplete="off"
        />
        {validationError.field === "domain" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label>Username</Label>
        <Input
          type="text"
          value={entrataConfig.username}
          onChange={(e) => onConfigChange(e, "username")}
          invalid={validationError.field === "username"}
          autoComplete="off"
        />
        {validationError.field === "username" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label>Password</Label>
        <Input
          type="text"
          value={entrataConfig.secret}
          onChange={(e) => onConfigChange(e, "secret")}
          invalid={validationError.field === "secret"}
          autoComplete="off"
          className="secret-blur"
        />
        {validationError.field === "password" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
        <FormText color="muted">
          * Credentials stored securely in AWS Secrets Manager.
        </FormText>
      </FormGroup>

      {platform.superAdmin && (
        <FormGroup inline>
          <Input
            id="ignore-pms-financials"
            type="checkbox"
            name="ignorePmsFinancials"
            onChange={(e) => onConfigChange(e, "ignorePmsFinancials")}
            checked={!!entrataConfig.ignorePmsFinancials}
          />
          <Label for="ignore-pms-financials" check>
            &nbsp;Ignore PMS Financials (use Emailed T-12s Instead)
          </Label>
        </FormGroup>
      )}

      {propertyConnectOrganizations && propertyConnectOrganizations.length ? (
        <FormGroup>
          <Label>Revolution RE Client</Label>
          <Input
            type="select"
            className="custom-select"
            value={entrataConfig.client}
            onChange={(e) => {
              const client = e.target.value
                ? parseInt(e.target.value, 10)
                : undefined;
              setEntrataConfig({ ...entrataConfig, client: client });
            }}
          >
            <option value=""></option>
            {propertyConnectOrganizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </Input>
          {validationError.field === "client" && validationError.message ? (
            <FormFeedback className="jh-block">
              {validationError.message}
            </FormFeedback>
          ) : null}
        </FormGroup>
      ) : null}
    </>
  );
};

export default EntrataForm;
