import { faCompress, faExpand } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isPropertiesById } from "@joyhub-integration/shared";
import axios from "axios";
import { head } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFullscreen, useToggle } from "react-use";
import { Button, Modal, ModalHeader } from "reactstrap";

import { InsightCustomComponentProps } from "../../services/models";
import { getProperty } from "../../services/propertiesService";
import { getFloors } from "../../services/unitMap/unitMap";
import { TagValue } from "../../services/unitMap/unitMapApi";
import { Unit } from "../../services/unitMap/unitMapSdk";
import {
  UnitFilters,
  UnitMetadata,
  UnitsApiResponse,
  getUnitData,
} from "../../services/unitMap/unitMetadata";
import { apiUrl, axiosConfig } from "../../utils/api";
import LevelPicker from "./LevelPicker";
import UnitFilter from "./UnitFilter";
import UnitMap from "./UnitMap";
import UnitPopover from "./UnitPopover";

const DEMO_MAP = 10120;

// 10120 - nice looking generic building
// 10491, 4290 - elevation examples

// Locally this expects a file: assets/data/unitData.json
// In production, it expects: asset/<orgid>/data/unitData.json

const UnitMapCard = ({ selection }: InsightCustomComponentProps) => {
  const [mapId, setMapId] = useState<number>();
  const [unitData, setUnitData] = useState<UnitMetadata[]>([]);
  const [floors, setFloors] = useState<TagValue[]>();
  const [selectedUnitData, setSelectedUnitData] = useState<UnitMetadata>();

  const [highlightedUnits, setHighlightedUnits] = useState<string[]>();
  const [selectedFloorId, setSelectedFloorId] = useState<number>();
  const [selectedUnit, setSelectedUnit] = useState<Unit>();
  const [filters, setFilters] = useState<UnitFilters>();

  const container = useRef<HTMLDivElement>(null);
  const [show, toggleFullscreen] = useToggle(false);
  const isFullscreen = useFullscreen(container, show, {
    onClose: () => toggleFullscreen(false),
  });

  const onUnitClick = useCallback(
    ({
      unit,
      originalEvent: _originalEvent,
    }: {
      unit: Unit;
      originalEvent: MouseEvent;
    }) => {
      setSelectedUnit(unit);
    },
    [],
  );

  const solePropertyId = isPropertiesById(selection) ? selection : undefined;

  useEffect(() => {
    if (solePropertyId != null) {
      getProperty(solePropertyId).then((propertyData) => {
        const mapIdTag = propertyData.metadata.MapId;
        if (mapIdTag) {
          setMapId(Number(mapIdTag));
        } else {
          setMapId(DEMO_MAP);
        }
      });
    } else {
      setMapId(DEMO_MAP);
    }
  }, [solePropertyId]);

  useEffect(() => {
    if (mapId) {
      getFloors(mapId).then(setFloors);
    }
  }, [mapId]);

  useEffect(() => {
    if (unitData.length === 0 && mapId) {
      axios
        .get<UnitsApiResponse>(
          apiUrl(`/organizations/data/unitData-${mapId}.json`),
          axiosConfig,
        )
        .then((res) => {
          setUnitData(res.data.units);
          setFilters(res.data.filters);
        });
    }
  }, [unitData, setUnitData, mapId]);

  useEffect(() => {
    if (selectedUnit) {
      getUnitData(selectedUnit.id, unitData).then(setSelectedUnitData);
    }
  }, [selectedUnit, unitData]);

  useEffect(() => {
    const firstFloor = head(floors)?.value;
    if (firstFloor) {
      setSelectedFloorId(Number.parseInt(firstFloor));
    }
  }, [floors]);

  return (
    <div className="w-100 h-100" style={{ background: "#fff" }} ref={container}>
      {mapId ? (
        <div className="flex-row w-100 h-100">
          <LevelPicker
            floors={floors}
            value={floors?.[0]?.value}
            onChange={setSelectedFloorId}
          />
          <div
            className="w-100 h-100"
            style={{
              position: "relative",
              flex: 1,
              maxWidth: filters ? "calc(100% - 25em)" : "100%",
            }}
          >
            <UnitMap
              className="w-100 h-100 px-4"
              mapId={mapId}
              floorId={selectedFloorId}
              highlightedUnits={highlightedUnits}
              onUnitClick={onUnitClick}
            />
            <Button
              style={{ position: "absolute", top: "1em", right: "1em" }}
              color="link"
              onClick={() => toggleFullscreen()}
            >
              <FontAwesomeIcon
                icon={isFullscreen ? faCompress : faExpand}
                aria-label="Toggle fullscreen"
              />
            </Button>
          </div>
          <UnitFilter
            mapId={mapId}
            onFilter={setHighlightedUnits}
            unitData={unitData}
            filters={filters}
          />
          <Modal
            isOpen={selectedUnit !== undefined}
            container={isFullscreen ? container : undefined}
          >
            <ModalHeader toggle={() => setSelectedUnit(undefined)}>
              Unit {selectedUnitData?.name}
            </ModalHeader>
            {selectedUnit && <UnitPopover unitDatum={selectedUnitData} />}
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default UnitMapCard;
