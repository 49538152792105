import React, { useContext } from "react";

import { IntegrationVendor } from "../../../../services/integrationsService";
import PlatformContext from "../../../app/PlatformContext";
import {
  Entrata,
  Fortress,
  JoyHub,
  RealPage,
  ResMan,
  Yardi,
} from "../vendorImages";

interface SelectVendorProps {
  setSelectedVendor: (vendor: IntegrationVendor) => void;
}

const SelectVendor: React.FC<SelectVendorProps> = (props) => {
  const { superAdmin } = useContext(PlatformContext).platform!;
  return (
    <>
      <h6 className="select-vendor-header">Select a Data Source</h6>
      <div className="flex-row select-vendor-deck">
        <div
          className="select-vendor-card"
          onClick={() => props.setSelectedVendor("Entrata")}
        >
          <img src={Entrata} alt="Entrata Logo" />
        </div>
        <div
          className="select-vendor-card"
          onClick={() => props.setSelectedVendor("Fortress")}
        >
          <img src={Fortress} alt="Fortress Logo" />
        </div>
        <div
          className="select-vendor-card"
          onClick={() => props.setSelectedVendor("RealPage")}
        >
          <img src={RealPage} alt="RealPage Logo" />
        </div>
        <div
          className="select-vendor-card"
          onClick={() => props.setSelectedVendor("ResMan")}
        >
          <img src={ResMan} alt="ResMan Logo" />
        </div>
        <div
          className="select-vendor-card"
          onClick={() => props.setSelectedVendor("Yardi")}
        >
          <img src={Yardi} alt="Yardi Logo" />
        </div>
        {superAdmin && (
          <div
            className="select-vendor-card"
            onClick={() => props.setSelectedVendor("JoyHub")}
          >
            <img src={JoyHub} alt="Revolution RE Logo" />
          </div>
        )}
      </div>
    </>
  );
};

export default SelectVendor;
