export enum DashboardKindEnum {
  Dashboard = "Dashboard",
  EmailReport = "EmailReport",
  GenericReport = "GenericReport",
  JoyhubStats = "JoyhubStats",
  PropertyDashboard = "PropertyDashboard",
  Scorecard = "Scorecard",
  FinanceReport = "FinanceReport",
  GoalYearEmail = "GoalYearEmail",
}

export type DashboardKind = keyof typeof DashboardKindEnum;

export type ExportedDashboard = {
  identifier: string;
  name: string;
  description: string;
  category: string[];
  kind: DashboardKind;
  definition: Object;
  shared: boolean;
  timestamp: Date;
};

export const isThisEnum =
  <T extends {}>(e: T) =>
  (x: any): x is T[keyof T] =>
    Object.values(e).includes(x as T[keyof T]);

export const isDashboardKind = isThisEnum(DashboardKindEnum);
