import React, { useContext } from "react";

import { Platform } from "../../services/platformService";
import { Property } from "../../services/propertiesService";

export interface PlatformContextType {
  platform?: Platform;
  setPlatform: (platform: Platform) => void;
  setProperties: (properties: Property[]) => void;
  updatePlatform: (platform: Partial<Platform>) => void;
}

const PlatformContext = React.createContext<PlatformContextType>({
  setPlatform: () => {},
  setProperties: () => {},
  updatePlatform: () => {},
});

export const usePlatformContext = () => useContext(PlatformContext);

export default PlatformContext;
