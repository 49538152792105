import { PropertiesSelection } from "@joyhub-integration/shared";

import { parseYMD } from "../utils/date";
import { CountOfTotal } from "../utils/number";
import { getInstantData } from "./dataService";
import {
  AllUnitCount,
  Occupancy,
  PropertyCount,
  TenantCount,
} from "./insightLibrary/backendInsightIds";

export interface PortfolioOverview {
  properties?: number;
  units?: number;
  residents?: number;
  occupancy?: CountOfTotal;
  asOf?: Date;
}

const Insights = [PropertyCount, AllUnitCount, Occupancy, TenantCount];

export async function getPortfolioOverview(
  selection: PropertiesSelection,
): Promise<PortfolioOverview> {
  return getInstantData(Insights, selection).then((insights) => {
    const properties = insights.overall[PropertyCount] as number;
    const units = insights.overall[AllUnitCount] as number;
    const residents = insights.overall[TenantCount] as number;
    const occupancy = insights.overall[Occupancy] as CountOfTotal;
    const asOf =
      insights.date === undefined ? undefined : parseYMD(insights.date);
    return { properties, units, residents, occupancy, asOf };
  });
}
