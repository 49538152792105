import { faTableColumns, faTableRows } from "@fortawesome/pro-solid-svg-icons";
import {
  PropertyColumnDefaultTitle,
  ReportGrouping,
  ReportSheet,
  RowsByDate,
  RowsByDimension,
  RowsByFinance,
  RowsByLease,
  RowsByProperty,
  SheetRows,
  exhaustiveCheck,
  isRowsByDate,
  isRowsByDimension,
  isRowsByFinance,
  isRowsByInsightData,
  isRowsByLease,
  isRowsByMortgage,
  isRowsByProperty,
  isRowsByUnit,
} from "@joyhub-integration/shared";
import { parseInt } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Select, { StylesConfig } from "react-select";
import Creatable from "react-select/creatable";
import { Col, FormGroup, Input, Label } from "reactstrap";

import { getMockPropertyFilter } from "../../../services/propertiesService";
import { Organization } from "../../../services/usersService";
import { sequence } from "../../../utils/misc";
import PlatformContext from "../../app/PlatformContext";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import ButtonWithIcon from "../../common/button/ButtonWithIcon";
import { IDropListItem, jhItem } from "../../common/JhSelect/JhSelect";
import { getAllGlTrees } from "../../finance/financeService";
import {
  baseDimensionOptions,
  baseLeasePeriodOptions,
  baseLeaseTypeOptions,
  findOption,
} from "../editUtil";

// To match bootstrap input height.. TODO: unify with JhSelect somehow
export const reactSelectBootstrapStyle: StylesConfig<any, any> = {
  control: (css) => ({ ...css, height: "calc(1.5em + 0.75rem + 2px)" }),
  menu: (css) => ({ ...css, zIndex: 9999 }),
};

const groupSortOptions = [
  { value: "Alphabetic", label: "Alphabetically" },
  { value: "Numeric", label: "Numerically" },
];

const groupLayoutOptions = [
  { value: "Inline", label: "Inline" },
  { value: "Footer", label: "Footer" },
  { value: "Only", label: "Only" },
];

const PivotSetting: React.FC<{
  pivot: boolean | undefined;
  setPivot: (pivot: boolean) => void;
}> = ({ pivot, setPivot }) => (
  <FormGroup row>
    <Label for="report-headerOrientation" sm={2}>
      Headers
    </Label>
    <Col sm={10}>
      <ButtonWithIcon
        icon={faTableColumns}
        tooltip={`Each header is a new column`}
        outline={pivot} // outline means button is off
        onClick={() => setPivot(false)}
      >
        {" "}Columns
      </ButtonWithIcon>
      <ButtonWithIcon
        icon={faTableRows}
        tooltip={`Each header is a new row`}
        outline={!pivot}
        onClick={() => setPivot(true)}
        className="ms-2"
      >
        {" "}Rows
      </ButtonWithIcon>
    </Col>
  </FormGroup>
);

const getCategoryOptions = (organization: Organization): IDropListItem[] => {
  const customColumns = organization.configuration.customColumns ?? [];
  let options: IDropListItem[] = [];
  for (const [k] of Object.entries(getMockPropertyFilter())) {
    const name =
      customColumns.find((col) => col.columnKey === k)?.name ??
      PropertyColumnDefaultTitle[k as keyof typeof PropertyColumnDefaultTitle];
    if (name) options.push(jhItem(k, name));
  }
  return options.sort((a, b) => a.label.localeCompare(b.label));
};

const RowsByPropertySettings: React.FC<{
  rows: RowsByProperty;
  updateRows: (rows: Partial<RowsByProperty>) => void;
}> = ({ rows, updateRows }) => {
  const { organization } = useContext(PlatformContext).platform!;
  const setGrouping = (grouping?: ReportGrouping) =>
    updateRows({
      grouping,
    });
  const updateGrouping = (
    grouping: ReportGrouping,
    update: Partial<ReportGrouping>,
  ) =>
    setGrouping({
      ...grouping,
      ...update,
    });
  const categoryOptions = useMemo(() => {
    return getCategoryOptions(organization);
  }, [organization]);
  const initialGrouping: ReportGrouping = {
    category: categoryOptions[0]?.value ?? "",
    sort: "Alphabetic",
    layout: "Inline",
  };
  return (
    <FormGroup row>
      <Label for="report-summarize" sm={2}>
        Group By
      </Label>
      <Col sm={1} className="d-flex align-items-center">
        <Input
          id="report-summarize"
          type="checkbox"
          checked={rows.grouping != null}
          onChange={(e) =>
            setGrouping(e.target.checked ? initialGrouping : undefined)
          }
        />
      </Col>
      <Col sm={3}>
        <Select
          id="report-category"
          options={categoryOptions}
          value={findOption(categoryOptions, rows.grouping?.category)}
          onChange={(option) =>
            updateGrouping(rows.grouping!, { category: option!.value })
          }
          isSearchable={false}
          isDisabled={!rows.grouping}
          styles={reactSelectBootstrapStyle}
        />
      </Col>
      <Col sm={3}>
        <Select
          id="report-category-sort"
          options={groupSortOptions}
          value={findOption(groupSortOptions, rows.grouping?.sort)}
          onChange={(option) =>
            updateGrouping(rows.grouping!, { sort: option!.value as any })
          }
          isSearchable={false}
          isDisabled={!rows.grouping}
          styles={reactSelectBootstrapStyle}
        />
      </Col>
      <Col sm={3}>
        <Select
          id="report-category-layout"
          options={groupLayoutOptions}
          value={findOption(groupLayoutOptions, rows.grouping?.layout)}
          onChange={(option) =>
            updateGrouping(rows.grouping!, { layout: option!.value as any })
          }
          isSearchable={false}
          isDisabled={!rows.grouping}
          styles={reactSelectBootstrapStyle}
        />
      </Col>
    </FormGroup>
  );
};

const dateIntervalOptions = [
  { value: "Quarter", label: "Quarters" },
  { value: "Month", label: "Months" },
  { value: "Week", label: "Weeks" },
  { value: "Day", label: "Days" },
];

const simpleOption = (a: string | number) => ({ value: `${a}`, label: `${a}` });

const parseCount = (a: string): "YTD" | number =>
  a === "YTD" ? a : parseInt(a, 10);

const RowsByDateSettings: React.FC<{
  rows: RowsByDate;
  updateRows: (rows: Partial<RowsByDate>) => void;
}> = ({ rows, updateRows }) => {
  const [maxSequence, setMaxSequence] = useState(
    rows.interval === "Quarter" ? 8 : 12,
  );
  const dateOptions = useMemo(() => {
    return sequence(1, maxSequence)
      .map(simpleOption)
      .concat(simpleOption("YTD"));
  }, [maxSequence]);
  return (
    <FormGroup row>
      <Label for="report-date-count" sm={2}>
        Timespan
      </Label>
      <Col sm={5}>
        <Creatable
          id="report-date-count"
          options={dateOptions}
          value={
            dateOptions.find((a) => a.value === `${rows.count}`) ??
            simpleOption(rows.count)
          }
          onChange={(option) =>
            updateRows({ count: parseCount(option!.value) })
          }
          isSearchable={true}
          isValidNewOption={(a) => !!a.match(/^[0-9]+$/)}
          formatCreateLabel={(a) => a}
          styles={reactSelectBootstrapStyle}
        />
      </Col>
      <Col sm={5}>
        <Select
          id="report-date-interval"
          options={dateIntervalOptions}
          value={findOption(dateIntervalOptions, rows.interval)}
          onChange={(option) => {
            setMaxSequence(option!.value === "Quarter" ? 8 : 12);
            updateRows({ interval: option!.value as any });
          }}
          isSearchable={false}
          styles={reactSelectBootstrapStyle}
        />
      </Col>
    </FormGroup>
  );
};

const RowsByDimensionSettings: React.FC<{
  rows: RowsByDimension;
  updateRows: (rows: Partial<RowsByDimension>) => void;
}> = ({ rows, updateRows }) => {
  return (
    <FormGroup row>
      <Label for="report-dimension" sm={2}>
        Dimension
      </Label>
      <Col sm={10}>
        <Select
          id="report-dimension"
          options={baseDimensionOptions}
          value={findOption(baseDimensionOptions, rows.dimension)}
          onChange={(option) => updateRows({ dimension: option!.value as any })}
          isSearchable={false}
          styles={reactSelectBootstrapStyle}
        />
      </Col>
    </FormGroup>
  );
};

const financeIntervalOptions = [
  { value: "Month", label: "Months" },
  { value: "Quarter", label: "Quarters" },
  { value: "Year", label: "Years" },
];

const RowsByFinanceSettings: React.FC<{
  rows: RowsByFinance;
  updateRows: (rows: Partial<RowsByFinance>) => void;
}> = ({ rows, updateRows }) => {
  const [treeOptions, setTreeOptions] = useState<IDropListItem[]>([]);
  const onUnexpectedError = useOnUnexpectedError();
  const [maxSequence, setMaxSequence] = useState(
    rows.interval === "Quarter" ? 8 : 12,
  );
  const financeOptions = useMemo(() => {
    return sequence(1, maxSequence)
      .map(simpleOption)
      .concat(simpleOption("YTD"));
  }, [maxSequence]);
  useEffect(() => {
    getAllGlTrees()
      .then((trees) =>
        setTreeOptions(
          trees.map((tree) => jhItem(tree.tree_code!, tree.tree_name!)),
        ),
      )
      .catch(onUnexpectedError);
  }, [onUnexpectedError]);

  return (
    <>
      <FormGroup row>
        <Label for="report-date-count" sm={2}>
          Timespan
        </Label>
        <Col sm={5}>
          <Creatable
            id="report-date-count"
            options={financeOptions.filter(
              (i) =>
                i.value !== "YTD" ||
                rows.interval === "Month" ||
                rows.interval === "Quarter",
            )}
            value={
              financeOptions.find((a) => a.value === `${rows.count}`) ??
              simpleOption(rows.count)
            }
            onChange={(option) =>
              updateRows({ count: parseCount(option!.value) })
            }
            isSearchable={true}
            isValidNewOption={(a) => !!a.match(/^[0-9]+$/)}
            formatCreateLabel={(a) => a}
            styles={reactSelectBootstrapStyle}
          />
        </Col>
        <Col sm={5}>
          <Select
            id="report-date-interval"
            options={financeIntervalOptions}
            value={findOption(financeIntervalOptions, rows.interval)}
            onChange={(option) => {
              setMaxSequence(option!.value === "Quarter" ? 8 : 12);
              updateRows({ interval: option!.value as any });
            }}
            isSearchable={false}
            styles={reactSelectBootstrapStyle}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="report-account-tree" sm={2}>
          Account Tree
        </Label>
        <Col sm={10}>
          <Select
            id="report-account-tree"
            options={treeOptions}
            value={findOption(treeOptions, rows.tree)}
            onChange={(option) => updateRows({ tree: option?.value })}
            isSearchable={true}
            isClearable={true}
            styles={reactSelectBootstrapStyle}
          />
        </Col>
      </FormGroup>
    </>
  );
};

const RowsByLeaseSettings: React.FC<{
  rows: RowsByLease;
  updateRows: (rows: Partial<RowsByLease>) => void;
}> = ({ rows, updateRows }) => {
  const leasePeriodOptions = useMemo(
    () =>
      baseLeasePeriodOptions.filter(
        (option) => (rows.leases === "InPlace") === (option.value === "Today"),
      ),
    [rows.leases],
  );
  return (
    <>
      <FormGroup row>
        <Label for="report-lease-type" sm={2}>
          Leases
        </Label>
        <Col sm={5}>
          <Select
            id="report-lease-type"
            options={baseLeaseTypeOptions}
            value={findOption(baseLeaseTypeOptions, rows.leases)}
            onChange={(option) =>
              updateRows({
                leases: option!.value as any,
                period: option!.value === "InPlace" ? "Today" : "Month",
              })
            }
            isSearchable={false}
            styles={reactSelectBootstrapStyle}
          />
        </Col>
        <Col sm={5}>
          <Select
            id="report-lease-period"
            options={leasePeriodOptions}
            value={findOption(leasePeriodOptions, rows.period)}
            onChange={(option) => updateRows({ period: option!.value as any })}
            isSearchable={false}
            styles={reactSelectBootstrapStyle}
          />
        </Col>
      </FormGroup>
    </>
  );
};

// Technically excel accepts other symbols but be stingy
const limitSheetName = (value: string) =>
  value.replaceAll(/[^- _a-zA-Z]/g, "").substring(0, 31);

const GenericReportSheetSettings: React.FC<{
  sheet: ReportSheet;
  setSheet: (s: ReportSheet) => void;
}> = ({ sheet, setSheet }) => {
  const { rows, pivot } = sheet;
  const setSheetName = (name: string) =>
    setSheet({
      ...sheet,
      name,
    });
  const setRows = (rows: SheetRows) =>
    setSheet({
      ...sheet,
      rows,
    });
  const setPivot = (pivot: boolean) =>
    setSheet({
      ...sheet,
      pivot,
    });
  return (
    <>
      <FormGroup row>
        <Label for="report-sheetName" sm={2}>
          Sheet Name
        </Label>
        <Col sm={10}>
          <Input
            type="text"
            id="report-sheetName"
            required
            value={sheet.name}
            onChange={(e) => setSheetName(limitSheetName(e.target.value))}
          />
        </Col>
      </FormGroup>
      {isRowsByProperty(rows) ? (
        <RowsByPropertySettings
          rows={rows}
          updateRows={(partial) => setRows({ ...rows, ...partial })}
        />
      ) : isRowsByDate(rows) ? (
        <RowsByDateSettings
          rows={rows}
          updateRows={(partial) => setRows({ ...rows, ...partial })}
        />
      ) : isRowsByDimension(rows) ? (
        <RowsByDimensionSettings
          rows={rows}
          updateRows={(partial) => setRows({ ...rows, ...partial })}
        />
      ) : isRowsByLease(rows) ? (
        <RowsByLeaseSettings
          rows={rows}
          updateRows={(partial) => setRows({ ...rows, ...partial })}
        />
      ) : isRowsByUnit(rows) ? null : isRowsByFinance(rows) ? (
        <RowsByFinanceSettings
          rows={rows}
          updateRows={(partial) => setRows({ ...rows, ...partial })}
        />
      ) : isRowsByMortgage(rows) || isRowsByInsightData(rows) ? null : (
        exhaustiveCheck(rows)
      )}
      {!isRowsByFinance(rows) &&
      !isRowsByMortgage(rows) &&
      !isRowsByInsightData(rows) ? (
        <PivotSetting pivot={pivot} setPivot={setPivot} />
      ) : null}
    </>
  );
};

export default GenericReportSheetSettings;
