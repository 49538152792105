import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import CalculateInsightWorker from "../../../../../services/calculateInsightWorker";
import {
  calculateInstantPieValues,
  PieValue,
} from "../../../../../services/calculateInsightWorker/calculateInsightWorker";
import {
  InstantInsights,
  RangedInsights,
} from "../../../../../services/dataService";
import definitions from "../../../../../services/insightLibrary/insightDefinitions";
import insightsLibraryService from "../../../../../services/insightLibrary/insightsLibraryService";
import { KnownInsight as BackendInsight } from "../../../../../services/insightsService";
import {
  ChartValues,
  ComplexInsightIds,
  ScatterChartValues,
} from "../../../../../services/models";
import { Property } from "../../../../../services/propertiesService";
import { DelimitedStringArrayParamDeepEqual } from "../../../../../utils/useQueryParams";
import JhBarChart from "../../../../common/charts/BarChart";
import JhColumnChart from "../../../../common/charts/ColumnChart";
import JhLineChart from "../../../../common/charts/LineChart";
import JhPieChart from "../../../../common/charts/PieChart";
import JhScatterChart from "../../../../common/charts/ScatterChart";
import DimensionFilter from "../../../DimensionFilter";
import MonthFilter from "../../../MonthFilter";
import ThirtySixtyNinetyDayFilter from "../../../ThirtySixtyNinetyDayFilter";

interface ChartProps {
  insightId: number;
  dashboardInsightId: number;
  rangeData: RangedInsights;
  rangeDataAYearAgo: RangedInsights;
  insightsMap: { [id: string]: BackendInsight };
  propertiesHash: { [key: string]: Property };
  onDimensionFilterChange?: (dimension: string) => void;
  onMonthsSelectedChange?: (monthsSelected: Set<string>) => void;
}

export const SimpleJhBarChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onDimensionFilterChange,
  onMonthsSelectedChange: onMonthSelectedChange,
}) => {
  const [chartValues, setChartValues] = useState<ChartValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onDimensionFilterChange) {
      onDimensionFilterChange(dimensionFilterSelected as string);
    }
  }, [dimensionFilterSelected, onDimensionFilterChange]);

  useEffect(() => {
    if (onMonthSelectedChange) {
      onMonthSelectedChange(new Set(monthsSelected));
    }
  }, [monthsSelected, onMonthSelectedChange]);

  useEffect(() => {
    setLoading(true);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "BAR",
        filterByDimension,
        new Set(monthsSelected),
      )
      .then((res) => {
        const chartValues = res as ChartValues;
        setChartValues(chartValues);
        setLoading(false);
      });
  }, [
    insightId,
    dimensionFilterSelected,
    filterDimension,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;
  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={chartValues.dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div style={{ minHeight: 300 }} className={chartContentClassName}>
          <JhBarChart
            id={dashboardInsightId}
            data={chartValues.data}
            yAxisKeys={chartValues.secondaryAxisKeys}
            categoryKey={chartValues.primaryAxisKey}
            dataKey="Rent"
            tooltipSortDir={
              insight?.generalChartOptions?.sortValuesInTooltip?.sortDirection
            }
            stackId={insight?.barChartOptions?.stacked ? "a" : undefined}
          />
        </div>
      )}
    </>
  );
};

export const SimpleScatterChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onMonthsSelectedChange: onMonthSelectedChange,
}) => {
  const [chartValues, setChartValues] = useState<ScatterChartValues>();
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onMonthSelectedChange) {
      onMonthSelectedChange(new Set(monthsSelected));
    }
  }, [monthsSelected, onMonthSelectedChange]);

  useEffect(() => {
    setLoading(true);
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeScatterValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "SCATTER",
        undefined,
        new Set(monthsSelected),
      )
      .then((res) => {
        setChartValues(res);
        setLoading(false);
      });
  }, [
    insightId,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;

  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div style={{ minHeight: 300 }} className={chartContentClassName}>
          <JhScatterChart
            data={chartValues.data}
            xAxisKey={chartValues.xAxisKey}
            xAxisKeyUnit={chartValues.xAxisKeyUnit}
            yAxisKey={chartValues.yAxisKey}
            yAxisKeyUnit={chartValues.yAxisKeyUnit}
            zAxisKey={chartValues.zAxisKey}
          />
        </div>
      )}
    </>
  );
};

export const SimpleJhColumnChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onDimensionFilterChange,
}) => {
  const [chartValues, setChartValues] = useState<ChartValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onDimensionFilterChange) {
      onDimensionFilterChange(dimensionFilterSelected as string);
    }
  }, [dimensionFilterSelected, onDimensionFilterChange]);

  useEffect(() => {
    setLoading(true);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "COLUMN",
        filterByDimension,
        new Set(monthsSelected),
      )
      .then((res) => {
        const chartValues = res as ChartValues;
        setChartValues(chartValues);
        setLoading(false);
      });
  }, [
    insightId,
    filterDimension,
    dimensionFilterSelected,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;
  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={chartValues.dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div className={chartContentClassName} style={{ minHeight: 300 }}>
          <JhColumnChart
            data={chartValues.data}
            yAxisKeys={chartValues.secondaryAxisKeys}
            xAxisKey={chartValues.primaryAxisKey}
            yAxisUnit=""
            stackId={insight?.columnChartOptions?.stacked ? "a" : undefined}
            tooltipSortDir={
              insight?.generalChartOptions?.sortValuesInTooltip?.sortDirection
            }
          />
        </div>
      )}
    </>
  );
};

export const SimpleLineChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onDimensionFilterChange,
}) => {
  const [chartValues, setChartValues] = useState<ChartValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onDimensionFilterChange) {
      onDimensionFilterChange(dimensionFilterSelected as string);
    }
  }, [dimensionFilterSelected, onDimensionFilterChange]);

  useEffect(() => {
    setLoading(true);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "LINE",
        filterByDimension,
        new Set(monthsSelected),
      )
      .then((res) => {
        const chartValues = res as ChartValues;
        setChartValues(chartValues);
        setLoading(false);
      });
  }, [
    insightId,
    dimensionFilterSelected,
    filterDimension,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;
  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={chartValues.dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div style={{ minHeight: 300 }} className={chartContentClassName}>
          <JhLineChart
            data={chartValues.data}
            yAxisKeys={chartValues.secondaryAxisKeys}
            xAxisKey={chartValues.primaryAxisKey}
            yAxisUnit=""
            stackId={insight?.lineChartOptions?.stacked ? "a" : undefined}
            tooltipSortDir={
              insight?.generalChartOptions?.sortValuesInTooltip?.sortDirection
            }
            type="monotone"
          />
        </div>
      )}
    </>
  );
};

export const InstantPieChart = ({
  insightId,
  instantValuesNow,
  instantValuesAYearAgo,
  insightsMap,
}: {
  insightId: number;
  dashboardInsightId: number;
  instantValuesNow: InstantInsights;
  instantValuesAYearAgo: InstantInsights;
  insightsMap: { [id: string]: BackendInsight };
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<PieValue[]>();

  useEffect(() => {
    setLoading(true);
    const definition = definitions.find((d) => d.id === insightId)!;
    const complexInsightIdsSet = definition.insightIds as ComplexInsightIds[];
    const promises = complexInsightIdsSet.map((complexInsightIds) => {
      return calculateInstantPieValues(
        complexInsightIds,
        instantValuesNow.overall,
        instantValuesAYearAgo.overall,
        insightsMap,
      );
    });
    Promise.all(promises).then((values) => {
      setValues(values);
      setLoading(false);
    });
  }, [insightId, instantValuesNow, instantValuesAYearAgo, insightsMap]);

  if (!values || loading) return null;

  return (
    <>
      {!values ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : (
        <div className="jh-chart-content-without-dimension-filter jh-chart-content-no-scrollbars">
          <JhPieChart
            data={values}
            yAxisKeys={{
              value: { colorHex: "", unit: "Number", orientation: "left" },
            }}
            xAxisKey="label"
          />
        </div>
      )}
    </>
  );
};

export const SimplePieChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onDimensionFilterChange,
}) => {
  const [chartValues, setChartValues] = useState<ChartValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onDimensionFilterChange) {
      onDimensionFilterChange(dimensionFilterSelected as string);
    }
  }, [dimensionFilterSelected, onDimensionFilterChange]);

  useEffect(() => {
    setLoading(true);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "PIE",
        filterByDimension,
        new Set(monthsSelected),
      )
      .then((res) => {
        const chartValues = res as ChartValues;
        setChartValues(chartValues);
        setLoading(false);
      });
  }, [
    insightId,
    dimensionFilterSelected,
    filterDimension,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;
  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth ||
    insight?.generalChartOptions?.filterByThirtySixtyNinetyDays
      ? "jh-chart-content-with-dimension-filter jh-chart-content-no-scrollbars"
      : "jh-chart-content-without-dimension-filter jh-chart-content-no-scrollbars";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={chartValues.dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div style={{ minHeight: 300 }} className={chartContentClassName}>
          <JhPieChart
            data={chartValues.data}
            yAxisKeys={chartValues.secondaryAxisKeys}
            xAxisKey={chartValues.primaryAxisKey}
          />
        </div>
      )}
    </>
  );
};

export const Skeleton: React.FC = () => (
  <div className="dashboard-number-insight skeleton" />
);
