export const PropertyCount = 0;
export const AllUnitCount = 1;
export const OnlineUnitCount = 2;
export const OfflineUnitCount = 117;
export const OccupiedUnitCount = 3;
export const Occupancy = 4;
export const TenantCount = 5;
export const AvailableUnitCount = 6;
export const AllUnitCountByBedrooms = 7;
export const OnlineUnitCountByBedrooms = 8;
export const OccupiedUnitCountByBedrooms = 9;
export const AvailableUnitCountByBedrooms = 10;
export const OccupancyByBedrooms = 11;
export const VacantUnrentedUnitCount = 12;
export const AllUnitCountByUnitType = 13;
export const LeasingVelocityByBedrooms = 14;
export const LeasingVelocity = 15;
export const TotalRentByAdSource = 16;
export const LeadsByAdSource = 17;
export const LeadCount = 18;
export const SiteVisitsAdSource = 19;
export const SiteVisitCount = 20;
export const ApplicationsByAdSource = 21;
export const Applications = 22;
export const MoveInsByAdSource = 23;
export const MoveIns = 24;
export const LeadToApplicationConversion = 25;
export const LeadToMoveInConversion = 26;
export const VacantUnitCount = 27;
export const VacantReadyUnitCount = 28;
export const VacantReadiness = 29;
export const Leads30Day = 30;
export const SiteVisits30Day = 31;
export const Applications30Day = 32;
export const MoveIns30Day = 33;
export const TotalMoveInRentByAdSource = 34;
export const TotalRent = 35;
export const OccupiedUnitCountByBedroomsAndAdSource = 36;
export const TenantCountByIncomeBracket = 37;
export const Leased = 154;
export const LeasedByBedrooms = 155;
export const LeasedUnitCountByBedrooms = 38;
export const LeasedUnitCount = 39;
export const ExpiringLeaseRenewalCountByBedrooms = 40;
export const ExpiringLeaseRenewalCount = 41;
export const MoveOuts30Day = 42;
export const AverageMonthlyIncome = 43;
export const InPlaceRent = 44;
export const AverageRentedSqft = 45;
export const InPlaceRentPerSqft = 46;
export const MoveInRentPerSqft = 47;
export const RenewalRentPerSqft = 48;
export const MoveInTradeOutPercent = 49;
export const RenewalTradeOutPercent = 50;
export const MoveOuts = 51;
export const LeaseExpiryCountByBedrooms = 52;
export const LeaseExpiryCount = 53;
export const ExpiringLeaseMoveOutCountByBedrooms = 54;
export const ExpiringLeaseMoveOutCount = 55;
export const ExpiringLeaseNoticeCountByBedrooms = 56;
export const ExpiringLeaseNoticeCount = 57;
export const ExpiringLeasePendingCountByBedrooms = 58;
export const ExpiringLeasePendingCount = 59;
export const AverageNotReadyDuration = 60;
export const AverageDaysOnMarket = 61;
export const AverageDaysVacant = 62;
export const AverageDaysFromMoveOutToReady = 63;
export const MaximumDaysVacant = 64;
export const AverageMoveInRentByAdSource = 65;
export const NoticeUnrentedUnitCountByLeaseEnd = 67;
export const NoticeRentedUnitCountByLeaseEnd = 68;
export const VacantNotReadyUnitCount = 70;
export const ApplicationsApprovedByAdSource = 71;
export const ApplicationsApproved = 72;
export const ApplicationsDeniedByAdSource = 73;
export const ApplicationsDenied = 74;
export const ApplicationsScreenedByAdSource = 75;
export const ApplicationsScreened = 76;
export const ApplicationsOverridden = 77;
export const MarketRentPerSqft = 78;
export const MoveInRent = 79;
export const RenewalRent = 80;
export const MarketRent = 81;
export const TotalTradeOutPercent = 82;
export const MoveOutReason = 83;
export const MoveIns7Day = 84;
export const MoveOuts7Day = 85;
export const WorkOrderAverageAgeToCompletion = 87;
export const WorkOrderAgeDistribution = 88;
export const NewWorkOrders = 89;
export const NewWorkOrderCompletions = 90;
export const WorkOrderAge = 91;
export const OpenWorkOrderCompletions = 92;
export const OccupiedUnitCountByUnitType = 93;
export const AvailableUnitCountByUnitType = 94;
export const LeasedUnitCountByUnitType = 95;
export const OccupancyByUnitType = 96;
export const VacantNotReadyUnitCountByBedrooms = 97;
export const VacantNotReadyUnitCountByUnitType = 98;
export const VacantReadyUnitCountByBedrooms = 99;
export const TotalOperatingExpenses = 100;
export const TotalOperatingRevenue = 101;
export const NetOperatingIncome = 102;
export const NetRentalIncome = 103;
export const VacantReadyUnitCountByUnitType = 104;
export const VacantUnitCountByBedrooms = 105;
export const VacantUnitCountByUnitType = 106;
export const VacantReadinessByBedrooms = 107;
export const VacantReadinessByUnitType = 108;
export const VacantUnrentedUnitCountByBedrooms = 109;
export const VacantUnrentedUnitCountByUnitType = 110;
export const VacantRentedUnitCount = 111;
export const VacantRentedUnitCountByBedrooms = 112;
export const VacantRentedUnitCountByUnitType = 113;
export const NoticeRentedUnitCount = 114;
export const NoticeRentedUnitCountByBedrooms = 115;
export const NoticeRentedUnitCountByUnitType = 116;
export const NoticeUnitCount = 120;
export const NoticeUnitCountByBedrooms = 121;
export const NoticeUnitCountByUnitType = 122;
export const Leads7Day = 123;
export const SiteVisits7Day = 124;
export const Applications7Day = 125;
export const LeadsByAdSourceLast7Days = 126;
export const BudgetedNetRentalIncome = 149;
export const NoticeUnrentedUnitCount = 151;
export const ExpiringLeasesByLeaseEndPending = 160;
export const ExpiringLeasesByLeaseEndNotice = 161;
export const ExpenseFactor = 180;
export const RenewalRateId = 181;
export const NoValueId = 182;
export const BudgetedTotalOperatingRevenue = 183;
export const BudgetedTotalOperatingExpenses = 184;
export const TotalInPlaceRent = 186;
export const TotalMarketRent = 187;
export const GrossPotentialRent = 1000;
export const BudgetedGrossPotentialRent = 1001;
export const LossToLease = 1002;
export const TotalNetPotentialRent = 1004;
export const RentConcessions = 1006;
export const NonIncomeUnits = 1008;
export const EmployeeUnits = 1010;
export const VacancyLoss = 1012;
export const BadDebt = 1014;
export const Delinquencies = 1016;
export const BudgetedDelinquencies = 1017;
export const TotalRentalIncomeAdjustments = 1018;
export const TotalOtherIncome = 1060;
export const TotalCommercialIncome = 1062;
export const TotalPropertyPayroll = 1064;
export const TotalAdvertisingMarketing = 1066;
export const TotalGeneralAdministrative = 1068;
export const UtilityExpensesElectricity = 1070;
export const UtilityExpensesGas = 1072;
export const UtilityExpensesTrash = 1074;
export const UtilityExpensesWater = 1076;
export const UtilityExpensesCable = 1078;
export const UtilityExpensesPest = 1080;
export const TotalUtilityExpenses = 1082;
export const TotalManagementFees = 1084;
export const TotalLandscape = 1086;
export const TotalRepairsMaintenance = 1088;
export const TotalContractServices = 1090;
export const TotalUnitTurnover = 1092;
export const TotalMiscExpenses = 1094;
export const TotalInsurance = 1096;
export const TotalTaxesFees = 1098;
export const TotalDebtService = 1100;
export const NoiAfterDebtService = 1102;
export const CapitalImprovement = 1104;
export const CashFlow = 1106;
export const LeaseExpiryCountByBedrooms30Days = 6001;
export const LeaseExpiryCount30Days = 6002;
export const LeasePendingCountByBedrooms30Days = 6003;
export const LeasePendingCount30Days = 6004;
export const LeaseRenewalCountByBedrooms30Days = 6005;
export const LeaseRenewalCount30Days = 6006;
export const LeaseNoticeCountByBedrooms30Days = 6007;
export const LeaseNoticeCount30Days = 6008;
export const LeaseExpiryCountByBedrooms60Days = 6101;
export const LeaseExpiryCount60Days = 6102;
export const LeasePendingCountByBedrooms60Days = 6103;
export const LeasePendingCount60Days = 6104;
export const LeaseRenewalCountByBedrooms60Days = 6105;
export const LeaseRenewalCount60Days = 6106;
export const LeaseNoticeCountByBedrooms60Days = 6107;
export const LeaseNoticeCount60Days = 6108;
export const LeaseExpiryCountByBedrooms90Days = 6201;
export const LeaseExpiryCount90Days = 6202;
export const LeasePendingCountByBedrooms90Days = 6203;
export const LeasePendingCount90Days = 6204;
export const LeaseRenewalCountByBedrooms90Days = 6205;
export const LeaseRenewalCount90Days = 6206;
export const LeaseNoticeCountByBedrooms90Days = 6207;
export const LeaseNoticeCount90Days = 6208;
export const NewAndRenewedLeasesLast30Days = 7000;
export const EffectiveRentNewAndRenewedLeasesLast30Days = 7001;
export const EffectiveRentPerSqftNewAndRenewedLeasesLast30Days = 7002;
export const TermsNewAndRenewedLeasesLast30Days = 7003;
export const AverageTradeOutNewAndRenewedLeasesLast30Days = 7004;
export const PercentTradeOutNewAndRenewedLeasesLast30Days = 7005;
export const NewAndRenewedLeasesLast60Days = 7100;
export const EffectiveRentNewAndRenewedLeasesLast60Days = 7101;
export const EffectiveRentPerSqftNewAndRenewedLeasesLast60Days = 7102;
export const TermsNewAndRenewedLeasesLast60Days = 7103;
export const AverageTradeOutNewAndRenewedLeasesLast60Days = 7104;
export const PercentTradeOutNewAndRenewedLeasesLast60Days = 7105;
export const NewAndRenewedLeasesLast90Days = 7200;
export const EffectiveRentNewAndRenewedLeasesLast90Days = 7201;
export const EffectiveRentPerSqftNewAndRenewedLeasesLast90Days = 7202;
export const TermsNewAndRenewedLeasesLast90Days = 7203;
export const AverageTradeOutNewAndRenewedLeasesLast90Days = 7204;
export const PercentTradeOutNewAndRenewedLeasesLast90Days = 7205;
export const NewSignsQuarterly = 7300;
export const EffectiveRentNewSignsQuarterly = 7301;
export const EffectiveRentPerSqftNewSignsQuarterly = 7302;
export const TermsNewSignsQuarterly = 7303;
export const AverageTradeOutNewSignsQuarterly = 7304;
export const PercentTradeOutNewSignsQuarterly = 7305;
export const RenewalsQuarterly = 7400;
export const EffectiveRentRenewalsQuarterly = 7401;
export const EffectiveRentPerSqftRenewalsQuarterly = 7402;
export const TermsRenewalsQuarterly = 7403;
export const AverageTradeOutRenewalsQuarterly = 7404;
export const PercentTradeOutRenewalsQuarterly = 7405;
export const NewSignsLast7Days = 7500;
export const EffectiveRentNewSignsLast7Days = 7501;
export const EffectiveRentPerSqftNewSignsLast7Days = 7502;
export const TermsNewSignsLast7Days = 7503;
export const AverageTradeOutNewSignsLast7Days = 7504;
export const PercentTradeOutNewSignsLast7Days = 7505;
export const RenewalsLast7Days = 7600;
export const EffectiveRentRenewalsLast7Days = 7601;
export const EffectiveRentPerSqftLast7Days = 7602;
export const TermsRenewalsLast7Days = 7603;
export const AverageTradeOutRenewalsLast7Days = 7604;
export const PercentTradeOutRenewalsLast7Days = 7605;
export const NewSignsLast30Days = 7700;
export const EffectiveRentNewSignsLast30Days = 7701;
export const EffectiveRentPerSqftNewSignsLast30Days = 7702;
export const TermsNewSignsLast30Days = 7703;
export const AverageTradeOutNewSignsLast30Days = 7704;
export const PercentTradeOutNewSignsLast30Days = 7705;
export const RenewalsLast30Days = 7800;
export const EffectiveRentRenewalsLast30Days = 7801;
export const EffectiveRentPerSqftRenewalsLast30Days = 7802;
export const TermsRenewalsLast30Days = 7803;
export const AverageTradeOutRenewalsLast30Days = 7804;
export const PercentTradeOutRenewalsLast30Days = 7805;
