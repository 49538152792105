import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router";

interface PortfolioOverviewItemProps {
  value: string;
  label: string;
  icon?: IconProp;
  svg?: React.FC<React.SVGProps<SVGSVGElement>>;
  to?: string;
  isAdmin?: boolean;
  className?: string;
}

const PortfolioOverviewItem: React.FC<PortfolioOverviewItemProps> = ({
  value,
  label,
  icon,
  svg: Svg,
  to,
  isAdmin,
  className,
}) => {
  return (
    <div className={`flex-row home-overview-item ${className || ""}`}>
      {Svg ? (
        <Svg className="home-overview-item-label-svg" />
      ) : icon ? (
        <FontAwesomeIcon
          className="home-overview-item-label-icon"
          icon={icon}
        />
      ) : null}
      <div className="flex-col home-overview-item-container">
        <strong>{value}</strong>
        <div className="flex-row home-overview-item-label">
          {isAdmin && to ? (
            <Link to={to}>
              <p>{label}</p>
            </Link>
          ) : (
            <p>{label}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioOverviewItem;
