import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  Integration,
  TransferUser,
  createTransferUser,
  transferUserKinds,
} from "../../../services/integrationsService";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface AddTransferUserModalProps {
  integration: Integration;
  onClose: () => void;
}

const AddTransferUserModal: React.FC<AddTransferUserModalProps> = ({
  integration,
  onClose,
}) => {
  const [username, setUsername] = useState("");
  const [kind, setKind] = useState("");
  const [password, setPassword] = useState(false);
  const [transferUser, setTransferUser] = useState<TransferUser>();
  const [error, setError] = useState<string>();
  const onUnexpectedError = useOnUnexpectedError();

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (username !== "") {
      setError(undefined);
      createTransferUser(integration.id, username, kind, password)
        .then((tu) => setTransferUser(tu))
        .catch((e) => {
          if (
            e.response?.status === 400 &&
            typeof e.response.data?.message === "string"
          ) {
            setError(e.response.data.message);
          } else {
            throw e;
          }
        })
        .catch(onUnexpectedError);
    }
  };

  return (
    <UncontrolledModal size="lg" onClosed={onClose} onFormSubmit={onFormSubmit}>
      <ModalHeader toggle={onClose}>
        <div
          className="flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span>
            {transferUser != null
              ? "Transfer User Created"
              : "Create Transfer User"}
          </span>
        </div>
      </ModalHeader>
      <ModalBody>
        {transferUser != null ? (
          <>
            <div className="mb-3">A transfer user has been created.</div>
            <div>
              <strong>Username:</strong>
              <span style={{ fontFamily: "monospace" }}>
                {" "}
                {transferUser.username}
              </span>
            </div>
            <div>
              <strong>Email:</strong>
              <span style={{ fontFamily: "monospace" }}>
                {" "}
                {transferUser.email}
              </span>
            </div>
            {transferUser.password ? (
              <>
                <div>
                  <strong>Password:</strong>
                  <span style={{ fontFamily: "monospace" }}>
                    {" "}
                    {transferUser.password}
                  </span>
                </div>
                <div className="mt-3">
                  Please record this information safely, it cannot subsequently
                  be retrieved.
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>
            <FormGroup row>
              <Label sm={3}>Username</Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={username}
                  onChange={(e) => {
                    setError(undefined);
                    setUsername(e.target.value.toLowerCase());
                  }}
                  autoComplete="off"
                  invalid={error != null}
                  required
                />
                {error == null ? null : (
                  <small className="text-danger">{error}</small>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3}>Behaviour</Label>
              <Col sm={9}>
                <Input
                  type="select"
                  className="custom-select"
                  value={kind}
                  onChange={(e) => setKind(e.target.value)}
                >
                  {["", "financial", "insights"].map((k) => (
                    <option key={k} value={k}>
                      {transferUserKinds[k]}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ size: 9, offset: 3 }}>
                <Label check>
                  <Input
                    type="checkbox"
                    selected={password}
                    onChange={(e) => setPassword(e.target.checked)}
                    className="me-2"
                  />
                  Enable FTP Access
                </Label>
              </Col>
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          {transferUser != null ? "Close" : "Cancel"}
        </Button>
        {!transferUser ? (
          <Button type="submit" color="primary" disabled={username === ""}>
            Create
          </Button>
        ) : null}
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default AddTransferUserModal;
