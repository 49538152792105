import { epsilonToZero, prettyMuchZero } from "@joyhub-integration/shared";

const toDecimalPlaces = (value: number, decimals: number) =>
  epsilonToZero(value).toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping: true,
  });

export const formatAmount = (value: number | null | undefined) =>
  value == null ? "" : toDecimalPlaces(value, 2);

export const formatDeltaPercentage = (value: number) =>
  prettyMuchZero(value)
    ? "±0%"
    : (value > 0 ? "+" : "") + toDecimalPlaces(value, 1) + "%";

export const bookName = (book: number) =>
  book === 0 ? "Cash" : book === 1 ? "Accrual" : `Unknown #${book}`;
