import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Alert, ModalBody, ModalHeader, Table } from "reactstrap";

import { unexpectedError } from "../../../constants";
import {
  ImportHistoryEntity,
  getImportHistoryById,
} from "../../../services/integrationsService";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface ImportHistoryLogsModalProps {
  onClose: () => void;
  systemId: number;
  syncId: number;
  importHistoryId: number;
}

const ImportHistoryLogsModal: React.FC<ImportHistoryLogsModalProps> = (
  props,
) => {
  const [serverError, setServerError] = useState<string>("");
  const [logs, setLogs] = useState<Array<LogMessage>>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getImportHistoryById(props.systemId, props.syncId, props.importHistoryId)
      .then((history: ImportHistoryEntity) => {
        const theseLogs = JSON.parse(history.logs) ?? [];
        const parsed = parseLegacyLogs(theseLogs);
        setLogs(_.sortBy(parsed, (a) => a.time));
        setLoaded(true);
      })
      .catch(() => setServerError(unexpectedError));
  }, [props.systemId, props.syncId, props.importHistoryId]);
  return (
    <UncontrolledModal size="xl" onClosed={props.onClose}>
      <ModalHeader toggle={props.onClose}>Ingest Log</ModalHeader>
      <ModalBody>
        {serverError && <Alert color="danger">{serverError}</Alert>}
        {loaded ? <LogsTable logs={logs} /> : <p>Loading</p>}
      </ModalBody>
    </UncontrolledModal>
  );
};

type LegacyLogMessage = {
  msg: string;
  captureTime: string;
  data: Record<string, any>;
};

type LogMessage = {
  msg: string;
  level: string;
  time: Date;
  data: Record<string, any>;
};

type LegacyLogs = {
  infos?: LegacyLogMessage[];
  warnings?: LegacyLogMessage[];
  errors?: LegacyLogMessage[];
};

type LogsTableProps = {
  logs: LogMessage[];
};

const parseLegacyLogs = (legacyLogs: LegacyLogs) => {
  const { infos, warnings, errors } = legacyLogs;
  const parsed = new Array<LogMessage>();
  for (const [level, logs] of [
    ["INFO", infos],
    ["WARNING", warnings],
    ["ERROR", errors],
  ] as const) {
    if (logs == null) {
      continue;
    }
    for (const log of logs) {
      parsed.push({
        msg: log.msg,
        level,
        time: new Date(log.captureTime),
        data: log.data,
      });
    }
  }
  return parsed;
};

const LogsTable: React.FC<LogsTableProps> = ({ logs }) => {
  if (logs.length === 0) {
    return <p>There are no logs.</p>;
  }

  return (
    <Table size="sm" striped borderless>
      <thead>
        <tr>
          <th>Date</th>
          <th>Level</th>
          <th>Message</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log, i) => {
          return (
            <tr key={i}>
              <td>
                <pre>{log.time.toUTCString()}</pre>
              </td>
              <td>
                <pre>{log.level}</pre>
              </td>
              <td>
                <pre style={{ whiteSpace: "pre-wrap" }}>{log.msg}</pre>
              </td>
              <td>
                {Object.keys(log.data).length === 0 ? null : (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(log.data, undefined, 2)}
                  </pre>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ImportHistoryLogsModal;
