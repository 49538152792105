import { LeaseDetailsRow, UnitDetailsRow } from "../insight";
import { PartialRecord } from "../util";
import { ExcelNumberFormats } from "./excelReport";

export const ShortMonthDayYearExcelFormat = "mmm d yyyy";

const leaseAttributeFormat: PartialRecord<keyof LeaseDetailsRow, string> = {
  sqft: ExcelNumberFormats.Integer,
  marketRent: ExcelNumberFormats.Dollars,
  previousRent: ExcelNumberFormats.Dollars,
  previousTerm: ExcelNumberFormats.Integer,
  rent: ExcelNumberFormats.Dollars,
  term: ExcelNumberFormats.Integer,
  tradeOut: ExcelNumberFormats.DeltaDollars,
  previousRentPSF: ExcelNumberFormats.DollarsCents,
  rentPSF: ExcelNumberFormats.DollarsCents,
  tradeOutPercent: ExcelNumberFormats.DeltaPercentTenths,
  varianceToMarket: ExcelNumberFormats.DeltaDollars,
  date: ShortMonthDayYearExcelFormat,
  moveInDate: ShortMonthDayYearExcelFormat,
  startDate: ShortMonthDayYearExcelFormat,
  endDate: ShortMonthDayYearExcelFormat,
  outstandingBalance: ExcelNumberFormats.Dollars,
};

const unitAttributeFormat: PartialRecord<keyof UnitDetailsRow, string> = {
  areaSqft: ExcelNumberFormats.Integer,
  marketRent: ExcelNumberFormats.Dollars,
  marketRentPSF: ExcelNumberFormats.DollarsCents,
};

export const leaseAttributeExcelFormat = (
  attribute: string | undefined,
): string | undefined =>
  leaseAttributeFormat[attribute as keyof LeaseDetailsRow];

export const unitAttributeExcelFormat = (
  attribute: string | undefined,
): string | undefined => unitAttributeFormat[attribute as keyof UnitDetailsRow];
