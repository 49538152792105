import "./actionBar.css";

import React from "react";
import { Button, ButtonGroup, ButtonProps } from "reactstrap";

import ButtonWithIcon, { ButtonWithIconProps } from "./ButtonWithIcon";

type ActionBarProps = {
  buttonProps?: Array<ButtonWithIconProps | React.ReactElement<unknown>>;
  rightButtonProps?: Array<ButtonWithIconProps | React.ReactElement<unknown>>;
  middleButtonProps?: Array<ButtonProps>;
  style?: React.CSSProperties;
} & React.PropsWithChildren;

const ActionBar: React.FC<ActionBarProps> = ({
  buttonProps,
  middleButtonProps,
  rightButtonProps,
  style,
}) => (
  <div className="flex-row jh-action-bar px-4 py-3" style={style}>
    <ButtonGroup>
      {buttonProps?.map((p, idx) =>
        React.isValidElement(p) ? (
          <React.Fragment key={idx}>{p}</React.Fragment>
        ) : (
          <ButtonWithIcon key={idx} {...p} />
        ),
      )}
    </ButtonGroup>
    <div className="d-flex">
      {middleButtonProps && (
        <ButtonGroup className="me-3">
          {middleButtonProps.map((p, idx) => (
            <Button {...p} key={idx}>
              {p.label}
            </Button>
          ))}
        </ButtonGroup>
      )}
      {rightButtonProps && (
        <ButtonGroup>
          {rightButtonProps.map((p, idx) =>
            React.isValidElement(p) ? (
              <React.Fragment key={idx}>{p}</React.Fragment>
            ) : (
              <ButtonWithIcon key={idx} {...p} />
            ),
          )}
        </ButtonGroup>
      )}
    </div>
  </div>
);

export default ActionBar;
