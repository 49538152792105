import {
  byUserAction,
  FinancialImportResponse,
} from "@joyhub-integration/shared";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";

import { unexpectedError } from "../../../constants";
import { prepareUpload, UploadInfo } from "../../../services/uploadService";
import PlatformContext from "../../app/PlatformContext";
import { useSetAlert } from "../../common/alert/withAlertModal";
import SubmitButton from "../../common/button/SubmitButton";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import AddFinancialImportForm, {
  AddFinancialImportState,
} from "./AddFinancialImportForm";
import FinancialImportResponsePreview from "./FinancialImportResponsePreview";
import {
  submitFinancialImport,
  validateFinancialImport,
} from "./financialImportsApi";

type AddFinancialImportModalProps = {
  onSubmit: () => void;
  onClose: () => void;
};

const AddFinancialImportModal: React.FC<AddFinancialImportModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const [state, setState] = useState<AddFinancialImportState>();
  const [upload, setUpload] = useState<UploadInfo>();
  const [submitting, setSubmitting] = useState(false);
  const [importError, setImportError] = useState<string>();
  const [validationResponse, setValidationResponse] =
    useState<FinancialImportResponse<true>>();
  const setAlert = useSetAlert();
  const { platform } = useContext(PlatformContext);

  useEffect(() => {
    if (state?.file) {
      setImportError(undefined);
      setValidationResponse(undefined);
    }
  }, [state?.file]);

  const doValidate = async () => {
    try {
      setSubmitting(true);
      const { file, book, system_id, tree_code, description } = state!;
      const upload = await prepareUpload(file);
      setUpload(upload);
      const validationResponse = await validateFinancialImport({
        ...upload,
        book,
        tree_code,
        system_id,
        description,
        cause: byUserAction(
          "user validated financial import",
          platform!.person.id,
        ),
      });
      setImportError(undefined);
      setValidationResponse(validationResponse);
    } catch (err) {
      const response = (err as any)?.response;
      if (response && response.status === 400 && response.data?.message) {
        setImportError(response.data.message);
      } else {
        setImportError(unexpectedError);
      }
      setState(undefined);
    } finally {
      setSubmitting(false);
    }
  };

  const doImport = async () => {
    const { book, tree_code, system_id, description } = state!;
    try {
      setSubmitting(true);
      await submitFinancialImport({
        ...upload!,
        book,
        tree_code,
        system_id,
        description,
        cause: byUserAction(
          "user submitted financial import",
          platform!.person.id,
        ),
      });
      setAlert(`${description} was imported.`, true);
      onSubmit();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <UncontrolledModal
      size="lg"
      onFormSubmit={() => (validationResponse ? doImport() : doValidate())}
      onClosed={onClose}
    >
      <ModalHeader toggle={onClose}>Import Financials</ModalHeader>
      <ModalBody>
        {importError ? (
          <UncontrolledAlert color="danger">{importError}</UncontrolledAlert>
        ) : null}
        {!validationResponse ? (
          <AddFinancialImportForm setState={setState} />
        ) : (
          <FinancialImportResponsePreview value={validationResponse} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton
          color="primary"
          busy={submitting}
          disabled={state?.file == null}
        >
          {!validationResponse ? "Validate" : "Import"}
        </SubmitButton>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default AddFinancialImportModal;
