import { VisualizationSpec } from "react-vega";

const pie = ({
  xAxisFormat,
  yAxisFormat,
  colors,
  isOneRow,
}: {
  xAxisFormat?: string;
  yAxisFormat?: string;
  colors?: string[];
  isOneRow?: boolean;
}) =>
  ({
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    width: isOneRow ? "container" : undefined,
    height: isOneRow ? "container" : undefined,
    data: { name: "data" },
    mark: "arc",
    encoding: {
      theta: {
        field: "y",
        title: "Value",
        type: "quantitative",
        stack: true,
        legend: { format: yAxisFormat },
      },
      color: {
        field: isOneRow ? "column" : "x",
        title: isOneRow ? "Header" : "Dimension",
        type: "nominal",
        legend: {
          orient: "bottom",
          title: null,
          columns: 4,
          labelFontSize: 14,
          labelLimit: 0,
          titleLimit: 0,
          format: xAxisFormat,
        },
        sort: isOneRow
          ? { field: "*", order: "ascending", op: "count" }
          : undefined,
        scale: {
          range: colors,
        },
      },
      column: isOneRow
        ? undefined
        : {
            field: "column",
            title: null,
          },
    },
  }) as VisualizationSpec;

export default pie;
