import { faPen, faSave, faX } from "@fortawesome/pro-light-svg-icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { BooleanParam, useQueryParam } from "use-query-params";

import { usePropertyNote } from "../../hooks/usePropertyNote";
import { InsightCustomComponentProps } from "../../services/models";
import { updatePropertyNote } from "../../services/propertiesService";
import PlatformContext from "../app/PlatformContext";
import ButtonWithIcon from "../common/button/ButtonWithIcon";

import "./style.css";

const placeholderText =
  "Share notes about the property with your team. Select the hamburger menu to update or delete this note.";

const PropertyNoteInsightCard: React.FC<InsightCustomComponentProps> = ({}) => {
  const [editMode] = useQueryParam("edit", BooleanParam);

  const { embedInfo } = useContext(PlatformContext).platform!;

  const { propertyNote, propertyId, updatePropertyNoteInContext } =
    usePropertyNote();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNote(e.target.value);
    },
    [setNote],
  );

  const handleCancel = useCallback(() => {
    setNote(propertyNote);
    setIsEdit(false);
  }, [propertyNote]);

  const handleSave = useCallback(async () => {
    if (propertyId) {
      await updatePropertyNote(propertyId, note);
      updatePropertyNoteInContext(note);
      setIsEdit(false);
    }
  }, [note, propertyId, updatePropertyNoteInContext]);

  useEffect(() => {
    setNote(propertyNote);
  }, [propertyNote]);

  return (
    <div className="w-100 h-100 d-flex flex-col justify-content-start position-relative">
      {isEdit ? (
        <>
          {!editMode && (
            <>
              <ButtonWithIcon
                color="primary"
                icon={faX}
                onClick={handleCancel}
                outline
                className="note-icon position-absolute"
              />
              <ButtonWithIcon
                color="primary"
                icon={faSave}
                onClick={handleSave}
                outline
                className="note-icon position-absolute"
                style={{ top: 20 }}
              />
            </>
          )}
          <TextareaAutosize
            name="text"
            placeholder={placeholderText}
            value={note}
            style={{
              width: "calc(100% - 30px)",
              resize: "none",
            }}
            className="h-100 note-outline note-write"
            minRows={4}
            onChange={handleChangeText}
          />
        </>
      ) : (
        <>
          {!editMode && !embedInfo && (
            <ButtonWithIcon
              color="primary"
              icon={faPen}
              onClick={() => setIsEdit(true)}
              outline
              className="note-icon position-absolute"
            />
          )}
          <TextareaAutosize
            style={{
              width: `calc(100%${!embedInfo && !editMode ? " - 25px" : ""})`,
              resize: "none",
            }}
            className="h-100 note note-readonly note-outline"
            readOnly
            placeholder={placeholderText}
            minRows={4}
            value={propertyNote}
          />
        </>
      )}
    </div>
  );
};

export default PropertyNoteInsightCard;
