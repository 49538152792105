import "react-grid-layout/css/styles.css";

import {
  DashboardKindEnum,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import CalculateInsightWorker from "../../services/calculateInsightWorker";
import {
  calculateInstantPieValues,
  PieValue,
} from "../../services/calculateInsightWorker/calculateInsightWorker";
import {
  getInstantData,
  getRangeDataOrDefault,
  InstantInsights,
  RangedInsights,
} from "../../services/dataService";
import { insightComponents } from "../../services/insightLibrary/insightComponents";
import definitions from "../../services/insightLibrary/insightDefinitions";
import insightsLibraryService from "../../services/insightLibrary/insightsLibraryService";
import { KnownInsight as BackendInsight } from "../../services/insightsService";
import {
  ChartValues,
  ComplexInsightIds,
  OtherDashboardInsight as DashboardInsight,
  Insight,
  ScatterChartValues,
} from "../../services/models";
import { Property } from "../../services/propertiesService";
import { monthsBack, nowAndAYearAgo } from "../../utils/date";
import { DelimitedStringArrayParamDeepEqual } from "../../utils/useQueryParams";
import JhBarChart from "../common/charts/BarChart";
import ChartWrapper from "../common/charts/ChartWrapper";
import JhColumnChart from "../common/charts/ColumnChart";
import JhFunnelChart from "../common/charts/FunnelChart";
import JhLineChart from "../common/charts/LineChart";
import NumberChart from "../common/charts/NumberChart";
import JhPieChart from "../common/charts/PieChart";
import JhScatterChart from "../common/charts/ScatterChart";
import SequenceChart from "../common/charts/SequenceChart";
import { colsPerRow } from "./colsPerRow";
import DimensionFilter from "./DimensionFilter";
import MonthFilter from "./MonthFilter";
import InstantTableChart from "./tableChart/InstantTableChart";
import RangeTableChart from "./tableChart/RangeTableChart";
import ThirtySixtyNinetyDayFilter from "./ThirtySixtyNinetyDayFilter";

interface ChartProps {
  insightId: number;
  dashboardInsightId: number;
  rangeData: RangedInsights;
  rangeDataAYearAgo: RangedInsights;
  insightsMap: { [id: string]: BackendInsight };
  propertiesHash: { [key: string]: Property };
  onDimensionFilterChange?: (dimension: string) => void;
  onMonthsSelectedChange?: (monthsSelected: Set<string>) => void;
}

export const SimpleJhBarChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onDimensionFilterChange,
  onMonthsSelectedChange: onMonthSelectedChange,
}) => {
  const [chartValues, setChartValues] = useState<ChartValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onDimensionFilterChange) {
      onDimensionFilterChange(dimensionFilterSelected as string);
    }
  }, [dimensionFilterSelected, onDimensionFilterChange]);

  useEffect(() => {
    if (onMonthSelectedChange) {
      onMonthSelectedChange(new Set(monthsSelected));
    }
  }, [monthsSelected, onMonthSelectedChange]);

  useEffect(() => {
    setLoading(true);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "BAR",
        filterByDimension,
        new Set(monthsSelected),
      )
      .then((res) => {
        const chartValues = res as ChartValues;
        setChartValues(chartValues);
        setLoading(false);
      });
  }, [
    insightId,
    dimensionFilterSelected,
    filterDimension,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;
  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={chartValues.dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div className={chartContentClassName}>
          <JhBarChart
            id={dashboardInsightId}
            data={chartValues.data}
            yAxisKeys={chartValues.secondaryAxisKeys}
            categoryKey={chartValues.primaryAxisKey}
            dataKey="Rent"
            tooltipSortDir={
              insight?.generalChartOptions?.sortValuesInTooltip?.sortDirection
            }
            stackId={insight?.barChartOptions?.stacked ? "a" : undefined}
          />
        </div>
      )}
    </>
  );
};

export const SimpleScatterChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onMonthsSelectedChange: onMonthSelectedChange,
}) => {
  const [chartValues, setChartValues] = useState<ScatterChartValues>();
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onMonthSelectedChange) {
      onMonthSelectedChange(new Set(monthsSelected));
    }
  }, [monthsSelected, onMonthSelectedChange]);

  useEffect(() => {
    setLoading(true);
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeScatterValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "SCATTER",
        undefined,
        new Set(monthsSelected),
      )
      .then((res) => {
        setChartValues(res);
        setLoading(false);
      });
  }, [
    insightId,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;

  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div className={chartContentClassName}>
          <JhScatterChart
            data={chartValues.data}
            xAxisKey={chartValues.xAxisKey}
            xAxisKeyUnit={chartValues.xAxisKeyUnit}
            yAxisKey={chartValues.yAxisKey}
            yAxisKeyUnit={chartValues.yAxisKeyUnit}
            zAxisKey={chartValues.zAxisKey}
          />
        </div>
      )}
    </>
  );
};

export const SimpleJhColumnChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onDimensionFilterChange,
}) => {
  const [chartValues, setChartValues] = useState<ChartValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onDimensionFilterChange) {
      onDimensionFilterChange(dimensionFilterSelected as string);
    }
  }, [dimensionFilterSelected, onDimensionFilterChange]);

  useEffect(() => {
    setLoading(true);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "COLUMN",
        filterByDimension,
        new Set(monthsSelected),
      )
      .then((res) => {
        const chartValues = res as ChartValues;
        setChartValues(chartValues);
        setLoading(false);
      });
  }, [
    insightId,
    filterDimension,
    dimensionFilterSelected,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;
  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={chartValues.dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div className={chartContentClassName}>
          <JhColumnChart
            data={chartValues.data}
            yAxisKeys={chartValues.secondaryAxisKeys}
            xAxisKey={chartValues.primaryAxisKey}
            yAxisUnit=""
            stackId={insight?.columnChartOptions?.stacked ? "a" : undefined}
            tooltipSortDir={
              insight?.generalChartOptions?.sortValuesInTooltip?.sortDirection
            }
          />
        </div>
      )}
    </>
  );
};

export const SimpleLineChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onDimensionFilterChange,
}) => {
  const [chartValues, setChartValues] = useState<ChartValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onDimensionFilterChange) {
      onDimensionFilterChange(dimensionFilterSelected as string);
    }
  }, [dimensionFilterSelected, onDimensionFilterChange]);

  useEffect(() => {
    setLoading(true);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "LINE",
        filterByDimension,
        new Set(monthsSelected),
      )
      .then((res) => {
        const chartValues = res as ChartValues;
        setChartValues(chartValues);
        setLoading(false);
      });
  }, [
    insightId,
    dimensionFilterSelected,
    filterDimension,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;
  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth
      ? "jh-chart-content-with-dimension-filter"
      : "jh-chart-content-without-dimension-filter";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={chartValues.dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div className={chartContentClassName}>
          <JhLineChart
            data={chartValues.data}
            yAxisKeys={chartValues.secondaryAxisKeys}
            xAxisKey={chartValues.primaryAxisKey}
            yAxisUnit=""
            stackId={insight?.lineChartOptions?.stacked ? "a" : undefined}
            tooltipSortDir={
              insight?.generalChartOptions?.sortValuesInTooltip?.sortDirection
            }
            type="monotone"
          />
        </div>
      )}
    </>
  );
};

export const InstantPieChart = ({
  insightId,
  instantValuesNow,
  instantValuesAYearAgo,
  insightsMap,
}: {
  insightId: number;
  dashboardInsightId: number;
  instantValuesNow: InstantInsights;
  instantValuesAYearAgo: InstantInsights;
  insightsMap: { [id: string]: BackendInsight };
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<PieValue[]>();

  useEffect(() => {
    setLoading(true);
    const definition = definitions.find((d) => d.id === insightId)!;
    const complexInsightIdsSet = definition.insightIds as ComplexInsightIds[];
    const promises = complexInsightIdsSet.map((complexInsightIds) => {
      return calculateInstantPieValues(
        complexInsightIds,
        instantValuesNow.overall,
        instantValuesAYearAgo.overall,
        insightsMap,
      );
    });
    Promise.all(promises).then((values) => {
      setValues(values);
      setLoading(false);
    });
  }, [insightId, instantValuesNow, instantValuesAYearAgo, insightsMap]);

  if (!values || loading) return null;

  return (
    <>
      {!values ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : (
        <div className="jh-chart-content-without-dimension-filter jh-chart-content-no-scrollbars">
          <JhPieChart
            data={values}
            yAxisKeys={{
              value: { colorHex: "", unit: "Number", orientation: "left" },
            }}
            xAxisKey="label"
          />
        </div>
      )}
    </>
  );
};

export const SimplePieChart: React.FC<ChartProps> = ({
  insightId,
  dashboardInsightId,
  rangeData,
  rangeDataAYearAgo,
  insightsMap,
  propertiesHash,
  onDimensionFilterChange,
}) => {
  const [chartValues, setChartValues] = useState<ChartValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const dimensionQueryParamName = `insight${dashboardInsightId}DimensionFilterSelected`;
  const [dimensionFilterSelected, setDimensionFilterSelected] =
    useQueryParam<string>(
      dimensionQueryParamName,
      withDefault(StringParam, "all"),
    );
  const monthQueryParamName = `insight${dashboardInsightId}monthSelected`;
  const [monthsSelected, setMonthsSelected] = useQueryParam(
    monthQueryParamName,
    withDefault(DelimitedStringArrayParamDeepEqual, [] as string[]),
  );
  const insight = insightsLibraryService.insights.find(
    (i) => i.id === insightId,
  );
  const filterDimension = insight?.generalChartOptions?.filterDimension;
  //const showLastNMonths = insight?.generalChartOptions?.showLastNMonths;

  useEffect(() => {
    if (onDimensionFilterChange) {
      onDimensionFilterChange(dimensionFilterSelected as string);
    }
  }, [dimensionFilterSelected, onDimensionFilterChange]);

  useEffect(() => {
    setLoading(true);
    const filterByDimension = filterDimension
      ? {
          dimension: filterDimension,
          selectedValue: dimensionFilterSelected,
        }
      : undefined;
    const calculateInsightWorker = new CalculateInsightWorker();
    calculateInsightWorker
      .calculateRangeInsightValue(
        insightId,
        rangeData,
        rangeDataAYearAgo,
        insightsMap,
        propertiesHash,
        "PIE",
        filterByDimension,
        new Set(monthsSelected),
      )
      .then((res) => {
        const chartValues = res as ChartValues;
        setChartValues(chartValues);
        setLoading(false);
      });
  }, [
    insightId,
    dimensionFilterSelected,
    filterDimension,
    monthsSelected,
    insightsMap,
    propertiesHash,
    rangeData,
    rangeDataAYearAgo,
  ]);
  /*useEffect(() => {
    if (chartValues && showLastNMonths) {
      setMonthsSelected(
        chartValues.monthOptions.slice(chartValues.monthOptions.length - showLastNMonths),
        "replaceIn"
      );
    }
  }, [chartValues, showLastNMonths, setMonthsSelected]);*/
  if (!chartValues || loading) return null;
  const isDataUnavailable = chartValues.dataUnavailable;
  const chartContentClassName =
    insight?.generalChartOptions?.filterDimension ||
    insight?.generalChartOptions?.filterByMonth ||
    insight?.generalChartOptions?.filterByThirtySixtyNinetyDays
      ? "jh-chart-content-with-dimension-filter jh-chart-content-no-scrollbars"
      : "jh-chart-content-without-dimension-filter jh-chart-content-no-scrollbars";
  return (
    <>
      <div className="jh-chart-filters">
        {insight?.generalChartOptions?.filterByMonth ? (
          <MonthFilter
            monthSelected={monthsSelected.length ? monthsSelected[0] : ""}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(month: string) =>
              setMonthsSelected([month], "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterDimension ? (
          <DimensionFilter
            allOptionKey="all"
            dimensionSelected={dimensionFilterSelected}
            filterDimension={insight?.generalChartOptions?.filterDimension}
            dimensionOptions={chartValues.dimensionOptions}
            onDimensionChange={(dim) =>
              setDimensionFilterSelected(dim, "replaceIn")
            }
          />
        ) : null}
        {insight?.generalChartOptions?.filterByThirtySixtyNinetyDays ? (
          <ThirtySixtyNinetyDayFilter
            monthsSelected={monthsSelected}
            monthOptions={chartValues.monthOptions}
            onMonthSelectedChange={(months: string[]) =>
              setMonthsSelected(months, "replaceIn")
            }
          />
        ) : null}
      </div>
      {isDataUnavailable ? (
        <div className="dashboard-grid-item-loading">No data available</div>
      ) : null}
      {isDataUnavailable ? null : (
        <div className={chartContentClassName}>
          <JhPieChart
            data={chartValues.data}
            yAxisKeys={chartValues.secondaryAxisKeys}
            xAxisKey={chartValues.primaryAxisKey}
          />
        </div>
      )}
    </>
  );
};

const Skeleton: React.FC = () => (
  <div className="dashboard-number-insight skeleton" />
);

interface DashboardGridProps {
  dashboardId: number;
  dashboardInsights: Array<DashboardInsight>;
  insightsMap: { [id: string]: BackendInsight };
  propertiesHash: { [key: string]: Property };
  setDashboardLayout: (layout: ReactGridLayout.Layouts) => void;
  setDashboardInsightToEdit: (di: DashboardInsight) => void;
  setDashboardInsightToRemove: (di: DashboardInsight) => void;
  layout?: ReactGridLayout.Layouts;
  editMode: boolean;
  selection: PropertiesSelection;
  dashboardKind:
    | DashboardKindEnum.Dashboard
    | DashboardKindEnum.PropertyDashboard;
}

const DashboardGrid: React.FC<DashboardGridProps> = ({
  dashboardId,
  dashboardInsights,
  layout,
  insightsMap,
  propertiesHash,
  setDashboardLayout,
  setDashboardInsightToEdit,
  setDashboardInsightToRemove,
  editMode,
  selection,
  dashboardKind,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [rangeDataBreakout, setRangeDataBreakout] = useState<RangedInsights>();
  const [rangeDataNoBreakout, setRangeDataNoBreakout] =
    useState<RangedInsights>();
  const [rangeDataBreakoutAYearAgo, setRangeDataBreakoutAYearAgo] =
    useState<RangedInsights>();
  const [rangeDataNoBreakoutAYearAgo, setRangeDataNoBreakoutAYearAgo] =
    useState<RangedInsights>();
  const [instantDataNow, setInstantDataNow] = useState<InstantInsights>();
  const [instantDataAYearAgo, setInstantDataAYearAgo] =
    useState<InstantInsights>();

  function getAllInstantData(
    insightIds: Set<number>,
    selection: PropertiesSelection,
    yoy?: boolean,
  ): Promise<InstantInsights> {
    const now = new Date();
    const aYearAgo = new Date(now);
    aYearAgo.setFullYear(now.getFullYear() - 1);
    const atDate = yoy ? aYearAgo : now;
    return insightIds.size
      ? getInstantData(Array.from(insightIds), selection, atDate, "property")
      : Promise.resolve({
          date: "",
          byProperty: {},
          byTag: {},
          overall: {},
          goals: {
            byProperty: {},
            byTag: {},
            overall: {},
          },
        });
  }

  const smCharts = useMemo(
    () =>
      dashboardInsights.filter((di) => {
        const definition = definitions.find((i) => i.id === di.insightId);
        return (
          !definition?.customComponent &&
          definition?.calculationType === "INSTANT"
        );
      }),
    [dashboardInsights],
  );

  const mdCharts = useMemo(
    () =>
      dashboardInsights.filter((di) => {
        const definition = definitions.find((i) => i.id === di.insightId);
        return (
          !definition?.customComponent &&
          definition?.calculationType === "RANGE"
        );
      }),
    [dashboardInsights],
  );

  const customComponentCharts = useMemo(
    () =>
      dashboardInsights.filter((di) => {
        const definition = definitions.find((i) => i.id === di.insightId);
        return !!definition?.customComponent;
      }),
    [dashboardInsights],
  );

  useEffect(() => {
    setLoaded(false);
    const smChartInsightIdsNow = smCharts.reduce<Set<number>>(
      (prev, current) => {
        const childInsightIds = insightsLibraryService.getAllChildInsightIds(
          current.insightId,
        );
        childInsightIds.forEach((id) => prev.add(id));
        return prev;
      },
      new Set(),
    );
    const smChartInsightIdsYOY = smCharts.reduce<Set<number>>(
      (prev, current) => {
        if (current.visualizationType === "YOY CHANGE") {
          const childInsightIds = insightsLibraryService.getAllChildInsightIds(
            current.insightId,
          );
          childInsightIds.forEach((id) => prev.add(id));
        } else {
          const insightDefinition = definitions.find(
            (i) => i.id === current.insightId,
          );
          for (const idOrComplexId of insightDefinition?.insightIds ?? []) {
            if (typeof idOrComplexId !== "number" && idOrComplexId.yoy) {
              for (const id of idOrComplexId.insightIds as number[]) {
                prev.add(id);
              }
            }
          }
        }
        return prev;
      },
      new Set(),
    );
    const getInsightIds = (breakout: boolean, yoy: boolean = false) => {
      return mdCharts
        .filter((mdChart) => {
          const insight = insightsLibraryService.insights.find(
            (i) => i.id === mdChart.insightId,
          ) as Insight;
          return !!insight.byPropertyCalculationType === breakout;
        })
        .reduce<Set<number>>((prev, current) => {
          const childInsightIds = insightsLibraryService.getAllChildInsightIds(
            current.insightId,
            yoy,
          );
          childInsightIds.forEach((id) => prev.add(id));
          return prev;
        }, new Set());
    };
    const rangeInsightIdsBreakout = getInsightIds(true);
    const rangeInsightIdsBreakoutYoy = getInsightIds(true, true);
    const rangeInsightIdsNonBreakout = getInsightIds(false);
    const rangeInsightIdsNonBreakoutYoy = getInsightIds(false, true);
    const { from: aYearAgo, to: now } = nowAndAYearAgo();
    const { from: twoYearsAgo, to: aYearAndMonthAgo } = nowAndAYearAgo(
      monthsBack(aYearAgo, 1),
    );
    Promise.all([
      getAllInstantData(smChartInsightIdsNow, selection, false),
      getAllInstantData(smChartInsightIdsYOY, selection, true),
      getRangeDataOrDefault(
        aYearAgo,
        now,
        rangeInsightIdsBreakout,
        selection,
        "property",
      ),
      getRangeDataOrDefault(
        aYearAgo,
        now,
        rangeInsightIdsNonBreakout,
        selection,
      ),
      getRangeDataOrDefault(
        twoYearsAgo,
        aYearAndMonthAgo,
        rangeInsightIdsBreakoutYoy,
        selection,
        "property",
      ),
      getRangeDataOrDefault(
        twoYearsAgo,
        aYearAndMonthAgo,
        rangeInsightIdsNonBreakoutYoy,
        selection,
      ),
    ]).then(
      ([
        instantNowValues,
        instantAYearAgoValues,
        rangeValuesBreakout,
        rangeValuesNoBreakout,
        rangeValuesBreakoutYoy,
        rangeValuesNoBreakoutYoy,
      ]) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setInstantDataNow(instantNowValues);
          setInstantDataAYearAgo(instantAYearAgoValues);
          setRangeDataBreakout(rangeValuesBreakout);
          setRangeDataNoBreakout(rangeValuesNoBreakout);
          setRangeDataBreakoutAYearAgo(rangeValuesBreakoutYoy);
          setRangeDataNoBreakoutAYearAgo(rangeValuesNoBreakoutYoy);
          setLoaded(true);
        });
      },
    );
  }, [dashboardInsights, selection, mdCharts, smCharts]);

  function func(
    _: ReactGridLayout.Layout[],
    allLayouts: ReactGridLayout.Layouts,
  ) {
    setDashboardLayout(allLayouts);
  }

  if (
    smCharts.length === 0 &&
    mdCharts.length === 0 &&
    customComponentCharts.length === 0
  ) {
    return null;
  }

  return (
    <ResponsiveGridLayout
      className={`dashboard-grid-container old-dashboard dashboard-${
        loaded ? "loaded" : "loading"
      }`}
      containerPadding={[0, 0]}
      margin={[24, 24]}
      cols={colsPerRow}
      layouts={layout}
      onLayoutChange={func}
      isDraggable={editMode && loaded}
      isResizable={editMode && loaded}
      rowHeight={185}
      width={document.documentElement.clientWidth - 48}
      resizeHandle={
        editMode && (
          <div className="react-resizable-handle dashboard-grid-item-resize" />
        )
      }
    >
      {smCharts.map((di) => {
        return (
          <ChartWrapper
            key={di.id.toString()}
            dashboardId={dashboardId}
            dashboardInsight={di}
            heading={di.name}
            onEditInsight={() => setDashboardInsightToEdit(di)}
            onRemoveInsight={() => setDashboardInsightToRemove(di)}
            editMode={editMode}
            showInfo={!editMode}
            drillable
            selection={selection}
            dashboardKind={dashboardKind}
          >
            {!instantDataNow || !instantDataAYearAgo || !loaded ? (
              <Skeleton />
            ) : di.visualizationType === "TABLE" ? (
              <InstantTableChart
                insightId={di.insightId}
                dashboardInsightId={di.id}
                instantDataNow={instantDataNow}
                instantDataAYearAgo={instantDataAYearAgo}
              />
            ) : di.visualizationType === "SEQUENCE" ? (
              <SequenceChart
                insightId={di.insightId}
                instantValuesNow={instantDataNow}
                instantValuesAYearAgo={instantDataAYearAgo}
                insightsMap={insightsMap}
              />
            ) : di.visualizationType === "FUNNEL" ? (
              <JhFunnelChart
                insightId={di.insightId}
                instantValuesNow={instantDataNow}
                instantValuesAYearAgo={instantDataAYearAgo}
                insightsMap={insightsMap}
              />
            ) : di.visualizationType === "PIE" ? (
              <InstantPieChart
                insightId={di.insightId}
                dashboardInsightId={0}
                instantValuesNow={instantDataNow}
                instantValuesAYearAgo={instantDataAYearAgo}
                insightsMap={insightsMap}
              />
            ) : (
              <NumberChart
                insightId={di.insightId}
                vizType={di.visualizationType}
                instantValuesNow={instantDataNow}
                instantValuesAYearAgo={instantDataAYearAgo}
                insightsMap={insightsMap}
              />
            )}
          </ChartWrapper>
        );
      })}
      {mdCharts.map((di) => {
        const insight = insightsLibraryService.insights.find(
          (i) => i.id === di.insightId,
        );
        const rangeData = insight?.byPropertyCalculationType
          ? rangeDataBreakout
          : rangeDataNoBreakout;
        const rangeDataAYearAgo = insight?.byPropertyCalculationType
          ? rangeDataBreakoutAYearAgo
          : rangeDataNoBreakoutAYearAgo;
        const chartOrTableProps = {
          insightId: di.insightId,
          dashboardInsightId: di.id,
          rangeData: rangeData as RangedInsights,
          rangeDataAYearAgo: rangeDataAYearAgo as RangedInsights,
          insightsMap: insightsMap,
          propertiesHash: propertiesHash,
        };
        return (
          <ChartWrapper
            key={di.id.toString()}
            dashboardId={dashboardId}
            dashboardInsight={di}
            heading={di.name}
            onEditInsight={() => setDashboardInsightToEdit(di)}
            onRemoveInsight={() => setDashboardInsightToRemove(di)}
            drillable
            editMode={editMode}
            showInfo={!editMode}
            selection={selection}
            dashboardKind={dashboardKind}
          >
            {!loaded || !rangeData ? (
              <Skeleton />
            ) : di.visualizationType === "COLUMN" ? (
              <SimpleJhColumnChart {...chartOrTableProps} />
            ) : di.visualizationType === "BAR" ? (
              <SimpleJhBarChart {...chartOrTableProps} />
            ) : di.visualizationType === "LINE" ? (
              <SimpleLineChart {...chartOrTableProps} />
            ) : di.visualizationType === "SCATTER" ? (
              <SimpleScatterChart {...chartOrTableProps} />
            ) : di.visualizationType === "PIE" ? (
              <SimplePieChart {...chartOrTableProps} />
            ) : di.visualizationType === "TABLE" &&
              insight?.calculationType === "RANGE" ? (
              <RangeTableChart {...chartOrTableProps} />
            ) : null}
          </ChartWrapper>
        );
      })}
      {customComponentCharts.map((di) => {
        const insight = insightsLibraryService.insights.find(
          (i) => i.id === di.insightId,
        );
        const defaultProps = insight?.customComponent?.defaultProps || {};
        const CustomComponent = insightComponents[insight!.id];
        const heading = insight?.customComponent?.showHeading
          ? di.name
          : undefined;
        const drillable = insight?.customComponent?.drillable;
        return (
          <ChartWrapper
            key={di.id.toString()}
            dashboardId={dashboardId}
            dashboardInsight={di}
            onEditInsight={() => setDashboardInsightToEdit(di)}
            onRemoveInsight={() => setDashboardInsightToRemove(di)}
            editMode={editMode}
            showInfo={!editMode}
            heading={heading}
            noContentPadding={insight?.customComponent?.noContentPadding}
            drillable={drillable}
            selection={selection}
            dashboardKind={dashboardKind}
          >
            <CustomComponent
              {...defaultProps}
              isOldDashboard
              dashboardInsight={di}
              selection={selection}
            />
          </ChartWrapper>
        );
      })}
    </ResponsiveGridLayout>
  );
};

export default DashboardGrid;
