import { ReportColumnBase, ReportDataColumn } from "@joyhub-integration/shared";

export type Bucket = {
  label: string | undefined;
  width: number;
  column: number;
  type?: string | undefined;
  index?: number;
};

export const headerBuckets = (
  columns: (ReportDataColumn | ReportColumnBase)[],
) => {
  const buckets: Bucket[] = [],
    subBuckets: Bucket[] = [];
  let column = 0;
  let bucketIndex = 0;
  for (const col of columns) {
    ++bucketIndex;
    if ((col as any).display === false) continue; // undefined in a ReportColumnBase

    const { bucket, subBucket } = col;
    if (bucket == null || bucket !== buckets[buckets.length - 1]?.label) {
      buckets.push({ label: bucket, width: 1, column, index: bucketIndex - 1 });
      subBuckets.push({
        label: subBucket,
        width: 1,
        column,
        index: bucketIndex - 1,
      });
    } else {
      ++buckets[buckets.length - 1].width;
      if (subBucket !== subBuckets[subBuckets.length - 1].label) {
        subBuckets.push({
          label: subBucket,
          width: 1,
          column,
          index: bucketIndex - 1,
        });
      } else {
        ++subBuckets[subBuckets.length - 1].width;
      }
    }
    ++column;
  }
  if (buckets.every((bucket) => !bucket.label)) buckets.splice(0);
  if (subBuckets.every((bucket) => !bucket.label)) subBuckets.splice(0);
  return { buckets, subBuckets };
};

export const isBucketStart = (columns: ReportDataColumn[], i: number) => {
  if (i === 0 || columns[i].bucket == null) return true;

  const displayedColumns = columns
    .map((col, index) => ({ ...col, index }))
    .filter((col) => col?.display !== false);

  const curColumn = displayedColumns
    .map((col, index) => ({ ...col, filteredIndex: index }))
    .find((col) => col.index === i);
  const prevColumn = displayedColumns[(curColumn?.filteredIndex ?? 0) - 1];

  return (
    curColumn?.bucket !== prevColumn?.bucket ||
    curColumn?.subBucket !== prevColumn?.subBucket
  );
};
