import React from "react";
import { Input } from "reactstrap";

import { getOverriddenDimension } from "../../services/insightLibrary/dimensionOverrides";

interface DimensionFilterProps {
  allOptionKey: string;
  filterDimension: string;
  dimensionOptions: string[];
  dimensionSelected: string;
  onDimensionChange: (dimension: string) => void;
}

const DimensionFilter: React.FC<DimensionFilterProps> = ({
  allOptionKey,
  filterDimension,
  dimensionOptions,
  dimensionSelected,
  onDimensionChange,
}) => {
  const allOption = getOverriddenDimension(filterDimension, allOptionKey);
  const dimensionsWithOverrides = dimensionOptions.map((rawDimension) => {
    const display = getOverriddenDimension(filterDimension, rawDimension);
    return {
      display: display,
      value: rawDimension,
    };
  });

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    onDimensionChange(e.target.value);
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "0.5rem",
        zIndex: 10,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Input
        type="select"
        className="custom-select"
        value={dimensionSelected}
        style={{ width: "fit-content" }}
        onClick={(e) => e.preventDefault()}
        onChange={onChange}
      >
        <option value="all">{allOption}</option>
        {dimensionsWithOverrides.map((dim) => (
          <option key={dim.value} value={dim.value}>
            {dim.display}
          </option>
        ))}
      </Input>
    </div>
  );
};

export default DimensionFilter;
