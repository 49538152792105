import { NewPropertyDto, PropertyType } from "@joyhub-integration/shared";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getProperties } from "../../../services/propertiesService";
import { statesList } from "../../../services/selection";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import { handleIntInputKeyPress } from "./utils/handleInputKeyPress";

interface NewPropertyModalProps {
  onClose: () => void;
  onSave: (p: NewPropertyDto) => void;
}

export const NewPropertyModal: React.FC<NewPropertyModalProps> = ({
  onClose,
  onSave,
}) => {
  const propertyTypes: PropertyType[] = [
    PropertyType.mfr,
    PropertyType.sfr,
    PropertyType.townhome,
    PropertyType.senior,
    PropertyType.student,
    PropertyType.affordable,
  ];

  const types: string[] = propertyTypes.map((value) => {
    const key = Object.keys(PropertyType).find(
      (key) => PropertyType[key as keyof typeof PropertyType] === value,
    );
    return key!;
  });

  const [is_comparable, setIsComparable] = useState<boolean | null>(null);
  const [property_names, setPropertyNames] = useState<string[]>([]);
  const [property_type, setPropertyType] = useState<PropertyType | null>(null);
  const [unit_count, setUnitCount] = useState<number | null>(null);
  const [property_name, setPropertyName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip_code, setZipCode] = useState<string>("");

  useEffect(() => {
    (async () => {
      const names = (await getProperties()).map((p) =>
        p.property_name.toLowerCase(),
      );
      setPropertyNames(names);
    })();
  }, []);

  const onSubmit = async () => {
    let newProperty: NewPropertyDto = {
      property_name,
      property_type: property_type as PropertyType,
      address,
      city,
      state,
      zip_code,
      country: "United States",
      unit_count: unit_count ?? 0,
      is_comparable: is_comparable ?? false,
    };
    onSave(newProperty);
  };

  function getPropertyType(value: string): PropertyType | null {
    let typeKey = Object.keys(PropertyType).find(
      (key) => PropertyType[key as keyof typeof PropertyType] === value,
    );
    return typeKey ? PropertyType[typeKey as keyof typeof PropertyType] : null;
  }

  return (
    <UncontrolledModal zIndex={500} onClosed={onClose} onFormSubmit={onSubmit}>
      <ModalHeader>{`Add New Property`}</ModalHeader>
      <ModalBody
        className="px-6"
        style={{ overflow: "auto", maxHeight: "50vh" }}
      >
        {
          <Row>
            <FormGroup>
              <Input
                id="radio-own"
                type="radio"
                name="property_comparable"
                inline
                checked={is_comparable === false}
                onChange={() => setIsComparable(false)}
                required
              />
              <label style={{ marginLeft: 10 }} htmlFor="radio-own">
                I own this property
              </label>
              &nbsp;
              <Input
                style={{ marginLeft: 20 }}
                id="radio-comparable"
                type="radio"
                name="property_comparable"
                inline
                checked={is_comparable === true}
                onChange={() => setIsComparable(true)}
                required
              />
              <label style={{ marginLeft: 10 }} htmlFor="radio-comparable">
                Comparable property
              </label>
            </FormGroup>
          </Row>
        }
        <Row>
          <Col size={6}>
            <FormGroup>
              <Label>Property Type</Label>
              <Input
                id="property-type"
                type="select"
                value={property_type?.toString() ?? ""}
                onChange={(e) => {
                  const propertyType = getPropertyType(e.target.value);
                  if (
                    (unit_count ?? 0) > 4 &&
                    propertyType === PropertyType.sfr
                  ) {
                    setUnitCount(null);
                  }
                  setPropertyType(propertyType);
                }}
                required
              >
                <option key="type-select" value="">
                  Select
                </option>
                {types.map((option, index) => {
                  const value =
                    PropertyType[option as keyof typeof PropertyType];
                  return (
                    <option key={"type" + index} value={value.toString()}>
                      {value}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col size={6}>
            <FormGroup>
              <Label>Unit Count</Label>
              <Input
                id="unit-count"
                type="text"
                value={unit_count ?? ""}
                onChange={(e) => {
                  const unitCount =
                    e.target.value === "" ? null : Number(e.target.value);
                  if (
                    unitCount === 0 ||
                    (property_type === PropertyType.sfr && (unitCount ?? 0) > 4)
                  )
                    return;
                  setUnitCount(unitCount);
                }}
                onKeyPress={(e) => {
                  handleIntInputKeyPress(e);
                }}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label>Property Name</Label>
          <Input
            id="name"
            type="text"
            value={property_name}
            required
            onChange={(e) => setPropertyName(e.target.value)}
          />
          {property_names.includes(property_name.toLowerCase()) ? (
            <Label style={{ paddingTop: 10, color: "orangered" }}>
              Property name already exists. We suggest you use a unique name for
              the best experience.
            </Label>
          ) : null}
        </FormGroup>
        <Row>
          <Col size={6}>
            <FormGroup>
              <Label>Street Name</Label>
              <Input
                id="street-name"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </FormGroup>
          </Col>
          <Col size={6}>
            <FormGroup>
              <Label>City</Label>
              <Input
                id="city"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col size={6}>
            <FormGroup>
              <Label>State</Label>
              <Input
                id="state"
                type="select"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              >
                <option key="state-select" value="">
                  Select
                </option>
                {Object.keys(statesList).map((option, index) => (
                  <option key={"state" + index} value={statesList[option]}>
                    {option}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col size={6}>
            <FormGroup>
              <Label>Zip Code</Label>
              <Input
                id="zip-code"
                type="text"
                maxLength={5}
                value={zip_code}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^\d*$/.test(newValue)) {
                    setZipCode(newValue);
                  }
                }}
                required
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Save
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};
