import { zipObject } from "lodash";
import { DateIO, PureDate } from "../util";

// Should this get the Deserialised treatment too?
export type Cause =
  | CommandCause
  | FileReceivedCause
  | ScheduledSyncCause
  | UserActionCause;

// scarre also creates "scarre ingest" and (only when testing I hope)
// "scarre test insert file" and "scarre test yardi".
export type CommandEvent =
  | "computeCommonInsights"
  | "computeInsights"
  | "importData"
  | "syncSystem";
export type CommandCause = {
  event: CommandEvent;
  args: Record<string, string | undefined>;
};
export const byCommand = (
  event: CommandEvent,
  args: string[],
  params: (string | undefined)[],
): CommandCause => {
  // zipObject won't fail if the lengths don't match.
  if (args.length !== params.length)
    throw new Error("byCommand called with mismatched args/params arrays!");
  return {
    event,
    args: zipObject(args, params),
  };
};

export type FileReceivedCause = {
  event: "file received";
  key: string;
  username: string;
  filename: string;
};
export const byFileReceived = (
  key: string,
  username: string,
  filename: string,
): FileReceivedCause => ({
  event: "file received",
  key,
  username,
  filename,
});

export type ScheduledSyncCause = {
  event: "scheduled sync";
};
export const byScheduler = (): ScheduledSyncCause => ({
  event: "scheduled sync",
});

export type UserActionEvent =
  | "user created system"
  | "user re-ingested financials"
  | "user initiated sync"
  | "user updated insight configuration"
  | "user validated financial import"
  | "user submitted financial import";
export type UserActionCause = {
  event: UserActionEvent;
  person: number;
};
export const byUserAction = (
  event: UserActionEvent,
  person: number,
): UserActionCause => ({
  event,
  person,
});

export type CauseEntity<Deserialised extends boolean = false> = {
  id: number;
  event: string;
  occurred: DateIO<Deserialised>;
  detail: any;
};

export type ImportHistoryEntity<Deserialised extends boolean = false> = {
  id: number;
  import_file_id: number;
  started: DateIO<Deserialised>;
  finished: DateIO<Deserialised> | null;
  success: boolean | null;
  reason: string | null;
  logs?: any;
};

export type ComputeHistoryEntity<Deserialised extends boolean = false> = {
  id: number;
  system_id: number;

  common: boolean;
  cause_id: number;
  started: DateIO<Deserialised>;
  updated: DateIO<Deserialised> | null;
  success_count: number;
  failure_count: number;
  errors: string[];
};

export type SyncHistoryEntity<Deserialised extends boolean = false> = {
  id: number;
  system_id: number;
  cause_id: number;
  data_date: PureDate;
  started: DateIO<Deserialised>;
  updated: DateIO<Deserialised> | null;
  finished: DateIO<Deserialised> | null;
  success_count: number;
  failure_count: number;
};
