import { useEffect } from "react";
import { usePreviousDistinct } from "react-use";
import { useCountUp } from "use-count-up";

const AnimatedNumber = ({
  children: number,
  formatter,
  animated,
}: {
  children: number;
  formatter?: (number: number) => string;
  animated?: boolean;
}) => {
  const prevNumber = usePreviousDistinct(number);

  const { value: countUp, reset } = useCountUp({
    isCounting: true,
    start: prevNumber,
    end: number,
    formatter,
    duration: 1,
    thousandsSeparator: ",",
  });

  useEffect(() => {
    reset();
  }, [number, reset]);

  return <>{countUp}</>;
};

export default AnimatedNumber;
