import { PureDateIO } from "@joyhub-integration/shared";

import { apiUrl, axiosConfig } from "../utils/api";
import axios from "./axios";
import { UploadUrl, uploadFile } from "./uploadService";

export type GetReportResult = {
  id: number;
  kind: string;
  created: string;
  edited: string;
  category: string[];
  shared: boolean;
  immutable: boolean;
  identifier: string;
  name: string;
  description: string | undefined;
  editor_id: number;
  viewed?: string;
  hidden?: boolean;
};

export type AugmentedGetReportResult = GetReportResult & {
  scheduledEmailCount: number | undefined;
  editor_name?: string;
};

export type SingleReportResult = GetReportResult & {
  definition: any;
};

export type GetReportsResult = {
  reports: AugmentedGetReportResult[];
};

export const ReportSections = ["library", "my"] as const;

export type ReportSection = (typeof ReportSections)[number];

export const getActualReport = async (identifier: number | string) =>
  axios
    .get<SingleReportResult>(apiUrl(`/reports/${identifier}`), axiosConfig)
    .then((res) => res.data);

export const getOrgReports = async () =>
  axios
    .get<GetReportsResult>(apiUrl("/reports/organization"), axiosConfig)
    .then((res) => res.data.reports);

export const getSharedReports = async () =>
  axios
    .get<GetReportsResult>(apiUrl("/reports/shared"), axiosConfig)
    .then((res) => res.data.reports);

export const getReports = async () =>
  axios
    .get<GetReportsResult>(apiUrl("/reports"), axiosConfig)
    .then((res) => res.data.reports);

export const deleteReport = async (id: number) =>
  axios.delete(apiUrl(`/reports/${id}`), axiosConfig).then(() => {});

export async function getReportCategories(): Promise<string[]> {
  return axios
    .get(apiUrl("/reports/categories"), axiosConfig)
    .then((res) => res.data.categories as string[]);
}

export type ReportFile = {
  id: number;
  dashboard_id: number | null;
  filename: string;
  size: number;
  creator_id: number;
  created: PureDateIO<true>;
};

export type GetReportFilesResult = {
  files: ReportFile[];
};

export const getReportFiles = async (id: number): Promise<ReportFile[]> => {
  return axios
    .get<GetReportFilesResult>(apiUrl(`/reports/${id}/files`), axiosConfig)
    .then((res) => res.data.files);
};

export const getReportFile = async (id: number): Promise<ReportFile> => {
  return axios
    .get<ReportFile>(apiUrl(`/reports/files/${id}`), axiosConfig)
    .then((res) => res.data);
};

export const reportFileDownloadUrl = (file: number) =>
  apiUrl(`/reports/files/${file}/download`);

export const uploadReportFile = async (
  report: number | undefined,
  file: File,
): Promise<ReportFile> => {
  const filename = file.name.replace(/.*\//, "");
  return axios
    .post<UploadUrl>(apiUrl(`/upload`), {}, axiosConfig)
    .then(({ data }) => {
      const key = data.prefix + filename;
      return uploadFile(file, data, key).then(() =>
        axios
          .post<ReportFile>(
            apiUrl(`/reports/files`),
            { key, filename, report },
            axiosConfig,
          )
          .then((res) => res.data),
      );
    });
};
