import { PropertyAttribute } from "@joyhub-integration/shared";

const PropertyCell = ({ value }: { value: PropertyAttribute }) => (
  <div>
    {value.image_url && (
      <img
        style={{ width: "100px", height: "75px", objectFit: "cover" }}
        className="me-3"
        src={value.image_url}
        alt={value.attribute}
      />
    )}
    <span>{value.attribute}</span>
  </div>
);

export default PropertyCell;
