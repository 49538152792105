export type AdHocRule = TransformerRule | ByPropertyRule;

export const isTransformerRule = (rule: AdHocRule): rule is TransformerRule =>
  Array.isArray((rule as unknown as TransformerRule).dataSetConfigs);

export const isByPropertyRule = (rule: AdHocRule): rule is ByPropertyRule =>
  Array.isArray((rule as unknown as ByPropertyRule).dataColumns);

export type ByPropertyRule = {
  match: AdHocRuleMatch;
  dataDate?: { fileNameRegex: string };
  headerRow?: RowMatch;
  propertyColumn: ColumnMatch;
  dataColumns: DataColumn[];
};

export type RowMatch = RowMatchFixed | RowMatchContaining;

export type RowMatchFixed = {
  row: number;
};

export type RowMatchContaining = {
  cells: RowCellMatch[];
};

export const isRowMatchFixed = (match: RowMatch): match is RowMatchFixed =>
  typeof (match as unknown as RowMatchFixed).row === "number";

export const isRowMatchContaining = (
  match: RowMatch,
): match is RowMatchContaining =>
  Array.isArray((match as unknown as RowMatchContaining).cells);

export type RowCellMatch = {
  col?: string; // A
  value: string;
};

export type ColumnMatch = ColumnMatchFixed | ColumnMatchHeader;

export type ColumnMatchFixed = {
  col: string; // A
};

export type ColumnMatchHeader = {
  header: string;
};

export type DataType = PropertyAttributeType | PropertyInsightType;

export type PropertyAttributeType = {
  attribute: string;
};

export type PropertyInsightType = {
  insight: string;
};

export const isPropertyAttribute = (
  type: DataType,
): type is PropertyAttributeType =>
  typeof (type as unknown as PropertyAttributeType).attribute === "string";

export const isPropertyInsight = (
  type: DataType,
): type is PropertyInsightType =>
  typeof (type as unknown as PropertyInsightType).insight === "string";

export const isColumnMatchFixed = (
  match: ColumnMatch,
): match is ColumnMatchFixed =>
  typeof (match as unknown as ColumnMatchFixed).col === "string";

export const isColumnMatchHeader = (
  match: ColumnMatch,
): match is ColumnMatchHeader =>
  typeof (match as unknown as ColumnMatchHeader).header === "string";

export type DataColumn = {
  column: ColumnMatch;
  dataType: DataType;
};

export type TransformerRule = {
  match: AdHocRuleMatch;
  inputExcelConfigurations: AdHocInputConfig[];
  dataSetConfigs: AdHocDataSet[];
};

export type AdHocRuleMatch = {
  cells?: AdHocCellRule[];
  fileName?: string;
  sheetName?: string;
};

export type AdHocCellRule = {
  col: string; // A
  row: number; // 1
  value: string;
};

export type AdHocInputConfig = {
  startRow: number; // 1
  endRow: number | null; // 1
  outputDataSetName: string;
  includeHeaderInOutput: boolean;
};

export type AdHocDataSet = {
  name: string;
  outputColumns: AdHocDataSetColumn[];
};

export type AdHocDataSetColumn = {
  outputColumnName: string;
  arguments: AdHocDataSetArgument[];
  calculationFunction: string;
  displayFormat?: AdHocDataSetDisplayFormat;
};

export type AdHocDataSetArgument = {
  alias: string;
  inputColumn: string; // A
};

export type AdHocDataSetDisplayFormat = {
  format: string; // enum of excel formats
};

export const initialRule: AdHocRule = {
  match: {
    fileName: "",
  },
  propertyColumn: {
    col: "A",
  },
  dataColumns: new Array<DataColumn>(),
};
