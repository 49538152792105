import { GenericReportData } from "@joyhub-integration/shared";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { InsightCustomComponentProps } from "../../services/models";
import { Skeleton } from "../dashboard/DashboardBoard/MainBoard/BoardRow/SimpleCharts";
import Unicard from "../unicard/Unicard";
import { getReportData } from "./Craport";
import { GenericReportTable } from "./GenericReportTable";

export type EmbeddedReportConfig = {
  showGraph?: boolean;
  tableType?: {
    name?: string;
    sortColumnId?: number;
    sortType?: string;
    rowCount?: number;
  };
};

const EmbeddedReport: React.FC<
  InsightCustomComponentProps<EmbeddedReportConfig>
> = (props) => {
  const { dashboardInsight, selection } = props;

  const reportId = dashboardInsight?.linkedReport;
  const [error, setError] = useState<string>();
  const [data, setData] = useState<GenericReportData<true>>();

  useEffect(() => {
    setData(undefined);
    if (reportId != null) {
      getReportData(reportId, { properties: selection ?? {}, sheet: 0 })
        .then((data) => {
          setData(data);
          setError(undefined);
        })
        .catch((e) =>
          setError(
            e.response?.status === 404
              ? `Unknown report ${reportId}.`
              : `An error occurred in ${reportId}.`,
          ),
        );
    } else {
      setError("No linked report.");
    }
  }, [reportId, selection]);

  return error ? (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      {error}
    </div>
  ) : !data?.sheets[0] ? (
    <Skeleton />
  ) : (
    <div
      className={clsx(
        "w-100 h-100",
        dashboardInsight?.stuff?.showGraph
          ? "overflow-hidden"
          : "overflow-auto",
      )}
    >
      {dashboardInsight?.stuff?.showGraph && data.sheets[0].graph ? (
        <Unicard data={data.sheets[0]} />
      ) : (
        <GenericReportTable
          data={data.sheets[0]}
          property={{ ...dashboardInsight?.stuff?.tableType }}
        />
      )}
    </div>
  );
};

export default EmbeddedReport;
