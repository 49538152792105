import { capitalize } from "../../utils/string";

type DimensionOverride = Record<string, string> | ((v: string) => string);

export type DimensionOverrides = {
  [key: string]: DimensionOverride;
};

const dimensionOverrides: DimensionOverrides = {
  bedrooms: (v: string) =>
    v === "all"
      ? "All Bedroom Types"
      : v === "Total"
        ? "All Units"
        : v === "0"
          ? "Studio"
          : v === "1"
            ? "1 Bedroom"
            : `${v} Bedrooms`,
  resultCol: (v: string) => v,
};

export function getOverriddenDimension(
  dimensionKey: string,
  rawDimension: string,
) {
  const override = dimensionOverrides[dimensionKey];
  if (typeof override == "function") {
    return override(rawDimension);
  } else if (override?.[rawDimension]) {
    return override[rawDimension];
  }
  return capitalize(rawDimension);
}

export default dimensionOverrides;
