import { useMemo } from "react";
import { Table } from "reactstrap";

import { InstantInsights } from "../../../services/dataService";
import {
  EffectiveRentNewSignsLast30Days,
  EffectiveRentPerSqftNewSignsLast30Days,
  EffectiveRentPerSqftRenewalsLast30Days,
  EffectiveRentRenewalsLast30Days,
  NewSignsLast30Days,
  PercentTradeOutNewSignsLast30Days,
  PercentTradeOutRenewalsLast30Days,
  RenewalsLast30Days,
  TermsNewSignsLast30Days,
  TermsRenewalsLast30Days,
} from "../../../services/insightLibrary/backendInsightIds";
import { formatMonths, formattedDollar } from "../../../utils/number";
import { insightValue } from "../cdUtil";
import AnimatedNumber from "../visualizations/AnimatedNumber";
import TrendArrow from "../visualizations/TrendArrow";
import DashboardCard from "./DashboardCard";
import { Skelington } from "./Skelington";

type CDTradeOut = {
  leasesSigned: number | undefined;
  averageRent: number | undefined;
  changeFromCurrent: number | undefined;
  rentPSF: number | undefined;
  averageTerm: number | undefined;
};

type CDTradeOutData = {
  newLeases: CDTradeOut;
  renewals: CDTradeOut;
};

const tradeOutData = (
  insights: InstantInsights | undefined,
): CDTradeOutData | undefined => {
  if (insights == null) return undefined;
  return {
    newLeases: {
      leasesSigned: insightValue(insights.overall, NewSignsLast30Days),
      averageRent: insightValue(
        insights.overall,
        EffectiveRentNewSignsLast30Days,
      ),
      changeFromCurrent: insightValue(
        insights.overall,
        PercentTradeOutNewSignsLast30Days,
      ),
      rentPSF: insightValue(
        insights.overall,
        EffectiveRentPerSqftNewSignsLast30Days,
      ),
      averageTerm: insightValue(insights.overall, TermsNewSignsLast30Days),
    },
    renewals: {
      leasesSigned: insightValue(insights.overall, RenewalsLast30Days),
      averageRent: insightValue(
        insights.overall,
        EffectiveRentRenewalsLast30Days,
      ),
      changeFromCurrent: insightValue(
        insights.overall,
        PercentTradeOutRenewalsLast30Days,
      ),
      rentPSF: insightValue(
        insights.overall,
        EffectiveRentPerSqftRenewalsLast30Days,
      ),
      averageTerm: insightValue(insights.overall, TermsRenewalsLast30Days),
    },
  };
};

const cell = (
  number: number | undefined,
  formatter?: (number: number) => string,
) => (
  <td>
    {number == null ? (
      "–"
    ) : (
      <AnimatedNumber formatter={formatter}>{number}</AnimatedNumber>
    )}
  </td>
);

const TradeOutDetail = ({
  insights,
  onClick,
}: {
  insights?: InstantInsights;
  onClick?: () => void;
}) => {
  const tradeOuts = useMemo(() => tradeOutData(insights), [insights]);
  return (
    <DashboardCard
      title="Trade Out Last 30 Days"
      background="inner"
      onClick={onClick}
    >
      <Skelington value={tradeOuts} height={3}>
        {({ newLeases, renewals }) => (
          <Table className="mb-0" size="sm" responsive>
            <thead>
              <tr>
                <th />
                <th>Leases Signed</th>
                <th>Average Rent</th>
                <th>Change from Prior</th>
                <th>Rent PSF</th>
                <th>Average Term</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New Leases</td>
                {cell(newLeases.leasesSigned)}
                {cell(newLeases.averageRent, formattedDollar)}
                <td>
                  {newLeases.changeFromCurrent == null ? (
                    "–"
                  ) : (
                    <TrendArrow delta={newLeases.changeFromCurrent} />
                  )}
                </td>
                {cell(newLeases.rentPSF, formattedDollar)}
                {cell(newLeases.averageTerm, formatMonths)}
              </tr>
              <tr>
                <td>Renewals</td>
                {cell(renewals.leasesSigned)}
                {cell(renewals.averageRent, formattedDollar)}
                <td>
                  {renewals.changeFromCurrent == null ? (
                    "–"
                  ) : (
                    <TrendArrow delta={renewals.changeFromCurrent} />
                  )}
                </td>
                {cell(renewals.rentPSF, formattedDollar)}
                {cell(renewals.averageTerm, formatMonths)}
              </tr>
            </tbody>
          </Table>
        )}
      </Skelington>
    </DashboardCard>
  );
};

export default TradeOutDetail;
