import "./printHeader.css";

import { underscoreSpaces } from "@joyhub-integration/shared";
import React, { useContext, useMemo } from "react";

import { getPropertySelectionLabel } from "../../services/propertiesService";
import { apiUrl } from "../../utils/api";
import { toFullMonthAndYear, toFullMonthDayAndYear } from "../../utils/date";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import PlatformContext from "../app/PlatformContext";

type PrintHeaderProps = {
  title: string;
  period?: string;
  fullDate?: boolean;
  date?: Date;
};

const PrintHeader: React.FC<PrintHeaderProps> = ({
  title,
  period,
  fullDate,
  date,
}) => {
  const [selection] = usePropertiesSelectionQueryParam();
  const { propertiesMap, propertyGroups, organization } =
    useContext(PlatformContext).platform!;

  const logoUrl = underscoreSpaces(organization.configuration.logo);

  const propertiesLabel = useMemo(
    () =>
      getPropertySelectionLabel(
        selection ?? {},
        Object.values(propertiesMap),
        propertyGroups,
        true,
        organization.configuration.customColumns ?? [],
      ),
    [
      organization.configuration.customColumns,
      propertiesMap,
      propertyGroups,
      selection,
    ],
  );

  const actualDate = date ?? new Date();

  return (
    <div className="print-header">
      <div className="flex-grow-1">
        <div className="print-title">{title}</div>
        <div className="print-period">
          {period ??
            (fullDate
              ? toFullMonthDayAndYear(actualDate)
              : toFullMonthAndYear(actualDate))}
        </div>
        <div className="print-properties mt-4">{propertiesLabel}</div>
      </div>
      {logoUrl ? (
        <img
          className="print-header-logo flex-grow-0"
          src={apiUrl(`/organizations/${organization.id}/logo/${logoUrl}`)}
          alt={organization.name}
        />
      ) : null}
    </div>
  );
};

export default PrintHeader;
